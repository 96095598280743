import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import { GooglePlacesInput, RegisterWrapper, Form } from 'components'
import { getStyledSelectBox, getStyledOption } from 'utils/address'
import { useBarbershopStore } from 'services/stores'
import { firebaseAnalytics } from 'services/firebase'
import { validationLocation, initialLocation, dataLocation } from './settings'
import { t } from 'services/translation'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { ADDRESS_BARBERSHOP } from 'services/api/endpoints'

export const LocationStepScreen = () => {
  const { setLocation, userData } = useBarbershopStore()
  const theme = useTheme()
  const history = useHistory()
  const toast = useToast()
  const [values, getValues] = useState({
    params: initialLocation,
    isValid: false,
  })
  const [methodControl, setMethodControl] = useState(false)
  const [edit, setEdit] = useState(false)

  async function getLocation() {
    const res = await api
      .get(ADDRESS_BARBERSHOP(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-address-barbershop', getLocation, {
    onSuccess: (value) => {
      setMethodControl(false)
      if (value) {
        setLocation(value)
        setEdit(true)
        getValues({
          params: value,
          isValid: false,
        })
      }
    },
    onError: () => {
      setMethodControl(true)
    },
  })

  async function changeLocation(params) {
    const res = await api[methodControl ? 'post' : 'put'](
      ADDRESS_BARBERSHOP(userData?.barbershop?.id || userData?.id),
      params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  const mutate = useMutation(
    'change-location',
    (params) => changeLocation(params),
    {
      onSuccess: (data) => {
        toast({
          title: t('LOCATION_TOAST'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/cadastro/horario-funcionamento')
      },
      onError: (error) => {
        toast({
          title: t('LOCATION_TOAST_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_location_step_opened')
  }, [])

  const continueDisabled = useMemo(() => {
    if (
      !values?.params?.zip_code ||
      !values?.params?.state ||
      !values?.params?.city ||
      !values?.params?.street ||
      !values?.params?.street_number ||
      !values?.params?.neighborhood ||
      !values?.params?.country ||
      !values?.params?.latitude ||
      !values?.params?.longitude
    ) {
      return true
    }
    return false
  }, [values])

  return (
    <RegisterWrapper
      continueDisabled={continueDisabled}
      loading={mutate.isLoading}
      onContinue={() => {
        const params = {
          ...values?.params,
        }
        mutate.mutate(params)
      }}
    >
      <Flex autocomplete="off" flexDir="column" w="100%" h="100%">
        <GooglePlacesInput
          {...{ getStyledSelectBox, getStyledOption }}
          onSelectAddress={(address) => {
            getValues({ params: address, isValid: values?.isValid })
            setEdit(true)
          }}
        />

        <Flex
          flexDirection={['column']}
          mb={theme.pxToRem(40)}
          mt={theme.pxToRem(16)}
        >
          {edit && (
            <Form
              mb={36}
              validationSchema={validationLocation}
              initialValues={values?.params}
              data={dataLocation}
              getValues={getValues}
            />
          )}
        </Flex>
      </Flex>
    </RegisterWrapper>
  )
}

LocationStepScreen.path = '/cadastro/localizacao'
LocationStepScreen.title = 'Localização'
LocationStepScreen.secured = true
