import { api } from 'services/api/config'

export const useBarbershopWorkingHours = () => {
  const deletePersonalizedOpeningHours = async (props) => {
    const response = await api.post(
      `v3/barbershop/personalized-opening-hours/${props.barbershop_id}`,
      { item: props?.item }
    )
    return response.data
  }

  return {
    deletePersonalizedOpeningHours,
  }
}
