import { useEffect, useState } from 'react'

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Text,
  FormHelperText,
  VStack,
  Input,
  Flex,
  Image,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'

import { Icon } from 'components/atoms'

export const o_fileToBase64 = async (file) => {
  const base64 = new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  return base64
}

export const InputImageField = ({
  label,
  error,
  helperText,
  isRequired,
  isLoading,
  accept = '.png, .jpg, .jpeg',
  templateColumns = {
    base: 'repeat(2, 1fr)',
    md: 'repeat(3, 1fr)',
    lg: 'repeat(4, 1fr)',
    xl: 'repeat(5, 1fr)',
  },
  value,
  onChange,
  ...props
}) => {
  const fileVoid = { url: null, file: null }
  const [photo, setPhoto] = useState(fileVoid)

  useEffect(() => {
    value && setPhoto(value)
  }, [value])

  const handleInsertPhoto = (ev) => {
    const inputEl = ev.target
    const newPhotoFile = inputEl.files[0]

    o_fileToBase64(newPhotoFile).then((base64) => {
      const newPhotoBase64 = { url: base64, file: newPhotoFile }

      onChange?.(newPhotoBase64)
    })

    inputEl.value = ''
  }

  const handleRemoveFile = () => {
    setPhoto(fileVoid)
    onChange?.(fileVoid)
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      {label && (
        <FormLabel
          mb={2}
          fontSize={14}
          fontWeight={600}
          opacity={props.isDisabled ? 0.5 : 1}
        >
          {label}
        </FormLabel>
      )}

      <Skeleton isLoaded={!isLoading} borderRadius={6}>
        <Box opacity={props.isDisabled ? 0.5 : 1} mt={2}>
          {photo.url ? (
            <Flex
              w="fit-content"
              position="relative"
              borderRadius={4}
              borderWidth={1}
              borderColor="gray.600"
              bg="gray.25"
              overflow="hidden"
            >
              <IconButton
                icon={<Icon name="Close" size={12} color="primary" />}
                isRound
                size="xs"
                position="absolute"
                top={1}
                right={1}
                opacity={0.6}
                _hover={{ opacity: 1 }}
                onClick={handleRemoveFile}
              />
              <Image
                objectFit="cover"
                src={photo.url}
                alt="Image inserida no campo de fotos"
              />
            </Flex>
          ) : (
            <Flex
              justify="center"
              align="center"
              position="relative"
              borderRadius={4}
              borderStyle="dashed"
              borderWidth={2}
              borderColor={error ? 'red' : 'primary'}
              py={6}
              maxH={338}
            >
              <Box position="absolute" inset={0} bg="primary" opacity={0.03} />
              <VStack spacing={0}>
                <Text fontWeight={600} textAlign="center">
                  Solte a imagem ou
                </Text>
                <Text fontWeight={600} color="primary" textAlign="center">
                  clique para pesquisar no dispositivo
                </Text>
                {accept && (
                  <Text variant="small" color="gray.100" textAlign="center">
                    Arquivos suportados: {accept}
                  </Text>
                )}
              </VStack>
              <Input
                type="file"
                accept={accept}
                position="absolute"
                inset={0}
                h="100%"
                w="100%"
                cursor="pointer"
                opacity={0}
                onChange={handleInsertPhoto}
              />
            </Flex>
          )}
        </Box>
      </Skeleton>

      {helperText && (
        <FormHelperText fontSize="xs">{helperText}</FormHelperText>
      )}
      {error && (
        <FormErrorMessage fontSize="xs" color="danger">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
