import React from 'react'
import { ModalMenu, Text, Button, TextInput } from 'components'
import { useBarbershopStore } from 'services/stores'
import { theme } from 'styles/theme'
import { Flex } from '@chakra-ui/react'
import { Select, ModalCloseButton, CaretIcon } from 'react-responsive-select'
import './react-responsive-select.css'
import { t } from 'services/translation'

import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { BARBERS, SERVICES } from 'services/api/endpoints'

export const FilterModal = ({
  isModalOpen,
  goBack,
  setBarber,
  barber,
  setStatus,
  status,
  setService,
  service,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) => {
  const { userData } = useBarbershopStore()

  const statuses = [
    { text: t('ALL'), value: '' },
    {
      text: t('PAID_PAYMENT'),
      value: 'paid',
    },
    {
      text: t('PENDING_PAYMENT'),
      value: 'pending',
    },
  ]

  async function getTeam() {
    const res = await api
      .get(BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: barbersList } = useQuery('get-team', getTeam)

  async function getServices() {
    const res = await api
      .get(SERVICES(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: serviceList } = useQuery('get-services', getServices)

  const barbersSelect = () => {
    let barber = [{ text: t('ALL'), value: '' }]
    barbersList?.map((item) =>
      barber.push({
        text: item?.name,
        value: item?.name,
      })
    )
    return barber
  }

  const servicesSelect = () => {
    let services = [{ text: t('ALL'), value: '' }]
    serviceList?.map((item) =>
      services.push({
        text: item?.name,
        value: item?.name,
      })
    )
    return services
  }

  return (
    <ModalMenu
      isNavigating
      bodyStyle={{ padding: 0 }}
      isOpen={isModalOpen}
      handleBackNavigation={goBack}
      title={t('FILTER')}
    >
      <Flex px={theme.pxToRem(24)} flexDir="column">
        <Flex flexDir="column" mb={theme.pxToRem(30)}>
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            label={t('START')}
            padding={theme.pxToRem(10)}
            h={theme.pxToRem(44)}
            backgroundColor="#2b3038"
          />
        </Flex>
        <Flex flexDir="column" mb={theme.pxToRem(30)}>
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            label={t('END')}
            padding={theme.pxToRem(10)}
            h={theme.pxToRem(44)}
            backgroundColor="#2b3038"
          />
        </Flex>
        <Flex flexDir="column" mb={theme.pxToRem(30)}>
          <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
            {t('BARBER')}:
          </Text>
          <Select
            name="barber"
            onChange={(e) => setBarber(e?.value)}
            noSelectionLabel={t('ALL')}
            w="full"
            options={barbersSelect()}
            selectedValue={barber}
            modalCloseButton={<ModalCloseButton />}
            caretIcon={<CaretIcon />}
          />
        </Flex>
        <Flex flexDir="column" mb={theme.pxToRem(30)}>
          <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
            {t('_SERVICE')}:
          </Text>

          <Select
            name="service"
            onChange={(e) => setService(e?.value)}
            noSelectionLabel={t('ALL')}
            selectedValue={service}
            w="full"
            options={servicesSelect()}
            modalCloseButton={<ModalCloseButton />}
            caretIcon={<CaretIcon />}
          />
        </Flex>
        <Flex flexDir="column" mb={theme.pxToRem(30)}>
          <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
            Status:
          </Text>

          <Select
            name="status"
            onChange={(e) => setStatus(e?.value)}
            noSelectionLabel={t('ALL')}
            selectedValue={status}
            w="full"
            options={statuses}
            modalCloseButton={<ModalCloseButton />}
            caretIcon={<CaretIcon />}
          />
        </Flex>

        <Button size="block" onClick={goBack}>
          {t('APPLY')}
        </Button>
      </Flex>
    </ModalMenu>
  )
}
