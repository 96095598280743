import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Box, Flex, Text, Button } from 'components/atoms'
import { Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { dataAccessData, validationAccessData, initialAccessData } from './settings'
import { formatPhone } from 'utils/phone-formater'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { ACCESS_DATA } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'

export const AccessData = () => {
  const toast = useToast()
  const { userData } = useBarbershopStore()
  const [values, getValues] = useState({
    params: initialAccessData,
    isValid: false,
  })
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()

  async function getAccessData() {
    const res = await api
      .get(ACCESS_DATA)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-access-data', getAccessData, {
    onSuccess: (value) => {
      getValues({
        params: {
          email: value?.email,
          phone: formatPhone(value?.phone, userData),
        },
        isValid: false,
      })
    },
    refetchOnWindowFocus: false,
  })

  async function changeAccessData(params) {
    const res = await api
      .put(ACCESS_DATA, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('change-access-data', (params) => changeAccessData(params), {
    onSuccess: (data) => {
      toast({
        title: t('UPDATED_DATA'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_UPDATE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleFormSubmit = async () => {
    const params = {
      ...values?.params,
    }
    mutate.mutate(params)
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box flex={isDesktop && '1'}>
        <Text fontSize={pxToRem(18)} fontWeight="bold" marginBottom={pxToRem(26)}>
          {t('LOGIN_DATA')}
        </Text>
        <Box mt={pxToRem(32)}>
          <Form
            mb={32}
            initialValues={values?.params}
            validationSchema={validationAccessData}
            data={dataAccessData}
            getValues={getValues}
          />
        </Box>
      </Box>
      <Button
        disabled={!values?.isValid}
        onClick={handleFormSubmit}
        mt={pxToRem(32)}
        size="block"
        isLoading={mutate.isLoading}
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Flex>
  )
}
