import React, { useState } from 'react'
import { dataMinimum, validationMinimum } from './settings'
import { Box, Flex, Text, Button } from 'components/atoms'
import { Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { useMutation } from 'react-query'
import { useBarbershop } from 'services/hooks'
import { formatToSimpleHour, unformatToSimpleHour } from 'utils'

export const MinimumCancelled = () => {
  const { t } = useTranslation()
  const { userData, setUserData } = useBarbershopStore()
  const {changeMinimumTime} = useBarbershop()
  const [values, getValues] = useState({ params: {minimum_cancel_time: formatToSimpleHour(userData?.minimum_cancel_time)}, isValid: false })

  const mutate = useMutation(
    'change-minimum-time',
    (params) => changeMinimumTime(params),
  )

  const handleFormSubmit = async () => {
    const params = {
      minimum_cancel_time: unformatToSimpleHour(values?.params?.minimum_cancel_time),
      barbershop_id: userData?.barbershop?.id || userData?.id
    }
    mutate.mutate({params, onSuccess: (data) => setUserData({...userData, minimum_cancel_time: data?.minimum_cancel_time})})
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box flex="1">
        <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
          {t('MINIMUM_CANCEL_TIME')}
        </Text>

        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          {t('MINIMUM_CANCEL_TIME_DESC')}
        </Text>

        <Box mt={pxToRem(32)} mb={pxToRem(32)}>
          <Form
            initialValues={values?.params}
            validationSchema={validationMinimum}
            data={dataMinimum}
            getValues={getValues}
          />
        </Box>
        {/* <Text kind="semiBold" fontSize={pxToRem(14)} mt={pxToRem(16)}>
          {`Prazo: ${
            values?.params?.minimum_cancel_time || 2
          } horas`}
        </Text> */}
      </Box>
      <Button
        disabled={!values?.isValid || mutate.isLoading}
        isLoading={mutate.isLoading}
        onClick={handleFormSubmit}
        mt={pxToRem(32)}
        size="block"
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Flex>
  )
}
