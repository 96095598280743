import React, { useMemo, useEffect, useState } from 'react'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import {
  RegisterWrapper,
  MobileList,
  ServiceCard,
  ServicesModal,
  Form,
  Loader,
} from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'
import { useAppointmentStore } from 'services/stores'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { EDIT_SERVICE, SERVICES, CREATE_SERVICE } from 'services/api/endpoints'
import { data, validation, initial } from './Services/settings'

export const ServicesStepScreen = () => {
  const { userData } = useBarbershopStore()
  const { setServiceList, serviceList } = useAppointmentStore()
  const history = useHistory()
  const toast = useToast()
  const [serviceModalOpen, setServiceModalOpen] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [values, getValues] = useState({ params: initial, isValid: false })
  const { t } = useTranslation()

  const onCloseServiceModal = () => {
    setServiceModalOpen(false)
    setSelectedService(null)
    refetch()
  }

  const onClickAddServiceButton = () => {
    setSelectedService(null)
    setServiceModalOpen(true)
  }

  async function getServices() {
    const res = await api
      .get(SERVICES(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const {
    refetch,
    data: services_data,
    isLoading: loading_services,
  } = useQuery('get-services', getServices, {
    onSuccess: (result) => {
      setServiceList(result)
      if (result?.length > 0) {
        const current = result?.[0]
        getValues({
          ...values,
          params: {
            id: current?.id,
            name: current?.name,
            price: current?.price,
            time_required: current?.time_required,
            percentage: current?.percentage,
          },
        })
      }
    },
  })

  async function deleteService(params) {
    const res = await api
      .delete(EDIT_SERVICE(params.id, userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation(
    'delete-service',
    (params) => deleteService(params),
    {
      onSuccess: async (value) => {
        toast({
          title: t('SERVICE_DELETED'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        refetch()
      },
      onError: (error) => {
        toast({
          title: t('ERROR_EXCLUDING_SERVICE'),
          description: error.toString().substring(7),
          status: 'danger',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_services_step_opened')
  }, [])

  const continueDisabled = useMemo(() => {
    return (
      mutateDelete.isLoading ||
      (!services_data?.length > 0 &&
        (!values?.params?.time_required?.length > 0 ||
          !values?.params?.price?.length > 0 ||
          !values?.params?.percentage?.length > 0))
    )
  }, [mutateDelete.isLoading, values])

  const updatingService = useMemo(
    () => services_data?.length > 0,
    [services_data]
  )

  async function changeService() {
    const params = {
      ...values?.params,
      price: Number(values?.params?.price?.replace?.(',', '.')),
      barbershop_id: userData?.barbershop?.id || userData?.id,
      visible_for_clients: true,
      percentage: Number(
        values?.params?.percentage?.toString()?.replace?.('%', '')
      ),
      barber: 'not_update',
      type: 'normal',
    }
    const res = await api[updatingService ? 'put' : 'post'](
      updatingService ? EDIT_SERVICE(values?.params?.id) : CREATE_SERVICE,
      params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error?.response?.data?.error)
      })
    return res
  }

  const service_mutation = useMutation(
    'change-service',
    (params) => changeService(params),
    {
      onSuccess: async (value) => {
        toast({
          title: updatingService ? t('SERVICE_UPDATED') : t('SERVICE_ADDED'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/cadastro/equipe')
      },
      onError: (error) => {
        toast({
          title: t('ERROR_SAVING_SERVICE'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <RegisterWrapper
      barbershopSetup
      continueDisabled={continueDisabled}
      loading={service_mutation.isLoading}
      onContinue={service_mutation.mutate}
    >
      <Flex flexDir="column" w="100%" h="100%">
        {loading_services ? (
          <Loader marginTop={20} />
        ) : (
          <Form
            initialValues={values?.params}
            validationSchema={validation}
            data={data}
            getValues={getValues}
            mb={32}
          />
        )}
        {/* <Flex flexDir="column" alignItems="flex-start">
          <Button
            onClick={onClickAddServiceButton}
            mb={theme.pxToRem(24)}
            w="100%"
            h={theme.pxToRem(50)}
            kind="outline-primary"
          >
            {t('ADD_SERVICE_PLUS')}
          </Button>
        </Flex>

        <Flex my={theme.pxToRem(24)} w="100%">
          <MobileList
            emptyListMessage="Nenhum serviço adicionado"
            data={serviceList || []}
            loading={isLoading}
            renderItem={({ item }) => (
              <ServiceCard
                inList
                fullWidth
                outline
                service={item}
                onDelete={() => mutateDelete.mutate(item)}
              />
            )}
          />
        </Flex> */}

        {/* <ServicesModal
          isOpen={serviceModalOpen}
          onClose={onCloseServiceModal}
          selectedService={selectedService}
        /> */}
      </Flex>
    </RegisterWrapper>
  )
}

ServicesStepScreen.path = '/cadastro/servicos-barbearia'
ServicesStepScreen.title = 'Serviços'
ServicesStepScreen.secured = true
