import React, { useState, useEffect } from 'react'
import { Text, Icon, TextInput, Button } from 'components/atoms'
import { FieldControl } from 'components/molecules'
import { FieldArray, useFormikContext } from 'formik'

import { VStack, Select, HStack, Divider, IconButton } from '@chakra-ui/react'

import { useInvoiceIssuer } from 'services/hooks'
import { useQuery } from 'react-query'
import { getData } from 'services/storage'
import { useInvoiceData } from 'services/stores'

const itemVoid = {
  tomador: {
    cpf: '',
  },
  servico: {
    aliquota: '',
    discriminacao: '',
    iss_retido: '',
    item_lista_servico: '',
    codigo_tributario_municipio: '',
    valor_servicos: '',
  },
}

const IssuerInvoiceItemCardField = ({ formik, item, index, isLoading, isDisabled, ...props }) => {
  const { getAllInvoiceIssuer } = useInvoiceIssuer()
  const {setInvoiceServiceData, invoiceServiceData} = useInvoiceData()
  const [userLogged, setUserLogged] = useState({})

  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),
    { enabled: !!userLogged?.id, select: (res) => res.data[0] }
  )

  return (
    <VStack
      spacing={4}
      align="normal"
      bg="cardBackground"
      borderWidth={1}
      borderColor="modalBorder"
      borderRadius={6}
      p={4}
      key={index}
      {...props}
    >
      <HStack align="start">
        <FieldControl
          label="Logradouro"
          isLoading={isLoading}
          error={
            !invoiceServiceData?.tomador?.endereco?.logradouro?.length && !formik.values.tomador?.endereco?.logradouro?.length
          }
          render={() => <TextInput name="logradouro" value={invoiceServiceData?.tomador?.endereco?.logradouro} onChange={(t) =>
            setInvoiceServiceData({
              ...invoiceServiceData,
              tomador: {
                ...invoiceServiceData.tomador,
                endereco: {
                  ...invoiceServiceData.tomador.endereco,
                  logradouro: t?.target.value
                }
              },
            })
          } />}
        />
        <FieldControl
          label="Número"
          isLoading={isLoading}
          render={() => <TextInput name="numero" value={invoiceServiceData?.tomador?.endereco?.numero} onChange={(t) =>
            setInvoiceServiceData({
              ...invoiceServiceData,
              tomador: {
                ...invoiceServiceData.tomador,
                endereco: {
                  ...invoiceServiceData.tomador.endereco,
                  numero: t?.target.value
                }
              },
            })
          } />}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Complemento"
          isLoading={isLoading}
          render={() => (
            <TextInput
              name="complemento"
              value={invoiceServiceData?.tomador?.endereco?.complemento}
              onChange={(t) =>
                setInvoiceServiceData({
                  ...invoiceServiceData,
                  tomador: {
                    ...invoiceServiceData.tomador,
                    endereco: {
                      ...invoiceServiceData.tomador.endereco,
                      complemento: t?.target.value
                    }
                  },
                })
              }
            />
          )}
        />
        <FieldControl
          label="Bairro"
          isLoading={isLoading}
          render={() => (
            <TextInput name={'bairro'} value={invoiceServiceData?.tomador?.endereco?.bairro} onChange={(t) =>
              setInvoiceServiceData({
                ...invoiceServiceData,
                tomador: {
                  ...invoiceServiceData.tomador,
                  endereco: {
                    ...invoiceServiceData.tomador.endereco,
                    bairro: t?.target.value
                  }
                },
              })
            } />
          )}
        />
      </HStack>
      <HStack align="start">
        <FieldControl
          label="Codigo do município"
          isLoading={isLoading}
          render={() => (
            <TextInput
              name="codigo_municipio"
              value={invoiceServiceData?.tomador?.endereco?.codigo_municipio}
              onChange={(t) =>
                setInvoiceServiceData({
                  ...invoiceServiceData,
                  tomador: {
                    ...invoiceServiceData.tomador,
                    endereco: {
                      ...invoiceServiceData.tomador.endereco,
                      codigo_municipio: t?.target.value
                    }
                  },
                })
              }
            />
          )}
        />
        <FieldControl
          label="Estado"
          isLoading={isLoading}
          render={() => (
            <TextInput
              name={'uf'}
              value={invoiceServiceData?.tomador?.endereco?.uf}
              onChange={(t) =>
                setInvoiceServiceData({
                  ...invoiceServiceData,
                  tomador: {
                    ...invoiceServiceData.tomador,
                    endereco: {
                      ...invoiceServiceData.tomador.endereco,
                      uf: t?.target.value
                    }
                  },
                })
              }
            />
          )}
        />
      </HStack>
      <HStack align="start">
        <FieldControl
          label="CEP"
          isLoading={isLoading}
          render={() => (
            <TextInput
              name="cep"
              value={invoiceServiceData?.tomador?.endereco?.cep}
              onChange={(t) =>
                setInvoiceServiceData({
                  ...invoiceServiceData,
                  tomador: {
                    ...invoiceServiceData.tomador,
                    endereco: {
                      ...invoiceServiceData.tomador.endereco,
                      cep: t?.target.value
                    }
                  },
                })
              }
            />
          )}
        />
        <FieldControl
          label="Cidade"
          isLoading={isLoading}
          render={() => (
            <TextInput
              name={'municipio'}
              value={invoiceServiceData?.tomador?.endereco?.municipio}
              onChange={(t) =>
                setInvoiceServiceData({
                  ...invoiceServiceData,
                  tomador: {
                    ...invoiceServiceData.tomador,
                    endereco: {
                      ...invoiceServiceData.tomador.endereco,
                      municipio: t?.target.value
                    }
                  },
                })
              }
            />
          )}
        />
      </HStack>
    </VStack>
  )
}

export const IssuerInvoiceAddressForm = ({ isLoading, isDisabled, ...props }) => {
  const formik = useFormikContext()

  return (
    <VStack spacing={4} align="normal" {...props} mt='12px'>
      <HStack spacing={4}>
        <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
          Endereço
        </Text>
        <Divider borderColor="gray.600" />
      </HStack>

      <IssuerInvoiceItemCardField
        formik={formik}
        item={formik.values.servico}
        isLoading={isLoading}
        isDisabled={isDisabled} 
      />
    </VStack>
  )
}
