import { format, addMinutes, addHours } from 'date-fns'

export function calculateInitialHour(serviceArray) {

  if(!serviceArray.length) {
    return '00:00'
  }
    let initialHour = new Date(2023, 1, 1, 0, 0, 0);
  
    serviceArray.forEach(service => {
      initialHour = addMinutes(initialHour, Number((service?.time_required ?? service?.service?.time_required).substring(3, 5)));
      initialHour = addHours(initialHour, Number((service?.time_required ?? service?.service?.time_required).substring(0, 2)));
    });
  
    return format(initialHour, 'HH:mm');
  }