import React from 'react'
import { Text, Avatar } from 'components/atoms'
import { Flex, useTheme } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useBreakpoint } from 'services/hooks'
import { t } from 'services/translation'

export const BarberReportCard = ({ mb, last, width, wMobile, full, item, fontSize, color, h, employee }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDir="row"
      mb={mb ? theme.pxToRem(mb) : !isDesktop && theme.pxToRem(16)}
      mr={!last && theme.pxToRem(16)}
      w={isDesktop ? width || '100%' : wMobile ? theme.pxToRem(wMobile) : full ? 'full' : theme.pxToRem(140)}
      h={theme.pxToRem(h || 100)}
      bg={'darkBackground'}
      borderColor="modalBorder"
    >
      <Avatar
        br={8}
        size={isDesktop ? 100 : 'large'}
        src={item?.barber?.profile_image_url || employee?.profile_image_url}
      />
      <Flex flexDir="column" ml={theme.pxToRem(16)}>
        <Text
          kind="extraBold"
          fontSize={theme.pxToRem(fontSize || 20)}
          color={color}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          maxW={isDesktop ? '100%' : '80%'}
        >
          {item?.barber?.name || employee?.name || 'Sem colaborador'}
        </Text>

        <Text fontSize={theme.pxToRem(10)} color={'textGrey'} kind={'normal'}>
          {t('SELLS')}
        </Text>

        <Text fontSize={theme.pxToRem(14)} color={'white'} kind={'normal'}>
          {item?.appointments?.length || item?.comission?.appointments_length} {t('TOTAL')}
        </Text>

        <Text fontSize={theme.pxToRem(10)} color={'textGrey'} kind={'normal'}>
          {t('TOTAL_SALES')}
        </Text>

        <Text fontSize={theme.pxToRem(14)} color={'white'} kind={'normal'}>
          {formatPrice(item?.total_sales || item?.comission?.total_sales)}
        </Text>
      </Flex>
    </Flex>
  )
}
