import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import { Box } from '../Box'
import { Button as ChakraButton, useTheme } from '@chakra-ui/react'

const COLORS = {
  primary: {
    bg: 'brand.primary',
    textColor: 'textDark',
    borderColor: 'brand.primary',
    fontFamily: 'Montserrat ExtraBold',
  },
  secondary: {
    bg: 'background',
    textColor: 'textLight',
    borderColor: 'transparent',
    fontFamily: 'Montserrat ExtraBold',
  },
  ghost: {
    bg: 'transparent',
    textColor: 'textLight',
    borderColor: 'transparent',
    fontFamily: 'Montserrat Regular',
  },
  outline: {
    bg: 'transparent',
    textColor: 'textLight',
    borderColor: 'modalBorder',
    fontFamily: 'Montserrat ExtraBold',
  },
  'outline-primary': {
    bg: 'transparent',
    textColor: 'primary',
    borderColor: 'primary',
    fontFamily: 'Montserrat ExtraBold',
  },
  'outline-danger': {
    bg: 'transparent',
    textColor: 'textLight',
    borderColor: 'danger',
    fontFamily: 'Montserrat ExtraBold',
  },
  dashed: {
    bg: 'transparent',
    textColor: 'textLight',
    borderColor: 'brand.primaryLight',
    fontFamily: 'Montserrat Regular',
  },
  danger: {
    bg: 'danger',
    textColor: 'textLight',
    borderColor: 'danger',
    fontFamily: 'Montserrat ExtraBold',
  },
  grey: {
    bg: 'darkGrey',
    textColor: 'textLight',
    borderColor: 'grey',
    fontFamily: 'Montserrat ExtraBold',
  },
  success: {
    bg: 'green',
    textColor: 'textLight',
    borderColor: 'green',
    fontFamily: 'Montserrat ExtraBold',
  },
  appointmentOptions: {
    bg: 'cardBackground',
    textColor: 'textGrey',
    borderColor: 'modalBorder',
    fontFamily: 'Montserrat Bold',
  },
}

const SIZES = {
  'x-small': {
    w: 184,
    h: 36,
    br: 8,
    fontSize: 12,
  },
  'x-small-auto': {
    w: 'auto',
    h: 36,
    br: 8,
    fontSize: 12,
  },
  small: {
    w: 'auto',
    h: 42,
    br: 8,
    fontSize: 13,
  },
  'fit-small': {
    w: 'fit-content',
    h: 42,
    br: 8,
    fontSize: 13,
  },
  medium: {
    w: 436,
    h: 56,
    br: 8,
    fontSize: 14,
  },
  large: {
    w: 500,
    h: 56,
    br: 8,
    fontSize: 14,
  },
  square: {
    w: 56,
    h: 56,
    br: 8,
    fontSize: 10,
  },
  block: {
    w: '100%',
    h: 56,
    br: 8,
    m: 0,
    fontSize: 16,
  },
}

export const Button = (props) => {
  const { children, size, kind, icon, leftIcon, iconColor, width, iconSize, height } = props
  const theme = useTheme()

  const defaultProps = {
    _focus: { border: undefined },
    _active: { border: undefined },
  }

  return (
    <ChakraButton
      {...defaultProps}
      bgColor={COLORS[kind]?.bg}
      color={COLORS[kind]?.textColor}
      borderWidth={theme.pxToRem(1.8)}
      borderColor={COLORS[kind]?.borderColor}
      borderStyle={kind === 'dashed' ? 'dashed' : 'solid'}
      _hover={{ bg: undefined }}
      borderRadius={theme.pxToRem(SIZES[size]?.br)}
      w={width || isNaN(SIZES[size]?.w) ? SIZES[size]?.w : theme.pxToRem(SIZES[size]?.w)}
      h={height || theme.pxToRem(SIZES[size]?.h)}
      fontSize={theme.pxToRem(SIZES[size]?.fontSize)}
      fontFamily={COLORS[kind]?.fontFamily}
      justifyContent={kind === 'appointmentOptions' ? 'space-between' : !!leftIcon ? 'flex-start' : 'center'}
      paddingX={kind === 'appointmentOptions' ? theme.pxToRem(16) : theme.pxToRem(props?.px || 6)}
      {...props}
    >
      {icon && <Icon name={icon} size={iconSize || 16} color={iconColor} mr={theme.pxToRem(!!children ? 6 : 0)} />}
      {children && (
        <Box ml={!icon && theme.pxToRem(8)} mr={!leftIcon && theme.pxToRem(8)}>
          {children}
        </Box>
      )}
      {leftIcon && <Icon name={leftIcon} size={16} color={iconColor} ml={theme.pxToRem(!!children ? 6 : 0)} />}
      {kind === 'appointmentOptions' && <Icon name="ChevronRight" size={16} color="primary" />}
    </ChakraButton>
  )
}

Button.defaultProps = {
  size: 'medium',
  kind: 'primary',
}

Button.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.string,
  leftIcon: PropTypes.string,
  size: PropTypes.oneOf(['x-small', 'small', 'fit-small', 'small-tall', 'medium', 'large', 'square', 'block']),
  kind: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'outline', 'dashed', 'danger']),
}
