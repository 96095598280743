export const handleGetStatus = (status) => {
  switch (status) {
    case 'awaiting':
      return {
        value: 'awaiting',
        text: 'Aguardando',
        color: 'primary',
      }
    case 'pending':
      return {
        value: 'pending',
        text: 'Pendente',
        color: 'danger',
      }
    case 'finished':
      return {
        value: 'finished',
        text: 'Finalizado',
        color: 'successDark',
      }
    case 'reopened':
      return {
        value: 'reopened',
        text: 'Reaberta',
        color: 'primary',
      }
    case 'confirmed':
      return {
        value: 'confirmed',
        text: 'Confirmado',
        color: 'blue',
      }
    case 'client_missed':
      return {
        value: 'client_missed',
        text: 'Cliente faltou',
        color: 'textMedium',
      }
    case 'client_arrived':
      return {
        value: 'client_arrived',
        text: 'Cliente chegou',
        color: 'orange',
      }
    case 'awaiting_payment':
      return {
        value: 'awaiting_payment',
        text: 'Aguardando pagamento',
        color: 'white',
      }
    default:
      return {}
  }
}

export const subtitle_props = {
  kind: 'bold',
  color: 'textLight',
  fontSize: '16px',
  mt: 2,
  mb: 2,
}

export const calculated_info = (appointment) => {
  const total_products_comission =
    appointment?.formatted_products?.reduce(
      (accumulated, product) => accumulated + Number(product?.barber_value || 0),
      0
    ) || 0

  const original_products_comission =
    appointment?.formatted_products?.reduce(
      (accumulated, product) => accumulated + Number(product?.barber_value || 0),
      0
    ) || 0
  const total_services_comission =
    appointment?.formatted_services?.reduce(
      (accumulated, product) => accumulated + Number(product?.barber_value || 0),
      0
    ) || 0

  const original_services_comission =
    appointment?.formatted_services?.reduce(
      (accumulated, product) =>
        accumulated + Number((product?.barber_percentage / 100 || 0) * (product?.service_value || 0)),
      0
    ) || 0

  const comission_product_of_total = original_products_comission / (appointment.total_value + appointment.discount)

  const payment_taxes_barber =
    (appointment?.appointment_payments?.some((pay) => pay.payment_discount_from_barber)
      ? appointment?.payment_percentage_value
      : 0) *
    ((original_products_comission + original_services_comission) / (appointment.total_value + appointment.discount))

  const total_comission_discount =
    original_products_comission -
    total_products_comission +
    original_services_comission -
    total_services_comission -
    payment_taxes_barber

  const partial_products =
    original_products_comission -
    total_products_comission -
    (appointment?.payment_percentage_value || 0) * comission_product_of_total

  const partial_comission_discount_products =
    original_products_comission === total_products_comission || partial_products < 0 ? 0 : partial_products

  const partial_comission_discount_services =
    total_comission_discount - partial_comission_discount_products - (partial_products < 0 ? partial_products : 0)

  return [
    {
      id: 1,
      section: 'Comissão de produtos',
      data: appointment.formatted_products?.map((product) => {
        // const original_comission = (product?.barber_percentage / 100 || 0) * (product?.value || 0)
        return {
          title: `${product?.name} - Comissão de ${product?.barber_percentage}%`,
          value: product?.barber_value,
        }
      }),
    },
    {
      id: 2,
      section: 'Desconto parcial Colaborador - Produtos',
      data:
        appointment?.formatted_products?.length > 0
          ? [
              {
                title: 'Desconto oferecido em Produtos - Percentual do colaborador',
                value: -partial_comission_discount_products,
              },
            ]
          : null,
    },
    {
      id: 3,
      section: 'Comissão de serviços',
      mt: 2,
      data: appointment?.formatted_services?.map((service) => {
        // const original_comission = (service?.barber_percentage / 100 || 0) * (service?.service_value || 0)
        return {
          title: `${service?.name} - Comissão de ${service?.barber_percentage}%`,
          value: service?.barber_value,
        }
      }),
    },
    {
      id: 4,
      section: 'Desconto parcial Colaborador - Serviços',
      data:
        appointment?.formatted_services?.length > 0
          ? [
              {
                title: 'Desconto oferecido em Serviços - Percentual do colaborador',
                value: -partial_comission_discount_services,
              },
            ]
          : null,
    },
    {
      id: 5,
      mt: 2,
      section: 'Desconto taxa de método de pagamento',
      data: [
        {
          title: 'Desconto de métodos de pagamento - Percentual do colaborador',
          value: -payment_taxes_barber,
        },
      ],
    },
    {
      mt: 2,
      id: 2.1,
      section: 'Total de comissões de Produtos',
      data: [
        {
          title: 'Total de comissões de Produtos',
          value: total_products_comission,
        },
      ],
    },
    {
      id: 2.1,
      section: 'Total de comissões de Serviços',
      data: [
        {
          title: 'Total de comissões de Serviços',
          value: total_services_comission,
        },
      ],
    },
    {
      mt: 2,
      id: 2.1,
      section: 'Total de comissões do Colaborador',
      data: [
        {
          title: 'Total de comissões do Colaborador',
          value: total_services_comission + total_products_comission,
        },
      ],
    },
    {
      id: 5,
      section: 'Total de descontos das taxas dos métodos de pagamento',
      data: [
        {
          title: 'Total de taxas dos métodos de pagamento',
          value: -appointment?.formatted_payments?.reduce?.((acc, val) => acc + val.payment_percentage_value, 0) || 0,
        },
      ],
    },
  ]
}
