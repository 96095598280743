import React, { useState } from 'react'
import { Flex, Text } from 'components/atoms'

export const AgendaContextMenu = ({
  onClickProducts,
  onClickAppointment,
  onClickBlockedTimes,
  X,
  Y,
  ...props
}) => {
  const options = [
    {
      value: 'id1',
      onClick: onClickAppointment,
      text: 'Novo agendamento',
    },
    {
      value: 'id2',
      onClick: onClickBlockedTimes,
      text: 'Bloquear horário',
    },
    {
      value: 'id3',
      onClick: onClickProducts,
      text: 'Nova venda de produtos',
    },
  ]
  const [show, setShow] = useState({ id1: false, id2: false, id3: false })
  return (
    <Flex
      zIndex={9999999}
      flexDir="column"
      w="200px"
      borderColor="grey"
      borderWidth="2px"
      bg="cardBackground"
      py="4px"
      borderRadius="8px"
      box-shadow="0px 1px 8px 0px rgba(0, 0, 0, 0.1)"
      left={X}
      top={Y}
      {...props}
    >
      {options?.map(opt =>
        <Flex
          onMouseHover={opt?.value === "id3"}
          borderColor="agendaIntervalBackground"
          borderBottomWidth={opt?.value !== "id3" && "1px"}
          onMouseOver={() => setShow({ [opt?.value]: true })}
          onMouseOut={() => setShow({ [opt?.value]: false })}
          bg={show[opt?.value] && 'textMedium'}
          py="4px"
          px="10px"
          mb="4px"
          h="100%"
        >
          <Text as="button" onClick={opt?.onClick} fontSize="14px">
            {opt?.text}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
