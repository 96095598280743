import React, { useState } from 'react'
import { Text, Icon, Button, Box } from 'components/atoms'
import { Table } from 'components/molecules'
import { useAppointmentDetailStore, useBarbershopStore, useSingleSaleStore, useAppointmentStore } from 'services/stores'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import { ModalButtonBackground } from 'components-v2/atoms'

import { BARBERSHOP_GET_PAYMENT_METHOD } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { t } from 'services/translation'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'percentage',
    label: 'PERCENTAGE',
    render: (item) => <Text>{item?.id === 'comission_barber' ? 'Não se aplica' : `${item.percentage}%`}</Text>,
  },
  { key: 'due_in_days', label: 'DUE_IN_DAYS' },
]

export const PaymentMethod = ({ goBack, isSingleSale, setEnabled, ...props }) => {
  const toast = useToast()
  const [payments, setPayments] = useState([])
  const { barberSell, scheduleType } = useAppointmentStore()
  const { paymentMethod, setPaymentMethod } = useAppointmentDetailStore()
  const {
    setPaymentMethod: setPaymentMethodSingleSale,
    paymentMethod: paymentMethodSingleSale,
    barber,
  } = useSingleSaleStore()
  const { userData } = useBarbershopStore()
  const theme = useTheme()

  async function getPaymentMethods() {
    const res = await api
      .get(BARBERSHOP_GET_PAYMENT_METHOD(userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading } = useQuery('get-payment-methods', getPaymentMethods, {
    onSuccess: (value) => {
      if (barberSell && !barber?.type) {
        setPayments([
          {
            name: 'Considerar na comissão do barbeiro',
            id: 'comission_barber',
            percentage: 'Não se aplica',
            due_in_days: 'Não se aplica',
          },
          ...value,
        ])
      } else {
        setPayments(value)
      }
    },
    onError: (error) => {
      toast({
        title: t('LOAD_PAYMENT_METHODS_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleSelectPaymentMethod = (pm) => {
    if (setEnabled) {
      setEnabled(false)
    }
    if (isSingleSale) {
      if (barberSell) {
        setPaymentMethodSingleSale([pm])
        goBack()
        return
      }
      if (paymentMethodSingleSale?.includes(pm)) {
        const updateItems = paymentMethodSingleSale?.filter((i) => i !== pm)
        setPaymentMethodSingleSale(updateItems)
      } else {
        setPaymentMethodSingleSale([...paymentMethodSingleSale, pm])
      }
      return
    }
    if (paymentMethod?.includes(pm)) {
      const updateItems = paymentMethod?.filter((i) => i !== pm)
      setPaymentMethod(updateItems)
    } else {
      setPaymentMethod([...paymentMethod, pm])
    }
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box pb={120}>
        {isSingleSale && scheduleType !== 'package' && (
          <Flex mt={theme.pxToRem(-12)} mb={theme.pxToRem(12)} w="100%" flexDir="row" align="center">
            <Icon
              name="ArrowLeft"
              color="primary"
              cursor="pointer"
              w="fit-content"
              size={20}
              mr={theme.pxToRem(12)}
              onClick={goBack}
            />
            {props?.withSubtitle && (
              <Text kind="bold" fontSize={18}>
                {t('PAYMENT_METHOD')}
              </Text>
            )}
          </Flex>
        )}

        <Table
          withoutHeader
          isMultiSelectable
          isSelectableRow
          data={payments?.filter((i) => i?.name !== 'Pagamento pelo app') || []}
          loading={isLoading}
          columns={columns}
          onSelect={handleSelectPaymentMethod}
          selectedList={isSingleSale ? paymentMethodSingleSale : paymentMethod}
        />
        <ModalButtonBackground>
          <Button size="block" onClick={goBack}>
            {t('CONFIRM')}
          </Button>
        </ModalButtonBackground>
      </Box>
    </Flex>
  )
}
