import React, { useMemo } from 'react'
import { format } from 'date-fns'
import {
  Box,
  Text,
  Button,
  Flex,
  ClientCard,
  ServiceCard,
  BarberCard,
  DateTimeCard,
} from 'components'
import { useTheme, useToast } from '@chakra-ui/react'
import { APPOINTMENT_STEPS } from '../settings'
import { useAppointmentStore, useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { useAppointment } from 'services/hooks/use-appointment'
import { useMutation } from 'react-query'
import { calculateInitialHour } from 'utils/servicesTime'
import { ModalButtonBackground } from 'components-v2/atoms'

export const AppointmentSignatureSelectOptions = ({ setStep, onClose, setClickEmpty }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const toast = useToast()
  const {
    client,
    setClient,
    barber,
    setBarber,
    serviceArray,
    setServiceArray,
    date,
    setDate,
    clearState,
    time,
    setTime,
    finishHour,
    setFinishHour,
    signature,
    setAppointmentType,
  } = useAppointmentStore()
  const { userData } = useBarbershopStore()
  const { newAppointment } = useAppointment()
  const createAppointment = () => {
      if (!time || !finishHour) {
        toast({
          title: t('TIME_START_END'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })

        return
      }

      if (time > finishHour) {
        toast({
          title: t('START_TIME_UNDER_END'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return
      }
    const params = {
      date: format(date, 'yyyy-MM-dd'),
      currentHour: format(new Date(), 'HH:mm'),
      currentDate: format(new Date(), 'yyyy-MM-dd'),
      barber_id: barber.id,
      servicesId: serviceArray.map((item) => item?.service_id || item.id),
      start_hour: time,
      type: 'fit',
      barbershop_id: userData?.id,
      time_required: calculateInitialHour(serviceArray),
      signature: true,
      plan_id: client?.plan_id,
      packageService: false,
      client_id: client.client_id,
      finish_hour: finishHour,
      schedule_source: 'web_without_verification'
    }
    mutate.mutate({ params })
    setClickEmpty(false)
  }

  const mutate = useMutation('create-appointment', ({ params }) =>
    newAppointment({
      params,
      onSuccess: () => {
        onClose()
        clearState()
      },
    })
  )

  const isDisabled = useMemo(() => {
    return (
      !barber ||
      serviceArray?.length < 1 ||
      !client ||
      !date ||
      !time ||
      mutate.isLoading
    )
  }, [
    barber,
    client,
    date,
    mutate.isLoading,
    serviceArray,
    time,
  ])

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box pb={40}>
          <Box marginBottom={theme.pxToRem(16)}>
            <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
              {t('CUSTOMER')}
            </Text>

            <Flex>
              {client ? (
                <ClientCard
                  client={client}
                  onClick={() => {
                    setClient(null)
                    setServiceArray([])
                  }}
                />
              ) : (
                <Button
                  size="block"
                  kind="appointmentOptions"
                  onClick={() => setStep(APPOINTMENT_STEPS.selectCustomer)}
                >
                  {t('SELECT_CUSTOMER')}
                </Button>
              )}
            </Flex>
          </Box>
          <Box marginBottom={theme.pxToRem(16)}>
            <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
              {'Colaborador'}
            </Text>

            <Flex>
              {barber ? (
                <BarberCard
                  barber={barber}
                  onClick={() => {
                    setBarber(null)
                      setDate(null)
                      setTime(null)
                      setServiceArray([])
                  }}
                />
              ) : (
                <Button size="block" kind="appointmentOptions" onClick={() => setStep(APPOINTMENT_STEPS.selectBarber)}>
                  Selecionar colaborador
                </Button>
              )}
            </Flex>
          </Box>
        <Box marginBottom={theme.pxToRem(16)}>
          <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
            {t('_SERVICE')}
          </Text>

          <Box>
            {!!serviceArray.length &&
              serviceArray.map((item) => (
                <ServiceCard
                  mb={theme.pxToRem(8)}
                  service={item}
                  onDelete={() => {
                  setAppointmentType('normal')
                  setServiceArray(serviceArray.filter((r) => r !== item))

                  }
                }
                  date={date}
                />
              ))}
            <Button
              size="block"
              kind="appointmentOptions"
              onClick={() => {
                setAppointmentType('normal')
                   setStep(APPOINTMENT_STEPS.selectService)
              }}
              isDisabled={!barber}
            >
              {t('SELECT_SERVICE')}
            </Button>
          </Box>
        </Box>
        <Box marginBottom={theme.pxToRem(16)}>
          <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
            {t('SELECT_DATE_TIME')}
          </Text>

          <Flex flexDirection="column">
            {date && time ? (
                <DateTimeCard
                  date={date}
                  time={time}
                  finishHour={finishHour}
                  onClick={() => {
                    setDate(null)
                    setTime(null)
                    setFinishHour(null)
                  }}
                />
              ) : (
                <Button
                  size="block"
                  kind="appointmentOptions"
                  onClick={() => setStep(APPOINTMENT_STEPS.selectDateAndTime)}
                  isDisabled={!barber || !serviceArray || !client}
                >
                  {t('SELECT_DATE')}
                </Button>
              )}
          </Flex>
        </Box>
      </Box>

      <ModalButtonBackground>
        <Button
          size="block"
          onClick={createAppointment}
          isLoading={
            mutate.isLoading
          }
          isDisabled={isDisabled}
        >
          {t('CONFIRM')}
        </Button>
      </ModalButtonBackground>
    </Flex>
  )
}