import * as React from 'react'

function SvgNotificationSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 16V3.812A2.813 2.813 0 013.813 1h9.374A2.812 2.812 0 0116 3.813v5.624a2.812 2.812 0 01-2.813 2.813H4.75L1 16zM6.625 6.625h3.75M8.5 4.75V8.5"
        stroke="#20242A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgNotificationSmall
