import React, { useState } from 'react'
import { Button, Flex, Table, Text, TextInput } from 'components'
import { useToast } from '@chakra-ui/react'
import { Layout } from '../Components'
import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { formatPrice } from 'utils'
import { t } from 'services/translation'
import { RefundConfirmationModal } from 'components/organisms/RefundConfirmationModal'

const columns = [
  {
    key: 'value',
    label: 'VALOR',
    render: (item) => <Text>{formatPrice(item?.value)}</Text>,
  },
  {
    key: 'date',
    label: 'DATA',
    render: (item) => <Text>{item?.paymentDate}</Text>,
  },
  {
    key: 'description',
    label: 'DESCRIÇAO',
    render: (item) => <Text>{item?.description}</Text>,
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => <Text>{t(item?.status)}</Text>,
  },
]

export const RefundAsaasPayment = () => {
  const [clientId, setClientId] = useState(null)
  const [payment, setPayment] = useState(null)
  const [enabled, setEnabled] = useState(false)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  const toast = useToast()

  const { isLoading } = useQuery(
    ['get-client-payments'],
    () =>
      api({
        url: `v3/client/client-asaas-payments/${clientId}`,
      }),

    {
      onSuccess: ({ data }) => {
        setData(data.data)
        setEnabled(false)
      },
      onError: (error) => {
        toast({
          title: 'Erro ao buscar pagamentos',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setEnabled(false)
      },
      enabled,
    }
  )

  const mutate = useMutation(
    'refund-asaas-barbershop',
    (description) =>
      api.post(`v3/client/refund-asaas-payment`, {
        payment_id: payment?.id,
        description,
      }),
    {
      onSuccess: ({ data }) => {
        toast({
          title: 'Estorno realizado com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        window.open(data?.url, '_blank')
        setOpen(false)
      },
      onError: (error) => {
        toast({
          title: 'Erro ao realizar estorno',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <Layout title="Estornar cobrança">
      <Flex align="center" justify="center" flexDir="column" w="100%" h="100%" p="60px">
        <TextInput
          noBorder
          w="28%"
          mr="3%"
          name="clientId"
          placeHolder="10"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
          label="Id do cliente"
          padding="10px"
          h="56px"
        />
        <Button isLoading={isLoading} onClick={() => setEnabled(true)} mt="80px">
          Buscar pagamentos
        </Button>
        {data?.length > 0 && (
          <Table
            loading={isLoading}
            data={data}
            columns={columns}
            mt="24px"
            isSelectableRow
            onSelect={(item) => {
              setPayment(item)
              setOpen(true)
            }}
          />
        )}
        <RefundConfirmationModal
          isOpen={open}
          loading={mutate.isLoading}
          onClose={() => setOpen(false)}
          payment={payment}
          onClick={(description) => mutate.mutate(description)}
        />
      </Flex>
    </Layout>
  )
}

RefundAsaasPayment.path = '/internal-adm/refund-asaas-payments'
RefundAsaasPayment.title = 'Cadastrar barbearia'
RefundAsaasPayment.secured = true
