import React, { useState } from 'react'
import { Text } from 'components/atoms'
import { Flex, useTheme } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useBreakpoint } from 'services/hooks'
import statusGreen from 'assets/img/backgrounds/statusGreen.png'
import statusGrey from 'assets/img/backgrounds/statusGrey.png'
import statusRed from 'assets/img/backgrounds/statusRed.png'
import statusGreyMobile from 'assets/img/backgrounds/statusGreyMobile.png'
import statusGreenMobile from 'assets/img/backgrounds/statusGreenMobile.png'
import statusRedMobile from 'assets/img/backgrounds/statusRedMobile.png'
import { t } from 'services/translation'
import { Icon } from 'components/atoms'
import { useHistory } from 'react-router-dom'

export const FinanceResumeCard = ({
  notCurrency,
  label,
  color,
  value,
  description,
  last,
  width,
  fontSize,
  percentage,
  wMobile,
  full,
  sizeFont,
  h,
  mb,
  balance,
  background,
  green,
  info,
  infoText,
}) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const history = useHistory()
  const [show, setShow] = useState(false)

  const formatFont = (val) => {
    if (Number.isInteger(val)) {
      const abs = Math.abs(val)
      const string = String(abs)
      return string.length
    }
    const abs = Math.abs(val)
    const string = String(abs)
    const split = string.split('.')
    return split[0].length
  }

  const handleFontSize = (value) => {
    if (value === 4) {
      return 16
    }

    if (value === 5) {
      return 14
    }

    if (value === 6) {
      return 12
    }

    return 20
  }

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDir="row"
      onClick={info === 'disabled' ? null : () => history.push(info)}
      cursor={info === 'disabled' || !info ? 'auto' : 'pointer'}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      p={theme.pxToRem(8)}
      mb={mb ? theme.pxToRem(mb) : !isDesktop && theme.pxToRem(16)}
      mr={!last && theme.pxToRem(8)}
      w={
        isDesktop
          ? width || '100%'
          : wMobile
          ? theme.pxToRem(wMobile)
          : percentage
          ? percentage
          : full
          ? 'full'
          : theme.pxToRem(140)
      }
      h={h || theme.pxToRem(100)}
      bg={balance ? balance : 'cardBackground'}
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      style={
        background && {
          backgroundImage:
            background === 'success'
              ? `url(${isDesktop || green ? statusGreen : statusGreenMobile})`
              : background === 'danger'
              ? `url(${isDesktop ? statusRed : statusRedMobile})`
              : `url(${isDesktop ? statusGrey : statusGreyMobile})`,
          backgroundPosition: '100%',
          backgroundRepeat: 'no-repeat',
        }
      }
    >
      {show && infoText && (
        <Flex
          marginTop={theme.pxToRem(-60)}
          borderWidth={theme.pxToRem(1)}
          borderColor="white"
          position="fixed"
          bg="border"
          p={theme.pxToRem(8)}
          borderRadius={theme.pxToRem(8)}
          maxW="40vw"
        >
          <Text>{infoText}</Text>
        </Flex>
      )}
      <Flex flexDir="column" w="95%">
        <Text
          kind="extraBold"
          fontSize={theme.pxToRem(
            !isDesktop || sizeFont ? handleFontSize(formatFont(value)) : fontSize ? fontSize : 18
          )}
          color={color}
          mb={theme.pxToRem(8)}
        >
          {notCurrency ? value : formatPrice(value)}
        </Text>

        <Text fontSize={theme.pxToRem(12)} color={balance ? 'white' : 'textGrey'} kind={balance ? 'bold' : 'normal'}>
          {t(label)}
        </Text>

        <Text fontSize={theme.pxToRem(11)} color={balance ? 'textLight' : 'textColorLighter'}>
          {t(description)}
        </Text>
      </Flex>
      {info && <Icon size={18} name="Info" />}
    </Flex>
  )
}
