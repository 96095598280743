import React, { useMemo } from 'react'
import { Box, Flex, Text, Icon, Avatar } from 'components/atoms'
import { theme } from 'styles/theme'

export const SelectCard = ({
  product,
  client,
  reward,
  onSelect,
  isSelected,
}) => {
  const cardText = useMemo(() => {
    if (!!product) {
      return product?.name
    }
    if (!!client) {
      return client?.client?.name
    }
    if (reward?.type === 'discount') {
      return `Desconto de R$ ${reward?.discount_value} - ${reward?.required_points} Pontos`
    }
    if (reward?.type === 'product') {
      return `${reward?.product?.name} - ${reward?.required_points} Pontos`
    }
    return ''
  }, [product, client, reward])

  return (
    <Box
      display="flex"
      w="100%"
      minH={theme.pxToRem(56)}
      flexDir="row"
      mb={theme.pxToRem(16)}
      px={theme.pxToRem(16)}
      py={theme.pxToRem(8)}
      borderWidth={isSelected ? '2px' : '2px'}
      borderColor={isSelected ? 'primary' : 'cardBackground'}
      borderRadius={theme.pxToRem(8)}
      onClick={onSelect}
      cursor="pointer"
    >
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexDir="row"
      >
        <Box display="flex" alignItems="center">
          {!!client && (
            <Avatar
              src={client?.client?.profile_image_url}
              alt="Imagem do Cliente"
              size={30}
              style={{
                marginRight: theme.pxToRem(16),
              }}
            />
          )}

          <Text kind="semiBold" color="textLight" fontSize={theme.pxToRem(14)}>
            {cardText}
          </Text>
        </Box>
        <Box>
          <Icon
            name="CheckCircle"
            size={26}
            color={isSelected ? 'primary' : 'cardBackground'}
          />
        </Box>
      </Flex>
    </Box>
  )
}
