import React from 'react'
import { Image } from '@chakra-ui/react'
import { Text, Flex } from 'components'
import { theme } from 'styles'
import whyDevice from '../images/why-device.png'
import { useBreakpoint } from 'services/hooks'

const cardList = [
  {
    title: 'Tudo o que você precisa',
    description:
      'Administração da barbearia, agendamento de horários e relatórios financeiros.',
  },
  {
    title: 'Divulgue sua barbearia',
    description:
      'Informe seus horários de funcionamento, localização, serviços e fotos do seu espaço.',
  },
  {
    title: 'Gerencie sua equipe',
    description:
      'Inclua seu time de barbeiros e controle suas comissões direto no App.',
  },
  {
    title: 'Gestão financeira',
    description:
      'Balanço mensal, fluxo de caixa, gerenciamento de despesas e receitas.',
  },
]

const Card = ({ title, description }) => (
  <Flex
    borderRadius={theme.pxToRem(12)}
    mt={theme.pxToRem(12)}
    p={theme.pxToRem(16)}
    flexDir="column"
    w="100%"
    // h={theme.pxToRem(128)}
    bg="primary"
    justify="center"
  >
    <Text kind="extraBold" fontSize={theme.pxToRem(16)} color="black">
      {title}
    </Text>
    <Text
      kind="medium"
      mt={theme.pxToRem(2)}
      fontSize={theme.pxToRem(13)}
      color="black"
    >
      {description}
    </Text>
  </Flex>
)

export const Why = () => {
  const { isDesktop } = useBreakpoint()
  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        align="center"
        justify="center"
        py="10vw"
        px="5vh"
        flexDir="column"
        bgColor="menuBackground"
        textAlign="center"
      >
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(12)}
          kind="medium"
          mb={theme.pxToRem(8)}
        >
          PORQUE UTILIZAR O BESTBARBERS
        </Text>
        <Flex bg="primary" h="2px" w="15%" mb={theme.pxToRem(12)} />
        <Text
          fontSize={theme.pxToRem(32)}
          kind="extraBold"
          mb={theme.pxToRem(12)}
        >
          O seu negócio merece o melhor
        </Text>
        <Text
          mb={theme.pxToRem(8)}
          color="textLight"
          fontSize={theme.pxToRem(12)}
          kind="medium"
        >
          O BestBarbers ajuda você a administrar sua barbearia, desde o
          agendamento até a parte financeira.
        </Text>
        {cardList.map((item) => (
          <Card {...item} />
        ))}
        <Image src={whyDevice} w="100%" mt="4vh" h="auto" />
      </Flex>
    )
  }
  return (
    <Flex
      w={'100vw'}
      h={'100vh'}
      maxH={theme.pxToRem(900)}
      align="center"
      justify="center"
      pl="10vw"
      pr="5vw"
      flexDir="row"
      bgColor="menuBackground"
    >
      <Flex w="40%" mr="4%" h="100%" flexDir="column" justify="center">
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(14)}
          kind="medium"
          mb={theme.pxToRem(8)}
          mt={theme.pxToRem(24)}
        >
          PORQUE UTILIZAR O BESTBARBERS
        </Text>
        <Flex bg="primary" h="2px" w="5%" mb={theme.pxToRem(12)} />
        <Text
          w={theme.pxToRem(600)}
          fontSize={theme.pxToRem(36)}
          kind="extraBold"
          mb={theme.pxToRem(12)}
        >
          O seu negócio merece o melhor
        </Text>
        <Text
          mb={theme.pxToRem(8)}
          color="textLight"
          fontSize={theme.pxToRem(16)}
          kind="medium"
        >
          O BestBarbers ajuda você a administrar sua barbearia, desde o
          agendamento até a parte financeira.
        </Text>
        {cardList.map((item) => (
          <Card {...item} />
        ))}
      </Flex>
      <Flex w="40%" flexDir="column" align="center">
        <Image
          src={whyDevice}
          w="auto"
          mt="8vh"
          h="80vh"
          maxH={theme.pxToRem(700)}
        />
      </Flex>
    </Flex>
  )
}
