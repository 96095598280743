import * as React from 'react'

function SvgClose(props) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.49992 0.583008C12.8723 0.583008 16.4166 4.1273 16.4166 8.49967C16.4166 12.872 12.8723 16.4163 8.49992 16.4163C4.12754 16.4163 0.583252 12.872 0.583252 8.49967C0.583252 4.1273 4.12754 0.583008 8.49992 0.583008ZM6.82079 5.70034C6.67866 5.55685 6.48705 5.47311 6.28519 5.46626C6.08334 5.45941 5.88649 5.52997 5.73496 5.6635C5.58342 5.79703 5.48865 5.98343 5.47005 6.18454C5.45144 6.38566 5.51041 6.58628 5.63488 6.74534L5.70138 6.82055L7.37971 8.49888L5.70138 10.1788C5.55788 10.3209 5.47414 10.5125 5.46729 10.7144C5.46045 10.9163 5.53101 11.1131 5.66454 11.2646C5.79807 11.4162 5.98446 11.5109 6.18558 11.5295C6.38669 11.5481 6.58731 11.4892 6.74638 11.3647L6.82079 11.299L8.49992 9.61909L10.179 11.299C10.3212 11.4425 10.5128 11.5262 10.7146 11.5331C10.9165 11.5399 11.1133 11.4694 11.2649 11.3358C11.4164 11.2023 11.5112 11.0159 11.5298 10.8148C11.5484 10.6137 11.4894 10.4131 11.365 10.254L11.2993 10.1788L9.61933 8.49967L11.2993 6.82055C11.4427 6.67841 11.5265 6.48681 11.5333 6.28495C11.5402 6.08309 11.4696 5.88625 11.3361 5.73471C11.2026 5.58318 11.0162 5.48841 10.815 5.4698C10.6139 5.4512 10.4133 5.51016 10.2543 5.63463L10.179 5.70034L8.49992 7.38026L6.82079 5.70034Z"
        fill="#D92635"
      />
    </svg>
  )
}

export default SvgClose
