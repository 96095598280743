import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, ModalContent, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { benefits, paymentMethod, freePeriodText } from './settings'
import { Box, Text, Button, Icon } from 'components/atoms'
import { FreeTrialModalBg } from 'assets/img/backgrounds'
import { useBreakpoint } from 'services/hooks'
import { useModalStore } from 'services/stores'
import { useHistory } from 'react-router-dom'

export const PaymentMethodChoosedModal = ({ isOpen, onClose }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { setCurrentModal } = useModalStore()
  const [select, setSelect] = useState(1)
  const history = useHistory()

  const onConfirm = async () => {
    if (select === 1) {
      setCurrentModal('creditCardModal')
    } else {
      history.push('/agenda')
    }
  }

  const Container = isDesktop ? Flex : ModalContent

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay>
        <Container
          borderRadius="xl"
          mt={0}
          h="100vh"
          align="center"
          justify="center"
          bg="transparent"
          pb={theme.pxToRem(isDesktop ? 0 : 850)}
        >
          <Flex
            bgColor="backgroundComponent"
            backgroundImage={`url(${FreeTrialModalBg})`}
            bgRepeat="no-repeat"
            pos={!isDesktop && 'absolute'}
            top={!isDesktop && 0}
            maxW={theme.pxToRem(530)}
            maxH={theme.pxToRem(850)}
            px={theme.pxToRem(32)}
            pt={theme.pxToRem(80)}
            pb={theme.pxToRem(40)}
            alignItems="center"
            justifyContent="center"
            flexDir="Column"
            borderRadius="xl"
          >
            <Text
              align="center"
              kind="extraBold"
              fontSize={22}
              mr={theme.pxToRem(6)}
              w="80%"
            >
              {t('PAYMENT_MODAL_TITLE')}
            </Text>

            <Flex
              w={isDesktop ? '78%' : '100%'}
              justify="space-between"
              mt={'3%'}
              mb={theme.pxToRem(32)}
            >
              {paymentMethod.map((item) => (
                <>
                  <Flex
                    cursor="pointer"
                    key={item?.id}
                    flexDir="column"
                    align="center"
                    justify="center"
                    h={theme.pxToRem(110)}
                    maxW={theme.pxToRem(110)}
                    w={'30%'}
                    mr={theme.pxToRem(10)}
                    ml={theme.pxToRem(10)}
                    borderWidth={2}
                    borderColor={select === item?.id ? 'primary' : 'border'}
                    borderRadius={theme.pxToRem(8)}
                    onClick={() => setSelect(item?.id)}
                    position="relative"
                  >
                    <Icon
                      size={32}
                      name={item?.icon}
                      color={select === item?.id ? 'primary' : 'white'}
                    />
                    <Text
                      mt="5%"
                      color={select === item?.id ? 'primary' : 'white'}
                      fontSize={theme.pxToRem(16)}
                      kind="extraBold"
                    >
                      {item?.name}
                    </Text>

                    {/* CREDIT CARD DISCOUNT
                     {item?.id === 1 && (
                      <Flex
                        w={theme.pxToRem(60)}
                        h={theme.pxToRem(35)}
                        bg={select === item?.id ? 'primary' : 'white'}
                        position="absolute"
                        bottom={theme.pxToRem(-20)}
                        right="-14%"
                        borderRadius={theme.pxToRem(8)}
                        align="center"
                        justify="center"
                      >
                        <Icon
                          name="Promo"
                          color={'backgroundComponent'}
                          size={24}
                        />
                      </Flex>
                    )} */}
                  </Flex>
                </>
              ))}
            </Flex>

            <Text
              kind="extraBold"
              fontSize={18}
              textAlign="center"
              mb={theme.pxToRem(24)}
            >
              {t('MEMBERSHIP_BENEFITS')}
            </Text>

            <Box w="100%">
              <Flex w="100%" justify="space-between">
                <Flex w="62%" />
                <Flex
                  w="38%"
                  align="flex-end"
                  justify="space-between"
                  mb={theme.pxToRem(8)}
                >
                  <Text
                    kind="bold"
                    fontSize={12}
                    w="40%"
                    textAlign="center"
                    color="grey"
                  >
                    {t('BASIC')}
                  </Text>
                  <Flex
                    flexDir="column"
                    align="center"
                    w="60%"
                    mt={theme.pxToRem(isDesktop ? -16 : 4)}
                  >
                    <Icon name="Crown" fill="primary" size={16} />
                    <Text
                      kind="bold"
                      fontSize={12}
                      textAlign="center"
                      color="textColorLighter"
                    >
                      Premium
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexDir="column" w="100%">
                {benefits.map((item, index) => (
                  <Flex
                    key={`firstColumn_benefit_${index}`}
                    alignItems="center"
                    borderWidth={1}
                    py={!isDesktop && theme.pxToRem(8)}
                    h={theme.pxToRem(!isDesktop ? 40 : 36)}
                    borderColor="border"
                    borderTopRadius={theme.pxToRem(index === 0 && 8)}
                    borderBottomRadius={theme.pxToRem(index === 8 && 8)}
                    justify="space-between"
                    w="100%"
                  >
                    <Text
                      kind="semiBold"
                      fontSize={12}
                      ml={theme.pxToRem(8)}
                      w="82%"
                    >
                      {t(item.text)}
                    </Text>
                    <Flex w="40%">
                      <Icon
                        w="82%"
                        name={item?.basic ? 'CheckCircle' : 'CloseCircle'}
                        fill={item?.basic ? 'green' : 'danger'}
                        size={16}
                        mt={theme.pxToRem(3)}
                      />
                      <Icon
                        w="60%"
                        name="CheckCircle"
                        fill={item?.premium ? 'green' : 'danger'}
                        size={16}
                        mt={theme.pxToRem(3)}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Box>

            <Button kind="primary" w="100%" mt="3%" onClick={onConfirm}>
              {t('CONTINUE_BUTTON')}
            </Button>

            <Text
              mt={'3%'}
              color="textGrey"
              fontSize={10}
              width="100%"
              textAlign="center"
            >
              {t('AUTOMATIC_RENOVATION')}
            </Text>
          </Flex>
        </Container>
      </ModalOverlay>
    </Modal>
  )
}
