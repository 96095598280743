import React, { useState, useMemo } from 'react'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import {
  RegisterWrapper,
  Button,
  MobileList,
  BarberCard,
  PaymentMethodModal,
} from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useTranslation } from 'react-i18next'
import { financesStore } from 'services/stores/finances'
import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { BARBERSHOP_GET_PAYMENT_METHOD } from 'services/api/endpoints'

export const PaymentMethodStepScreen = () => {
  const { userData } = useBarbershopStore()
  const { setPaymentMethods } = financesStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const onCloseModal = () => {
    setModalVisible(false)
    refetch()
  }

  async function getPaymentMethods() {
    const res = await api
      .get(BARBERSHOP_GET_PAYMENT_METHOD(userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, refetch, isLoading } = useQuery(
    'get-payment-methods',
    getPaymentMethods,
    {
      onSuccess: (value) => {
        setPaymentMethods(value)
      },
      onError: (error) => {
        toast({
          title: t('LOAD_PAYMENT_METHODS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const continueDisabled = useMemo(() => {
    return !data || data.length === 0
  }, [data])
  return (
    <RegisterWrapper
      barbershopSetup
      continueDisabled={continueDisabled}
      loading={isLoading}
    >
      <Flex flexDir="column" w="100%" h="100%">
        <Button
          onClick={() => setModalVisible(true)}
          mb={theme.pxToRem(24)}
          w="100%"
          h={theme.pxToRem(50)}
          kind="outline-primary"
          disabled={isLoading}
        >
          {t('ADD_PAYMENT_METHOD')}
        </Button>
        <Flex w="100%">
          <MobileList
            emptyListMessage="Nenhum método de pagamento encontrado."
            data={data || []}
            loading={isLoading}
            renderItem={({ item }) => (
              <BarberCard inList fullWidth outline barber={item} />
            )}
          />
        </Flex>
      </Flex>

      {modalVisible && (
        <PaymentMethodModal isModalOpen={modalVisible} onClose={onCloseModal} />
      )}
    </RegisterWrapper>
  )
}

PaymentMethodStepScreen.path = '/cadastro/metodos-pagamentos'
PaymentMethodStepScreen.title = 'Métodos de pagamentos'
PaymentMethodStepScreen.secured = true
