import React, { useState, useEffect } from 'react'
import { Layout, Table, Text, ClientDetailModal, Avatar, Link, SendAdviceModal, TextInput } from 'components'
import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore, useCustomerStore } from 'services/stores'
import { parseISO, differenceInDays } from 'date-fns'
import './hideScrollbar.css'
import { formatPhone } from 'utils/phone-formater'
import { useMutation, useQuery } from 'react-query'
import { UnitysModal } from 'components/organisms/Unitys'
import { usePermissionCollaborator } from 'services/hooks'
import { financesStore } from 'services/stores/finances'
import { useBarbershop } from 'services/hooks'
import { useUserType } from 'services/hooks/use-user-type'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const ClientsFrequencyReport = (props) => {
  const { userData } = useBarbershopStore()
  const { getClientsFrequency, exportClientsFrequency } = useBarbershop()
  const toast = useToast()
  const { t } = useTranslation()
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const [min, setMin] = useState()
  const [max, setMax] = useState()
  const [isSendAdviceModalOpen, setSendAdviceModalOpen] = useState(false)
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore()
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const [filtered, setFiltered] = useState([])
  const { unity, setUnity } = financesStore()
  const permission = usePermissionCollaborator('notifications')
  const clients = usePermissionCollaborator('clients')
  const type = useUserType()

  useEffect(() => {
    setUnity(userData)
  }, [userData])

  const { data, refetch, isLoading } = useQuery(
    ['get-clients-resume', userData],
    () => getClientsFrequency({ id: userData?.id }),
    {
      onSuccess: (d) => setFiltered(d?.clients),
      refetchOnWindowFocus: false,
    }
  )

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])
  const columns = [
    {
      key: 'client',
      label: 'CLIENT',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Avatar size="medium" src={item['client.profile_image_url']} mr="5%" />
            <Text>{item['client.name']}</Text>
          </Flex>
        )
      },
    },
    {
      key: 'cellphone',
      label: 'PHONE',
      render: (item) => {
        if (permission === 'edit') {
          return (
            <Link href={`https://api.whatsapp.com/send?1=pt_BR&phone=${item['client.user.phone']}`} target="_blank">
              {formatPhone(item['client.user.phone'], userData)}
            </Link>
          )
        } else {
          return <Text>{formatPhone(item['client.user.phone'], userData)}</Text>
        }
      },
    },
    {
      key: 'last_appointment',
      label: 'LAST_APPOINTMENT',
      render: (item) => (
        <Text>
          {differenceInDays(new Date(), parseISO(item?.last_appointment)) >= 0
            ? t('HAVE') + differenceInDays(new Date(), parseISO(item?.last_appointment)) + ' ' + t('DAYS')
            : 'Próximo agendamento marcado'}
        </Text>
      ),
    },
  ]

  const handleSelectCustomer = (customer) => {
    if (clients !== 'blocked') {
      setSelectedCustomer(customer)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const onFilter = () => {
    const newArr = data?.clients?.filter(
      (l) =>
        differenceInDays(new Date(), parseISO(l?.last_appointment)) > (!min || min?.length === 0 ? 0 : Number(min)) &&
        differenceInDays(new Date(), parseISO(l?.last_appointment)) < (!max || max?.length === 0 ? 10000 : Number(max))
    )
    setFiltered(newArr)
  }

  useEffect(() => {
    onFilter()
  }, [min, max])

  const mutate = useMutation(['get-frequency-excel'], () => exportClientsFrequency({ id: userData?.id }), {
    onSuccess: (value) => {
      const url = URL.createObjectURL(
        new Blob([value], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      )
      window.open(url)
    },
  })

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Relatório de frequência de clientes"
      widthTitle="100%"
      subTitle={`Unidade: ${unity?.name}`}
      // headerActionClick={() => setUnityModalOpen(true)}
      iconColor="black"
      // headerActionText={t('CHANGE_UNITY')}
      iconSecondActionText={'NotificationSmall'}
      secondActionText={t('SEND_NOTIFICATION')}
      secondActionClick={permission === 'edit' ? () => setSendAdviceModalOpen(true) : null}
      thirtyClick={{
        icon: 'Excel',
        iconColor: 'black',
        onClick: () => mutate.mutate(),
        text: 'Exportar em excel',
        isLoading: mutate.isLoading,
      }}
    >
      <>
        {!filtered || isLoading ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : (
          <Flex w="100%" flexDir="column">
            <Flex w="30%" mb="64px">
              <TextInput
                value={min}
                label="Mínimo de dias último atendimento"
                placeholder="0"
                type="number"
                onChange={(e) => setMin(e.target.value.replace(/[^0-9]/g, ''))}
                onWheel={(e) => e.target.blur()}
                mr="6"
              />

              <TextInput
                value={max}
                label="Máximo de dias do último atendimento"
                placeholder="100"
                type="number"
                onChange={(e) => setMax(e.target.value.replace(/[^0-9]/g, ''))}
                onWheel={(e) => e.target.blur()}
              />
            </Flex>
            <Table
              loading={isLoading}
              data={
                filtered?.sort?.(
                  (a, b) => parseISO(a?.last_appointment).getTime() - parseISO(b?.last_appointment).getTime()
                ) || []
              }
              columns={columns}
              isSelectableRow
              onSelect={(item) => handleSelectCustomer(item)}
            />
          </Flex>
        )}
      </>

      <SendAdviceModal
        isModalOpen={isSendAdviceModalOpen}
        onClose={() => {
          setSendAdviceModalOpen(false)
        }}
        clients={filtered}
      />

      {isCustomerDetailModalOpen && (
        <ClientDetailModal
          isModalOpen={isCustomerDetailModalOpen}
          onClose={() => {
            setCustomerDetailModal(false)
            refetch()
          }}
          customerData={selectedCustomer}
        />
      )}
      {unityModalOpen && (
        <UnitysModal
          isModalOpen={unityModalOpen}
          onClose={() => setUnityModalOpen(false)}
          selected={unity}
          setSelected={setUnity}
        />
      )}
    </Layout>
  )
}

ClientsFrequencyReport.path = '/relatorio-frequencia-clientes'
ClientsFrequencyReport.title = 'Relatório Frequência - Clientes'
ClientsFrequencyReport.secured = true
