import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { Text, Button, TextInput } from 'components/atoms'
import { useBarbershop } from 'services/hooks'
import { useAppointmentDetailStore } from 'services/stores'

export const UpdateAppointmentId = ({
  isCashierOpen,
  isOpen,
  onClose,
}) => {
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const { updateAppointmentId } = useBarbershop()
  const {selectedAppointment, setSelectedAppointment} = useAppointmentDetailStore()

  const mutation = useMutation('update-appointment-personalized-id', () =>
  updateAppointmentId({
      params: {
        personalized_id: value,
      },
      appointment_id: selectedAppointment?.id,
      onSuccess: (d) => {
        onClose()
        setSelectedAppointment({
            ...selectedAppointment,
            personalized_id: value,
        })
      },
    })
  )

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
      >
        <ModalHeader textAlign="flex-start" mt={theme.pxToRem(20)} w={'100%'}>
          <Text kind="extraBold" fontSize={24} w="100%">
            Cadastro de ID personalizado
          </Text>
          <Text kind="regular" fontSize={14} mt='8px' mb='8px' w="100%">
            Insira o ID personalizado da sua comanda
          </Text>
          {!isCashierOpen && (
            <TextInput
              placeholder="ID personalizado"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              label={'ID personalizado'}
            />
          )}
        </ModalHeader>

        <ModalBody mt={theme.pxToRem(24)} w="100%">
          <Button w="100%" mb={theme.pxToRem(24)} onClick={mutation.mutate} isLoading={mutation.isLoading} disabled={ value?.length === 0}>
            Cadastrar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
