import React from 'react'
import PropTypes from 'prop-types'
import { Text, Icon } from 'components/atoms'
import { Flex, useTheme } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { usePremiumBlock } from 'services/hooks/use-premium-block'

export const MenuItem = ({ collapsed, item, selected, onClick, hasSubitem, isSubItem }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { icon, title } = item
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()

  return (
    <Flex
      alignItems="center"
      justifyContent={collapsed ? 'center' : 'space-between'}
      py={theme.pxToRem(isDesktop ? 12 : 24)}
      px={theme.pxToRem(8)}
      w={collapsed ? theme.pxToRem(48) : '100%'}
      h={collapsed ? theme.pxToRem(48) : theme.pxToRem(40)}
      bg={isSubItem ? 'backgroundLayout' : selected ? 'cardBackground' : ''}
      borderWidth={selected && 1}
      borderColor={isSubItem ? 'transparent' : selected && 'modalBorder'}
      borderRadius={!isSubItem && theme.pxToRem(8)}
      cursor="pointer"
      onClick={onClick}
      tabIndex={-1}
    >
      <Flex alignItems="center" ml={isSubItem && '16px'}>
       {!isSubItem && <Icon
          name={icon}
          size={18}
          color={selected ? 'primary' : 'textMedium'}
        />}
        {!collapsed && (
          <Text
            color={selected ? 'primary' : 'white'}
            ml={theme.pxToRem(4)}
            kind="semiBold"
            fontSize={theme.pxToRem(14)}
          >
            {t(title)}
          </Text>
        )}
      </Flex>

      {isBlocked && item?.premium && !collapsed && (
        <Icon name="PremiumCrown" size={18} />
      )}
      {item?.new && !collapsed && (
        <Flex
          w={theme.pxToRem(40)}
          h="90%"
          bg="primary"
          align="center"
          justify="center"
          borderRadius={theme.pxToRem(4)}
          ml={theme.pxToRem(6)}
        >
          <Text kind="bold" fontSize={theme.pxToRem(10)} color="black">
            Novo!
          </Text>
        </Flex>
      )}
      {hasSubitem && !collapsed && (
         <Icon
         name='ChevronDown'
         size={18}
         color='primary'
       />
      )}
    </Flex>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    selected: PropTypes.bool,
  }),
}
