import React from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { t } from 'services/translation'
import { Flex, Box, Text, Button, Icon } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { useHistory } from 'react-router-dom'

export const MissingProductModal = ({ isOpen, onClose }) => {
  const toast = useToast()
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { userData, setUserData } = useBarbershopStore()
  const { missingAdditionalProducts } = additionalProductsStore()
  const history = useHistory()

  async function infoDocs() {
    if (!userData?.barberhsop?.id && !userData?.id) {
      throw new Error('Barbearia não encontrada')
    }
    const { data } = await api.get(
      `v3/barbershop/main-data?barbershop_id=${
        userData?.barberhsop?.id || userData?.id
      }`
    )
    return data
  }

  async function infoDocsUpdate(formData) {
    const res = await api
      .put(
        `v3/barbershop/main-data?barbershop_id=${
          userData?.barberhsop?.id || userData?.id
        }`,
        formData
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data } = useQuery('barbershop-info-docs', infoDocs, {
    onError: ({ error }) => {
      toast({
        title: t('BARBERSHOP_INFO_DOCS_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        isClosable: true,
        duration: 4000,
      })
    },
  })

  const handleSuccess = (data) => {
    setUserData({
      ...userData,
      ...data,
    })
  }

  const mutate = useMutation(
    'barbershop-info-docs-update',
    (formData) => infoDocsUpdate(formData),
    {
      onSuccess: (data) => {
        toast({
          title: t('UPDATED_DATA'),
          status: 'success',
          isClosable: true,
          duration: 4000,
        })
        handleSuccess(data)

        onClose()
      },
      onError: ({ error }) => {
        toast({
          title: t('ERROR_SAVE_DATA'),
          description: error.toString().substring(7),
          status: 'error',
          isClosable: true,
          duration: 4000,
        })
      },
    }
  )
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
        pos={!isDesktop && 'absolute'}
        top={!isDesktop && 0}
        maxW={theme.pxToRem(744)}
        maxH={theme.pxToRem(!isDesktop ? 690 : 639)}
      >
        <ModalHeader
          textAlign="center"
          mt={theme.pxToRem(20)}
          w={!isDesktop ? '100%' : theme.pxToRem(744)}
        >
          <Flex
            flexDir="column"
            w="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Icon name="Stock" color="primary" size={90} />
            <Text kind="extraBold" fontSize={24} mt="12px">
              {t('MISSING_PRODUCTS_LIST_TITLE')}
            </Text>
            <Text w="100%" color="textGrey" fontSize={14} mt="12px">
              {t('MISSING_PRODUCTS_LIST_DESCRIPTION')}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody w="100%">
          <Box w="100%">
            {missingAdditionalProducts?.map((item) => (
              <Flex align="center" mb="8px">
                <Icon name="CloseCircle" color="red" />
                <Flex flexDir="column" ml="1%">
                  <Text>{`${t('product')}: ${item?.name}`}</Text>
                  <Text>
                    {`${t('QUANTITY_LEFT')}: ${item?.quantity}`} unidades
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Box>
        </ModalBody>

        <ModalFooter flexDir="column">
          <Button
            isLoading={mutate.isLoading}
            kind="primary"
            w="100%"
            onClick={() => {
              onClose()
              history.push('produtos-adicionais')
            }}
            mb={theme.pxToRem(24)}
          >
            {t('VERIFY')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
