import React, { useState, useEffect } from 'react'
import { Box, Text, TextInput, Button, Flex } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { useMutation, useQueryClient } from 'react-query'
import { ModalButtonBackground } from 'components-v2/atoms'

import { Select, useTheme, useToast } from '@chakra-ui/react'
import { tiposPremio } from '../settings'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { SelectProductModal } from '../SelectProductModal'
import { useBarbershopStore } from 'services/stores'

export const RewardsModal = ({ isOpen, onClose, isEditing, selectedItem }) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { updateReward, createReward, deleteReward } = useBestcoins()
  const [valueDiscount, setValueDiscount] = useState()
  const [valuePoints, setValuePoints] = useState()
  const [selectedType, setSelectedType] = useState('discount')
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [isProductting, setIsProducting] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const {userData} = useBarbershopStore()
  const toast = useToast()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isOpen) {
      setValueDiscount()
      setValuePoints()
      setSelectedProduct()
      setSelectedType('discount')
    }
  }, [isOpen])

  useEffect(() => {
    if (isEditing && selectedItem) {
      setValueDiscount(selectedItem?.discount_value || '')
      setValuePoints(selectedItem?.required_points || '')
      setSelectedProduct(selectedItem?.product)
      setSelectedType(selectedItem?.type || '')
    }
  }, [isEditing, selectedItem])

  const mutateEditReward = useMutation(
    () => {
      let body = {
        type: selectedType,
        required_points: valuePoints,
      }
      if (selectedType === 'product') {
        body = { ...body, product_id: selectedProduct?.id }
      } else {
        body = { ...body, discount_value: valueDiscount }
      }
      return updateReward({
        reward_id: selectedItem.id,
        body,
      })
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-rewards')
        onClose()
        toast({
          title: 'Prêmio atualizado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao atualizar prêmio',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const mutateCreateReward = useMutation(
    () => {
      let body = {
        barbershop_id: userData?.id,
        type: selectedType,
        required_points: valuePoints,
      }

      if (selectedType === 'product') {
        body = { ...body, product_id: selectedProduct?.id }
      } else {
        body = { ...body, discount_value: valueDiscount }
      }
      return createReward({ body })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-rewards')
        onClose()
        toast({
          title: 'Prêmio adicionado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao adicionar prêmio',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onCloseProductModal = () => {
    setProductModalOpen(false)
    setIsProducting(false)
  }

  const onClickProductButton = () => {
    setProductModalOpen(true)
    setIsProducting(false)
  }

  const mutateDeleteReward = useMutation(
    ['delete-reward'],
    () =>
      deleteReward({
        reward_id: selectedItem?.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('get-rewards')
        toast({
          title: 'Prêmio excluido com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        onClose()
      },
      onError: (error) => {
        toast({
          title: 'Erro ao excluir prêmio',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <ModalMenu
      title={isEditing ? 'Editar prêmio' : 'Adicionar novo prêmio'}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <Box>
        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {'Tipo de prêmio'}
        </Text>
        <Select
          name="Tipo de Prêmio"
          w="100%"
          h={theme.pxToRem(56)}
          fontFamily="Montserrat Bold"
          textColor="textLight"
          iconColor="primary"
          backgroundColor="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={theme.pxToRem(8)}
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          {tiposPremio.map((tipo, index) => (
            <option key={index} value={tipo.value}>
              {tipo.label}
            </option>
          ))}
        </Select>
        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {selectedType === 'product' ? 'Produto' : 'Valor do desconto (R$)'}
        </Text>
        {selectedType === 'product' ? (
          <Button
            size="block"
            kind="appointmentOptions"
            onClick={() => onClickProductButton()}
          >
            <Text>
              {selectedProduct
                ? `${selectedProduct?.name}`
                : 'Selecionar um produto'}
            </Text>
          </Button>
        ) : (
          <TextInput
            noBorder
            type="text"
            name="valueDiscount"
            placeholder="R$ 0,00"
            paddingLeft={theme.pxToRem(16)}
            value={valueDiscount}
            onChange={(e) => setValueDiscount(e.target.value)}
          />
        )}

        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {'Quantidade de pontos para troca'}
        </Text>
        <TextInput
          noBorder
          type="text"
          name="valuePoints"
          placeholder="10"
          paddingLeft={theme.pxToRem(16)}
          value={valuePoints}
          onChange={(e) => setValuePoints(e.target.value)}
        />
      </Box>

      <ModalButtonBackground>
        {isEditing && (
          <Button
            iconColor="baseRed"
            icon="Trash2"
            h={theme.pxToRem(52)}
            kind="outline-danger"
            size="square"
            disabled={!valuePoints}
            onClick={() => mutateDeleteReward.mutate()}
            // loading={loading}
            w="24%"
          >
            <Text fontSize="md" color={colors.baseRed} kind="bold">
              {t('DELETE')}
            </Text>
          </Button>
        )}

        <Button
          flex="1"
          h={theme.pxToRem(52)}
          ml={theme.pxToRem(12)}
          w="76%"
          disabled={
            !valuePoints ||
            (selectedType === 'discount' && !valueDiscount) ||
            (selectedType === 'product' && !selectedProduct)
          }
          onClick={() =>
            isEditing
              ? mutateEditReward.mutate({
                  requestBody: {
                    type: selectedType,
                    discount_value: valueDiscount,
                    required_points: valuePoints,
                  },
                })
              : mutateCreateReward.mutate({
                  requestBody: {
                    type: selectedType,
                    discount_value: valueDiscount,
                    required_points: valuePoints,
                  },
                })
          }
        >
          <Text fontSize="md" color={colors.textDark} kind="bold">
            {isEditing ? t('SAVE') : t('ADD')}
          </Text>
        </Button>
      </ModalButtonBackground>
      <SelectProductModal
        isOpen={productModalOpen}
        onClose={onCloseProductModal}
        isEditing={isEditing}
        selected={selectedProduct}
        setSelected={setSelectedProduct}
      />
    </ModalMenu>
  )
}
