import * as React from 'react'

function SvgPlus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 3.33301C10.4603 3.33301 10.8334 3.7061 10.8334 4.16634V15.833C10.8334 16.2932 10.4603 16.6663 10 16.6663C9.53978 16.6663 9.16669 16.2932 9.16669 15.833V4.16634C9.16669 3.7061 9.53978 3.33301 10 3.33301Z"
        fill={props.color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33334 10.0003C3.33334 9.54009 3.70644 9.16699 4.16668 9.16699H15.8333C16.2936 9.16699 16.6667 9.54009 16.6667 10.0003C16.6667 10.4606 16.2936 10.8337 15.8333 10.8337H4.16668C3.70644 10.8337 3.33334 10.4606 3.33334 10.0003Z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgPlus
