import React, { useMemo, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Box, Button, Flex, Text, TextInput, FileInput, Icon } from 'components/atoms'
import { ModalAlert, ModalMenu, WeekdaySelector } from 'components/molecules'
import { Select, useTheme, useToast, useDisclosure } from '@chakra-ui/react'
import { colors } from 'styles/colors'
import { WEEK_DAYS, TIME_REGEX, getInputTimeProcessed } from 'utils/services'
import { isEmpty } from 'lodash'
import { serviceStore, useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { useBreakpoint, usePermissionCollaborator } from 'services/hooks'
import { UpdateServiceModal } from '..'

import { api } from 'services/api/config'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { CREATE_SERVICE, EDIT_SERVICE, SERVICES_CATEGORIES } from 'services/api/endpoints'
import { useBarbershop } from 'services/hooks'
import { format } from 'date-fns'

export const ServicesModal = ({ isOpen, onClose, selectedService, packageService }) => {
  const { isOpen: isModalDeleteOpen, onOpen: onOpenModalAlert, onClose: onCloseModalAlert } = useDisclosure()
  const { category, setCategory, photo, setPhoto } = serviceStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [weekDays, setWeekDays] = useState(WEEK_DAYS)
  const [barber, setBarber] = useState()
  const [barbers, setBarbers] = useState([])
  const [visibleForClients, setVisibleForClients] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [type, setType] = useState()
  // const [updateType, setUpdateType] = useState()
  // const [date, setDate] = useState()
  const [hasDiscount, setHasDiscount] = useState(false)
  const queryClient = useQueryClient()
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()
  const { getBarbers } = useBarbershop()
  const services = usePermissionCollaborator('services')
  const updatingService = useMemo(() => !!selectedService?.id, [selectedService])

  async function getCategories() {
    const res = await api
      .get(SERVICES_CATEGORIES)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: categories } = useQuery('get-categories-service', getCategories)

  const { refetch } = useQuery(
    'get-only-barbers',
    () => getBarbers({ barbershop_id: userData?.barbershop?.id || userData?.id }),
    {
      onSuccess: (data) => {
        setBarbers([{ name: 'Todos os barbeiros', value: 'all_barbers' }, ...data])
      },
    }
  )

  async function changeService(params) {
    const res = await api[updatingService ? 'put' : 'post'](
      updatingService ? EDIT_SERVICE(selectedService?.id) : CREATE_SERVICE,
      params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error?.response?.data?.error)
      })
    return res
  }

  const mutate = useMutation('change-service', (params) => changeService(params), {
    onSuccess: async (value) => {
      queryClient.invalidateQueries('get-services')
      toast({
        title: updatingService ? t('SERVICE_UPDATED') : t('SERVICE_ADDED'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setCategory(null)
      setPhoto(null)
      setValues(initialValues)
      setWeekDays(WEEK_DAYS)
      setVisibleForClients(true)
      setModalOpen(false)
      setBarbers([])
      setBarber()
      setType()
      onClose()
    },
    onError: (error) => {
      toast({
        title: t('ERROR_SAVING_SERVICE'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  async function deleteService() {
    const res = await api
      .delete(EDIT_SERVICE(selectedService.id, userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const types = () => {
    const arr = [
      {
        id: 0,
        name: 'Normal',
        value: 'normal',
      },
      // {
      //   id: 2,
      //   name: 'Pacote de serviço recorrente',
      //   value: 'recurring_service_package',
      // },
      {
        id: 2,
        name: 'Pacote de serviço',
        value: 'individual_service_package',
      },
    ]

    if (userData?.signature_club_enable) {
      arr.push({
        id: 1,
        name: 'Assinatura',
        value: 'signature',
      })
    }

    return arr
  }

  const mutateDelete = useMutation('delete-service', () => deleteService(), {
    onSuccess: async (value) => {
      toast({
        title: t('SERVICE_DELETED'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setValues(initialValues)
      queryClient.invalidateQueries('get-services')
      onClose()
    },
    onError: (error) => {
      toast({
        title: t('ERROR_EXCLUDING_SERVICE'),
        description: error.toString().substring(7),
        status: 'danger',
        duration: 4000,
        isClosable: true,
      })
    },
  })
  useEffect(() => {
    if (updatingService) {
      refetch()
      setValues({
        name: selectedService?.name,
        time_required: selectedService?.time_required,
        price: Number(selectedService?.price).toFixed(2),
        percentage: selectedService?.percentage || '',

        quantity: Number(selectedService?.quantity),
        promotion_days: selectedService?.promotion_days,
        promotion_price: Number(selectedService?.promotion_price).toFixed(2),
      })
      setType(types().find((item) => item?.value === selectedService?.type))
    }
  }, [updatingService, selectedService])

  const initialValues = {
    name: '',
    time_required: '',
    price: '',
    percentage: '',
    quantity: null,
    // promotion_days: [],
    // promotion_price: '',
  }
  const { values, handleChange, errors, setValues, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().min(4, t('SERVICE')).required(t('SERVICE_REQUIRED')),
      time_required: Yup.string().required(t('TIME_REQUIRED')).matches(TIME_REGEX, t('INVALID_TIME')),
      price: Yup.string().required(t('PRICE_REQUIRED')).nullable(),
      percentage: Yup.string(t('Informe a comissão')).required(t('Comissão é obrigatória')).nullable(),
      // promotion_days: Yup.array().nullable(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
  })

  const handleSubmit = ({ date, update_type }) => {
    if (values?.time_required === '00:00' || values?.time_required === '00:00:00') {
      toast({
        title: t('INVALID_TIME'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    // if (!category) {
    //   toast({
    //     title: t('CATEGORY_REQUIRED'),
    //     status: 'error',
    //     duration: 4000,
    //     isClosable: true,
    //   })
    //   return
    // }
    values.price = type?.value === 'signature' ? 0 : Number(values?.price?.replace?.(',', '.'))
    const params = {
      ...values,
      product_category_id: category?.id,
      base64: photo ? photo.replace('data:image/png;base64,', '') : '',
      mime: 'image/jpeg;',
      barbershop_id: userData?.barbershop?.id || userData?.id,
      visible_for_clients: visibleForClients,
      percentage: Number(values?.percentage?.toString()?.replace?.('%', '')),
      barber: barber || 'not_update',
      initial_date: date,
      type: type?.value || type,
      promotion_days: hasDiscount ? weekDays.filter((i) => i.selected)?.map((a) => a.id) : null,
      promotion_price: hasDiscount ? Number(values?.promotion_price?.replace?.(',', '.')) : null,
      update_type,
    }
    mutate.mutate(params)
  }

  const onSubmit = () => {
    if (values?.time_required === '00:00' || values?.time_required === '00:00:00') {
      toast({
        title: t('INVALID_TIME'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    // if (!category) {
    //   toast({
    //     title: t('CATEGORY_REQUIRED'),
    //     status: 'error',
    //     duration: 4000,
    //     isClosable: true,
    //   })
    //   return
    // }
    values.price = type?.value === 'signature' ? 0 : Number(values?.price?.replace?.(',', '.'))
    const params = {
      ...values,
      product_category_id: category?.id,
      base64: photo ? photo.replace('data:image/png;base64,', '') : '',
      mime: 'image/jpeg;',
      barbershop_id: userData?.barbershop?.id || userData?.id,
      visible_for_clients: visibleForClients,
      percentage: Number(values?.percentage?.toString()?.replace?.('%', '')),
      barber: barber || 'not_update',
      // initial_date: date,
      type: type?.value || type,
      promotion_days: hasDiscount ? weekDays.filter((i) => i.selected)?.map((a) => a.id) : null,
      promotion_price: hasDiscount ? Number(values?.promotion_price?.replace?.(',', '.')) : null,
    }
    mutate.mutate(params)
  }

  const onTypeTimeInput = (ev) => {
    const value = ev?.target?.value
    const maskedValue = getInputTimeProcessed(value)

    setFieldValue('time_required', maskedValue)
  }

  const onConfirmDeleteService = () => {
    mutateDelete.mutate()
    onCloseModalAlert()
    onClose()
  }

  useEffect(() => {
    // const promotionDays = selectedService?.promotion_days || []
    // setHasDiscount(!!promotionDays.length)

    // const selectedDays = WEEK_DAYS.map((day, index) => ({
    //   ...day,
    //   selected: promotionDays.includes(index),
    // }))
    // setWeekDays(selectedDays)

    if (selectedService?.product_category) {
      setCategory(selectedService?.product_category)
    } else {
      setCategory(null)
    }

    if (selectedService?.percentage) {
      setVisibleForClients(selectedService?.visible_for_clients)
    }

    if (selectedService?.image_url) {
      setPhoto(selectedService.image_url)
    } else {
      setPhoto(null)
    }
  }, [selectedService])

  const handleSelectBarbers = (e) => {
    const [barber] = barbers.filter((item) =>
      e === 'Todos os barbeiros' ? item?.name === 'Todos os barbeiros' : e.target.value === item?.name
    )
    setBarber(barber)
  }

  useEffect(() => {
    if (packageService) {
      handleSelectType('package')
    }
  }, [packageService])

  const handleSelectType = (e) => {
    setHasDiscount(false)
    setWeekDays(WEEK_DAYS)
    if (e === 'package') {
      const [type] = types().filter((item) => 'Pacote de serviço' === item?.name)
      setType(type)
      setVisibleForClients(false)
      return
    }
    const [type] = types().filter((item) => e.target.value === item?.name)
    setType(type)
    if (type?.value === 'individual_service_package') {
      setVisibleForClients(false)
    }
  }

  const handleSelectPhoto = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setPhoto(reader.result)
    }
  }
  const isButtonDisabled = useMemo(() => {
    return type?.value === 'signature'
      ? !values?.name || values?.name?.length < 3 || !values?.time_required
      : mutate.isLoading ||
          isEmpty(values) ||
          !isEmpty(errors) ||
          (visibleForClients !== true && visibleForClients !== false) ||
          !type ||
          ((type?.value === 'recurring_service_package' || type?.value === 'individual_service_package') &&
            !values?.quantity)
  }, [mutate.isLoading, values, errors, userData, visibleForClients, type])

  const promotions_barbershops = [7657, 12014, 10712, 12956, 10313, 12243, 13124, 13148, 13205, 10810, 14239, 12685]
  return (
    <ModalMenu
      isOpen={isOpen}
      headerStyle={{ mb: theme.pxToRem(-12) }}
      onClose={() => {
        setValues(initialValues)
        setType()
        onClose()
      }}
      title={`${updatingService ? t('EDIT_SERVICE') : t('ADD')}  ${t('SERVICE_TABLE').toLowerCase()}`}
    >
      <Flex align="flex-start" flexDir="column" w="100%" h="100%">
        <Box w="100%" flex="1" pb={theme.pxToRem(24)}>
          {updatingService && selectedService?.type !== 'signature' && (
            <>
              <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
                Selecione para quem deve ser essa alteração
              </Text>

              <Select
                name="barber"
                placeholder="Não alterar para nenhum barbeiro"
                w="100%"
                h={theme.pxToRem(56)}
                fontFamily="Montserrat Bold"
                textColor="textLight"
                iconColor="primary"
                backgroundColor="cardBackground"
                borderWidth={1}
                borderColor="modalBorder"
                borderRadius={theme.pxToRem(8)}
                disabled={services !== 'edit' || hasDiscount}
                value={barber?.name}
                mb="16px"
                errorBorderColor="baseRed"
                onChange={handleSelectBarbers}
              >
                {!!barbers &&
                  barbers.map((item) => (
                    <option
                      style={{
                        backgroundColor: theme.colors.menuBackground,
                        fontFamily: 'Montserrat Medium',
                      }}
                      key={item?.id}
                    >
                      {item?.name}
                    </option>
                  ))}
              </Select>
            </>
          )}
          <Text fontSize={14} kind="medium" mt={theme.pxToRem(16)} mb={theme.pxToRem(16)}>
            {t('TYPE')}
          </Text>
          <Select
            name="product_category_id"
            placeholder={t('SELECT')}
            w="100%"
            h={theme.pxToRem(56)}
            fontFamily="Montserrat Bold"
            textColor="textLight"
            iconColor="primary"
            backgroundColor="cardBackground"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={theme.pxToRem(8)}
            value={type?.name || t('SELECT')}
            errorBorderColor="baseRed"
            onChange={handleSelectType}
            disabled={packageService || selectedService?.type === 'signature'}
          >
            {types().map((item) => (
              <option
                style={{
                  backgroundColor: theme.colors.menuBackground,
                  fontFamily: 'Montserrat Medium',
                }}
                key={item?.id}
              >
                {item?.name}
              </option>
            ))}
          </Select>
          <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)} mt={theme.pxToRem(16)}>
            {t('_SERVICE')}
          </Text>

          <TextInput
            noBorder
            type="text"
            name="name"
            placeholder={t('SERVICE_NAME_PLACEHOLDER')}
            paddingLeft={theme.pxToRem(16)}
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            readOnly={services !== 'edit' || selectedService?.type === 'signature'}
            disabled={services !== 'edit' || selectedService?.type === 'signature'}
            mb={theme.pxToRem(errors?.name ? 18 : 6)}
          />

          <Text fontSize={14} kind="medium" mt={theme.pxToRem(16)} mb={theme.pxToRem(8)}>
            {t('TIME')}
          </Text>

          <TextInput
            noBorder
            type="text"
            name="time_required"
            placeholder={t('TIME_PLACEHOLDER')}
            paddingLeft={theme.pxToRem(16)}
            error={errors.time_required}
            value={values.time_required}
            onChange={onTypeTimeInput}
            readOnly={services !== 'edit'}
            mb={theme.pxToRem(errors?.time_required ? 18 : 6)}
          />

          {(userData?.version === 'v3' || type?.value !== 'signature') && (
            <>
              <Text fontSize={14} kind="medium" mt={theme.pxToRem(16)} mb={theme.pxToRem(8)}>
                {t('PRICE')}
              </Text>

              <TextInput
                noBorder
                name="price"
                error={errors.price}
                value={values.price}
                onChange={handleChange}
                placeholder="0,00"
                mask="CURRENCY_MASK"
                masked
                leftElement={
                  <Text mt={theme.pxToRem(2)} fontWeight="bold" ml="0" width={theme.pxToRem(40)}>
                    {t('CURRENCY')}
                  </Text>
                }
                readOnly={services !== 'edit'}
                mb={theme.pxToRem(errors?.price ? 18 : 6)}
              />
              <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
                Percentual padrão de comissão
              </Text>
              <TextInput
                noBorder
                name="percentage"
                error={errors.percentage}
                value={values.percentage}
                onChange={handleChange}
                placeholder="30%"
                mask="PERCENTAGE_MASK"
                readOnly={services !== 'edit'}
                mb={theme.pxToRem(errors?.price ? 18 : 6)}
              />
            </>
          )}

          {/* <Text
            fontSize={14}
            kind="medium"
            mt={theme.pxToRem(16)}
            mb={theme.pxToRem(8)}
          >
            {t('CATEGORY')}
          </Text>

          <Select
            name="product_category_id"
            placeholder={t('SELECT')}
            w="100%"
            h={theme.pxToRem(56)}
            fontFamily="Montserrat Bold"
            textColor="textLight"
            iconColor="primary"
            backgroundColor="cardBackground"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={theme.pxToRem(8)}
            disabled={services !== 'edit'}
            value={
              updatingService
                ? selectedService?.product_category?.name ||
                category?.name ||
                t('SELECT')
                : category?.name || t('SELECT')
            }
            errorBorderColor="baseRed"
            onChange={handleSelectBarbers}
          >
            {!!categories &&
              categories.map((item) => (
                <option
                  style={{
                    backgroundColor: theme.colors.menuBackground,
                    fontFamily: 'Montserrat Medium',
                  }}
                  key={item?.id}
                >
                  {item?.name}
                </option>
              ))}
          </Select> */}

          {/* <Flex
            alignItems="center"
            justifyContent="center"
            w="100%"
            mt={theme.pxToRem(16)}
            bgImage={`url(${photo})`}
            bgPosition="center"
            bgSize="cover"
            borderRadius={16}
          >
            {photo ? (
              <Box
                top={2}
                right={2}
                p={2}
                bg="black"
                borderRadius={50}
                onClick={() => setPhoto(null)}
              >
                <Icon name="Close" size={12} />
              </Box>
            ) : (
              <FileInput
                canOverwrite
                w="100%"
                h={theme.pxToRem(120)}
                label={t('PHOTO_LABEL')}
                onChange={handleSelectPhoto}
                disabled={services !== 'edit'}
              />
            )}
          </Flex> */}

          {type?.value === 'normal' && promotions_barbershops.includes(userData?.id) && (
            <>
              <Button
                w="100%"
                mt={theme.pxToRem(24)}
                h={theme.pxToRem(52)}
                kind="outline"
                borderColor={hasDiscount ? colors.primary : 'whiteTransparent.200'}
                onClick={() => {
                  if (!hasDiscount) {
                    handleSelectBarbers('Todos os barbeiros')
                  }
                  setHasDiscount(!hasDiscount)
                }}
                isDisabled={services !== 'edit'}
              >
                <Text fontSize="md" color={hasDiscount ? colors.primary : colors.textMedium} kind="bold">
                  {t('MAKE_PROMOTIONAL')}
                </Text>
              </Button>

              {hasDiscount && (
                <Box>
                  <Text fontSize="sm" fontWeight="bold" mt={theme.pxToRem(24)}>
                    {t('PROMOTIONAL_VALUE_TABLE')}
                  </Text>
                  <TextInput
                    name="promotion_price"
                    placeholder="0,00"
                    error={errors.promotion_price}
                    value={values.promotion_price}
                    onChange={handleChange}
                    mask="CURRENCY_MASK"
                    leftElement={
                      <Text mt={theme.pxToRem(2)} fontWeight="bold" ml="0" width={theme.pxToRem(40)}>
                        {t('CURRENCY')}
                      </Text>
                    }
                    readOnly={services !== 'edit'}
                  />

                  <Text fontSize="sm" fontWeight="bold" mt={theme.pxToRem(24)}>
                    {t('IN_PROMOTION_DAYS')}
                  </Text>
                  <Box mt={theme.pxToRem(12)}>
                    <WeekdaySelector
                      locale={t('LOCALE')}
                      weekDays={weekDays}
                      onSetWeekdays={setWeekDays}
                      disabled={services !== 'edit'}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
          {type?.value === 'individual_service_package' && (
            <>
              <Text fontSize={14} kind="medium" mt={theme.pxToRem(16)} mb={theme.pxToRem(8)}>
                Quantidade de serviços no pacote
              </Text>
              <TextInput
                noBorder
                name="quantity"
                type="number"
                error={errors.quantity}
                value={values.quantity}
                onChange={handleChange}
                placeholder="4"
                readOnly={services !== 'edit'}
                mb={theme.pxToRem(errors?.quantity ? 18 : 6)}
              />
            </>
          )}

          <Text kind="semiBold" mt={theme.pxToRem(12)}>
            Serviço visível para os clientes:
          </Text>
          <Flex mt={theme.pxToRem(12)} w="100%" justifyContent="space-between">
            <Button
              onClick={() => setVisibleForClients(false)}
              h={theme.pxToRem(48)}
              kind={visibleForClients === false ? 'primary' : 'outline'}
              disabled={type?.value === 'individual_service_package'}
              w="48%"
            >
              {t('NO')}
            </Button>
            <Button
              onClick={() => setVisibleForClients(true)}
              h={theme.pxToRem(48)}
              kind={visibleForClients === true ? 'primary' : 'outline'}
              w="48%"
              disabled={type?.value === 'individual_service_package'}
            >
              {t('YES')}
            </Button>
          </Flex>
          {updatingService && services === 'edit' ? (
            <>
              {selectedService?.type !== 'signature' && (
                <Button
                  flex={1}
                  mr={theme.pxToRem(8)}
                  onClick={onOpenModalAlert}
                  isLoading={mutateDelete.isLoading}
                  h={theme.pxToRem(52)}
                  kind="danger"
                  w="100%"
                  mt={theme.pxToRem(24)}
                  mb={theme.pxToRem(16)}
                >
                  <Text fontSize="md" color={colors.white} kind="bold">
                    {t('DELETE')}
                  </Text>
                </Button>
              )}
              <Button
                mt={selectedService?.type === 'signature' && '24px'}
                flex={1}
                mb={theme.pxToRem(!isDesktop && 52)}
                w="100%"
                h={theme.pxToRem(52)}
                isLoading={mutate.isLoading}
                disabled={isButtonDisabled}
                onClick={() => {
                  if (Number(selectedService?.price) !== Number(values?.price?.replace?.(',', '.')) && !hasDiscount) {
                    setModalOpen(true)
                    return
                  }
                  handleSubmit({ date: format(new Date(), 'yyyy-MM-dd'), update_type: 'only_next' })
                }}
              >
                <Text fontSize="md" color={colors.textDark} kind="bold">
                  {t('SAVE')}
                </Text>
              </Button>
            </>
          ) : (
            services === 'edit' && (
              <Button
                mb={!isDesktop && '20%'}
                flex="1"
                h={theme.pxToRem(52)}
                isLoading={mutate.isLoading}
                disabled={isButtonDisabled}
                w="100%"
                mt={theme.pxToRem(24)}
                onClick={() => onSubmit()}
              >
                <Text fontSize="md" color={colors.textDark} kind="bold">
                  {t('ADD')}
                </Text>
              </Button>
            )
          )}
        </Box>

        <ModalAlert
          isOpen={isModalDeleteOpen}
          onClose={onCloseModalAlert}
          onConfirm={onConfirmDeleteService}
          primaryButtonLabel={t('DELETE')}
          modalTitle={t('DELETE_SERVICE')}
        >
          <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
            {t('DELETE_SERVICE_CONFIRM')} "{values.name}" ?
          </Text>
        </ModalAlert>
        <UpdateServiceModal
          isOpen={modalOpen}
          isLoading={mutate.isLoading}
          onClose={() => setModalOpen(false)}
          onClick={(ty) => {
            // setUpdateType(ty?.type)
            // setDate(ty?.date)
            handleSubmit({ date: ty?.date, update_type: ty?.type })
          }}
          barber_id={barber?.value === 'all_barbers' ? 'undefined' : barber?.id}
          service_id={selectedService?.id}
        />
      </Flex>
    </ModalMenu>
  )
}
