import React from 'react'
import { Box, Text } from 'components/atoms'
import { ModalAlert } from "components/molecules"
import { useTheme } from "@chakra-ui/react"
import { useHistory } from 'react-router-dom'
import { useBarbershopStore } from 'services/stores/barbershop'
import { saveLoginData } from 'services/hooks'
import { useTranslation } from 'react-i18next'

export const LogoutModal = ({isOpen, onClose}) => {
  const { setData } = useBarbershopStore()
  const history = useHistory()
  const theme = useTheme()
  const {t} = useTranslation()

  const onConfirmLogout = () => {
    saveLoginData({})
    setData({}, {})
    localStorage.clear()
    history.replace("/");
   
  }

  return (
    <Box>
      <ModalAlert
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirmLogout}
        primaryButtonLabel={t('YES')}
        modalTitle="Logout"
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('EXIT_CONFIRMATION')}
        </Text>
      </ModalAlert>
    </Box>
  )
}
