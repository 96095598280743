import React, { useState } from 'react'
import { dataMinumumHour, validationMinumumHour } from './settings'
import { Box, Flex, Text, Button } from 'components/atoms'
import { Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { useMutation } from 'react-query'
import { useBarbershop } from 'services/hooks'
import { formatToSimpleHour, unformatToSimpleHour } from 'utils'

export const MinimumScheduleHour = () => {
  const { t } = useTranslation()
  const { userData, setUserData } = useBarbershopStore()
  const {changeMinimumScheduleTime} = useBarbershop()
  const [values, getValues] = useState({ params: {minimum_schedule_hour: formatToSimpleHour(userData?.minimum_schedule_hour)}, isValid: false })

  const mutate = useMutation(
    'change-minimum-time',
    (params) => changeMinimumScheduleTime(params),
  )

  const handleFormSubmit = async () => {
    const params = {
      minimum_schedule_hour: unformatToSimpleHour(values?.params?.minimum_schedule_hour),
      barbershop_id: userData?.barbershop?.id || userData?.id
    }
    mutate.mutate({params, onSuccess: (data) => setUserData({...userData, minimum_schedule_hour: data?.minimum_schedule_hour})})
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box flex="1">
        <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
          Tempo mínimo de agendamento
        </Text>

        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          Determine qual o prazo mínimo para que o seu cliente consiga realizar um agendamento (Ex.10 minutos de antecedência)
        </Text>

        <Box mt={pxToRem(32)} mb={pxToRem(32)}>
          <Form
            initialValues={values?.params}
            validationSchema={validationMinumumHour}
            data={dataMinumumHour}
            getValues={getValues}
          />
        </Box>
      </Box>
      <Button
        disabled={!values?.isValid || mutate.isLoading}
        isLoading={mutate.isLoading}
        onClick={handleFormSubmit}
        mt={pxToRem(32)}
        size="block"
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Flex>
  ) 
}
