import React from 'react'
import { Flex, Table, Text } from 'components'
import { useAppointmentStore } from 'services/stores'

const columns = [
  {
    key: 'source',
    label: 'SELECT_SOURCE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
]

export const AppointmentSelectSource = ({ goBack, setEnabled }) => {
  const { sourcesList, loading, setSource } = useAppointmentStore()

  const handleSelectSource = (source) => {
    setEnabled(false)
    setSource(source)
    goBack()
  }

  return (
    <Flex flexDirection="column">
      <Table
        isSelectableRow
        data={sourcesList || []}
        loading={loading}
        columns={columns}
        onSelect={handleSelectSource}
      />
    </Flex>
  )
}
