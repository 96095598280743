import * as React from 'react'

function SvgScissors(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.656 37.772a1.66 1.66 0 01-1.37-2.592l22.838-33.6a1.66 1.66 0 013.006.644c.554 3.144.484 6.567-.202 9.898-.703 3.412-2.026 6.574-3.826 9.145l-9.84 14.162a1.661 1.661 0 01-2.314.413 4.983 4.983 0 00-6.931 1.222c-.323.461-.838.708-1.361.708zm5.45-6.145c1.112 0 2.23.222 3.286.68l8.988-12.938c2.282-3.259 3.624-7.628 3.785-11.947L25.706 31.636c.133-.006.266-.01.4-.01zM13.031 56.116a8.235 8.235 0 01-4.74-1.503C4.544 51.99 3.63 46.807 6.253 43.06a8.241 8.241 0 015.355-3.412 8.242 8.242 0 016.199 1.374 8.24 8.24 0 013.412 5.355 8.242 8.242 0 01-1.375 6.199 8.242 8.242 0 01-5.355 3.411 8.376 8.376 0 01-1.458.129zm-4.06-11.153a4.983 4.983 0 001.223 6.932 4.945 4.945 0 003.72.825 4.946 4.946 0 003.212-2.047 4.946 4.946 0 00.825-3.72 4.946 4.946 0 00-2.047-3.213 4.945 4.945 0 00-3.72-.824 4.946 4.946 0 00-3.213 2.047z"
      />
      <path
        fill="currentColor"
        d="M16.849 44.04a1.659 1.659 0 01-1.416-2.52l3.806-6.268a1.659 1.659 0 012.836 1.723l-3.806 6.266a1.659 1.659 0 01-1.42.798z"
      />
      <path
        fill="currentColor"
        d="M25.34 37.773a1.658 1.658 0 01-1.36-.707 4.983 4.983 0 00-6.932-1.223 1.66 1.66 0 01-2.312-.409l-9.843-14.1C1.305 16.209-.277 8.708.867 2.226a1.659 1.659 0 013.005-.645l22.839 33.6a1.659 1.659 0 01-1.37 2.592zM3.827 7.417c.153 4.35 1.497 8.747 3.786 12.015L16.6 32.31a8.26 8.26 0 013.69-.671L3.826 7.416zM32.972 56.116a8.38 8.38 0 01-1.459-.128 8.242 8.242 0 01-5.355-3.412 8.242 8.242 0 01-1.373-6.198 8.24 8.24 0 013.411-5.355c3.747-2.624 8.93-1.71 11.554 2.037 2.624 3.747 1.71 8.93-2.038 11.554a8.239 8.239 0 01-4.74 1.502zm-.025-13.273a4.95 4.95 0 00-2.848.898 4.983 4.983 0 00-1.223 6.932 4.944 4.944 0 003.214 2.047c1.309.231 2.63-.062 3.72-.824a4.975 4.975 0 00-2.862-9.053zm-3.8-.461h.002-.002z"
      />
      <path
        fill="currentColor"
        d="M29.15 44.04c-.562 0-1.108-.285-1.42-.799l-3.807-6.266a1.659 1.659 0 012.836-1.722l3.806 6.266a1.658 1.658 0 01-1.416 2.52zM38.663 57.482c-.508 0-1.01-.233-1.335-.673l-1.903-2.573a1.658 1.658 0 112.668-1.973l1.903 2.573a1.659 1.659 0 01-1.333 2.646zM6.406 57.482a1.659 1.659 0 01-1.333-2.646l1.904-2.573a1.66 1.66 0 012.667 1.973l-1.903 2.573c-.325.44-.827.673-1.335.673z"
      />
    </svg>
  )
}

export default SvgScissors
