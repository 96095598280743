import React, { useEffect, useMemo } from 'react'
import { Flex, Box, Text, Calendar, TextInput, Button } from 'components'
import { useTheme } from '@chakra-ui/react'
import { useAppointmentStore } from 'services/stores'
import { useTranslation } from 'react-i18next'

export const AppointmentSignatureSelectDate = ({ goBack }) => {
  const { time, setTime, date, setDate, finishHour, setFinishHour, signature } = useAppointmentStore()
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    if (!date) {
      setDate(new Date())
    }
  }, [])

  const tileDisabled = ({ date }) => {
    return !signature?.plan?.days ? false : !signature?.plan?.days?.find((i) => i === date?.getDay())
  }

  const fitTypeButtonDisabled = useMemo(() => {
    if (!time || !finishHour) {
      return true
    }
    if (finishHour?.replace('_', '')?.length < 5 || time?.replace('_', '')?.length < 5) {
      return true
    }
    if (finishHour < time || finishHour > '23:59' || time > '23:59') {
      return true
    }
  }, [finishHour, time])

  return (
    <Flex flexDirection="column">
      <Box marginBottom={theme.pxToRem(32)}>
        <Calendar
          selectedDate={date}
          onSelectDate={(date) => setDate(date)}
          // tileDisabled={tileDisabled}
        />
      </Box>
      <Box>
        <Text marginBottom={theme.pxToRem(16)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
          {t('TIME')}
        </Text>
        <Flex mb={theme.pxToRem(64)} flexDir="row" w="100%" justify="space-between">
          <TextInput
            label={t('START_TIME')}
            value={time}
            onChange={(e) => setTime(e.target.value)}
            type="time"
            mr={theme.pxToRem(12)}
          />

          <TextInput
            label={t('END_TIME')}
            value={finishHour}
            onChange={(e) => setFinishHour(e.target.value)}
            type="time"
            min={`${time}:00`}
            error={
              finishHour < time
                ? 'O horário do fim deve ser maior que o horário de início'
                : finishHour > '23:59'
                ? 'O horário final deve ser até 23:59'
                : null
            }
          />
        </Flex>
        <Button disabled={fitTypeButtonDisabled} w="100%" onClick={goBack}>
          {t('CONFIRM')}
        </Button>
      </Box>
    </Flex>
  )
}
