import { Box } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import {
  MinimumCancelled,
  MaximumSchedule,
  AvailableTimesConfig,
  InvoiceIssuer,
  MinimumScheduleHour,
  SignatureAppEnabled,
} from 'components/organisms'

export const ConfigurationContainer = ({ route }) => {
  function mapRouteToComponent(route) {
    const map = {
      'tempo-cancelamento': <MinimumCancelled />,
      'maximo-agendamento': <MaximumSchedule />,
      'horarios-disponiveis': <AvailableTimesConfig />,
      'nota-fiscal': <InvoiceIssuer />,
      'minimo-agendamento': <MinimumScheduleHour />,
      'clube-no-app': <SignatureAppEnabled />
    } 

    return map[route]
  }

  return (
    <>
      {route === 'sair' || route === 'deletar' ? (
        <></>
      ) : (
        <Box
          flex="1"
          p={pxToRem(32)}
          // maxW={pxToRem(680)}
          background="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={pxToRem(12)}
        >
          {mapRouteToComponent(route)}
        </Box>
      )}
    </>
  )
}
