import * as React from 'react'

function ArrowRightCircle(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="currentColor" />
      <path
        d="M5.99881 12C5.99881 12.1768 6.06905 12.3464 6.19407 12.4714C6.3191 12.5964 6.48867 12.6667 6.66548 12.6667L15.7228 12.6667L12.1935 16.1947C12.1315 16.2567 12.0823 16.3302 12.0488 16.4112C12.0152 16.4922 11.998 16.579 11.998 16.6667C11.998 16.7543 12.0152 16.8411 12.0488 16.9221C12.0823 17.0031 12.1315 17.0767 12.1935 17.1387C12.2555 17.2007 12.329 17.2498 12.41 17.2834C12.491 17.3169 12.5778 17.3342 12.6655 17.3342C12.7531 17.3342 12.8399 17.3169 12.9209 17.2834C13.0019 17.2498 13.0755 17.2007 13.1375 17.1387L17.8041 12.472C17.8662 12.4101 17.9155 12.3365 17.9491 12.2555C17.9827 12.1745 18 12.0877 18 12C18 11.9123 17.9827 11.8255 17.9491 11.7445C17.9155 11.6635 17.8662 11.5899 17.8041 11.528L13.1375 6.86134C13.0755 6.79936 13.0019 6.75019 12.9209 6.71664C12.8399 6.6831 12.7531 6.66583 12.6655 6.66583C12.5778 6.66583 12.491 6.6831 12.41 6.71664C12.329 6.75019 12.2555 6.79936 12.1935 6.86134C12.1315 6.92332 12.0823 6.99691 12.0488 7.0779C12.0152 7.15888 11.998 7.24568 11.998 7.33334C11.998 7.421 12.0152 7.5078 12.0488 7.58878C12.0823 7.66977 12.1315 7.74336 12.1935 7.80534L15.7228 11.3333L6.66548 11.3333C6.48867 11.3333 6.3191 11.4036 6.19407 11.5286C6.06905 11.6536 5.99881 11.8232 5.99881 12Z"
        fill="#FFFFFF"
      />
    </svg>
  )
}

export default ArrowRightCircle
