import { Text, Flex, Box } from 'components'
import { format, parseISO } from 'date-fns'
import { formatPrice } from 'utils'
export const settings = (data) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL_RECEIVED',
      value: data?.signatures_paid || 0,
      color: 'success',
      info: 'disabled',
      infoText:
        'Valor total bruto de agendamentos finalizados - descontos oferecidos',
    },
    {
      label: 'DISCOUNTS',
      description: 'TOTAL',
      value: data?.total_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos das assinaturas',
    },
    {
      label: 'COMISSIONS',
      description: 'DISCOUNTS',
      value: data?.total_comission_signature,
      color: 'primary',
      info: 'disabled',
      infoText: 'Total de descontos de comissões dos barbeiros',
    },
    {
      label: 'PAYMENTS',
      description: 'DISCOUNTS',
      value: data?.signatures_discount,
      color: 'primary',
      info: 'disabled',
      infoText: 'Total de descontos dos métodos de pagamentos',
    },
  ]
}

export const settingsMobile = (data) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL',
      value: data?.signatures_paid || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de agendamentos finalizados',
    },
    {
      label: 'DISCOUNTS',
      description: 'TOTAL',
      value: data?.appointment_payments_methods_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos dos métodos de pagamentos',
      last: true,
    },
  ]
}

export const columns = [
  {
    key: 'description',
    label: 'DESCRIÇÃO',
    render: (item) => <Text>{item?.title}</Text>,
  },
  {
    key: 'value',
    label: 'QUANTITY_TABLE',
    render: (item) => <Text>{formatPrice(item?.value)}</Text>,
  },
  {
    key: 'payment_date',
    label: 'DATA DE PAGAMENTO',
    render: (item) => (
      <Text>{item?.payment_simple_date ? format(parseISO(item?.payment_simple_date), 'dd/MM/yyyy') : format(parseISO(item?.payment_date), 'dd/MM/yyyy')}</Text>
    ),
  },
  {
    key: 'due_date',
    label: 'DATA DE VENCIMENTO',
    render: (item) => (
      <Text>{item?.due_simple_date ? format(parseISO(item?.due_simple_date), 'dd/MM/yyyy') : format(parseISO(item?.due_at_date), 'dd/MM/yyyy')}</Text>
    ),
  },
  {
    key: 'discounts',
    label: 'DISCOUNTS',
    render: (item) => <Text>{formatPrice(item?.discount)}</Text>,
  },
]
