import React, { useMemo, useState, useEffect } from 'react'
import { useTheme, useToast } from '@chakra-ui/react'
import { Box, Text, Button, Flex, Icon } from 'components/atoms'
import { ModalMenu, ModalAlert, Form } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import {
  initial,
  validation,
  validateData,
  flagPayment,
  functionPayment,
} from './settings'

import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import {
  BARBERSHOP_ADD_PAYMENT_METHOD,
  BARBERSHOP_UPDATE_PAYMENT_METHOD,
  BARBERSHOP_DELETE_PAYMENT_METHOD,
} from 'services/api/endpoints'
import { useBarbershopStore, useSingleSaleStore } from 'services/stores'
import SelectFunction from './SelectFunction'
import SelectFlag from './SelectFlag'
import { usePermissionCollaborator } from 'services/hooks'

export const PaymentMethodModal = ({ isModalOpen, onClose, selected }) => {
  const theme = useTheme()
  const toast = useToast()
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const { t } = useTranslation()
  const [select, setSelect] = useState(
    selected ? selected.discount_from_barber : true
  )
  const [values, getValues] = useState({ params: initial, isValid: false })
  const [functionCard, setFunctionCard] = useState()
  const { userData } = useBarbershopStore()
  const { products, setProducts, nullProducts, setProductsFull } =
    useSingleSaleStore()
  const [selectFunctionOpen, setSelectFunctionOpen] = useState(false)
  const [selectFlagOpen, setSelectFlagOpen] = useState(false)
  const [name, setName] = useState()
  const permission = usePermissionCollaborator('payment_methods')

  useEffect(() => {
    getValues({
      params: {
        percentage: selected ? selected.percentage : '',
        due_in_days: selected ? selected.due_in_days : '',
        discount_from_barber: selected ? selected.discount_from_barber : select,
        barbershop_id: userData?.barbershop?.id || userData?.id,
        name: selected && selected?.type === 'Outro' && selected?.name,
      },
    })
    if (selected && selected?.type) {
      const func = functionPayment?.filter(
        (item) => item?.name === selected?.type
      )
      setFunctionCard(...func)

      if (selected?.type === 'Pix' || selected?.type === 'Dinheiro') {
        const flag = flagPayment.filter((item) => item?.name === selected?.type)
        setProducts(...flag)
      } else {
        const string = selected?.name?.split(' - ')
        const finalArr = string?.[1]?.split(', ')
        const newArr = finalArr?.map((item) => {
          if (item !== 'Débito' && item !== 'Crédito') {
            return flagPayment?.find((i) => i?.name === item)
          }
        })
        setProductsFull(newArr)
      }
    }
  }, [selected])

  async function changePaymentMethod(params) {
    const res = await api[selected ? 'put' : 'post'](
      selected
        ? BARBERSHOP_UPDATE_PAYMENT_METHOD(selected?.id)
        : BARBERSHOP_ADD_PAYMENT_METHOD,
      params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  const mutate = useMutation(
    'change-payment-method',
    (params) => changePaymentMethod(params),
    {
      onSuccess: async (value) => {
        toast({
          title: t('SAVE_PAYMENT_METHOD'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        nullProducts()
        setFunctionCard(null)
        onClose()
      },
      onError: (error) => {
        setFunctionCard(null)
        nullProducts()
        toast({
          title: t('SAVE_PAYMENT_METHOD_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  async function deleteService() {
    const res = await api
      .delete(BARBERSHOP_DELETE_PAYMENT_METHOD(selected.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation('delete-service', () => deleteService(), {
    onSuccess: async (value) => {
      toast({
        title: t('SERVICE_DELETED'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClose()
      setFunctionCard(null)
      nullProducts()
      setAlertModalOpen(false)
    },
    onError: (error) => {
      toast({
        title: t('ERROR_EXCLUDING_SERVICE'),
        description: error.toString().substring(7),
        status: 'danger',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const isButtonDisabled = useMemo(
    () =>
      values?.params?.percentage === '' || values?.params?.due_in_days === ''
  )

  const flagName = () => {
    if (functionCard?.name === 'Pix') {
      setName('Pix')
      return
    }
    if (functionCard?.name === 'Outro') {
      setName('Outro')
      return
    }
    if (functionCard?.name === 'Dinheiro') {
      setName('Dinheiro')
      return
    }
    if (products?.length > 2) {
      setName(`${products[0]?.name}, ${products[1]?.name}...`)
      return
    }
    if (products?.length === 2) {
      setName(`${products[0]?.name}, ${products[1]?.name}`)
      return
    }
    if (products?.length === 1) {
      setName(products[0]?.name)
      return
    } else {
      setName(t('SELECT'))
      return
    }
  }

  useEffect(() => {
    flagName()
  }, [products, functionCard])

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={() => {
        onClose()
        setFunctionCard(null)
        nullProducts()
      }}
      title={selected ? t('UPDATE_PAYMENT_METHOD') : t('CREATE_PAYMENT_METHOD')}
    >
      <Box marginBottom={theme.pxToRem(36)}>
        <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
          {t('FUNCTION')}
        </Text>
        <Flex
          onClick={() => setSelectFunctionOpen(true)}
          h={theme.pxToRem(56)}
          bg="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={theme.pxToRem(8)}
          mb={theme.pxToRem(12)}
          w="100%"
          p={theme.pxToRem(16)}
          justify="space-between"
          align="center"
          cursor="pointer"
        >
          {!functionCard ? (
            <Text fontSize={theme.pxToRem(14)}>{t('SELECT')}</Text>
          ) : (
            <Text>{functionCard?.name}</Text>
          )}
          <Icon name="ChevronRight" />
        </Flex>
        <Text fontSize={14} kind="medium" mb={theme.pxToRem(8)}>
          {t('FLAG')}
        </Text>
        <Flex
          onClick={() =>
            functionCard?.name === 'Pix' ||
            functionCard?.name === 'Dinheiro' ||
            functionCard?.name === 'Outro' ||
            !functionCard
              ? null
              : setSelectFlagOpen(true)
          }
          opacity={
            functionCard?.name === 'Pix' ||
            functionCard?.name === 'Dinheiro' ||
            functionCard?.name === 'Outro' ||
            !functionCard
              ? 0.5
              : 1
          }
          h={theme.pxToRem(56)}
          bg="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={theme.pxToRem(8)}
          mb={theme.pxToRem(12)}
          w="100%"
          p={theme.pxToRem(16)}
          justify="space-between"
          align="center"
          cursor={
            functionCard?.name !== 'Pix' &&
            functionCard?.name !== 'Dinheiro' &&
            functionCard &&
            'pointer'
          }
        >
          {!functionCard ? (
            <Text fontSize={theme.pxToRem(14)}>{t('SELECT')}</Text>
          ) : (
            <Text>{name}</Text>
          )}
          <Icon name="ChevronRight" />
        </Flex>
        <Form
          initialValues={values?.params}
          validationSchema={validation}
          data={validateData(functionCard?.name)}
          getValues={getValues}
          mb={32}
          readOnly={permission !== 'edit'}
        />
      </Box>
      <Text
        fontSize={theme.pxToRem(14)}
        fontWeight="800"
        marginBottom={theme.pxToRem(10)}
      >
        {t('DISCOUNT_BARBER_TITLE')}
      </Text>
      <Text
        fontSize={theme.pxToRem(12)}
        marginBottom={theme.pxToRem(16)}
        color="textGrey"
        fontWeight="600"
      >
        {t('DISCOUNT_BARBER_SUBTITLE')}
      </Text>
      <Flex alignItems="center">
        <Button
          flex="1"
          mr={theme.pxToRem(8)}
          h={theme.pxToRem(52)}
          kind={select === true ? 'primary' : 'outline'}
          onClick={() => {
            setSelect(true)
          }}
          isDisabled={permission !== 'edit'}
        >
          {t('YES')}
        </Button>
        <Button
          flex="1"
          ml={theme.pxToRem(8)}
          onClick={() => {
            setSelect(false)
          }}
          kind={select === false ? 'primary' : 'outline'}
          isDisabled={permission !== 'edit'}
        >
          {t('NO')}
        </Button>
      </Flex>
      <Flex alignItems="flex-end" h="20vh">
        {selected && permission === 'edit' ? (
          <>
            <Button
              flex="1"
              mr={theme.pxToRem(8)}
              isLoading={mutateDelete.isLoading}
              h={theme.pxToRem(52)}
              disabled={isButtonDisabled || mutateDelete.isLoading}
              kind="danger"
              onClick={() => setAlertModalOpen(true)}
            >
              {t('DELETE')}
            </Button>
            <Button
              flex="1"
              ml={theme.pxToRem(8)}
              onClick={() => {
                const params = {
                  percentage: values?.params?.percentage,
                  due_in_days: values?.params?.due_in_days,
                  discount_from_barber: select,
                  barbershop_id: userData?.barbershop?.id || userData?.id,
                  type: functionCard?.name,
                }
                if (
                  functionCard?.name === 'Débito' ||
                  functionCard?.name === 'Crédito'
                ) {
                  const newArr = products?.map((item) => item?.name)
                  params.name = `${functionCard?.name} - ${newArr?.join(', ')}`
                } else if (functionCard?.name === 'Outro') {
                  params.name = values?.params?.name
                } else {
                  params.name = functionCard?.name
                }
                mutate.mutate(params)
              }}
              isLoading={mutate.isLoading}
              disabled={isButtonDisabled || mutate.isLoading}
            >
              {t('UPDATE')}
            </Button>
          </>
        ) : (
          <Button
            isLoading={mutate.isLoading}
            disabled={isButtonDisabled || mutate.isLoading}
            w="100%"
            onClick={() => {
              const params = {
                percentage: values?.params?.percentage,
                due_in_days: values?.params?.due_in_days,
                discount_from_barber: select,
                barbershop_id: userData?.barbershop?.id || userData?.id,
                type: functionCard?.name,
              }
              if (
                functionCard?.name === 'Débito' ||
                functionCard?.name === 'Crédito'
              ) {
                const newArr = products?.map((item) => item?.name)
                params.name = `${functionCard?.name} - ${newArr?.join(', ')}`
              } else if (functionCard?.name === 'Outro') {
                params.name = values?.params?.name
              } else {
                params.name = functionCard?.name
              }
              mutate.mutate(params)
            }}
          >
            {t('SAVE')}
          </Button>
        )}
      </Flex>

      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        onConfirm={() => mutateDelete.mutate()}
        primaryButtonLabel={t('DELETE')}
        modalTitle={t('DELETE_PAYMENT_METHOD')}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CONFIRM_DELETE_PAYMENT_METHOD')}?
        </Text>
      </ModalAlert>

      <SelectFunction
        isOpen={selectFunctionOpen}
        onClose={() => setSelectFunctionOpen(false)}
        setFunction={setFunctionCard}
      />
      <SelectFlag
        isOpen={selectFlagOpen}
        onClose={() => setSelectFlagOpen(false)}
        setProducts={setProducts}
      />
    </ModalMenu>
  )
}
