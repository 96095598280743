import React from 'react'
import { Text } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'
import { ChevronRight } from 'assets/icons'


export const SignaturePlanCard = ({ item, onClick }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isMobile } = useBreakpoint()
  return (
    <Box
      display="flex"
      flexDir="row"
      w={isMobile ? '90vw' : '100%'}
      h={theme.pxToRem(56)}
      borderRadius={theme.pxToRem(10)}
      backgroundColor="background"
      alignItems="center"
      justifyContent="space-between"
      px={theme.pxToRem(24)}
      mt={theme.pxToRem(24)}
      onClick={onClick}
      cursor={onClick && 'pointer'}
    >
      <Flex
        w="100%"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Flex flexDir="column" w="100%">
          <Text w="100%" fontSize={theme.pxToRem(14)}>
            {t('EXPIRED_AT')}
          </Text>
          <Text w="100%" fontSize={theme.pxToRem(14)} kind="bold">
            {item?.due_at_date
              ? format(parseISO(item?.due_at_date), 'dd/MM/yyyy')
              : t('PENDING')}
          </Text>
        </Flex>

        <Text
          w="30%"
          fontSize={theme.pxToRem(14)}
          color={
            item?.status === 'CONFIRMED' || item?.status === 'PAID'
              ? 'success'
              : differenceInDays(parseISO(item?.due_at_date), new Date()) >= 0
              ? 'primary'
              : 'error'
          }
          kind="bold"
          mr='16px'
        >
          {item?.status === 'CONFIRMED' || item?.status === 'PAID'
            ? t('PAID')
            : differenceInDays(parseISO(item?.due_at_date), new Date()) >= 0
            ? t('AWAITING')
            : t(item?.status)}
        </Text>
        {onClick && (
          <ChevronRight
          ml='12px'
          color={theme.colors.primary}
          style={{ fontWeigth: 'bold' }}
        />
        )}
      </Flex>
    </Box>
  )
}
