import { Text, Flex, Box } from 'components'
import { formatPrice } from 'utils'
export const settings = (data) => {
  return [
    {
      label: 'TOTAL',
      description: 'Faturamento líquido',
      value: data?.signature_paid_liquid_value || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total líquido de faturamento de assinaturas',
    },
    {
      label: 'TOTAL', 
      description: 'Faturamento bruto',
      value: data?.signature_paid_gross_value,
      color: 'danger',
      info: 'disabled',
      infoText: 'Valor total líquido de faturamento de assinaturas',
    },
    {
      label: 'TOTAL',
      description: 'DISCOUNTS',
      value: data?.signature_paid_discounts,
      color: 'primary',
      info: 'disabled',
      infoText: 'Total de descontos em taxas',
    },
  ]
}

export const settingsMobile = (data) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL',
      value: data?.signatures_paid || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de agendamentos finalizados',
    },
    {
      label: 'DISCOUNTS',
      description: 'TOTAL',
      value: data?.appointment_payments_methods_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos dos métodos de pagamentos',
      last: true,
    },
  ]
}

export const columns = (cellColors) => [
  {
    key: 'service_name',
    label: 'SERVICE_TABLE',
    render: (item, index) => {
      return (
        <Flex flexDir="row" align="center">
          <Text>{item?.service_name}</Text>
          <Box ml="8px" h="8px" w="8px" bgColor={cellColors[index % cellColors?.length]} borderRadius={100} />
        </Flex>
      )
    },
  },
  {
    key: 'new_subscribers',
    label: 'Nº DE NOVOS ASSINANTES',
    render: (item) => <Text>{item?.new_subscribers}</Text>,
  },
  {
    key: 'payments_confirmed',
    label: 'Nº DE PAGAMENTOS REALIZADOS',
    render: (item) => <Text>{item?.active_quantity}</Text>,
  },
  {
    key: 'payments_pending',
    label: 'Nº DE PAGAMENTOS VENCIDOS',
    render: (item) => <Text>{item?.inactive_quantity}</Text>,
  },
  {
    key: 'payment_total',
    label: 'VALOR TOTAL DOS PAGAMENTOS',
    render: (item) => <Text>{formatPrice(item?.value)}</Text>,
  },
]
