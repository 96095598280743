import React, { useState, useMemo } from 'react'
import { useLoadScript } from '@react-google-maps/api'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { Box, Text, TextInput } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { theme } from 'styles/theme'
import { Search } from 'assets/icons'

export const GooglePlacesInput = ({
  onSelectAddress,
  getStyledSelectBox,
  getStyledOption,
  fontSize,
  fontColor,
}) => {
  const [address, setAddress] = useState('')
  const { t } = useTranslation()

  const googleMapsConfig = useMemo(() => {
    return {
      id: 'google-map-script',
      libraries: ['places'],
      apiKey: 'AIzaSyAJmq1XnF5hTb_-hk-RlZ6s6XCk_M2A-H0',
    }
  }, [])

  const { isLoaded, loadError } = useLoadScript({
    id: googleMapsConfig.id,
    libraries: googleMapsConfig.libraries,
    googleMapsApiKey: googleMapsConfig.apiKey,
  })

  const handleSelect = async (value) => {
    setAddress(value)
    const results = await geocodeByAddress(value)
    const selectedResult = results?.[0]
    const { lat: latitude, lng: longitude } = await getLatLng(selectedResult)
    const addressComponents = selectedResult?.address_components || []

    let completeAddress = {
      zip_code: '',
      state: '',
      city: '',
      street: '',
      street_number: '',
      neighborhood: '',
      country: '',
      latitude,
      longitude,
    }

    addressComponents.forEach((addressComponent) => {
      const addressChunk = handleWithGoogleInput(addressComponent)

      completeAddress = {
        ...completeAddress,
        ...addressChunk,
      }
    })

    onSelectAddress(completeAddress)
  }

  const handleWithGoogleInput = ({ types, long_name }) => {
    const typesStr = types.join()

    switch (true) {
      case /country/i.test(typesStr):
        return { country: long_name }
      case /administrative_area_level_1/i.test(typesStr):
        return { state: long_name }
      case /administrative_area_level_2/i.test(typesStr):
        return { city: long_name }
      case /postal_code/i.test(typesStr):
        return { zip_code: long_name }
      case /sublocality_level_1/i.test(typesStr) ||
        /sublocality_level_2/i.test(typesStr):
        return { neighborhood: long_name }
      case /route/i.test(typesStr) || /sublocality_level_3/i.test(typesStr):
        return { street: long_name }
      case /street_number/i.test(typesStr) || /premise/i.test(typesStr):
        return { street_number: long_name }
      case /subpremise/i.test(typesStr):
        return { complement: long_name }
      default:
        break
    }
  }

  return (
    isLoaded && (
      <PlacesAutocomplete
        debounce={600}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <Box position="relative">
            <Text
              kind="medium"
              fontSize={theme.pxToRem(fontSize || 12)}
              color={fontColor && fontColor}
              mb={theme.pxToRem(8)}
            >
              {t('SEARCH_ADDRESS')}
            </Text>
            <TextInput
              noBorder
              pl={theme.pxToRem(56)}
              type="string"
              value={address}
              autocomplete="chrome-off"
              {...getInputProps({ placeholder: t('SEARCH_ADDRESS') })}
              leftElement={<Search color={theme.colors.primary} />}
            />

            <Box style={getStyledSelectBox(suggestions.length > 0 || loading)}>
              {loading && (
                <Box>
                  <Text>{t('LOADING')}</Text>
                </Box>
              )}

              {loadError && (
                <Box>
                  <Text>{t('GOOGLE_MAPS_ERROR')}</Text>
                </Box>
              )}

              {!loading &&
                suggestions.map((suggestion) => {
                  return (
                    <Box
                      key={suggestion.placeId}
                      {...getSuggestionItemProps(suggestion, {
                        style: getStyledOption(suggestion.active),
                      })}
                    >
                      <Text>{suggestion.description}</Text>
                    </Box>
                  )
                })}
            </Box>
          </Box>
        )}
      </PlacesAutocomplete>
    )
  )
}
