import * as React from 'react'

function Reload(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.642 2.89C11.9963 1.9944 11.1482 1.26546 10.1674 0.76306C9.18668 0.260664 8.10126 -0.000849726 7.00033 2.07419e-06C3.39828 2.07419e-06 0.436069 2.74333 0.0690277 6.264L0 6.92667L1.32082 7.066L1.38918 6.40267C1.51297 5.22939 1.99824 4.12418 2.7773 3.24117C3.55637 2.35816 4.59037 1.74139 5.73511 1.47687C6.87985 1.21236 8.07824 1.31329 9.16314 1.76559C10.248 2.21789 11.1654 2.99901 11.7871 4H9.32338V5.33333H13.9695V0.666669H12.642V2.89ZM12.6798 6.934L12.6108 7.59733C12.487 8.77061 12.0018 9.87582 11.2227 10.7588C10.4436 11.6418 9.40963 12.2586 8.26489 12.5231C7.12015 12.7876 5.92176 12.6867 4.83686 12.2344C3.75196 11.7821 2.83465 11.001 2.21287 10H4.67729V8.66667H0.0311952V13.3333H1.35865V11.11C2.0044 12.0056 2.85249 12.7345 3.83323 13.2369C4.81398 13.7393 5.8994 14.0009 7.00033 14C10.6017 14 13.5646 11.2567 13.931 7.736L14 7.07333L12.6798 6.934Z"
        fill="#E5B817"
      />
    </svg>
  )
}

export default Reload
