export const APPOINTMENT_STEPS = {
  initialStep: 'NEW_APPOINTMENTS',
  editAppointment: 'EDIT_APPOINTMENT',
  selectCustomer: 'SELECT_CUSTOMER',
  selectBarber: 'SELECT_BARBER',
  selectService: 'SELECT_SERVICE',
  selectDateAndTime: 'DATE',
  selectDiscount: 'SELECT_DISCOUNT',
  selectPaymentMethod: 'SELECT_PAYMENT_METHOD',
  editInvoice: 'EDIT_INVOICE',
}

export const renderButton = [
  {
    text: 'Mensal',
    type: 'month',
  },
  {
    text: 'Quinzenal',
    type: 'biweekly',
  },
  {
    text: 'Semanal',
    type: 'week',
  },
]
