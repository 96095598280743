import * as React from 'react'

function SvgChip(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.999 3.273v17.454c0 1.808-1.55 3.273-3.461 3.273H3.46C1.55 24 0 22.535 0 20.727V3.273C0 1.465 1.55 0 3.461 0h23.077c1.911 0 3.461 1.465 3.461 3.273z"
        fill="#E5B817"
      />
      <path
        d="M16.153 24V0h-2.308v24h2.308zM20.77 7.635c0-.602.516-1.09 1.153-1.09h6.923c.637 0 1.154.488 1.154 1.09 0 .603-.517 1.091-1.154 1.091h-6.923c-.637 0-1.154-.488-1.154-1.09z"
        fill="#B38F12"
      />
      <path
        d="M13.846 12.001c0-.602.517-1.09 1.154-1.09h6.923c.637 0 1.154.488 1.154 1.09 0 .603-.517 1.091-1.154 1.091H15c-.637 0-1.154-.488-1.154-1.09zM20.77 16.364c0-.603.516-1.091 1.153-1.091h6.923c.637 0 1.154.488 1.154 1.09 0 .603-.517 1.092-1.154 1.092h-6.923c-.637 0-1.154-.489-1.154-1.091zM0 12.001c0-.602.517-1.09 1.154-1.09h6.923c.637 0 1.153.488 1.153 1.09 0 .603-.516 1.091-1.153 1.091H1.154c-.637 0-1.154-.488-1.154-1.09zM6.924 7.636c0-.603.516-1.091 1.154-1.091H15c.638 0 1.154.488 1.154 1.09 0 .603-.516 1.092-1.154 1.092H8.078c-.638 0-1.154-.489-1.154-1.091zM6.924 16.364c0-.602.516-1.09 1.154-1.09H15c.638 0 1.154.488 1.154 1.09 0 .603-.516 1.091-1.154 1.091H8.078c-.638 0-1.154-.488-1.154-1.09z"
        fill="#B38F12"
      />
    </svg>
  )
}

export default SvgChip
