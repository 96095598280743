import * as React from 'react'

function SvgBarbers(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.87385 16.7629C9.38392 16.6221 9.91182 16.5 10.333 16.5C11.2193 16.5 11.856 16.9242 12.2442 17.4822C12.6107 18.0089 12.7497 18.638 12.7497 19.1371C12.7497 20.6157 11.843 21.5412 10.8221 21.7579C6.28314 22.7216 3.75358 20.5795 3.28022 18.9715C3.13879 18.4911 3.4989 18.0097 3.99969 18.0097C4.74961 18.0097 5.90382 17.6778 7.14698 17.289C7.25422 17.2555 7.36185 17.2217 7.46934 17.1879C7.9565 17.0347 8.44082 16.8825 8.87385 16.7629ZM5.28152 19.3563C6.06381 20.1021 7.69205 20.889 10.5106 20.2906C10.8231 20.2243 11.2497 19.9232 11.2497 19.1371C11.2497 18.8814 11.1717 18.567 11.0129 18.3388C10.8759 18.1419 10.6793 18 10.333 18C10.1379 18 9.78825 18.0666 9.27305 18.2088C8.868 18.3207 8.41556 18.4629 7.92957 18.6156C7.81959 18.6502 7.70789 18.6853 7.59464 18.7207C6.86327 18.9494 6.04401 19.2002 5.28152 19.3563Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0287 16.7697C14.5801 16.6272 14.1007 16.5 13.6671 16.5C12.7808 16.5 12.1441 16.9242 11.7559 17.4822C11.3894 18.0089 11.2504 18.638 11.2504 19.1371C11.2504 20.6157 12.1571 21.5412 13.178 21.7579C17.717 22.7216 20.2465 20.5795 20.7199 18.9715C20.861 18.4922 20.5028 18.0115 20.0031 18.0097C18.7996 18.0053 17.6221 17.6635 16.5395 17.2918C16.3498 17.2267 16.1571 17.1586 15.9668 17.0913C15.6371 16.9746 15.3149 16.8606 15.0287 16.7697ZM18.671 19.4008C17.8712 20.1328 16.2521 20.8771 13.4895 20.2906C13.177 20.2243 12.7504 19.9232 12.7504 19.1371C12.7504 18.8814 12.8284 18.567 12.9872 18.3388C13.1242 18.1419 13.3208 18 13.6671 18C13.8497 18 14.1368 18.0603 14.5746 18.1993C14.8416 18.2841 15.1199 18.3827 15.4286 18.4921C15.6229 18.5609 15.8293 18.634 16.0524 18.7106C16.8186 18.9736 17.7105 19.2519 18.671 19.4008Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.82152 5.40948C5.59155 5.78962 5.73107 6.27454 6.10899 6.50813C7.68241 7.4807 10.2479 8.26838 13.6264 7.25483C16.1698 6.49183 17.812 6.2 19.125 6.2C19.2121 6.2 19.2975 6.20133 19.3813 6.20388C17.6349 4.30941 14.9174 3.3 12.5 3.3C10.3988 3.3 8.76338 3.43491 7.55556 3.9469C6.84503 4.2481 6.25672 4.6901 5.82152 5.40948ZM4.86126 7.2332C4.29453 6.81803 4.08023 6.07153 4.35716 5.42589C5.65198 2.40715 8.70563 2 12.5 2C15.6042 2 19.3031 3.487 21.2248 6.461C21.4492 6.80823 21.6493 7.17575 21.8215 7.56353C21.9024 7.74593 21.7363 7.93299 21.5432 7.88273C21.1502 7.78044 20.7838 7.68509 20.3979 7.61515C19.0895 7.37799 17.5568 7.43295 14 8.5C9.80721 9.75784 6.65003 8.54364 4.86126 7.2332Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5788 9.36679C20.063 9.31674 19.6904 9.80994 19.7703 10.3219C20.0238 11.9453 19.8669 13.7104 19.4726 15.3114C19.3736 15.7136 19.5969 16.1313 19.9936 16.2503C20.3904 16.3693 20.8101 16.1446 20.9109 15.7428C21.3595 13.9549 21.5585 11.8998 21.2315 9.96033C21.1775 9.63994 20.9022 9.39818 20.5788 9.36679Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25039 9.06626C3.37358 8.50573 4.00762 8.32537 4.41069 8.73392C4.61047 8.93641 4.73742 9.26961 4.6819 9.5486C4.31985 11.3678 4.53384 13.4452 5.01137 15.3123C5.11401 15.7136 4.89048 16.1313 4.49374 16.2504C4.09701 16.3694 3.67744 16.1447 3.57357 15.7437C3.04066 13.6864 2.76828 11.26 3.25039 9.06626Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}

export default SvgBarbers
