import React, { useEffect, useState } from 'react'
import { format, startOfMonth, endOfMonth, differenceInHours, parseISO } from 'date-fns'
import { Layout, Loader, FeatureBlockedMessage, TextInput, AppointmentDetailsModal } from 'components'
import { useToast, Flex, useTheme } from '@chakra-ui/react'
import { useBreakpoint, usePremiumBlock, useBarbershop } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore, useModalStore, useAppointmentDetailStore } from 'services/stores'
import { api } from 'services/api/config'
import { useLocation } from 'react-router-dom'
import { BARBERSHOP_MONTH_RESUME, GET_APPOINTMENTS_BY_DATE } from 'services/api/endpoints'
import { useQuery } from 'react-query'
import { RenderDesktop } from './desktop'
import { RenderMobile } from './mobile'
import useDateReviewed from 'services/hooks/use-date-review'
import { resumeReport } from 'utils/mockData'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const HomeScreen = ({ ...props }) => {
  const location = useLocation()
  const { setCurrentModal } = useModalStore()
  const { userData, subscription } = useBarbershopStore()
  const { getAdditionalProducts } = useBarbershop()
  const { isDesktop } = useBreakpoint()
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const theme = useTheme()
  const toast = useToast()
  const isBlocked = usePremiumBlock()
  const { t } = useTranslation()
  const { showReviewedModal, onBabershopUpdatedAddressReview, onBabershopUpdatedDataReview } = useDateReviewed()
  const { setMissingAdditionalProducts } = additionalProductsStore()
  const [app, setApp] = useState()

  const { data: products } = useQuery('get-additional-products', () => getAdditionalProducts({ id: userData?.id }))

  useEffect(() => {
    const filter = products?.filter((i) => i?.quantity < i?.minimum_quantity)
    const lastSeen = localStorage.getItem('missingProduct')
    if (filter?.length > 0 && differenceInHours(parseISO(lastSeen), new Date()) > 24) {
      localStorage.setItem('missingProduct', format(new Date(), 'yyyy-MM-dd HH:mm:ss'))
      setMissingAdditionalProducts(filter)
      setCurrentModal('missingProductModal')
    }
  }, [products])

  async function getHomeResume({ queryKey }) {
    const params = {
      id: queryKey[1]?.id,
      start_date: queryKey[2],
      end_date: queryKey[3],
    }
    const res = await api
      .post(BARBERSHOP_MONTH_RESUME(params))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading } = useQuery(['get-home-resume', userData, startDate, endDate], getHomeResume, {
    onSuccess: (value) => {},
    onError: (error) => {
      toast({
        title: t('LOAD_COMMISSIONS_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const onCloseModals = () => {
    refetch()
    setDetailsModalVisible(false)
    setApp(null)
  }

  async function getAppointments() {
    const res = await api
      .get(GET_APPOINTMENTS_BY_DATE(format(new Date(), 'yyyy-MM-dd'), false, userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: appointments, refetch } = useQuery('get-appointments', getAppointments, {
    onSuccess: (value) => {},
    onError: (error) => {
      toast({
        title: t('APPOINTMENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  // useEffect(() => {
  //   onBabershopUpdatedAddressReview()
  //   onBabershopUpdatedDataReview()
  // }, [])

  const checkModal = () => {
    if (location.search.includes('register')) {
      setCurrentModal('barbershopIntroModal')
      return
    }
    if (showReviewedModal.babershopUpdatedData) {
      setCurrentModal('refreshDataModal')
      return
    }
    if (showReviewedModal.babershopUpdatedAddress) {
      setCurrentModal('refreshAddressModal')
      return
    }
    if (location.search.includes('subscribe') && !userData?.iugu_subscription_id) {
      setCurrentModal('freeTrialModal')
      return
    }
    setCurrentModal(null)
  }

  useEffect(() => {
    checkModal()
  }, [location, showReviewedModal])

  const RenderCenterElement = () => (
    <Flex w="100%">
      <TextInput
        noBorder
        type="date"
        name="start_date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        label={t('START')}
        padding={theme.pxToRem(10)}
        mr="6%"
        width={'98%'}
      />
      <TextInput
        noBorder
        type="date"
        name="end_date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        label={t('END')}
        padding="5%"
        ml="5%"
        width={'198%'}
      />
    </Flex>
  )

  const renderChildren = () => {
    if (!data || isLoading) {
      return (
        <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
          <Loader />
        </Flex>
      )
    }

    if (isDesktop) {
      return (
        <>
          {isBlocked && <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="Home" />}

          <RenderDesktop
            data={isBlocked ? resumeReport : data}
            startDate={startDate}
            endDate={endDate}
            appointments={isBlocked ? resumeReport.all_appointments : appointments}
            setDetailsModalVisible={setDetailsModalVisible}
            setSelectedAppointment={setApp}
            isBlocked={isBlocked}
          />
        </>
      )
    }

    return (
      <>
        {isBlocked && <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="Home" />}

        <RenderMobile
          data={isBlocked ? resumeReport : data}
          startDate={startDate}
          endDate={endDate}
          appointments={isBlocked ? resumeReport.all_appointments : appointments}
          setDetailsModalVisible={setDetailsModalVisible}
          setSelectedAppointment={setApp}
          isBlocked={isBlocked}
        />
      </>
    )
  }

  useEffect(() => {
    renderChildren()
  }, [data, isLoading, isBlocked])

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Home"
      widthTitle="100%"
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      headerCenterElement={() => !isBlocked && <RenderCenterElement />}
    >
      {renderChildren()}

      {detailsModalVisible && (
        <AppointmentDetailsModal
          isModalOpen={detailsModalVisible}
          onClose={onCloseModals}
          setAppointmentId={setApp}
          app={app}
        />
      )}
    </Layout>
  )
}

HomeScreen.path = '/home'
HomeScreen.title = 'Home'
HomeScreen.secured = true
