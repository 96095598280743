import React from 'react'
import { useBreakpoint } from 'services/hooks'
import { Box, Text } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { theme } from 'styles'

export const WeekdayCard = (props) => {
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const cardColors = {
    default: {
      backgroundColor: props.selected ? colors.primary : colors.cardBackground,
      border: props.selected ? colors.primary : colors.modalBorder,
      text: props.selected ? colors.black : colors.white,
    },
    disabled: {
      backgroundColor: colors.cardBackground,
      border: props.selected ? colors.primary : colors.transparent[100],
      text: props.selected ? colors.primary : colors.whiteTransparent[300],
    },
  }

  const borderWidth = props.selected ? pxToRem(2) : pxToRem(1)
  const backgroundColor = cardColors[props.status || 'default'].backgroundColor
  const textColor = cardColors[props.status || 'default'].text
  const borderColor = cardColors[props.status || 'default'].border

  return (
    <Box
      cursor={props.cursor || 'pointer'}
      display={props.display || 'flex'}
      alignItems={props.alignItems || 'center'}
      justifyContent={props.justifyContent || 'center'}
      backgroundColor={props.backgroundColor || backgroundColor}
      border={props.border || `1px solid ${borderColor}`}
      borderWidth={props.borderWidth || borderWidth}
      borderRadius={theme.pxToRem(props.borderRadius || 8)}
      width={props.width || props.w || pxToRem(isDesktop ? 64 : 34)}
      height={props.height || props.h || pxToRem(isDesktop ? 104 : 70)}
      {...props}
    >
      <Text
        fontSize={props.fontSize || pxToRem(isDesktop ? 16 : 11)}
        kind="extraBold"
        color={props.color || textColor}
      >
        {t(props.children)}
      </Text>
    </Box>
  )
}
