import React from 'react'
import { Box as ChakraBox } from '@chakra-ui/react'

export const Box = (props) => {
  return (
    <ChakraBox {...props}>
      { props.children }
    </ChakraBox>
  )
}

Box.defaultProps = {
}

Box.propTypes = {
  ...Box.propTypes,
}
