import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTheme, Flex, Input, Spinner } from '@chakra-ui/react'
import { Text } from '../Text'
import { Icon } from 'components/atoms/Icon'

export const FileInput = ({
  label,
  type,
  onChange,
  onClear,
  defaultImage,
  canDelete = true,
  canOverwrite = true,
  loading,
  disabled,
  ...props
}) => {
  const theme = useTheme()
  const ref = useRef(null)

  const [file, fileSet] = useState({})
  const [dataURL, dataURLSet] = useState('')
  const [hasImage, hasImageSet] = useState(!!defaultImage)

  const handleFileSelect = (event) => {
    if (event?.target?.files[0]) {
      fileSet(event?.target?.files[0])

      if (typeof onChange === 'function') {
        onChange(event?.target?.files[0])
      }
    }
  }
  const handleFileClear = () => {
    if (typeof onClear === 'function') {
      onClear(() => {
        fileSet({})
        dataURLSet('')

        if (ref?.current) {
          ref.current.value = null
          ref.current.files = null
        }
      })
    }
  }

  useEffect(() => {
    const File = new FileReader()

    const convertImageToBase64 = (event) => {
      dataURLSet(event?.target?.result)
    }

    File.addEventListener('load', convertImageToBase64)

    if (file?.name) {
      File.readAsDataURL(file)
    }

    return () => {
      File.removeEventListener('load', convertImageToBase64)
    }
  }, [file])

  useEffect(() => {
    if (defaultImage) {
      hasImageSet(true)
    }
  }, [defaultImage])

  return (
    <Flex
      border="1px dashed"
      bg="cardBackground"
      borderColor={hasImage && defaultImage ? 'transparent' : 'brand.primaryLight'}
      borderRadius={theme.pxToRem(props?.br || 12)}
      bgImg={hasImage && defaultImage ? `${`url(${defaultImage})` || `url(${dataURL})`}` : 'transparent'}
      bgSize="cover"
      w={theme.pxToRem(props?.w || 96)}
      h={theme.pxToRem(props?.h || 96)}
      direction="column"
      align="center"
      justify="center"
      position="relative"
      {...props}
    >
      {loading && <Spinner color="white" />}
      {!defaultImage && !loading ? (
        <>
          <Icon size={24} name={type === 'logo' ? 'BarberShop' : 'Camera'} />
          <Text textAlign="center" kind="medium" fontSize={theme.pxToRem(12)} mt={theme.pxToRem(8)}>
            {label}
          </Text>
        </>
      ) : (
        canDelete && (
          <Flex
            align="center"
            justify="center"
            bg="background"
            borderRadius="100px"
            position="absolute"
            opacity={disabled ? 0.5 : 1}
            top="2"
            right="2"
            cursor={disabled ? 'auto' : 'pointer'}
            w={theme.pxToRem(24)}
            h={theme.pxToRem(24)}
            onClick={() => (disabled ? '' : handleFileClear())}
            zIndex={10}
            di
          >
            <Icon name="Close" color="white" size={12} />
          </Flex>
        )
      )}
      {canOverwrite && (
        <Input
          ref={ref}
          type="file"
          name="file"
          opacity="0"
          position="absolute"
          style={{ display: disabled ? 'none' : 'flex' }}
          left={0}
          right={0}
          w="100%"
          h="100%"
          cursor={disabled ? 'auto' : 'pointer'}
          onChange={handleFileSelect}
          multiple={false}
          accept="image/*"
          disabled={disabled}
        />
      )}
    </Flex>
  )
}

FileInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  defaultImage: PropTypes.string.isRequired,
  canDelete: PropTypes.bool,
  canOverwrite: PropTypes.bool,
  loading: PropTypes.bool,
}
