import React, { useState } from 'react'
import { Box, Button, Flex, Text, TextInput } from 'components/atoms'
import { ModalMenu, SelectCard } from 'components/molecules'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { useToast, Select, Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react'
import { unformatCurrency } from 'utils/formatCurrency'
import { useBarbershopGoals } from 'services/hooks/use-barber-goals'
import { useBarbershopStore } from 'services/stores'
import { useTeam } from 'services/hooks/use-team'
import { colors } from 'styles'
import { TableInputs } from '../../molecules'
import { tableInputColumns, tableInputAll, types } from './settings'
import { ModalButtonBackground } from 'components-v2/atoms'
import { format, parseISO } from 'date-fns'
import { useBarbershopGoalsV2 } from 'services/hooks/use-barber-goals-v2'

export const CreateGoalModalV2 = ({ isOpen, onClose }) => {
  const [collaborator, setCollaborator] = useState()
  const [type, setType] = useState({
    value: 'total_sales',
    signature_enabled: false,
    name: 'Total em vendas - Sem assinatura',
  })
  const [value, setValue] = useState()
  const [finish_date, setFinishDate] = useState()
  const [bonus_active, setBonusActive] = useState(false)
  const { userData } = useBarbershopStore()
  const { getAllCollaborators } = useTeam()
  const { createGoal, getProductsAndServicesByBarbershop } = useBarbershopGoalsV2()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [products, setProducts] = useState({
    change_all: false,
    change_all_value: 0,
    data: [],
  })
  const [services, setServices] = useState({
    change_all: false,
    change_all_value: 0,
    data: [],
  })

  const onCleanState = () => {
    setProducts({
      change_all: false,
      change_all_value: 0,
      data: [],
    })
    setServices({
      change_all: false,
      change_all_value: 0,
      data: [],
    })
    setCollaborator()
    setFinishDate()
    setBonusActive(false)
    setValue()
  }

  const { data: team } = useQuery('get-team', () => getAllCollaborators({ id: userData?.id }))

  const { refetch } = useQuery(
    ['get-products-by-barber-goals'],
    () =>
      getProductsAndServicesByBarbershop({
        barbershop_id: userData?.id,
      }),
    {
      // refetchOnWindowFocus: false,
      onSuccess: (d) => {
        setProducts({
          data: d?.products,
          change_all: products.change_all,
          change_all_value: products.change_all_value,
        })
        setServices({
          data: d?.services?.filter((i) => i?.type === 'normal'),
          change_all: services.change_all,
          change_all_value: services.change_all_value,
        })
      },
    }
  )

  const mutateCreate = useMutation(
    'create-goal',
    (params) =>
      createGoal({
        body: params,
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Meta cadastrada com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        queryClient.invalidateQueries(['get-all-goals'])
        onClose()
        onCleanState()
      },
      onError: () => {
        toast({
          title: 'Erro ao cadastrar meta',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleSelectBarbers = (e) => {
    const [barber] = team.filter((item) => e.target.value?.replace(' ', '') === item?.name?.replace(' ', ''))
    setCollaborator(barber)
  }

  const handleSelectType = (e) => {
    const type = types.find((item) => e.target.value === item?.name)
    setType(type)
  }

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onCleanState()
        onClose()
      }}
      title="Adicionar nova meta"
      size="lg"
    >
      <Box w="100%">
        <Text fontSize={16} kind="semiBold" mb="12px">
          Meta de faturamento Mensal
        </Text>
        <Text fontSize={14} kind="medium" color="textGrey" mb="12px">
          Para quem é esta meta:
        </Text>

        <Select
          name="barber"
          placeholder="Todos os colaboradores"
          w="100%"
          h={14}
          fontFamily="Montserrat semiBold"
          fontSize={14}
          textColor="textLight"
          iconColor="primary"
          backgroundColor="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={8}
          value={collaborator?.name}
          mb={2}
          errorBorderColor="baseRed"
          onChange={handleSelectBarbers}
        >
          {!!team &&
            team.map((item) => (
              <option
                style={{
                  backgroundColor: colors.menuBackground,
                  fontFamily: 'Montserrat Medium',
                }}
                key={item?.id}
              >
                {item?.name}
              </option>
            ))}
        </Select>

        <Text fontSize={14} kind="medium" color="textGrey" mb="12px">
          Qual o tipo da meta:
        </Text>
        <Select
          name="barber"
          w="100%"
          h={14}
          fontFamily="Montserrat semiBold"
          fontSize={14}
          textColor="textLight"
          iconColor="primary"
          backgroundColor="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={8}
          value={type?.name}
          mb={2}
          errorBorderColor="baseRed"
          onChange={handleSelectType}
        >
          {types.map((item) => (
            <option
              style={{
                backgroundColor: colors.menuBackground,
                fontFamily: 'Montserrat Medium',
              }}
              key={item?.name}
            >
              {item?.name}
            </option>
          ))}
        </Select>

        <TextInput
          mask="CURRENCY_MASK"
          value={value}
          label="Valor da meta de faturamento mensal (R$)"
          placeholder="Informe o valor da meta de faturamento mensal"
          onChange={(e) => setValue(e.target.value)}
        />
        <TextInput
          mt={8}
          type="DATE"
          value={finish_date}
          label="Essa meta é válida até quando?"
          placeholder="Informe até qual data esta meta será válida"
          onChange={(e) => setFinishDate(e.target.value)}
        />
        <Text mt={10} fontSize={14} kind="medium" color="textGrey" mb="12px">
          Comissão escalável
        </Text>

        <SelectCard
          px={4}
          item={{
            title: 'Ativar bônus de comissão ao alcançar esta meta',
          }}
          setSelected={() => {
            if (!bonus_active) {
              refetch()
            }
            setBonusActive(!bonus_active)
          }}
          selected={bonus_active}
          h={14}
          w="100%"
        />
        {bonus_active && (
          <>
            <Text mb={2} mt={2} fontSize={12} color="textGrey">
              Observação: ao bater a meta, os serviços e produtos terão suas comissões alteradas de acordo com as
              informações abaixo. As comissões serão alteradas no mês seguinte ao mês em que a meta foi alcançada.
            </Text>

            <Tabs>
              <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={1}>
                {['Serviços', 'Produtos'].map((label) => (
                  <Tab
                    w="50%"
                    fontSize={14}
                    fontWeight={500}
                    _selected={{
                      color: '#E5B817',
                      borderColor: '#E5B817',
                      borderBottomWidth: 4,
                    }}
                  >
                    {label}
                  </Tab>
                ))}
              </TabList>

              <TabPanels p={0} marginTop="12px">
                <TabPanel p={0}>
                  <Flex w="100%" justify="space-between">
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar individualmente',
                      }}
                      setSelected={() =>
                        setServices({
                          change_all: !services.change_all,
                          data: services.data,
                        })
                      }
                      selected={!services.change_all}
                      h={14}
                      w="54%"
                    />
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar para todos',
                      }}
                      setSelected={() =>
                        setServices({
                          change_all: !services.change_all,
                          data: services.data,
                        })
                      }
                      selected={services.change_all}
                      h={14}
                      w="45%"
                    />
                  </Flex>
                  <TableInputs
                    columns={services.change_all ? tableInputAll : tableInputColumns}
                    data={
                      services.change_all
                        ? [
                            {
                              id: 1,
                              name: 'Todos os serviços',
                              change_all: true,
                              percentage: services.change_all_value,
                            },
                          ]
                        : services?.data
                    }
                    setData={(d) => {
                      const change_all = d?.[0]?.change_all
                      setServices({
                        change_all: services.change_all,
                        ...(change_all
                          ? {
                              change_all_value: Number(d?.[0]?.percentage?.replace('%', '')),
                            }
                          : {}),
                        data: change_all ? services.data : d,
                      })
                    }}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Flex w="100%" justify="space-between">
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar individualmente',
                      }}
                      setSelected={() =>
                        setProducts({
                          data: products.data,
                          change_all: !products.change_all,
                        })
                      }
                      selected={!products.change_all}
                      h={14}
                      w="54%"
                    />
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar para todos',
                      }}
                      setSelected={() =>
                        setProducts({
                          data: products.data,
                          change_all: !products.change_all,
                        })
                      }
                      selected={products.change_all}
                      h={14}
                      w="45%"
                    />
                  </Flex>
                  <TableInputs
                    columns={products.change_all ? tableInputAll : tableInputColumns}
                    data={
                      products.change_all
                        ? [
                            {
                              id: 1,
                              name: 'Todos os produtos',
                              change_all: true,
                              percentage: products.change_all_value,
                            },
                          ]
                        : products?.data
                    }
                    setData={(d) => {
                      const change_all = d?.[0]?.change_all
                      setProducts({
                        change_all: products.change_all,
                        ...(change_all
                          ? {
                              change_all_value: Number(d?.[0]?.percentage?.replace('%', '')),
                            }
                          : {}),
                        data: change_all ? products.data : d,
                      })
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>
      <ModalButtonBackground>
        <Button
          kind="primary"
          isDisabled={!value || !finish_date || finish_date?.length < 10 || mutateCreate.isLoading}
          isLoading={mutateCreate.isLoading}
          size="block"
          onClick={() => {
            const params = {
              collaborator_user_id: !collaborator ? 'all_collaborators' : collaborator?.user_id,
              value: unformatCurrency(value),
              barbershop_id: userData?.id,
              type: type?.value,
              signature_enabled: type?.signature_enabled,
              bonus_active,
              start_date: format(new Date(), 'yyyy-MM-dd'),
              end_date: format(parseISO(finish_date), 'yyyy-MM-dd'),
              products_and_services_comission: [{ products, services }],
            }
            if (collaborator) {
              params.collaborator_user_id = collaborator.user_id
            }
            mutateCreate.mutate(params)
          }}
        >
          {t('SAVE')}
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
