import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import {
  Link,
  Avatar,
  Table,
  Text,
  SelectDateModal,
  SelectDateButton,
  NewUsersChart,
  NewBarbershopsChart,
  TextInput,
  Button,
} from 'components'
import { UserDetailsModal } from 'components/organisms/InternalAdm'
import { Flex, useToast } from '@chakra-ui/react'
import {
  INTERNAL_ADM_NEW_REGISTERS,
  INTERNAL_ADM_SEARCH_USER,
} from 'services/api/endpoints'
import { useLazyFetch } from 'services/hooks'
import { theme } from 'styles'
import { Layout, ResultCard, Loader } from '../Components'

const columns = [
  {
    key: 'image',
    label: '',
    render: (item) => <Avatar src={item?.profile_image_url} size="medium" />,
  },
  {
    width: 120,
    key: 'name',
    label: 'NOME',
    render: (item) => <Text>{item?.name}</Text>,
  },
  {
    width: 140,
    key: 'phone',
    label: 'TELEFONE',
    render: (item) => (
      <Link
        href={`https://wa.me/${item?.user?.phone.replace(/[^\w]/gi, '')}`}
        isExternal
      >
        {item?.user?.phone}
      </Link>
    ),
  },
  {
    width: 260,
    key: 'email',
    label: 'EMAIL',
    render: (item) => <Text>{item?.user?.email}</Text>,
  },
  {
    width: 140,
    key: 'status',
    label: 'STATUS',
    render: (item) => <Text>{item?.status}</Text>,
  },
  {
    width: 100,
    key: 'createdAt',
    label: 'CADASTRO',
    render: (item) => (
      <Text>{format(parseISO(item?.createdAt), 'dd/MM/yyyy')}</Text>
    ),
  },
  {
    width: 100,
    key: 'createdAt',
    label: 'ÚLTIMO ACESSO',
    render: (item) => (
      <Text>
        {item?.user?.last_login
          ? format(parseISO(item?.user?.last_login), 'dd/MM/yyyy')
          : '-'}
      </Text>
    ),
  },
]

const active_columns = [
  {
    key: 'type',
    label: 'TIPO',
  },
  {
    key: 'total',
    label: 'Ativos',
  },
  {
    key: 'percentage',
    label: '%',
  },
]

export const NewRegisters = () => {
  const toast = useToast()
  const [modalOpen, setModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [type, setType] = useState('e-mail')
  const [query, setQuery] = useState('')
  const [activeData, setActiveData] = useState({ all: [], search: [] })
  const [onlyActives, setOnlyActives] = useState(false)

  const [getData, { loading, data }] = useLazyFetch(
    INTERNAL_ADM_NEW_REGISTERS(
      format(startDate, 'yyyy-MM-dd'),
      format(endDate, 'yyyy-MM-dd')
    ),
    {
      onCompleted: (data) =>
        setActiveData({
          all: data?.barbershops || [],
          search: data?.barbershops,
        }),
      onError: (error) => {
        console.log({ error })
        toast({
          title: 'Houve um erro ao carregar os usuários!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const [getUser, { loading: loadingSearch, data: dataSearch }] = useLazyFetch(
    INTERNAL_ADM_SEARCH_USER,
    {
      method: 'post',
      onCompleted: (data) =>
        setActiveData({ all: activeData.all || [], search: data }),
      onError: ({ error }) => {
        toast({
          title: error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    getData()
  }, [startDate, endDate])

  const onPressOnlyActives = () => {
    if (onlyActives) {
      setActiveData({ ...activeData, search: activeData.all })
    } else {
      setActiveData({
        ...activeData,
        search: activeData.search.filter((a) => a.user.last_login),
      })
    }
    setOnlyActives(!onlyActives)
  }

  const Dates = () => {
    return (
      <Flex flexDir="row">
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="flex-start"
          mr={theme.pxToRem(12)}
        >
          <Text
            kind="semiBold"
            fontFamily="Montserrat ExtraBold"
            fontSize={theme.pxToRem(14)}
            mb={theme.pxToRem(10)}
          >
            Data inicial:
          </Text>
          <SelectDateButton
            disableChangeMonth
            date={startDate}
            setDate={setStartDate}
            onClickCenter={() => setDateModalOpen('start')}
          />
        </Flex>
        <Flex flexDir="column" alignItems="center" justifyContent="flex-start">
          <Text
            kind="semiBold"
            fontFamily="Montserrat ExtraBold"
            fontSize={theme.pxToRem(14)}
            mb={theme.pxToRem(10)}
          >
            Data final:
          </Text>
          <SelectDateButton
            disableChangeMonth
            date={endDate}
            setDate={setEndDate}
            onClickCenter={() => setDateModalOpen('end')}
          />
        </Flex>
      </Flex>
    )
  }
  const onChangeUser = (text) => {
    if (!text) {
      setActiveData({ all: activeData.all || [], search: activeData.all })
    }
    const unmasked = text.replace(/[^a-zA-Z0-9@._]/g, '')
    if (!isNaN(parseFloat(unmasked)) && isFinite(unmasked)) {
      setType('phone')
      setQuery(text)
      return
    }
    setType('e-mail')
    let unmaskedEmail = text.replace(/[^a-zA-Z0-9@._-]/g, '')
    if (type === 'phone') {
      unmaskedEmail = text.replace(/[^a-zA-Z0-9@._]/g, '')
    }
    setQuery(unmaskedEmail)
  }

  return (
    <Layout title="Novos Usuários Cadastrados">
      <Flex flexDir="row">
        <Flex flexDir="column">
          <Dates />
          {!loading && (
            <>
              <ResultCard
                label="TAXA DE CONVERSÃO"
                value={`${data?.conversion || 0}%`}
                description="Número de subscriptions pelo total de barbearias cadastradas no período"
              />
              <Table
                data={data?.active_users}
                columns={active_columns}
                bgRow="backgroundLayout"
              />
            </>
          )}
        </Flex>
        {!loading && (
          <Flex
            flexDir="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            ml={theme.pxToRem(48)}
          >
            {data?.usersChartData && (
              <NewUsersChart
                chartData={data?.usersChartData}
                total={data?.total}
              />
            )}
            {data?.barbershopsChartData && (
              <NewBarbershopsChart
                mt={theme.pxToRem(12)}
                chartData={data?.barbershopsChartData}
                total={data?.barbershop}
              />
            )}
          </Flex>
        )}
        <SelectDateModal
          onClose={() => setDateModalOpen(false)}
          isModalOpen={dateModalOpen !== false}
          selectedDate={dateModalOpen === 'start' ? startDate : endDate}
          onSelectDate={(date) => {
            if (dateModalOpen === 'start') {
              setStartDate(date)
            } else {
              setEndDate(date)
            }
            setDateModalOpen(false)
          }}
        />
      </Flex>
      {!loading && (
        <Flex
          flexDir="column"
          w="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text kind="bold" mb="2vh">
            Barbearias cadastradas:
          </Text>
          <Flex flexDir="row" mb={theme.pxToRem(12)}>
            <TextInput
              type="inputMask"
              mask={type === 'phone' ? '** *****-****' : null}
              alwaysShowMask={false}
              maskChar={null}
              noBorder
              name="user"
              label="Buscar usuário"
              value={query}
              w={theme.pxToRem(420)}
              onChange={(event) => onChangeUser(event?.target?.value)}
            />
            <Button
              isLoading={loading === 'getUser'}
              mt={theme.pxToRem(24)}
              ml={theme.pxToRem(8)}
              w={theme.pxToRem(60)}
              onClick={() => getUser({ params: { user: query } })}
              icon="Search"
              iconColor={'cardBackground'}
            />
            <Button
              mt={theme.pxToRem(24)}
              ml={theme.pxToRem(8)}
              w={theme.pxToRem(200)}
              onClick={onPressOnlyActives}
              iconColor={'cardBackground'}
              kind={onlyActives ? 'outline' : 'primary'}
            >
              Exibir {!onlyActives ? 'somente ativos' : 'todos'}
            </Button>
          </Flex>
          <Table
            isSelectableRow
            onSelect={(item) => setModalOpen(item)}
            data={activeData?.search}
            columns={columns}
          />
        </Flex>
      )}
      {loading && <Loader />}
      <UserDetailsModal
        user={modalOpen}
        isOpen={modalOpen !== false}
        onClose={() => setModalOpen(false)}
      />
    </Layout>
  )
}

NewRegisters.path = '/internal-adm/new-registers'
NewRegisters.title = 'Novos cadastros'
NewRegisters.secured = false
