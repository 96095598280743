import React from 'react';
import { Text } from 'components';
import { formatPrice, formatToSimpleDate } from 'utils';
import { t } from 'services/translation';

export const serviceColumns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'price',
    label: 'VALUE_TABLE',
    render: (item) => <Text>{formatPrice(item.price)}</Text>,
  },
  {
    key: 'time_required',
    label: 'TEMPO DE ATENDIMENTO',
    render: (item) => <Text>{item.time_required.substring(0, 5)}</Text>,
  },
  {
    key: 'type',
    label: 'TYPE',
    render: (item) => <Text>{t(item.type)}</Text>,
  },
  {
    key: 'quantity',
    label: 'QUANTIDADE DE SERVIÇOS',
    render: (item) => <Text>{t(item.quantity)}</Text>,
  },
];

export const clientColumns = [
  {
    key: 'name',
    label: 'CLIENTE',
    render: (item) => <Text>{item?.client?.name}</Text>,
  },
  {
    key: 'name',
    label: 'PACOTE DE SERVIÇO',
    render: (item) => <Text>{item?.product?.name}</Text>,
  },
  {
    key: 'value',
    label: 'PRICE',
    render: (item) => <Text>{formatPrice(item.product.price)}</Text>,
  },
  {
    key: 'quantity_',
    label: 'QUANTIDADE CONTRATADA',
    render: (item) => <Text >{item?.product?.quantity}</Text>,
  },
  {
    key: 'quantity_',
    label: 'QUANTIDADE RESTANTE',
    render: (item) => <Text color={item.quantity === 0 ? 'danger' : 'white'}>{item?.quantity}</Text>,
  },
];

export const mockData = [
  {
    name: 'Pomada',
    price: 15,
  },
  {
    name: 'Shampoo',
    price: 25.5,
  },
  {
    name: 'Condicionador',
    price: 20.49,
  },
  {
    name: 'Locão para barba',
    price: 39.99,
  },
  {
    name: 'Creme pós barba',
    price: 29.9,
  },
  {
    name: 'Hidratante capilar',
    price: 39.9,
  },
];
