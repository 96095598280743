import { useFetch, useFetchReport } from './use-fetch'
import { api } from 'services/api/config'
import { getData } from 'services/storage'
import {
  BARBERSHOP_CLIENTS,
  SERVICES,
  EDIT_SERVICE,
  CREATE_SERVICE,
  BARBERSHOP_STATUS,
  UPDATE_BARBERSHOP_STATUS,
  REVIEWS,
  GET_CASHIER_TRANSACTIONS,
  SERVICES_BY_BARBER,
  PRODUCTS_BY_BARBER,
  BARBERSHOP_GET_PAYMENT_METHOD,
  UPDATE_CASHIER_STATUS,
  CREATE_FINANCE_RELEASE,
  CHANGE_FINANCE_RELEASE,
  NEW_CASHIER_TRANSACTION,
  GET_CASHIER_USER_INFO,
  ADD_SERVICE_APPOINTMENT,
  FINISH_REOPENED_APPOINTMENT,
  SINGLE_PRODUCT_GET,
  CREATE_STOCK,
  GET_STOCK,
  GET_ADDITIONAL_PRODUCTS,
  GET_ADDITIONAL_PRODUCTS_PRODUCERS,
  UPDATE_STOCK,
  DELETE_STOCK,
  GET_SUBSCRIPTION_DETAIL,
  ONLY_BARBERS,
  GET_APPOINTMENTS_TO_CLEARED,
  PAID_APPOINTMENTS_BARBER,
  CHANGE_BARBER_VISIBLE,
  CHANGE_MINIMUM_TIME,
  CHANGE_MAXIMUM_SCHEDULE,
  EXPORT_BARBER_EXTRACT,
  EXPORT_SERVICES_EXTRACT,
  UPDATE_APPOINTMENT_ID,
  CREATE_WORTH_BARBER,
  OBSERVATION_GET,
  GET_FUTURE_APPOINTMENTS,
  BARBERS,
  CLIENTS_PACKAGE,
  UPDATE_PRODUCT,
  GET_CLIENT_APPOINTMENTS,
  CHANGE_CONFIG_AVAILABLE,
  BARBERSHOP_PERSONALIZED_HOUR,
  BARBERSHOP_CLIENTS_FREQUENCY,
  GET_APPOINTMENTS_TO_CLEARED_SIGNATURE,
  GET_CASHIER_TRANSACTIONS_EXCEL,
  CREATE_CLIENT_RELEASE,
  CHANGE_PLAN_VISIBLE,
  CHANGE_SCHEDULE_TIME,
  BARBERSHOP_CLIENTS_FREQUENCY_EXCEL,
  PAID_APPOINTMENTS_COLLABORATOR,
  ALL_TEAM,
  EXPORT_SIGNATURES_REPORT,
  UPDATE_CLIENT_PASSWORD,
  BARBERSHOP_NEW_CLIENTS,
  BARBERSHOP_NEW_CLIENTS_EXCEL,
  BEST_NEWS,
  BEST_NEWS_UPDATE,
  BARBERSHOP_CLIENTS_FREQUENCY_SIGNATURE,
  BARBERSHOP_PLANS_AVERAGE,
} from 'services/api/endpoints'
import { format } from 'date-fns'

export const useBarbershop = () => {
  const { onFetch, onFetchExport } = useFetch()

  async function getClients(props) {
    const response = await onFetch({
      url: BARBERSHOP_CLIENTS(props),
      method: 'get',
      message: {
        error: 'LOAD_CLIENTS_ERROR',
      },
    })
    return response
  }

  async function getClientsFrequency(props) {
    const response = await onFetch({
      url: BARBERSHOP_CLIENTS_FREQUENCY(props),
      method: 'get',
      message: {
        error: 'Erro ao carregar relatório',
      },
    })
    return response
  }

  async function getClientsFrequencySignature(props) {
    const response = await onFetch({
      url: BARBERSHOP_CLIENTS_FREQUENCY_SIGNATURE(props),
      method: 'get',
      message: {
        error: 'Erro ao carregar relatório',
      },
    })
    return response
  }

  async function getPlansAverage(props) {
    const response = await onFetch({
      url: BARBERSHOP_PLANS_AVERAGE(props),
      method: 'get',
      message: {
        error: 'Erro ao carregar relatório',
      },
    })
    return response
  }

  async function getNewClients(props) {
    const response = await onFetch({
      url: BARBERSHOP_NEW_CLIENTS({ id: props?.id, startDate: props?.startDate, endDate: props?.endDate }),
      method: 'get',
      message: {
        error: 'Erro ao carregar relatório',
      },
    })
    return response
  }

  async function getClientsAppointments(props) {
    const response = await onFetch({
      url: GET_CLIENT_APPOINTMENTS(props),
      method: 'get',
      message: {
        error: 'Erro ao carregar os agendamentos',
      },
    })
    return response
  }

  async function updateProduct(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_PRODUCT,
      method: 'put',
      message: {
        error: 'Erro ao atualizar produto',
      },
    })
    return response
  }

  async function updateClientPassword(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_CLIENT_PASSWORD,
      method: 'put',
      message: {
        error: 'Erro ao atualizar senha',
      },
    })
    return response
  }

  async function getClientsPackage(props) {
    const response = await onFetch({
      url: CLIENTS_PACKAGE(props?.id),
      method: 'get',
      message: {
        error: 'LOAD_CLIENTS_ERROR',
      },
    })
    return response
  }

  async function deleteClientsPackage(props) {
    const response = await onFetch({
      url: CLIENTS_PACKAGE(props?.id),
      method: 'delete',
      message: {
        error: 'Erro ao excluir pacote',
        success: 'Pacote excluido com sucesso',
      },
      ...props,
    })
    return response
  }

  async function getAppointmentsCount(props) {
    if (props?.service_id) {
      const response = await onFetch({
        url: GET_FUTURE_APPOINTMENTS(props?.barbershop_id, props?.service_id, props?.barber_id),
        method: 'get',
      })
      return response
    }
  }

  async function getSingleProductDetail(props) {
    const response = await onFetch({
      url: SINGLE_PRODUCT_GET(props?.id),
      method: 'get',
      message: {
        error: 'GET_SINGLE_SALE_ERROR',
      },
    })
    return response
  }

  async function getServices(props) {
    const response = await onFetch({
      url: SERVICES(props?.id),
      method: 'get',
      message: {
        error: 'LOAD_SERVICES_ERROR',
      },
    })
    return response
  }

  async function getServicesByBarber(props) {
    const response = await onFetch({
      url: SERVICES_BY_BARBER(props?.id, props?.barber_id),
      method: 'get',
      message: {
        error: 'LOAD_SERVICES_ERROR',
      },
    })
    return response
  }

  async function getProductsByBarber(props) {
    const response = await onFetch({
      url: PRODUCTS_BY_BARBER(props?.id, props?.barber_id, props?.type || 'barber'),
      method: 'get',
      message: {
        error: 'ERROR_LOADING_PRODUCTS',
      },
    })
    return response
  }

  async function editServices(props) {
    const response = await onFetch({
      url: props?.edit ? EDIT_SERVICE(props?.id, props?.barbershop_id) : CREATE_SERVICE,
      method: props?.edit ? 'put' : 'post',
      message: {
        success: 'SAVE_DATA_SUCCESS',
        error: 'ERROR_SAVE_DATA',
      },
    })
    return response
  }

  async function getStatus(props) {
    const response = await onFetch({
      url: BARBERSHOP_STATUS(props?.id),
      method: 'get',
      message: {
        error: 'LOAD_SERVICES_ERROR',
      },
    })
    return response
  }

  async function updateStatus(props) {
    const response = await onFetch({
      url: UPDATE_BARBERSHOP_STATUS(props?.status, props?.id),
      method: 'put',
      message: {
        success: 'BARBERSHOP_STATUS',
        error: 'BARBERSHOP_STATUS',
      },
    })
    return response
  }

  async function getRatings(props) {
    const response = await onFetch({
      url: REVIEWS(props?.id),
      method: 'get',
    })
    return response
  }

  async function getPaymentMethods(barbershop_id) {
    const response = await onFetch({
      url: BARBERSHOP_GET_PAYMENT_METHOD(barbershop_id),
      method: 'get',
      message: {
        error: 'LOAD_PAYMENT_METHODS_ERROR',
      },
    })
    return response
  }

  async function getCashierTransactions(barbershop_id, date) {
    const response = await onFetch({
      url: GET_CASHIER_TRANSACTIONS(barbershop_id, date),
      method: 'get',
      message: {
        error: 'LOAD_PAYMENT_METHODS_ERROR',
      },
    })
    return response
  }

  async function updateCashierStatus({ barbershop_id, ...props }) {
    const response = await onFetch({
      ...props,
      url: UPDATE_CASHIER_STATUS(barbershop_id),
      method: 'put',
      message: {
        error: 'LOAD_PAYMENT_METHODS_ERROR',
      },
    })
    return response
  }

  async function submitFinanceRelease(props) {
    const release_id = props?.params?.id
    const response = await onFetch({
      ...props,
      url: release_id ? CHANGE_FINANCE_RELEASE(release_id, props?.params?.barbershop_id) : CREATE_FINANCE_RELEASE,
      method: release_id ? 'put' : 'post',
      message: {
        success: `${release_id ? 'EDIT' : 'ADD'}_RELEASE_SUCCESS`,
        error: `${release_id ? 'EDIT' : 'ADD'}_RELEASE_ERROR`,
      },
    })
    return response
  }

  async function deleteFinanceRelease(props) {
    const release_id = props?.params?.id
    const response = await onFetch({
      ...props,
      url: CHANGE_FINANCE_RELEASE(release_id, props?.params?.barbershop_id),
      method: 'delete',
      message: {
        success: `DELETE_RELEASE`,
        error: `DELETE_RELEASE_ERROR`,
      },
    })
    return response
  }

  async function newCashierTransaction(props) {
    const response = await onFetch({
      ...props,
      url: NEW_CASHIER_TRANSACTION,
      method: 'post',
      message: {
        success: `ADD_RELEASE_SUCCESS`,
        error: `Add_RELEASE_ERROR`,
      },
    })
    return response
  }

  async function getCashierUserInfo(barbershop_id) {
    const response = await onFetch({
      url: GET_CASHIER_USER_INFO(barbershop_id),
      method: 'get',
    })
    return response
  }

  async function addServiceAppointment(props) {
    const response = await onFetch({
      ...props,
      url: ADD_SERVICE_APPOINTMENT,
      method: 'post',
      message: {
        error: `ERROR_ADD_SERVICE`,
      },
    })
    return response
  }

  async function finishReopenedAppointment(props) {
    const response = await onFetch({
      ...props,
      url: FINISH_REOPENED_APPOINTMENT(props?.id),
      method: 'post',
      message: {
        error: `FINISH_APPOINTMENT_ERROR`,
        success: `FINISHED_APPOINTMENT`,
      },
    })
    return response
  }

  async function addStock(props) {
    const response = await onFetch({
      ...props,
      url: CREATE_STOCK,
      method: 'post',
      message: {
        error: `FAILED_CREATE_STOCK`,
        success: `SUCCESS_CREATE_STOCK`,
      },
    })
    return response
  }

  async function getStock(props) {
    const response = await onFetch({
      ...props,
      url: GET_STOCK(props?.barbershop_id),
      method: 'get',
      message: {
        error: `FAILED_GET_STOCK`,
      },
    })
    return response
  }

  async function updateStock(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_STOCK(props?.id),
      method: 'put',
      message: {
        error: `FAILED_CREATE_STOCK`,
        success: `SUCCESS_CREATE_STOCK`,
      },
    })
    return response
  }

  async function deleteStock(props) {
    const response = await onFetch({
      ...props,
      url: DELETE_STOCK(props?.id, props?.product_id),
      method: 'delete',
      message: {
        error: `FAILED_DELETE_STOCK`,
        success: `SUCCESS_DELETE_STOCK`,
      },
    })
    return response
  }

  async function getAdditionalProducts(props) {
    const response = await onFetch({
      ...props,
      url: GET_ADDITIONAL_PRODUCTS(props?.id),
      method: 'get',
    })
    return response
  }

  async function getObservations(props) {
    const response = await onFetch({
      ...props,
      url: OBSERVATION_GET(props?.barbershop_id, props?.client_id),
      method: 'get',
    })
    return response
  }

  async function getAdditionalProductsProducers(props) {
    const response = await onFetch({
      ...props,
      url: GET_ADDITIONAL_PRODUCTS_PRODUCERS(props?.id),
      method: 'get',
    })
    return response
  }
  async function getSubscriptionDatails(props) {
    const token = await getData('@BestBarbers:token')
    api.defaults.headers.common.Authorization = `bearer ${token}`
    const response = await onFetch({
      ...props,
      url: GET_SUBSCRIPTION_DETAIL,
      method: 'get',
    })
    return response
  }

  async function getBarbers(props) {
    const response = await onFetch({
      ...props,
      url: ONLY_BARBERS(props?.barbershop_id),
      method: 'get',
    })
    return response
  }

  async function getAllEmployees(props) {
    const response = await onFetch({
      ...props,
      url: BARBERS(props?.barbershop_id),
      method: 'get',
    })
    return response
  }

  async function getAllAndInactiveEmployees(props) {
    const response = await onFetch({
      ...props,
      url: ALL_TEAM(props?.barbershop_id),
      method: 'get',
    })
    return response
  }

  async function getAppointmentsToCleared(props) {
    const response = await onFetch({
      url: GET_APPOINTMENTS_TO_CLEARED(props),
      method: 'get',
    })
    return response
  }

  async function getAppointmentsSignatureToCleared(props) {
    const response = await onFetch({
      url: GET_APPOINTMENTS_TO_CLEARED_SIGNATURE(props),
      method: 'get',
    })
    return response
  }

  async function paidBarberAppointments(props) {
    const response = await onFetch({
      ...props,
      url: PAID_APPOINTMENTS_BARBER,
      method: 'post',
      message: {
        success: 'Baixa realizada com sucesso',
        error: 'Falha ao dar baixa',
      },
    })
    return response
  }

  async function paidCollaboratorAppointments(props) {
    const response = await onFetch({
      ...props,
      url: PAID_APPOINTMENTS_COLLABORATOR,
      method: 'post',
      message: {
        success: 'Baixa realizada com sucesso',
        error: 'Falha ao dar baixa',
      },
    })
    return response
  }

  async function createWorthBarber(props) {
    const response = await onFetch({
      ...props,
      url: CREATE_WORTH_BARBER,
      method: 'post',
      message: {
        success: 'Lançamento cadastrado com sucesso',
        error: 'Erro ao cadastrar lançamento',
      },
    })
    return response
  }

  async function createClientRelease(props) {
    const response = await onFetch({
      ...props,
      url: CREATE_CLIENT_RELEASE,
      method: 'post',
      message: {
        success: 'Lançamento cadastrado com sucesso',
        error: 'Erro ao cadastrar lançamento',
      },
    })
    return response
  }

  async function changeBarberVisible(props) {
    const response = await onFetch({
      ...props,
      url: CHANGE_BARBER_VISIBLE,
      method: 'put',
      message: {
        success: 'Status alterado com sucesso',
        error: 'Falha ao alterar status',
      },
    })
    return response
  }

  async function changePlanVisible(props) {
    const response = await onFetch({
      ...props,
      url: CHANGE_PLAN_VISIBLE,
      method: 'put',
      message: {
        success: 'Plano alterado com sucesso',
        error: 'Falha ao alterar plano',
      },
    })
    return response
  }

  async function changeMinimumTime(props) {
    const response = await onFetch({
      ...props,
      url: CHANGE_MINIMUM_TIME,
      method: 'put',
      message: {
        success: 'Tempo alterado com sucesso',
        error: 'Falha ao alterar tempo',
      },
    })
    return response
  }

  async function changeMinimumScheduleTime(props) {
    const response = await onFetch({
      ...props,
      url: CHANGE_SCHEDULE_TIME,
      method: 'put',
      message: {
        success: 'Tempo alterado com sucesso',
        error: 'Falha ao alterar tempo',
      },
    })
    return response
  }

  async function changeConfigAvailable(props) {
    const response = await onFetch({
      ...props,
      url: CHANGE_CONFIG_AVAILABLE,
      method: 'put',
      message: {
        success: 'Configuração alterada com sucesso',
        error: 'Falha ao alterar configuração',
      },
    })
    return response
  }

  async function changeMaximumSchedule(props) {
    const response = await onFetch({
      ...props,
      url: CHANGE_MAXIMUM_SCHEDULE,
      method: 'put',
      message: {
        success: 'Prazo alterado com sucesso',
        error: 'Falha ao alterar prazo',
      },
    })
    return response
  }

  async function updateAppointmentId(props) {
    const response = await onFetch({
      ...props,
      url: UPDATE_APPOINTMENT_ID(props?.appointment_id),
      method: 'put',
      message: {
        success: 'ID personalizado salvo com sucesso',
        error: 'Erro ao salvar ID personalizado',
      },
    })
    return response
  }

  async function exportComissionReport(props) {
    const response = await onFetchExport({
      ...props,
      url: EXPORT_BARBER_EXTRACT({ id: props?.id }),
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportSignatureReport(props) {
    const response = await onFetchExport({
      ...props,
      url: EXPORT_SIGNATURES_REPORT({ id: props?.id }),
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportClientsFrequency(props) {
    const response = await onFetchExport({
      ...props,
      url: BARBERSHOP_CLIENTS_FREQUENCY_EXCEL({ id: props?.id }),
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportNewClients(props) {
    const response = await onFetchExport({
      ...props,
      url: BARBERSHOP_NEW_CLIENTS_EXCEL({ id: props?.id }),
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportServicesReport(props) {
    const response = await onFetchExport({
      ...props,
      url: EXPORT_SERVICES_EXTRACT(props),
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        ...props.params,
        responseType: 'blob',
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function exportCashierReport(props) {
    const response = await onFetchExport({
      ...props,
      url: GET_CASHIER_TRANSACTIONS_EXCEL(props?.barbershop_id),
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      config: {
        responseType: 'blob',
        ...props.params,
      },
      method: 'post',
      message: {
        error: 'Erro ao exportar relatório',
      },
    })
    return response
  }

  async function createPersonalizedHour(props) {
    const response = await onFetch({
      ...props,
      url: BARBERSHOP_PERSONALIZED_HOUR,
      method: 'put',
      message: {
        success: 'Horário cadastrado com sucesso',
        error: 'Falha ao cadastrar horário',
      },
    })
    return response
  }

  async function createBestNews(props) {
    const response = await onFetch({
      ...props,
      url: BEST_NEWS,
      method: 'post',
      message: {
        success: 'Cadastro com sucesso',
        error: 'Erro ao cadastrar',
      },
    })
    return response
  }

  async function deleteBestNews(props) {
    const response = await onFetch({
      ...props,
      url: BEST_NEWS_UPDATE(props?.id),
      method: 'delete',
      message: {
        success: 'Deletado com sucesso',
        error: 'Erro ao deletar',
      },
    })
    return response
  }

  return {
    getPlansAverage,
    getClientsFrequencySignature,
    deleteBestNews,
    createBestNews,
    exportNewClients,
    getNewClients,
    exportSignatureReport,
    changePlanVisible,
    createClientRelease,
    exportCashierReport,
    createPersonalizedHour,
    getClientsFrequency,
    changeConfigAvailable,
    updateProduct,
    createWorthBarber,
    getObservations,
    updateAppointmentId,
    exportServicesReport,
    exportComissionReport,
    getClientsAppointments,
    changeMinimumTime,
    changeMaximumSchedule,
    changeBarberVisible,
    paidBarberAppointments,
    getAppointmentsToCleared,
    getAppointmentsSignatureToCleared,
    getBarbers,
    deleteStock,
    updateStock,
    getAdditionalProductsProducers,
    getAdditionalProducts,
    getStock,
    addStock,
    finishReopenedAppointment,
    addServiceAppointment,
    getClients,
    getServices,
    editServices,
    getStatus,
    updateStatus,
    getRatings,
    getServicesByBarber,
    getProductsByBarber,
    getPaymentMethods,
    getCashierTransactions,
    updateCashierStatus,
    submitFinanceRelease,
    deleteFinanceRelease,
    newCashierTransaction,
    getCashierUserInfo,
    getSingleProductDetail,
    getSubscriptionDatails,
    getAppointmentsCount,
    getAllEmployees,
    getClientsPackage,
    deleteClientsPackage,
    changeMinimumScheduleTime,
    exportClientsFrequency,
    paidCollaboratorAppointments,
    getAllAndInactiveEmployees,
    updateClientPassword,
  }
}
