import { useMemo } from 'react'
import { useBarbershopStore } from 'services/stores'

export const usePremiumBlock = () => {
  const { userData } = useBarbershopStore()

  return useMemo(() => {
    if (
      userData?.iugu_plan_id?.substring(0, 10) === 'whitelabel' &&
      userData?.account_status === 'SUBSCRIBED'
    ) {
      return false
    }
    if (
      userData?.iugu_plan_id === 'basic_plan' &&
      userData?.account_status === 'SUBSCRIBED'
    ) {
      return false
    }
    if (
      userData?.iugu_plan_id === 'best_friday_anual_plan' &&
      userData?.account_status === 'SUBSCRIBED'
    ) {
      return false
    }
    if (userData?.iugu_plan_id === 'free_plan') {
      return false
    }
    if (userData?.account_status === 'TRIAL') {
      return false
    }
    if (userData?.account_status === 'ONBOARDING') {
      return false
    }
    if (userData?.account_status === 'LEAD') {
      return false
    }

    return true
  }, [userData])
}
