import React from 'react'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { Icon, Text } from 'components/atoms'
import { ChevronRight } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles'
import { formatPrice } from 'utils'

export const PaymentMethodCard = ({
  inList,
  paymentMethod,
  index,
  selected,
  onClick,
  reopened,
  status,
  ...props
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box
      key={`data_payment_card_${index}`}
      height={theme.pxToRem(56)}
      width={inList ? 'full' : theme.pxToRem(436)}
      display="flex"
      flexDir="row"
      w="100%"
      h={theme.pxToRem(56)}
      background="cardBackground"
      alignItems="center"
      justifyContent="flex-start"
      p={theme.pxToRem(16)}
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor={selected ? 'primary' : 'modalBorder'}
      marginBottom={inList && theme.pxToRem(16)}
      onClick={inList && onClick}
      {...props}
    >
      <Flex
        w="100%"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Text
            color={selected ? 'primary' : 'textLight'}
            fontSize={theme.pxToRem(14)}
          >
            {paymentMethod.name}
          </Text>
          {!reopened && paymentMethod?.id !== 'comission_barber' && status === 'finished' && (
            <Text
              fontSize={theme.pxToRem(14)}
              color={selected ? 'primary' : 'textGrey'}
            >
              {`${paymentMethod.due_in_days} ${t('DAYS')}${
                paymentMethod?.payment_value || paymentMethod?.payment_value === 0
                  ? ` - ${formatPrice(paymentMethod?.payment_value)}`
                  : ''
              }`}
            </Text>
          )}
        </Box>

       {paymentMethod?.id !== 'comission_barber' && <Text
          fontSize={theme.pxToRem(14)}
          color={selected ? 'primary' : 'textLight'}
          mr={theme.pxToRem(16)}
        >
          {paymentMethod.percentage}%
        </Text>}

        {!inList && !!onClick && (
          <Icon
            cursor="pointer"
            onClick={onClick}
            name="Trash"
            color="baseRed"
            size={16}
          />
        )}
      </Flex>

      {inList && (
        <ChevronRight color={colors.primary} style={{ fontWeigth: 'bold' }} />
      )}
    </Box>
  )
}
