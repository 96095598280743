import React from 'react'
import { Text, Icon, Avatar } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { t } from 'services/translation'

export const BarberCard = ({
  barber,
  outline,
  fullWidth,
  inList,
  onClick,
  isSingleSale,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent="flex-start"
      p={theme.pxToRem(10)}
      mb={inList && theme.pxToRem(16)}
      w="100%"
      minH="68px"
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Flex
        w="100%"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Avatar size="medium" src={barber?.profile_image_url} type="client" />

        <Flex
          w="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDir="column"
          ml={theme.pxToRem(16)}
        >
          <Text kind="bold" color="textLight" fontSize={theme.pxToRem(14)}>
            {barber?.name}
          </Text>
          {isSingleSale && (
            <Text kind="medium" color="textLight" fontSize={theme.pxToRem(14)}>
              Função: {t(barber?.type || barber?.visible_name)}
            </Text>
          )}
        </Flex>

        {onClick && (
          <Icon
            cursor="pointer"
            onClick={onClick}
            name="Trash"
            color="baseRed"
            size={16}
            mr={theme.pxToRem(4)}
          />
        )}
      </Flex>
    </Box>
  )
}
