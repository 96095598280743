export const getPaymentInfo = (appointments) => {
  if (!appointments || !appointments?.length) {
    return []
  }
  let total = 0
  const organizer = appointments.reduce((groups, appointment) => {
    const { appointment_payments } = appointment
    if (appointment_payments?.length) {
      appointment_payments.map((payment) => {
        const foundIndexOfProduct = groups.findIndex((item) => item.payment_method_id === payment.payment_method_id)
        total += 1
        if (foundIndexOfProduct >= 0) {
          const item = groups[foundIndexOfProduct]
          groups[foundIndexOfProduct] = {
            ...item,
            quantity: item.quantity + 1,
            value_percentage: item.value_percentage + payment.payment_percentage_value,
            value: item.value + payment.payment_value,
          }
        } else {
          groups.push({
            payment_method_id: payment.payment_method_id,
            name: payment.payment_method_choosed.name,
            quantity: 1,
            value_percentage: payment.payment_percentage_value,
            value: payment.payment_value,
          })
        }
      })
    }
    // if (formatted_payments?.payment_method_id) {
    //   const foundIndexOfProduct = groups.findIndex(
    //     (item) => item.payment_method.payment_method_id === formatted_payments.payment_method_id
    //   )
    //   total += 1
    //   if (foundIndexOfProduct >= 0) {
    //     const item = groups[foundIndexOfProduct]
    //     groups[foundIndexOfProduct] = {
    //       ...item,
    //       quantity: item.quantity + 1,
    //       value_percentage: item.value_percentage + formatted_payments.payment_percentage_value,
    //       value: item.value + formatted_payments.payment_value,
    //     }
    //   } else {
    //     groups.push({
    //       payment_method: formatted_payments,
    //       quantity: 1,
    //       value_percentage: formatted_payments.payment_percentage_value,
    //       value: formatted_payments.payment_value,
    //     })
    //   }
    // }
    return groups
  }, [])
  return {
    formatted_payments: organizer,
    total,
  }
}
