import { VStack } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import { ProfileMenuItem } from 'components/atoms/ProfileMenuItem'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'

export const ProfileMenu = ({ route, onSelect, onLogout }) => {
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()

  return (
    <VStack w={isDesktop && pxToRem(335)} spacing={pxToRem(14)}>
      <ProfileMenuItem
        title={t('MAIN_DATA')}
        to="/perfil/dados"
        active={route === 'dados'}
        icon="Document"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('LOGIN_DATA')}
        to="/perfil/acesso"
        active={route === 'acesso'}
        icon="People"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('BARBERSHOP_LINK')}
        to="/perfil/link"
        active={route === 'link'}
        icon="BarbershopLink"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('LOCATION')}
        to="/perfil/localizacao"
        active={route === 'localizacao'}
        icon="LocationMarker"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('WORKING_HOURS')}
        to="/perfil/horarios"
        active={route === 'horarios'}
        icon="Clock"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('PHOTOS')}
        to="/perfil/fotos"
        active={route === 'fotos'}
        icon="Camera"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('BLOCKED_TIME')}
        to="/perfil/horario-bloqueado"
        active={route === 'horario-bloqueado'}
        icon="Blocked"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('NOTIFICATIONS')}
        to="/perfil/notificacoes"
        active={route === 'notificacoes'}
        icon="Notification"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('SUBSCRIPTION')}
        to="/perfil/meu-plano"
        active={route === 'meu-plano'}
        icon="Plan"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('PASSWORD_PROFILE')}
        to="/perfil/senha"
        active={route === 'senha'}
        icon="Lock"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title={t('EXIT')}
        to="/perfil/sair"
        active={route === 'sair'}
        icon="Logout"
        onSelect={onLogout}
      />
    </VStack>
  )
}
