import * as React from 'react'

function SvgChevronLeft(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.149 9.147l6.078-6a.511.511 0 01.716 0 .496.496 0 010 .706L4.225 9.501l5.719 5.646a.496.496 0 010 .707.512.512 0 01-.717 0l-6.078-6a.496.496 0 010-.707z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChevronLeft
