import React from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { steps } from './settings'
import { Flex, Box, Text, Button, Icon } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'

export const ConfirmFreeTrialModal = ({ isOpen, onClose }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { user } = useBarbershopStore()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        py={theme.pxToRem(20)}
        bgColor="cardBackground"
        pos={!isDesktop && 'absolute'}
        top={!isDesktop && 0}
      >
        <ModalHeader textAlign="center">
          <Flex justifyContent="center" mb={theme.pxToRem(24)} w="100%">
            <Icon name="CheckCircle" fill="primary" size={56} />
          </Flex>

          <Text kind="extraBold" fontSize={24} mb={theme.pxToRem(8)}>
            {t('CONFIRM_FREE_TRIAL_MODAL_TITLE')}
          </Text>

          <Box w="100%" mb={theme.pxToRem(24)}>
            <Text w="100%" color="textGrey" fontSize={14} mr={theme.pxToRem(6)}>
              {t('CONFIRM_FREE_TRIAL_MODAL_SUBTITLE')}
            </Text>
            <Text color="white" kind="bold" fontSize={14}>
              {user?.email}
            </Text>
          </Box>

          <Box w="100%" borderWidth={0.5} borderColor="background"></Box>
        </ModalHeader>
        {/* <ModalCloseButton color="white" /> */}

        <ModalBody w="100%">
          <Box
            px={theme.pxToRem(12)}
            py={theme.pxToRem(2)}
            mb={theme.pxToRem(24)}
            w="100%"
            textAlign="center"
            fontWeight="900"
          >
            <Text kind="extraBold" fontSize={16}>
              {t('ATENTION')}
            </Text>
            <Text fontSize={14} color="textGrey" mr={theme.pxToRem(6)}>
              {t('REMEMBER_TO_PAY')}
            </Text>
          </Box>

          <Box w="100%">
            {steps.map((item, index) => (
              <Flex
                key={`step_${index}`}
                alignItems="base-line"
                mb={theme.pxToRem(16)}
              >
                <Icon name={item.number} mr={theme.pxToRem(12)} />

                <Box>
                  <Text fontSize={14} kind="bold" mb={theme.pxToRem(4)}>
                    {t(item.title)}
                  </Text>
                  <Text fontSize={12} color="textGrey">
                    {t(item.text)}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Box>
        </ModalBody>

        <ModalFooter flexDir="column">
          <Button kind="primary" w="100%" onClick={onClose}>
            {t('CONTINUE_BUTTON')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
