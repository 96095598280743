import React, { useEffect, useMemo, useState } from 'react'
import { HStack, useTheme, useToast, VStack, Flex } from '@chakra-ui/react'
import { TextInput, Button, FileInput } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint, useBarbershop } from 'services/hooks'
import { ModalMenu } from 'components/molecules'
import { useMutation, useQueryClient } from 'react-query'

export const BestNewsModal = ({ isModalOpen, onClose, refetch, ...props }) => {
  const { isDesktop } = useBreakpoint()

  const theme = useTheme()
  const { createBestNews } = useBarbershop()
  const queryClient = useQueryClient()
  const toast = useToast()

  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [urlFile, setUrlFile] = useState()
  const [photo, setPhoto] = useState()

  const handleSelectPhoto = async (photo) => {
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onload = () => {
      setPhoto(reader?.result)
    }
  }

  const mutate = useMutation('create-best-news', (params) => createBestNews(params))

  const handleCreateBestNews = () => {
    const splitted = photo?.split(',')
    const mime = splitted?.[0]?.toString()
    const mime1 = mime?.split(':')
    const mime2 = mime1?.[1]?.split(';')
    const params = {
      title,
      description,
      file_url: urlFile,
      base64: splitted?.[1],
      mime: mime2?.[0],
    }
    mutate.mutate(
      { params },
      {
        onSuccess: () => {
          onClose()
          setTitle(null)
          setDescription(null)
          setUrlFile(null)
          setPhoto(null)
          queryClient.invalidateQueries(['get-news'])
        },
        onSettled: () => {
          queryClient.invalidateQueries(['get-news'])
        },
      }
    )
  }

  return (
    <>
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onClose}
        title="Adicionar lançamento para o barbeiro"
        headerStyle={{ mb: pxToRem(-12) }}
      >
        <VStack h="90%">
          <Flex w="100%" mb="24px">
            <TextInput
              noBorder
              name="title"
              label="Titulo"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              mt={'36px'}
              mb="100px"
            />
          </Flex>

          <Flex w="100%" mb="24px">
            <TextInput
              noBorder
              type="string"
              value={description}
              placeholder="Descrição"
              name="description"
              label="Descrição"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Flex>
          <Flex w="100%" mb="100px">
            <TextInput
              mt="36px"
              h="46px"
              noBorder
              placeholder="link do documento"
              value={urlFile}
              onChange={(e) => {
                setUrlFile(e.target.value)
              }}
              label="Url do documento"
              padding={theme.pxToRem(10)}
            />
          </Flex>

          <Flex w="100%" mt="100px" align="baseline" justify="end" mb="24px">
            <FileInput
              defaultImage={photo}
              label="Profile"
              onChange={handleSelectPhoto}
              canDelete={false}
              mt="64px"
              w={'100%'}
              h={130}
              br={8}
            />
          </Flex>
        </VStack>

        <Flex mt="36px" width="full" maxWidth={!isDesktop && 325}>
          <Button
            kind={'primary'}
            size="block"
            type="submit"
            isLoading={mutate.isLoading}
            onClick={() => handleCreateBestNews()}
            mt="12px"
          >
            {'Salvar'}
          </Button>
        </Flex>
      </ModalMenu>
    </>
  )
}
