import React from 'react'
import {
  useTheme,
  Center,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Button, Text, Flex } from 'components'
import { useBreakpoint } from 'services/hooks'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import setupImg from 'assets/img/backgrounds/barbershopOnboarding/bb-setup-illustration.png'

export const BarbershopIntroModal = ({ isOpen, onClose }) => {
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const { t } = useTranslation()

  const history = useHistory()

  const onClickContinue = () => {
    history.push('/cadastro/localizacao')
  }

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent
        padding={theme.pxToRem(40)}
        bg="menuBackground"
        borderWidth={1}
        borderColor="modalBorder"
        borderRadius={8}
      >
        <ModalBody w="100%">
          <Flex flexDir="column" w="100%" h="100%">
            <Center w="100%" h="100%" flexDir="column">
              <Image
                src={setupImg}
                w={theme.pxToRem(330)}
                mb={theme.pxToRem(24)}
              />

              <Text
                align="center"
                color="textLight"
                kind="extraBold"
                fontSize={theme.pxToRem(24)}
                lineHeight={theme.pxToRem(42)}
                mb={theme.pxToRem(24)}
              >
                {t('BARBERSHOP_INTRO_TITLE')}
              </Text>

              <Text
                fontSize={theme.pxToRem(14)}
                align="center"
                mb={theme.pxToRem(24)}
              >
                {t('BARBERSHOP_INTRO_DESC')}
              </Text>
            </Center>

            <Button size="block" kind="primary" onClick={onClickContinue}>
              {t('BEGIN')}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

BarbershopIntroModal.path = '/cadastro/barbershop-intro'
BarbershopIntroModal.title = ''
BarbershopIntroModal.secured = true
