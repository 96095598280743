import * as React from 'react'

function WhatsAppSvg(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="40" height="40" rx="20" fill="#25D366" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5823 21.985C24.3328 21.8608 23.1095 21.2625 22.8817 21.1792C22.6539 21.0967 22.4881 21.0558 22.3215 21.3042C22.1557 21.5508 21.6793 22.1092 21.5344 22.2742C21.3887 22.44 21.2438 22.46 20.9952 22.3367C20.7465 22.2117 19.9443 21.9508 18.9939 21.1075C18.2545 20.4509 17.7546 19.64 17.6098 19.3917C17.4649 19.1442 17.5939 19.01 17.7186 18.8867C17.8308 18.7759 17.9673 18.5975 18.0921 18.4534C18.2169 18.3084 18.2579 18.205 18.3408 18.0392C18.4245 17.8742 18.3826 17.73 18.3198 17.6059C18.2579 17.4817 17.7605 16.2625 17.5528 15.7667C17.351 15.2842 17.1459 15.35 16.9935 15.3417C16.8478 15.335 16.682 15.3334 16.5162 15.3334C16.3504 15.3334 16.0808 15.395 15.853 15.6434C15.6244 15.8909 14.9822 16.49 14.9822 17.7092C14.9822 18.9275 15.8731 20.105 15.9979 20.2709C16.1227 20.4359 17.7521 22.9375 20.2482 24.01C20.8428 24.265 21.3058 24.4175 21.6667 24.5308C22.2629 24.72 22.8055 24.6933 23.2342 24.6292C23.7115 24.5583 24.7063 24.03 24.9139 23.4517C25.1208 22.8733 25.1207 22.3775 25.0588 22.2742C24.9968 22.1708 24.831 22.1092 24.5815 21.985H24.5823ZM20.0423 28.1542H20.0389C18.5563 28.1544 17.101 27.7578 15.8254 27.0058L15.524 26.8275L12.3906 27.6458L13.2271 24.6058L13.0303 24.2942C12.2015 22.9811 11.7629 21.4615 11.7651 19.9109C11.7668 15.3692 15.4796 11.6742 20.0456 11.6742C22.2562 11.6742 24.3345 12.5325 25.897 14.0892C26.6676 14.853 27.2785 15.7613 27.6941 16.7616C28.1098 17.7619 28.322 18.8343 28.3186 19.9167C28.3169 24.4583 24.6041 28.1542 20.0423 28.1542ZM27.086 12.9067C26.1634 11.9825 25.0657 11.2496 23.8564 10.7507C22.6472 10.2518 21.3505 9.9966 20.0414 10C14.5535 10 10.0854 14.4459 10.0837 19.91C10.0829 21.6567 10.5409 23.3617 11.4126 24.8642L10 30L15.2786 28.6217C16.7388 29.4134 18.3752 29.8283 20.0381 29.8283H20.0423C25.5302 29.8283 29.9983 25.3825 30 19.9175C30.004 18.6152 29.7485 17.3251 29.2484 16.1217C28.7482 14.9183 28.0132 13.8256 27.086 12.9067Z"
        fill="white"
      />
    </svg>
  )
}

export default WhatsAppSvg
