import React, { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalHeader, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { Button, Text } from 'components/atoms'
import { useMutation } from 'react-query'
import { useInternal } from 'services/hooks/use-internal'

export const ExportClientsModal = ({ isOpen, onClose, barbershops, user }) => {
  const theme = useTheme()
  const [password, setPassword] = useState('')

  const closeModal = () => {
    setPassword('')
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      setPassword('')
    }
  }, [isOpen])

  const { exportBarbershopClients } = useInternal()

  const { isLoading, mutate } = useMutation(
    ['get-barbershop-clients-excel'],
    ({ type, id }) =>
      exportBarbershopClients({
        type,
        id,
      }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
      },
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      {
        <ModalContent
          maxW={theme.pxToRem(700)}
          borderColor="primary"
          borderWidth={1}
          bg="backgroundLayout"
          flexDir="column"
          align="center"
          justify="center"
          p="60px"
          borderRadius="12px"
          maxH={theme.pxToRem(1028)}
        >
          <ModalHeader textAlign="center" w={'100%'}>
            <Flex flexDir="column" w="100%" alignItems="center" justifyContent="center">
              <Text kind="extraBold" fontSize={28} textAlign="center">
                Exportar lista de clientes
              </Text>
              <Text kind="semiBold" fontSize={16} textAlign="center">
                Escolha uma unidade para exportar a lista de clientes ou exporte a lista de clientes unificada (de todas
                as unidades)
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mt="8px" color="white" onClick={closeModal} />
          <Flex justify="space-between" mt="20px" flexDir="column">
            <Button
              onClick={() => mutate({ type: 'user', id: user?.id })}
              disabled={isLoading}
              kind="secondary"
              w="100%"
              isLoading={isLoading}
            >
              Lista de clientes unificada (de todas as unidades)
            </Button>
            {barbershops?.map((barbershop) => (
              <Button
                onClick={() => mutate({ type: 'barbershop', id: barbershop?.id })}
                disabled={isLoading}
                w="100%"
                kind="outline"
                mt={2}
                isLoading={isLoading}
              >
                id: {barbershop?.id} | {barbershop?.name} | Ativa: {barbershop?.inactive ? 'Sim' : 'Não'}
              </Button>
            ))}
          </Flex>
        </ModalContent>
      }
    </Modal>
  )
}
