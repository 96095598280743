import { api } from 'services/api/config'
import { useBarbershopStore } from 'services/stores'

export const usePaymentMethod = () => {
  const { userData } = useBarbershopStore()
  const getPaymentMethods = async (props) => {
    const response = await api({
      url: `v3/barbershop/payment-method/${userData?.id}`,
    })
    return response.data
  }

  return {
    getPaymentMethods,
  }
}
