import * as React from 'react'

function SvgStocks(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="transparent"
        d="M19.099.125H2.617A1.904 1.904 0 00.715 2.027v2.535c0 .35.283.634.634.634h.634v12.045c0 .35.283.634.634.634h16.482c.35 0 .634-.284.634-.634V5.196h.634c.35 0 .634-.283.634-.633V2.026A1.904 1.904 0 0019.099.125zm-.634 16.482H3.25V5.197h15.214v11.41zM19.733 3.93H1.983V2.027c0-.35.284-.634.634-.634h16.482c.35 0 .634.284.634.634v1.902z"
      />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M8.956 10.27h3.804a1.904 1.904 0 001.902-1.902 1.904 1.904 0 00-1.902-1.902H8.956a1.904 1.904 0 00-1.901 1.902c0 1.048.853 1.901 1.901 1.901zm0-2.536h3.804a.634.634 0 010 1.268H8.956a.634.634 0 010-1.268z"
      />
    </svg>
  )
}

export default SvgStocks
