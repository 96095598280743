import React, { useEffect } from 'react'
import {
  Layout,
  Table,
} from 'components'
import { Flex } from '@chakra-ui/react'
import './hideScrollbar.css'
import { columns } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useSignatureStore } from 'services/stores/signature'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const SignatureReportDetail = () => {
  const { onLogout } = useLogoutPaymentIssues()
  const {selectedSignature} = useSignatureStore()
  const history = useHistory()

  useEffect(() => {
    onLogout()
  }, [])

  return (
    <Layout
      isPremiumScreen
      headerTitle={`Relatório detalhado assinatura - ${selectedSignature?.service_name}`}
      widthTitle="100%"
      handleBackNavigation={() => history.goBack()}
    >
      <>
          <Flex w="100%" flexDir="column">
            <Table
              data={
                selectedSignature.releases?.sort((a, b) => {
                  return a?.payment_date - b?.payment_date
                }) || []
              }
              isSelectableRowVariabled={selectedSignature.releases.map(i => i?.invoice_url)}
              onSelect={(item) => window.open(item?.invoice_url, '_blank')}
              columns={columns}
            />
          </Flex>
      </>
    </Layout>
  )
}

SignatureReportDetail.path = '/relatorio-detalhado-assinaturas'
SignatureReportDetail.title = 'Relatório Detalhado - Assinaturas'
SignatureReportDetail.secured = true
