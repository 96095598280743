import { useState } from 'react'

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Text,
  FormHelperText,
  VStack,
  Input,
  Flex,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'

import { Icon } from 'components/atoms'

export const fileToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const base64String = btoa(
        new Uint8Array(reader.result).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      resolve(base64String)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })

export const InputFileField = ({
  label,
  error,
  helperText,
  isRequired,
  isLoading,
  accept = '.jpg, .jpeg',
  templateColumns = {
    base: 'repeat(2, 1fr)',
    md: 'repeat(3, 1fr)',
    lg: 'repeat(4, 1fr)',
    xl: 'repeat(5, 1fr)',
  },
  value,
  onChange,
  ...props
}) => {
  const fileVoid = { url: null, file: null }
  const [file, setFile] = useState(value ?? fileVoid)

  const handleInsertFile = (ev) => {
    const inputEl = ev.target
    const inputFile = inputEl.files[0]

    if (!inputFile) return

    fileToBase64(inputFile).then((base64) => {
      const newFile = { url: base64, file: inputFile }

      setFile(newFile)
      onChange?.(newFile)
    })

    inputEl.value = ''
  }

  const handleRemoveFile = () => {
    setFile(fileVoid)
    onChange?.(fileVoid)
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error}>
      {label && (
        <FormLabel
          mb={2}
          fontSize={14}
          fontWeight={600}
          opacity={props.isDisabled ? 0.5 : 1}
        >
          {label}
        </FormLabel>
      )}

      <Skeleton isLoaded={!isLoading} borderRadius={6}>
        <Box opacity={props.isDisabled ? 0.5 : 1} mt={2}>
          {file.url ? (
            <Flex
              position="relative"
              borderRadius={4}
              borderWidth={1}
              borderColor="gray.600"
              bg="gray.800"
              overflow="hidden"
            >
              <IconButton
                icon={<Icon name="Close" size={12} color="primary" />}
                isRound
                size="xs"
                position="absolute"
                top={1}
                right={1}
                opacity={0.6}
                _hover={{ opacity: 1 }}
                onClick={handleRemoveFile}
              />

              <Flex p={4}>
                <Icon
                  name="Document"
                  size={64}
                  onClick={handleRemoveFile}
                  color="primary"
                />
                <Box ml={4}>
                  <Text fontSize={14} color="gray.100">
                    {file.file.name}
                  </Text>
                  <Text fontSize={14} color="gray.100" mt={2}>
                    {(file.file.size / 1000).toFixed(1)} KB
                  </Text>
                </Box>
              </Flex>
            </Flex>
          ) : (
            <Flex
              justify="center"
              align="center"
              position="relative"
              borderRadius={4}
              borderStyle="dashed"
              borderWidth={2}
              borderColor={error ? 'red' : 'primary'}
              py={6}
              maxH={338}
            >
              <Box position="absolute" inset={0} bg="primary" opacity={0.03} />
              <VStack spacing={0}>
                <Text fontWeight={600} textAlign="center">
                  Solte o arquivo ou
                </Text>
                <Text fontWeight={600} color="primary" textAlign="center">
                  clique para pesquisar no dispositivo
                </Text>
                {accept && (
                  <Text variant="small" color="gray.100" textAlign="center">
                    Arquivos suportados: {accept}
                  </Text>
                )}
              </VStack>
              <Input
                type="file"
                accept={accept}
                position="absolute"
                inset={0}
                h="100%"
                w="100%"
                cursor="pointer"
                opacity={0}
                onChange={handleInsertFile}
              />
            </Flex>
          )}
        </Box>
      </Skeleton>

      {helperText && (
        <FormHelperText fontSize="xs">{helperText}</FormHelperText>
      )}
      {error && (
        <FormErrorMessage fontSize="xs" color="danger">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
