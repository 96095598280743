import React, { useEffect, useState, useMemo } from 'react'
import { TextInput, Button, Text, Table, Icon } from 'components'
import { Flex, useToast, useTheme, Switch } from '@chakra-ui/react'
import { userManagementStore } from 'services/stores'
import { Layout } from '../Components'
import { format, parseISO } from 'date-fns'
import { formatPhone } from 'utils/phone-formater'
import { ChangeDueDateModal } from '../Modals/ChangeDueDate'
import { ChangePasswordModal } from '../Components/ChangePasswordModal'
import { BarbershopDetailsModal } from '../Components/BarbershopDetailsModal'
import { unmaskPhone } from 'utils/phone-formater'
import { formatCurrency } from 'utils/formatCurrency'
import { ExportClientsModal } from '../Components/ExportClientsModal'

export const UsersManagement = () => {
  const theme = useTheme()
  const toast = useToast()
  const {
    loading,
    onGetUserData,
    onGetByUserId,
    onGetByName,
    onGetByBarbershopId,
    userData,
    cleanUserData,
    onUpdatePassword,
    onInactivateUser,
    onUpdateClientSignature,
    onUpdateClientSignatureDueDate,
    onAddUnityPromise,
  } = userManagementStore()

  const [user, setUser] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [barbershopId, setBarbershopId] = useState('')
  const [userId, setUserId] = useState('')
  const [subscriptionId, setSubscriptionId] = useState(null)

  const [selected, setSelected] = useState(null)
  const [selectedBarbershop, setSelectedBarbershop] = useState(null)

  const [modalOpen, setModalOpen] = useState(false)

  const onSuccess = (withLoading = true) => {
    setSubscriptionId(null)
    setModalOpen(false)
    if (user !== '') {
      onGetUserData(user, toast, withLoading)
      return
    }
    if (userId !== '') {
      onGetByUserId(userId, toast, withLoading)
      return
    }
    if (name !== '') {
      onGetByName(name, toast, withLoading)
      return
    }
    if (barbershopId !== '') {
      onGetByBarbershopId(barbershopId, toast, withLoading)
      return
    }
    cleanUserData()
    setSelected(null)
  }

  useEffect(() => {
    if (selected?.id) {
      setSelected(userData?.find((u) => u.id === selected?.id))
    }
  }, [userData])

  useEffect(() => {
    cleanUserData()
  }, [])

  const onInactivate = async (item) => {
    let payload = {
      id: item?.id,
      status: !item?.inactive,
    }
    const resp = await onInactivateUser(payload, toast, false)
    if (!resp.error || !resp) {
      onSuccess(false)
    }
  }

  const onChangePassword = async (password) => {
    const payload = {
      user_id: modalOpen?.id,
      password,
    }
    const resp = await onUpdatePassword(payload, toast)
    if (!resp?.error) {
      setModalOpen(false)
    }
  }

  const onChangeClientSignature = async (signature_id, data) => {
    const resp = await onUpdateClientSignature({ signature_id, data }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onChangeDueDate = async (signature_id, data) => {
    const resp = await onUpdateClientSignatureDueDate({ signature_id, data }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onAddUnity = async () => {
    const resp = await onAddUnityPromise({ user_id: selected?.id }, toast)
    if (!resp?.error) {
      onSuccess()
    }
  }

  const onSelect = (item) => {
    if (item?.id === selected?.id) {
      setSelected(null)
    } else {
      setSelected(item)
    }
  }

  const barbershop_signature_plans = useMemo(() => {
    if (selected?.barbershop?.length > 0) {
      const plans_array = []
      selected?.barbershop?.forEach((barbershop) => {
        barbershop?.plans?.forEach((plan) => {
          plans_array.push(plan)
        })
      })

      return plans_array?.sort((a, b) => a.id - b.id)
    }
    return []
  }, [selected])

  const user_columns = [
    {
      key: 'id',
      label: 'user_id',
      render: (item) => <Text onClick={() => onSelect(item)}>{item?.id}</Text>,
      width: 70,
    },
    {
      key: 'name',
      label: 'NOME',
      render: (item) => {
        return (
          <Text onClick={() => onSelect(item)}>{item?.barbershop?.[0]?.name || item?.client?.[0]?.name || ''}</Text>
        )
      },
      width: 100,
    },
    {
      key: 'type',
      label: 'TIPO',
      render: (item) => <Text onClick={() => onSelect(item)}>{item?.type}</Text>,
      width: 100,
    },
    {
      key: 'phone',
      label: 'TELEFONE',
      render: (item) => <Text onClick={() => onSelect(item)}>{formatPhone(item?.phone)}</Text>,
      width: 140,
    },
    {
      key: 'email',
      label: 'EMAIL',
      render: (item) => <Text onClick={() => onSelect(item)}>{item?.email}</Text>,
      width: 250,
    },
    {
      key: 'createdAt',
      label: 'CADASTRO',
      width: 100,
      render: (item) => <Text onClick={() => onSelect(item)}>{format(parseISO(item.createdAt), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'inactive',
      label: 'CADASTRO',
      width: 90,
      render: (item) => (
        <Flex>
          <Switch colorScheme="yellow" onChange={(e) => onInactivate(item)} isChecked={!item.inactive} />
          <Text ml={2} color={item.inactive ? 'danger' : 'success'}>
            {item.inactive ? 'Desativado' : 'Ativo'}
          </Text>
        </Flex>
      ),
    },
    {
      key: 'password',
      label: 'SENHA',
      width: 60,
      render: (item) => (
        <Button onClick={() => setModalOpen(item)} size="x-small-auto">
          Alterar
        </Button>
      ),
    },
    {
      key: 'inactive',
      // label: 'CADASTRO',
      width: 30,
      render: (item) => (
        <Flex cursor={'pointer'} onClick={() => onSelect(item)}>
          <Icon name="ChevronDown" />
        </Flex>
      ),
    },
  ]

  const barbershop_columns = [
    {
      key: 'id',
      label: 'barbershop_id',
      render: (item) => <Text>{item?.id}</Text>,
    },
    {
      key: 'name',
      label: 'NOME',
      render: (item) => {
        return <Text>{item?.name || ''}</Text>
      },
    },
    {
      key: 'account_status',
      label: 'MENSALIDADE',
      render: (item) => (
        <Text color={item?.account_status?.includes('SUBSCRIBED') ? 'success' : 'danger'}>
          {item?.account_status?.includes('SUBSCRIBED') ? 'Em dia' : 'Pendente'}
        </Text>
      ),
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => <Text>{item?.status}</Text>,
    },
    {
      key: 'inactive',
      label: 'CLUBE',
      render: (item) => (
        <Text color={!item.signature_club_enable ? 'grey' : 'success'}>
          {!item.signature_club_enable ? 'Desativado' : 'Ativado'}
        </Text>
      ),
    },
    {
      key: 'inactive',
      label: 'NOTA FISCAL',
      render: (item) => (
        <Text color={!item.nf_module_active ? 'grey' : 'success'}>
          {!item.nf_module_active ? 'Desativado' : 'Ativado'}
        </Text>
      ),
    },
    {
      key: 'createdAt',
      label: 'CADASTRO',
      render: (item) => <Text>{format(parseISO(item.createdAt), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'inactive',
      label: 'CADASTRO',
      render: (item) => (
        <Text color={item.inactive ? 'danger' : 'success'}>{item.inactive ? 'Desativado' : 'Ativo'}</Text>
      ),
    },
  ]

  const client_signature_columns = [
    {
      key: 'client_id',
      label: 'client_id',
      render: (item) => <Text>{item?.client_id}</Text>,
    },
    {
      key: 'id',
      label: 'ID ASSINATURA',
      render: (item) => <Text>{item?.id}</Text>,
    },
    {
      key: 'barbershop',
      label: 'BARBEARIA',
      render: (item) => {
        return <Text>{item?.barbershop?.name || ''}</Text>
      },
    },
    {
      key: 'plan',
      label: 'PLANO',
      render: (item) => <Text>{item?.plan?.name || 'Pacote de serviços'}</Text>,
    },
    {
      key: 'change',
      label: 'MODIFICAÇÃO',
      render: (item) => <Text>{item?.change_type || 'Nenhuma'}</Text>,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <Flex>
          <Switch
            colorScheme="yellow"
            onChange={(e) => {
              onChangeClientSignature(item?.id, { inactive: !item?.inactive })
            }}
            isChecked={!item.inactive}
          />
          <Text ml={2} color={item.inactive ? 'danger' : 'success'}>
            {item.inactive ? 'Desativado' : 'Ativo'}
          </Text>
        </Flex>
      ),
    },
    {
      key: 'due_date',
      label: 'VENCIMENTO',
      render: (item) => (
        <Text>{item?.due_simple_date ? format(parseISO(item?.due_simple_date), 'dd/MM/yyyy') : 'Não informado'}</Text>
      ),
    },
    {
      key: 'due_date',
      label: 'VENCIMENTO',
      render: (item) => (
        <Button
          onClick={() => {
            setSubscriptionId(item.id)
            setModalOpen('due_date')
          }}
          size="x-small-auto"
        >
          Alterar
        </Button>
      ),
    },
  ]

  const plans_columns = [
    {
      key: 'id',
      label: 'ID DO PLANO',
      width: 100,
      render: (item) => <Text>{item?.id}</Text>,
    },
    {
      key: 'barbershop_id',
      label: 'barbershop_id',
      width: 100,
      render: (item) => <Text>{item?.barbershop_id}</Text>,
    },
    {
      key: 'name',
      label: 'NOME',
      render: (item) => {
        return <Text>{item?.name}</Text>
      },
    },
    {
      key: 'value',
      label: 'VALOR',
      render: (item) => <Text>{formatCurrency(item?.value)}</Text>,
    },
    {
      key: 'comission',
      label: 'COMISSÃO',
      render: (item) => <Text>{item?.comission}%</Text>,
    },
    {
      key: 'limited',
      label: 'QTD LIMITADA',
      render: (item) => <Text>{item?.limited ? 'Sim' : 'Não'}</Text>,
    },
    {
      key: 'days',
      label: 'DIAS SEMANA',
      render: (item) => <Text>{!item?.days ? 'Todos' : item?.days}</Text>,
    },
  ]

  const filters = [
    {
      name: 'email',
      label: 'E-mail cadastrado',
      value: user,
      onChange: (value) => setUser(value),
      isLoading: loading === 'getUser',
      width: 250,
      action: () => {
        setSelected(null)
        onGetUserData(user, toast)
        setName('')
        setPhone('')
        setBarbershopId('')
        setUserId('')
      },
    },
    {
      name: 'phone',
      label: 'Telefone cadastrado',
      value: phone,
      mask: 'PHONE_MASK',
      onChange: (value) => setPhone(value),
      isLoading: loading === 'getUser',
      width: 250,
      action: () => {
        setSelected(null)
        onGetUserData(unmaskPhone(phone), toast)
        setUser('')
        setName('')
        setBarbershopId('')
        setUserId('')
      },
    },
    {
      name: 'name',
      label: 'Nome da barbearia',
      value: name,
      onChange: (value) => setName(value),
      isLoading: loading === 'getByName',
      width: 200,
      action: () => {
        setSelected(null)
        onGetByName(name, toast)
        setUser('')
        setPhone('')
        setBarbershopId('')
        setUserId('')
      },
    },
    {
      name: 'barbershop_id',
      label: 'ID da barbearia',
      value: barbershopId,
      onChange: (value) => setBarbershopId(value),
      isLoading: loading === 'getByBarbershopId',
      width: 150,
      action: () => {
        setSelected(null)
        onGetByBarbershopId(barbershopId, toast)
        setName('')
        setUser('')
        setPhone('')
        setUserId('')
      },
    },
    {
      name: 'user_id',
      label: 'ID do usuário',
      value: userId,
      onChange: (value) => {
        setUserId(value)
      },
      isLoading: loading === 'getByUserId',
      width: 150,
      action: () => {
        setSelected(null)
        onGetByUserId(userId, toast)
        setName('')
        setUser('')
        setPhone('')
        setBarbershopId('')
      },
    },
  ]

  return (
    <Layout
      title="Gerenciamento de usuários"
      addButton={
        selected?.id &&
        selected?.barbershop?.length > 0 && { text: 'Adicionar Unidade para a Barbearia', onClick: onAddUnity }
      }
      secondButton={
        selected?.id &&
        selected?.barbershop?.length > 0 && {
          text: 'Exportar lista de Clientes',
          onClick: () => setModalOpen('export_clients'),
        }
      }
    >
      <Text mb={4}>Buscar cadastros:</Text>
      <Flex w="100%">
        {filters.map((filter) => {
          return (
            <Flex mr={theme.pxToRem(12)}>
              <TextInput
                {...filter}
                noBorder
                w={theme.pxToRem(filter.width)}
                onChange={(event) => filter.onChange(event?.target?.value)}
              />
              <Button
                isLoading={filter.isLoading}
                mt={theme.pxToRem(26)}
                ml={theme.pxToRem(8)}
                w={theme.pxToRem(55)}
                onClick={() => {
                  filter.action()
                  setSelected(null)
                }}
                icon="Search"
                iconColor={'cardBackground'}
              />
            </Flex>
          )
        })}
      </Flex>
      <Flex mt={4} w="100%" flexDir={'column'}>
        {userData && (
          <Table
            fluid
            isSelectableRow={false}
            data={
              loading
                ? []
                : selected?.id
                ? userData?.filter((u) => u.id === selected?.id)
                : userData?.sort((a, b) => a.id - b.id) || []
            }
            loading={loading}
            columns={user_columns}
            selected={selected}
          />
        )}

        {!loading && selected?.barbershop?.length > 0 && (
          <Table
            fluid
            isSelectableRow={true}
            data={selected?.barbershop?.sort((a, b) => a.id - b.id) || []}
            columns={barbershop_columns}
            onSelect={(u) => {
              setSelectedBarbershop(u)
              setModalOpen('barbershop')
            }}
            selected={selected}
          />
        )}

        {!loading && selected?.client?.[0]?.subscriptions?.length > 0 && (
          <Table fluid data={selected?.client?.[0]?.subscriptions || []} columns={client_signature_columns} />
        )}

        {!loading && selected?.barber?.[0]?.barbershop && (
          <Table fluid data={[selected?.barber?.[0]?.barbershop]} columns={barbershop_columns} />
        )}

        {!loading && barbershop_signature_plans?.length > 0 && (
          <Table fluid data={barbershop_signature_plans} columns={plans_columns} />
        )}
      </Flex>

      <ChangeDueDateModal
        isOpen={modalOpen === 'due_date'}
        onClose={() => {
          setModalOpen(false)
          setSubscriptionId(null)
        }}
        onClick={(dueDate) => onChangeDueDate(subscriptionId, { due_date: dueDate })}
      />

      <BarbershopDetailsModal
        isOpen={modalOpen === 'barbershop'}
        onSuccess={onSuccess}
        barbershop={selectedBarbershop}
        onClose={() => setModalOpen(false)}
      />

      <ChangePasswordModal
        isOpen={!!modalOpen?.id}
        onClose={() => setModalOpen(false)}
        onClick={(password) => onChangePassword(password)}
      />

      <ExportClientsModal
        user={selected}
        barbershops={selected?.barbershop}
        isOpen={modalOpen === 'export_clients'}
        onClose={() => setModalOpen(false)}
      />
    </Layout>
  )
}

UsersManagement.path = '/internal-adm/users-management'
UsersManagement.title = 'Users adm'
UsersManagement.secured = false
