import React, { useMemo, useEffect, useState } from 'react'
import { Box, Button, Flex, Icon, Text } from 'components/atoms'
import { ModalMenu, ModalAlert, Form } from 'components/molecules'
import { useTheme, useToast } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { initial, validation, data } from './settings'
import { clearCurrency } from 'utils/clearCurrency'
import { useMutation, useQueryClient } from 'react-query'
import { useBarbershop } from 'services/hooks'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { AdditionalProducts } from './AdditionalProducts'

export const StockModal = ({
  onClose,
  isModalOpen,
  selectedBatch,
  isAppointment,
  selectedProduct,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [product, setProduct] = useState()
  const [productModal, setProductModal] = useState()
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const { userData } = useBarbershopStore()
  const [values, getValues] = useState({ params: initial, isValid: false })
  const { addStock, updateStock, deleteStock } = useBarbershop()
  const {entryType, setEntryType} = additionalProductsStore()
  const queryClient = useQueryClient()

  const mutate = useMutation(
    'add-stock-release',
    (params) => addStock(params), 
    {
      onSettled: (data) => {
        onClose()
        getValues({ params: initial, isValid: false })
        setProduct()
        queryClient.invalidateQueries('get-stocks-releases')
        queryClient.invalidateQueries('get-products-by-barber')
      },
    }
  )
  const mutateUpdate = useMutation(
    'update-stock-release',
    (params) => updateStock(params),
    {
      onSettled: (data) => {
        onClose()
        getValues({ params: initial, isValid: false })
        setProduct()
        queryClient.invalidateQueries('get-stocks-releases')
      },
    }
  )
  const mutateDelete = useMutation(
    'delete-stock-release',
    (params) => deleteStock(params),
    {
      onSettled: (data) => {
        onClose()
        getValues({ params: initial, isValid: false })
        setProduct()
        queryClient.invalidateQueries('get-stocks-releases')
      },
    }
  )

  const handleSubmitProduct = async (payload) => {
    const params = {
      ...payload,
      cost_price: Number(
        typeof payload?.cost_price === 'string'
          ? payload.cost_price.replace(/\,/g, '.')
          : payload?.cost_price
      ),
      sale_price: Number(
        typeof payload?.sale_price === 'string'
          ? payload.sale_price.replace(/\,/g, '.')
          : payload?.sale_price
      ),
      barbershop_id: userData?.id,
      product_id: product?.product_id || product?.id,
      type: entryType,
    }
    selectedBatch && !isAppointment
      ? mutateUpdate.mutate({ params, id: selectedBatch?.id })
      : mutate.mutate({ params })
  }
  const isDisabled = useMemo(() => {
    return (
      !values?.params?.quantity ||
      !product ||
      !values?.params?.cost_price ||
      !values?.params?.sale_price
    )
  }, [product, values])

  useEffect(() => {
    if (product) {
      getValues({
        params: {
          ...values?.params,
          sale_price: Number(product?.price),
          cost_price: Number(product?.cost_price),
        },
      })
    }
  }, [product])

  useEffect(() => {
    setAlertModalOpen(false)
    if (selectedProduct && !selectedBatch) {
      setProduct(selectedProduct)
      getValues({
        params: {
          ...selectedProduct,
          quantity: entryType ? null : Number(selectedProduct?.quantity),
          sale_price: Number(selectedProduct?.price),
          cost_price: Number(selectedProduct?.cost_price),
        },
      })
      return
    }
    if (selectedBatch) {
      setProduct(isAppointment ? selectedBatch : selectedBatch?.product)
      setEntryType(selectedBatch?.type)
      getValues({
        params: {
          ...selectedBatch,
        },
      })
    }
  }, [selectedBatch, selectedProduct, entryType])

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={() => {
        onClose()
        getValues({ params: initial, isValid: false })
      }}
      title={t('Adicionar estoque de produto')}
    >
      <Flex flexDir="column">
        <Text mb={theme.pxToRem(12)} fontSize={theme.pxToRem(14)}>
          Produto
        </Text>
        <Flex
          onClick={() => setProductModal(true)}
          h={theme.pxToRem(56)}
          bg="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={theme.pxToRem(8)}
          mb={theme.pxToRem(12)}
          w="100%"
          p={theme.pxToRem(16)}
          justify="space-between"
          align="center"
          cursor="pointer"
        >
          {!product ? (
            <Text fontSize={theme.pxToRem(14)}>{t('Selecionar produto')}</Text>
          ) : (
            <Text>{product?.name}</Text>
          )}
          <Icon name="ChevronRight" />
        </Flex>
        <Box marginBottom={theme.pxToRem(36)}>
          <Form
            initialValues={values?.params}
            validationSchema={validation}
            data={data}
            getValues={getValues}
            mb={32}
          />
        </Box>
      </Flex>

      <Flex mt={theme.pxToRem(60)} mb={theme.pxToRem(16)}>
        {selectedBatch && !isAppointment ? (
          <>
            <Button
              flex="1"
              mr={theme.pxToRem(8)}
              disabled={mutate.isLoading}
              isLoading={mutateDelete.isLoading}
              h={theme.pxToRem(52)}
              kind="danger"
              textTransform="uppercase"
              onClick={() => setAlertModalOpen(true)}
            >
              {t('DELETE')}
            </Button>
            <Button
              flex="1"
              ml={theme.pxToRem(8)}
              onClick={() => handleSubmitProduct(values?.params)}
              isLoading={mutateUpdate.isLoading}
              h={theme.pxToRem(52)}
              textTransform="uppercase"
            >
              {t('UPDATE')}
            </Button>
          </>
        ) : (
          <Button
            w="100%"
            disabled={mutate.isLoading || isDisabled}
            onClick={() => handleSubmitProduct(values?.params)}
            isLoading={mutate.isLoading}
          >
            {t('SAVE')}
          </Button>
        )}
      </Flex>

      <AdditionalProducts
        isOpen={productModal}
        onClose={() => setProductModal(false)}
        setProduct={(product) => {
          setProduct(product)
          setProductModal(false)
        }}
      />

      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        onConfirm={() =>
          mutateDelete.mutate({
            id: selectedBatch?.id,
            product_id: selectedBatch?.product?.id,
          })
        }
        primaryButtonLabel={t('DELETE')}
        modalTitle={t('CONFIRM_DELETE_STOCK')}
        loading={mutateDelete.isLoading}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CONFIRM_DELETE_STOCK')}
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}
StockModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}
