const currentPrefix = `v3`

export const INTERNAL_GET_USER_APPOINTMENTS = (user_id) =>
  `${currentPrefix}/internal-adm/user-details/appointments/${user_id}`

export const INTERNAL_GET_USER_OBSERVATIONS = (user_id) => `${currentPrefix}/internal-adm/observations/${user_id}`

export const INTERNAL_CREATE_OBSERVATION = `${currentPrefix}/internal-adm/observations/create`

export const INTERNAL_DELETE_OBSERVATION = (id) => `${currentPrefix}/internal-adm/observations/delete/${id}`

export const APPOINTMENTS_PAYMENT_METHODS_RESUME = `${currentPrefix}/internal-adm/dashboard/reports/payment-methods/all-appointments`

export const CLIENT_AVATAR = `${currentPrefix}/profile-image/client`
export const INTERNAL_UPDATE_USER_DATA = `${currentPrefix}/internal-adm/update-user-data`

// INTERNAL DASHBOARD
export const INTERNAL_ADM_LOGIN = `${currentPrefix}/internal-adm/login`
export const INTERNAL_ADM_INACTIVE_BARBERSHOPS = (date) =>
  `${currentPrefix}/internal-adm/barbershops/month-inactive?date=${date}`
export const INTERNAL_ADM_NEW_BARBERSHOPS_BY_DATE = (date) =>
  `${currentPrefix}/internal-adm/barbershops/new-barbershops-by-date?date=${date}`
export const INTERNAL_ADM_NEW_BARBERSHOPS_LAST_MONTHS = (date) =>
  `${currentPrefix}/internal-adm/barbershops/new-barbershops-last-months?date=${date}`
export const INTERNAL_ADM_BARBERSHOPS_BY_APPOINTMENTS = `${currentPrefix}/internal-adm/barbershops/by-appointments`
export const INTERNAL_ADM_ACTIVE_BARBERSHOPS = `${currentPrefix}/internal-adm/barbershops/active`
export const INTERNAL_ADM_NEW_REGISTERS = (startDate, endDate) =>
  `${currentPrefix}/internal-adm/new-registers?startDate=${startDate}&endDate=${endDate}`
export const INTERNAL_ADM_SEARCH_USER = `${currentPrefix}/internal-adm/new-registers/search`

// users management
export const INTERNAL_GET_USER_DATA = (user) => `${currentPrefix}/internal-adm/user-details/${user}`
export const INTERNAL_GET_BY_USER_ID = (user_id) => `${currentPrefix}/internal-adm/user-by-id/${user_id}`
export const INTERNAL_GET_BY_NAME = (name) => `${currentPrefix}/internal-adm/barbershop-name/${name}`
export const INTERNAL_GET_BY_BARBERSHOP_ID = (id) => `${currentPrefix}/internal-adm/barbershop-id/${id}`
export const INTERNAL_UPDATE_MAIN_DATA = `${currentPrefix}/internal-adm/users-management/main-data`
export const INTERNAL_UPDATE_ADDRESS = `${currentPrefix}/internal-adm/users-management/address`
export const INTERNAL_UPDATE_PASSWORD = (user_id) => `${currentPrefix}/internal-adm/update-password/${user_id}`
export const INTERNAL_INACTIVATE_USER = (id) => `${currentPrefix}/internal-adm/inactive-user/${id}`
