export const REGISTER_STEPS = [
  // {
  //   step: 0,
  //   // title: 'YOUR_BARBERSHOP',
  //   // subTitle: 'SHOW_WHO_YOU_ARE',
  //   title: 'Dados da Barbearia e do Responsável',
  //   subTitle: 'Preencha seus dados e algumas informações da sua Barbearia',
  //   path: '/cadastro/perfil-barbearia',
  // },
  {
    step: 1,
    title: 'Endereço da Barbearia',
    subTitle:
      'Digite o endereço completo no campo de busca e selecione o endereço da sua barbearia',
    path: '/cadastro/localizacao',
  },
  {
    step: 2,
    title: 'WORKING_HOURS',
    subTitle: 'Configure o horário de funcionamento da sua barbearia',
    path: '/cadastro/horario-funcionamento',
  },
  // {
  //   step: 3,
  //   title: 'AGENDA_INTERVAL',
  //   subTitle: 'AGENDA_INTERVAL_SUBTITLE',
  //   path: '/cadastro/intervalo-agenda',
  // },
  // {
  //   step: 4,
  //   title: 'PHOTOS',
  //   subTitle: 'PHOTOS_DESC',
  //   path: '/cadastro/fotos-barbearia',
  // },
  {
    step: 5,
    title: 'SERVICES',
    subTitle: 'SERVICES_DESC',
    path: '/cadastro/servicos-barbearia',
  },
  {
    step: 6,
    title: 'TEAM',
    subTitle: 'TEAM_DESC',
    path: '/cadastro/equipe',
  },
  // {
  //   step: 7,
  //   title: 'PAYMENT_METHODS',
  //   subTitle: 'PAYMENT_METHODS_DESC',
  //   path: '/cadastro/metodos-pagamentos',
  // },
  {
    step: 8,
    title: 'MEET_US',
    subTitle: 'MEET_US_DESC',
    path: '/cadastro/onde-nos-conheceu',
  },
]
