import React from 'react'
import { Text } from 'components'
import { formatPrice, formatToSimpleDate } from 'utils'
import { t } from 'services/translation'

export const productsColumns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'price',
    label: 'SALE_PRICE',
    render: (item) => <Text>{formatPrice(item.price)}</Text>,
  },
  {
    key: 'cost_price',
    label: 'COST_PRICE',
    render: (item) => <Text>{formatPrice(item.cost_price)}</Text>,
  },
  {
    key: 'producer',
    label: 'PRODUCER',
    render: (item) => (
      <Text color={!item?.producer && 'grey'}>
        {item?.producer ?? 'Não informado'}
      </Text>
    ),
  },
  {
    key: 'type',
    label: 'TYPE',
    render: (item) => (
      <Text color={!item?.producer && 'grey'}>
        {item?.type ?? 'Não informado'}
      </Text>
    ),
  },
  {
    key: 'quantity',
    label: 'QUANTIDADE EM ESTOQUE',
    width: 110,
    render: (item) => (
      <Text
        color={
          (!item?.quantity && 'grey') ||
          (item?.quantity < item?.minimum_quantity && 'error')
        }
      >
        {item.quantity ?? 'Não informado'}
      </Text>
    ),
  },
]

export const batchColumns = [
  {
    key: 'createdAt',
    label: 'DATA DO LANÇAMENTO',
    width: 80,
    render: (item) => (
      <Text>{formatToSimpleDate(item?.createdAt?.substring(0, 10))}</Text>
    ),
  },
  {
    key: 'name',
    label: 'PRODUTO',
    render: (item) => <Text>{item?.product?.name}</Text>,
  },
  {
    key: 'quantity_',
    label: 'QUANTITY_',
    render: (item) => <Text>{item.quantity}</Text>,
  },
  {
    key: 'type',
    label: 'TIPO',
    render: (item) => <Text>{t(item?.type)}</Text>,
  },
  {
    key: 'cost_price',
    label: 'COST_PRICE',
    render: (item) => <Text>{formatPrice(item.cost_price)}</Text>,
  },
  {
    key: 'sale_price',
    label: 'SALE_PRICE',
    render: (item) => <Text>{formatPrice(item.sale_price)}</Text>,
  },
]

export const mockData = [
  {
    name: 'Pomada',
    price: 15,
  },
  {
    name: 'Shampoo',
    price: 25.5,
  },
  {
    name: 'Condicionador',
    price: 20.49,
  },
  {
    name: 'Locão para barba',
    price: 39.99,
  },
  {
    name: 'Creme pós barba',
    price: 29.9,
  },
  {
    name: 'Hidratante capilar',
    price: 39.9,
  },
]
