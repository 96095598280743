import * as React from 'react'

function SvgCheckCircleOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={80} cy={80} r={77.5} stroke="currentColor" strokeWidth={5} />
      <path
        d="M53.165 80.002l19.167 19.166 38.333-38.333"
        stroke="currentColor"
        strokeWidth={10.514}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCheckCircleOutline
