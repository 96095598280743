import React, { useState, useEffect } from 'react'
import {
  Layout,
  Table,
  Text,
  PaymentMethodModal,
  MobileList,
  PaymentMethodCard,
  PremiumBlockMessage,
} from 'components'
import { useToast, useTheme } from '@chakra-ui/react'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { financesStore } from 'services/stores/finances'

import { BARBERSHOP_GET_PAYMENT_METHOD } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { usePermissionCollaborator } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'percentage',
    label: 'PERCENTAGE',
    render: (item) => <Text>{item.percentage}%</Text>,
  },
  { key: 'due_in_days', label: 'DUE_IN_DAYS' },
  {
    key: 'discount_from_barber',
    label: 'DISCOUNT_FROM_BARBER',
    render: (item) => <Text>{item.discount_from_barber ? 'Sim' : 'Não'}</Text>,
  },
]
export const PaymentMethodsScreen = ({ ...props }) => {
  const { userData } = useBarbershopStore()
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const { isDesktop } = useBreakpoint()
  const { setPaymentMethods } = financesStore()
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const toast = useToast()
  const permission = usePermissionCollaborator('payment_methods')

  async function getPaymentMethods() {
    const res = await api
      .get(BARBERSHOP_GET_PAYMENT_METHOD(userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const {onLogout} = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const { data, refetch, isLoading } = useQuery(
    'get-payment-methods',
    getPaymentMethods,
    {
      onSuccess: (value) => {
        setPaymentMethods(value)
      },
      onError: (error) => {
        toast({
          title: t('LOAD_PAYMENT_METHODS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onSelectItem = (item) => {
    if (permission !== 'blocked') {
      setSelectedItem(item)
      setModalVisible(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const onCloseModal = () => {
    setModalVisible(false)
    setSelectedItem()
    refetch()
  }

  const freeAccountLimit = data?.length >= 2

  return (
    <Layout
      {...props}
      headerTitle={t('PAYMENT_METHODS')}
      headerActionText={permission === 'edit' && t('ADD_PAYMENT_METHOD')}
      headerActionClick={() => setModalVisible(true)}
      premiumBlockMessage={
        isBlocked && freeAccountLimit && 'PAYMENT_METHODS_BLOCK_MESSAGE_HEADER'
      }
      px={130}
    >
      {isDesktop ? (
        <Table
          isSelectableRow
          onSelect={onSelectItem}
          data={data || []}
          loading={isLoading}
          columns={columns}
        />
      ) : (
        <MobileList
          data={data || []}
          loading={isLoading}
          renderItem={({ item, index }) => (
            <PaymentMethodCard
              inList
              paymentMethod={item}
              index={index}
              onClick={() => onSelectItem(item)}
            />
          )}
        />
      )}

      {isBlocked && freeAccountLimit && (
        <PremiumBlockMessage
          message="PAYMENT_METHODS_BLOCK_MESSAGE"
          mt={theme.pxToRem(16)}
        />
      )}

      {modalVisible && (
        <PaymentMethodModal
          isModalOpen={modalVisible}
          onClose={onCloseModal}
          selected={selectedItem}
        />
      )}
    </Layout>
  )
}

PaymentMethodsScreen.path = '/meios-de-pagamento'
PaymentMethodsScreen.title = 'Meios de Pagamento'
PaymentMethodsScreen.secured = true
