import React, { useState, useMemo } from 'react'
import { useTheme, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RegisterWrapper } from 'components/templates'
import { Text, Flex, Button, Box, Icon } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { generateHoursList } from 'utils'
import { useBarbershopStore } from 'services/stores'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { BARBERSHOP_AGENDA_INTERVAL } from 'services/api/endpoints'

export const AgendaIntervalScreen = () => {
  const [agenda_time_interval, setAgendaTimeInterval] = useState(10)
  const { isMobile } = useBreakpoint()
  const { t } = useTranslation()
  const theme = useTheme()
  const toast = useToast()
  const history = useHistory()
  const { setAgendaInterval, userData } = useBarbershopStore()

  async function getAgendaInterval() {
    const res = await api
      .get(BARBERSHOP_AGENDA_INTERVAL(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-agenda-interval', getAgendaInterval, {
    onSuccess: (data) => {
      if (data) {
        setAgendaTimeInterval(data ? data : 10)
        setAgendaInterval(data)
      }
    },
  })

  async function changeAgendaInterval(params) {
    const res = await api
      .put(
        BARBERSHOP_AGENDA_INTERVAL(userData?.barbershop?.id || userData?.id),
        params.params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-agenda-interval',
    (params) => changeAgendaInterval(params),
    {
      onSuccess: (data) => {
        toast({
          title: t('AGENDA_INTERVAL_SAVED'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/cadastro/fotos-barbearia')
      },
      onError: (error) => {
        toast({
          title: t('AGENDA_INTERVAL_SAVE_FAIL'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onClickSave = () => {
    mutate.mutate({
      params: {
        agenda_time_interval,
      },
    })
  }

  const hoursList = useMemo(() => {
    const list = generateHoursList(agenda_time_interval)

    return list
  }, [agenda_time_interval])

  const handleAddMinutes = () => {
    setAgendaTimeInterval(agenda_time_interval + 5)
  }

  const handleDecreaseMinutes = () => {
    setAgendaTimeInterval(
      agenda_time_interval - 5 > 10 ? agenda_time_interval - 5 : 10
    )
  }

  return (
    <RegisterWrapper barbershopSetup onContinue={onClickSave}>
      <Flex flexDir="column" w="100%" h="100%">
        <Flex w="100%">
          <Flex
            alignItems="center"
            justifyContent="center"
            px={theme.pxToRem(18)}
            mr={theme.pxToRem(16)}
            mb={theme.pxToRem(42)}
            width="100%"
            height={theme.pxToRem(56)}
            bgColor="cardBackground"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={theme.pxToRem(8)}
          >
            <Text kind="medium" fontSize={14}>{`${agenda_time_interval} ${t(
              'MINUTES'
            )}`}</Text>
          </Flex>

          <Flex w="fit-content">
            <Button
              disabled={agenda_time_interval === 10}
              size="square"
              kind="outline"
              color="primary"
              borderColor="agendaIntervalBackground"
              mr={theme.pxToRem(16)}
              onClick={handleDecreaseMinutes}
            >
              <Icon name="Minus" size={20} />
            </Button>
            <Button
              size="square"
              kind="outline"
              color="primary"
              borderColor="agendaIntervalBackground"
              onClick={handleAddMinutes}
            >
              <Icon name="Plus" size={20} />
            </Button>
          </Flex>
        </Flex>

        <Box w="100%" textAlign={isMobile && 'center'}>
          <Text kind="extraBold" fontSize={18} mb={theme.pxToRem(2)}>
            {t('YOUR_AGENDA_INTERVAL')}
          </Text>
          <Text
            kind="medium"
            fontSize={14}
            color="textGrey"
            mb={theme.pxToRem(24)}
          >
            {t('YOUR_AGENDA_INTERVAL_DESC')}
          </Text>

          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            alignItems="center"
            justifyContent="center"
            w="100%"
          >
            {hoursList.slice(0, isMobile ? 3 : 5).map((item, index) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={isMobile && theme.pxToRem(8)}
                w={!isMobile && '100%'}
              >
                <Flex
                  key={`time_${index}`}
                  alignItems="center"
                  justifyContent="center"
                  p={theme.pxToRem(16)}
                  width={isMobile ? theme.pxToRem(170) : theme.pxToRem(80)}
                  height={theme.pxToRem(56)}
                >
                  <Text kind="extraBold">{item}</Text>
                </Flex>

                {!isMobile && index !== 4 && <Icon name="ChevronRight" />}
              </Flex>
            ))}
          </Flex>
        </Box>
      </Flex>
    </RegisterWrapper>
  )
}

AgendaIntervalScreen.path = '/cadastro/intervalo-agenda'
AgendaIntervalScreen.title = ''
AgendaIntervalScreen.secured = true
