import React, { useState } from 'react'
import { Box, Text, Flex, Button, Table, Avatar, TextInput } from 'components'
import { useTheme } from '@chakra-ui/react'
import { RescueRewardsModal } from './RescueRewardsModal'
import { HistoricalClientModal } from './HistoricalClientModal'
import { useBarbershopStore } from 'services/stores'
import { Search } from 'assets/icons'
import { colors } from 'styles'
import { useBreakpoint } from 'services/hooks'

import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

export const PointsForClients = () => {
  const { userData, setUserData } = useBarbershopStore()
  const { getClient } = useBestcoins()

  const theme = useTheme()
  const [selectedClient, setSelectedClient] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [historicalModalOpen, setHistoricalModalOpen] = useState(false)
  const [query, setQuery] = useState('')

  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()

  const { isLoading, data: originalClientList } = useQuery(
    ['bestcoins-points-for-client'],
    () =>
      getClient({
        params: { barbershop_id: userData?.barbershop?.id || userData?.id },
      }),
    {
      onSuccess: (data) => setClientFiltered(data),
    }
  )
  const [clientFiltered, setClientFiltered] = useState(originalClientList)

  const columns = [
    {
      label: 'Imagem',
      render: (item) => (
        <Avatar
          src={item?.client?.profile_image_url}
          alt="Imagem do Cliente"
          style={{ width: 40, height: 40, borderRadius: '50%' }}
        />
      ),
    },
    {
      label: 'Cliente',
      key: 'client',
      render: (item) => <Text>{item?.client?.name}</Text>,
    },
    {
      label: 'Saldo de Pontos',
      key: 'bestcoins_balance',
      render: (item) => <Text>{item?.bestcoins_balance?.toFixed(2) || 0} pontos</Text>,
    },
    {
      label: 'Pontos resgatados',
      key: 'bestcoins_exchanged',
      render: (item) => <Text>{item?.bestcoins_exchanged?.toFixed(2) || 0} pontos</Text>,
    },
  ]

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setClientFiltered(originalClientList)
    } else {
      const search_result = originalClientList?.filter(
        (item) =>
          String(item?.client?.name.toLowerCase()).includes(text.toLowerCase()) ||
          String(item?.client?.user?.email?.toLowerCase()).includes(text.toLowerCase())
      )
      setClientFiltered(search_result)
    }
  }

  const onclickHistoricalModal = () => {
    setHistoricalModalOpen(true)
  }

  const onCloseHistoricalModal = () => {
    setHistoricalModalOpen(false)
  }

  const onCloseRescueRewardsModal = () => {
    setIsModalOpen(false)
  }

  const onclickRescueRewardsButton = () => {
    setIsModalOpen(true)
  }
  return (
    <Box>
      <Flex pb={theme.pxToRem(12)} w="100%" justify="space-between">
        <Flex align="center" mb={theme.pxToRem(10)}>
          <Text kind="extraBold" fontSize={theme.pxToRem(16)}>
            Pontos por cliente
          </Text>
        </Flex>
        <Button w={260} h={10} kind={'primary'} ml={theme.pxToRem(12)} onClick={() => onclickRescueRewardsButton()}>
          + Resgatar prêmio para cliente
        </Button>
      </Flex>
      <Box mt={4}>
        <TextInput
          noBorder
          type="text"
          width="full"
          color="white"
          placeholder={t('SEARCH_SUBSCRIBERS')}
          value={query}
          mb={!isDesktop && theme.pxToRem(16)}
          onChange={(event) => onSearch(event.target.value)}
          leftElement={<Search color={colors.primary} />}
        />
      </Box>
      <Box>
        <Table
          loading={isLoading}
          isSelectableRow
          data={clientFiltered}
          columns={columns}
          onSelect={() => onclickHistoricalModal()}
        />
      </Box>
      <RescueRewardsModal isOpen={isModalOpen} onClose={onCloseRescueRewardsModal} selectedClient={selectedClient} />
      <HistoricalClientModal isOpen={historicalModalOpen} onClose={onCloseHistoricalModal} />
    </Box>
  )
}
