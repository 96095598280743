import React from 'react'
import { colors } from 'styles/colors'
import { Flex } from '@chakra-ui/react'
import screen_background from 'assets/img/backgrounds/login.png'
import { Logo, Text, Button, Link } from 'components/atoms'

export const AuthLayout = ({ children, ...props }) => {
  return (
    <Flex
      backgroundImage={`url(${screen_background})`}
      backgroundPosition="top"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      overflowX="hidden"
      w="100%"
      h="100vh"
      justifyContent="center"
      align="center"
    >
      <Flex
        align={'center'}
        backgroundColor="cardBackground"
        justifyContent="center"
        flexDir="column"
        w={640}
        py={16}
        px={10}
        borderRadius={8}
      >
        <Logo size="medium" />
        <Text kind="extraBold" fontSize={24} mt={6}>
          {props?.title}
        </Text>
        <Text align="center" kind="semiBold" fontSize={16} mt={4} mb={4}>
          {props?.subtitle}
        </Text>

        {children}

        <Button
          isLoading={props?.button?.isLoading}
          w="100%"
          disabled={props?.button?.disabled}
          onClick={props?.button?.onClick}
          mt={4}
        >
          {props?.button?.text}
        </Button>
        <Link onClick={props?.link?.onClick} mt={6}>
          <Text
            kind="bold"
            fontSize={16}
            as="u"
            textDecoration="none"
            color={colors.primary}
          >
            {props?.link?.text}
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}
