import { VStack } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import { ProfileMenuItem } from 'components/atoms/ProfileMenuItem'
import { useBarbershop, useBreakpoint } from 'services/hooks'

export const ConfigurationMenu = ({ route, onSelect, userData }) => {
  const { isDesktop } = useBreakpoint()

  return (
    <VStack w={isDesktop && pxToRem(335)} spacing={pxToRem(14)}>
      <ProfileMenuItem
        title="Tempo mínimo para cancelamento"
        to="/configuracoes/tempo-cancelamento"
        active={route === 'tempo-cancelamento'}
        icon="Clock"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title="Tempo máximo de agendamento"
        to="/configuracoes/maximo-agendamento"
        active={route === 'maximo-agendamento'}
        icon="Calendar"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title="Disposição dos horários disponíveis"
        to="/configuracoes/horarios-disponiveis"
        active={route === 'horarios-disponiveis'}
        icon="Clock"
        onSelect={onSelect}
      />
      <ProfileMenuItem
        title="Tempo mínimo de agendamento"
        to="/configuracoes/minimo-agendamento"
        active={route === 'minimo-agendamento'}
        icon="Clock"
        onSelect={onSelect}
      />
      {userData?.signature_club_enable && (
        <ProfileMenuItem
        title="Clube habilitado no app"
        to="/configuracoes/clube-no-app"
        active={route === 'clube-no-app'}
        icon="Cellphone"
        onSelect={onSelect}
      />
      )}
    </VStack>
  )
}
