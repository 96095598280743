import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout,
  Table,
  Text,
  FinanceResumeCard,
  MobileList,
  BarberCommissionReportDetailCard,
  BarberReportCard,
  AppointmentDetailsModal,
  Icon,
  LineChartRechart,
  SingleProductModal,
  ClearedComissionModal,
  WorthBarberModal,
} from 'components'
import { Center, Flex, Grid, GridItem, Spinner, useTheme } from '@chakra-ui/react'
import {
  formatPrice,
  formatToSimpleDate,
  formatAppointmentsToChart,
  useQuery,
  handleAppoitmentStatusColor,
} from 'utils'
import { useCommissionStore, useBarbershopStore, useAppointmentDetailStore } from 'services/stores'
import { useBreakpoint } from 'services/hooks'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useQuery as queryReact, useMutation, useQueryClient } from 'react-query'
import './hideScrollbar.css'
import { t } from 'services/translation'
import { useBarbershop } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useBarber } from 'services/hooks/use-barber'

const columns = (userData) => {
  const arr = [
    {
      key: 'date',
      label: 'DATE_TABLE',
      render: (item) => (
        <Text kind="medium" fontSize={14}>
          {formatToSimpleDate(item?.simple_date || item?.date || item?.createdAt)}
        </Text>
      ),
    },
    {
      key: 'id',
      label: 'NUM. COMANDA',
      render: (item) => (
        <Text kind="medium" fontSize={14}>
          {item?.id}
        </Text>
      ),
    },
    {
      key: 'client',
      label: 'CLIENT_TABLE',
      render: (item) => (
        <Text kind="medium" fontSize={14}>
          {item?.type === 'worth' || item?.type === 'revenue' || item?.type === 'singleProductBarber'
            ? ' - '
            : item?.client?.name}
        </Text>
      ),
    },
    {
      key: 'service',
      label: 'SELLS',
      render: (item) => (
        <Text kind="medium" fontSize={14} maxW="120px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {item?.type === 'singleProduct'
            ? 'Venda de produto'
            : item?.type === 'worth'
            ? item?.description || 'Vale'
            : item?.type === 'revenue'
            ? item?.description
            : item?.type === 'singleProductBarber'
            ? 'Compra de produto'
            : item?.appointmentServices?.map((i) => i?.product?.name)?.join(', ')}
        </Text>
      ),
    },
    {
      key: 'payment',
      label: 'PAYMENT_TABLE',
      render: (item) => (
        <Text
          kind="medium"
          fontSize={14}
          color={handleAppoitmentStatusColor(item?.status)}
          maxW="120px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {item?.type === 'worth' || item?.type === 'revenue'
            ? t(item.status)
            : item?.type === 'signature'
            ? 'Clube de assinatura'
            : item?.payment_method_choosed?.length > 0
            ? item?.payment_method_choosed?.map((i) => i?.name).join(', ')
            : item?.type === 'singleProductBarber' && item?.payment_method_choosed?.length === 0
            ? 'Abatido em comissão'
            : t(item?.status)}
        </Text>
      ),
    },
    {
      key: 'value',
      label: 'GROSS_VALUE_TABLE',
      render: (item) => (
        <Text
          kind="medium"
          fontSize={14}
          color={item?.type === 'worth' || item?.type === 'singleProductBarber' ? 'danger' : 'white'}
        >
          {formatPrice(
            item?.type === 'worth' || item?.type === 'revenue'
              ? item?.value
              : item?.appointmentServices?.reduce((acc, val) => acc + val.service_value, 0) +
                  item?.appointmentProducts?.reduce((acc, val) => acc + val.product_value, 0)
          )}
        </Text>
      ),
    },
    {
      key: 'total',
      label: 'NET_VALUE_TABLE',
      render: (item) => (
        <Text kind="medium" fontSize={14} color={item?.type === 'singleProductBarber' ? 'danger' : 'success'}>
          {item?.type === 'worth'
            ? ' - '
            : formatPrice(
                item?.type === 'revenue'
                  ? item?.value
                  : item?.type === 'singleProductBarber'
                  ? -item?.total_after_discounts
                  : item?.total_after_discounts
              )}
        </Text>
      ),
    },
    {
      key: 'commission',
      label: 'BARBER_COMMISSION_SERVICE_',
      render: (item) => (
        <Text kind="medium" fontSize={14} color="primary">
          {item?.type === 'worth' || item?.type === 'revenue'
            ? ' - '
            : formatPrice(item?.appointmentServices.reduce((acc, val) => acc + (val?.barber_value || 0), 0))}
        </Text>
      ),
    },
    {
      key: 'commission',
      label: 'BARBER_COMMISSION_PRODUCT_',
      render: (item) => (
        <Text kind="medium" fontSize={14} color="primary">
          {item?.type === 'worth' || item?.type === 'revenue'
            ? ' - '
            : formatPrice(item?.appointmentProducts.reduce((acc, val) => acc + (val?.barber_value || 0), 0))}
        </Text>
      ),
    },
  ]

  if (userData?.signature_club_enable) {
    arr.push({
      key: 'points',
      label: 'TOTAL DE PONTOS',
      render: (item) => (
        <Text kind="medium" fontSize={14} color="white">
          {item?.type === 'signature'
            ? item?.appointmentServices.reduce((acc, val) => acc + (val.points || 0), 0)
            : '-'}
        </Text>
      ),
    })
  }

  return arr
}

export const BarbersCommissionReportDetailScreen = ({ ...props }) => {
  const theme = useTheme()
  const history = useHistory()
  const { selectedCommission } = useCommissionStore()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const [data, setData] = useState(false)
  const [enable, setEnable] = useState(false)
  const [filteredData, setFilteredData] = useState()
  const { userData } = useBarbershopStore()
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false)
  const [clearedModalOpen, setClearedModalOpen] = useState(false)
  const { setSelectedAppointment } = useAppointmentDetailStore()
  const [singleProductModalOpen, setSingleProductModalOpen] = useState(false)
  const [worthModalOpen, setWorthModalOpen] = useState(false)
  const [product, setProduct] = useState(false)
  const [worth, setWorth] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const { exportComissionReport, test } = useBarbershop()
  const { getBarberComissionResume } = useBarber()
  // const queryClient = useQueryClient()
  const [app, setApp] = useState()

  const { isLoading } = queryReact(
    ['get-barber-resume-comission', selectedCommission],
    () =>
      getBarberComissionResume({
        body: {
          start_date: selectedCommission?.startDate,
          end_date: selectedCommission?.endDate,
          employee_id: selectedCommission?.comission?.employee_id,
          barber_id: selectedCommission?.comission?.barber_id,
          barbershop_id: userData?.id,
        },
      }),
    {
      onSuccess: (d) => {
        setData({
          ...d,
          all_releases: d?.all_releases?.sort(
            (a, b) =>
              parseISO(b?.payment_date || b?.due_at_date || b?.date || b?.createdAt).getTime() -
              parseISO(a?.payment_date || a?.due_at_date || a?.date || b?.createdAt).getTime()
          ),
        })
      },
      refetchOnWindowFocus: false,
    }
  )
  const onSelectAppointment = (appointment) => {
    setApp(appointment)
    if (
      appointment?.type === 'worth' ||
      (appointment?.type === 'revenue' && appointment?.description && appointment?.barber)
    ) {
      setWorth(appointment)
      setWorthModalOpen(true)
      return
    }
    if (appointment?.type === 'singleProduct' || appointment?.type === 'singleProductBarber') {
      setSingleProductModalOpen(true)
      setProduct(appointment)
      return
    }
    setSelectedAppointment(appointment)
    setAppointmentModalOpen(true)
  }

  const onCloseModal = () => {
    setSingleProductModalOpen(false)
    setSelectedAppointment(null)
    setProduct(null)
    setAppointmentModalOpen(false)
  }

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const mutate = useMutation(
    ['get-barber-excel-detail'],
    (params) => exportComissionReport({ id: params.id, params: params }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
        setEnable(false)
      },
      enabled: enable,
    }
  )

  const handleExport = () => {
    const params = {
      start_date: selectedCommission?.startDate,
      end_date: selectedCommission?.endDate,
      id: userData?.barbershop?.id || userData?.id,
      barber_id: selectedCommission?.comission?.barber_id,
      employee_id: selectedCommission?.comission?.employee_id,
      report: 'detail',
    }

    mutate.mutate(params)
  }

  return (
    <Layout
      {...props}
      isDetailScreen
      // withouPading
      iconActionText="Money"
      headerTitle={t('COMMISSION_REPORT_DETAILS_TITLE')}
      headerActionText={t('CLEARED_COMISSION')}
      headerActionClick={() => setClearedModalOpen(true)}
      handleBackNavigation={() => history.goBack()}
      widthTitle={!isDesktop && '80%'}
      secondActionClick={handleExport}
      secondActionText="Exportar em excel"
      secondLoading={mutate.isLoading}
      iconSecondActionText={'Excel'}
      thirtyClick={{
        icon: 'Money',
        iconColor: 'black',
        onClick: () => setWorthModalOpen(true),
        text: 'Adicionar vale para o barbeiro',
      }}
    >
      <>
        {!data || isLoading ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : isDesktop ? (
          <Flex w="100%" flexDir="column">
            <Grid templateColumns="25% 25% 12.5% auto" gap={4}>
              {/* First Column */}
              <GridItem>
                <Flex flexDir="column" alignItems="stretch" justifyContent="space-between" pb={theme.pxToRem(48)}>
                  <BarberReportCard item={selectedCommission} width="100%" mb={24} employee={data?.employee} />
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FinanceResumeCard
                      width="100%"
                      label={t('COMMISSIONS')}
                      description={t('TOTAL_COMMISSION')}
                      value={selectedCommission?.comission?.total_comission || 0}
                      background
                      balance="cardBackground"
                      color="success"
                    />
                    <FinanceResumeCard
                      width="100%"
                      label="Extras"
                      description={t('TOTAL_COMMISSION')}
                      value={selectedCommission?.comission?.extra_value || 0}
                      background
                      balance="cardBackground"
                      color="success"
                    />
                  </Grid>
                </Flex>
              </GridItem>

              {/* Second Column */}
              <GridItem>
                <Grid templateRows="auto auto" gap={6}>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FinanceResumeCard
                      label="Comissão"
                      width="100%"
                      description="Serviços normais"
                      color="success"
                      value={selectedCommission?.comission?.commom_services_comission}
                    />
                    <FinanceResumeCard
                      label="Comissão"
                      width="100%"
                      description="Serviços do clube"
                      color="success"
                      value={selectedCommission?.comission?.comission_signature}
                    />
                  </Grid>

                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FinanceResumeCard
                      label="Total"
                      width="100%"
                      description="Débitos"
                      color="danger"
                      value={
                        (selectedCommission?.comission?.shopping_value + selectedCommission?.comission?.worth_value) *
                        -1
                      }
                    />
                    <FinanceResumeCard
                      label="Débitos"
                      width="100%"
                      description="Pagos"
                      color="blue"
                      value={selectedCommission?.comission?.debits_paid}
                    />
                  </Grid>
                </Grid>
              </GridItem>

              <GridItem>
                <Grid templateRows="auto" gap={6}>
                  <FinanceResumeCard
                    label={t('Comissão')}
                    width="100%"
                    description={t('Produtos')}
                    color="success"
                    value={selectedCommission?.comission?.products_comission}
                  />
                  <FinanceResumeCard
                    label="Débitos"
                    width="100%"
                    description="Pendentes"
                    color="danger"
                    value={-selectedCommission?.comission?.debits_pending}
                  />
                </Grid>
              </GridItem>

              {/* Third Column */}
              <GridItem h={theme.pxToRem(224)} display="flex" alignItems="center" justifyContent="center">
                {!data ? (
                  <Flex flexDir="column" align="center">
                    <Icon name="Chart" size={50} />
                    <Text>{t('NO_DATA_RESUME')}</Text>
                  </Flex>
                ) : (
                  <LineChartRechart
                    key="appointments"
                    data={formatAppointmentsToChart(
                      selectedCommission?.startDate,
                      selectedCommission?.endDate,
                      selectedCommission?.comission,
                      data?.appointments
                    )}
                  />
                )}
              </GridItem>
            </Grid>

            <Flex align="center">
              <Text>{`Total: ${data?.all_releases?.length || 0} ${
                data?.all_releases?.length > 1 ? 'Lançamentos' : 'Lançamento'
              }`}</Text>
            </Flex>

            <Table
              data={data?.all_releases || []}
              columns={columns(userData)}
              isSelectableRow
              onSelect={(item) => onSelectAppointment(item)}
            />
          </Flex>
        ) : (
          <Flex flexDirection="column" width="100%">
            <Flex w="49%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(272)}>
              <BarberReportCard
                fontSize={14}
                item={selectedCommission}
                wMobile="100%"
                h={120}
                employee={data?.employee}
              />
              <FinanceResumeCard
                label={t('TOTAL')}
                wMobile="100%"
                description={t('BARBER_COMMISSION_SERVICE')}
                color="success"
                value={selectedCommission?.services_comission}
                h={120}
              />
              <FinanceResumeCard
                wMobile="100%"
                label={t('COMMISSIONS')}
                description={t('TOTAL_COMMISSION')}
                value={selectedCommission?.total_comission || 0}
                background
                h={120}
                color="success"
                // balance
                // resize
              />
              <FinanceResumeCard
                label={t('TOTAL')}
                wMobile="100%"
                description={t('BARBER_COMMISSION_PRODUCT')}
                color="success"
                value={selectedCommission?.products_comission}
                h={120}
              />
            </Flex>
            <MobileList
              data={data?.all_releases || []}
              renderItem={({ item, index }) => (
                <BarberCommissionReportDetailCard
                  item={item}
                  index={index}
                  onSelect={(item) => onSelectAppointment(item)}
                />
              )}
            />
          </Flex>
        )}
      </>

      {singleProductModalOpen && (
        <SingleProductModal isOpen={singleProductModalOpen} onClose={onCloseModal} selectedSale={product} />
      )}

      {appointmentModalOpen && (
        <AppointmentDetailsModal
          isModalOpen={appointmentModalOpen}
          onClose={onCloseModal}
          setAppointmentId={setApp}
          app={app}
        />
      )}
      {clearedModalOpen && (
        <ClearedComissionModal isModalOpen={clearedModalOpen} onClose={() => setClearedModalOpen(false)} />
      )}
      {worthModalOpen && (
        <WorthBarberModal isModalOpen={worthModalOpen} edit={worth} onClose={() => setWorthModalOpen(false)} />
      )}
    </Layout>
  )
}

BarbersCommissionReportDetailScreen.path = '/detalhes-comissao'
BarbersCommissionReportDetailScreen.title = 'Detalhes da Comissão'
BarbersCommissionReportDetailScreen.secured = true
