import create from 'zustand'
import { useBarbershopStore } from './barbershop'

export const financesStore = create((set, get) => ({
  monthFinances: null,
  setMonthFinances: (monthFinances) => set({ monthFinances }),

  financesCategory: [],
  setFinancesCategory: (financesCategory) => set({ financesCategory }),

  loading: false,
  setLoading: (loading) => set({ loading }),

  revenues: false,
  setRevenues: (revenues) => set({ revenues }),

  paymentMethods: false,
  setPaymentMethods: (paymentMethods) => set({ paymentMethods }),

  barbersFinances: false,
  setBarbersFinances: (barbersFinances) => set({ barbersFinances }),

  barbersMonthExtract: false,
  setBarbersMonthExtract: (barbersMonthExtract) => set({ barbersMonthExtract }),

  expenses: false,
  setExpenses: (expenses) => set({ expenses }),

  monthExtract: [],
  setMonthExtract: (monthExtract) => set({ monthExtract }),

  unity: useBarbershopStore.getState()?.userData,
  setUnity: (unity) => set({ unity }),
}))
