export const colors = {
  primary: '#E5B817',
  brand: {
    primary: '#E5B817',
    primaryLight: '#EBE9E1',
    secondary: '#2B3038',
    secondaryLight: '#DBEEF0',
  },
  white: '#FFFFFF',
  black: '#000000',

  background: '#313740',
  menuBackground: '#20242A',
  agendaIntervalBackground: '#4E535A',
  boxBackground: '#252A31',
  backgroundLayout: '#181B20',
  cardBackground: '#2B3038',
  componentBorder: '#E2E9E9',
  tabsBorder: '#C5C4BF',
  modalBorder: '#363D49',
  textLight: '#EBE9E1',
  textMedium: '#9E9F9D',
  textDark: '#20242A',
  textGrey: '#AEB0B2',
  textGreyMedium: '#C5C4BF',

  baseRed: '#EA1D2C',
  blue: '#2E93fA',

  pieChart: [
    '#2E93fA',
    '#66DA26',
    '#546E7A',
    '#E91E63',
    '#FF9800',
    '#70C270',
    '#EA1D2C',
    '#A31621',
    '#6C9093',
    '#000',
    '#C53911',
    '#E5B817',
    '#57955e',
    '#7c5926',
    '#f3b5e2',
    '#0c0ca9',
    '#8a4f5a',
    '#58c8a1',
    '#9df348',
    '#fb6ef6',
    '#83c801',
    '#3451d8',
    '#a870cf',
    '#ba2669',
    '#30ffeb',
    '#f6f448',
    '#47cd0e',
    '#458094',
    '#061161',
    '#4e9144',
    '#a25f43',
    '#727e0f',
    '#063046',
    '#9c0625',
    '#5e3988',
    '#18f27f',
    '#0d87d4',
    '#df75b3',
    '#cbb56a',
    '#ba0049',
    '#35df56',
    '#b403b3',
    '#250427',
    '#75daa6',
    '#7163a6',
    '#5e7f75',
    '#f99a56',
    '#c0a220',
    '#de611c',
  ],

  grey: {
    100: '#FAFAFA',
    200: '#E2E9E9',
    300: '#C4D3D4',
    400: '#90AAAD',
    500: '#6C9093',
    600: '#577375',
  },
  green: {
    100: '#D9F2D9',
    400: '#70C270',
    700: '#2E6B2E',
  },
  red: {
    100: '#FEF1F2',
    600: '#C53911',
    700: '#EA1D2C',
  },
  yellow: {
    200: '#FFF0C2',
    700: '#7A631F',
  },
  danger: '#EA1D2C',
  dangerDark: '#A31621',
  success: '#70C270',
  dangerCard: '#D92635',
  transparent: {
    100: 'transparent',
    200: 'rgba(0, 0, 0, 0.3)',
    300: 'rgba(0, 0, 0, 0.08)',
    400: 'rgba(0, 0, 0, 0.02)',
    500: 'rgba(0, 0, 0, 0.5)',
    600: 'rgba(0, 0, 0, 0.75)',
    700: 'rgba(0, 0, 0, 0.85)',
  },
  whiteTransparent: {
    100: 'rgba(255, 255, 255, 0.1)',
    200: 'rgba(255, 255, 255, 0.3)',
    300: 'rgba(255, 255, 255, 0.5)',
    400: 'rgba(255, 255, 255, 0.7)',
  },
  primaryTransparent: 'rgba(27, 18, 255, 0.85)',
  bgOverlay: 'rgba(45, 43, 89, 0.2)',

  primaryIsrael: '#FFFFFF',
  primaryDark: '#FFFFFF',
  secondary: '#111111',

  backgroundIsrael: '#20242A',
  background2: '#272C33',

  component: '#313740',
  component2: '#2B3038',

  whiteIsrael: '#EBE9E1',
  blackIsrael: '#000000',

  successIsrael: '#359925',
  successDark: '#588F3D',
  positive: '#70C270',
  negative: '#EA1D2C',
  error: '#EA1D2C',
  orange: '#de4e00',
  transparentIsrael: 'transparent',
  greyIsrael: '#9A9C9F',
  darkGrey: '#353940',
  darkGrey2: '#4c4c4c',
  backgroundComponent: '#2B3038',
  backgroundComponentLight: '#313740',
  backgroundBottomBar: '#272C33',
  border: '#363D49',

  textColorLight: '#E5E3DC',
  textColorLighter: '#EBE9E1',
  textColorDark: '#20242A',
  textColorGrey: '#AEB0B2',

  // lightTransparent: 'rgba(59,66,77, 0.75)',
  darkTransparent: [
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0.1)',
    'rgba(0, 0, 0, 0.2)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.4)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.6)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.9)',
    'rgba(0, 0, 0, 1)',
  ],
  lightTransparent: [
    'rgba(255, 255, 255, 0)',
    'rgba(255, 255, 255, 0.1)',
    'rgba(255, 255, 255, 0.2)',
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.4)',
    'rgba(255, 255, 255, 0.5)',
    'rgba(255, 255, 255, 0.6)',
    'rgba(255, 255, 255, 0.7)',
    'rgba(255, 255, 255, 0.9)',
    'rgba(255, 255, 255, 1)',
  ],
  greyTransparent: [
    'rgba(59, 59, 59, 0)',
    'rgba(59, 59, 59, 0.1)',
    'rgba(59, 59, 59, 0.2)',
    'rgba(59, 59, 59, 0.3)',
    'rgba(59, 59, 59, 0.4)',
    'rgba(59, 59, 59, 0.5)',
    'rgba(59, 59, 59, 0.6)',
    'rgba(59, 59, 59, 0.7)',
    'rgba(59, 59, 59, 0.9)',
    'rgba(59, 59, 59, 1)',
  ],
}
