import React from 'react'
import { useTheme } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Box, Flex, Text, Icon } from 'components/atoms'
import { formatPrice } from 'utils'

export const BarbersCommissionReportCard = ({
  item,
  index,
  onSelect = () => {},
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Flex
      key={`data_commission_report_${index}`}
      alignItems="center"
      justifyContent="space-between"
      paddingX={theme.pxToRem(20)}
      paddingY={theme.pxToRem(12)}
      marginBottom={theme.pxToRem(16)}
      width="full"
      height="fit-content"
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      onClick={() => onSelect(item)}
    >
      <Box w="100%">
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <Box width="50%">
            <Text
              kind="medium"
              fontColor="textLight"
              fontSize={theme.pxToRem(14)}
            >
              {item?.barber?.name}
            </Text>
            <Text
              kind="medium"
              fontSize={theme.pxToRem(14)}
              fontColor="textGrey"
            >
              {item?.barberAppointments?.length}
            </Text>
          </Box>

          <Box width="50%">
            <Text
              kind="medium"
              fontColor="textLight"
              fontSize={theme.pxToRem(14)}
              marginBottom={theme.pxToRem(4)}
            >
              {item?.barber?.percentage || 0}% {t('COMMISSION')}
            </Text>
            <Text kind="medium" color="success" fontSize={theme.pxToRem(14)}>
              {formatPrice(item?.total_comission)}
            </Text>
          </Box>
          {/* <Text
          fontColor="textLight"
          fontSize={theme.pxToRem(14)}
          fontWeight={500}
        >
          {formatPrice(item?.barberTotalSales)}
        </Text> */}
        </Flex>
      </Box>

      <Box>
        <Icon name="ChevronRight" color="primary" />
      </Box>
    </Flex>
  )
}
