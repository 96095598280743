import * as React from 'react'

function SvgAlarmClock(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 74 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.5 74C53.897 74 68 59.897 68 42.5S53.897 11 36.5 11 5 25.103 5 42.5 19.103 74 36.5 74z"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46 33L27 52m0-19l19 19-19-19z"
        stroke="currentColor"
        strokeWidth={4.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 2L2.125 11M59.5 2l12.375 9"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgAlarmClock
