import * as Yup from 'yup'
import { t } from 'services/translation'

export const validationSchema = Yup.object().shape({
  value: Yup.string(),
  pixAddressKey: Yup.string(),
})

export const inputs = (props) => {
  return [
    {
      id: 0,
      name: 'value',
      label: 'VALUE',
      noBorder: true,
      mask: 'CURRENCY_MASK',
      type: 'money',
      placeholder: 'R$ 10,00',
    },
    {
      id: 1,
      name: 'pixAddressKey',
      label: 'PIX_KEY',
      placeholder: t('PIX_KEY'),
      mask: props?.mask || false,
      type: props?.type,
      autoCorrect: false,
      noBorder: true,
      autoCapitalize: false,
    },
  ]
}

export const initialValues = {
  pixAddressKey: '',
  value: '',
}

export const form = {
  initialValues,
  validationSchema,
  inputs,
}
