import React, { useMemo, useState } from 'react'
import { useTheme, Select } from '@chakra-ui/react'
import { Flex, Text, TextInput, Button } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint, useBarbershop } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { ModalMenu } from 'components/molecules'
import { useMutation, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { types } from './settings'
import { format } from 'date-fns'

export const ClientReleaseModal = ({ isModalOpen, onClose, refetch, data, ...props }) => {
  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const [value, setValue] = useState()
  const [description, setDescription] = useState()
  const [type, setType] = useState()
  const theme = useTheme()
  const { createClientRelease } = useBarbershop()
  const queryClient = useQueryClient()

  const mutate = useMutation('create-client-release', (params) => createClientRelease(params))

  const onSuccess = (res) => {
    queryClient.invalidateQueries(['get-client-detail'])
    onClose()
    setValue()
    setDescription()
    setType()
  }

  const isDisabled = useMemo(() => {
    return !value || value?.length === 0 || Number(value) === 0 || !type || type?.length === 0 || !description
  }, [value, type, description])

  const handleCreateRelease = () => {
    const params = {
      barbershop_id: userData?.barbershop?.id || userData?.id,
      client_id: data?.client_id,
      type,
      value: Number(value?.replace?.(',', '.')),
      description,
      simple_date: format(new Date(), 'yyyy-MM-dd'),
    }
    mutate.mutate(
      { params },
      {
        onSuccess,
      }
    )
  }

  return (
    <>
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onClose}
        title="Adicionar lançamento para o cliente"
        headerStyle={{ mb: pxToRem(-12) }}
      >
        <Flex position="relative" direction="column" alignItems={!isDesktop && 'center'}>
          <Flex mb={theme.pxToRem(12)} flexDir="column">
            <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)}>
              {t('RELEASE_TYPE')}
            </Text>
            <Flex
              bg="backgroundLayout"
              flexDir="column"
              h={theme.pxToRem(56)}
              align="center"
              justify="center"
              px={theme.pxToRem(16)}
              borderRadius={theme.pxToRem(8)}
              borderWidth={1}
              borderColor="border"
            >
              <Select
                name="barber"
                value={type}
                w="full"
                fontFamily="Montserrat Bold"
                placeholder={t('SELECT')}
                fontSize={14}
                textColor="textLight"
                iconColor="primary"
                borderColor="transparent"
                errorBorderColor="baseRed"
                focusBorderColor="transparent"
                variant="unstyled"
                marginBottom={theme.pxToRem(2)}
                onChange={(e) => setType(e.target.value)}
              >
                {types?.map((item, index) => (
                  <option
                    style={{
                      backgroundColor: theme.colors.menuBackground,
                      fontFamily: 'Montserrat Medium',
                    }}
                    key={`service_${item?.value}`}
                    value={item?.value}
                  >
                    {item?.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          <TextInput
            noBorder
            type="string"
            value={description}
            placeholder="Acertou na barbearia"
            name="description"
            label="Descrição"
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextInput
            noBorder
            name="price"
            label="Valor"
            onChange={(e) => setValue(e.target.value)}
            placeholder="0,00"
            mask="CURRENCY_MASK"
            value={value}
            mt={'36px'}
            leftElement={
              <Text mt={theme.pxToRem(2)} fontWeight="bold" ml="0" width={theme.pxToRem(40)}>
                {t('CURRENCY')}
              </Text>
            }
          />
          <Flex mt="36px" width="full" maxWidth={!isDesktop && 325}>
            <Button
              kind={'primary'}
              size="block"
              isDisabled={isDisabled}
              type="submit"
              isLoading={mutate.isLoading}
              onClick={() => handleCreateRelease()}
              mt="12px"
            >
              Salvar
            </Button>
          </Flex>
        </Flex>
      </ModalMenu>
    </>
  )
}
