import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'components/atoms/Box'

export const Screen = (props) => {
  const { imageUrl } = props

  if (!!imageUrl) {
    return (
      <Box
        backgroundImage={`url(${imageUrl})`}
        backgroundPosition="top"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        overflowX="hidden"
        w="100%"
        h="100vh"
      >
        {props.children}
      </Box>
    )
  }

  return (
    <Box overflowX="hidden" overflowY="hidden" w="100%" h="100vh">
      {props.children}
    </Box>
  )
}

Screen.defaultProps = {
  imageUrl: '',
}

Screen.propTypes = {
  ...Screen.propTypes,
  imageUrl: PropTypes.string,
}
