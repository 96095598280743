import { addMinutes, set, format } from 'date-fns'

export const generateHoursList = interval => {
  const start_hour = set(new Date(), { hours: 9, minutes: 0, seconds: 0 })
  const hours = []
  for (let i = 0; i < 300; i++) {
    if (i === 0 || i % interval === 0) {
      const hourToAdd = addMinutes(start_hour, i)
      const scheduleHour = format(hourToAdd, 'HH:mm')
      hours.push(scheduleHour)
    }
  }
  return hours
}
