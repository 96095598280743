import React, { useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import { Table, Text, Link, Avatar } from 'components'
import { Flex, Spinner, Center, useToast } from '@chakra-ui/react'
import { INTERNAL_ADM_ACTIVE_BARBERSHOPS } from 'services/api/endpoints'
import { useLazyFetch } from 'services/hooks'
import { Layout } from '../Components'

const columns = [
  {
    key: 'image',
    label: '',
    render: (item) => <Avatar src={item.profile_image_url} size="medium" />,
  },
  {
    key: 'name',
    label: 'NOME',
    render: (item) => <Text>{item.name}</Text>,
  },

  {
    key: 'phone',
    label: 'TELEFONE',
    render: (item) => (
      <Link
        href={`https://wa.me/${item.user.phone.replace(/[^\w]/gi, '')}`}
        isExternal
      >
        {item.user.phone}
      </Link>
    ),
  },
  {
    key: 'email',
    label: 'EMAIL',
    render: (item) => <Text>{item.user.email}</Text>,
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => <Text>{item.status}</Text>,
  },
  {
    key: 'createdAt',
    label: 'DATA CRIAÇÃO',
    render: (item) => (
      <Text>{format(parseISO(item.createdAt), 'dd/MM/yyyy')}</Text>
    ),
  },
]

export const ActiveBarbershops = () => {
  const toast = useToast()

  const [getData, { loading, data }] = useLazyFetch(
    INTERNAL_ADM_ACTIVE_BARBERSHOPS,
    {
      onError: (error) => {
        toast({
          title: 'Houve um erro ao carregar as barbearias!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout title="Barbearias ativas">
      {loading ? (
        <Flex
          flexDir="column"
          w="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Center>
            <Spinner color="textLight" />
          </Center>
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          w="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Text mb="2vh">Número de barbearias ativas: {data?.total}</Text>
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <Table
              loading={loading}
              data={data?.barbershops || []}
              columns={columns}
            />
          </Flex>
        </Flex>
      )}
    </Layout>
  )
}

ActiveBarbershops.path = '/internal-adm/active'
ActiveBarbershops.title = 'Barbearias Ativas'
ActiveBarbershops.secured = true
