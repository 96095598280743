import React, { useState } from 'react'
import { Box, Text, Flex, Button, Table } from 'components'
import { RewardsModal } from './RewardsModal'
import { useTheme } from '@chakra-ui/react'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useQuery } from 'react-query'
import { useBarbershopStore } from 'services/stores'

export const ExchangeRewards = () => {
  const { userData, setUserData } = useBarbershopStore()
  const { getRewards } = useBestcoins()

  const [rewardsModalOpen, setRewardsModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isEditing, setIsEditing] = useState(false)

  const theme = useTheme()

  const { isLoading, data: rewards } = useQuery(['get-rewards'], () =>
    getRewards({
      params: { barbershop_id: userData?.barbershop?.id || userData?.id },
    })
  )

  const columns = [
    {
      label: 'Tipo de Prêmio',
      key: 'type',
      render: (item) => (
        <Text>{item?.type === 'discount' ? 'Desconto' : 'Produto'}</Text>
      ),
    },
    {
      label: 'Prêmio',
      render: (item) => {
        if (item.type === 'product') {
          return <Text>{item?.product?.name}</Text>
        } else {
          return <Text>{item?.discount_value} reais de desconto </Text>
        }
      },
    },
    {
      label: 'Qte Pontos',
      key: 'required_points',
      render: (item) => <Text>{item?.required_points} pontos</Text>,
    },
  ]

  const onCloseRewardsModal = () => {
    setRewardsModalOpen(false)
    setSelectedItem(null)
    setIsEditing(false)
  }

  const onClickAddRewardsButton = () => {
    setRewardsModalOpen(true)
    setSelectedItem(null)
    setIsEditing(false)
  }

  const onEditItemClick = (item) => {
    setSelectedItem(item)
    setRewardsModalOpen(true)
    setIsEditing(true)
  }
  return (
    <Box>
      <Flex pb={theme.pxToRem(12)} w="100%" justify="space-between">
        <Flex align="center" mb={theme.pxToRem(10)}>
          <Text kind="extraBold" fontSize={theme.pxToRem(16)}>
            Prêmios para troca
          </Text>
        </Flex>
        <Button
          w={220}
          h={10}
          kind={'primary'}
          ml={theme.pxToRem(12)}
          onClick={onClickAddRewardsButton}
        >
          + Adicionar novo Prêmio
        </Button>
      </Flex>
      <Box>
        <Table
          loading={isLoading}
          onSelect={(item) => onEditItemClick(item)}
          isSelectableRow
          data={rewards}
          columns={columns}
        />
      </Box>
      <RewardsModal
        isOpen={rewardsModalOpen}
        onClose={onCloseRewardsModal}
        isEditing={isEditing}
        selectedItem={selectedItem}
      />
    </Box>
  )
}
