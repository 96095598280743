import React, { useState, useMemo, useCallback } from 'react'
import { useTheme, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Layout } from 'components/templates'
import { useBreakpoint } from 'services/hooks'

import { api } from 'services/api/config'
import { YOUTUBE } from 'constants/youtube'
import { useInfiniteQuery } from 'react-query'
import YouTube from 'react-youtube'
import { Text } from 'components'

export const Contents = ({ props }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const [playing, setPlaying] = useState()
  const [video, setVideo] = useState(0)

  async function getBarberShopsByName({ pageParam }) {
    const { data } = await api.get(YOUTUBE.PLAYLIST_ITEMS, {
      params: {
        key: 'AIzaSyBBQOgKe2ChElwxTr8UDeP4Fz_kjYheAZE',
        playlistId: 'PLw1E0_RVpXYAFfmVk64oOMpsZyDEKwv_U',
        part: 'snippet',
        maxResults: 50,
        pageToken: pageParam,
      },
    })

    return data
  }

  const { data } = useInfiniteQuery(['bestbarbers_podcast'], ({ pageParam }) => getBarberShopsByName({ pageParam }), {
    getNextPageParam: ({ nextPageToken }) => nextPageToken,
  })
  const videos = useMemo(() => data?.pages.flatMap((page) => page.items), [data])

  const onStateChange = useCallback((state) => {
    if (state === 'ended') {
      setPlaying(false)
    }
  }, [])

  const opts = {
    height: '404',
    width: '100%',
  }

  const optsMobile = {
    height: '220',
    width: '360',
  }

  return (
    <Layout {...props} headerTitle={t('CONTENTS')} fullHeight>
      <Flex mt={8} flexDir="column" ml={theme.pxToRem(isDesktop && 6)} w="full">
        <Text
          fontSize={theme.pxToRem(isDesktop ? 16 : 14)}
          mt={theme.pxToRem(isDesktop ? -30 : -15)}
          w={isDesktop ? '45%' : '100%'}
        >
          {t('CONTENTS_SUBTITLE')}
        </Text>
        <Text fontSize={theme.pxToRem(24)} mt={theme.pxToRem(24)} kind="extraBold" align="flex-start">
          BestBarbers Podcast
        </Text>
        <Text fontSize={theme.pxToRem(16)} mt={theme.pxToRem(12)} w={theme.pxToRem(520)}>
          {data?.pages[0].pageInfo?.totalResults} {t('EPISODES')}
        </Text>
        {isDesktop ? (
          <Flex mt={theme.pxToRem(24)}>
            <Flex flexDir="column" w="100%" mr="5%">
              <Text fontSize={theme.pxToRem(20)} kind="bold" mb={theme.pxToRem(16)}>
                {data?.pages[0]?.items[video]?.snippet?.title}
              </Text>
              <YouTube
                videoId={data?.pages[0]?.items[video]?.snippet?.resourceId?.videoId}
                opts={opts}
                onReady={onStateChange}
              />
              <Text fontSize={theme.pxToRem(14)} mt={theme.pxToRem(24)}>
                {data?.pages[0]?.items[video]?.snippet?.description}
              </Text>
            </Flex>
            <Flex flexDir="column" w="35%">
              <Text fontSize={theme.pxToRem(20)} kind="bold" mb={theme.pxToRem(16)}>
                {t('OTHER_EPISODES')}
              </Text>
              {videos?.map((item, index) => {
                if (item?.snippet?.thumbnails?.medium?.url) {
                  return (
                    <Flex
                      w={theme.pxToRem(320)}
                      h={theme.pxToRem(180)}
                      mb={theme.pxToRem(24)}
                      borderWidth={video === index ? 1 : 0}
                      borderColor={video === index ? 'primary' : 'transparent'}
                      cursor="pointer"
                      onClick={() => {
                        setVideo(item?.snippet?.position)
                      }}
                      backgroundImage={`url(${item?.snippet?.thumbnails?.medium?.url})`}
                    />
                  )
                } else {
                  return null
                }
              })}
            </Flex>
          </Flex>
        ) : (
          <Flex flexDir="column" w="full">
            {videos?.map((item) => {
              if (item?.snippet?.thumbnails?.medium?.url) {
                return (
                  <Flex mb={theme.pxToRem(12)} w="full">
                    <YouTube videoId={item?.snippet?.resourceId?.videoId} opts={optsMobile} onReady={onStateChange} />
                  </Flex>
                )
              } else {
                return null
              }
            })}
          </Flex>
        )}
      </Flex>
    </Layout>
  )
}

Contents.path = '/conteudos'
Contents.title = 'Conteúdos'
Contents.secured = true
