import React from 'react'
import { Text, Icon, Avatar, Link } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { formatPhone } from 'utils/phone-formater'
import { usePermissionCollaborator } from 'services/hooks'
import { useAppointmentDetailStore, useBarbershopStore } from 'services/stores'
import { format, parseISO } from 'date-fns'

export const ClientCard = ({ client, onClick, onPress, isDetails, ...props }) => {
  const theme = useTheme()
  const clientPhone = client?.client?.user?.phone || client?.user?.phone
  const clientName = client?.client?.name || client?.name
  const clients = usePermissionCollaborator('clients')
  const { selectedAppointment } = useAppointmentDetailStore()
  const { userData } = useBarbershopStore()

  const Component = clients === 'edit' ? Link : Text

  const message = selectedAppointment?.simple_date
    ? `Olá, ${clientName}, tudo bem? Aqui é da ${
        selectedAppointment?.barbershop?.name
      }! Podemos confirmar o seu horário para ${selectedAppointment?.formatted_services
        ?.map((i) => i?.name)
        ?.join(', ')} com ${selectedAppointment?.barber?.name} no dia 
  ${format(parseISO(selectedAppointment?.simple_date), 'dd/MM')} às ${selectedAppointment?.start_hour?.substring(
        0,
        5
      )}h`
    : null

  return (
    <Box
      display="flex"
      flexDir="row"
      w={props?.w || '100%'}
      background="cardBackground"
      alignItems="center"
      justifyContent="flex-start"
      p={theme.pxToRem(10)}
      minH="68px"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      onClick={onPress}
      cursor={onPress && 'pointer'}
      {...props}
    >
      <Flex w="100%" flexDir="row" alignItems="center" justifyContent="space-between">
        <Avatar size="medium" src={client?.profile_image_url} type="client" />

        <Flex
          flexDir={props?.size === 'md' ? 'column' : 'row'}
          w="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text kind="bold" color="textLight" fontSize={theme.pxToRem(14)} ml={theme.pxToRem(16)} noOfLines={1}>
            {clientName}
          </Text>

          <Component
            href={
              isDetails
                ? `https://wa.me/${clientPhone}?text=${message}?`
                : `https://wa.me/${clientPhone}?text=Olá, ${clientName}!`
            }
            isExternal
            ml={theme.pxToRem(props?.size === 'md' ? 16 : 32)}
            // width="100%"
          >
            {formatPhone(clientPhone, userData)}
          </Component>
        </Flex>

        {onPress && <Icon name="ChevronRight" color="primary" size={16} />}

        {onClick && (
          <Icon cursor="pointer" onClick={onClick} name="Trash" color="baseRed" size={16} mr={theme.pxToRem(4)} />
        )}
      </Flex>
    </Box>
  )
}
