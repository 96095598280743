import React from 'react'
import { Flex, useTheme } from '@chakra-ui/react'
import { Text, Icon } from 'components/atoms'
import { useTranslation } from 'react-i18next'

export const AppointmentHomeCard = ({ data, onClick, h }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Flex
      cursor="pointer"
      flexDir="row"
      w="48.5%"
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderLeftColor="primary"
      borderLeftWidth={theme.pxToRem(8)}
      padding={theme.pxToRem(10)}
      onClick={onClick}
    >
      <Flex flexDir="column" w="95%">
        <Text kind="bold" fontSize={14}>
          {data?.client?.name}
        </Text>
        <Text kind="normal" fontSize={12} color="textGrey">
          {`${data?.start_hour.slice(0, 5)} até ${data?.finish_hour.slice(
            0,
            5
          )}`}
        </Text>
        <Text
          kind="normal"
          fontSize={12}
          color="textGrey"
          mt={theme.pxToRem(8)}
        >
          {t('SERVICES')}
        </Text>
        <Text w="100%" kind="semiBold" fontSize={12} numberOfLines={1}>
          {data?.product?.name}
        </Text>
      </Flex>
      <Icon name="Info" />
    </Flex>
  )
}
