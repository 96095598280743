import { Box } from '@chakra-ui/react'
import { useState, useEffect, useRef } from 'react'
import { differenceInMinutes, set, format, parseISO } from 'date-fns'

export const ClockPointer = ({
  displayMinutes,
  rows5Min,
  rowHeight,
  minTime,
  maxTime,
  ...props
}) => {
  const containerRef = useRef(null)
  const [minutes, setMinutes] = useState(0)
  // const curTopPercentage = minutes / displayMinutes
  // const maxTopHeight = rows5Min * rowHeight + rows5Min - 1800
  // const curTopHeight = maxTopHeight * curTopPercentage

  const dif_height =
    format(new Date(), 'HH:mm') > maxTime
      ? 0
      : differenceInMinutes(
          parseISO(`2023-10-10 ${format(new Date(), 'HH:mm')}`),
          parseISO(`2023-10-10 ${minTime}`)
        )

  useEffect(() => {
    if (minutes !== 0) return

    const curDate = new Date()
    const curMinutes = differenceInMinutes(
      curDate,
      set(curDate, { hours: 0, minutes: 0 })
    )
    setMinutes(curMinutes)
  }, [minutes])

  useEffect(() => setTimeout(() => setMinutes(minutes + 1), 60000), [minutes])

  useEffect(() => {
    const initContainerScroll = () => {
      setTimeout(() => {
        containerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
      }, 1)
    }
    initContainerScroll()
  }, [])

  return (
    <Box
      ref={containerRef}
      position="absolute"
      top={`${dif_height * 3.2}px`}
      left={-100}
      right={0}
      bg="red"
      color="blue"
      textAlign="center"
      py={0.25}
      {...props}
      zIndex={999}
    />
  )
}
