import create from 'zustand'
import { saveData } from 'services/storage'

export const useBarbershopStore = create((set, get) => ({
  user: null,
  setUser: (user) => {
    saveData('@BestBarbers:user', user)
    return set({ user })
  },

  userData: null,
  setUserData: (userData) => {
    saveData('@BestBarbers:userData', userData)
    return set({ userData })
  },

  selectFlag: [],
  setFlag: (product) =>
    set((state) => ({
      selectFlag: [...state.selectFlag, product],
    })),
  removeFlag: (product) =>
    set((state) => ({
      selectFlag: state.selectFlag.filter((item) => item.id !== product.id),
    })),

  subscription: null,
  setSubscription: (subscription) => set({ subscription }),

  customer: null,
  setCustomer: (customer) => set({ customer }),

  permissions: null,
  setPermissions: (permissions) => set({ permissions }),

  setData: (user, userData, subscription, customer, permissions) =>
    set({ user, userData, subscription, customer, permissions }),

  loading: false,
  setLoading: (loading) => set({ loading }),

  avatarData: null,
  setAvatarData: (avatarData) => set({ avatarData }),

  location: null,
  setLocation: (location) => set({ location }),

  workingHours: null,
  setWorkingHours: (workingHours) => set({ workingHours }),

  agendaInterval: null,
  setAgendaInterval: (agendaInterval) => set({ agendaInterval }),

  mainData: null,
  setMainData: (mainData) => set({ mainData }),

  photos: null,
  setPhotos: (photos) => set({ photos }),

  plan: null,
  setPlan: (plan) => set({ plan }),

  blockedTimes: null,
  setBlockedTimes: (blockedTimes) => set({ blockedTimes }),
}))
