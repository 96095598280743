import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Text, BarbershopAdsModal, Image, Box, Button, Loader, SelectCard } from 'components'
import { Layout } from 'components/templates'
import { useBarbershopStore } from 'services/stores'
import { useQuery, useMutation } from 'react-query'
import { useBarbershopAds } from 'services/hooks/use-barbershop-ads'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useToast } from '@chakra-ui/react'
import { theme } from 'styles'

export const BarbershopAds = ({ props }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const { userData, setUserData } = useBarbershopStore()
  const { getBarbershopAds, deleteBarbershopAds, managerBarbershopAds } = useBarbershopAds()
  const { onLogout } = useLogoutPaymentIssues()

  const [addModalOpen, setModalOpen] = useState(false)

  const { data, isLoading, refetch, isRefetching } = useQuery(
    'get-barbershop-ads',
    () =>
      getBarbershopAds({
        barbershop_id: userData?.barbershop?.id || userData?.id,
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  const deleteMutation = useMutation('delete-ads', (ads_id) => deleteBarbershopAds({ ads_id }), {
    onSuccess: () => refetch(),
  })

  const mutateUpdate = useMutation(
    (data) =>
      managerBarbershopAds({
        body: {
          barbershop_id: userData?.barbershop?.id || userData?.id,
          ads_enable: data?.ads_enable,
        },
      }),
    {
      onSuccess: (data) => {
        setUserData({
          ...userData,
          ads_enable: data?.ads_enable,
        })
        toast({
          title: 'Anúncios habilitados com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao atualizar Anúncios',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    onLogout()
  }, [userData])

  return (
    <Layout
      {...props}
      headerTitle={t('Anúncios')}
      fullHeight
      headerActionText="Adicionar novo anúncio"
      headerActionClick={() => setModalOpen(true)}
    >
      <Text mt={4} mb="12px" kind="bold" fontSize="18px" align="flex-start">
        Configurações
      </Text>

      <Flex alignItems="center" justifyContent="space-between">
        <SelectCard
          px="8px"
          item={{
            title: 'Habilitar exibição de anúncios no app',
          }}
          setSelected={() =>
            mutateUpdate.mutate({
              ads_enable: !userData?.ads_enable,
            })
          }
          selected={userData?.ads_enable}
          h="52px"
          mr="5"
          w="43%"
        />
      </Flex>
      {(isLoading || isRefetching) && <Loader mb={8} />}
      {!isLoading &&
        data?.map((item) => {
          return (
            <Flex
              mt="24px"
              mb={4}
              borderColor="border"
              borderWidth={1}
              borderRadius={8}
              align="center"
              justify="space-between"
            >
              <Flex align="center">
                <Image objectFit="cover" w={400} h={120} src={item?.url} borderRadius={8} mr={8} />
                <Box>
                  <Text color="textMedium">ID do anúncio: {item?.id}</Text>
                  <Text>Link de redirecionamento ao clicar no anúncio:</Text>
                  <Text kind="semiBold">{item?.redirect_link || 'Não informado'}</Text>
                </Box>
              </Flex>
              <Flex mr={8} flexDir="column">
                <Button onClick={() => setModalOpen(item)} size="x-small">
                  Alterar
                </Button>
                <Button
                  onClick={() => deleteMutation.mutate(item?.id)}
                  mt={2}
                  kind="danger"
                  size="x-small"
                  isLoading={deleteMutation.isLoading}
                >
                  Excluir
                </Button>
              </Flex>
            </Flex>
          )
        })}
      <BarbershopAdsModal
        onClose={() => setModalOpen(false)}
        isModalOpen={addModalOpen !== false}
        ads_data={addModalOpen?.id ? addModalOpen : null}
        refetch
      />
    </Layout>
  )
}

BarbershopAds.path = '/ads'
BarbershopAds.title = 'Anúncios'
BarbershopAds.secured = true
