import React, { useState, useEffect } from 'react'
import { Layout, ClientDetailModal, TextInput, Button, Text } from 'components'
import { Center, Flex, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore, useCustomerStore } from 'services/stores'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'
import { useQuery } from 'react-query'
import { useBarbershop } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { Clients } from './Clients'
import { Plans } from './Plans'

export const ClientsSignatureFrequency = (props) => {
  const { userData } = useBarbershopStore()
  const { getClientsFrequencySignature } = useBarbershop()
  const { t } = useTranslation()
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore()
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const [filtered, setFiltered] = useState([])
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))

  // const { refetch, isLoading, isRefetching, data } = useQuery(
  //   ['get-clients-resume', userData],
  //   () => getClientsFrequencySignature({ id: userData?.id, startDate, endDate }),
  //   {
  //     onSuccess: (d) => setFiltered({ data: d?.appointments_normal, filtered: d?.appointments_normal }),
  //     refetchOnWindowFocus: false,
  //   }
  // )

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Relatório de frequência"
      widthTitle="100%"
      //   headerCenterElement={() => (
      //   )
      // }
    >
      <>
        <Tabs w="100%" onChange={(index) => {}}>
          <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={4}>
            <Tab
              w="50%"
              fontSize={14}
              fontWeight={900}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              Relatório de clientes
            </Tab>
            <Tab
              w="50%"
              fontSize={14}
              fontWeight={900}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              Relatório de planos
            </Tab>
          </TabList>

          <TabPanels p={0}>
            <TabPanel p={0}>
              <Clients
                setSelectedCustomer={setSelectedCustomer}
                setCustomerDetailModal={setCustomerDetailModal}
                userData={userData}
                setFiltered={setFiltered}
              />
            </TabPanel>
            <TabPanel p={0}>
              <Plans userData={userData} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>

      {isCustomerDetailModalOpen && (
        <ClientDetailModal
          isModalOpen={isCustomerDetailModalOpen}
          onClose={() => {
            setCustomerDetailModal(false)
            // refetch()
          }}
          customerData={selectedCustomer}
        />
      )}
    </Layout>
  )
}

ClientsSignatureFrequency.path = '/frequencia-assinantes'
ClientsSignatureFrequency.title = 'Relatório Frequência - Assinatura'
ClientsSignatureFrequency.secured = true
