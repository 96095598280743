import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import { Text, Image, Button, Modal, Link, Form } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { CREATE_BARBERSHOP } from 'services/api/endpoints'
import { saveLoginData, useBreakpoint } from 'services/hooks'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'
import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { input, validation, initial } from './settings'

export const CreateAccountForm = () => {
  const { cupom } = useParams()
  const { isMobile } = useBreakpoint()
  const { setData } = useBarbershopStore()
  const history = useHistory()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [openPass, setOpenPass] = useState(false)
  const [openNew, setOpenNew] = useState(false)
  const [values, getValues] = useState({
    params: initial,
    isValid: false,
    errors: {},
  })
  const [error, setError] = useState({})

  async function createAccount({ params }) {
    params.device_id = 'null'
    const res = await api
      .post(CREATE_BARBERSHOP, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('create-account', (params) => createAccount(params), {
    onSuccess: (data) => {
      const { user, userData, token, subscription, customer } = data
      const toSaveUserData = userData.barbershop ? userData.barbershop : userData

      saveLoginData({ user, userData: toSaveUserData, token })
      setData(user, toSaveUserData, subscription, customer)

      history.push('/cadastro/localizacao')
    },
    onError: (error) => {
      toast({
        title: t('REGISTER_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleSubmit = () => {
    if (Object?.keys(values?.errors)?.length !== 0) {
      setError(values?.errors)
      return
    }
    const url_params = new URLSearchParams(window?.location?.search)
    let params = {
      ...values?.params,
      link_source: url_params?.toString(),
      campaign_source: url_params?.toString(),
    }
    if (!!cupom && (cupom === 'oferta01' || cupom === 'oferta02' || cupom === 'oferta03')) {
      const text_source =
        cupom === 'oferta01' ? 'AD06 Oferta 01' : cupom === 'oferta02' ? 'AD08 Oferta 02' : 'AD09 Operacional'
      params = {
        ...values?.params,
        link_source: `Meta LP LAL - ${text_source}`,
        campaign_source: `Meta LP LAL - ${text_source}`,
      }
    }
    // if (url_params?.size > 0) {
    //   const utm_source = url_params?.get('utm_source')
    //   const utm_medium = url_params?.get('utm_medium')
    //   params = {
    //     ...params,
    //     campaign_source: `${utm_source}${utm_medium}`,
    //   }
    // }
    mutate.mutate({ params })
  }

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_opened')
    getValues({
      params: { ...initial, cupom },
      isValid: false,
      errors: {},
    })
  }, [])

  return (
    <Flex flexDir="column" justifyContent="center" alignItems="center" pb={theme.pxToRem(40)}>
      <Modal
        pb={isMobile && theme.pxToRem(24)}
        mb={isMobile && theme.pxToRem(24)}
        w={isMobile ? '95%' : theme.pxToRem(600)}
        backgroundColor="cardBackground"
        height="auto"
        isLoginModal
      >
        <Flex
          alignItems="center"
          flexDir="column"
          w="100%"
          py={theme.pxToRem(48)}
          px={theme.pxToRem(isMobile ? 24 : 48)}
        >
          <Image src={bestBarbersLogo} alt="BestBarbers" width={theme.pxToRem(160)} objectFit="contain" />
          <Text kind="extraBold" fontSize={theme.pxToRem(24)} mt={theme.pxToRem(40)}>
            {t('CREATE_ACCOUNT')}
          </Text>
          <Text color="textGrey" textAlign={isMobile ? 'center' : 'center'} fontSize={theme.pxToRem(14)}>
            {t('START_NOW')}
          </Text>

          <Flex flexDir="column" w="100%" mt={theme.pxToRem(24)}>
            <Form
              initialValues={values.params}
              validationSchema={validation}
              mb={36}
              data={input({
                theme,
                setOpenPass,
                openPass,
                openNew,
                setOpenNew,
                errors: error,
              })}
              getValues={getValues}
            />
            <Button
              mt={theme.pxToRem(24)}
              onClick={handleSubmit}
              disabled={Object?.keys(values?.errors)?.length !== 0}
              isLoading={mutate.isLoading}
              w="100%"
            >
              <Text fontSize={theme.pxToRem(16)} color={theme.colors.textDark} kind="bold">
                {t('CREATE_ACCOUNT')}
              </Text>
            </Button>
            <Flex mt={theme.pxToRem(24)} justifyContent="center">
              <Link
                fontSize={isMobile && theme.pxToRem(15)}
                href="/login"
                type="multiple"
                mainText={t('ALREADY_REGISTERED')}
                highlightText={t('LOG_IN')}
                mt={theme.pxToRem(40)}
              />
            </Flex>
            {/* <Flex
          flexDir="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          // mt={theme.pxToRem(isMobile ? 24 : 0)}
        > */}
            <Text align={'center'} fontSize={isMobile && theme.pxToRem(14)} mt={theme.pxToRem(16)}>
              {t('TERMS_AND_POLICYS_1')} os
              <Link
                isExternal
                fontSize={isMobile && theme.pxToRem(14)}
                href="https://www.bestbarbers.app/termos-de-uso"
              >
                {` ${t('TERMS')} `}
              </Link>
              {t('AND')}
              <Link
                fontSize={isMobile && theme.pxToRem(14)}
                isExternal
                href="https://www.bestbarbers.app/politica-de-privacidade"
              >
                {t('PRIVACY')}
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}
