import * as React from 'react'

function SvgEmailAlert(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.362 2.336H8.698a5.952 5.952 0 00-5.952 5.952v29.76A5.952 5.952 0 008.698 44h41.664a5.952 5.952 0 005.952-5.952V8.288a5.952 5.952 0 00-5.952-5.952z"
        stroke="currentColor"
        strokeWidth={4.464}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.103 9.69L29.53 23.975 50.957 9.69"
        stroke="currentColor"
        strokeWidth={3.571}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.254 11.508v14.907M69.254 35.549v.04"
        stroke="currentColor"
        strokeWidth={4.31}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgEmailAlert
