import * as Yup from 'yup'
import { t } from 'services/translation'

export const input = [
  {
    name: 'owner_name',
    type: 'string',
    noBorder: true,
    label: 'RESPONSABLED_NAME',
  },
  {
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'BARBERSHOP_NAME',
  },
  {
    name: 'email',
    type: 'string',
    noBorder: true,
    label: 'Email',
  },
  {
    name: 'phone',
    type: 'string',
    noBorder: true,
    label: 'Whatsapp da barbearia',
    mask: 'PHONE_MASK',
  },
  {
    name: 'mobile_phone',
    type: 'string',
    noBorder: true,
    label: 'MOBILE_PHONE',
    mask: 'PHONE_MASK',
  },
  {
    name: 'secondary_identifier_doc',
    type: 'string',
    noBorder: true,
    label: 'CPF',
    mask: 'CPF_MASK',
  },
  {
    name: 'identifier_doc',
    type: 'string',
    noBorder: true,
    label: 'CNPJ',
    mask: 'CNPJ_MASK',
  },
  {
    name: 'street',
    type: 'string',
    noBorder: true,
    label: 'ADDRESS',
  },
  {
    name: 'street_number',
    type: 'string',
    noBorder: true,
    label: 'NUMBER',
  },
  {
    name: 'zip_code',
    type: 'string',
    noBorder: true,
    label: 'ZIP_CODE_PLACEHOLDER',
    mask: 'CEP_MASK',
  },
  {
    name: 'neighborhood',
    type: 'string',
    noBorder: true,
    label: 'NEIGHBORHOOD',
  },
]

export const validation = Yup.object().shape({
  name: Yup.string().min(4, 'NAME').required('Nome do serviço é obrigatório'),
  owner_name: Yup.string()
    .min(4, 'NAME')
    .required('Nome do responsável é obrigatório'),
  identifier_doc: Yup.string(),
  secondary_identifier_doc: Yup.string()
    .length(14, 'CPF inválido!')
    .required('CPF é obrigatório!'),
  zip_code: Yup.string()
    .min(8, t('INCOMPLETE_CEP'))
    .required(t('CEP_REQUIRED')),
  street: Yup.string().required(t('STREET_REQUIRED')),
  street_number: Yup.string().required(t('STREET_NUMBER_REQUIRED')),
  neighborhood: Yup.string(),
  phone: Yup.string().required('Telefone é obrigatório'),
  mobile_phone: Yup.string().required('Telefone é obrigatório'),
  email: Yup.string()
    .email('Precisa ser um email válido')
    .required('Email é obrigatório'),
})

export const initial = {
  owner_name: '',
  name: '',
  phone: '',
  mobile_phone: '',
  email: '',
  identifier_doc: '',
  secondary_identifier_doc: '',
  street: '',
  street_number: '',
  zip_code: '',
  neighborhood: '',
}
