import {
  CREATE_INVOICE_RECORD,
  GET_ALL_INVOICE_RECORD,
  GET_ONE_INVOICE_RECORD,
  UPDATE_INVOICE_RECORD,
  DELETE_INVOICE_RECORD,
  CREATE_INVOICE_RECORD_NFSE,
} from 'services/api/endpoints'

import { api } from 'services/api/config'

export const useInvoice = () => {
  const createInvoice = async (data) => {
    try {
      const response = await api.post(CREATE_INVOICE_RECORD, data)
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const createInvoiceNfse = async (data) => {
    try {
      const response = await api.post(CREATE_INVOICE_RECORD_NFSE, data)
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const getAllInvoice = async (data) => {
    try {
      const response = await api.get(GET_ALL_INVOICE_RECORD(data))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const getOneInvoice = async (id) => {
    try {
      const response = await api.get(GET_ONE_INVOICE_RECORD(id))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const updateInvoice = async (data) => {
    try {
      const response = await api.put(UPDATE_INVOICE_RECORD(data.id), data)
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.codigo,
        message: response.data.mensagem,
      }
      throw error
    }
  }

  const removeInvoice = async (data) => {
    try {
      const response = await api.delete(DELETE_INVOICE_RECORD(data.id))
      return response.data
    } catch ({ response }) {
      const error = {
        status: response.status,
        code: response.data.code,
        message: response.data.message,
      }
      throw error
    }
  }

  return {
    getAllInvoice,
    getOneInvoice,
    createInvoice,
    updateInvoice,
    removeInvoice,
    createInvoiceNfse,
  }
}
