import React from 'react'
import { Box, Text, Flex, Table } from 'components'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useBarbershopStore } from 'services/stores'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useTheme, useToast } from '@chakra-ui/react'

export const ExchangeHistoric = () => {
  const { getRewardsExchanges, deleteRewardExchange } = useBestcoins()
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    isLoading,
    data: rewardsExchange,
    refetch,
  } = useQuery(['get-rewards-exchange'], () =>
    getRewardsExchanges({
      params: { barbershop_id: userData?.barbershop_id || userData?.id },
    })
  )

  const mutateDeleteRewardExchange = useMutation(
    ['delete-reward-exchange'],
    (reward_exchange_id) =>
      deleteRewardExchange({
        reward_exchange_id,
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Prêmio excluido com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        queryClient.invalidateQueries('bestcoins-points-for-client')
        refetch()
      },
      onError: (error) => {
        toast({
          title: 'Erro ao excluir prêmio',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const columns = [
    {
      label: 'Data do Resgatte',
      key: 'dataDoResgate',
      render: (item) => (
        <Text>
          {item?.date} - {item?.hour}
        </Text>
      ),
    },
    {
      label: 'Cliente',
      key: 'cliente',
      render: (item) => <Text>{item?.client?.name}</Text>,
    },
    {
      label: 'Prêmio resgatado',
      key: 'premioResgatado',
      render: (item) => {
        if (item?.reward?.type === 'discount') {
          return <Text>Desconto de {item?.reward?.discount_value} reais</Text>
        }
        return <Text>{item?.reward?.product?.name}</Text>
      },
    },
    {
      label: 'Pontos utilizados',
      key: 'pontosUtilizados',
      render: (item) => <Text>{item?.reward?.required_points} pontos</Text>,
    },
    // { label: 'Responsável', key: 'responsavel' },
  ]

  return (
    <Box>
      <Flex pb={theme.pxToRem(12)} w="100%" justify="space-between">
        <Flex align="center" mb={theme.pxToRem(10)}>
          <Text kind="extraBold" fontSize={theme.pxToRem(16)}>
            Histórico de resgates
          </Text>
        </Flex>
      </Flex>

      <Box>
        <Table
          loading={isLoading}
          deleteButtom
          containSwitch
          isSelectableRow
          data={rewardsExchange}
          columns={columns}
          onSelect={(item) => {
            mutateDeleteRewardExchange.mutate(item?.id)
          }}
        />
      </Box>
    </Box>
  )
}
