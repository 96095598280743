import React from 'react'

const PaymentClient = (props) => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0C4.8 0 3 1.8 3 4C3 6.2 4.8 8 7 8C9.2 8 11 6.2 11 4C11 1.8 9.2 0 7 0ZM7 6C5.9 6 5 5.1 5 4C5 2.9 5.9 2 7 2C8.1 2 9 2.9 9 4C9 5.1 8.1 6 7 6ZM7 10C3.1 10 0 11.8 0 14V16H11V14H2C2 13.4 3.8 12 7 12C8.8 12 10.2 12.5 11 13V10.8C9.9 10.3 8.5 10 7 10ZM22 0H15C13.9 0 13 0.9 13 2V14C13 15.1 13.9 16 15 16H22C23.1 16 24 15.1 24 14V2C24 0.9 23.1 0 22 0ZM16 14H15V2H16V14ZM22 14H18V2H22V14Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PaymentClient
