import * as React from 'react'

function SvgPeople(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="transparent"
        d="M19.924 9.826H17.73c-.299 0-.588.04-.863.116a3.286 3.286 0 00-2.927-1.8h-3.878a3.286 3.286 0 00-2.927 1.8 3.274 3.274 0 00-.863-.116H4.078A3.287 3.287 0 00.795 13.11v3.507c0 1.086.884 1.97 1.97 1.97h18.472c1.086 0 1.97-.884 1.97-1.97V13.11a3.287 3.287 0 00-3.283-3.283zm-13.145 1.6v5.847H2.765a.657.657 0 01-.657-.657V13.11c0-1.086.884-1.97 1.97-1.97h2.194c.178 0 .35.025.515.07a3.305 3.305 0 00-.008.216zm9.13 5.847H8.093v-5.848c0-1.086.884-1.97 1.97-1.97h3.878c1.086 0 1.97.884 1.97 1.97v5.848zm5.985-.657a.657.657 0 01-.657.657h-4.014v-5.848c0-.073-.003-.145-.008-.217.164-.044.337-.069.515-.069h2.194c1.086 0 1.97.884 1.97 1.97v3.507z"
      />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M5.175 3.625a2.92 2.92 0 00-2.917 2.917A2.92 2.92 0 005.175 9.46a2.92 2.92 0 002.917-2.918 2.92 2.92 0 00-2.917-2.917zm0 4.521c-.884 0-1.604-.72-1.604-1.604 0-.884.72-1.604 1.604-1.604.885 0 1.604.72 1.604 1.604 0 .885-.72 1.604-1.604 1.604zM12.001 0a3.902 3.902 0 00-3.897 3.897A3.902 3.902 0 0012 7.796a3.902 3.902 0 003.898-3.897A3.902 3.902 0 0012 0zm0 6.482a2.587 2.587 0 01-2.584-2.585A2.587 2.587 0 0112 1.313a2.587 2.587 0 012.584 2.585 2.587 2.587 0 01-2.584 2.584zM18.828 3.625a2.92 2.92 0 00-2.918 2.917 2.92 2.92 0 002.918 2.918 2.92 2.92 0 002.917-2.918 2.92 2.92 0 00-2.918-2.917zm0 4.521c-.885 0-1.605-.72-1.605-1.604 0-.884.72-1.604 1.605-1.604.884 0 1.604.72 1.604 1.604 0 .885-.72 1.604-1.604 1.604z"
      />
    </svg>
  )
}

export default SvgPeople
