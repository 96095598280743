import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Text,
  TextInput,
} from 'components/atoms'
import { Form, ModalAlert, ModalMenu, PaymentMethodCard, SelectCard } from 'components/molecules'
import { useMutation, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { options } from './settings'
import { usePackageService } from 'services/hooks/use-client-package'
import { useToast } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { SelectPaymentMethod } from './SelectPaymentMethod'
import { unformatCurrency } from 'utils/formatCurrency'

export const ClientPackageModal = ({ isOpen, onClose, selectedClient }) => {
 const [isAlertOpen, setIsAlertOpen] = useState()
 const [paymentMethod, setPaymentMethod] = useState([])
 const [paymentMethodOpen, setPaymentMethodOpen] = useState(false)
 const [type, setType] = useState()
  const [inputValue, setInputValue] = useState([])
  const queryClient = useQueryClient()
const {deletePackageService, renewPackageService} = usePackageService()
const toast = useToast()

 const deletePackage = useMutation(['delete-package-service'], () => deletePackageService({id: selectedClient?.id}), {
  onSuccess: () => {
    queryClient.invalidateQueries(['get-clients-package'])
    setIsAlertOpen(false)
    onClose()
    toast({
      title: t('PACKAGE_DELETED_SUCCESS'),
      status: 'success',
          duration: 4000,
          isClosable: true,
    })
  },
  onError:(err) => {
    toast({
      title: t('PACKAGE_DELETED_ERROR'),
      status: 'error',
          duration: 4000,
          isClosable: true,
    })
  }
 })

 const renewPackage = useMutation(['renew-package-service'], 
 () => renewPackageService({id: selectedClient?.id, 
  body: {quantity: selectedClient?.quantity_hired, payment_method: paymentMethod?.length > 1
    ? inputValue
    : [{ id: paymentMethod[0].id, value: selectedClient?.value }],}}), 
  {
  onSuccess: () => {
    queryClient.invalidateQueries(['get-clients-package'])
    setIsAlertOpen(false)
    onClose()
    toast({
      title: t('PACKAGE_RENEWED_SUCCESS'),
      status: 'success',
          duration: 4000,
          isClosable: true,
    })
  },
  onError:(err) => {
    toast({
      title: t('PACKAGE_RENEWED_ERROR'),
      status: 'error',
          duration: 4000,
          isClosable: true,
    })
  }
 })

 const handleChange = (id, value) => {
  const existingItem = inputValue.find((item) => item.id === id)
  if (existingItem) {
    const updatedInputValue = inputValue.map((item) =>
      item.id === id
        ? { ...item, value: Number(value.replace(',', '.')) }
        : item
    )
    setInputValue(updatedInputValue)
  } else {
    setInputValue([
      ...inputValue,
      { id, value: Number(value.replace(',', '.')) },
    ])
  }
}

 const totalValue = useMemo(() => {
  const valorDosInputs = inputValue.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.value,
    0
  )
  return valorDosInputs
}, [inputValue])
  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={
        onClose
      }
      title={t('MANAGER_PACKAGE_CLIENT')}
    >
<Box>
      <Form mb={42} data={options} initialValues={selectedClient} disabled={true} />
      <SelectCard mb='16px' item={{
title: t('REFRESH_PACKAGE'), description: t('REFRESH_PACKAGE_DESC')
      }}  setSelected={setType} selected={type} />
     {type &&
     <Box marginBottom='24px'>
          <Text
            marginBottom='16px'
            fontSize='12px'
            textTransform="uppercase"
          >
            {t('SELECT_PAYMENT_METHOD')}
          </Text>
            {!!paymentMethod?.length &&
              paymentMethod.map((pm) => (
                <Box
                  minHeight={paymentMethod?.length > 1 && '156px'}
                  mb='16px'
                >
                  <PaymentMethodCard
                    marginBottom='16px'
                    paymentMethod={pm} 
                    onClick={
                      () =>
                            setPaymentMethod(
                              paymentMethod.filter((r) => r !== pm)
                            )
                    }
                  />
                  {paymentMethod?.length > 1 && (
                    // Fazer a verificação valor digitado é igual selectedClient?.value
                    <TextInput
                      mask="CURRENCY_MASK"
                      value={inputValue?.find((i) => i?.id === pm?.id)?.value}
                      label={t('VALUE_PAYMENT')}
                      placeholder="Digite o valor"
                      mb='16px'
                      onChange={(e) => handleChange(pm?.id, e.target.value)}
                    />
                  )}
                </Box>
              ))}

            {/* controla valores digitados no input */}

            {paymentMethod?.length > 1 && totalValue === 0 ? (
              <Text color="red">
                {' '}
                Digite o valor igual ao total:{' '}
                {formatPrice(selectedClient?.value - totalValue)}
              </Text>
            ) : (
              <Text color="red">
                {paymentMethod?.length > 1 && totalValue !== unformatCurrency(selectedClient?.value) && (
                  <>
                    O valor inserido é{' '}
                    {totalValue > selectedClient?.value ? 'maior' : 'menor'} que o total
                    em {formatPrice(Math.abs(totalValue - selectedClient?.value))}
                  </>
                )}
              </Text>
            )}
                <Button
                mt={paymentMethod?.length > 1 && '12px'}
                  size="block"
                  kind="appointmentOptions"
                  onClick={() => setPaymentMethodOpen(true)}
                >
                  {t('SELECT_PAYMENT_METHOD')}
                </Button>

                </Box>
                }

<Button kind={type ? 'primary' : 'danger'} isDisabled={type && (!paymentMethod || paymentMethod?.length === 0 || (paymentMethod?.length > 1 && totalValue !== unformatCurrency(selectedClient?.value)) )} isLoading={renewPackage.isLoading ||deletePackage.isLoading} size='block' mb='16px' onClick={() => {
        if(type) {
          renewPackage.mutate()
          return
        }
        setIsAlertOpen(true)}
        } >
        {t(type ? 'REFRESH_PACKAGE' :  'DELETE_PACKAGE')}
      </Button>

        <SelectPaymentMethod isOpen={paymentMethodOpen} onClose={() => setPaymentMethodOpen(false)} payments={paymentMethod} setPayments={setPaymentMethod} />


        <ModalAlert
          isOpen={isAlertOpen}
          onClose={() => {
            setType('')
            setIsAlertOpen(false)}}
          onConfirm={deletePackage.mutate}
          primaryButtonLabel={t('DELETE')}
          modalTitle={t('DELETE_PACKAGE')}
        >
          <Text fontWeight="bold" m='16px'>
            { t('PACKAGE_DELETED_DESC') }
          </Text>
        </ModalAlert>
      </Box>
    </ModalMenu>
  )
}
