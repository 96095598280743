import { Flex } from '@chakra-ui/react'
import { Button, Loader } from 'components/atoms'
import React from 'react'
import Chart from 'react-apexcharts'
import { colors } from 'styles'
import { pxToRem } from 'styles/metrics'

export const MultiColumnChart = ({ isLoading, renderTop, ...props }) => {
  const columnChartOptions = {
    series: props?.series,
    options: {
      chart: {
        height: props?.h,
        background: '#363D49',
        borderRadius: 100,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return props?.type === 'money' ? 'R$ ' + parseFloat(val).toFixed(2) : val + '%'
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#FFF'],
        },
      },

      xaxis: {
        categories: props?.categories,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: '#fff',
          },
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#000',
              colorTo: '#FFF',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return props?.type === 'money' ? 'R$ ' + parseFloat(val).toFixed(2) : val + '%'
          },
          style: {
            colors: '#fff',
          },
        },
      },
      legend: {
        labels: {
          colors: '#FFF',
        },
      },
      colors: ['#E5B817', '#FFF'],
      title: {
        text: props?.title,
        floating: true,
        offsetY: 0,
        align: 'center',
        style: {
          color: '#FFF',
        },
      },
    },
  }

  if (isLoading || !columnChartOptions?.series) {
    return (
      <Flex
        w="100%"
        h={448}
        justify="center"
        align="center"
        bg="#363D49"
        borderRadius={pxToRem(16)}
        padding={pxToRem(16)}
      >
        <Loader />
      </Flex>
    )
  }

  return (
    <Flex
      flexDir="column"
      justify="center"
      align="center"
      bg="#363D49"
      borderRadius={pxToRem(16)}
      overflow="hidden"
      padding={pxToRem(16)}
    >
      {renderTop?.()}

      <Chart
        options={columnChartOptions?.options}
        series={columnChartOptions?.series}
        type="bar"
        height={props?.h || 350}
        style={{ width: props?.w || '100%' }}
      />
    </Flex>
  )
}
