import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from '@chakra-ui/react'
import { Text, Flex, Icon, Button, Box } from 'components'
import { theme } from 'styles'
import plansBackground from '../images/section-plans.png'
import { benefits } from './settings'
import { useBreakpoint } from 'services/hooks'

import bestBarbersLogo from 'assets/img/brand/logo_white.png'

export const Plans = ({ clickButton, clickAppExclusive, ...props }) => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const headerHeight = theme.pxToRem(clickButton ? 270 : 200)

  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        backgroundImage={`url(${plansBackground})`}
        backgroundSize="cover"
        backgroundPosition="center center"
        align="center"
        justify="center"
        px="5vw"
        py="5vh"
        flexDir="column"
      >
        <Flex mb={theme.pxToRem(12)} flexDir="row" align="center" justify="center">
          <Icon name="Cash" mr={theme.pxToRem(10)} />
          <Text color="primary" fontSize={theme.pxToRem(14)} kind="bold">
            NOSSOS PLANOS
          </Text>
        </Flex>

        <Text fontSize={theme.pxToRem(30)} kind="extraBold" textAlign="center">
          Conheça as vantagens de ser Premium
        </Text>

        <Text mt={theme.pxToRem(6)} color="textLight" fontSize={theme.pxToRem(12)} kind="medium" textAlign="center">
          Experimente um gerenciamento de barbearias que realmente funciona e que tem tudo que você precisa
        </Text>
        <Box w="100%" mt={theme.pxToRem(24)}>
          <Flex w="100%" justify="space-between">
            <Flex w="62%" />
            <Flex w="38%" align="center" justify="space-between" mb={theme.pxToRem(8)}>
              <Text kind="bold" fontSize={12} w="40%" textAlign="center" color="grey">
                {t('Premium')}
              </Text>
              <Flex flexDir="column" align="center" w="60%" mt={theme.pxToRem(-16)}>
                <Icon name="Crown" fill="primary" size={16} />
                <Text kind="bold" fontSize={12} textAlign="center" color="textColorLighter">
                  App Personalizado
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex flexDir="column" w="100%" bg="menuBackground">
            {benefits.map((item, index) => (
              <Flex
                key={`firstColumn_benefit_${index}`}
                alignItems="center"
                borderWidth={1}
                py={!isDesktop && theme.pxToRem(8)}
                h={theme.pxToRem(44)}
                borderColor="border"
                borderTopRadius={theme.pxToRem(index === 0 && 8)}
                borderBottomRadius={theme.pxToRem(index === 8 && 8)}
                justify="space-between"
                w="100%"
              >
                <Text kind="semiBold" fontSize={12} ml={theme.pxToRem(8)} w="82%">
                  {t(item.text)}
                </Text>
                <Flex w="40%">
                  <Icon
                    w="82%"
                    name={item?.basic ? 'CheckCircle' : 'CloseCircle'}
                    fill={item?.basic ? 'green' : 'danger'}
                    size={16}
                    mt={theme.pxToRem(3)}
                  />
                  <Icon
                    w="60%"
                    name="CheckCircle"
                    fill={item?.premium ? 'green' : 'danger'}
                    size={16}
                    mt={theme.pxToRem(3)}
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Box>
        {clickButton && (
          <Button h={theme.pxToRem(48)} mt={theme.pxToRem(24)} w="100%" onClick={clickAppExclusive}>
            {props?.buttonText || 'Quero um App Próprio'}
          </Button>
        )}
      </Flex>
    )
  }
  return (
    <Flex
      w={'100vw'}
      h={'120vh'}
      backgroundImage={`url(${plansBackground})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      align="center"
      justify="center"
      pl="10vw"
      pr="10vw"
      flexDir="column"
    >
      <Flex mb={theme.pxToRem(12)} flexDir="row" align="center" justify="center">
        <Icon name="Cash" mr={theme.pxToRem(10)} />
        <Text color="primary" fontSize={theme.pxToRem(16)} kind="bold">
          NOSSOS PLANOS
        </Text>
      </Flex>

      <Text fontSize={theme.pxToRem(36)} kind="extraBold" textAlign="center">
        Conheça as vantagens de ser Premium
      </Text>

      <Text mt={theme.pxToRem(8)} color="textLight" fontSize={theme.pxToRem(14)} kind="medium">
        Experimente um gerenciamento de barbearias que realmente funciona e que tem tudo que você precisa
      </Text>

      <Flex mt={theme.pxToRem(40)} maxW={theme.pxToRem(1200)} flexDir="row" w="90%" align="flex-end">
        <Flex borderRadius={theme.pxToRem(10)} flexDir="column" w="34%" bgColor="background">
          {/* columnHeader */}
          <Flex justify="space-between" flexDir="column" p={theme.pxToRem(20)} h={headerHeight}>
            <Image src={bestBarbersLogo} w={theme.pxToRem(140)} h="auto" />
            <Text fontSize={theme.pxToRem(20)} kind="extraBold">
              Conheça nossos planos e funcionalidades
            </Text>
            <Text fontSize={theme.pxToRem(12)} kind="medium">
              Planos que se encaixam na sua necessidade.
            </Text>
          </Flex>

          {benefits.map((item, index) => (
            <Flex
              key={`firstColumn_benefit_${index}`}
              alignItems="center"
              borderWidth={1}
              p={theme.pxToRem(12)}
              h={theme.pxToRem(42)}
              borderColor="border"
              justify="space-between"
              w="100%"
            >
              <Text kind="semiBold" fontSize={14} ml={theme.pxToRem(8)}>
                {t(item.text)}
              </Text>
            </Flex>
          ))}
        </Flex>

        <Flex flexDir="column" w="33%" bgColor="cardBackground" borderRadius={theme.pxToRem(10)}>
          <Flex justify="space-between" flexDir="column" p={theme.pxToRem(20)} h={headerHeight}>
            <Text fontSize={theme.pxToRem(20)} kind="extraBold" color="textLight">
              Premium
            </Text>
            <Text fontSize={theme.pxToRem(12)} kind="medium">
              Para você que quer impulsionar os resultados financeiros da sua barbearia e ter todo o controle do seu
              negócio na palma da sua mão.
            </Text>
            {/* <Text fontSize={theme.pxToRem(18)} kind="bold" color="textMedium">
              Gratuito
            </Text> */}
            {clickButton && (
              <Button h={theme.pxToRem(40)} w="100%" onClick={clickButton}>
                Testar por 7 dias grátis
              </Button>
            )}
          </Flex>

          {benefits.map((item, index) => (
            <Flex
              key={`firstColumn_benefit_${index}`}
              align="center"
              justify="center"
              borderWidth={1}
              p={theme.pxToRem(12)}
              h={theme.pxToRem(42)}
              borderColor="border"
              w="100%"
            >
              <Flex w="33%" justify="center">
                <Icon
                  name={item?.basic ? 'CheckCircle' : 'CloseCircle'}
                  fill={item?.basic ? 'green' : 'danger'}
                  size={20}
                  mt={theme.pxToRem(3)}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>

        <Flex w="33%" flexDir="column" bgColor="backgroundLayout" borderRadius={theme.pxToRem(10)}>
          <Flex justify="space-between" flexDir="column" p={theme.pxToRem(20)} h={headerHeight}>
            <Flex flexDir="row">
              <Icon name="Crown" mr={theme.pxToRem(10)} fill="primary" />
              <Text fontSize={theme.pxToRem(20)} kind="extraBold">
                App Personalizado
              </Text>
            </Flex>
            <Text fontSize={theme.pxToRem(12)} kind="medium">
              Para você que quer oferecer uma experiência exclusiva para seus clientes, onde você terá seu próprio
              aplicativo, personalizado com suas cores e logo.
            </Text>
            {/* <>
              <Text
                mt={theme.pxToRem(12)}
                fontSize={theme.pxToRem(12)}
                kind="medium"
              >
                Por apenas
              </Text>
              <Flex flexDir="row" align="flex-end">
                <Text fontSize={theme.pxToRem(28)} kind="extraBold">
                  R$ 69,90
                </Text>
                <Text fontSize={theme.pxToRem(12)} kind="medium">
                  /mês
                </Text>
              </Flex>
            </> */}
            {clickButton && (
              <Button h={theme.pxToRem(40)} mt={theme.pxToRem(12)} w="100%" onClick={clickAppExclusive}>
                {props?.buttonText || 'Quero um App Personalizado!'}
              </Button>
            )}
          </Flex>

          {benefits.map((item, index) => (
            <Flex
              key={`firstColumn_benefit_${index}`}
              align="center"
              justify="center"
              borderWidth={1}
              p={theme.pxToRem(12)}
              h={theme.pxToRem(42)}
              borderColor="border"
              w="100%"
            >
              <Icon name={'CheckCircle'} fill={'green'} size={20} mt={theme.pxToRem(3)} />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
