import React from 'react'
import PropTypes from 'prop-types'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  VStack,
} from '@chakra-ui/react'
import { Button, Icon, Text } from 'components/atoms'
import { useTranslation } from 'react-i18next'

export const ActionModal = ({
  title,
  description,
  onConfirm,
  onCancel,
  isOpen = false,
  loading = false,
}) => {
  const cancelRef = React.useRef()
  const { t } = useTranslation()
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="brand.secondary" borderRadius="24" p="5">
          <AlertDialogCloseButton color="white" m="5" />
          <AlertDialogBody mt="10" alignItems="center" justifyContent="center">
            <Icon
              name="ExclamationCircle"
              size={80}
              alignItems="center"
              justifyContent="center"
              mb="8"
              color="danger"
            />
            <Text kind="extraBold" fontSize="xl" textAlign="center" mb="4">
              {t(title)}
            </Text>
            <Text textAlign="center">{t(description)}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <VStack alignItems="stretch" spacing="2" flex="1">
              <Button
                size="block"
                colorScheme="red"
                onClick={onConfirm}
                ml={3}
                kind="danger"
                disabled={!!loading}
              >
                {loading ? t('DELETING') : t('CONFIRM')}
              </Button>
              <Button
                size="block"
                ref={cancelRef}
                onClick={onCancel}
                kind="ghost"
              >
                {t('CANCEL')}
              </Button>
            </VStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

ActionModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
}
