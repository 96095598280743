import React, { useState } from 'react'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useToast } from '@chakra-ui/react'
import { Box, Text, Button } from 'components/atoms'
import {
  AgendaIntervalModal,
  WorkingHoursTable,
  PersonalizedHours,
} from 'components/organisms'
import { colors } from 'styles/colors'
import { pxToRem } from 'styles/metrics'
import { daysOfWeek } from 'utils'
import { useTranslation } from 'react-i18next'
import { AgendaIntervalCard, Table } from 'components/molecules'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import {
  BARBERSHOP_OPENING_HOURS,
  BARBERSHOP_AGENDA_INTERVAL,
} from 'services/api/endpoints'
import { format, parseISO } from 'date-fns'
import { useBarbershopWorkingHours } from 'services/hooks'

export const WorkingHours = () => {
  const { userData, setUserData } = useBarbershopStore()
  const toast = useToast()
  const { t } = useTranslation()
  const { deletePersonalizedOpeningHours } = useBarbershopWorkingHours()

  const [weekDays, setWeekDays] = useState(
    userData?.opening_hours || daysOfWeek
  )
  const [personalizedDays, setPersonalizedDays] = useState(
    userData?.personalized_opening_hours
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [personalizedOpen, setPersonalizedOpen] = useState(false)

  const columns = [
    {
      key: 'date',
      label: 'DATE',
      render: (item) => (
        <>
          <Text>{format(parseISO(item?.date), 'dd/MM/yyyy')}</Text>
        </>
      ),
    },
    {
      key: 'start_hour',
      label: 'HORÁRIO DE FUNCIONAMENTO',
      render: (item) => (
        <Text>
          {item?.is_closed ? '-' : `${item?.start_hour} - ${item?.close_hour} `}
        </Text>
      ),
    },
    {
      key: 'is_closed',
      label: 'ESTARÁ FECHADO?',
      render: (item) => <Text>{item?.is_closed ? 'Sim' : 'Não'}</Text>,
    },
  ]

  async function getWorkingHours() {
    const res = await api
      .get(BARBERSHOP_OPENING_HOURS(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { refetch } = useQuery('get-working-hours', getWorkingHours, {
    onSuccess: (data) => {
      if (data) {
        setWeekDays(data?.opening_hours)
        setPersonalizedDays(data?.personalized_opening_hours)
      }
    },
  })

  async function changeWorkingHours(params) {
    const res = await api
      .put(
        BARBERSHOP_OPENING_HOURS(userData?.barbershop?.id || userData?.id),
        params.params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  const mutate = useMutation(
    'change-working-hours',
    (params) => changeWorkingHours(params),
    {
      onSuccess: (data) => {
        setUserData({
          ...userData,
          opening_hours: data,
        })
        toast({
          title: t('UPDATE_WORKING_HOURS'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: t('UPDATE_WORKING_HOURS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleSubmit = () => {
    if (weekDays?.find((hour) => hour?.close_hour < hour?.start_hour)) {
      toast({
        title: 'Erro ao alterar horário de funcionamento',
        description: 'A hora de fechamento deve ser maior que a de abertura',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    mutate.mutate({ params: { opening_hours: weekDays } })
  }

  async function getAgendaInterval() {
    const res = await api
      .get(BARBERSHOP_AGENDA_INTERVAL(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-agenda-interval', getAgendaInterval, {
    onSuccess: (data) => {
      if (data) {
        setUserData({
          ...userData,
          agenda_time_interval: data,
        })
      }
    },
  })

  const deletePersonalizedMutation = useMutation(
    'delete-personalized-mutation',
    (item) =>
      deletePersonalizedOpeningHours({
        barbershop_id: userData?.barbershop?.id || userData?.id,
        item,
      }),
    {
      onSuccess: (data) => {
        refetch()
        toast({
          title: t('UPDATE_WORKING_HOURS'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: t('UPDATE_WORKING_HOURS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <Box width="100%" color={colors.white}>
      <Text fontSize={pxToRem(18)} mb={pxToRem(20)} kind="bold">
        {t('WORKING_HOURS')}
      </Text>

      <WorkingHoursTable daysList={weekDays} setDaysList={setWeekDays} />
      {/* SAVE BUTTON */}
      <Box mt={pxToRem(32)} w="100%">
        {personalizedDays && personalizedDays?.length > 0 && (
          <>
            <Text fontSize={18} fontWeight="900" mb={pxToRem(2)}>
              Dias personalizados
            </Text>

            <Table
              deleteButtom
              containSwitch
              isSelectableRow
              data={personalizedDays || []}
              columns={columns}
              onSelect={(item) => {
                deletePersonalizedMutation.mutate(item)
              }}
            />
          </>
        )}
        <Button
          onClick={() => setPersonalizedOpen(true)}
          p={`${pxToRem(16)} ${pxToRem(24)}`}
          w="100%"
          mb="24px"
          mt="12px"
        >
          Criar horário personalizado
        </Button>

        <Text fontSize={18} fontWeight="900" mb={pxToRem(2)}>
          {t('AGENDA_INTERVAL')}
        </Text>
        <Text fontSize={14} mb={pxToRem(24)}>
          {t('AGENDA_INTERVAL_SUBTITLE')}
        </Text>

        <AgendaIntervalCard
          time={userData?.agenda_time_interval}
          onClick={() => setIsModalOpen(true)}
        />
      </Box>
      <Box mt={pxToRem(48)}>
        <Button
          disabled={mutate.isLoading}
          isLoading={mutate.isLoading}
          onClick={handleSubmit}
          p={`${pxToRem(16)} ${pxToRem(24)}`}
          w="100%"
        >
          {t('SAVE_CHANGES')}
        </Button>
      </Box>

      <PersonalizedHours
        isModalOpen={personalizedOpen}
        onClose={() => setPersonalizedOpen(false)}
        refetch={refetch}
      />

      <AgendaIntervalModal
        title={t('AGENDA_INTERVAL')}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
    </Box>
  )
}
