import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Box, Layout } from 'components'
import { ConfigurationMenu } from 'components/molecules/ConfigurationMenu'
import { ConfigurationContainer } from 'components/templates/ConfigurationContainer'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'

export const ConfigurationScreen = ({ ...props }) => {
  const { t } = useTranslation()
  const params = useParams()
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()

  return (
    <Layout
      {...props}
      headerTitle="Configurações personalizadas"
      fullHeight={false}
    >
      {isDesktop ? (
        <Flex
          direction={{ base: 'column', md: 'row' }}
          sx={{ gap: 16 }}
          width="100%"
        >
          <ConfigurationMenu userData={userData} route={params?.section} />
          <ConfigurationContainer route={params?.section} />
        </Flex>
      ) : (
        <Box width="full">
          <ConfigurationMenu userData={userData} route={params?.section} />
        </Box>
      )}
    </Layout>
  )
}

ConfigurationScreen.path = '/configuracoes/:section'
ConfigurationScreen.title = 'Configurations'
ConfigurationScreen.secured = true
