import React from 'react'
import { Table, ModalMenu } from 'components/molecules'
import { useQuery } from 'react-query'
import { useBarbershopStore } from 'services/stores'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { t } from 'services/translation'

const columns = [{ key: 'name', label: 'NAME_TABLE' }]

export const AdditionalProducts = ({ isOpen, onClose, setProduct }) => {
  const { userData } = useBarbershopStore()

  const { getAdditionalProducts } = useBarbershop()

  const { data, isLoading } = useQuery('get-additional-products', () =>
    getAdditionalProducts({ id: userData?.id })
  )

  return (
    <ModalMenu
      title={t('SELECT_ADDITIONAL_PRODUCT')}
      isOpen={isOpen}
      onClose={onClose}
      flexDirection="column"
    >
      <Table
        isSelectableRow
        data={data || []}
        loading={isLoading}
        columns={columns}
        onSelect={setProduct}
      />
    </ModalMenu>
  )
}
