import React, { useState, useEffect } from 'react'
import { Box, Flex, useTheme, Select } from '@chakra-ui/react'
import { ModalMenu, ModalAlert, Form } from 'components/molecules'
import { Button, Text } from 'components/atoms'
import { clearCurrency } from 'utils/clearCurrency'
import { useTranslation } from 'react-i18next'
import { inputs, validation, initialValues, selectProps } from './settings'
import { useQuery, useMutation } from 'react-query'
import { format, parseISO } from 'date-fns'

import {
  usePermissionCollaborator,
  useBreakpoint,
  useBarbershop,
} from 'services/hooks'

import { useBarbershopStore } from 'services/stores'

export const CashierReleaseModal = ({
  isOpen,
  onClose,
  modalTitle: title,
  editData,
  ...props
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const finance = usePermissionCollaborator('finance')
  const { getPaymentMethods, submitFinanceRelease, deleteFinanceRelease } = useBarbershop()
  const { userData } = useBarbershopStore()

  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [values, getValues] = useState({
    params: initialValues,
    isValid: false,
  })

  // API QUERIES
  const { data } = useQuery('get-payment-methods', () =>
    getPaymentMethods(userData?.barbershop?.id || userData?.id)
  )
  const mutation = useMutation(
    'submit-release',
    () => {
      const params = {
        ...values?.params,
        due_at_date: `${values?.params?.due_at_date} 03:00:00+00`,
        value: clearCurrency(String(values?.params?.value)),
        payment_method_id: values?.params?.payment_method?.id,
        finance_categories_id: editData?.finance_category_id || props?.finance_category_id,
        description: editData?.description || title,
        type: editData?.type || props?.type,
        barbershop_id: userData?.barbershop?.id || userData?.id

      }
      submitFinanceRelease({ params, onSuccess: () => onClose(false) })
    }
  )

  const delelte_mutation = useMutation(
    'delete-release',
    () => deleteFinanceRelease({ params: values?.params, onSuccess: () => onClose(false) })
  )

  // HANDLE UPDATE VALUES
  const handleSetValues = (key, value) => {
    getValues({
      params: { ...values.params, [key]: value },
      isValid: values.isValid,
    })
  }
  const selectPaymentMethod = (e) => {
    const [paymentMethod] = data?.filter(
      (item) => e.target.value === item?.name
    )
    handleSetValues('payment_method', paymentMethod)
  }

  useEffect(() => {
    if (editData) {
      getValues({
        params: {
          ...editData, value: Number(editData?.value),
          due_at_date: format((parseISO(editData?.due_at_date || new Date())), 'yyyy-MM-dd')
        },
        isValid: false,
      })
    }
  }, [editData])

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={onClose}
      bodyStyle={{ display: 'flex', flexDirection: 'column', pb: 1 }}
    >
      {/* CONTENT CONTAINER */}
      <Box h="89%">
        {/* TITLE */}
        <Flex flexDir={'row'} mb={theme.pxToRem(16)}>
          <Text fontSize={theme.pxToRem(20)} kind={'bold'} color={'white'}>
            Adicionar nova
          </Text>
          <Text
            fontSize={theme.pxToRem(20)}
            ml={theme.pxToRem(6)}
            kind={'bold'}
            color={title === 'ENTRADA' ? 'success' : 'danger'}
          >
            {title}
          </Text>
        </Flex>
        {/* INPUTS */}
        <Form
          mb={32}
          getValues={getValues}
          initialValues={values?.params}
          validationSchema={validation}
          data={inputs}
        />
        {/* PAYMENT METHOD LABEL*/}
        <Text
          mt={theme.pxToRem(36)}
          mb={theme.pxToRem(12)}
          fontSize={theme.pxToRem(14)}
          color="textGrey"
        >
          {t('PAYMENT_METHOD')}
        </Text>
        {/* PAYMENT METHOD SELECT*/}
        <Select
          {...selectProps}
          value={values?.params?.payment_method?.name}
          onChange={selectPaymentMethod}
        >
          {data?.map((item) => (
            <option {...selectProps?.optStyle} key={item?.id}>
              {item?.name}
            </option>
          ))}
        </Select>
        {/* STATUS LABEL*/}
        <Text
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(12)}
          fontSize={theme.pxToRem(14)}
          color="textGrey"
        >
          {t('CHOOSE_RELEASE_STATUS')}
        </Text>
        {/* STATUS OPTIONS BUTTONS*/}
        <Flex flexDirection="row" justifyContent="space-between">
          <Button
            kind={values?.params?.status === 'pending' ? 'primary' : 'outline'}
            size="small"
            w="100%"
            marginRight="32px"
            onClick={() => handleSetValues('status', 'pending')}
            isDisabled={finance !== 'edit'}
          >
            {t('PENDING_PAYMENT')}
          </Button>
          <Button
            kind={values?.params?.status === 'paid' ? 'primary' : 'outline'}
            size="small"
            w="100%"
            onClick={() => handleSetValues('status', 'paid')}
            isDisabled={finance !== 'edit'}
          >
            {t('PAID_PAYMENT')}
          </Button>
        </Flex>
      </Box>
      {/* BUTTONS CONTAINER */}
      <Flex h="10%">
        {editData && <Button
          flex="1"
          mr={theme.pxToRem(12)}
          kind="danger"
          textTransform="uppercase"
          onClick={() => setAlertModalOpen(true)}
        >
          {t('DELETE')}
        </Button>}
        <Button
          w="100%"
          isLoading={mutation.isLoading}
          // disabled={mutation.isLoading}
          onClick={mutation.mutate}
        >
          {t('SAVE')}
        </Button>
      </Flex>
      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        onConfirm={delelte_mutation.mutate}
        primaryButtonLabel={t('DELETE')}
        modalTitle={t('CONFIRM_DELETE_RELEASE')}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CONFIRM_DELETE_RELEASE')} "{values?.params?.title}" ?
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}
