import React, { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalHeader, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { Button, Text, TextInput } from 'components/atoms'

export const ChangePasswordModal = ({ isOpen, onClose, onClick, loading }) => {
  const theme = useTheme()
  const [password, setPassword] = useState('')

  const closeModal = () => {
    setPassword('')
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      setPassword('')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      {
        <ModalContent
          maxW={theme.pxToRem(700)}
          borderColor="primary"
          borderWidth={1}
          bg="backgroundLayout"
          flexDir="column"
          align="center"
          justify="center"
          p="60px"
          borderRadius="12px"
          maxH={theme.pxToRem(1028)}
        >
          <ModalHeader textAlign="center" w={'100%'}>
            <Flex flexDir="column" w="100%" alignItems="center" justifyContent="center">
              <Text mb="8px" kind="extraBold" fontSize={28} textAlign="center">
                Alterar senha
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mt="8px" color="white" onClick={closeModal} />
          <TextInput
            noBorder
            w="100%"
            mt="12px"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            h="56px"
            label="Nova senha"
          />
          <Flex justify="space-between" mt="80px">
            <Button kind={'secondary'} marginRight="32px" size="medium" onClick={closeModal}>
              Cancelar
            </Button>
            <Button
              disabled={password === ''}
              kind="primary"
              size="medium"
              onClick={() => {
                onClick(password)
              }}
              isLoading={loading}
            >
              Alterar
            </Button>
          </Flex>
        </ModalContent>
      }
    </Modal>
  )
}
