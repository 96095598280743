import * as React from 'react'

function CommandSvg(props) {

    return (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M1 14L3 12L1 10M3 8V2C3 1.73478 3.10536 1.48043 3.29289 1.29289C3.48043 1.10536 3.73478 1 4 1H9L14 6V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H10.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 1V6H14M5 14H8" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}

export default CommandSvg

