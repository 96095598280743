import { Text } from 'components/atoms'
import { formatPrice } from 'utils'

export const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
  {
    key: 'price',
    label: 'VALUES',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {formatPrice(item.price)}
      </Text>
    ),
  },
  {
    key: 'time_required',
    label: 'LENGTH',
    render: (item) => <Text>{item.time_required.substring(0, 5)}</Text>,
  },
]
