import * as React from 'react'

function SvgSend(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.176987 0.118741C0.251675 0.0553713 0.343332 0.015334 0.440576 0.00360069C0.53782 -0.00813263 0.636369 0.00895482 0.723987 0.0527415L13.724 6.55274C13.8069 6.59431 13.8767 6.65814 13.9254 6.73708C13.9741 6.81602 14 6.90697 14 6.99974C14 7.09252 13.9741 7.18346 13.9254 7.2624C13.8767 7.34135 13.8069 7.40517 13.724 7.44674L0.723987 13.9467C0.636429 13.9906 0.537904 14.0079 0.440646 13.9963C0.343387 13.9847 0.251679 13.9447 0.176907 13.8815C0.102135 13.8182 0.047592 13.7344 0.0200507 13.6404C-0.0074905 13.5464 -0.00681733 13.4464 0.0219868 13.3527L1.97699 6.99974L0.0219868 0.646742C-0.00665259 0.553219 -0.00723293 0.45335 0.0203175 0.3595C0.047868 0.265651 0.102339 0.181943 0.176987 0.118741V0.118741ZM2.86899 7.49974L1.32199 12.5297L12.382 6.99974L1.32199 1.46974L2.86899 6.49974H8.49999C8.63259 6.49974 8.75977 6.55242 8.85354 6.64619C8.94731 6.73996 8.99999 6.86713 8.99999 6.99974C8.99999 7.13235 8.94731 7.25953 8.85354 7.3533C8.75977 7.44706 8.63259 7.49974 8.49999 7.49974H2.86999H2.86899Z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgSend
