import React, { useState, useEffect } from 'react'
import { Layout, Table, FinanceResumeCard, FeatureBlockedMessage, TextInput, Text } from 'components'
import { settings } from './settings'
import { Center, Flex, Grid, HStack, Select, Spinner, Stack, useTheme, useToast } from '@chakra-ui/react'
import { useBarbershop, usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'

import { useMutation, useQuery } from 'react-query'
import { useBarbershopSubscription } from 'services/hooks'
import { columns } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const SignatureReportTotal = (props) => {
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const { getSignatureGlobalResume } = useBarbershopSubscription()
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const [status, setStatus] = useState()
  const [type, setType] = useState()
  const [dataTable, setDataTable] = useState({ data: [], filtered: [] })
  const { onLogout } = useLogoutPaymentIssues()
  const { exportSignatureReport } = useBarbershop()

  useEffect(() => {
    onLogout()
  }, [])
  const { data, isLoading, refetch } = useQuery(
    ['get-signature-extract-resume', startDate, endDate, userData?.barbershop?.id || userData?.id, type],
    getSignatureGlobalResume,
    {
      onSuccess: (dt) => {
        setDataTable({ data: dt?.releases, filtered: dt?.releases })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao buscar dados',
          description: error.response?.data?.error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  const isBlocked = usePremiumBlock()

  const onSelectRelease = (release) => {
    window.open(release?.invoice_url, '_blank')
  }

  useEffect(() => {
    if (!status) {
      setDataTable({ ...dataTable, filtered: dataTable?.data })
      return
    }
    if (status === 'Recebido em dinheiro') {
      setDataTable({ ...dataTable, filtered: dataTable?.data?.filter((i) => i?.asaas_status === 'RECEIVED_IN_CASH') })
      return
    }
    setDataTable({ ...dataTable, filtered: dataTable?.data?.filter((i) => i?.asaas_status !== 'RECEIVED_IN_CASH') })
  }, [status])

  const mutate = useMutation(
    ['get-signatures-excel'],
    (params) => exportSignatureReport({ params, id: userData?.id }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
      },
      retry: false,
    }
  )

  const handleExport = () => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      type: type,
    }
    mutate.mutate(params)
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Extrato - Assinaturas"
      widthTitle="100%"
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      // subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      thirtyClick={{
        icon: 'Excel',
        onClick: handleExport,
        isLoading: mutate.isLoading,
        text: 'Exportar em excel',
      }}
      secondActionText={t('CHANGE_UNITY')}
      headerCenterElement={() => (
        <Flex>
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            label={t('START')}
            padding={theme.pxToRem(10)}
            mr={theme.pxToRem(16)}
          />
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            label={t('END')}
            padding={theme.pxToRem(10)}
            width={'100%'}
          />
        </Flex>
      )}
    >
      <>
        {isBlocked && <FeatureBlockedMessage title="BARBERS_REPORT_BLOCKED" section="PAYMENTS" />}

        {!data || isLoading ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : (
          <Flex w="100%" flexDir="column" padding={isBlocked && theme.pxToRem(24)} pointerEvents={isBlocked && 'none'}>
            <Grid templateColumns="repeat(3, 1fr)" gap={3} mb="24px">
              {settings(data)?.map((item) => (
                <FinanceResumeCard
                  width="100%"
                  notCurrency={item?.notCurrency}
                  label={t(item?.label)}
                  description={t(item?.description)}
                  value={item?.value}
                  color={item?.color}
                  info={item?.info}
                  infoText={item?.infoText}
                />
              ))}
              <HStack>
                <FinanceResumeCard
                  width="100%"
                  notCurrency={true}
                  label="Crédito"
                  description="Qtd. Pagamentos"
                  value={data?.received_credit}
                  color="white"
                  info={true}
                  infoText="Quantidade de pagamentos por cartão de crédito na asaas"
                  last
                />
                <FinanceResumeCard
                  width="100%"
                  notCurrency={true}
                  label="Débito"
                  description="Qtd. Pagamentos"
                  value={data?.received_debit}
                  color="white"
                  info={true}
                  infoText="Quantidade de pagamentos por cartão de débito na asaas"
                  last
                />
                <FinanceResumeCard
                  width="100%"
                  notCurrency={true}
                  label="Dinheiro"
                  description="Qtd. Pagamentos"
                  value={data?.received_money}
                  color="white"
                  info={true}
                  infoText="Quantidade de pagamentos recebidos em dinheiro fora da asaas"
                  last
                />
              </HStack>
            </Grid>
            <Flex>
              <Flex
                mr={theme.pxToRem(16)}
                flexDir="row"
                borderRadius={theme.pxToRem(8)}
                bg="cardBackground"
                h={theme.pxToRem(42)}
                align="center"
                justify="center"
                w="400px"
              >
                <Text fontSize={14} kind="medium" ml={theme.pxToRem(8)} mr={theme.pxToRem(4)} color="textGrey">
                  Filtrar periodo por:
                </Text>

                <Select
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                  value={status}
                  // placeholder={t('ALL')}
                  w={theme.pxToRem(200)}
                  fontFamily="Montserrat Bold"
                  fontSize={14}
                  textColor="textLight"
                  iconColor="primary"
                  borderColor="transparent"
                  errorBorderColor="baseRed"
                  focusBorderColor="transparent"
                  variant="unstyled"
                  marginBottom={theme.pxToRem(2)}
                >
                  {[
                    {
                      name: 'Data de faturamento',
                      value: 'invoice',
                    },
                    {
                      name: 'Data de recebimento',
                      value: 'receipt',
                    },
                  ].map((item, index) => (
                    <option
                      style={{
                        backgroundColor: theme.colors.menuBackground,
                        fontFamily: 'Montserrat Medium',
                      }}
                      key={`barber_${item?.name}`}
                    >
                      {item?.name}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex
                mr={theme.pxToRem(16)}
                flexDir="row"
                borderRadius={theme.pxToRem(8)}
                bg="cardBackground"
                h={theme.pxToRem(42)}
                align="center"
                justify="center"
                w="200px"
              >
                <Text fontSize={14} kind="medium" ml={theme.pxToRem(8)} mr={theme.pxToRem(4)} color="textGrey">
                  Status:
                </Text>

                <Select
                  name="type"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  placeholder={t('ALL')}
                  w={theme.pxToRem(140)}
                  fontFamily="Montserrat Bold"
                  fontSize={14}
                  textColor="textLight"
                  iconColor="primary"
                  borderColor="transparent"
                  errorBorderColor="baseRed"
                  focusBorderColor="transparent"
                  variant="unstyled"
                  marginBottom={theme.pxToRem(2)}
                >
                  {[
                    {
                      name: 'Recebido em dinheiro',
                    },
                    {
                      name: 'Recebido na asaas',
                    },
                  ].map((item, index) => (
                    <option
                      style={{
                        backgroundColor: theme.colors.menuBackground,
                        fontFamily: 'Montserrat Medium',
                      }}
                      key={`barber_${item?.name}`}
                    >
                      {item?.name}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
            <Table
              loading={isLoading}
              data={
                dataTable?.filtered?.sort((a, b) => {
                  return a?.payment_date > b?.payment_date ? -1 : a?.payment_date < b?.payment_date ? 1 : 0
                }) || []
              }
              isSelectableRowVariabled={dataTable?.filtered?.map(
                (i) => i.status === 'REFUND' || (i.asaas_status && i.asaas_status !== 'RECEIVED_IN_CASH')
              )}
              onSelect={(item) => {
                if (item.asaas_status === 'RECEIVED_IN_CASH') {
                  return null
                }
                onSelectRelease(item)
              }}
              columns={columns}
            />
          </Flex>
        )}
      </>
    </Layout>
  )
}

SignatureReportTotal.path = '/relatorio-assinaturas-total'
SignatureReportTotal.title = 'Extrato - Assinaturas'
SignatureReportTotal.secured = true
