import * as Yup from 'yup'
import { t } from 'services/translation'

export const dataProfile = [
  {
    id: 0,
    name: 'owner_name',
    type: 'string',
    noBorder: true,
    label: 'RESPONSABLED_NAME',
    placeholder: t('RESPONSABLED_NAME'),
  },
  {
    id: 1,
    name: 'secondary_identifier_doc',
    noBorder: true,
    label: 'CPF do Responsável',
    placeholder: 'CPF',
    mask: 'CPF_MASK',
  },
  {
    id: 2,
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'BARBERSHOP_NAME',
    placeholder: t('BARBERSHOP_NAME'),
  },
  {
    id: 3,
    name: 'phone',
    type: 'cel-phone',
    noBorder: true,
    label: 'BARBERSHOP_PHONE',
    mask: 'PHONE_MASK',
    placeholder: t('BARBERSHOP_PHONE'),
  },
  // {
  //   id: 4,
  //   name: 'identifier_doc',
  //   noBorder: true,
  //   label: 'CNPJ',
  //   placeholder: 'CNPJ',
  //   mask: 'CNPJ_MASK',
  // },
  // {
  //   id: 5,
  //   name: 'description',
  //   label: 'BARBERSHOP_DESCRIPTION',
  //   placeholder: t('BARBERSHOP_DESCRIPTION_PLACEHOLDER'),
  //   typeInput: 'textArea',
  // },
]

export const validationProfile = Yup.object().shape({
  name: Yup.string().min(4, t('NAME')).required(t('NAME_REQUIRED')),
  owner_name: Yup.string()
    .min(4, 'NAME')
    .required('Nome do responsável é obrigatório'),
  phone: Yup.string(),
  identifier_doc: Yup.string(),
  secondary_identifier_doc: Yup.string(),
  description: Yup.string()
    .min(10, t('DESCRIPTION'))
    .required(t('DESCRIPTION_REQUIRED')),
})

export const initialProfile = {
  name: '',
  owner_name: '',
  phone: '',
  identifier_doc: '',
  description: '',
  secondary_identifier_doc: '',
}

//LOCATION ------------------------------------------------------------------------
export const dataLocation = [
  {
    name: 'zip_code',
    type: 'string',
    noBorder: true,
    label: 'ZIP_CODE_PLACEHOLDER',
    mask: 'CEP_MASK',
  },
  {
    name: 'street_number',
    type: 'string',
    noBorder: true,
    label: 'NUMBER',
  },
  {
    name: 'street',
    type: 'string',
    noBorder: true,
    label: 'ADDRESS',
  },
  {
    name: 'neighborhood',
    type: 'string',
    noBorder: true,
    label: 'NEIGHBORHOOD',
  },
  {
    name: 'complement',
    type: 'string',
    noBorder: true,
    label: 'COMPLEMENT',
  },
  {
    name: 'city',
    type: 'string',
    noBorder: true,
    label: 'CITY',
  },
  {
    name: 'state',
    type: 'string',
    noBorder: true,
    label: 'STATE',
  },
]

export const validationLocation = Yup.object().shape({
  zip_code: Yup.string()
    .min(8, t('INCOMPLETE_CEP'))
    .required(t('CEP_REQUIRED')),
  state: Yup.string().required(t('STATE_REQUIRED')),
  city: Yup.string().required(t('CITY_REQUIRED')),
  street: Yup.string().required(t('STREET_REQUIRED')),
  street_number: Yup.string().required(t('STREET_NUMBER_REQUIRED')),
  neighborhood: Yup.string(),
})

export const initialLocation = {
  zip_code: '',
  state: '',
  city: '',
  street: '',
  street_number: '',
  neighborhood: '',
  country: '',
  latitude: '',
  longitude: '',
}

//LOCATION ------------------------------------------------------------------------
export const dataBarber = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    noBorder: true,
    label: 'BARBER_NAME',
    placeholder: t('BARBER_NAME'),
  },
  {
    id: 'phone',
    name: 'phone',
    type: 'tel',
    noBorder: true,
    label: 'PHONE',
    mask: 'PHONE_MASK',
    placeholder: '(00) 00000-0000',
  },
  {
    id: 'email',
    name: 'email',
    type: 'email',
    noBorder: true,
    label: 'E-mail',
    placeholder: 'exemplo@ex.com',
  },
]

export const validationBarber = Yup.object().shape({
  email: Yup.string().email(t('INVALID_EMAIL')).required(t('EMAIL_REQUIRED')),
  phone: Yup.string().required(t('CELLPHONE_REQUIRED')),
  name: Yup.string().min(3, t('INVALID_NAME')).required(t('NAME_REQUIRED')),
})

export const initialBarber = {
  name: '',
  phone: '',
  email: '',
}
