import React, { useMemo, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Box, Text, TextInput, Button, Flex } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from 'react-query'
import { useBarbershop } from 'services/hooks'

export const ClientsPasswordModal = ({ isModalOpen, onClose, clientData = {} }) => {
  const toast = useToast()
  const [password, setPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const { updateClientPassword } = useBarbershop()

  const mutate = useMutation(
    'edit-client-password',
    () =>
      updateClientPassword({
        params: {
          client_id: clientData?.id,
          password,
          new_password: newPassword,
        },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Senha atualizada com sucesso',
          duration: 3000,
          status: 'success',
        })
        onClose()
      },
      onError: (error) => {
        toast({
          title: 'Erro ao atualizar senha',
          description: error.response.data.error,
          duration: 3000,
          status: 'error',
        })
      },
    }
  )

  const isButtonDisabled = useMemo(
    () => mutate.isLoading || password !== newPassword || password?.length < 6 || newPassword?.length < 6,
    [mutate.isLoading, password, newPassword]
  )

  return (
    <ModalMenu isOpen={isModalOpen} onClose={onClose} title="Editar senha do cliente">
      <Box>
        <Box marginBottom={pxToRem(16)}>
          <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
            Nova senha
          </Text>

          <TextInput
            noBorder
            type="text"
            name="password"
            id="password"
            placeholder="Senha"
            value={password}
            disabled={mutate.isLoading}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Box marginBottom={pxToRem(16)}>
          <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
            Confirme nova senha
          </Text>

          <TextInput
            noBorder
            type="text"
            name="newPassword"
            id="newPassword"
            placeholder="Senha"
            value={newPassword}
            disabled={mutate.isLoading}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Box>
      </Box>

      <Flex marginTop="auto">
        <Button w="100%" type="submit" onClick={mutate.mutate} disabled={isButtonDisabled || mutate.isLoading}>
          Salvar
        </Button>
      </Flex>
    </ModalMenu>
  )
}

ClientsPasswordModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
