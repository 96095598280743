import React, { useState, useEffect, useMemo } from 'react'
import {
  Layout,
  Table,
  Text,
  ServicesModal,
  FinanceResumeCard,
  MobileList,
  ServiceDetailsCard,
  FeatureBlockedMessage,
  PieChart,
  Icon,
  TextInput,
  AppointmentsResumeCard,
  Box,
} from 'components'
import { settings, settingsMobile, options, SelectFilter, status_options, order_options } from './settings'
import { Center, Flex, Spinner, useToast, useTheme } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'

import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { BARBERSHOP_SERVICES_RESUME, ONLY_BARBERS } from 'services/api/endpoints'
import { servicesReport } from 'utils/mockData'
import { UnitysModal } from 'components/organisms/Unitys'
import { financesStore } from 'services/stores/finances'
import { useBarbershop } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const ServicesReport = (props) => {
  const { isDesktop } = useBreakpoint()
  const { userData, subscription } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [serviceModalOpen, setServiceModalOpen] = useState(false)
  const [enable, setEnable] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const [barber, setBarber] = useState('')
  const [type, setType] = useState('')
  const [appointmentStatus, setAppointmentStatus] = useState('')
  const [order, setOrder] = useState({ value: 'total_value' })
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const { unity, setUnity } = financesStore()
  const { exportServicesReport } = useBarbershop()
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])
  const cellColors = [...theme.colors.pieChart]

  async function getServiceResume({ queryKey }) {
    const params = {
      id: queryKey[1]?.id || userData?.barbershop?.id || userData?.id,
      start_date: queryKey[2],
      end_date: queryKey[3],
    }

    const body = {
      barber: queryKey[4],
      type: !queryKey[5] || queryKey[5] === '' ? 'all' : queryKey[5]?.value,
      status: queryKey[6]?.value,
    }
    const res = await api
      .post(BARBERSHOP_SERVICES_RESUME(params), body)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useEffect(() => {
    setUnity(userData)
  }, [userData])

  const { data, refetch, isLoading, isError } = useQuery(
    ['get-service-resume', unity, startDate, endDate, barber, type, appointmentStatus],
    getServiceResume,
    {
      onError: (error) => {
        toast({
          title: t('LOAD_COMMISSIONS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const columns = [
    {
      key: 'description',
      label: 'DESCRIPTION_TABLE',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Text>{item?.service || item?.name}</Text>
            <Box
              ml={theme.pxToRem(8)}
              h={theme.pxToRem(8)}
              w={theme.pxToRem(8)}
              bgColor={cellColors[index]}
              borderRadius={100}
            />
          </Flex>
        )
      },
    },

    // {
    //   key: 'value',
    //   label: 'VALOR',
    //   render: (item) => <Text>{formatPrice(item?.value)}</Text>,
    // },
    {
      key: 'category',
      label: 'CATEGORY',
      render: (item) => <Text>{t(item?.category) || t('_SERVICE')}</Text>,
    },
    {
      key: 'quantity',
      label: 'QUANTITY',
      render: (item) => <Text>{item?.quantity}</Text>,
    },
    {
      width: 30,
      key: 'finished',
      label: 'FINISHED',
      render: (item) => <Text>{item?.quantity_finished}</Text>,
    },
    {
      key: 'pending',
      width: 20,
      label: 'PENDING',
      render: (item) => <Text>{item?.quantity_pending}</Text>,
    },
    {
      key: 'total_value',
      label: 'TOTAL_SALES_TABLE',
      render: (item) => <Text>{formatPrice(item?.type === 'individual_service_package' ? 0 : item?.total_value)}</Text>,
    },
    {
      key: 'percentage',
      label: 'BILLING_PERCENTAGE',
      render: (item) => (
        <Text>
          {(
            (item?.type === 'individual_service_package'
              ? 0
              : item?.total_value / (isBlocked ? servicesReport : data)?.appointments_total_value) * 100
          ).toFixed(2)}
          %
        </Text>
      ),
    },
  ]

  async function getTeam() {
    const res = await api
      .get(ONLY_BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: barbers } = useQuery('get-team', getTeam)

  const isBlocked = usePremiumBlock()

  const onCloseServiceModal = () => {
    setUnityModalOpen(false)
    setServiceModalOpen(false)
    setSelectedService(null)
    refetch()
  }

  const servicesQuantity = useMemo(() => {
    return data?.all_sells?.reduce((acc, val) => acc + (!val?.category ? val?.quantity_finished : 0), 0)
  }, [data])

  const formattedData = useMemo(() => {
    let nData = (isBlocked ? servicesReport : data)?.all_sells?.sort(
      (a, b) =>
        (b?.type === 'individual_service_package' && order?.value === 'total_value' ? 0 : b?.[order?.value]) -
        (a?.type === 'individual_service_package' && order?.value === 'total_value' ? 0 : a?.[order?.value])
    )

    let label = nData?.map((item) => item?.service || item?.name)
    let d = nData?.map((item) => item?.quantity)

    const dataPie = {
      label,
      data: d,
    }
    return {
      data: nData || [],
      dataPie,
    }
  }, [data, isBlocked, order])

  const productsQuantity = useMemo(() => {
    return data?.all_sells?.reduce((acc, val) => acc + (val?.category ? val?.quantity_finished : 0), 0)
  }, [data])
  // barber: queryKey[4],
  // type: !queryKey[5] || queryKey[5] === '' ? 'all' : queryKey[5]?.value,
  // status: queryKey[6]?.value,
  const mutate = useMutation(
    ['get-services-excel'],
    () =>
      exportServicesReport({
        id: userData?.barbershop?.id || userData?.id,
        params: {
          start_date: startDate,
          end_date: endDate,
          type: !type || type === '' ? 'all' : type?.value,
          barber_id: barber?.id,
          status: appointmentStatus?.value,
        },
      }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
        setEnable(false)
      },
      retry: false,
      enabled: enable,
    }
  )

  const handleExport = () => {
    mutate.mutate()
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('SELLS_REPORT')}
      widthTitle="100%"
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      // headerActionClick={() => setUnityModalOpen(true)}
      // iconActionText={'Shop'}
      subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      // headerActionText={t('CHANGE_UNITY')}
      secondActionClick={handleExport}
      secondActionText="Exportar em excel"
      iconSecondActionText={'Excel'}
      headerCenterElement={() =>
        !isBlocked && !isDesktop ? (
          <>
            <TextInput
              noBorder
              type="date"
              name="start_date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              label={t('START')}
              padding={theme.pxToRem(10)}
              mr="4%"
              width={'93%'}
            />
            <TextInput
              noBorder
              type="date"
              name="end_date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              label={t('END')}
              ml="2%"
              padding="5%"
              width={'98%'}
            />
          </>
        ) : (
          !isBlocked && (
            <Flex>
              <TextInput
                noBorder
                type="date"
                name="start_date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label={t('START')}
                padding={theme.pxToRem(10)}
                mr={theme.pxToRem(16)}
              />
              <TextInput
                noBorder
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label={t('END')}
                padding={theme.pxToRem(10)}
                width={'100%'}
              />
            </Flex>
          )
        )
      }
    >
      <>
        {isBlocked && <FeatureBlockedMessage title="BARBERS_REPORT_BLOCKED" section="SELLS" />}

        {(!(isBlocked ? servicesReport : data) || isLoading) && !isError ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : isDesktop ? (
          <Flex w="100%" flexDir="column" padding={isBlocked && theme.pxToRem(24)} pointerEvents={isBlocked && 'none'}>
            <Flex w="100%" flexDir="row" alignItems="stretch" justifyContent="space-between" pb={theme.pxToRem(48)}>
              <Flex h={theme.pxToRem(210)} w="70%" flexDir="column" justifyContent="space-between" flexWrap="wrap">
                {settings(isBlocked ? servicesReport : data, productsQuantity, servicesQuantity)?.map((item, index) => {
                  if (item.cards) {
                    return (
                      <Flex w="50%">
                        {item.cards.map((a) => (
                          <FinanceResumeCard
                            width="50%"
                            notCurrency={a?.notCurrency}
                            label={t(a?.label)}
                            description={t(a?.description)}
                            value={a?.value}
                            color={a?.color}
                            info={a?.info}
                            infoText={a?.infoText}
                          />
                        ))}
                      </Flex>
                    )
                  }
                  return (
                    <FinanceResumeCard
                      width={index === 0 ? '48.5%' : '25%'}
                      notCurrency={item?.notCurrency}
                      label={t(item?.label)}
                      description={t(item?.description)}
                      value={item?.value}
                      color={item?.color}
                      info={item?.info}
                      infoText={item?.infoText}
                    />
                  )
                })}
                <AppointmentsResumeCard
                  mb={16}
                  width="30%"
                  item={isBlocked ? servicesReport : data}
                  infoText={'Resumo geral dos agendamentos'}
                />
                <Flex h={theme.pxToRem(286)} w="35%" align="center" justify="center">
                  {(isBlocked ? servicesReport : data)?.services_info?.length !== 0 ? (
                    <PieChart title={'SELLS_REPORT'} w="100%" data={formattedData?.dataPie} />
                  ) : (
                    <Flex flexDir="column" align="center">
                      <Icon name="Chart" size={40} />
                      <Text align="center">{t('NO_DATA_RESUME')}</Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDir="row" w="100%">
              <SelectFilter
                onChange={(e) => {
                  setBarber(
                    barbers?.find(
                      (i) => i?.name?.toLowerCase()?.replace(/\s/g, '') === e?.toLowerCase()?.replace(/\s/g, '')
                    )
                  )
                }}
                value={barber?.name?.trim()}
                options={barbers}
                label={'BARBER'}
              />
              <SelectFilter
                onChange={(e) => {
                  setType(options?.find((i) => i?.name === e))
                }}
                value={type?.name}
                options={options}
                label={'TYPE'}
              />
              <SelectFilter
                onChange={(e) => {
                  setAppointmentStatus(status_options?.find((i) => i?.name === e))
                }}
                value={appointmentStatus?.name}
                options={status_options}
                label={'Status da comanda'}
              />
              <SelectFilter
                onChange={(e) => {
                  setOrder(order_options?.find((i) => i?.name === e))
                }}
                value={order?.name}
                options={order_options}
                label={'Ordenar por'}
                withoutPlaceholder
                width={19}
              />
            </Flex>

            <Table loading={isLoading} data={formattedData?.data} columns={columns} />
          </Flex>
        ) : (
          <Flex
            flexDirection="column"
            width="full"
            padding={isBlocked && theme.pxToRem(16)}
            pointerEvents={isBlocked && 'none'}
          >
            <Flex w="100%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(232)}>
              {settingsMobile(isBlocked ? servicesReport : data, productsQuantity, servicesQuantity)?.map((item) => (
                <FinanceResumeCard
                  wMobile="50%"
                  notCurrency={item?.notCurrency}
                  label={t(item?.label)}
                  description={t(item?.description)}
                  value={item?.value}
                  color={item?.color}
                  last={item.last}
                />
              ))}
            </Flex>

            <AppointmentsResumeCard full item={isBlocked ? servicesReport : data} />

            <Flex h={theme.pxToRem(250)} mb={theme.pxToRem(16)}>
              {(isBlocked ? servicesReport : data)?.all_sells.length !== 0 ? (
                <PieChart title={'SELLS_REPORT'} h="100%" w="100%" data={formattedData?.dataPie} dataLabels />
              ) : (
                <Flex flexDir="column" align="center">
                  <Icon name="Chart" size={20} />
                  <Text>{t('NO_DATA_RESUME')}</Text>
                </Flex>
              )}
            </Flex>
            <MobileList
              loading={isLoading}
              data={
                (isBlocked ? servicesReport : data)?.all_sells.sort((a, b) => {
                  return a?.total_value > b?.total_value ? -1 : a?.total_value < b?.total_value ? 1 : 0
                }) || []
              }
              renderItem={({ item, index }) => (
                <ServiceDetailsCard
                  item={item}
                  index={index}
                  color={cellColors[index]}
                  totalAppointmentsThisMoth={(isBlocked ? servicesReport : data)?.appointments_total_value}
                />
              )}
            />
          </Flex>
        )}
      </>

      <ServicesModal isOpen={serviceModalOpen} onClose={onCloseServiceModal} selectedService={selectedService} />
      {unityModalOpen && (
        <UnitysModal
          isModalOpen={unityModalOpen}
          onClose={onCloseServiceModal}
          selected={unity}
          setSelected={setUnity}
        />
      )}
    </Layout>
  )
}

ServicesReport.path = '/relatorio-servicos'
ServicesReport.title = 'Relatório - Serviços'
ServicesReport.secured = true
