import React, { useState } from 'react'
import { dataLink, initialLink, validationLink } from './settings'
import { useToast, Flex } from '@chakra-ui/react'
import { Box, Text, Button } from 'components/atoms'
import { FeatureBlockedMessage, Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { BARBERSHOP_LINK } from 'services/api/endpoints'
import { usePremiumBlock } from 'services/hooks'

export const ShareLink = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const [values, getValues] = useState({ params: initialLink, isValid: false })

  const isBlocked = usePremiumBlock()

  async function getMainData() {
    const res = await api
      .get(BARBERSHOP_LINK(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-access-data', getMainData, {
    onSuccess: (value) => {
      if (!isBlocked) {
        getValues({
          params: {
            ...value,
          },
          isValid: false,
        })
      }
    },
  })

  async function changeAccessData(params) {
    const res = await api
      .put(BARBERSHOP_LINK(userData?.barbershop?.id || userData?.id), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const onClickCopy = () => {
    navigator.clipboard.writeText(`https://agendamentos.bestbarbers.app/barbershop/${values?.params?.slug}`)
    toast({
      title: t('COPIED_LINK'),
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  const mutate = useMutation('change-access-data', (params) => changeAccessData(params), {
    onSuccess: (data) => {
      toast({
        title: t('UPDATED_DATA'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClickCopy()
    },
    onError: (error) => {
      toast({
        title: t('ERROR_UPDATE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleFormSubmit = async () => {
    const params = {
      ...values?.params,
    }
    mutate.mutate(params)
  }

  if (isBlocked) {
    return <FeatureBlockedMessage icon="BarbershopLink" title="LINK_BLOCKED" />
  }

  return (
    <Flex flexDirection="column" flex="1" h="100%">
      <Box>
        <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
          {t('BARBERSHOP_LINK')}
        </Text>

        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          {t('LINK_DESC')}
        </Text>

        <Text kind="semiBold" fontSize={pxToRem(16)} marginBottom={pxToRem(24)}>
          {t('LINK_NOTE')}
        </Text>

        <Box mt={pxToRem(32)} mb={pxToRem(32)}>
          <Form
            initialValues={values?.params}
            validationSchema={validationLink}
            data={dataLink}
            getValues={getValues}
          />
        </Box>

        <Text kind="semiBold" fontSize={pxToRem(16)} mt={pxToRem(64)}>
          Preview:
        </Text>
        <Text kind="semiBold" fontSize={pxToRem(14)} mt={pxToRem(12)}>
          {`https://agendamentos.bestbarbers.app/barbershop/${values?.params?.slug || ''}`}
        </Text>

        <Button
          kind="outline-primary"
          disabled={!values?.isValid || mutate.isLoading}
          onClick={handleFormSubmit}
          isLoading={mutate.isLoading}
          mt={pxToRem(32)}
          size="block"
        >
          {t('Salvar alteração e Copiar Link')}
        </Button>
      </Box>
    </Flex>
  )
}
