import React from 'react'
import PropTypes from 'prop-types'
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  InputRightAddon,
  Input,
  useTheme,
  Box,
  Flex,
} from '@chakra-ui/react'
import MaskedInput from 'react-text-mask'
import InputMask from 'react-input-mask'
import { t } from 'services/translation'

import { Text } from '../Text'
import * as masks from 'utils/input-masks'
export const TextInput = React.forwardRef(
  (
    {
      noBorder,
      error,
      required,
      leftElement,
      leftAddon,
      rightElement,
      buttonElement,
      rightAddon,
      containerprops,
      backgroundColor,
      label,
      mt,
      mb,
      mask,
      w,
      maxLength,
      value,
      focusBorderColor,
      type,
      ...props
    },
    ref
  ) => {
    const theme = useTheme()

    const DefaultInputProps = {
      errorBorderColor: 'danger',
      backgroundColor: 'backgroundLayout',
      borderWidth: 1,
      borderColor: 'modalBorder',
      _placeholder: {
        color: theme.colors.agendaIntervalBackground,
        fontFamily: 'Montserrat SemiBold',
        fontSize: 14,
      },
      fontFamily: 'Montserrat SemiBold',
      fontSize: 16,
      maxLength: 150,
      minHeight: 42,
      color: theme.colors.textLight,
    }

    return (
      <Box
        w={w || '100%'}
        mt={mt}
        h={theme.pxToRem(56)}
        {...(containerprops || {})}
        mb={error && typeof error === 'string' && 12}
      >
        {label && (
          <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(6)}>
            {t(label)}
          </Text>
        )}
        <InputGroup
          borderBottomWidth={noBorder ? 0 : 1}
          borderBottomColor={!error ? 'transparent' : theme.colors.danger}
        >
          {leftElement &&
            (leftAddon ? (
              <InputLeftAddon
                padding={0}
                margin={0}
                bg="transparent"
                borderColor="transparent"
                color="textLight"
                children={leftElement}
              />
            ) : (
              <InputLeftElement
                height="100%"
                alignItems="center"
                justifyContent="center"
                pointerEvents="none"
                ml={theme.pxToRem(8)}
                children={leftElement}
              />
            ))}

          <Input
            type={type}
            as={type === 'inputMask' ? InputMask : mask ? MaskedInput : Input}
            mask={masks[mask] ? masks[mask] : mask}
            focusBorderColor={focusBorderColor || 'primary'}
            {...DefaultInputProps}
            backgroundColor={backgroundColor || 'backgroundLayout'}
            isInvalid={!!error}
            isReadOnly={props?.isReadOnly}
            paddingLeft={leftElement && !leftAddon ? theme.pxToRem(48) : theme.pxToRem(16)}
            paddingRight={rightElement && !rightAddon ? theme.pxToRem(36) : theme.pxToRem(10)}
            py={theme.pxToRem(26)}
            ref={ref}
            maxLength={maxLength}
            value={mask === 'CURRENCY_MASK' ? masks.formatCurrencyToMask(value) : value}
            onWheel={(e) => e.target.blur()}
            {...props}
          />

          {rightElement &&
            (rightAddon ? (
              <InputRightAddon my="8px" children={rightElement} />
            ) : buttonElement ? (
              <InputRightElement height="100%" pointerEvents="all">
                <Flex onClick={buttonElement}>{rightElement}</Flex>
              </InputRightElement>
            ) : (
              <InputRightElement height="100%" pointerEvents="none" children={rightElement} />
            ))}
        </InputGroup>

        {error && typeof error === 'string' && (
          <Text mt={1} fontSize={theme.pxToRem(12)} color="red">
            {error}
          </Text>
        )}
      </Box>
    )
  }
)

TextInput.propTypes = {
  ...Input.PropTypes,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,
  leftElement: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  leftAddon: PropTypes.bool,
  rightElement: PropTypes.func,
  rightAddon: PropTypes.bool,
  containerprops: PropTypes.object,
  label: PropTypes.string,
  mask: PropTypes.oneOf(['PHONE_MASK', 'CURRENCY_MASK', 'CNPJ_MASK', 'CPF_MASK', 'CEP_MASK', 'PERCENTAGE_MASK']),
}
