import React, { useEffect, useState, useMemo } from 'react'
import { parseISO, format } from 'date-fns'
import { t } from 'services/translation'
import {
  Layout,
  FinanceResumeCard,
  Table,
  Text,
  PieChart,
  Button,
  SelectDateButton,
  SelectReleaseTypeModal,
  CashierReleaseModal,
  NewSingleSaleModal,
  Icon,
  ServiceDetailsCard,
  Link,
  Box,
  AppointmentDetailsModal,
  CashierUpdateStatusModal,
  Avatar,
  SingleProductModal,
  FeatureBlockedMessage,
  SelectDateModal,
  WorthBarberModal,
} from 'components'
import { Center, Flex, Spinner, useTheme, useToast } from '@chakra-ui/react'
import { usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import './hideScrollbar.css'
import { settingsCards, entryColumns, appointmentsColumns, releasesColumns, Dot, exportOptions } from './settings'
import { useBarbershop } from 'services/hooks'
import { useMutation, useQuery } from 'react-query'
import { formatCurrency } from 'utils/formatCurrency'
import { pxToRem } from 'styles/metrics'
import { usePermissionCollaborator } from 'services/hooks'
import { useAppointmentDetailStore } from 'services/stores'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { SelectOptionsModal } from 'components-v2/molecules/OptionsModal'

const SectionHeader = ({ onClick, isOpen, title }) => {
  return (
    <Flex onClick={onClick} cursor={'pointer'} align={'center'} mb={pxToRem(16)} mt={pxToRem(16)}>
      <Text fontSize={18} kind="bold">
        {title}
      </Text>
      <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} color={'white'} size={18} ml={pxToRem(4)} />
    </Flex>
  )
}

const UserCard = ({ onClick, selected, setType, setEnabled, loadingExcel, ...props }) => {
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])
  return (
    <Flex
      flexDir="column"
      bg="component"
      minW={pxToRem(200)}
      p={pxToRem(12)}
      mr={pxToRem(12)}
      borderRadius={pxToRem(8)}
      borderColor={selected === null || selected === props?.user?.user_id ? 'primary' : 'transparent'}
      borderWidth={1}
    >
      <Flex onClick={onClick} cursor={'pointer'} flexDir="column">
        <Flex mb={pxToRem(4)}>
          <Avatar type="barber" size="medium" src={props?.user?.profile_image_url} />
          <Box ml={pxToRem(12)}>
            <Text fontSize={pxToRem(14)} kind="bold">
              {props?.user?.name}
            </Text>

            <Text fontSize={pxToRem(12)} kind="regular">
              {props?.status !== 'Avulso' ? `${props?.quantity} comandas fechadas` : t(props?.user?.type)}
            </Text>
          </Box>
          <Button
            size="x-small"
            kind={props?.status === 'Avulso' ? 'grey' : !props?.transaction?.close_date ? 'success' : 'danger'}
            ml={pxToRem(18)}
            w="64px"
            cursor="none"
          >
            {props?.status === 'Avulso' ? 'Avulso' : !props?.transaction?.close_date ? 'Aberto' : 'Fechado'}
          </Button>
        </Flex>
        {props?.status !== 'Avulso' ? (
          <>
            <Text fontSize={pxToRem(12)} kind="regular">
              Caixa aberto às {props?.transaction?.open_hour}
            </Text>
            <Text fontSize={pxToRem(12)} mb={pxToRem(6)} kind="semiBold">
              Valor inicial do caixa: {formatCurrency(props?.cashier_initial_value || 0)}
            </Text>
            {props?.transaction?.close_date && (
              <Text fontSize={pxToRem(12)} kind="regular">
                Caixa fechado às {props?.transaction?.close_hour}
              </Text>
            )}
            <Text fontSize={pxToRem(12)} kind="semiBold">
              Total de vendas: {formatCurrency(props?.total_value || 0)}
            </Text>
            <Text fontSize={pxToRem(12)} mb={pxToRem(6)} kind="semiBold">
              Vendas em dinheiro: {formatCurrency(props?.din_sales_value || 0)}
            </Text>
            <Text fontSize={pxToRem(12)} kind="regular">
              Valor em dinheiro no caixa:
            </Text>
            <Text fontSize={pxToRem(16)} kind="bold">
              {formatCurrency(props?.din_value || 0)}
            </Text>
          </>
        ) : (
          <>
            <Text mt="4px" fontSize={pxToRem(12)} kind="medium">
              Nº de comandas fechadas:
            </Text>
            <Text fontSize={pxToRem(16)} kind="bold">
              {props?.quantity}
            </Text>
            <Text mt="4px" fontSize={pxToRem(12)} kind="regular">
              Valores pagos em dinheiro:
            </Text>
            <Text fontSize={pxToRem(14)} kind="bold">
              {formatCurrency(props?.din_value || 0)}
            </Text>
            <Text mt="4px" fontSize={pxToRem(12)} kind="medium">
              Total das comandas fechadas:
            </Text>
            <Text fontSize={pxToRem(16)} kind="bold">
              {formatCurrency(props?.total_value || 0)}
            </Text>
          </>
        )}
      </Flex>
      <Button
        size="x-small-auto"
        kind="primary"
        mt="12px"
        onClick={() => {
          setType(props?.user?.user_id)
          setEnabled(props?.user?.user_id)
        }}
        isLoading={loadingExcel}
      >
        Exportar para excel
      </Button>
    </Flex>
  )
}

export const CashierScreen = ({ ...props }) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()
  const appointment_permission = usePermissionCollaborator('appointments')
  const single_product_permission = usePermissionCollaborator('single_product')
  const finance_permission = usePermissionCollaborator('finance')
  const cashier_permission = usePermissionCollaborator('cashier')
  const { setSelectedAppointment } = useAppointmentDetailStore()
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedSingleSale, setSelectedSingleSale] = useState(null)
  const [selectedOperator, setSelectedOperator] = useState(null)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [worthModalOpen, setWorthModalOpen] = useState(false)
  const [worth, setWorth] = useState(null)
  const [type, setType] = useState(null)
  const [appointment, setAppointment] = useState(null)

  const [modalOpen, setModalOpen] = useState(null)
  const [sections, setSections] = useState({
    payment_method_limit: 4,
    resume: true,
    operators: true,
    entriesDetails: false,
    transactions: true,
    appointments: true,
    releases: true,
    exportType: true,
  })

  const { getCashierTransactions, exportCashierReport } = useBarbershop()

  const barbershop_id = useMemo(() => {
    return userData?.barbershop?.id || userData?.barbershop_id || userData?.id
  }, [userData])

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-cashier-transactions'],
    () => getCashierTransactions(barbershop_id, format(selectedDate, 'yyyy-MM-dd'), type),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { isLoading: loadingExcel, mutate } = useMutation(
    ['get-cashier-excel'],
    (type) =>
      exportCashierReport({
        barbershop_id,
        params: {
          date: format(selectedDate, 'yyyy-MM-dd'),
          export: 'export',
          type: type,
        },
      }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
      },
    }
  )

  const onSelectRow = (item) => {
    setAppointment(item)
    if (item?.subscription_id) {
      return
    }
    if (item?.type === 'worth' || (item?.type === 'revenue' && item?.description && item?.barber)) {
      setWorth(item)
      setWorthModalOpen(true)
      return
    }
    if (appointment_permission === 'blocked' && single_product_permission === 'blocked') {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    if (item?.type === 'singleProduct' && single_product_permission === 'blocked') {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    if (item?.type === 'singleProduct' || item?.type === 'singleProductBarber') {
      setSelectedSingleSale(item)
      setModalOpen({ singleSaleDetails: true })
      return
    }

    if ((item?.type === 'expense' || item?.type === 'revenue') && finance_permission === 'blocked') {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }

    if (item?.type === 'expense' || item?.type === 'revenue') {
      setSelectedItem(item)
      setModalOpen({ chashierRelease: true })
      return
    }

    if (appointment_permission === 'blocked') {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }

    if (item)
      // setSelectedAppointment(item)
      setModalOpen({ appointment: true })
  }

  const resume = useMemo(() => {
    if (data) {
      const arr1 = data?.current_cashier?.payment_resume?.data || []
      const arr2 = data?.info || []
      return {
        initial: {
          title: 'Valor inicial do Caixa',
          value: data?.current_cashier?.cashier_initial_value || 0,
        },
        data: [...arr1, ...arr2],
        final: [
          { title: 'Total de Entradas', value: data?.total_entries || 0 },
          { title: 'Total de Saídas', value: data?.expenses_value || 0 },
          {
            title: 'Valor final disponível no caixa',
            value: data?.cashier_din_value || 0,
          },
        ],
      }
    }
    return {}
  }, [data])
  const isCashierOpen = useMemo(() => {
    if (data) {
      return data?.current_cashier?.status === 'open'
    }
    return false
  }, [data])

  useEffect(() => {
    if (modalOpen === false) {
      refetch()
    }
  }, [modalOpen])

  useEffect(() => {
    refetch()
  }, [selectedDate])

  if (isLoading || isRefetching) {
    return (
      <Layout isPremiumScreen headerTitle={t('Caixa Financeiro')}>
        <Center w="100%">
          <Spinner color="textLight" />
        </Center>
      </Layout>
    )
  }

  const dataPie = () => {
    const array_data = data?.payment_method_info?.data?.sort((a, b) => b?.quantity - a?.quantity)
    let label = array_data?.map((item) => item?.payment_method?.payment_method_choosed?.name)
    let d = array_data?.map((item) => item?.quantity)
    const values = {
      label,
      data: d,
    }
    return values
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      withoutHeader
      contentPosition={isBlocked && 'relative'}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      px={100}
      pb={100}
    >
      {isBlocked && <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="CASHIER" />}
      {/* HEADER */}
      <Flex align="center" flexDir="row" w="100%" justifyContent="space-between">
        <Flex>
          <Text fontSize={theme.pxToRem(24)} kind="bold">
            CAIXA
          </Text>
          {/* CASHIER STATUS */}
          <Button
            icon="Point"
            size="x-small"
            iconColor={isCashierOpen ? 'positive' : 'dangerDark'}
            kind={isCashierOpen ? 'success' : 'danger'}
            ml={theme.pxToRem(12)}
            w={24}
            cursor="none"
          >
            {isCashierOpen ? 'Aberto' : 'Fechado'}
          </Button>
        </Flex>
        {/* DATE FILTER */}
        <SelectDateButton date={selectedDate} setDate={setSelectedDate} onClickCenter={() => setDateModalOpen(true)} />
        {/* Adicionar vale ao barbeiro */}
        {cashier_permission === 'edit' && (
          <Flex>
            <Button
              icon="Excel"
              size="x-small"
              kind="primary"
              iconColor="black"
              mr={theme.pxToRem(12)}
              onClick={() => {
                setType('general')
                mutate('general')
              }}
              isLoading={loadingExcel}
            >
              Exp. para excel (geral)
            </Button>
            {/* <Button
              size="x-small"
              kind="primary"
              mr={theme.pxToRem(12)}
              onClick={() => {
                setWorthModalOpen(true)
              }}
            >
              Adicionar vale ao barbeiro
            </Button> */}
            <Button
              size="x-small-auto"
              kind="primary"
              mr={theme.pxToRem(12)}
              onClick={(item) => {
                setWorth(null)
                setWorthModalOpen(true)
              }}
            >
              Adicionar lançamento para barbeiro
            </Button>
            {/* CHANGE CASHIER STATUS BUTTON */}
            <Button
              icon={isCashierOpen ? 'CloseSlim' : 'Money'}
              size="x-small"
              iconColor="white"
              kind={isCashierOpen ? 'danger' : 'success'}
              onClick={() => {
                setModalOpen({ update_status: true })
              }}
            >
              {isCashierOpen ? 'Fechar caixa' : 'Abrir caixa'}
            </Button>
            {/* ADD RELEASE BUTTON*/}
            {isCashierOpen && (
              <Button
                icon="Plus"
                size="x-small"
                iconColor="black"
                onClick={() => setModalOpen({ releaseType: true })}
                ml={theme.pxToRem(12)}
              >
                Novo lançamento
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      {/* RESUME CARDS */}
      <SectionHeader
        isOpen={sections?.resume}
        onClick={() => setSections({ ...sections, resume: !sections?.resume })}
        title={`Resumo - ${format(selectedDate, 'dd/MM/yyyy')}`}
      />
      {sections?.resume && (
        <Flex
          w="100%"
          flexDir="row"
          alignItems="stretch"
          justifyContent="space-between"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(24)}
        >
          {/* BALANCE - ENTRIES - EXPENSES - SERVICES - PRODUCTS - REVENUES */}
          {settingsCards?.map((item) => (
            <FinanceResumeCard
              {...item}
              label={t(item?.label)}
              description={t(item?.description)}
              value={data?.[item?.value_key]}
              background={item?.value_key === 'balance' ? (data?.balance >= 0 ? 'success' : 'danger') : null}
              balance={item?.value_key === 'balance' ? (data?.balance >= 0 ? 'successDark' : 'dangerCard') : null}
            />
          ))}
        </Flex>
      )}
      {/* OPERATORS */}
      <SectionHeader
        isOpen={sections?.operators}
        onClick={() => setSections({ ...sections, operators: !sections?.operators })}
        title={`Operadores`}
      />
      {sections?.operators && (
        <Flex>
          {data?.cashier_by_users?.map((item) => (
            <UserCard
              {...item}
              selected={selectedOperator}
              onClick={() => setSelectedOperator(selectedOperator === item?.user.user_id ? null : item?.user.user_id)}
              setEnabled={mutate}
              setType={setType}
              loadingExcel={loadingExcel}
            />
          ))}
        </Flex>
      )}
      {/* ENTRIES DETAILS */}
      <SectionHeader
        isOpen={sections?.entriesDetails}
        onClick={() =>
          setSections({
            ...sections,
            entriesDetails: !sections?.entriesDetails,
          })
        }
        title={'Detalhamento - Entradas, Lançamentos e Saídas'}
      />
      {/* ENTRIES RESUME CONTAINER */}
      {sections?.entriesDetails && (
        <Flex align="flex-start">
          {/* RELEASES RESUME */}
          <Flex w="35%" mr="2%" flexDir="column">
            {data?.info?.map((item, index) => {
              const color = ['success', 'danger', 'primary'][index]
              return (
                <ServiceDetailsCard
                  mb={18}
                  item={{
                    title: item?.title,
                    description: item?.empty || `Lançamentos manuais`,
                    value: formatCurrency(item?.total_value || 0),
                    value_color: color,
                  }}
                  index={index}
                  color={color}
                />
              )
            })}
          </Flex>
          {/* PAYMENT METHODS CHART */}
          {data?.payment_method_info?.data?.length && (
            <PieChart
              title="Formas de pagamento"
              h="100%"
              width="20%"
              data={dataPie()}
              mr="2%"
              w="100%"
              total={formatCurrency(data?.payment_method_info?.data?.reduce((acc, val) => acc + val?.value, 0))}
            />
          )}
          {/* PAYMENT METHODS TABLE */}
          <Box w="40%" align="center">
            <Table
              withoutHeader
              loading={isLoading}
              data={data?.payment_method_info?.data
                ?.slice(0, sections?.payment_method_limit)
                ?.sort((a, b) => b?.value - a?.value)}
              height={200}
              emptyListMessage="Finaliza suas comandas para ver o resumo dos pagamentos recebidos!"
              columns={entryColumns(data?.payment_method_info?.total)}
              // isSelectableRow
              // onSelect={onSelect}
            />
            {data?.payment_method_info?.data?.length > 4 && sections?.payment_method_limit === 4 && (
              <Link onClick={() => setSections({ ...sections, payment_method_limit: 100 })}>Ver todos</Link>
            )}
          </Box>
        </Flex>
      )}
      {/* APPOINTMENTS */}
      <SectionHeader
        isOpen={sections?.appointments}
        onClick={() =>
          setSections({
            ...sections,
            appointments: !sections?.appointments,
          })
        }
        title={'Comandas'}
      />
      {sections?.appointments && (
        <Flex align={'center'} mt={theme.pxToRem(-16)} pl={theme.pxToRem(8)}>
          <Dot bg={'pieChart.0'} />
          <Text mr={theme.pxToRem(24)}>Agendamentos</Text>
          <Dot bg={'pieChart.8'} />
          <Text>Vendas avulsas</Text>
        </Flex>
      )}
      {sections?.appointments && (
        <Table
          loading={isLoading}
          data={data?.appointments
            ?.filter((a) => (selectedOperator ? a?.finished_by_user_id === selectedOperator : a))
            ?.sort((a, b) => {
              const hour_a = a?.start_hour?.substring(0, 5) || format(parseISO(a?.createdAt), 'HH:mm')
              const hour_b = b?.start_hour?.substring(0, 5) || format(parseISO(b?.createdAt), 'HH:mm')
              if (hour_b < hour_a) return -1
              if (hour_b > hour_a) return 1
              return 0
            })}
          columns={appointmentsColumns}
          isSelectableRow
          onSelect={onSelectRow}
        />
      )}
      {/* TRANSACTIONS */}
      <SectionHeader
        isOpen={sections?.releases}
        onClick={() =>
          setSections({
            ...sections,
            releases: !sections?.releases,
          })
        }
        title={'Lançamentos manuais'}
      />
      {sections?.releases && (
        <Table
          loading={isLoading}
          data={data?.releases?.sort((a, b) => {
            const hour_a = a?.start_hour?.substring(0, 5) || format(parseISO(a?.createdAt), 'HH:mm')
            const hour_b = b?.start_hour?.substring(0, 5) || format(parseISO(b?.createdAt), 'HH:mm')
            if (hour_b < hour_a) return -1
            if (hour_b > hour_a) return 1
            return 0
          })}
          columns={releasesColumns}
          // isSelectableRow
          isSelectableRowVariabled={data?.releases?.map((item) => !item?.subscription_id)}
          onSelect={onSelectRow}
        />
      )}
      <Flex h="5vh" />
      {/* MODALS */}

      <SingleProductModal
        isOpen={modalOpen?.singleSaleDetails}
        selectedSale={selectedSingleSale}
        onClose={(close) => {
          setSelectedSingleSale(null)
          setModalOpen(close === false ? false : null)
        }}
      />
      <NewSingleSaleModal
        isOpen={modalOpen?.singleSale}
        onClose={(close) => setModalOpen(close === false ? false : null)}
      />
      <SelectReleaseTypeModal
        onClose={() => setModalOpen(null)}
        isOpen={modalOpen?.releaseType}
        onSelectOption={(option) => setModalOpen(option?.id === 2 ? { singleSale: true } : { chashierRelease: option })}
      />
      <SelectOptionsModal
        title={'oi'}
        description={''}
        onClose={() => setModalOpen(null)}
        isOpen={modalOpen?.exportType}
        onSelectOption={(opt) => {
          setType(opt?.value)
          mutate(opt?.value)
        }}
        options={exportOptions}
      />
      <CashierReleaseModal
        {...modalOpen?.chashierRelease}
        onClose={(close) => setModalOpen(close === false ? false : null)}
        isOpen={modalOpen?.chashierRelease}
        editData={selectedItem}
      />
      <CashierUpdateStatusModal
        resume={resume}
        cashierValue={data?.cashier_din_value || 0}
        isCashierOpen={isCashierOpen}
        date={selectedDate}
        isOpen={modalOpen?.update_status}
        onClose={(close) => setModalOpen(close === false ? false : null)}
        current_cashier={data?.current_cashier}
      />
      <AppointmentDetailsModal
        isModalOpen={modalOpen?.appointment}
        onClose={(close) => setModalOpen(close === false ? false : null)}
        app={appointment}
        setAppointmentId={setAppointment}
      />
      <SelectDateModal
        onClose={() => setDateModalOpen(false)}
        isModalOpen={dateModalOpen}
        selectedDate={selectedDate}
        onSelectDate={(date) => {
          setSelectedDate(date)
          setDateModalOpen(false)
        }}
      />
      {worthModalOpen && (
        <WorthBarberModal
          isModalOpen={worthModalOpen}
          onClose={() => setWorthModalOpen(false)}
          refetch={refetch}
          edit={worth}
        />
      )}
    </Layout>
  )
}

CashierScreen.path = '/caixa'
CashierScreen.title = 'Caixa'
CashierScreen.secured = true
