import React from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, ModalContent, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { benefits } from './settings'
import { Box, Text, Button, Icon } from 'components/atoms'
import { FreeTrialModalBg } from 'assets/img/backgrounds'
import { useBreakpoint } from 'services/hooks'
import { useModalStore } from 'services/stores'

export const FreeTrialModal = ({ isOpen, onClose, ...props }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { setCurrentModal } = useModalStore()

  const Container = isDesktop ? Flex : ModalContent

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay>
        <Container
          borderRadius="sm"
          mt={0}
          h="100vh"
          align="center"
          justify="center"
          bg="transparent"
          pb={theme.pxToRem(isDesktop ? 0 : 800)}
        >
          <Flex
            bgColor="backgroundComponent"
            backgroundImage={`url(${FreeTrialModalBg})`}
            bgRepeat="no-repeat"
            pos={!isDesktop && 'absolute'}
            top={!isDesktop && 0}
            maxW={theme.pxToRem(530)}
            maxH={theme.pxToRem(850)}
            py={theme.pxToRem(20)}
            px={theme.pxToRem(28)}
            h={isDesktop ? '99vh' : 'auto'}
            alignItems="center"
            justifyContent="center"
            flexDir="Column"
            borderRadius="md"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="center"
              justifyContent="center"
            >
              {props?.conclude ? (
                <>
                  <Text
                    align="center"
                    kind="extraBold"
                    color="primary"
                    fontSize={20}
                  >
                    Parabéns!
                  </Text>
                  <Text align="center" kind="extraBold" fontSize={20}>
                    Você ganhou 7 dias de teste grátis!
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    align="center"
                    kind="extraBold"
                    fontSize={20}
                    mr={theme.pxToRem(6)}
                  >
                    {t('FREE_TRIAL_MODAL_TITLE_FIRST_PART_NEW')}
                  </Text>
                  <Text
                    align="center"
                    kind="extraBold"
                    fontSize={22}
                    color="primary"
                  >
                    {t('FREE_TRIAL_MODAL_TITLE_SPAN')}
                  </Text>
                  <Text align="center" kind="extraBold" fontSize={20} mb={'2%'}>
                    {t('FREE_TRIAL_MODAL_TITLE_SECOND_PART')}
                  </Text>
                </>
              )}
            </Flex>
            <Text w="80%" textAlign="center" kind={'medium'} fontSize={12}>
              {t('FREE_TRIAL_MODAL_SUBTITLE')}
            </Text>
            <Box
              p={theme.pxToRem(12)}
              mt={'2%'}
              mb={'1%'}
              w="100%"
              textAlign="center"
              borderWidth={1}
              borderRadius="xl"
              borderColor="agendaIntervalBackground"
            >
              <Text kind="extraBold" fontSize={14}>
                {t('FREE_TRIAL_PERIOD')}
              </Text>
              <Flex w="100%" justifyContent="center">
                <Text kind="semiBold" fontSize={14} mr={theme.pxToRem(6)}>
                  {t('AFTER_FREE_TRIAL')}
                </Text>
                <Text kind="extraBold" fontSize={14} color="primary">
                  {t('AFTER_FREE_TRIAL_SPAN')}
                </Text>
              </Flex>
            </Box>

            <Box w="100%">
              <Text
                kind="extraBold"
                fontSize={18}
                textAlign="center"
                mb={isDesktop ? '3%' : '7%'}
              >
                {t('MEMBERSHIP_BENEFITS')}
              </Text>
              <Flex w="100%" justify="space-between">
                <Flex w="62%" />
                <Flex
                  w="38%"
                  align="center"
                  justify="space-between"
                  mb={theme.pxToRem(8)}
                >
                  <Text
                    kind="bold"
                    fontSize={12}
                    w="40%"
                    textAlign="center"
                    color="grey"
                  >
                    {t('BASIC')}
                  </Text>
                  <Flex
                    flexDir="column"
                    align="center"
                    w="60%"
                    mt={theme.pxToRem(-16)}
                  >
                    <Icon name="Crown" fill="primary" size={16} />
                    <Text
                      kind="bold"
                      fontSize={12}
                      textAlign="center"
                      color="textColorLighter"
                    >
                      Premium
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexDir="column" w="100%">
                {benefits.map((item, index) => (
                  <Flex
                    key={`firstColumn_benefit_${index}`}
                    alignItems="center"
                    borderWidth={1}
                    py={!isDesktop && theme.pxToRem(8)}
                    h={theme.pxToRem(!isDesktop ? 40 : 36)}
                    borderColor="border"
                    borderTopRadius={theme.pxToRem(index === 0 && 8)}
                    borderBottomRadius={theme.pxToRem(index === 8 && 8)}
                    justify="space-between"
                    w="100%"
                  >
                    <Text
                      kind="semiBold"
                      fontSize={12}
                      ml={theme.pxToRem(8)}
                      w="82%"
                    >
                      {t(item.text)}
                    </Text>
                    <Flex w="40%">
                      <Icon
                        w="82%"
                        name={item?.basic ? 'CheckCircle' : 'CloseCircle'}
                        fill={item?.basic ? 'green' : 'danger'}
                        size={16}
                        mt={theme.pxToRem(3)}
                      />
                      <Icon
                        w="60%"
                        name="CheckCircle"
                        fill={item?.premium ? 'green' : 'danger'}
                        size={16}
                        mt={theme.pxToRem(3)}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Box>
            <Button
              kind="primary"
              w="100%"
              // onClick={() => setCurrentModal('paymentMethodChoosedModal')}
              isLoading={props?.isLoading}
              onClick={onClose}
              mt={'10px'}
              h="50px"
            >
              {t(
                props?.conclude
                  ? 'Começar meu teste grátis'
                  : 'QUERO GANHAR 7 DIAS GRÁTIS'
              )}
            </Button>

            <Text mt={'3%'} color="textGrey" fontSize={10}>
              {t('AUTOMATIC_RENOVATION')}
            </Text>
          </Flex>
        </Container>
      </ModalOverlay>
    </Modal>
  )
}
