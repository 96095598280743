import React from 'react'
import { Text } from 'components/atoms'
import { Box, useTheme } from '@chakra-ui/react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts'
import { useTranslation } from 'react-i18next'

export const MonthAppointmentsChart = ({ appointmentsByDate }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="flex-start"
      p={theme.pxToRem(16)}
      w="100%"
      h="100%"
      bg="cardBackground"
      borderRadius={theme.pxToRem(12)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Text kind="extraBold" mb={theme.pxToRem(12)} ml={theme.pxToRem(12)}>
        {t('Total de receita bruta por dia')}
      </Text>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          width={730}
          height={250}
          data={appointmentsByDate}
          margin={{ left: -5 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#70C270" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#70C270" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#E5B817" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#E5B817" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="receita"
            stroke="#70C270"
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={true}
          />
          <Area
            type="monotone"
            dataKey="agendamentos"
            stroke="#E5B817"
            fillOpacity={1}
            fill="url(#colorPv)"
            dot={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  )
}
