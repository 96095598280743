import {
  format,
  parse,
  formatDistance as formatDistanceFns,
  parseISO,
} from 'date-fns'
import { enUS, ptBR } from 'date-fns/locale'

export const formatDate = (date, dateFormat = `dd/MM/yyyy' ás 'HH:mm`) =>
  !date ? null : format(date, dateFormat)

export const parseDate = (date, dateFormat = 'dd/MM/yyyy') =>
  !date ? null : parse(date, dateFormat, new Date())

const locales = {
  enUS,
  ptBR,
}

// use case: when date is formatted as dd/MM/yyyy and you want to send it as yyyy-MM-dd
export const formatPayloadDate = (date) => {
  if (!date) {
    console.log('empty date')
    return null
  }

  const [day, month, year] = date.split('/')

  return `${year}-${month}-${day}`
}

export const formatDistance = (date, locale = 'ptBR') =>
  date ? formatDistanceFns(new Date(), date, { locale: locales[locale] }) : null

export const daysOfWeek = [
  {
    day: 1,
    label: 'MONDAY',
    desc: 'MONDAY_DESC_DATE',
    start_hour: '08:00',
    close_hour: '19:00',
    is_closed: false,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
  {
    day: 2,
    label: 'TUESDAY',
    desc: 'TUESDAY_DESC_DATE',
    start_hour: '08:00',
    close_hour: '19:00',
    is_closed: false,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
  {
    day: 3,
    label: 'WEDNESDAY',
    desc: 'WEDNESDAY_DESC_DATE',
    start_hour: '08:00',
    close_hour: '19:00',
    is_closed: false,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
  {
    day: 4,
    label: 'THURSDAY',
    desc: 'THURSDAY_DESC_DATE',
    start_hour: '08:00',
    close_hour: '19:00',
    is_closed: false,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
  {
    day: 5,
    label: 'FRIDAY',
    desc: 'FRIDAY_DESC_DATE',
    start_hour: '08:00',
    close_hour: '19:00',
    is_closed: false,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
  {
    day: 6,
    label: 'SATURDAY',
    desc: 'SATURDAY_DESC_DATE',
    start_hour: '09:00',
    close_hour: '17:00',
    is_closed: false,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
  {
    day: 7,
    label: 'SUNDAY',
    desc: 'SUNDAY_DESC_DATE',
    start_hour: '08:00',
    close_hour: '19:00',
    is_closed: true,
    timeWindow: {
      open: 0,
      close: 24 * 60,
    },
  },
]

export const createDateComplete = (simpleDate, simpleHour) => {
  return parseISO(`${simpleDate} ${simpleHour}`)
}

export const formatToSimpleDate = (date) => {
  return format(parseISO(date), 'dd/MM')
}

export const formatToSimpleHour = (minutes) => {
  const hour = Math.floor(minutes / 60)
  const minute = minutes % 60
  return format(new Date(2023, 1, 1, hour, minute), 'HH:mm')
}

export const unformatToSimpleHour = (minutes) => {
  const split = minutes.split(':')
  const hour = Number(split[0]) * 60
  const formattedMinutes = Number(split[1])
  return hour + formattedMinutes
}

export const formatBlockedDate = (item) => {
  if (item?.repeat_every_day) {
    return 'EVERYDAY'
  }

  if (item?.repeat_every_week_day > -1) {
    const day = daysOfWeek.find((d) => d.day === item?.repeat_every_week_day)
    return day?.desc
  }

  return format(parseISO(item?.date), "dd 'de' MMMM 'de' yyyy", {
    locale: ptBR,
  })
}
