import { api } from 'services/api/config'

export const useBarber = () => {
  const getOnlyBarbers = async (props) => {
    const response = await api({
      url: `v3/barbershop/team/only-barbers/${props?.id}`,
    })
    return response.data
  }

  const getBarberComissionResume = async (props) => {
    const response = await api.post(`v3/barbershop/finances/barber-resume`, props?.body)
    return response.data
  }

  const updateBarberOrder = async (props) => {
    const response = await api.put(`v3/barbershop/team/order-barbers`, props?.body)
    return response.data
  }

  return {
    updateBarberOrder,
    getBarberComissionResume,
    getOnlyBarbers,
  }
}
