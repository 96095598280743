import React, { useState } from 'react'
import { ModalMenu, AdditionalProductsModal, PaymentMethodModal, IssueInvoiceCustomForm, Button } from 'components'
import { DetailsOptions } from './DetailsOptions'
import { AdditionalProducts } from './AdditionalProducts'
import { PaymentMethod } from './PaymentMethod'
import { Discount } from './Discount'
import { Service } from './Service'
import { AppointmentSelectSource } from './Source'
import { APPOINTMENT_DETAILS_STEPS } from './settings'
import { useAppointmentDetailStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { pxToRem } from 'styles/metrics'
import { ModalButtonBackground } from 'components-v2/atoms'
import { additionalProductsStore } from 'services/stores/additionalProducts'

export const AppointmentDetailsModal = ({ isModalOpen, onClose, selectedDate, app, setAppointmentId }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { setPaymentMethod, setDiscount, setSelectedAppointment } = useAppointmentDetailStore()
  const { setAdditionalProductsAppointmentList } = additionalProductsStore()

  const [schedulingStep, setSchedulingStep] = useState(APPOINTMENT_DETAILS_STEPS.initialStep)
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [paymentMethodModal, setPaymentMethodModal] = useState(false)
  const [enabled, setEnabled] = useState(true)

  const handleBackNavigation = () => {
    setSchedulingStep(APPOINTMENT_DETAILS_STEPS.initialStep)
  }

  const handleClose = (e) => {
    onClose(e)
    setPaymentMethod([])
    setDiscount(null)
    setAppointmentId(null)
    setSelectedAppointment(null)
    setAdditionalProductsAppointmentList([])
    queryClient.removeQueries(['get-services'])
    queryClient.removeQueries(['get-services-plans'])
  }

  const onCloseServiceModal = () => {
    setProductModalOpen(false)
    queryClient.invalidateQueries('get-additional-products')
  }

  const onClosePaymentMethodModal = () => {
    setPaymentMethodModal(false)
    queryClient.invalidateQueries('get-payment-methods')
  }

  const displayAppointmentSteps = () => {
    switch (schedulingStep) {
      case APPOINTMENT_DETAILS_STEPS.initialStep:
        return (
          <DetailsOptions
            setStep={setSchedulingStep}
            onClose={handleClose}
            selectedDate={selectedDate}
            app={app}
            setAppointment={setAppointmentId}
            enabled={enabled}
            setEnabled={setEnabled}
          />
        )
      case APPOINTMENT_DETAILS_STEPS.addSource:
        return <AppointmentSelectSource goBack={handleBackNavigation} setEnabled={setEnabled} />
      case APPOINTMENT_DETAILS_STEPS.addProducts:
        return <AdditionalProducts goBack={handleBackNavigation} setEnabled={setEnabled} />
      case APPOINTMENT_DETAILS_STEPS.selectPaymentMethod:
        return <PaymentMethod goBack={handleBackNavigation} setEnabled={setEnabled} />
      case APPOINTMENT_DETAILS_STEPS.discount:
        return <Discount goBack={handleBackNavigation} setEnabled={setEnabled} />
      case APPOINTMENT_DETAILS_STEPS.service:
        return <Service goBack={handleBackNavigation} setEnabled={setEnabled} app={app} />
      case APPOINTMENT_DETAILS_STEPS.editInvoice:
        return <IssueInvoiceCustomForm mb={-14} />
      default:
        return <DetailsOptions app={app} setAppointment={setAppointmentId} enabled={enabled} setEnabled={setEnabled} />
    }
  }

  return (
    <ModalMenu
      headerStyle={{ mb: pxToRem(-12) }}
      isOpen={isModalOpen}
      onClose={handleClose}
      onAdd={
        schedulingStep === APPOINTMENT_DETAILS_STEPS.addProducts
          ? () => setProductModalOpen(true)
          : schedulingStep === APPOINTMENT_DETAILS_STEPS.selectPaymentMethod
          ? () => setPaymentMethodModal(true)
          : null
      }
      title={t(schedulingStep)}
      isNavigating={schedulingStep !== APPOINTMENT_DETAILS_STEPS.initialStep}
      handleBackNavigation={handleBackNavigation}
      size="lg"
    >
      {displayAppointmentSteps()}

      {(schedulingStep === APPOINTMENT_DETAILS_STEPS.selectService ||
        schedulingStep === APPOINTMENT_DETAILS_STEPS.addProducts) && (
        <ModalButtonBackground>
          <Button size="block" onClick={handleBackNavigation}>
            {t('CONFIRM')}
          </Button>
        </ModalButtonBackground>
      )}

      <AdditionalProductsModal isModalOpen={productModalOpen} onClose={onCloseServiceModal} />

      <PaymentMethodModal isModalOpen={paymentMethodModal} onClose={onClosePaymentMethodModal} />
    </ModalMenu>
  )
}
