import * as Yup from 'yup'
import { t } from 'services/translation'
import { Telephone, Shop } from 'assets/icons'
import { colors } from 'styles'
import { pxToRem } from 'styles/metrics'

export const inputs = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    noBorder: true,
    label: 'UNITY_NAME',
    placeholder: t('UNITY_NAME'),
    leftElement: <Shop color={colors?.primary} />,
  },
  {
    id: 'phone',
    name: 'phone',
    type: 'text',
    noBorder: true,
    label: 'PHONE',
    mask: 'PHONE_MASK',
    placeholder: t('PHONE_PLACEHOLDER'),
    leftElement: <Telephone width={pxToRem(16)} color={colors?.primary} />,
  },
]

export const validation = Yup.object().shape({
  name: Yup.string().min(4, t('NAME')).required(t('NAME_REQUIRED')),
  phone: Yup.string(),
})

export const initial = {
  name: '',
  phone: '',
}
