import { useMemo } from 'react'
import { useBarbershopStore } from 'services/stores'

export const useUserType = () => {
  const { user } = useBarbershopStore()
  return useMemo(() => {
    if (
      user?.type === 'barbershopAndBarber' ||
      user?.type === 'barbershop' ||
      user?.type === 'ownerAndBarber' ||
      user?.type === 'owner' ||
      user?.type === 'internal'
    ) {
      return 'barbershop'
    }
    return 'collaborator'
  }, [])
}
