import React from 'react'
import { useTheme } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Box, Flex, Text, Icon } from 'components/atoms'

export const BarbershopRating = ({ data }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const barbershopRating = React.useMemo(() => {
    const total = data?.reduce(
      (accumulated, current) => accumulated + current?.rate,
      0
    )
    return total / data?.length
  }, [data])

  return (
    <Box
      width="full"
      height="fit-content"
      padding={theme.pxToRem(16)}
      marginBottom={theme.pxToRem(16)}
      bg="menuBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Box marginBottom={theme.pxToRem(18)}>
        <Flex alignItems="center">
          <Text kind="extraBold" fontSize={theme.pxToRem(24)}>
            {barbershopRating?.toFixed(2) || 0}
          </Text>

          <Flex paddingX={theme.pxToRem(8)}>
            <Icon
              name="Star"
              fill={barbershopRating >= 1 && 'primary'}
              size={22}
            />
            <Icon
              name="Star"
              fill={barbershopRating >= 2 && 'primary'}
              size={22}
            />
            <Icon
              name="Star"
              fill={barbershopRating >= 3 && 'primary'}
              size={22}
            />
            <Icon
              name="Star"
              fill={barbershopRating >= 4 && 'primary'}
              size={22}
            />
            <Icon
              name="Star"
              fill={barbershopRating === 5 && 'primary'}
              size={22}
            />
          </Flex>
        </Flex>

        <Text kind="medium" fontSize={theme.pxToRem(14)} fontColor="textGrey">
          {t('AVERAGE_BARBERSHOP_RATING')}
        </Text>
      </Box>

      {/* <Box marginBottom={theme.pxToRem(18)}>
        <Text fontSize={theme.pxToRem(26)} fontWeight={900}>
          8%
        </Text>

        <Text fontColor="textLight" fontWeight={500} opacity={0.9} fontSize={theme.pxToRem(14)}>
          {t('BASED_IN_30_DAYS')}
        </Text>
      </Box> */}

      <Box>
        <Flex alignItems="center">
          <Text
            kind="extraBold"
            fontSize={theme.pxToRem(24)}
            marginRight={theme.pxToRem(8)}
          >
            {data?.length}
          </Text>

          <Icon name="Person" size={18} />
        </Flex>

        <Text kind="medium" fontSize={theme.pxToRem(14)} fontColor="textGrey">
          {t('RECEIVED_RATINGS_TOTAL')}
        </Text>
      </Box>
    </Box>
  )
}
