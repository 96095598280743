export const settings = (monthReport) => {
  return [
    {
      label: 'TOTAL',
      description: 'SALES_PRODUCT',
      color: 'success',
      notCurrency: false,
      value: monthReport?.appointments_total_products || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a produtos',
    },
    {
      label: 'TOTAL',
      description: 'SALES_SERVICE',
      color: 'success',
      notCurrency: false,
      value: monthReport?.appointments_total_services || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a serviços',
    },
  ]
}

export const settingsMobile = (monthReport) => {
  return [
    {
      label: 'TOTAL',
      description: 'SALES_PRODUCT',
      color: 'success',
      notCurrency: false,
      value: monthReport?.appointments_total_products || 0,
    },
    {
      label: 'CLIENTS',
      description: 'TOTAL',
      color: 'white',
      notCurrency: true,
      value: monthReport?.total_clients || 0,
    },
    {
      label: 'TOTAL',
      description: 'SALES_SERVICE',
      color: 'success',
      notCurrency: false,
      value: monthReport?.appointments_total_services || 0,
      last: true,
    },
    {
      label: 'NEW_CLIENTS',
      description: 'TOTAL',
      color: 'white',
      notCurrency: true,
      value: monthReport?.new_clients || 0,
      last: true,
    },
  ]
}
