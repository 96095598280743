import React from 'react'
import * as Ramda from 'ramda'
import { api } from 'services/api/config'

function isObject(obj) {
  return obj === Object(obj)
}

const handleError = (error) => {
  if (error.response?.data?.error && isObject(error?.response?.data?.error)) {
    return {
      error:
        'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
    }
  }

  if (error?.response?.data?.error) {
    return {
      error: error.response.data.error,
    }
  }
  return {
    error: 'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
  }
}

const initialState = {
  data: null,
  loading: false,
}

const reducer = (state = initialState, action) => {
  if (action.type === 'add_data') {
    return { ...state, data: action.data, loading: false }
  }
  if (action.type === 'change_loading') {
    return { ...state, loading: action.loading }
  }
  return state
}

const onFetch = async ({
  url,
  method,
  params,
  dispatch,
  onCompleted,
  onError,
}) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: 'change_loading', loading: true })
    try {
      console.log('url ->', `${url} [${method || 'get'}]`, 'params ->', params)
      const { data } = await api[method || 'get'](url, params || {})
      dispatch({ type: 'add_data', data })
      !!onCompleted && onCompleted(data)
      resolve(data)
    } catch (error) {
      console.warn('[fetch error] ', error)
      dispatch({ type: 'change_loading', loading: false })
      const message = handleError(error)
      !!onError && onError(message)
      reject(message)
    }
  })

export const useLazyFetch = (url, props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const handleFetch = (newProps) =>
    new Promise(async (resolve, reject) => {
      try {
        const result = await onFetch({
          url,
          ...{ ...props, ...(newProps || {}) },
          dispatch,
        })
        resolve(result)
      } catch (error) {
        reject(handleError(error))
      }
    })

  return [
    handleFetch,
    { refetch: handleFetch, ...Ramda.pick(['loading', 'data'], state) },
  ]
}

export const useFetch = (url, props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const handleFetch = (newProps) =>
    new Promise(async (resolve, reject) => {
      try {
        const result = await onFetch({
          url,
          ...{ ...props, ...(newProps || {}) },
          dispatch,
        })
        resolve(result)
      } catch (error) {
        reject(handleError(error))
      }
    })

  React.useEffect(() => {
    if (url) handleFetch()
  }, [props?.params])

  return { refetch: handleFetch, ...Ramda.pick(['loading', 'data'], state) }
}

export const useSimpleFetch = async (url, props) => {
  try {
    console.log(
      'url ->',
      `${url} [${props ? props.method : 'get'}]`,
      'params ->',
      props ? props.params : {}
    )
    const { data } = await api[props ? props.method : 'get'](
      url,
      props ? props.params : {}
    )
    return data
  } catch (error) {
    console.warn('[fetch error] ', error)
    return handleError(error)
  }
}
