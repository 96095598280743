import React from 'react'

const Crown = (props) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2703 7.32013L12.929 7.5466C12.3132 7.95433 11.5685 8.09483 10.8544 7.93797C10.1403 7.78112 9.51368 7.33939 9.10888 6.7075L8.37128 5.55579L7.63368 6.7075C7.22889 7.33939 6.60227 7.78112 5.88817 7.93797C5.17406 8.09483 4.42932 7.95433 3.8136 7.5466L3.47224 7.32013L4.22472 10.2933H12.5178L13.2703 7.32013ZM2.46862 4.36054C1.75427 3.88824 0.853896 4.57249 1.06969 5.42417L2.60814 11.5069C2.6603 11.7136 2.77673 11.8965 2.93926 12.0271C3.10179 12.1577 3.3013 12.2286 3.50665 12.2289H13.2341C13.4394 12.2289 13.6391 12.1582 13.8018 12.0278C13.9645 11.8973 14.0811 11.7145 14.1335 11.5079L15.672 5.42417C15.8877 4.57249 14.9874 3.88824 14.273 4.36054L11.9291 5.91292C11.7238 6.04883 11.4756 6.09566 11.2375 6.04338C10.9995 5.99109 10.7906 5.84385 10.6557 5.63322L9.14423 3.27465C9.05929 3.1421 8.94421 3.03341 8.80921 2.95825C8.67421 2.88308 8.52347 2.84375 8.37035 2.84375C8.21724 2.84375 8.0665 2.88308 7.9315 2.95825C7.7965 3.03341 7.68142 3.1421 7.59648 3.27465L6.08593 5.63322C5.95114 5.84401 5.74234 5.99145 5.50429 6.04392C5.26625 6.09639 5.01792 6.04971 4.81257 5.91389L2.46862 4.36054Z"
      />
      <path d="M9.3014 1.09868C9.3014 1.35536 9.2034 1.60153 9.02897 1.78303C8.85453 1.96453 8.61795 2.0665 8.37126 2.0665C8.12457 2.0665 7.88799 1.96453 7.71355 1.78303C7.53911 1.60153 7.44112 1.35536 7.44112 1.09868C7.44112 0.841997 7.53911 0.595828 7.71355 0.414327C7.88799 0.232826 8.12457 0.130859 8.37126 0.130859C8.61795 0.130859 8.85453 0.232826 9.02897 0.414327C9.2034 0.595828 9.3014 0.841997 9.3014 1.09868ZM16.7425 3.03432C16.7425 3.291 16.6445 3.53717 16.4701 3.71867C16.2957 3.90017 16.0591 4.00213 15.8124 4.00213C15.5657 4.00213 15.3291 3.90017 15.1547 3.71867C14.9802 3.53717 14.8822 3.291 14.8822 3.03432C14.8822 2.77763 14.9802 2.53147 15.1547 2.34996C15.3291 2.16846 15.5657 2.0665 15.8124 2.0665C16.0591 2.0665 16.2957 2.16846 16.4701 2.34996C16.6445 2.53147 16.7425 2.77763 16.7425 3.03432ZM1.86028 3.03432C1.86028 3.291 1.76228 3.53717 1.58785 3.71867C1.41341 3.90017 1.17683 4.00213 0.93014 4.00213C0.683451 4.00213 0.446867 3.90017 0.272432 3.71867C0.0979965 3.53717 0 3.291 0 3.03432C0 2.77763 0.0979965 2.53147 0.272432 2.34996C0.446867 2.16846 0.683451 2.0665 0.93014 2.0665C1.17683 2.0665 1.41341 2.16846 1.58785 2.34996C1.76228 2.53147 1.86028 2.77763 1.86028 3.03432Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.25537 13.4385C3.25537 13.1818 3.35337 12.9357 3.5278 12.7542C3.70224 12.5727 3.93882 12.4707 4.18551 12.4707H12.7902C13.0369 12.4707 13.2735 12.5727 13.4479 12.7542C13.6224 12.9357 13.7204 13.1818 13.7204 13.4385C13.7204 13.6952 13.6224 13.9414 13.4479 14.1229C13.2735 14.3044 13.0369 14.4063 12.7902 14.4063H4.18551C3.93882 14.4063 3.70224 14.3044 3.5278 14.1229C3.35337 13.9414 3.25537 13.6952 3.25537 13.4385Z"
      />
    </svg>
  )
}

export default Crown
