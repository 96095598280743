import * as React from 'react'

function SvgAnnotation(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.422 1.09v4.85a1.212 1.212 0 001.212 1.211h4.849"
        stroke="currentColor"
        strokeWidth={1.545}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.061 22.909H3.94a2.424 2.424 0 01-2.424-2.424V3.515A2.424 2.424 0 013.94 1.091h8.485l6.06 6.06v13.334a2.425 2.425 0 01-2.424 2.424zM6.363 8.364h1.212M6.363 13.212h7.273M6.363 18.061h7.273"
        stroke="currentColor"
        strokeWidth={1.545}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgAnnotation
