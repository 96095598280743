import * as React from 'react'

function SvgPhone(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.98 1.833h4.582l2.292 5.73L7.99 9.28a12.604 12.604 0 005.729 5.73l1.719-2.865 5.729 2.291v4.583a2.292 2.292 0 01-2.292 2.292A18.333 18.333 0 011.687 4.125 2.292 2.292 0 013.98 1.833"
        stroke="currentColor"
        strokeWidth={1.719}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgPhone
