const publicIp = require('public-ip')
const getDeviceId = async () => {
  const ipv4 = await publicIp.v4()

  return `ipv4=${ipv4}`
}
const DEVICE_ID = getDeviceId()

const LOGIN_SCREEN_STEPS = {
  LOGIN: "login",
  AUTH_FACTOR: "auth_factor",
  FORGOT_PASSWORD: "forgot_my_password",
  MAIL_SENT: "email_sent",
  MAIL_NOT_SENT: "user_not_found",
  RESET_PASSWORD: "reset_password",
  PASSWORD_CHANGED: "password_changed",
  REGISTER: "register",
  CHOOSE_PLAN: "choose_plan",
  CHECKOUT: "checkout",
  CHECK_INVOICE: "check_invoice"
}

export { DEVICE_ID, LOGIN_SCREEN_STEPS }
