export const productiveCapacity = (values) => {
  const workingHoursSplited = values
    ?.find((i) => i?.key === 'working_hours')
    ?.working_hours?.split?.(':')

  const workingHours =
    Number(workingHoursSplited?.[0] || 0) * 60 +
      Number(workingHoursSplited?.[1] || 0) ?? 0

  const workingDays =
    workingHours *
      values?.find((i) => i?.key === 'working_days')?.working_days ?? 0

  const workingMinutesSplited = values
    ?.find((i) => i?.key === 'working_minutes')
    ?.working_minutes?.split?.(':')

  const workingMinutes =
    Number(workingMinutesSplited?.[0] || 0) * 60 +
      Number(workingMinutesSplited?.[1] || 0) ?? 0

  const product_capacity =
    workingMinutes === 0
      ? 0
      : (
          (workingDays / workingMinutes || 0) *
          (values?.find((i) => i?.key === 'working_barbers')?.working_barbers ||
            0)
        ).toFixed(0) ?? 0
  const occupancy_rate =
    product_capacity == 0
      ? '0'
      : (
          (values?.find((i) => i?.key === 'working_services')
            ?.working_services /
            product_capacity) *
          100
        ).toFixed(2) ?? 0

  return { product_capacity, occupancy_rate }
}
