import React from 'react'
import { t } from 'services/translation'
import { Flex, Table, Text, Button, Box } from 'components'
import { useAppointmentStore, useBarbershopStore } from 'services/stores'
import { formatPrice } from 'utils'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useQuery } from 'react-query'
import { pxToRem } from 'styles/metrics'

const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
  {
    key: 'price',
    label: 'VALUES',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {formatPrice(item.price)}
      </Text>
    ),
  },
  {
    key: 'time_required',
    label: 'LENGTH',
    render: (item) => <Text>{item.time_required.substring(0, 5)}</Text>,
  },
]

const AppointmentSelectService = ({ goBack, clickEmpty }) => {
  const {
    setServiceArray,
    serviceArray,
    setDate,
    setServiceList,
    serviceList,
    barber,
  } = useAppointmentStore()
  const { userData } = useBarbershopStore()
  const { getServicesByBarber } = useBarbershop()

  const { isLoading } = useQuery(
    'get-services-recurrent',
    () => getServicesByBarber({ id: userData?.id, barber_id: barber?.id }),
    {
      onSuccess: (data) => {
        setServiceList(data?.filter((i) => i?.active && i?.type === 'normal'))
      },
    }
  )

  const handleSelect = (item) => {
    if (serviceArray.includes(item)) {
      const updateItems = serviceArray.filter((i) => i !== item)
      setServiceArray(updateItems)
      setDate(null)
    } else {
      setServiceArray([...serviceArray, item])
    }
  }

  return (
    <Box position="relative">
      <Text mb='12px' color='grey'>Pacotes de serviço e serviços do clube não podem ser agendados de maneira recorrente</Text>
      <Table
        withoutHeader
        data={serviceList || []}
        loading={isLoading}
        columns={columns}
        isSelectableRow
        onSelect={handleSelect}
        selectedList={serviceArray}
        isMultiSelectable
      />
    </Box>
  )
}

export default AppointmentSelectService
