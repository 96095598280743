import React, { useEffect, useState } from 'react'
import { Layout } from 'components'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { CreateGoalModal } from 'components-v2/organisms/BarberGoals/CreateGoals'
import { ManagerGoals } from 'components-v2/organisms/BarberGoals/ManagerGoals'

export const ManagerGoalsScreen = ({ ...props }) => {
  const [goalModalOpen, setGoalModalOpen] = useState(false)
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])

  return (
    <Layout
      {...props}
      h="auto"
      headerTitle="Gerenciamento de metas"
      px={100}
      secondActionClick={() => setGoalModalOpen(true)}
      secondActionText="Criar nova meta para colaborador"
      iconSecondActionText={'ReportChart'}
      iconColor="black"
    >
      <ManagerGoals />
      <CreateGoalModal
        isOpen={goalModalOpen}
        onClose={() => setGoalModalOpen(false)}
      />
    </Layout>
  )
}

ManagerGoalsScreen.path = '/metas-barbeiro'
ManagerGoalsScreen.title = 'Gerenciamento de metas'
ManagerGoalsScreen.secured = true
