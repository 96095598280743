import React, { useState, useEffect } from 'react'
import {
  Layout,
  Table,
  Text,
  FinanceResumeCard,
  MobileList,
  ClientDetailModal,
  FeatureBlockedMessage,
  Avatar,
  PieChart,
  Icon,
  TextInput,
  ClientReportCard,
  Box,
  AppointmentsResumeCard,
  Link,
} from 'components'
import { settings, settingsMobile } from './settings'
import { Center, Flex, Spinner, useToast, useTheme } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { formatPrice } from 'utils'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore, useCustomerStore } from 'services/stores'
import { parseISO, format, startOfMonth, endOfMonth, differenceInDays } from 'date-fns'
import './hideScrollbar.css'
import { formatPhone } from 'utils/phone-formater'

import { useQuery } from 'react-query'
import { api } from 'services/api/config'
import { BARBERSHOP_CLIENTS_RESUME } from 'services/api/endpoints'
import { clientReport } from 'utils/mockData'
import { UnitysModal } from 'components/organisms/Unitys'
import { usePermissionCollaborator } from 'services/hooks'
import { financesStore } from 'services/stores/finances'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const ClientsReport = (props) => {
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore()
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const { unity, setUnity } = financesStore()
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const permission = usePermissionCollaborator('finance')
  const clients = usePermissionCollaborator('clients')

  const cellColors = [...theme.colors.pieChart]

  async function getClientsResume({ queryKey }) {
    const params = {
      id: queryKey[1]?.id || userData?.barbershop?.id || userData?.id,
      start_date: queryKey[2],
      end_date: queryKey[3],
    }
    const res = await api
      .post(BARBERSHOP_CLIENTS_RESUME(params))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  useEffect(() => {
    setUnity(userData)
  }, [userData])
  const { data, refetch, isLoading, isError } = useQuery(
    ['get-clients-resume', unity, startDate, endDate],
    getClientsResume,
    {
      onError: (error) => {
        toast({
          title: t('LOAD_COMMISSIONS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
    }
  )

  const handleSelectCustomer = (customer) => {
    if (clients !== 'blocked') {
      setSelectedCustomer(customer?.client)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const columns = [
    {
      key: 'client',
      label: 'CLIENT',
      width: 125,
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center" ml={theme.pxToRem(-10)}>
            <Avatar size="medium" src={item?.client?.profile_image_url} />
            <Box
              ml={theme.pxToRem(12)}
              mr={theme.pxToRem(12)}
              h={theme.pxToRem(8)}
              w={theme.pxToRem(8)}
              bgColor={cellColors[index % cellColors?.length]}
              borderRadius={'100%'}
            />
            <Text w="47%">{item?.client?.name}</Text>
          </Flex>
        )
      },
    },
    {
      key: 'cellphone',
      label: 'PHONE',
      width: 130,
      render: (item) => {
        if (permission === 'edit') {
          return (
            <Link href={`https://api.whatsapp.com/send?1=pt_BR&phone=${item?.client?.user?.phone}`} target="_blank">
              {formatPhone(item?.client?.user?.phone, userData)}
            </Link>
          )
        } else {
          return <Text>{formatPhone(item?.client?.user?.phone, userData)}</Text>
        }
      },
    },
    {
      key: 'quantity',
      label: `${t('APPOINTMENTS_1')} ${t('APPOINTMENTS_2')}`,
      render: (item) => <Text>{item?.quantity}</Text>,
    },
    {
      key: 'more_services',
      label: 'MORE_SERVICE',
      render: (item) => {
        let arr = []
        const test = item?.service_quantity?.reduce((groups, val) => {
          const findIndex = arr?.findIndex((item) => item?.product === val)
          if (findIndex >= 0) {
            arr[findIndex] = {
              ...arr[findIndex],
              quantity: (arr[findIndex].quantity += 1),
            }
          } else {
            arr.push({
              product: val,
              quantity: 1,
            })
          }
          return arr
        }, [])
        const sort = test?.sort((a, b) => {
          return a?.quantity > b?.quantity ? -1 : a?.quantity < b?.quantity ? 1 : 0
        })

        return <Text>{sort[0]?.product}</Text>
      },
    },
    // {
    //   key: 'last_appointment',
    //   label: 'LAST_APPOINTMENT',
    //   render: (item) => (
    //     <Text>
    //       {differenceInDays(new Date(), parseISO(item?.last_appointment)) >= 0
    //         ? t('HAVE') +
    //           differenceInDays(new Date(), parseISO(item?.last_appointment)) +
    //           ' ' +
    //           t('DAYS')
    //         : 'Próximo agendamento marcado'}
    //     </Text>
    //   ),
    // },
    {
      key: 'total_products',
      label: 'TOTAL_PRODUCTS',
      render: (item) => <Text>{formatPrice(item?.product_value)}</Text>,
    },
    {
      key: 'total_services',
      label: 'TOTAL_SERVICES',
      render: (item) => <Text>{formatPrice(item?.service_value)}</Text>,
    },
    {
      key: 'discounts',
      label: 'TOTAL_DISCOUNTS',
      render: (item) => <Text>{formatPrice(item?.discount || 0)}</Text>,
    },
  ]

  const dataPie = () => {
    let label = (isBlocked ? clientReport : data)?.clients_info?.map((item) => item?.client?.name)
    let d = (isBlocked ? clientReport : data)?.clients_info?.map((item) => item?.quantity)

    const values = {
      label,
      data: d,
    }
    return values
  }

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const isBlocked = usePremiumBlock()

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('CLIENTS_REPORT')}
      widthTitle="100%"
      subTitle={`Unidade: ${unity?.name}`}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      // secondActionClick={() => setUnityModalOpen(true)}
      // iconSecondActionText={'Shop'}
      iconColor="black"
      // secondActionText={t('CHANGE_UNITY')}
      headerCenterElement={() =>
        !isBlocked && !isDesktop ? (
          <>
            <TextInput
              noBorder
              type="date"
              name="start_date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              label={t('START')}
              padding={theme.pxToRem(10)}
              mr="4%"
              width={'98%'}
            />
            <TextInput
              noBorder
              type="date"
              name="end_date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              label={t('END')}
              padding="5%"
              width={'100%'}
            />
          </>
        ) : (
          !isBlocked && (
            <Flex>
              <TextInput
                noBorder
                type="date"
                name="start_date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label={t('START')}
                padding={theme.pxToRem(10)}
                mr={theme.pxToRem(16)}
              />
              <TextInput
                noBorder
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label={t('END')}
                padding={theme.pxToRem(10)}
                width={'100%'}
              />
            </Flex>
          )
        )
      }
    >
      <>
        {isBlocked && <FeatureBlockedMessage title="BARBERS_REPORT_BLOCKED" section="CLIENTS" />}

        {(!(isBlocked ? clientReport : data) || isLoading) && !isError ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : isDesktop ? (
          <Flex w="100%" flexDir="column" padding={isBlocked && theme.pxToRem(24)} pointerEvents={isBlocked && 'none'}>
            <Flex w="99.4%" flexDir="row" alignItems="stretch" justifyContent="space-between" pb={theme.pxToRem(48)}>
              <Flex h={theme.pxToRem(210)} w="79%" flexDir="column" justifyContent="space-between" flexWrap="wrap">
                <FinanceResumeCard
                  width="30%"
                  label={t('SALES')}
                  description={t('TOTAL')}
                  value={(isBlocked ? clientReport : data)?.appointments_total_value}
                  color={'success'}
                  info="disabled"
                  infoText="Valor total de agendamentos recebidos - descontos oferecidos"
                />
                <Flex w="30%">
                  <FinanceResumeCard
                    width="50%"
                    notCurrency
                    label={t('CLIENTS')}
                    description={t('TOTAL')}
                    value={(isBlocked ? clientReport : data)?.total_clients || 0}
                    color={'white'}
                  />
                  <FinanceResumeCard
                    last
                    width="50%"
                    notCurrency
                    label={t('NEW_CLIENTS')}
                    description={t('TOTAL')}
                    value={(isBlocked ? clientReport : data)?.new_clients || 0}
                    color={'white'}
                  />
                </Flex>
                {settings(isBlocked ? clientReport : data)?.map((item) => (
                  <FinanceResumeCard
                    width="30%"
                    notCurrency={item?.notCurrency}
                    label={t(item?.label)}
                    description={t(item?.description)}
                    value={item?.value}
                    color={item?.color}
                    info={item?.info}
                    infoText={item?.infoText}
                  />
                ))}
                <AppointmentsResumeCard
                  mb={16}
                  h={210}
                  width="31%"
                  item={isBlocked ? clientReport : data}
                  infoText={'Resumo geral dos agendamentos'}
                />
                <Flex h={theme.pxToRem(210)} w="31%" align="center" justify="center">
                  {(isBlocked ? clientReport : data)?.clients_info?.length !== 0 ? (
                    <PieChart title="CLIENTS_REPORT" h="100%" w="100%" data={dataPie()} />
                  ) : (
                    <Flex flexDir="column" align="center">
                      <Icon name="Chart" size={40} />
                      <Text align="center">{t('NO_DATA_RESUME')}</Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>

            <Table
              loading={isLoading}
              data={
                (isBlocked ? clientReport : data)?.clients_info?.sort((a, b) => {
                  return a?.quantity > b?.quantity ? -1 : a?.quantity < b?.quantity ? 1 : 0
                }) || []
              }
              columns={columns}
              isSelectableRow
              onSelect={(item) => handleSelectCustomer(item)}
            />
          </Flex>
        ) : (
          <Flex
            flexDirection="column"
            width="full"
            padding={isBlocked && theme.pxToRem(16)}
            pointerEvents={isBlocked && 'none'}
          >
            <FinanceResumeCard
              full
              label={t('SALES')}
              description={t('TOTAL')}
              value={(isBlocked ? clientReport : data)?.appointments_total_value}
              color={'success'}
            />
            <Flex w="100%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(232)}>
              {settingsMobile(isBlocked ? clientReport : data)?.map((item) => (
                <FinanceResumeCard
                  wMobile="100%"
                  notCurrency={item?.notCurrency}
                  label={t(item?.label)}
                  description={t(item?.description)}
                  value={item?.value}
                  color={item?.color}
                  last={item?.last}
                />
              ))}
            </Flex>
            <AppointmentsResumeCard full item={isBlocked ? clientReport : data} />

            <Flex h={theme.pxToRem(250)} mb={theme.pxToRem(16)}>
              {(isBlocked ? clientReport : data)?.clients_info?.data?.length !== 0 ? (
                <PieChart title="CLIENTS_REPORT" h="100%" w="100%" data={dataPie()} dataLabels />
              ) : (
                <Flex flexDir="column" align="center">
                  <Icon name="Chart" size={20} />
                  <Text>{t('NO_DATA_RESUME')}</Text>
                </Flex>
              )}
            </Flex>

            <MobileList
              loading={isLoading}
              data={
                (isBlocked ? clientReport : data)?.clients_info.sort((a, b) => {
                  return a?.quantity > b?.quantity ? -1 : a?.quantity < b?.quantity ? 1 : 0
                }) || []
              }
              renderItem={({ item, index }) => (
                <ClientReportCard
                  item={item}
                  onSelect={(item) => handleSelectCustomer(item)}
                  index={index}
                  color={cellColors[index % cellColors?.length]}
                  totalAppointmentsThisMoth={data?.total_appointments}
                />
              )}
            />
          </Flex>
        )}
      </>

      {isCustomerDetailModalOpen && (
        <ClientDetailModal
          isModalOpen={isCustomerDetailModalOpen}
          onClose={() => {
            setCustomerDetailModal(false)
            refetch()
          }}
          customerData={selectedCustomer}
        />
      )}
      {unityModalOpen && (
        <UnitysModal
          isModalOpen={unityModalOpen}
          onClose={() => setUnityModalOpen(false)}
          selected={unity}
          setSelected={setUnity}
        />
      )}
    </Layout>
  )
}

ClientsReport.path = '/relatorio-clientes'
ClientsReport.title = 'Relatório - Clientes'
ClientsReport.secured = true
