import React from 'react'

const goodExample = `
  <Tabs>
    <>
      <p>Tab 1 label</p>
      <p>Tab 2 label</p>
    </>
    <>
      <div>Tab 1 content</div>
      <div>Tab 2 content</div>
    </>
  </Tabs>
`

const badExample = `
  <Tabs>
    <>
      <p>Tab 1 label</p>
      <p>Tab 2 label</p>
    </>
    <>
      <div>Tab 1 content</div>
    </>
  </Tabs>
`

function tabsChildrenShape({ children }, propName, componentName) {
  const hasRequiredSize = Array.isArray(children) && children.length === 2
  const hasRequiredElements = hasRequiredSize && children[0].type === React.Fragment && children[1].type === React.Fragment

  if (!hasRequiredSize || !hasRequiredElements) {
    return new Error(
      `
        Invalid prop "${propName}" supplied to ${componentName}.
        Children must be a tuple of two Fragments:
          <Tabs>
            <>
              <p>Tab 1 label</p>
              <p>Tab 2 label</p>
            </>
            <>
              <div>Tab 1 content</div>
              <div>Tab 2 content</div>
            </>
          </Tabs>
        `
    );
  }

  const hasChildrenSameSize = children[0].props.children.length === children[1].props.children.length

  if (!hasChildrenSameSize) {
    console.warn(`
      Divergent children size for content, the component may not work properly.
      
      Good:
        ${goodExample}

      Bad:
        ${badExample}
    `)
  }
}

export default tabsChildrenShape