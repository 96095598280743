import * as React from 'react'

function SvgChart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.142 23.395H1.858a1.177 1.177 0 000 2.354h32.284a1.176 1.176 0 100-2.354zM30.794 1.136a1.177 1.177 0 00-1.652-.203l-9.864 7.709-6.296-4.96a1.176 1.176 0 00-1.543.075l-6.747 6.472a1.177 1.177 0 001.63 1.699l6.007-5.763 6.218 4.897a1.177 1.177 0 001.453.003L30.59 2.788c.512-.4.603-1.14.203-1.652z"
        fill="currentColor"
      />
      <path
        d="M30.758 1.13a1.178 1.178 0 00-.808-.405l-6.59-.47a1.169 1.169 0 00-1.257 1.09 1.177 1.177 0 001.09 1.257l5.41.386-.381 4.94a1.177 1.177 0 002.347.18l.47-6.119a1.176 1.176 0 00-.281-.858zM17.98 16.178c-.65 0-1.177.526-1.177 1.177v7.217a1.177 1.177 0 102.353 0v-7.217c0-.65-.526-1.177-1.176-1.177zM23.706 12.844c-.65 0-1.177.527-1.177 1.177v10.551a1.177 1.177 0 102.354 0V14.021c0-.65-.527-1.177-1.177-1.177zM12.292 11.43c-.65 0-1.177.527-1.177 1.177v11.965a1.177 1.177 0 102.354 0V12.607c0-.65-.527-1.176-1.177-1.176zM6.565 16.648c-.65 0-1.176.526-1.176 1.176v6.747a1.177 1.177 0 102.353 0v-6.747c0-.65-.527-1.177-1.177-1.177zM29.435 15.236c-.65 0-1.177.527-1.177 1.177v8.16a1.177 1.177 0 102.353 0v-8.16c0-.65-.526-1.177-1.176-1.177z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChart
