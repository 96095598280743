import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'styles/colors'
import { useTheme, Box } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'

export const Modal = (props) => {
  const { isMobile } = useBreakpoint()

  const { type, height, isLoginModal } = props
  const theme = useTheme()

  if (type === 'float') {
    return (
      <Box
        color="white"
        pos="absolute"
        borderWidth={1}
        borderColor={colors.modalBorder}
        borderRadius="xl"
        transition="height 0.3s"
        height={height || 'fit-content'}
        w={isMobile ? theme.pxToRem(380) : theme.pxToRem(532)}
        top={theme.pxToRem(64)}
        marginX={isMobile ? 'auto' : null}
        right={isMobile ? null : theme.pxToRem(64)}
        background={colors.backgroundLayout}
        {...props}
      >
        {props.children}
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      color="white"
      pos={!isLoginModal && 'absolute'}
      borderWidth={1}
      borderColor={colors.modalBorder}
      borderRadius="xl"
      transition="height 0.3s"
      height={height || '100vh'}
      w="30vw"
      top="0"
      right="0"
      background={
        isLoginModal ? colors.menuBackground : colors.backgroundLayout
      }
      {...props}
    >
      {props.children}
    </Box>
  )
}

Modal.defaultProps = {
  type: '',
}

Modal.propTypes = {
  ...Modal.propTypes,
  type: PropTypes.string,
}
