import React from 'react'
import { Text } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'


export const ReleaseDetailsCard = ({ item }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isMobile } = useBreakpoint()

  const handleDescription = (payment) => {
    switch(payment.event) {
        case 'PAYMENT_CREATED':
            return 'Criação do pagamento'
        case 'PAYMENT_CONFIRMED':
          return `Cobrança confirmada - Recebimento estimado em: ${format(parseISO(payment.payment.estimatedCreditDate), 'dd/MM/yyyy')}`
        case 'PAYMENT_RECEIVED':
            return'Cobrança já disponível para saque'
        case 'PAYMENT_DELETED':
          return 'Cobrança removida'
        case 'PAYMENT_CREDIT_CARD_CAPTURE_REFUSED':
          return `Motivo: ${payment.additionalInfo.refuseReason}`
        default:
          return 'Sem descrição'
    }
  }

  return (
    <Box
      display="flex"
      flexDir="row"
      w='100%'
      h={theme.pxToRem(120)}
      borderRadius={theme.pxToRem(10)}
      backgroundColor="background"
      alignItems="center"
      justifyContent="space-between"
      px={theme.pxToRem(24)}
      mt={theme.pxToRem(24)}
    >
      <Flex
        w="100%"
        flexDir="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Flex
        w="100%"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Flex flexDir="column" w="100%">
          <Text w="100%" fontSize={theme.pxToRem(14)}>
            Data do evento
          </Text>
          <Text w="100%" fontSize={theme.pxToRem(14)} kind="bold">
            {format(parseISO(item?.logs?.dateCreated), 'dd/MM/yyyy')}
          </Text>
        </Flex>

        <Flex flexDir="column" w="100%">
          <Text w="100%" fontSize={theme.pxToRem(14)}>
            Descrição
          </Text>
          <Text w="100%" fontSize={theme.pxToRem(14)} kind="bold">
            {t(item?.logs?.event)}
          </Text>
        </Flex>
        </Flex>
        <Flex flexDir="column" w="100%" mt='6px'>
          <Text w="100%" fontSize={theme.pxToRem(14)} kind="bold">
            {handleDescription(item?.logs)}
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}
