import * as React from 'react'

function SvgHome(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#home_svg__clip0)">
        <path
          d="M23.672 10.26L12.776.354 12.673.26A1 1 0 0012.44.1l-.01-.005a1.001 1.001 0 00-.858 0l-.01.005c-.084.04-.162.094-.234.159l-.103.093L.327 10.26a1 1 0 101.345 1.48L2 11.441V23a1 1 0 001 1h18a1 1 0 001-1V11.442l.327.297a1 1 0 101.345-1.48zM10 22v-6a2 2 0 114 0v6h-4zm10 0h-4v-6a4 4 0 10-8 0v6H4V9.624l8-7.273 8 7.273V22z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="home_svg__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgHome
