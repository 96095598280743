import * as React from 'react'

function Excel(props) {
  return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C9 0.895 9.806 0 10.8 0H34.2C35.194 0 36 0.895 36 2V34C36 35.105 35.194 36 34.2 36H10.8C9.806 36 9 35.105 9 34V28H1.8C0.806 28 0 27.105 0 26V10C0 8.895 0.806 8 1.8 8H9V2ZM22 8V2H11V8H22ZM24 2V8H34V2H24ZM22 10H18V17H22V10ZM24 17V10H34V17H24ZM22 19H18V26H22V19ZM24 26V19H34V26H24ZM22 28H11V34H22V28ZM24 34V28H34V34H24ZM16 10V26H1.8V10H16ZM5.226 13H7.373L9.116 16.754L10.957 13H12.963L10.2 18L13.027 23H10.91L9.011 19.07L7.121 23H4.973L7.847 17.982L5.226 13Z" fill="black"/>
</svg>
  )
}

export default Excel