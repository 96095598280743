import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Text, Button, Icon } from 'components/atoms'
import { FreeTrialModalBg } from 'assets/img/backgrounds'
import { useBreakpoint } from 'services/hooks'
import { Form } from 'components/molecules'
import { TextInput } from 'components/atoms'
import { initial, input, validation } from './settings'
import { useMutation } from 'react-query'

import { api } from 'services/api/config'
import { IUGU_CLIENT_CREATE_CREDIT_CARD } from 'services/api/endpoints'

export const CreditCardModal = ({ isOpen, onClose }) => {
  const theme = useTheme()
  const history = useHistory()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const [cvv, setCvv] = useState('')
  const [expire, setExpire] = useState('')
  const [values, getValues] = useState({
    params: initial,
    isValid: false,
  })
  const toast = useToast()

  async function create(params) {
    const res = await api
      .post(IUGU_CLIENT_CREATE_CREDIT_CARD, params.params)
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'create-iugu-client-credit-card',
    (params) => create(params),
    {
      onSuccess: () => {
        toast({
          title: t('ADD_CREDIT_CARD'),
          status: 'success',
          duration: 4000,
        })
        history.push('/agenda')
      },
      onError: (error) => {
        toast({
          title: t('ADD_CREDIT_CARD_ERROR'),
          description: t('ADD_CREDIT_CARD_ERROR_DESC'),
          status: 'error',
          duration: 4000,
        })
      },
    }
  )

  const onConfirm = async () => {
    const split_name = String(values?.params.name).split(' ', 1)
    const first_name = split_name[0]
    const last_name = String(values?.params.name).substring(
      first_name.length + 1
    )
    const split_date = String(expire).split('/', 2)

    const payload = {
      number: values?.params.number.replace(/\s/g, ''),
      verification_value: cvv,
      first_name,
      last_name,
      month: split_date[0],
      year: split_date[1],
    }

    mutate.mutate({
      params: payload,
    })
  }

  const Container = isDesktop ? Flex : ModalContent

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <Container
          borderRadius="xl"
          mt={0}
          h="100vh"
          align="center"
          justify="center"
          bg="transparent"
          pb={theme.pxToRem(isDesktop ? 0 : 700)}
        >
          <Flex
            bgColor="backgroundComponent"
            backgroundImage={`url(${FreeTrialModalBg})`}
            bgRepeat="no-repeat"
            pos={!isDesktop && 'absolute'}
            top={!isDesktop && 0}
            maxW={theme.pxToRem(530)}
            maxH={theme.pxToRem(850)}
            px={theme.pxToRem(32)}
            pb={theme.pxToRem(40)}
            alignItems="center"
            justifyContent="flex-start"
            flexDir="Column"
            borderRadius="xl"
          >
            {/* close button */}
            <Flex
              w={theme.pxToRem(20)}
              h={theme.pxToRem(20)}
              bg="grey"
              align="center"
              justify="center"
              cursor="pointer"
              borderRadius={theme.pxToRem(16)}
              ml="95%"
              opacity={0.5}
              mt="4%"
            >
              <Icon
                name="CloseSlim"
                color="black"
                size={16}
                onClick={() => history.push('/agenda')}
              />
            </Flex>

            {/* credit card */}
            <Flex
              mt={'3%'}
              mb={'3%'}
              w={!isDesktop ? '80%' : '63%'}
              h={'22%'}
              bg="backgroundLayout"
              borderRadius={theme.pxToRem(16)}
              p={theme.pxToRem(!isDesktop ? 16 : 24)}
              flexDir="column"
              align="flex-start"
            >
              <Flex mb={'5%'} w="full" justify="space-between">
                <Icon name="Chip" />
                <Icon name="MasterCard" />
              </Flex>
              <Text
                color="textGrey"
                mt={'6%'}
                mb={'10%'}
                fontSize={theme.pxToRem(!isDesktop ? 12 : 14)}
              >
                {values?.params?.name || ''}
              </Text>
              <Flex justify="space-between" align="flex-end" w="full">
                <Text
                  color="textGrey"
                  fontSize={theme.pxToRem(!isDesktop ? 12 : 14)}
                >
                  {values?.params?.number || 'XXX.XXX.XXX-XX'}
                </Text>
                <Text color="textGrey" fontSize={theme.pxToRem(12)}>
                  {expire}
                </Text>
              </Flex>
            </Flex>
            {/* form */}
            <Form
              w="100%"
              initialValues={initial}
              validationSchema={validation}
              data={input(values?.params?.identifier)}
              getValues={getValues}
            />
            {/* form last line */}
            <Flex justify="space-between">
              <TextInput
                noBorder
                w="28%"
                mr="3%"
                name="cvv"
                placeHolder="XXX"
                maxLength={3}
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                label={t('CVV')}
                padding={theme.pxToRem(10)}
                h={theme.pxToRem(56)}
              />
              <TextInput
                w="69%"
                noBorder
                name="expire_date"
                mask="EXPIRE_DATE_MASK"
                value={expire}
                onChange={(e) => setExpire(e.target.value)}
                placeHolder="XX/XXXX"
                label={t('EXPIRE_AT')}
                padding={theme.pxToRem(10)}
                h={theme.pxToRem(56)}
              />
            </Flex>
            {/* final button */}
            <Button kind="primary" w="100%" mt="12%" onClick={onConfirm}>
              {t('BEGIN_PREMIUM')}
            </Button>

            <Text
              mt={'3%'}
              color="textGrey"
              fontSize={10}
              width="100%"
              textAlign="center"
            >
              {t('CREDIT_CARD_ADVISE')}
            </Text>
          </Flex>
        </Container>
      </ModalOverlay>
    </Modal>
  )
}
