import * as Yup from 'yup'
export const initialValues = {
  type: 'e-mail',
  user: '',
  password: '',
}

export const validationSchema = Yup.object().shape({
  user: Yup.string().email().required('Usuário é obrigatório'),
  password: Yup.string()
    .min(6, 'Senha deve ter ao menos 6 caracteres')
    .required('Senha é obrigatório'),
})

export const formikProps = {
  initialValues,
  validationSchema,
  validateOnChange: false,
  validateOnBlur: false,
}