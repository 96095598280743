import React from 'react'
import { Flex, Table, Text, ModalMenu } from 'components'
import { functionPayment } from './settings'
import { t } from 'services/translation'

const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
]

const SelectFunction = ({ isOpen, onClose, setFunction }) => {
  const selectFunction = (item) => {
    setFunction(item)
    onClose()
  }
  return (
    <ModalMenu
      title={t('SELECT_FUNCTION')}
      isOpen={isOpen}
      onClose={onClose}
      flexDirection="column"
    >
      <Table
        isSelectableRow
        data={functionPayment || []}
        columns={columns}
        onSelect={selectFunction}
      />
    </ModalMenu>
  )
}

export default SelectFunction
