import { api } from 'services/api/config'

export const useResetPassword = () => {
  const phoneCheck = async (data) => {
    const response = await api.post(`v3/check-phone`, {
      phone: data?.phone,
    })
    return response.data
  }
  const updatePassword = async (data) => {
    const { user_id, token, new_password, confirm_password } = data
    const response = await api.put(`v3/reset-password-with-token`, {
      user_id,
      token,
      new_password,
      confirm_password,
    })
    return response.data
  }

  return {
    phoneCheck,
    updatePassword,
  }
}
