import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader, Logo, AdsModal, SubscriptionModal } from 'components'
import { Flex, useTheme } from '@chakra-ui/react'
import {
  useFetch,
  saveSeeTutorial,
  saveLoginData,
  getLoginSavedData,
  usePremiumBlock,
  useBarbershopSubscription,
} from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useMutation } from 'react-query'
import { api } from 'services/api/config'
import { REVALIDATE_TOKEN } from 'services/api/endpoints'
import { useModalStore } from 'services/stores'
import { defaultPermissions } from 'utils'

export const AuthLoaderScreen = () => {
  const { setData } = useBarbershopStore()
  const history = useHistory()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { setCurrentModal } = useModalStore()
  const isBlocked = usePremiumBlock()
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)
  const [barbershopUserData, setBarbershopUserData] = useState()
  const { getIuguNewInvoice } = useBarbershopSubscription()

  async function revalidateToken() {
    const response = await getLoginSavedData()
    let params
    if (response?.user?.type === 'owner' || response?.user?.type === 'ownerAndBarber') {
      params = {
        barbershop_id: response?.userData?.barbershop?.id || response?.userData?.id,
      }
    } else {
      params = {}
    }
    const res = await api
      .post(REVALIDATE_TOKEN, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('revalidate-token', (params) => revalidateToken(params), {
    onSuccess: (data) => {
      const { user, userData, token, subscription, customer, new_token } = data

      const isBarbershop =
        data?.user?.type === 'owner' ||
        data?.user?.type === 'ownerAndBarber' ||
        data?.user?.type === 'barbershop' ||
        data?.user?.type === 'barbershopAndBarber'
      const permissions = isBarbershop ? defaultPermissions : data?.userData?.permissions
      const toSaveUserData = userData.barbershop ? userData.barbershop : userData
      const positive_access = ['LEAD', 'TRIAL', 'ONBOARDING', 'SUBSCRIBED', 'SUBSCRIBED_PARTNER']
      if (!positive_access.includes(toSaveUserData?.account_status)) {
        setOpenSubscriptionModal(true)
        setBarbershopUserData(toSaveUserData)
        return
      }
      toSaveUserData.permissions = permissions
      if (!isBarbershop) {
        toSaveUserData.employee_name = data?.userData?.name
        toSaveUserData.employee_image = data?.userData?.profile_image_url
      }
      saveLoginData({ user, userData: toSaveUserData, toSaveUserData, token, new_token })
      setData(user, toSaveUserData, subscription, customer)
      if (!toSaveUserData.status || toSaveUserData.status === 'not_published') {
        history.push('/cadastro/localizacao')
        return
      }

      // if (
      //   userData?.barbershop?.iugu_plan_id === 'free_barbershop_plan' ||
      //   userData?.iugu_plan_id === 'free_barbershop_plan'
      // ) {
      //   setOpen(true)
      //   return
      // }

      // if (!open) return history.push('/home')

      history.push('/agenda')
    },
    onError: (error) => {
      history.push('/login')
    },
  })

  useEffect(() => {
    mutate.mutate()
  }, [])

  const openLink = (url) => {
    var win = window.open(url, '_blank')
    win.focus()
  }

  const mutation_invoice = useMutation('get-iugu-new-invoice-login', () =>
    getIuguNewInvoice({
      iugu_subscription_id: barbershopUserData?.iugu_subscription_id,
      onSuccess: (data) => {
        openLink(data?.invoice)
      },
    })
  )

  return (
    <Flex flexDir="column" justifyContent="center" alignItems="center" w="100%" h="100vh">
      <Logo mb={theme.pxToRem(64)} size="medium" />
      <Loader />

      {/* <AdsModal isOpen={open} /> */}
      <SubscriptionModal
        isOpen={openSubscriptionModal}
        onClose={() => history.push('/login')}
        onClick={mutation_invoice.mutate}
        isLoading={mutation_invoice.isLoading}
      />
    </Flex>
  )
}

AuthLoaderScreen.path = '/'
AuthLoaderScreen.title = 'BestBarbers'
AuthLoaderScreen.secured = false
