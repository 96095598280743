import { api } from 'services/api/config'

function isObject(obj) {
  return obj === Object(obj)
}

const handleError = (error) => {
  if (error.response?.data?.error && isObject(error?.response?.data?.error)) {
    return {
      error:
        'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
    }
  }
  if (error?.response?.data?.error) {
    return {
      error: error.response.data.error,
    }
  }
  return {
    error: 'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
  }
}

export const onFetch = async ({
  url,
  method,
  params,
  onCompleted,
  onError,
  message,
  headers,
  toast,
}) => {
  try {
    const { data } = await api[method || 'get'](url, params || {}, headers)
    !!onCompleted && onCompleted(data)
    if (message?.success && toast) {
      toast({
        title: message?.success?.title,
        description: message?.success?.description,
        status: message?.success?.status,
        duration: 4000,
        isClosable: true,
      })
    }
    return { data }
  } catch (error) {
    const errorMessage = handleError(error)
    !!onError && onError(errorMessage)
    if (message?.error && toast) {
      toast({
        title: message?.error?.title,
        description: message?.error?.description,
        status: message?.error?.status,
        duration: 4000,
        isClosable: true,
      })
    }
    return { error }
  }
}
