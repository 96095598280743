import { api } from 'services/api/config'

export const useBarbershopSignature = () => {
  const getSubscriptionApp = async (props) => {
    const response = await api({
      url: `v3/barbershop/manager-subscribe`,
      params: {
        barbershop_id: props?.barbershop_id
      }
    })
    return response.data
  }

  const changeSubscriptionApp = async (props) => {
    const response = await api.put( `v3/barbershop/manager-subscribe`,
      props?.body
    )
    return response.data
  }

  return {
    getSubscriptionApp,
    changeSubscriptionApp
  }
}
