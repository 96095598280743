import React, { useCallback } from 'react'
import { format } from 'date-fns'
import {
  Box,
  Text,
  Button,
  Flex,
  ClientCard,
  ServiceCard,
  BarberCard,
  DateTimeCard,
} from 'components'
import { useTheme } from '@chakra-ui/react'
import { APPOINTMENT_STEPS } from './settings'
import { useAppointmentStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAppointment } from 'services/hooks/use-appointment'
import { useMutation } from 'react-query'
import { calculateInitialHour } from 'utils/servicesTime'

const EditAppointment = ({ setStep, selectedAppointment }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const { editAppointment } = useAppointment()

  const {
    type,
    setType,
    client,
    setClient,
    barber,
    setBarber,
    serviceArray,
    setServiceArray,
    date,
    setDate,
    time,
    setTime,
    finishHour,
    setFinishHour,
    clearState,
  } = useAppointmentStore()

  React.useEffect(() => {
    setClient(selectedAppointment?.client)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppointment])

  const updateAppointment = async () => {
    const params = {
      id: selectedAppointment?.id,
      date: format(date, 'yyyy-MM-dd'),
      currentHour: format(new Date(), 'HH:mm'),
      currentDate: format(new Date(), 'yyyy-MM-dd'),
      barber_id: barber?.id,
      barbershop_id: barber?.barbershop_id,
      servicesId: serviceArray.map((item) => item?.service_id || item?.id),
      type,
      client_id: client?.id,
      start_hour: time,
      time_required: calculateInitialHour(serviceArray),
    }
    if (type === 'fit') {
      params.finish_hour = finishHour
    }
    mutate.mutate({ params })
  }

  const mutate = useMutation('update-appointment', ({ params }) =>
    editAppointment({
      params,
      onSuccess: handleSuccess,
    })
  )

  const handleSuccess = () => {
    history.go(0)
    clearState()
  }

  const deleteItemList = useCallback((item) => {
    const find = serviceArray.filter((r) => r !== item)
    setServiceArray(find)
  })

  return (
    <Flex flexDirection="column" justifyContent="space-between" height="95%">
      <Box>
        <Box marginBottom={theme.pxToRem(24)}>
          <Text
            marginBottom={theme.pxToRem(16)}
            fontSize={theme.pxToRem(12)}
            textTransform="uppercase"
          >
            {t('CUSTOMER')}
          </Text>
          <Flex>
            {client ? (
              <ClientCard client={client} />
            ) : (
              <Button
                size="block"
                kind="dashed"
                onClick={() => setStep(APPOINTMENT_STEPS.selectCustomer)}
              >
                {t('SELECT_CUSTOMER')}
              </Button>
            )}
          </Flex>
        </Box>
        <Box marginBottom={theme.pxToRem(24)} flex={1}>
          <Text
            marginBottom={theme.pxToRem(16)}
            fontSize={theme.pxToRem(12)}
            textTransform="uppercase"
          >
            {t('APPOINTMENT_TYPE')}
          </Text>
          <Flex justifyContent="space-between" width="100%">
            <Button
              kind={type === 'normal' ? 'primary' : 'secondary'}
              size="medium"
              marginRight="32px"
              onClick={() => {
                setDate(null)
                setTime(null)
                setType('normal')
              }}
            >
              {t('STANDARD')}
            </Button>
            <Button
              kind={type === 'fit' ? 'primary' : 'secondary'}
              size="medium"
              onClick={() => {
                setDate(null)
                setTime(null)
                setType('fit')
              }}
            >
              {t('FIT')}
            </Button>
          </Flex>
        </Box>
        <Box marginBottom={theme.pxToRem(24)}>
          <Text
            marginBottom={theme.pxToRem(16)}
            fontSize={theme.pxToRem(12)}
            textTransform="uppercase"
          >
            {t('BARBER')}
          </Text>
          <Flex>
            {barber ? (
              <BarberCard
                barber={barber}
                onClick={() => {
                  setBarber(null)
                  setDate(null)
                  setTime(null)
                  setServiceArray([])
                }}
              />
            ) : (
              <Button
                size="block"
                kind="dashed"
                onClick={() => setStep(APPOINTMENT_STEPS.selectBarber)}
              >
                {t('SELECT_BARBER')}
              </Button>
            )}
          </Flex>
        </Box>
        <Box marginBottom={theme.pxToRem(24)}>
          <Text
            marginBottom={theme.pxToRem(16)}
            fontSize={theme.pxToRem(12)}
            textTransform="uppercase"
          >
            {t('_SERVICE')}
          </Text>
          <Flex flexDirection="column">
            {!!serviceArray.length &&
              serviceArray.map((item) => (
                <ServiceCard
                  mb={theme.pxToRem(16)}
                  service={item}
                  onDelete={() => {
                    setDate(null)
                    setTime(null)
                    deleteItemList(item)
                  }}
                  date={date}
                />
              ))}
            <Button
              size="block"
              kind="dashed"
              onClick={() => setStep(APPOINTMENT_STEPS.selectService)}
            >
              {t('SELECT_SERVICE')}
            </Button>
          </Flex>
        </Box>
        <Box marginBottom={theme.pxToRem(24)}>
          <Text
            marginBottom={theme.pxToRem(16)}
            fontSize={theme.pxToRem(12)}
            textTransform="uppercase"
          >
            {t('SELECT_DATE_TIME')}
          </Text>
          <Flex>
            {date && time ? (
              <DateTimeCard
                date={date}
                time={time}
                finishHour={finishHour}
                onClick={() => {
                  setDate(null)
                  setTime(null)
                  setFinishHour(null)
                }}
              />
            ) : (
              <Button
                size="block"
                kind="dashed"
                onClick={() => setStep(APPOINTMENT_STEPS.selectDateAndTime)}
              >
                {t('SELECT_DATE')}
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
      <Box pb={theme.pxToRem(40)}>
        <Button
          size="block"
          onClick={updateAppointment}
          isLoading={mutate.isLoading}
          isDisabled={!date || !time || !barber || !serviceArray || !client}
        >
          {t('CONFIRM')}
        </Button>
      </Box>
    </Flex>
  )
}

export default EditAppointment
