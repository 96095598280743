import React, { useEffect, useState } from 'react'
import { firebaseAnalytics } from '../../../../services/firebase'
import { useLocation, useHistory } from 'react-router-dom'
import { Flex, LandingPageModal } from 'components'

import { Why } from '../../components/whySection'
import { Plans } from '../../components/plans'
import { HeroPartners } from '../../components/hero_partners'
import { FreeTestPartners } from '../../components/freeTest_partners'
import { About } from '../../components/about'
import { Header } from '../../components/header'
import { Footer } from '../../components/footer'

import octos from '../../images/octos.png'

export const OctosLandingPage = () => {
  const history = useHistory()

  const clickPremium = () => history.push('/cadastro/OCTOS23')
  const clickAppExclusive = () =>
    window.open(
      `https://wa.me/553171684757?text=Olá! Conheci o BestBarbers através do Pedro da Octo's e gostaria de saber mais sobre os aplicativos personalizados`
    )

  return (
    <Flex overflowY="auto" overflowX="hidden" overscrollY="contain" w={'100vw'} flexDir="column">
      <Header clickButton={clickAppExclusive} />
      <HeroPartners clickAppExclusive={clickAppExclusive} img={octos} h={'100%'} />
      <About clickButton={clickPremium} />
      <Plans clickAppExclusive={clickAppExclusive} clickButton={clickPremium} />
      <FreeTestPartners clickButton={clickPremium} />
      <Why clickButton={clickPremium} />
      <Footer />
    </Flex>
  )
}

OctosLandingPage.path = '/lp/partners/octos'
OctosLandingPage.title = 'LandingPage'
OctosLandingPage.secured = false
