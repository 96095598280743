import * as React from 'react'

function SvgBank(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.98 33.12c.01.001.027 0 .043-.01a.076.076 0 00.037-.055 23.24 23.24 0 00.002-.28v-.087l-.003-1.973v-.075H1.941V33.12h30.04zm0 0h-.006 0c-.011-.002.003 0 .01 0h-.003zm.008-.15s0 0 0 0 0 0 0 0zm0 0c.008.001.017.003.008.002h0l-.008-.001zm-.004-19.378h-.075v0H30.71v15.2h1.275c1.06 0 1.922.863 1.922 1.923v2.33c0 1.061-.863 1.924-1.923 1.924H2.016a1.925 1.925 0 01-1.922-1.923v-2.331c0-1.06.862-1.923 1.922-1.923H3.29v-15.2H2.016a1.925 1.925 0 01-1.922-1.923V9.082c0-.79.472-1.49 1.205-1.784h0L16.286 1.17h0a1.914 1.914 0 011.435.003s0 0 0 0l14.98 6.125h.001a1.913 1.913 0 011.205 1.784v2.587c0 1.06-.862 1.922-1.923 1.922zM5.138 28.716v.075h2.414v-15.2H5.138v15.125zm4.262 0v.075h4.545v-15.2H9.4v15.125zm6.393 0v.075h2.414v-15.2h-2.414v15.125zm4.262 0v.075h4.546v-15.2h-4.546v15.125zm6.393 0v.075H28.863v-15.2H26.448v15.125zM1.941 11.669v.075H32.06v-.075V10.1a503.935 503.935 0 00-.001-1.009.218.218 0 00-.006-.036.075.075 0 00-.064-.052l-14.96-6.117L17 2.874l-.028.012-14.96 6.116a.077.077 0 00-.067.068 8.327 8.327 0 00-.004.26v2.339z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.15}
      />
      <path
        d="M16.1 6.275a.899.899 0 011.798 0v2.13a.899.899 0 01-1.798 0v-2.13z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  )
}

export default SvgBank
