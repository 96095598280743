export const resume = (monthExtract) => {
  return [
    {
      label: 'BALANCE_MONTH',
      description: 'LIQUID_VALUE',
      color: 'white',
      value: monthExtract?.month_balance || 0,
      balance: monthExtract?.month_balance >= 0 ? 'successDark' : 'dangerCard',
      background: monthExtract?.month_balance >= 0 ? 'success' : 'danger',
      info: 'lancamentos',
    },
    {
      label: 'UNTIL_MOMENT',
      color: 'success',
      value: monthExtract?.total_entries || 0,
      description: 'TOTAL_ENTRIES',
      info: 'lancamentos',
    },
    {
      label: 'UNTIL_MOMENT',
      color: 'danger',
      value: -monthExtract?.total_exits || 0,
      description: 'TOTAL_EXITS',
      info: 'lancamentos',
    },
    {
      label: 'TOTAL_CLIENTS',
      description: 'ATTENDED',
      value: monthExtract?.total_clients || 0,
      notCurrency: true,
    },
  ]
}

export const resumeMobile = (monthExtract) => {
  return [
    {
      label: 'BALANCE_MONTH',
      description: 'LIQUID_VALUE',
      color: 'white',
      value: monthExtract?.month_balance || 0,
      balance: monthExtract?.month_balance >= 0 ? 'successDark' : 'dangerCard',
      background: monthExtract?.month_balance >= 0 ? 'success' : 'danger',
      info: 'lancamentos',
    },
    {
      label: 'UNTIL_MOMENT',
      color: 'success',
      value: monthExtract?.total_entries || 0,
      description: 'TOTAL_ENTRIES',
      info: 'lancamentos',
    },
    {
      label: 'TOTAL_CLIENTS',
      description: 'ATTENDED',
      value: monthExtract?.total_clients || 0,
      notCurrency: true,
    },
    {
      label: 'UNTIL_MOMENT',
      color: 'danger',
      value: monthExtract?.total_exits || 0,
      description: 'TOTAL_EXITS',
      info: 'lancamentos',
    },
  ]
}
