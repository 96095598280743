import create from 'zustand'

export const authStore = create((set, get) => ({
  loading: null,
  setLoading: (loading) => set({ loading }),

  userDataChangePassword: null,
  setUserDataChangePassword: (userDataChangePassword) =>
    set({ userDataChangePassword }),
}))
