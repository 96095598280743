import React, { useEffect, useState } from 'react'
import { Layout, SelectDateButton } from 'components'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { BarberGoalsResume } from 'components-v2/organisms/BarberGoalsResume'

export const ResumeGoalsScreenV2 = ({ ...props }) => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])

  return (
    <Layout
      {...props}
      headerTitle="Metas da equipe"
      headerCenterElement={() => (
        <SelectDateButton width="400px" h="42px" onlyMonth date={selectedDay} setDate={setSelectedDay} />
      )}
    >
      <BarberGoalsResume selectedDay={selectedDay} />
    </Layout>
  )
}

ResumeGoalsScreenV2.path = '/metas-resumo-v2'
ResumeGoalsScreenV2.title = 'Metas dos colaboradores'
ResumeGoalsScreenV2.secured = true
