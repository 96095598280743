import * as Yup from 'yup'

export const data = [
  {
    id: 0,
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'Nome',
    placeholder: 'Digite seu nome',
  },
  {
    id: 1,
    name: 'email',
    type: 'string',
    noBorder: true,
    label: 'Email',
    placeholder: 'Digite seu e-mail',
  },
  {
    id: 2,
    name: 'phone',
    type: 'cel-phone',
    noBorder: true,
    label: 'Telefone de contato (whatsapp)',
    mask: 'PHONE_MASK',
    placeholder: 'Digite seu telefone para contato',
  },
]

export const validation = Yup.object().shape({
  name: Yup.string().min(4, 'NAME').required('Nome do serviço é obrigatório'),
  phone: Yup.string()
    .min(4, 'NAME')
    .required('Nome do responsável é obrigatório'),
  email: Yup.string(),
})

export const initialValues = {
  name: '',
  phone: '',
  email: '',
}
