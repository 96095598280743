import { api } from 'services/api/config'

//função espera um valor props -> obj -> 2 atributos: params e body(bestocins_active)
export const useBestcoins = () => {
  const updateBestcoins = async (props) => {
    const { barbershop_id } = props?.params
    const response = await api.put(
      `v3/bestcoins/update-config/${barbershop_id}`,
      props?.body
    )
    return response.data
  }

  const getClient = async (props) => {
    const { barbershop_id } = props?.params
    const response = await api({
      url: `v3/bestcoins/client-points/${barbershop_id}`,
    })
    return response.data
  }

  const getRewardsExchanges = async (props) => {
    const { barbershop_id } = props?.params
    const response = await api({
      url: `v3/bestcoins/rewards-exchanges/${barbershop_id}`,
    })
    return response.data
  }

  const getRewards = async (props) => {
    const { barbershop_id } = props?.params
    const response = await api({
      url: `v3/bestcoins/rewards/${barbershop_id}`,
    })
    return response.data
  }

  const updateReward = async (props) => {
    const { reward_id } = props
    const response = await api.put(
      `v3/bestcoins/rewards/update/${reward_id}`,
      props?.body
    )
    return response.data
  }

  const createReward = async (props) => {
    const response = await api.post('v3/bestcoins/rewards/create', props?.body)
    return response.data
  }

  const createRewardExchange = async (props) => {
    const response = await api.post(
      'v3/bestcoins/reward_exchange/create',
      props?.body
    )
    return response.data
  }

  const deleteReward = async (props) => {
    const { reward_id } = props
    const response = await api.delete(
      `v3/bestcoins/rewards/delete/${reward_id}`
    )
    return response.data
  }

  const deleteRewardExchange = async (props) => {
    const { reward_exchange_id } = props
    const response = await api.delete(
      `v3/bestcoins/reward_exchange/delete/${reward_exchange_id}`
    )
    return response.data
  }

  return {
    updateBestcoins,
    getClient,
    getRewardsExchanges,
    getRewards,
    updateReward,
    createReward,
    createRewardExchange,
    deleteReward,
    deleteRewardExchange,
  }
}

// PARA FAZER UPDATES SEPARADOS
// const updatePointBestcoins = async (props) => {
//     const { barbershop_id } = props?.params
//     const response = await api.put(
//      `v3/bestcoins/update-config/${barbershop_id}`,
//      props?.body
//     )
//         return response.data
// }
