import React, { useState } from 'react'
import {
  Table,
  Text,
  PaymentMethodModal,
  MobileList,
  PaymentMethodCard,
  PremiumBlockMessage,
  Button,
  Layout,
} from 'components'
import { useToast, useTheme, Box, Flex } from '@chakra-ui/react'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { financesStore } from 'services/stores/finances'

import { BARBERSHOP_GET_PAYMENT_METHOD } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { usePremiumBlock } from 'services/hooks/use-premium-block'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'percentage',
    label: 'PERCENTAGE',
    render: (item) => <Text>{item.percentage}%</Text>,
  },
  { key: 'due_in_days', label: 'DUE_IN_DAYS', width: 40 },
  {
    key: 'discount_from_barber',
    label: 'DISCOUNT_FROM_BARBER',
    width: 97,
    render: (item) => <Text>{item.discount_from_barber ? 'Sim' : 'Não'}</Text>,
  },
]

export const PaymentsScreen = ({ ...props }) => {
  const { userData } = useBarbershopStore()
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const { isDesktop } = useBreakpoint()
  const { setPaymentMethods } = financesStore()
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const toast = useToast()

  async function getPaymentMethods() {
    const res = await api
      .get(BARBERSHOP_GET_PAYMENT_METHOD(userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, refetch, isLoading } = useQuery('get-payment-methods', getPaymentMethods, {
    onSuccess: (value) => {
      setPaymentMethods(value)
    },
    onError: (error) => {
      toast({
        title: t('LOAD_PAYMENT_METHODS_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const onSelectItem = (item) => {
    setSelectedItem(item)
    setModalVisible(true)
  }

  const onCloseModal = () => {
    setModalVisible(false)
    setSelectedItem()
    refetch()
  }

  const freeAccountLimit = data?.length >= 2

  return (
    <Layout {...props} headerTitle={t('PAYMENTS_MANAGER')} fullHeight={false}>
      <Box>
        <Flex justify="space-between" align="flex-start" mb={theme.pxToRem(24)}>
          <Text fontSize={theme.pxToRem(18)} fontWeight="bold" w="50%">
            {t('PAYMENT_METHODS')}
          </Text>

          <Flex flexDir="column" align="flex-end">
            <Button size={'fit-small'} disabled={isBlocked && freeAccountLimit} onClick={() => setModalVisible(true)}>
              {t('ADD_PAYMENT_METHOD')}
            </Button>

            {isBlocked && freeAccountLimit && (
              <Text fontSize={theme.pxToRem(14)} kind="medium" color="danger" mt={theme.pxToRem(4)}>
                {t('PAYMENT_METHODS_BLOCK_MESSAGE_HEADER')}
              </Text>
            )}
          </Flex>
        </Flex>

        {isDesktop ? (
          <Table isSelectableRow onSelect={onSelectItem} data={data || []} loading={isLoading} columns={columns} />
        ) : (
          <MobileList
            data={data || []}
            loading={isLoading}
            renderItem={({ item, index }) => (
              <PaymentMethodCard inList paymentMethod={item} index={index} onClick={onSelectItem} />
            )}
          />
        )}

        {isBlocked && freeAccountLimit && (
          <PremiumBlockMessage message="PAYMENT_METHODS_BLOCK_MESSAGE" mt={theme.pxToRem(16)} />
        )}

        {modalVisible && (
          <PaymentMethodModal isModalOpen={modalVisible} onClose={onCloseModal} selected={selectedItem} />
        )}
      </Box>
    </Layout>
  )
}

PaymentsScreen.path = '/metodos-pagamentos'
PaymentsScreen.title = 'Profile'
PaymentsScreen.secured = true
