import React, { useEffect, useState } from 'react'
import { Layout, Table, SubscriptionDetailModal, Text } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useMutation, useQuery } from 'react-query'
import { serviceColumns } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { usePlans } from 'services/hooks/use-plans'
import { CreatePlanModal } from 'components-v2/organisms/CreatePlanModal'
import { AddNewSubscriber } from 'components-v2/organisms/AddNewSubscriber'
import { formatPrice, WEEK_DAYS } from 'utils'
import { t } from 'services/translation'
import { Switch, useToast } from '@chakra-ui/react'

export const SignaturePlans = ({ ...props }) => {
  const [modalDetailOpen, setModalDetailOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [addSubscriber, setAddSubscriber] = useState(false)
  const [plan, setPlan] = useState(null)
  const [client, setClient] = useState()
  const { getPlans, updatePlanVisible } = usePlans()
  const { userData } = useBarbershopStore()
  const { onLogout } = useLogoutPaymentIssues()
  const [visible, setVisible] = useState([])
  const toast = useToast()

  useEffect(() => {
    onLogout()
  }, [])

  const queryPlans = useQuery(['get-plans'], () => getPlans({ id: userData?.barbershop_id || userData?.id }))

  const mutate = useMutation('change-barber-visible', (params) => updatePlanVisible(params), {
    onSuccess: () => {
      toast({
        title: 'Plano alterado com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: () => {
      toast({
        title: 'Erro ao alterar plano',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleChangeVisible = (key, value) => {
    const find = visible?.filter((i) => i.id !== key)
    setVisible([...find, { id: key, value }])
    mutate.mutate({ body: { plan_id: key, visible_for_clients: value } })
  }

  const onCloseModal = () => {
    setClient()
    setModalDetailOpen(false)
  }

  const onClickTableRow = (selectedRow) => {
    setPlan(selectedRow)
    setPlanModalOpen(true)
  }

  const serviceColumns = [
    { key: 'name', label: 'NAME_TABLE' },
    {
      key: 'price',
      label: 'VALUE_TABLE',
      render: (item) => <Text>{formatPrice(item.value)}</Text>,
    },
    {
      key: 'days',
      label: 'DIAS HABILITADOS',
      render: (item) => (
        <Text>
          {!item?.days
            ? 'Todos os dias'
            : item?.days?.map((day) => t(WEEK_DAYS.find((i) => i.id === day)?.day)).join(', ')}
        </Text>
      ),
    },
    {
      key: 'services',
      label: 'SERVIÇOS',
      render: (item) => (
        <Text maxW="190px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {item?.services_plans?.map((i) => i?.name)?.join(', ')}
        </Text>
      ),
    },
    {
      key: 'days',
      label: 'LIMITE DE USO',
      render: (item) => <Text>{item?.limited ? 'Sim' : 'Não'}</Text>,
    },
    {
      key: 'visible_for_clients',
      label: 'VISÍVEL P/ CLIENTES',
      type: 'switch',
      width: 70,
      render: (item) => (
        <Switch
          colorScheme="yellow"
          onChange={(e) => {
            handleChangeVisible(item?.id, e.target.checked)
          }}
          isChecked={
            !visible?.find((i) => i?.id === item?.id)
              ? queryPlans?.data?.plans?.find?.((i) => i?.id === item?.id)?.visible_for_clients
              : visible?.find((i) => i?.id === item?.id)?.value
          }
        />
      ),
    },
  ]

  return (
    <Layout
      {...props}
      headerTitle="Planos de assinatura"
      modalFilter
      modalFilterWidth="12%"
      headerActionText="Cadastrar novo plano"
      headerActionClick={() => setPlanModalOpen(true)}
    >
      <Table
        isSelectableRow
        onSelect={(item, index, column) => {
          if (column?.key !== 'visible_for_clients') {
            onClickTableRow(item)
          }
        }}
        data={queryPlans.data?.plans || []}
        loading={queryPlans.isLoading}
        columns={serviceColumns}
        widthOptions="100%"
      />

      <CreatePlanModal
        isOpen={planModalOpen}
        onClose={() => {
          setPlan(null)
          setPlanModalOpen(false)
        }}
        plan={plan}
      />
      <AddNewSubscriber isOpen={addSubscriber} onClose={() => setAddSubscriber(false)} />

      <SubscriptionDetailModal
        isOpen={modalDetailOpen}
        onClose={onCloseModal}
        barbershop_id={client?.barbershop_id}
        signature_id={client?.signature_club_id}
        client_id={client?.client_id}
      />
    </Layout>
  )
}

SignaturePlans.path = '/planos-assinatura'
SignaturePlans.title = 'Planos de assinatura'
SignaturePlans.secured = true
