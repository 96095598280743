import React from 'react'
import { Flex, useTheme } from '@chakra-ui/react'
import { Text } from 'components/atoms'

export const BillRow = ({ key, description, value}) => {
  const theme = useTheme()
  return (
    <Flex
      key={`bill_row_${key}`}
      h={theme.pxToRem(36)}
      w="94%"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      borderColor="textMedium"
      borderTopWidth={theme.pxToRem(0.2)}
      borderBottomWidth={theme.pxToRem(0.2)}
    >
      <Text fontSize={theme.pxToRem(14)} kind="medium">
        {description}
      </Text>
      <Text fontSize={theme.pxToRem(14)} kind="medium">
        {value}
      </Text>
    </Flex>
  )
}
