import React, { useMemo, useState } from 'react'
import { useTheme, Select, toast } from '@chakra-ui/react'
import {
  Flex,
  Text,
  TextInput,
  Button,
  Icon,
  CheckBox,
} from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint, useBarbershop } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { Calendar, ModalMenu } from 'components/molecules'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { format, parseISO } from 'date-fns'

export const PersonalizedHours = ({ isModalOpen, onClose, refetch, ...props }) => {
  const { userData, setUserData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const [startHour, setStartHour] = useState()
  const [closeHour, setCloseHour] = useState()
  const [isClosed, setIsClosed] = useState(false)
  const [selectedDate, setSelectedDate] = useState()
  const theme = useTheme()
  const {createPersonalizedHour} = useBarbershop()
  const queryClient = useQueryClient()

  const mutate = useMutation(
    'create-personalized-hour',
    (params) => createPersonalizedHour(params),
  )

  const onSuccess = (data) => {
    setUserData({
      ...userData,
      personalized_opening_hours: data,
    })
    setSelectedDate()
    setStartHour()
    setCloseHour()
    refetch()
    onClose()
  }


  const isDisabled = useMemo(() => {
    return (!isClosed && ( !startHour || startHour?.length === 0 || 
    !closeHour || closeHour?.length === 0 || !selectedDate)) || (isClosed && !selectedDate)
  }, [startHour, closeHour, isClosed])

  const handleCreateWorthBarber = () => {
    if(closeHour < startHour) {
      toast({
        title: 'Erro ao alterar horário de funcionamento',
        description: 'A hora de fechamento deve ser maior que a de abertura',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    const params = {
      barbershop_id: userData?.barbershop?.id || userData?.id,
      start_hour: startHour, 
      close_hour: closeHour,
      date: format(selectedDate, 'yyyy-MM-dd'),
      isClosed
    }
    mutate.mutate({params, onSuccess }, {
      onSettled: () => {
        queryClient.invalidateQueries('change-working-hours')}
    })
  }

  return (
    <>
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onClose}
        title='Adicionar horário personalizado'
        headerStyle={{ mb: pxToRem(-12) }}
      > 
          <Flex
            position="relative"
            direction="column"
            alignItems={!isDesktop && 'center'}
          >
              <Flex mt='16px' mb={theme.pxToRem(12)} flexDir="column">
                <Text
                  kind="medium"
                  fontSize={theme.pxToRem(14)}
                  color="textGrey" 
                  mb={theme.pxToRem(8)}
                >
                  Selecione o dia
                </Text>
      <Calendar {...props} 
      selectedDate={selectedDate}
      onSelectDate={setSelectedDate}
      minDate={new Date()}
      />
                
              </Flex>
              <Flex align='center' mb='12px'>
              <CheckBox
                                onChange={() =>
                                    setIsClosed(true)
                                }
                                isChecked={isClosed}
                                borderWidth="1px"
                                borderColor={isClosed ? 'primary' : 'grey'}
                                borderRadius="4px"
                                mr="12px"
                                size="lg"
                            />
                         <Text fontSize={18} >
          Estará fechado esse dia?
        </Text>
              </Flex>
              
              <Flex>
              <TextInput
            noBorder
            name="start_hour"
            label='Abre ás'
            onChange={(e) => setStartHour(e.target.value)}
            placeholder="08:00"
            mb={theme.pxToRem(12)}
            mr='12px'
            mask="HOUR_MASK"
            leftElement={<Icon name="Clock" size={16} />}
            disabled={isClosed}
            />
            <TextInput
            noBorder
            name="close_hour"
            label='Fecha ás'
            onChange={(e) => setCloseHour(e.target.value)}
            placeholder="20:00"
            mb={theme.pxToRem(12)}
            mask="HOUR_MASK"
            leftElement={<Icon name="Clock" size={16} />}
            disabled={isClosed}
            />
              </Flex>
              
              <Flex
                marginTop={pxToRem(32)}
                width="full"
                maxWidth={!isDesktop && 325}
              >
                <Button
                  kind="primary"
                  size="block"
                  isDisabled={isDisabled}
                  type="submit"
                  isLoading={mutate.isLoading}
                  onClick={handleCreateWorthBarber}
                >
                  Salvar
                </Button>
              </Flex>
          </Flex>
      </ModalMenu>
    </>
  )
}
