import { t } from 'services/translation'
import * as Yup from 'yup'
import { Text } from 'components/atoms'
import { pxToRem } from 'styles/metrics'

export const data = [
  {
    id: 'quantity',
    name: 'quantity',
    type: 'number',
    noBorder: true,
    label: 'QUANTITY_',
    placeholder: 10,
  },
  {
    id: 'cost_price',
    name: 'cost_price',
    type: 'text',
    noBorder: true,
    label: 'COST_PRICE',
    placeholder: '0,00',
    mask: 'CURRENCY_MASK',
    leftElement: (
      <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(40)}>
        {t('CURRENCY')}
      </Text>
    ),
  },
  {
    id: 'sale_price',
    name: 'sale_price',
    type: 'text',
    noBorder: true,
    label: 'SALE_PRICE',
    placeholder: '0,00',
    mask: 'CURRENCY_MASK',
    leftElement: (
      <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(40)}>
        {t('CURRENCY')}
      </Text>
    ),
  },
]

export const validation = Yup.object().shape({
  quantity: Yup.number().required('Quantidade é obrigatório'),
  cost_price: Yup.string().required(t('COST_PRICE_REQUIRED')),
  sale_price: Yup.string().required(t('SALE_PRICE_REQUIRED')),
})

export const initial = {
  quantity: '',
  cost_price: '',
  sale_price: '',
  minimum_quantity: 3,
}
