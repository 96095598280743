import React, { useState, useEffect } from 'react'
import { Box, Text, Button, TextInput } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { useTheme } from '@chakra-ui/react'
import { ModalButtonBackground } from 'components-v2/atoms'
import { SelectCard } from 'components-v2/molecules'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useBarbershopStore } from 'services/stores'
import { useQuery } from 'react-query'
import { Search } from 'assets/icons'

export const SelectClientModal = ({
  isOpen,
  onClose,
  setSelected,
  selected,
}) => {
  const { userData } = useBarbershopStore()
  const [query, setQuery] = useState('')
  const [clients, setClients] = useState([])
  const { getClient } = useBestcoins()
  const theme = useTheme()
  const { t } = useTranslation()

  const { isLoading, data: initialClients } = useQuery(['clients'], () =>
    getClient({
      params: { barbershop_id: userData?.barbershop?.id || userData?.id },
    })
  )

  useEffect(() => {
    if (initialClients) {
      const sortedClients = [...initialClients].sort((a, b) =>
        a?.client?.client?.name.localeCompare(b?.client?.client.name)
      )
      setClients(sortedClients)
    }
  }, [initialClients])

  const filteredClients = clients.filter(
    (client) =>
      client?.client?.name &&
      client?.client?.name.toLowerCase().includes(query.toLowerCase())
  )

  const onSearch = (text) => {
    setQuery(text)
  }

  return (
    <ModalMenu
      isNavigating
      title={'Selecionar cliente'}
      isOpen={isOpen}
      handleBackNavigation={onClose}
    >
      <Box mb={theme.pxToRem(24)}>
        <TextInput
          noBorder
          type="text"
          width="full"
          color="white"
          placeholder={t('SEARCH_CLIENT')}
          backgroundColor="menuBackground"
          value={query}
          onChange={(event) => onSearch(event.target.value)}
          leftElement={<Search color={colors.primary} />}
        />
      </Box>
      <Box>
        {filteredClients.length > 0 ? (
          filteredClients.map((client) => (
            <SelectCard
              key={client?.id}
              client={client}
              onSelect={() => setSelected(client)}
              isSelected={client === selected}
            />
          ))
        ) : (
          <Text>Nenhum cliente encontrado</Text>
        )}
      </Box>
      <ModalButtonBackground>
        <Button
          flex="1"
          h={theme.pxToRem(52)}
          w="100%"
          onClick={onClose}
          disabled={!selected}
        >
          <Text fontSize="md" color={colors.textDark} kind="bold">
            {t('CONFIRM')}
          </Text>
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
