import React, { useState } from 'react'
import { Text, Box, Icon } from 'components/atoms'
import { Flex, useTheme } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { t } from 'services/translation'
import { useHistory } from 'react-router-dom'

export const AppointmentsResumeCard = ({
  last,
  width,
  fontSize,
  wMobile,
  item,
  full,
  mb,
  info,
  h,
  type,
  horizontal,
  infoText,
}) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const settingsAppointments = (item) => {
    return [
      {
        label: 'AWAITING',
        value: item?.total_awaiting,
        color: 'primary',
      },
      {
        label: 'OUTSTANDING',
        value: item?.total_pending,
        color: 'danger',
      },
      {
        label: 'Confirmados',
        value: item?.total_confirmed,
        color: 'blue',
      },
      {
        label: 'Finalizadas',
        value: item?.total_finished,
        color: 'success',
      },
      {
        label: 'Cliente faltante',
        value: item?.total_client_missed,
        color: 'textMedium',
      },
    ]
  }
  return (
    <Flex
      alignItems="flex-start"
      justifyContent="flex-start"
      flexDir="column"
      py={theme.pxToRem(8)}
      px={theme.pxToRem(8)}
      mb={!isDesktop ? theme.pxToRem(16) : theme.pxToRem(mb)}
      mr={!last && theme.pxToRem(16)}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      w={isDesktop ? width || '100%' : wMobile ? theme.pxToRem(wMobile) : full ? 'full' : theme.pxToRem(140)}
      h={!!h ? theme.pxToRem(h) : 'auto'}
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      onClick={() => history.push(info)}
      cursor={info && 'pointer'}
    >
      {show && infoText && (
        <Flex
          marginTop={theme.pxToRem(-60)}
          borderWidth={theme.pxToRem(1)}
          borderColor="white"
          position="fixed"
          bg="border"
          p={theme.pxToRem(8)}
          borderRadius={theme.pxToRem(8)}
        >
          <Text>{infoText}</Text>
        </Flex>
      )}
      <Flex flexDir="row" align="center" mb={theme.pxToRem(8)} justify="space-between" w="100%">
        <Flex w="95%" align="center">
          <Text kind="extraBold" fontSize={theme.pxToRem(fontSize || 20)} color="white">
            {item?.total_appointments}
          </Text>
          <Text ml={theme.pxToRem(6)} fontSize={theme.pxToRem(14)} color="white">
            {t('Comandas no total')}
          </Text>
        </Flex>
        {info && <Icon name="Info" />}
      </Flex>
      <Flex
        flexDir={horizontal ? 'row' : 'column'}
        justify={horizontal && 'space-between'}
        w={horizontal ? '96%' : '100%'}
        flexWrap="wrap"
      >
        {settingsAppointments(item)?.map((item) => (
          <Flex flexDir="row" align="center" mb={theme.pxToRem(horizontal ? 0 : 8)}>
            <Box
              w={theme.pxToRem(8)}
              h={theme.pxToRem(8)}
              borderRadius={theme.pxToRem(8)}
              mr={theme.pxToRem(8)}
              bg={item.color}
            />

            <Text fontSize={theme.pxToRem(12)} color="white">
              {item?.value} {t('comandas')} {t(item?.label)}
            </Text>
            {/* <Flex flexDir="column">
              <Text fontSize={theme.pxToRem(14)} color="white">
                {t(item?.label)}
              </Text>
              <Text fontSize={theme.pxToRem(12)} color="white">
                {item?.value} {t('comandas')} {t(item?.label)}
              </Text>
            </Flex> */}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
