import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalHeader, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { Button, Text, TextInput } from 'components/atoms'
import { formatPrice } from 'utils'
import { format, parseISO } from 'date-fns'

export const RefundConfirmationModal = ({ isOpen, onClose, onClick, loading, payment }) => {
  const theme = useTheme()
  const [description, setDescription] = useState()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {
        <ModalContent
          maxW={theme.pxToRem(700)}
          borderColor="primary"
          borderWidth={1}
          bg="backgroundLayout"
          flexDir="column"
          align="center"
          justify="center"
          p="32px"
          borderRadius="12px"
          maxH={theme.pxToRem(1028)}
        >
          <ModalHeader textAlign="center" w={'100%'}>
            <Flex flexDir="column" w="100%" alignItems="center" justifyContent="center">
              <Text mb="8px" kind="extraBold" fontSize={28} textAlign="center">
                Deseja estornar a cobrança? Essa ação NAO poderá ser desfeita
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mt="8px" color="white" onClick={onClose} />
          <Text mb="8px" kind="bold" fontSize={22} textAlign="center">
            Dados da cobrança
          </Text>
          <Text mb="8px" kind="bold" fontSize={14} textAlign="center">
            Data de vencimento: {payment?.dueDate ? format(parseISO(payment?.dueDate), 'dd/MM/yyyy') : ''}
          </Text>
          <Text mb="8px" kind="bold" fontSize={14} textAlign="center">
            Data de pagamento: {payment?.paymentDate ? format(parseISO(payment?.paymentDate), 'dd/MM/yyyy') : ''}
          </Text>
          <Text mb="8px" kind="bold" fontSize={14} textAlign="center">
            Valor: {formatPrice(payment?.value)}
          </Text>
          <Text mb="8px" kind="medium" fontSize={14} textAlign="left">
            Digite qual o motivo do estorno
          </Text>
          <TextInput
            noBorder
            w="100%"
            mt="12px"
            mb="24px"
            name="description"
            placeHolder="Estorno solicitado pela barbearia"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            h="56px"
          />
          <Flex justify="space-between" mt="24px">
            <Button kind={'secondary'} marginRight="32px" size="medium" onClick={onClose}>
              Cancelar
            </Button>
            <Button kind="danger" size="medium" onClick={() => onClick(description)} isLoading={loading}>
              Estornar
            </Button>
          </Flex>
        </ModalContent>
      }
    </Modal>
  )
}
