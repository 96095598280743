import * as React from 'react'

function SvgShampoo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <path
        fill="currentColor"
        d="M15.38 28.88v23.15c0 .11.1.2.21.2h11.06V28.67H15.59c-.11 0-.21.09-.21.21z"
      />
      <path
        fill="currentColor"
        d="M20.28 17.33H8.44c-3.51 0-6.36 2.85-6.36 6.36v38.46c0 .79.64 1.45 1.44 1.45H25.2c.79 0 1.45-.65 1.45-1.45v-7.87H15.59c-1.24 0-2.25-1.01-2.25-2.24V28.88c0-1.24 1.01-2.25 2.25-2.25h11.06v-2.95c0-3.5-2.86-6.35-6.37-6.35zm6.36-15.17C26.47 2.07 22.35 0 17.42 0c-1.18 0-2.33.13-3.42.36-.02 0-.03 0-.04.01h-.01c-.04.01-.07.03-.11.03-.05.02-.1.03-.15.05-.59.16-1.14.38-1.66.62-.51.23-1.03.46-1.36.48-.13.01-.26.01-.38.01-.48 0-1.03-.08-2.43-1.11-.29-.2-.63-.31-.98-.31-.52 0-1.01.25-1.32.67-.52.72-.37 1.74.36 2.27 1.98 1.45 3.13 1.74 4.36 1.74.19 0 .38-.01.57-.02.65-.03 1.25-.23 1.83-.47l.04 4.97h-2.37v5.98h8.01V9.3h-2.39l-.04-5.96c.47-.05.96-.08 1.45-.08 4.19 0 7.73 1.78 7.77 1.8.23.12.48.18.74.18a1.628 1.628 0 0 0 .75-3.08zm29.67 1.26c-.19-.49-.38-.92-.56-1.3A3.546 3.546 0 0 0 52.51 0H38.2c-.7 0-1.27.57-1.27 1.26v2.71h19.61l-.23-.55zm2.37 6.67c-.46-1.56-.99-3.11-1.55-4.62l-.1-.27h-3.69l-.12.19c-1.4 2.16-3.77 3.44-6.33 3.44s-4.93-1.29-6.33-3.44l-.13-.19h-3.5v5.41h21.91l-.16-.52zM56 38.15c0-10.48-8.18-19.1-18.64-19.65l-.43-.02v39.34l.43-.02C47.81 57.24 56 48.62 56 38.15zM45.55 32.5c.1-.14.3-.23.51-.23.21 0 .41.09.51.23.58.87 3.48 5.26 3.48 7.19 0 2.2-1.78 3.99-3.98 3.99s-3.98-1.79-3.98-3.99c-.01-1.5 1.86-4.82 3.46-7.19z"
      />
      <path
        fill="currentColor"
        d="m59.26 12.13-.08-.3H36.93v5.43l.39.02c11.16.51 19.9 9.68 19.9 20.86S48.48 58.49 37.32 59l-.39.02v3.84c0 .62.5 1.13 1.12 1.13h15.68c.44 0 .84-.25 1.03-.65 9.67-20.22 7.79-39.01 4.5-51.21z"
      />
      <path
        fill="currentColor"
        d="M46.06 42.47c1.52 0 2.76-1.24 2.76-2.77 0-1.16-1.69-4.02-2.42-5.19l-.35-.56-.35.56c-.73 1.17-2.42 4.03-2.42 5.19.02 1.52 1.26 2.77 2.78 2.77zm.83-34.87c1.6 0 3.13-.6 4.3-1.7l.75-.71H41.83l.75.71a6.28 6.28 0 0 0 4.31 1.7z"
      />
    </svg>
  )
}

export default SvgShampoo
