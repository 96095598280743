import { api } from 'services/api/config'

export const useSignature = () => {
  const createSignature = async (props) => {
    const response = await api.post(`v3/client/create-subscription?barbershop_id=${props?.barbershop_id}`, props?.body)
    return response.data
  }

  const getAllSignatures = async (props) => {
    const response = await api({
      url: `v3/client/get-all-subscriptions/${props?.barbershop_id}?skip=${props?.skip}&name=${props?.name}`,
    })
    return response.data
  }

  const getAllSubscriptionPayments = async (subscription_id) => {
    const response = await api({
      url: `v3/client/get-subscription-payments/${subscription_id}`,
    })
    return response.data
  }

  const changeToReceivedInCash = async (props) => {
    const response = await api.post(`v3/client/received-in-cash`, props?.body)
    return response.data
  }

  const getPaymentDetails = async (payment_id) => {
    const response = await api({
      url: `v3/payment/payment-details/${payment_id}`,
    })
    return response.data
  }

  return {
    getPaymentDetails,
    changeToReceivedInCash,
    getAllSubscriptionPayments,
    getAllSignatures,
    createSignature,
  }
}
