import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useTheme, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { authStore } from 'services/stores/auth'

import {
  Button,
  Link,
  Icon,
  Text,
  TextInput,
  Flex,
  Logo,
} from 'components/atoms'

import { LOGIN_SCREEN_STEPS as SCREEN_STEPS } from 'constants/login'
import { colors } from 'styles/colors'
import { forgotPasswordInputs } from './settings'

import { api } from 'services/api/config'
import { CHECK_PHONE, UPDATE_EMAIL } from 'services/api/endpoints'
import { useMutation } from 'react-query'

export const LoginForgotPasswordForm = (props) => {
  const theme = useTheme()
  const toast = useToast()
  const { isMobile } = useBreakpoint()
  const { t } = useTranslation()
  const { setUserDataChangePassword } = authStore()

  const { values, setFieldValue, setValues } = useFormik({
    initialValues: {
      checked: false,
      user: null,
      email: '',
      phone: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email(8, t('INVALID_TOKEN')),
      phone: Yup.string().min(11, t('INVALID_PHONE')),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  })

  const routes = () => {
    if (values.checked) {
      return {
        method: 'put',
        endpoint: UPDATE_EMAIL,
        key: 'update-email',
        onSuccess: () => {
          setUserDataChangePassword(values)
          props?.setValues(values)
          props?.changeSelectedStep(SCREEN_STEPS.RESET_PASSWORD)
        },
      }
    } else {
      return {
        method: 'post',
        endpoint: CHECK_PHONE,
        key: 'check-phone',
        onSuccess: (data) => {
          setValues({
            checked: true,
            user: data?.user,
            email: data?.user?.email,
            phone: props?.params?.phone,
          })
        },
      }
    }
  }

  const onSubmit = () => {
    if (values.checked) {
      const params = { email: values.email, userId: values.user?.id }
      mutate.mutate(params)
      return
    }
    const params = { phone: values.phone }
    mutate.mutate(params)
  }

  async function fetch(params) {
    const res = await api[routes().method](routes().endpoint, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('login', (params) => fetch(params), {
    onSuccess: (data) => {
      routes().onSuccess(data)
    },
    onError: (error) => {
      toast({
        title: t('USER_NOT_FOUND'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const disabled = useMemo(() => {
    if (values.checked) return !values.email || mutate.isLoading

    return !values.phone
  }, [values, mutate.isLoading])
  return (
    <Flex
      align={isMobile ? 'center' : 'flex-start'}
      justifyContent="center"
      flexDir="column"
      w={isMobile ? '100%' : theme.pxToRem(520)}
      py={theme.pxToRem(48)}
      px={theme.pxToRem(isMobile ? 24 : 48)}
      borderRadius={theme.pxToRem(isMobile ? 0 : 24)}
    >
      <Logo />
      <Text
        kind="extraBold"
        textAlign={isMobile ? 'center' : null}
        fontSize={theme.pxToRem(24)}
        mt={theme.pxToRem(24)}
        mb={theme.pxToRem(24)}
      >
        {!values?.checked ? t('RESET_PASSWORD') : t('ADD_EMAIL')}
      </Text>
      <Text kind="semiBold">{t('RESET_PASSWORD_DESC')}</Text>
      <Text kind="semiBold" textAlign={isMobile ? 'center' : null}>
        {!values?.checked ? t('ENTER_THE_PHONE') : t('ADD_EMAIL_DESC')}
      </Text>
      <TextInput
        noBorder
        mask={forgotPasswordInputs[!values?.checked ? 1 : 0]?.mask}
        type={forgotPasswordInputs[!values?.checked ? 1 : 0].type}
        name={!values?.checked ? 'phone' : 'email'}
        placeholder={!values?.checked ? t('PHONE') : 'Email'}
        mt={theme.pxToRem(24)}
        value={values[forgotPasswordInputs[!values?.checked ? 1 : 0].name]}
        onChange={(ev) =>
          setFieldValue(
            forgotPasswordInputs[!values?.checked ? 1 : 0].name,
            ev.target.value
          )
        }
        disabled={mutate.isLoading}
        paddingLeft={theme.pxToRem(50)}
        leftElement={
          <Icon
            name={!values?.checked ? 'Phone' : 'Email'}
            mr={theme.pxToRem(20)}
            ml={theme.pxToRem(8)}
          />
        }
      />
      <Flex w="100%" flexDir="column" align="center">
        <Button
          isLoading={mutate.isLoading}
          w="100%"
          disabled={mutate.isLoading}
          onClick={onSubmit}
          mt={theme.pxToRem(24)}
        >
          {t('REDEFINE_PASSWORD_BUTTON')}
        </Button>
        <Link
          onClick={() => props.changeSelectedStep(SCREEN_STEPS.LOGIN)}
          mt={theme.pxToRem(24)}
          mb={theme.pxToRem(-8)}
        >
          <Text
            kind="bold"
            fontSize={theme.pxToRem(14)}
            as="u"
            textDecoration="none"
            color={colors.primary}
          >
            {t('CANCEL')}
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}
