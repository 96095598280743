import React from 'react'
import PropTypes from 'prop-types'
import { Flex, useTheme } from '@chakra-ui/react'
import { Text, Button, Icon } from 'components/atoms'
import { t } from 'services/translation'

export const Header = ({
  headerTitle,
  subTitle,
  headerActionText,
  headerActionClick,
  secondActionText,
  secondActionClick,
  secondLoading,
  headerCenterElement,
  px,
  iconColor,
  buttonWidth,
  pxSecondAction,
  iconActionText,
  iconSecondActionText,
  handleBackNavigation,
  premiumBlockMessage,
  thirtyClick,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      h={theme.pxToRem(64)}
      mb={theme.pxToRem(headerCenterElement ? 24 : 12)}
      {...props}
    >
      <Flex
        flexDir="column"
        // w="33%"
        h="100%"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Text kind="extraBold" fontSize={theme.pxToRem(24)}>
          {headerTitle}
        </Text>
        {subTitle && (
          <Text kind="bold" fontSize={theme.pxToRem(16)}>
            {subTitle}
          </Text>
        )}
        {handleBackNavigation && (
          <Button size="square" kind="ghost" onClick={handleBackNavigation}>
            <Flex
              align={'center'}
              ml={theme.pxToRem(32)}
              mt={theme.pxToRem(10)}
            >
              <Icon name="ArrowLeft" color="primary" />
              <Text
                ml={theme.pxToRem(16)}
                kind="normal"
                fontSize={theme.pxToRem(16)}
              >
                {t('BACK')}
              </Text>
            </Flex>
          </Button>
        )}
      </Flex>

      <Flex
        flexDir="column"
        // w="33%"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        {headerCenterElement && headerCenterElement()}
      </Flex>

      <Flex
        flexDir="column"
        h="100%"
        // w={secondActionClick ? '36%' : '33%'}
        alignItems="flex-end"
        justifyContent={'center'}
      >
        <Flex w="full" mr={secondActionClick ? '4%' : thirtyClick ? '5%' : 0} >
          {headerActionText && (
            <Button
              icon={iconActionText || "Plus"}
              size="small"
              iconColor="black"
              onClick={headerActionClick}
            >
              {headerActionText}
            </Button>
          )}
          {secondActionClick && ( 
            <Button
              icon={iconSecondActionText}
              iconColor={iconColor}
              ml={secondActionClick && '2%'}
              size="small"
              onClick={secondActionClick}
              isLoading={secondLoading}
            >
              {secondActionText}
            </Button>
          )}
           {thirtyClick && (
            <Button
              icon={thirtyClick?.icon}
              iconColor={thirtyClick?.iconColor}
              ml={thirtyClick && '2%'}
              size="small"
              onClick={thirtyClick?.onClick}
              isLoading={thirtyClick?.isLoading}
            >
              {thirtyClick?.text}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

Header.propTypes = {
  headerTitle: PropTypes.string,
  headerCenterElement: PropTypes.func,
  headerActionText: PropTypes.string,
  headerActionClick: PropTypes.func,
}
