import React, { useState } from 'react'
import { Box, Flex, Text, Button } from 'components/atoms'
import { SelectCard } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useBarbershopStore } from 'services/stores'
import { useMutation } from 'react-query'
import { useBarbershop } from 'services/hooks'
import { t } from 'services/translation'

export const AvailableTimesConfig = () => {
  const { userData, setUserData } = useBarbershopStore()
  const {changeConfigAvailable} = useBarbershop()
  const [values, setValues] = useState(userData?.config_available)

  const mutate = useMutation(
    'change-config-available',
    (params) => changeConfigAvailable(params),
  )

  const handleFormSubmit = async () => {
    const params = {
        config_available: values,
      barbershop_id: userData?.barbershop?.id || userData?.id
    }
    mutate.mutate({params, onSuccess: (data) => setUserData({...userData, config_available: data?.config_available})})
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box flex="1">
        <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
          Configuração de horários disponíveis para agendar
        </Text>

        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          Determine como os horários disponíveis para agendamentos serão calculados:
        </Text>

       <SelectCard item={{
        title: 'Horários inteligentes',
        description: 'Os horários são calculados conforme o tempo individual de cada serviço.'
       }}
       selected={values === 'inteligent_interval'}
       setSelected={() => setValues('inteligent_interval')}
       mb='16px'
       borderWidth={2}
       borderColor='grey'
       />
       <SelectCard item={{
        title: 'Intervalo da agenda',
        description: 'Os horários são calculados conforme o intervalo da agenda definido no perfil da barbearia.'
       }}
       selected={values === 'time_interval'}
       setSelected={() => setValues('time_interval')}
       borderWidth={2}
       borderColor='grey'
       />

      </Box>
      <Button
        disabled={mutate.isLoading}
        isLoading={mutate.isLoading}
        onClick={handleFormSubmit}
        mt={pxToRem(32)}
        size="block"
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Flex>
  )
}
