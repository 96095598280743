import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { Link, Avatar, Table, Text, NewBarbershopsChart } from 'components'
import { Flex, useToast } from '@chakra-ui/react'
import { theme } from 'styles'
import { Layout } from '../Components'

import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { APPOINTMENTS_PAYMENT_METHODS_RESUME } from 'services/api/endpoints'

const columns = [
  {
    key: 'image',
    label: '',
    render: (item) => <Avatar src={item?.profile_image_url} size="medium" />,
  },
  {
    width: 120,
    key: 'name',
    label: 'NOME',
    render: (item) => <Text>{item?.name}</Text>,
  },
  {
    width: 140,
    key: 'phone',
    label: 'TELEFONE',
    render: (item) => (
      <Link
        href={`https://wa.me/${item?.user?.phone.replace(/[^\w]/gi, '')}`}
        isExternal
      >
        {item?.user?.phone}
      </Link>
    ),
  },
  {
    width: 260,
    key: 'email',
    label: 'EMAIL',
    render: (item) => <Text>{item?.user?.email}</Text>,
  },
  {
    width: 140,
    key: 'status',
    label: 'STATUS',
    render: (item) => <Text>{item?.status}</Text>,
  },
  {
    width: 100,
    key: 'createdAt',
    label: 'CADASTRO',
    render: (item) => (
      <Text>{format(parseISO(item?.createdAt), 'dd/MM/yyyy')}</Text>
    ),
  },
  {
    width: 100,
    key: 'createdAt',
    label: 'ÚLTIMO ACESSO',
    render: (item) => (
      <Text>
        {item?.user?.last_login
          ? format(parseISO(item?.user?.last_login), 'dd/MM/yyyy')
          : '-'}
      </Text>
    ),
  },
]

const active_columns = [
  {
    key: 'type',
    label: 'TIPO',
  },
  {
    key: 'total',
    label: 'Ativos',
  },
  {
    key: 'percentage',
    label: '%',
  },
]

export const AppointmentsPaymentMethods = () => {
  const toast = useToast()

  async function getData() {
    const res = await api
      .get(APPOINTMENTS_PAYMENT_METHODS_RESUME)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, refetch, isLoading } = useQuery(
    'get-payment-methods-resume',
    getData,
    {
      onSuccess: (value) => {
      },
      onError: (error) => {},
    }
  )

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout title="Novos Usuários Cadastrados">
      {data?.chartData && (
        <NewBarbershopsChart
          mt={theme.pxToRem(12)}
          chartData={data?.chartData}
          total={data?.total}
        />
      )}
      {data?.detailsChartData && (
        <NewBarbershopsChart
          mt={theme.pxToRem(12)}
          chartData={data?.detailsChartData}
          total={data?.total}
        />
      )}
      {/* <Table
        isSelectableRow
        onSelect={(item) => setModalOpen(item)}
        data={activeData?.search}
        columns={columns}
      /> */}
    </Layout>
  )
}

AppointmentsPaymentMethods.path =
  '/internal-adm/reports/payment-methods/all-appointments'
AppointmentsPaymentMethods.title = 'Agendamentos - Métodos de pagamento'
AppointmentsPaymentMethods.secured = false
