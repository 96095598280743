import React, { useState } from 'react'
import { Button, Text, TextInput } from 'components'
import { Box, useToast, Flex, useTheme } from '@chakra-ui/react'
import { DeleteServiceModal, Layout, SelectServiceMethod, UpdateInfos, UpdateValueModal } from '../Components'
import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { formatPrice, WEEK_DAYS } from 'utils'
import { t } from 'services/translation'

export const PlansManagement = () => {
  const [barbershopId, setBarbershopId] = useState(null)
  const [enabled, setEnabled] = useState(false)
  const [addServiceOpen, setAddServiceOpen] = useState(false)
  const [deleteServiceOpen, setDeleteServiceOpen] = useState(false)
  const [updateServiceOpen, setUpdateServiceOpen] = useState(false)
  const [updateInfosOpen, setUpdateInfosOpen] = useState(false)
  const [plan, setPlan] = useState(null)
  const toast = useToast()
  const theme = useTheme()

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['get-plans-barbershop'],
    () => (barbershopId ? api.get(`v3/barbershop/plans/${barbershopId}`) : null),
    {
      onError: (error) => {
        toast({
          title: 'Erro ao buscar planos',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Layout title="Gerenciar planos">
      <Flex align="center" justify="center" flexDir="column" w="100%" h="100%" p="60px">
        <Flex flexDir="column" mr={theme.pxToRem(16)}>
          <Flex flexDir="row" mb={theme.pxToRem(12)}>
            <TextInput
              type="number"
              noBorder
              name="id"
              label="Id da barbearia"
              value={barbershopId}
              w={theme.pxToRem(370)}
              onChange={(event) => setBarbershopId(event?.target?.value)}
            />
            <Button
              isLoading={isLoading || isRefetching}
              mt={theme.pxToRem(26)}
              ml={theme.pxToRem(8)}
              w={theme.pxToRem(60)}
              onClick={() => {
                refetch()
              }}
              icon="Search"
              iconColor={'cardBackground'}
            />
          </Flex>
        </Flex>
        {data?.data?.plans?.map((item) => {
          return (
            <Flex
              flexDir="column"
              p={4}
              align="flex-start"
              borderRadius={8}
              mt={2}
              borderColor={'border'}
              borderWidth={1}
            >
              <Flex flexDir="column">
                <Text>{item?.name}</Text>
                <Text fontSize={12}>
                  ID do plano: {item?.id} | Limitado: {item?.limited ? 'Sim' : 'Não'} | Valor:{' '}
                  {formatPrice(item?.value)} | Comissão: {item?.comission}% | Visível:{' '}
                  {item?.visible_for_clients ? 'Sim' : 'Não'} | Dias:{' '}
                  {!item?.days
                    ? 'Todos os dias'
                    : item?.days?.map((day) => t(WEEK_DAYS.find((i) => i.id === day)?.min)).join(', ')}
                </Text>
              </Flex>
              <Flex mt={4}>
                <Button
                  kind="primary"
                  onClick={() => {
                    setPlan(item)
                    setUpdateServiceOpen(true)
                  }}
                  size="x-small-auto"
                >
                  Alterar valor
                </Button>
                <Button
                  ml={1}
                  kind="primary"
                  onClick={() => {
                    setPlan(item)
                    setAddServiceOpen(true)
                  }}
                  size="x-small-auto"
                >
                  Adicionar serviço
                </Button>
                <Button
                  ml={1}
                  kind="danger"
                  onClick={() => {
                    setPlan(item)
                    setDeleteServiceOpen(true)
                  }}
                  size="x-small-auto"
                >
                  Remover serviço
                </Button>
                <Button
                  ml={1}
                  kind="primary"
                  onClick={() => {
                    setPlan(item)
                    setUpdateInfosOpen(true)
                  }}
                  size="x-small-auto"
                >
                  Alterar pontuação, comissão e qtd
                </Button>
              </Flex>
            </Flex>
          )
        })}
      </Flex>

      <SelectServiceMethod isOpen={addServiceOpen} onClose={() => setAddServiceOpen(false)} plan={plan} />
      <DeleteServiceModal isOpen={deleteServiceOpen} onClose={() => setDeleteServiceOpen(false)} plan={plan} />
      <UpdateValueModal isOpen={updateServiceOpen} onClose={() => setUpdateServiceOpen(false)} plan={plan} />
      <UpdateInfos isOpen={updateInfosOpen} onClose={() => setUpdateInfosOpen(false)} plan={plan} />
    </Layout>
  )
}

PlansManagement.path = '/internal-adm/planos'
PlansManagement.title = 'Gerenciar planos'
PlansManagement.secured = true
