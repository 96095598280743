import * as React from 'react'

function SvgStarOutline(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 19.535l-7.014 3.687 1.34-7.81-5.674-5.53 7.841-1.14L13 1.638l3.507 7.106 7.84 1.139-5.673 5.53 1.34 7.81L13 19.535z"
        stroke="currentColor"
        strokeWidth={1.364}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgStarOutline
