import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthLayout } from 'components-v2/organisms/AuthLayout'
import { TextInput, Icon } from 'components/atoms'
import { t } from 'services/translation'
import { useResetPassword } from 'services/hooks'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'

export const ResetPasswordCheckPhone = () => {
  const [phone, setPhone] = useState('')
  const history = useHistory()
  const toast = useToast()

  const { phoneCheck } = useResetPassword()

  const mutation = useMutation(
    'reset-password-phone-check',
    () => phoneCheck({ phone }),
    {
      onSuccess: (data) => {
        toast({
          title: t('Chave de acesso enviada com sucesso!'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/reset-password/new-password', { data })
      },
      onError: (error) => {
        toast({
          title: t('USER_NOT_FOUND'),
          description:
            error?.response?.data?.error || 'Por favor, tente novamente',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <AuthLayout
      title="Redefinir senha"
      subtitle="Esqueceu sua senha? Tranquilo! Informe o celular utilizado no seu cadastro que lhe ajudaremos a definir uma nova senha."
      button={{
        text: 'Confirmar',
        onClick: () => mutation.mutate(),
        disabled: phone === '',
        isLoading: mutation.isLoading,
      }}
      link={{
        text: 'Cancelar',
        onClick: () => history.push('/login'),
      }}
    >
      <TextInput
        noBorder
        mask={'PHONE_MASK'}
        type={'phone'}
        name={'phone'}
        placeholder={t('PHONE')}
        mt={4}
        mb={4}
        value={phone}
        onChange={(ev) => setPhone(ev.target.value)}
        leftElement={<Icon name={'Phone'} mr={10} ml={10} />}
      />
    </AuthLayout>
  )
}
ResetPasswordCheckPhone.path = '/reset-password/check-phone'
ResetPasswordCheckPhone.title = 'Esqueceu senha'
ResetPasswordCheckPhone.secured = false
