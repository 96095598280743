import React from 'react'
import { Text } from 'components/atoms'
import { useTheme, Flex } from '@chakra-ui/react'

export const MeetUsCard = ({ item, select, onClick, ...props }) => {
  const theme = useTheme()

  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      p={theme.pxToRem(10)}
      mb={theme.pxToRem(16)}
      h={theme.pxToRem(60)}
      w="100%"
      onClick={onClick}
      cursor="pointer"
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor={select ? 'primary' : 'modalBorder'}
      {...props}
    >
      <Flex w="100%" flexDir="row" alignItems="center" justifyContent="center">
        <Flex w="100%" alignItems="center" justifyContent="center">
          <Text kind="bold" color="textLight" fontSize={theme.pxToRem(14)}>
            {item?.name}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
