import React, { useState, useEffect } from 'react'
import { useTheme } from '@chakra-ui/react'
import { Box, Flex, Icon, Text, Button } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { colors } from 'styles'
import { useBarbershopStore } from 'services/stores'
import { BARBERSHOP_STATUSES, getCurrentStatus } from 'utils/status'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useBarbershop } from 'services/hooks/use-barbershop'

export const StatusModal = ({ isModalOpen, onClose }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { userData, setUserData } = useBarbershopStore()
  const [status, setStatus] = useState(getCurrentStatus(userData))
  const { updateStatus } = useBarbershop()

  const mutate = useMutation(
    'update-status',
    () =>
      updateStatus({
        status: status.action,
        id: userData?.barbershop?.id || userData?.id,
      }),
    {
      onSuccess: (data) => {
        setUserData({ ...userData, status: data.status })
        onClose()
      },
    }
  )

  useEffect(() => {
    setStatus(getCurrentStatus(userData))
  }, [isModalOpen])

  const disabled = (item) => {
    return item.status === 'blocked' || item.status === 'ready_to_publish'
  }

  return (
    <ModalMenu title={t('SET_STATUS')} isOpen={isModalOpen} onClose={onClose}>
      <Flex h="100%" flexDirection="column">
        <Box flex="1">
          <Text
            colors={colors.white}
            textTransform="uppercase"
            fontSize={theme.pxToRem(12)}
            fontWeight="bold"
            mt={2}
          >
            {t('CHOOSE_STATUS')}
          </Text>

          {BARBERSHOP_STATUSES.map((item) => (
            <Box
              key={t(item.label)}
              fontSize={theme.pxToRem(13)}
              fontWeight="bold"
              my={6}
            >
              <Flex
                cursor="pointer"
                onClick={() => !disabled(item) && setStatus(item)}
              >
                {status.status === t(item.status) ? (
                  <Icon name="CheckCircle" size={20} />
                ) : (
                  <Box
                    borderRadius={100}
                    border={`1px solid ${
                      disabled(item) ? colors.textMedium : colors.white
                    }`}
                    w={5}
                    h={5}
                  />
                )}
                <Text
                  color={disabled(item) ? 'textMedium' : 'textLight'}
                  ml={2}
                >
                  {t(item.label)}
                </Text>
              </Flex>
              <Text mt={2.5}>{t(item.description)}</Text>
            </Box>
          ))}
        </Box>

        <Button
          w="100%"
          disabled={mutate.isLoading || status === getCurrentStatus(userData)}
          mb={theme.pxToRem(24)}
          onClick={() => mutate.mutate()}
        >
          {t('SAVE')}
        </Button>
      </Flex>
    </ModalMenu>
  )
}
