import * as React from 'react'

function SvgArrowUpCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25} cy={25} r={20} fill="currentColor" />
      <path
        d="M25 17.416v15.167M25 17.416l-4.333 4.334M25 17.416l4.333 4.334"
        stroke="#20242A"
        strokeWidth={2.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgArrowUpCircle
