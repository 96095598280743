import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useToast, useTheme } from '@chakra-ui/react'
import { Box, Button, CheckBox, Flex, Text } from 'components/atoms'
import { ModalMenu, Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useBarbershopStore } from 'services/stores'
import { inputs, initial, validation } from './settings'

import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { SEND_NOTIFICAITON } from 'services/api/endpoints'
import { t } from 'services/translation'

export const SendAdviceModal = ({ isModalOpen, onClose, clients }) => {
  const toast = useToast()
  const theme = useTheme()
  const { userData } = useBarbershopStore()
  const [values, getValues] = useState({
    params: initial,
    isValid: false,
  })
  const [allUnities, setAllUnities] = useState(false)

  async function sendNotification() {
    const pushs = []

    if (clients) {
      clients.forEach((client) => {
        if (client?.client?.user?.push_uids) {
          pushs.push(...client?.client?.user?.push_uids)
        }
      })
    }
    const res = await api
      .post(SEND_NOTIFICAITON(userData?.barbershop?.id || userData?.id, allUnities), {
        ...values?.params,
        clients: clients ? pushs : null,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('send-notification', sendNotification, {
    onSuccess: async (value) => {
      toast({
        title: t('SEND_NOTIFICATION_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClose()
      return
    },
    onError: (error) => {
      toast({
        title: t('SEND_NOTIFICATION_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const isButtonDisabled = useMemo(() => values?.params?.message < 10)

  return (
    <ModalMenu isOpen={isModalOpen} onClose={onClose} title={t('SEND_NOTIFICATION')}>
      <Box marginBottom={pxToRem(200)}>
        <Text align="center" kind="semiBold" mb={theme.pxToRem(16)}>
          {t('SEND_NOTIFICATION_TITLE')}
        </Text>
        <Form
          mb={32}
          initialValues={values?.params}
          data={inputs}
          validationSchema={validation}
          getValues={getValues}
        />
        {clients && clients?.length > 0 && (
          <Text align="center" kind="semiBold" mb={theme.pxToRem(16)}>
            Enviando notificação para os clientes selecionados
          </Text>
        )}

        {!clients && (
          <>
            <Flex mt={pxToRem(16)} alignItems="center">
              <CheckBox
                onChange={(event) => {
                  setAllUnities(event?.target?.checked)
                }}
                isChecked={allUnities}
                borderColor={!allUnities ? 'textMedium' : 'primary'}
                color="primary"
                size="lg"
              />

              <Text ml={pxToRem(8)} fontWeight="bold" fontSize="small" color={!allUnities ? 'textMedium' : 'textLight'}>
                Enviar para os clientes de todas as unidades?
              </Text>
            </Flex>
            <Text mt="12px" fontSize="12px" fontWeight="regular" color="textGrey">
              Dessa forma enviará apenas uma vez mesmo que o cliente seja cadastrado em mais de uma unidade.
            </Text>
          </>
        )}
      </Box>

      <Flex marginTop="auto">
        <Button
          w="100%"
          type="submit"
          onClick={() => mutate.mutate()}
          isLoading={mutate.isLoading}
          disabled={isButtonDisabled}
        >
          {t('SEND')}
        </Button>
      </Flex>
    </ModalMenu>
  )
}

SendAdviceModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}
