import React from 'react'
import { Box, Button, Loader } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { SelectCard } from 'components-v2/molecules'
import { theme } from 'styles/theme'
import { useBarbershopStore } from 'services/stores'
import { useQuery } from 'react-query'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { ModalButtonBackground } from 'components-v2/atoms'

export const SelectProductModal = ({
  isOpen,
  onClose,
  selected,
  setSelected,
}) => {
  const { getAdditionalProducts } = useBarbershop()

  const { userData } = useBarbershopStore()
  const { t } = useTranslation()

  const { data, isLoading } = useQuery('get-additional-products', () =>
    getAdditionalProducts({ id: userData?.id })
  )

  return (
    <ModalMenu
      title={'Selecionar um produto'}
      isOpen={isOpen}
      onClose={() => onClose()}
      isNavigating
      handleBackNavigation={onClose}
    >
      {isLoading ? (
        <Loader />
      ) : (
        data?.map((product) => (
          <SelectCard
            key={product?.id}
            product={product}
            onSelect={() => setSelected(product)}
            isSelected={product === selected}
          />
        ))
      )}
      <ModalButtonBackground>
        <Button disabled={!selected} w="100%" onClick={() => onClose()}>
          {t('CONFIRM')}
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
