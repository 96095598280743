import { Text } from "components/atoms"
export const columnsSignature = [
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <Text w="100%" kind="bold" color="textLight">
          {item?.name}
        </Text>
      ),
    },
    {
      key: 'price',
      label: 'VALUES',
      render: (item) => (
        <Text w="100%" kind="bold" color="textLight">
          Serviço pelo clube
        </Text>
      ),
    },
    {
      key: 'time_required',
      label: 'LENGTH',
      render: (item) => <Text>{item?.service?.time_required?.substring(0, 5)}</Text>,
    },
  ]