import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/react'
import { Box, Button, Flex, Text } from 'components/atoms'
import { ModalMenu, Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { useHistory } from 'react-router-dom'
import { inputs, initial, validation } from './settings'

import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { CREATE_UNITY } from 'services/api/endpoints'
import { saveLoginData } from 'services/hooks'
import { ModalAlert } from 'components/molecules'

export const UnityModal = ({ isModalOpen, onClose, length }) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData, setData } = useBarbershopStore()
  const [values, getValues] = useState({
    params: initial,
    isValid: false,
  })
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)

  async function addUnity({ params }) {
    const p = {
      owner_name: userData?.owner_name,
      iugu_subscription_id: userData?.iugu_subscription_id,
      iugu_plan_id: userData?.iugu_plan_id,
      iugu_client_id: userData?.iugu_client_id,
      identifier_doc: userData?.identifier_doc,
      secondary_identifier_doc: userData?.secondary_identifier_doc,
      ...params,
    }
    const res = await api
      .post(CREATE_UNITY, p)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('add-team', (params) => addUnity(params), {
    onSuccess: async (value) => {
      const { user, userData, token, subscription, customer } = value
      const toSaveUserData = userData.barbershop
        ? userData.barbershop
        : userData
      saveLoginData({ user, userData: toSaveUserData, token })
      setData(user, toSaveUserData, subscription, customer)
      toast({
        title: t('ADD_UNITY_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      history.push('/cadastro/perfil-barbearia')
      return
    },
    onError: (error) => {
      toast({
        title: t('ADD_BARBER_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const isButtonDisabled = useMemo(
    () =>
      mutate?.isLoading ||
      values?.params?.name === '' ||
      values?.params?.phone === ''
  )

  return (
    <ModalMenu isOpen={isModalOpen} onClose={onClose} title={t('ADD_UNITY')}>
      <Box marginBottom={pxToRem(200)}>
        <Form
          mb={32}
          initialValues={values?.params}
          data={inputs}
          validationSchema={validation}
          getValues={getValues}
        />
      </Box>

      <Flex marginTop="auto">
        <Button
          w="100%"
          type="submit"
          onClick={() => {
            if(userData?.iugu_plan_id?.substring(0, 10) === 'whitelabel') {
              window.open(`https://wa.me/5531971105643?text=Olá%2C%20gostaria%20de%20adicionar%20a%20unidade%20${values?.params?.name}%20de%20telefone%20${values?.params?.name}%20no%20BestBarbers`)
            return
            }
            setModalOpen(true)}}
          loading={mutate.isLoading}
          disabled={isButtonDisabled}
        >
          {t('ADD_UNITY')}
        </Button>
      </Flex>

      <ModalAlert
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() =>
          mutate.mutate({
            params: {
              ...values?.params,
            },
          })
        }
        primaryButtonLabel={t('CREATE')}
        modalTitle={t('CREATE_UNITY_TITLE')}
      >
        <Text align="center" fontWeight="bold" m={`${pxToRem(16)} 0`}>
          {`${t('CREATE_UNITY_SUB_1')} ${
            length >= 2 ? 'R$49,90' : 'R$59,90'
          } ${t('CREATE_UNITY_SUB_2')}`}
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}

UnityModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}
