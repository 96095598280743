import React from 'react'
import { Text, Icon, Button, Box } from 'components/atoms'
import { ModalMenu, Table } from 'components/molecules'
import { Flex, useTheme, useToast } from '@chakra-ui/react'

import { useQuery } from 'react-query'
import { t } from 'services/translation'
import { usePaymentMethod } from 'services/hooks/use-payment-method'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'percentage',
    label: 'PERCENTAGE',
    render: (item) => <Text>{item?.id === 'comission_barber' ? 'Não se aplica' : `${item.percentage}%`}</Text>,
  },
  { key: 'due_in_days', label: 'DUE_IN_DAYS' },
]

export const SelectPaymentMethod = ({ isOpen, onClose, payments, setPayments, ...props }) => {
  const toast = useToast()
  const {getPaymentMethods} = usePaymentMethod()
  const theme = useTheme()

  const { data, isLoading } = useQuery(
    'get-payment-methods',
    getPaymentMethods,
    {
      onError: (error) => {
        toast({
          title: t('LOAD_PAYMENT_METHODS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleSelectPaymentMethod = (pm) => {
      if (payments?.includes(pm)) {
        const updateItems = payments?.filter((i) => i !== pm)
        setPayments(updateItems)
      } else {
        setPayments([...payments, pm])
      }
  }

  return (
    <ModalMenu 
    isOpen={isOpen}
      onClose={
        onClose
      }
      title={t('SELECT_PAYMENT_METHOD')}>
      <Box>
        <Table
          withoutHeader
          isMultiSelectable
          isSelectableRow
          data={data?.filter((i) => i?.name !== 'Pagamento pelo app') || []}
          loading={isLoading}
          columns={columns}
          onSelect={handleSelectPaymentMethod}
          selectedList={payments}
        />
        <Button size="block" mt={theme.pxToRem(24)} onClick={onClose}>
          {t('CONFIRM')}
        </Button>
      </Box>
    </ModalMenu>
  )
}
