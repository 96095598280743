import React, { useState, useMemo, useEffect } from 'react'
import { format } from 'date-fns'
import {
  Layout,
  SelectDateModal,
  SelectDateButton,
  Table,
  Text,
  AppointmentDetailsModal,
  HistoryList,
  Button,
  SelectFilter,
  TextInput,
} from 'components'
import { useToast, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { useAppointmentDetailStore, useBarbershopStore } from 'services/stores'
import { useBarbershop } from 'services/hooks'
import {
  formatAppointmentTime,
  formatAppointmentStatus,
  formatPrice,
} from 'utils'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'react-query'
import { api } from 'services/api/config'
import {
  GET_APPOINTMENTS_BY_DATE,
  GET_RECURRENT_APPOINTMENTS,
} from 'services/api/endpoints'
import { Search } from 'assets/icons'
import { colors } from 'styles'


const columns = [
  {
    key: 'personalized_id',
    label: 'ID PERSONALIZADO',
    render: (item) => <Text kind="medium">{item?.personalized_id}</Text>,
  },
  {
    key: 'time',
    label: 'TIME_TABLE',
    render: (item) => <Text kind="medium">{formatAppointmentTime(item)}</Text>,
  },
  {
    key: 'client',
    label: 'CLIENT_TABLE',
    render: (item) => <Text kind="medium">{item?.client?.name}</Text>,
  },
  {
    key: 'barber',
    label: 'BARBER_TABLE',
    render: (item) => <Text kind="medium">{item?.barber?.name}</Text>,
  },
  {
    key: 'service',
    label: 'SERVICE_TABLE',
    render: (item) => (
      <Text
        kind="medium"
        maxW="120px"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {item?.product?.map((i) => i?.name).join(', ') || item?.service?.name}
      </Text>
    ),
  },
  {
    key: 'status',
    label: 'STATUS_TABLE',
    render: (item) => (
      <Text
        kind="bold"
        color={
          item?.status === 'pending'
            ? 'danger'
            : item?.status === 'awaiting'
            ? 'primary'
            : item?.status === 'client_missed'
            ? 'textMedium'
            : item?.status === 'confirmed'
            ? 'blue'
            : !item?.status
            ? 'textMedium'
            : item?.status === 'client_arrived' ? 'orange' : 'success'
        }
      >
        {formatAppointmentStatus(item)}
      </Text>
    ),
  },
]

export const CommandScreen = (props) => {
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [barberFilter, setBarberFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState(null)
  const [query, setQuery] = useState()
  const [clients, setClients] = useState([])
  const [recurrentFilter, setRecurrentFilter] = useState(false)
  // const [filteredData, setFilteredData] = useState([])
  const [app, setApp] = useState()

  const [selectedDate, setSelectedDate] = useState(new Date())
  const { userData } = useBarbershopStore()
  const { getBarbers, getClients } = useBarbershop()

  const { t } = useTranslation()

  const onCloseModals = () => {
    setDetailsModalVisible(false)
    setApp(null)
    refetch()
  }

  const onSelectAppointment = (appointment) => {
    setApp(appointment)
    setDetailsModalVisible(true)
  }

  async function getAppointments({ queryKey }) {
    const res = await api
      .get(
        GET_APPOINTMENTS_BY_DATE(
          format(queryKey[1], 'yyyy-MM-dd'),
          false,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const {
    data: appointmentsList,
    isLoading,
    refetch,
  } = useQuery(['get-appointments', selectedDate], getAppointments, {
    onError: (error) => {
      toast({
        title: t('APPOINTMENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const { data: barbers } = useQuery('get-team', () =>
    getBarbers({ barbershop_id: userData.id })
  )

  const filteredData = useMemo(() => {
    if (query?.length > 0) {
      const search_result = appointmentsList?.filter((item) =>
      String(item?.personalized_id?.toLowerCase())?.includes(query?.toLowerCase()) 
    )

    return search_result
    }
    if (barberFilter && barberFilter !== null)
      return (appointmentsList).filter(
        (i) => i?.barber?.id === barberFilter?.id && i?.status === 'client_arrived'
      )
    return appointmentsList?.filter((i => i?.status === 'client_arrived'))
  }, [query, barberFilter, appointmentsList])
 
  return (
    <Layout {...props} headerTitle={t('HISTORIC_TITLE')}>
      {/* Filters */}
      <Flex mb="16px">
        <SelectDateButton
          date={selectedDate}
          setDate={setSelectedDate}
          onClickCenter={() => setDateModalOpen(true)}
        />
         <SelectFilter
          onChange={(value) =>
            setBarberFilter(
              barbers?.find((i) => i?.name?.trim() === value?.trim())
            )
          }
          value={barberFilter?.name}
          placeholder="Todos"
          options={barbers || []}
          label="BARBER"
          h='56px'
          ml='16px'
        />
        <TextInput
          noBorder
          type="text"
          width="50%"
          ml='8px'
          color="white"
          placeholder='Pesquise por número personalizado da comanda'
          backgroundColor="menuBackground"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value)
          }}
          leftElement={<Search color={colors.primary} />}
        />
       
      </Flex>
      {isDesktop ? (
        <Table
          loading={isLoading}
          data={filteredData || []}
          columns={columns}
          isSelectableRow
          onSelect={onSelectAppointment}
        />
      ) : (
        <HistoryList
          loading={isLoading}
          data={filteredData || []}
          onSelect={onSelectAppointment}
        />
      )}
      <SelectDateModal
        onClose={() => setDateModalOpen(false)}
        isModalOpen={dateModalOpen}
        selectedDate={selectedDate}
        onSelectDate={(date) => {
          setSelectedDate(new Date(date))
          setDateModalOpen(false)
        }}
      />

      {detailsModalVisible && (
        <AppointmentDetailsModal
          isModalOpen={detailsModalVisible}
          onClose={onCloseModals}
          setAppointmentId={setApp}
          app={app}
        />
      )}
    </Layout>
  )
}

CommandScreen.path = '/comandas'
CommandScreen.title = 'Comandas'
CommandScreen.secured = true
