import React, { useMemo, useState, useEffect } from 'react'
import { Box, Button, Flex, Text, TextInput, FileInput, Icon } from 'components/atoms'
import { ModalAlert, ModalMenu, SelectCard, Form } from 'components/molecules'
import { Select, useTheme, useToast, useDisclosure } from '@chakra-ui/react'
import { userManagementStore } from 'services/stores'

const form_data = [
  {
    id: 'one_signal_id',
    name: 'one_signal_id',
    type: 'text',
    noBorder: true,
    label: 'One signal ID',
  },
  {
    id: 'version',
    name: 'version',
    type: 'text',
    noBorder: true,
    label: 'Versão da API',
  },
  {
    id: 'status',
    name: 'status',
    type: 'text',
    noBorder: true,
    label: 'Status da barbearia',
  },
  {
    id: 'notification_rest_key',
    name: 'notification_rest_key',
    type: 'text',
    noBorder: true,
    label: 'REST API Key do OneSignal',
  },
  {
    id: 'iugu_client_id',
    name: 'iugu_client_id',
    type: 'text',
    noBorder: true,
    label: 'Iugu Client ID',
  },
  {
    id: 'iugu_subscription_id',
    name: 'iugu_subscription_id',
    type: 'text',
    noBorder: true,
    label: 'Iugu Subscription ID',
  },
  {
    id: 'signature_terms',
    name: 'signature_terms',
    type: 'text',
    noBorder: true,
    label: 'Link do termo de assinatura',
  },
]

export const BarbershopDetailsModal = ({ isOpen, onClose, barbershop, onSuccess }) => {
  const theme = useTheme()
  const toast = useToast()

  const [values, getValues] = useState({ params: barbershop, isValid: true })

  const { onUpdateBarbershopForm, onUpdateSignatureActive, onUpdateNfActive, onUpdateBarbershopInfo } =
    userManagementStore()

  const onSaveForm = async () => {
    const { params } = values
    const payload = {
      barbershop_id: barbershop.id,
      one_signal_id: params?.one_signal_id,
      version: params?.version,
      status: params?.status,
      notification_rest_key: params?.notification_rest_key,
      iugu_client_id: params?.iugu_client_id,
      iugu_subscription_id: params?.iugu_subscription_id,
      signature_terms: params?.signature_terms,
    }
    const response = await onUpdateBarbershopForm(payload, toast)
    if (!response?.error) {
      onSuccess()
    }
  }

  const onSelectChange = async ({ type, value }) => {
    const barbershop_id = barbershop.id
    let response = null
    if (type === 'active') {
      response = await onUpdateBarbershopInfo({ barbershop_id, data: { inactive: value } }, toast)
    }
    if (type === 'signature') {
      response = await onUpdateSignatureActive({ barbershop_id, status: value }, toast)
    }
    if (type === 'nf_module') {
      response = await onUpdateNfActive({ barbershop_id, status: value }, toast)
    }
    if (type === 'push_notifications') {
      response = await onUpdateBarbershopInfo({ barbershop_id, data: { push_notification: value } }, toast)
    }
    if (!response?.error) {
      onSuccess()
    }
  }

  return (
    <ModalMenu
      size="xl"
      isOpen={isOpen}
      headerStyle={{ mb: theme.pxToRem(-12) }}
      onClose={() => {
        onClose()
      }}
      title={barbershop?.name}
    >
      <Text kind="semiBold" fontSize={16}>
        ID da barbearia: {barbershop?.id}
      </Text>
      <Text kind="medium" fontSize={14}>
        Cadastro: {barbershop?.inactive ? 'Desativado' : 'Ativo'} | Status: {barbershop?.status}
      </Text>
      <Flex justify="space-between" w="100%" mt={4} mb={4}>
        <SelectCard
          mr={4}
          h={theme.pxToRem(56)}
          selected={!barbershop?.inactive}
          item={{ title: `Barbearia ativa` }}
          setSelected={() => onSelectChange({ type: 'active', value: !barbershop?.inactive })}
        />
        <SelectCard
          mr={4}
          h={theme.pxToRem(56)}
          selected={barbershop?.signature_club_enable}
          item={{ title: `Clube de assinatura` }}
          setSelected={() => onSelectChange({ type: 'signature', value: !barbershop?.signature_club_enable })}
        />
        <SelectCard
          mr={4}
          h={theme.pxToRem(56)}
          selected={barbershop?.nf_module_active}
          item={{ title: `Módulo de Nota Fiscal` }}
          setSelected={() => onSelectChange({ type: 'nf_module', value: !barbershop?.nf_module_active })}
        />
        <SelectCard
          h={theme.pxToRem(56)}
          selected={barbershop?.push_notification}
          item={{ title: `Push notifications` }}
          setSelected={() => onSelectChange({ type: 'push_notifications', value: !barbershop?.push_notification })}
        />
      </Flex>
      <Form data={form_data} initialValues={barbershop} getValues={getValues} />
      <Button onClick={onSaveForm} mt={8} w="100%">
        Salvar
      </Button>
    </ModalMenu>
  )
}
