import { Text, Flex, Box } from 'components'
import { addHours, format, parseISO } from 'date-fns'
import { formatPrice } from 'utils'
export const settings = (data) => {
  return [
    {
      label: 'Asaas',
      description: 'Faturamento líquido em crédito/débito',
      value: data?.month_balance || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total líquido de assinaturas faturadas na asaas',
    },
    {
      label: 'Asaas',
      description: 'Faturamento bruto em crédito/débito',
      value: data?.signatures_paid_gross_value || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de assinaturas faturadas na asaas',
    },
    {
      id: 1,
      label: 'Asaas',
      description: 'DISCOUNTS',
      value: data?.total_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos das assinaturas faturadas na asaas',
    },
    {
      label: 'Total bruto faturado',
      description: 'Asaas + faturados em dinheiro',
      value: data?.received_in_cash + data?.signatures_paid_gross_value || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Esse valor é usado para cálculo de comissão dos barbeiros',
    },
    {
      label: 'Faturado em dinheiro',
      description: 'TOTAL_RECEIVED',
      value: data?.received_in_cash,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total considerado como faturado em dinheiro',
    },
  ]
}

export const settingsMobile = (data) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL',
      value: data?.signatures_paid || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de agendamentos finalizados',
    },
    {
      label: 'DISCOUNTS',
      description: 'TOTAL',
      value: data?.appointment_payments_methods_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos dos métodos de pagamentos',
      last: true,
    },
  ]
}

export const columns = [
  {
    key: 'date',
    label: 'DATA DE PAGAMENTO',
    render: (item) => (
      <Text>
        {item?.payment_simple_date
          ? format(parseISO(item?.payment_simple_date), 'dd/MM/yyyy')
          : format(addHours(parseISO(item?.payment_date), 3), 'dd/MM/yyyy')}
      </Text>
    ),
  },
  {
    key: 'client',
    label: 'CLIENT_TABLE',
    render: (item, index) => {
      return (
        <Flex flexDir="row" align="center">
          <Text>{item?.subscription?.client?.name}</Text>
        </Flex>
      )
    },
  },
  {
    key: 'description',
    label: 'DESCRIPTION_TABLE',
    render: (item, index) => {
      return (
        <Flex flexDir="row" align="center">
          <Text>{item?.title}</Text>
        </Flex>
      )
    },
  },
  {
    key: 'value',
    label: 'VALOR BRUTO',
    render: (item) => <Text>{formatPrice(item?.value)}</Text>,
  },
  {
    key: 'discounts',
    label: 'DESCONTO',
    render: (item) => <Text>{formatPrice(item?.asaas_status === 'RECEIVED_IN_CASH'
      ? 0 : item?.discount)}</Text>,
  },
  {
    key: 'net_value',
    label: 'VALOR LIQUIDO',
    render: (item) => <Text>{formatPrice(item?.asaas_status === 'RECEIVED_IN_CASH'
      ? item?.total_value : item?.net_value)}</Text>,
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text
        kind="bold"
        color={
          item?.status === 'REFUND'
            ? 'grey'
            : item?.asaas_status === 'CONFIRMED'
            ? 'blue'
            : item?.asaas_status === 'RECEIVED'
            ? 'success'
            : 'primary'
        }
      >
        {item?.status === 'REFUND'
          ? 'Estornado'
          : item?.asaas_status === 'CONFIRMED'
          ? `Previsto para ${format(parseISO(item.estimated_credit_date), 'dd/MM/yyyy')}`
          : item?.asaas_status === 'RECEIVED'
          ? 'Recebido'
          : 'Recebido externamente'}
      </Text>
    ),
  },
  {
    key: 'billing_type',
    label: 'FORMA DE PAGAMENTO',
    render: (item) => (
      <Text>
        {item?.status === 'REFUND'
          ? ' - '
          : item?.asaas_status === 'RECEIVED_IN_CASH'
          ? 'Recebido externamente'
          : item?.billing_type === 'DEBIT_CARD'
          ? 'Débito'
          : item?.billing_type === 'CREDIT_CARD'
          ? 'Crédito'
          : 'Recebido externamente'}
      </Text>
    ),
  },
]
