import React from 'react'
import { Screen } from 'components/templates'
import { Flex } from '@chakra-ui/react'
import { LoginModal } from 'components/organisms'
import loginBg from 'assets/img/backgrounds/login.png'

export const LoginScreen = () => {
  return (
    <Screen type="login" imageUrl={loginBg}>
      <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
        <LoginModal />
      </Flex>
    </Screen>
  )
}

LoginScreen.path = '/login'
LoginScreen.title = 'Login'
LoginScreen.secured = false
