import create from 'zustand'
import { useBarbershopStore } from './barbershop'
import { onFetch } from 'utils/fetch'
import { t } from 'services/translation'
import { useAppointmentDetailStore } from './appointmentDetail'
import * as e from 'services/api/endpoints'

export const additionalProductsStore = create((set, get) => ({
  additionalProductsList: [],
  setAdditionalProductsList: (additionalProductsList) => set({ additionalProductsList }),

  missingAdditionalProducts: [],
  setMissingAdditionalProducts: (missingAdditionalProducts) => set({ missingAdditionalProducts }),

  additionalProductsAppointmentList: [],
  setAdditionalProductsAppointmentList: (additionalProductsAppointmentList) =>
    set({ additionalProductsAppointmentList }),

  queryControl: false,
  setQueryControl: (queryControl) => set({ queryControl }),

  entryType: false,
  setEntryType: (entryType) => set({ entryType }),

  onGetAdditionalProducts: async (props) => {
    set({ loading: true })
    await onFetch({
      url: e.GET_ADDITIONAL_PRODUCTS(useBarbershopStore.getState().userData?.id),
      method: 'get',
      ...props,
      message: {
        error: {
          title: t('ERROR_LOADING_PRODUCTS'),
        },
      },
      onCompleted: (data) => {
        set({ loading: false, additionalProductsList: data })
      },
      onError: () => {
        set({ loading: false })
      },
    })
  },

  onGetAdditionalProductsAppointment: async (props) => {
    set({ loading: true })
    if (!useAppointmentDetailStore.getState()?.selectedAppointment?.id) {
      throw new Error('Parâmetro não informado')
    }
    await onFetch({
      url: e.GET_ADD_ADDITIONAL_PRODUCTS_APPOINTMENT(useAppointmentDetailStore.getState()?.selectedAppointment?.id),
      method: 'get',
      ...props,
      message: {
        error: {
          title: t('ERROR_LOADING_PRODUCTS'),
        },
      },
      onCompleted: (data) => {
        set({ loading: false, additionalProductsAppointmentList: data })
      },
      onError: () => {
        set({ loading: false })
      },
    })
  },
}))
