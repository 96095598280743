import { t } from 'services/translation'
import * as Yup from 'yup'

const onlyNumbers = (data) => {
  let numStr = data.replace(/[^0-9]/g, '')
  return numStr
}

export const initial = {
  identifier: '',
  number: '',
  name: '',
}

export const validation = Yup.object().shape({
  identifier: Yup.string(),
  number: Yup.string().min(16).required('Número do cartão é obrigatório'),
  name: Yup.string().required('Nome do titular é obrigatório'),
})

export const input = (data) => [
  {
    id: 0,
    name: 'number',
    noBorder: true,
    label: 'NUMBER_CARD',
    placeholder: 'XXXX-XXXX-XXXX-XXXX',
    mask: 'CREDIT_MASK',
    mb: '9%',
  },
  {
    id: 1,
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'SERVICE_NAME',
    placeholder: t('COMPLETE_NAME_CARD'),
    mb: '9%',
  },
  {
    id: 2,
    name: 'identifier',
    noBorder: true,
    label: 'CPF_OR_CNPJ',
    placeholder: 'XXX.XXX.XXX-XX',
    mask: onlyNumbers(data).length > 11 ? 'CNPJ_MASK' : 'CPF_MASK_',
    mb: '9%',
  },
]
