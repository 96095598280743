import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import {
  Flex,
  RegisterWrapper
} from 'components'
import { daysOfWeek } from 'utils'
import { useBarbershopStore } from 'services/stores/barbershop'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { BARBERSHOP_OPENING_HOURS } from 'services/api/endpoints'
import { WorkingHoursTable } from 'components'

export const WorkingHoursStepScreen = ({ onClickNext }) => {
  const { workingHours, setWorkingHours, userData } = useBarbershopStore()
  const [weekDays, setWeekDays] = useState(workingHours || daysOfWeek)
  const history = useHistory()
  const toast = useToast()
  const { t } = useTranslation()

  useEffect(() => {
    firebaseAnalytics.logEvent(
      'web_register_barbershop_working_hours_step_opened'
    )
  }, [])

  async function getWorkingHours() {
    const res = await api
      .get(BARBERSHOP_OPENING_HOURS(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-working-hours', getWorkingHours, {
    onSuccess: (data) => {
      if (data?.opening_hours) {
        setWeekDays(data?.opening_hours)
        setWorkingHours(data?.opening_hours)
      }
    },
  })

  async function changeWorkingHours(params) {
    const res = await api
      .put(
        BARBERSHOP_OPENING_HOURS(userData?.barbershop?.id || userData?.id),
        params.params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-working-hours',
    (params) => changeWorkingHours(params),
    {
      onSuccess: (data) => {
        toast({
          title: t('UPDATE_WORKING_HOURS'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/cadastro/servicos-barbearia')
      },
      onError: (error) => {
        toast({
          title: t('UPDATE_WORKING_HOURS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleSubmit = () => mutate.mutate({ params: { opening_hours: weekDays } })

  return (
    <RegisterWrapper
      barbershopSetup
      loading={mutate.isLoading}
      onContinue={handleSubmit}
    > 
      <Flex flexDir="column" w="100%" h="100%">
        <WorkingHoursTable daysList={weekDays} setDaysList={setWeekDays} />
      </Flex>
    </RegisterWrapper>
  )
}

WorkingHoursStepScreen.path = '/cadastro/horario-funcionamento'
WorkingHoursStepScreen.title = 'Horário de Funcionamento'
WorkingHoursStepScreen.secured = true
