import React from 'react'
import { Logo, Flex, Icon, Text, Button, Box } from 'components/atoms'
import { useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { usePremiumBlock } from 'services/hooks'
import { useBarbershopSubscription } from 'services/hooks'
import { useMutation } from 'react-query'
import { useBreakpoint } from 'services/hooks'
import { parseISO, differenceInDays, addDays } from 'date-fns'

export const BarbershopStatusBanner = ({ ...props }) => {
  const { t } = useTranslation()
  const { setSubscription, subscription, userData } = useBarbershopStore()
  const isBlocked = usePremiumBlock()
  const { isDesktop } = useBreakpoint()
  const { getIuguNewInvoice } = useBarbershopSubscription()

  const openLink = (url) => {
    var win = window.open(url, '_blank')
    win.focus()
  }

  const mutation = useMutation(
    'get-iugu-new-invoice',
    () => getIuguNewInvoice({
      iugu_subscription_id: subscription.id, onSuccess: (data) => {
        openLink(data?.invoice);
        setSubscription({ ...subscription, recent_invoices: [{ status: 'pending', secure_url: data?.invoice }] })
      }
    }),
  )

  const onClick = () => {
    const status = subscription?.recent_invoices[0]?.status
    if (status === 'expired' || status === 'canceled') {
      mutation.mutate()
      return
    }
    openLink(subscription.recent_invoices[0].secure_url)
  }

  if (userData?.account_status === 'TRIAL') {
    return (
      <Flex
        {...props}
        w="100vw"
        bg="success"
        flexDir={isDesktop ? "row" : "column"}
        align="center"
        justify="center"
        minH="7vh"
        px="8vw"
        py="2vh"
      >
        <Icon size={14} onClick={props?.onClose} color="white" name="Close" position="absolute" right="24px" top="16px" />
        <Logo mb={1} />
        <Flex align="center" justify="center">
          <Text align="center" ml={4} kind="bold" color="white">
            Restam {1 + differenceInDays(parseISO(userData?.trial_end_date || (addDays(parseISO(userData?.trial_start_date), 7))), new Date())} dias de teste GRÁTIS! Estamos ansiosos para essa parceria 👊
          </Text>
        </Flex>
        {/* <Button isLoading={mutation.isLoading} size={"x-small"} onClick={onClick}>
          Regularizar assinatura
        </Button> */}
      </Flex>
    )
  }

  if (isBlocked) {
    return (
      <Flex
        {...props}
        w="100vw"
        bg="danger"
        flexDir={isDesktop ? "row" : "column"}
        align="center"
        justify="space-between"
        minH="7vh"
        px="8vw"
        py="2vh"
      >
        <Logo mb={1} />
        <Flex align="center" justify="center">
          <Icon size={32} color="white" name="CardDelete" />
          <Text align="center" ml={4} kind="bold" color="white">
            Sua assinatura está pendente!{isDesktop ? ' Faça o pagamento da fatura e regularize sua assinatura agora mesmo!' : ''}
          </Text>
        </Flex>
        <Button isLoading={mutation.isLoading} size={"x-small"} onClick={onClick}>
          Regularizar assinatura
        </Button>
      </Flex>
    )
  }

  return null
}
