import * as Yup from 'yup'
import { t } from 'services/translation'

export const inputs = [
  {
    name: 'message',
    type: 'string',
    typeInput: 'textArea',
    noBorder: true,
    placeholder: t('SEND_NOTIFICATION_PLACEHOLDER'),
  },
]

export const validation = Yup.object().shape({
  message: Yup.string()
    .min(10, 'MESSAGE')
    .required('Mensagem deve pelo menos 10 caracteres'),
})

export const initial = {
  message: '',
}
