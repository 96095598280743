import React, { useState, useEffect } from 'react'
import { ModalMenu, Text, Flex } from 'components'
import { IssueInvoiceCustomForm } from './index'
import { getData } from 'services/storage'
import { formatPrice } from 'utils'

import { useInvoice, useInvoiceIssuer } from 'services/hooks'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { useToast } from '@chakra-ui/react'
import { useInvoiceData } from 'services/stores'

export const IssuerInvoiceDrawer = ({ appointmentId, type, onClose }) => {
  const toast = useToast()
  const { createInvoice, createInvoiceNfse } = useInvoice()
  const [userLogged, setUserLogged] = useState({})
  const { getAllInvoiceIssuer } = useInvoiceIssuer()
  const { invoiceServiceData, appointmentData, setInvoiceServiceData } = useInvoiceData()
  const queryClient = useQueryClient()
  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),
    { enabled: !!userLogged?.id, select: (res) => res.data[0] }
  ) 

  const issuerInvoiceMutation = useMutation('invoice-records', createInvoice, {
    enabled: !!userLogged?.id,
    onSuccess: (data) => {
      toast({
        title: 'Solicitação de NFCe enviada com sucesso!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      queryClient.invalidateQueries(['get-historic-nf'])
      const queryState = queryClient?.getQueryData(['get-historic-nf'])
      const newState = [...queryState]
      const index = queryState.findIndex((appointment) => appointment?.id === appointmentId)
      if (index !== -1) {
        const invoices = newState[index]?.invoices || []
        newState[index] = {
          ...newState[index],
          invoices: [...invoices, data],
        }
        queryClient.setQueryData(['get-historic-nf'], newState)
      }
      onClose()
    },
    onError: () => {
      toast({
        title: 'Erro ao emitir nota fiscal!',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const issuerInvoiceServiceMutation = useMutation('invoice-records-nfse', createInvoiceNfse, {
    onSuccess: (data) => {
      toast({
        title: 'Solicitação de NFSe enviada com sucesso!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })

      queryClient.invalidateQueries(['get-historic-nf'])
      const queryState = queryClient?.getQueryData(['get-historic-nf'])
      const newState = [...queryState]
      const index = queryState.findIndex((appointment) => appointment?.id === appointmentId)
      if (index !== -1) {
        const invoices = newState[index]?.invoices || []
        newState[index] = {
          ...newState[index],
          invoices: [...invoices, data],
        }
        queryClient.setQueryData(['get-historic-nf'], newState)
      }
      onClose()
    },
    onError: () => {
      toast({
        title: 'Erro ao emitir nota fiscal!',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleSubmitInvoice = (form) => {
    if (type === 'NFSe') {
      const {helpers} = form.values

      const params = {
        ...invoiceServiceData,
        tomador: {
          ...invoiceServiceData.tomador,
          endereco: !helpers.sendClientAddress 
            ? {}
            : {
            ...invoiceServiceData.tomador.endereco
          }
          
      }
    }
        issuerInvoiceServiceMutation.mutate(params)
        return
    }
    if (!form.isValid || !invoiceIssuerQuery.data?.id) return

    const { helpers, ...rest } = form.values

    const {
      presenca_comprador,
      nome_transportador,
      cnpj_transportador,
      cpf_transportador,
      inscricao_estadual_transportador,
      endereco_transportador,
      municipio_transportador,
      uf_transportador,
      nome_destinatario,
      cpf_destinatario,
      telefone_destinatario,
      cnpj_destinatario,
      indicador_inscricao_estadual_destinatario,
      cep_destinatario,
      uf_destinatario,
      municipio_destinatario,
      bairro_destinatario,
      logradouro_destinatario,
      numero_destinatario,
      ...formData
    } = JSON.parse(JSON.stringify(rest).replaceAll('""', null))

    const additionalData = helpers?.showAdvancedFields ? helpers?.additionalData.jsObject : {}

    const newIssuerInvoice = {
      ...formData,
      presenca_comprador,
      ...(presenca_comprador === '4'
        ? {
            nome_transportador,
            cnpj_transportador: helpers?.carrierType === 'PJ' ? cnpj_transportador : '',
            cpf_transportador: helpers?.carrierType === 'PF' ? cpf_transportador : '',
            inscricao_estadual_transportador,
            endereco_transportador,
            municipio_transportador,
            uf_transportador,
          }
        : {}),
      ...(helpers?.addRecipientData
        ? {
            nome_destinatario,
            cpf_destinatario: helpers?.recipientType === 'PF' ? cpf_destinatario : '',
            cnpj_destinatario: helpers?.recipientType === 'PJ' ? cnpj_destinatario : '',
            telefone_destinatario,
            indicador_inscricao_estadual_destinatario,
            cep_destinatario,
            uf_destinatario,
            municipio_destinatario,
            bairro_destinatario,
            logradouro_destinatario,
            numero_destinatario,
          }
        : {}),
      ...additionalData,
      itens: additionalData.itens?.length
        ? formData.itens.map((item, index) => ({
            ...item,
            ...additionalData.itens?.[index],
          }))
        : formData.itens,
      formas_pagamento: additionalData.formas_pagamento?.length
        ? formData.formas_pagamento.map((item, index) => ({
            ...item,
            ...additionalData.formas_pagamento?.[index],
          }))
        : formData.formas_pagamento,
      issuer_id: invoiceIssuerQuery.data.id,
      appointment_id: appointmentId,
    }

    issuerInvoiceMutation.mutate(newIssuerInvoice)
  }

  return (
    <ModalMenu
      isOpen={!!appointmentId}
      onClose={onClose}
      title="Nota Fiscal"
      size="lg"
      bodyStyle={{ pb: 6, color: 'white' }}
    >
      <IssueInvoiceCustomForm
        type={type}
        isDisabled={issuerInvoiceMutation.isLoading || issuerInvoiceServiceMutation.isLoading}
        isLoading={issuerInvoiceMutation.isLoading || issuerInvoiceServiceMutation.isLoading}
        onSubmit={handleSubmitInvoice}
      />
    </ModalMenu>
  )
}
