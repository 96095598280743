import React, { useState } from 'react'
import { SelectDateButton, Table, Text } from 'components'
import { Center, Flex, Spinner } from '@chakra-ui/react'
import './hideScrollbar.css'
import { useBarbershop } from 'services/hooks'
import { useQuery } from 'react-query'
import { format, subMonths } from 'date-fns'

export const Plans = ({ userData }) => {
  const [selectedDay, setSelectedDay] = useState(subMonths(new Date(), 1))
  const { getPlansAverage } = useBarbershop()

  const { isLoading, data } = useQuery(
    ['get-plans-average', userData, selectedDay],
    () => getPlansAverage({ id: userData?.id, startDate: format(selectedDay, 'yyyy-MM-dd') }),
    {
      refetchOnWindowFocus: false,
    }
  )

  const columns = [
    {
      key: 'plan',
      label: 'PLANO',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Text>{item?.name}</Text>
          </Flex>
        )
      },
    },
    {
      key: 'appointments',
      label: 'TOTAL DE ATENDIMENTOS',
      render: (item) => <Text>{item?.appointments}</Text>,
    },
    {
      key: 'subscribers',
      label: 'CLIENTES ATIVOS NO PERIODO',
      render: (item) => <Text>{item?.subscribers}</Text>,
    },
    {
      key: 'count',
      label: 'MÉDIA POR CLIENTE',
      render: (item) => <Text>{item?.average?.toFixed(0)}</Text>,
    },
  ]

  return (
    <>
      <Flex w="100%" flexDir="column" align="center">
        <Flex flexDir="column" w="100%" mt="24px" mb="24px" align="center">
          <SelectDateButton
            width="400px"
            h="42px"
            onlyMonth
            date={selectedDay}
            setDate={setSelectedDay}
            disabledNextMonth={selectedDay.getMonth() === new Date().getMonth() - 1}
            disabledPreviousMonth={selectedDay.getMonth() === 7}
          />
        </Flex>
        <Flex flexDir="column" w="100%">
          <Table
            // isSelectableRow
            loading={isLoading}
            data={data?.plans?.sort?.((a, b) => b?.average - a?.average) || []}
            columns={columns}
          />
        </Flex>
      </Flex>
    </>
  )
}
