import { Text } from 'components'
import { WEEK_DAYS, formatPrice } from 'utils'
import { t } from 'services/translation'

export const statuses = [
  {
    name: 'Inativo',
    value: {
      status: 'INACTIVE',
    },
  },
  {
    name: 'Cancelado',
    value: {
      inactive: true,
    },
  },
  {
    name: 'Ativo',
    value: { status: 'ACTIVE' },
  },
]
