import React, { useEffect, useState } from 'react'
import {
  Layout,
  SelectDateButton,
} from 'components'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { ResumeGoals } from 'components-v2/organisms/BarberGoals/ResumeGoals'

export const ResumeGoalsScreen = ({ ...props }) => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const {onLogout} = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])

  return (
    <Layout
      {...props}
      headerTitle='Metas da equipe'
      headerCenterElement={() =>
        <SelectDateButton width='400px' h='42px' onlyMonth date={selectedDay}
        setDate={setSelectedDay}/>
      }
    >

      <ResumeGoals selectedDay={selectedDay} />
      
    </Layout>
  )
}

ResumeGoalsScreen.path = '/metas-resumo'
ResumeGoalsScreen.title = 'Metas dos colaboradores'
ResumeGoalsScreen.secured = true
