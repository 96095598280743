export const benefits = [
  {
    id: 0,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_1_NEW',
  },
  {
    id: 1,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_2_NEW',
  },
  {
    id: 2,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_3_NEW',
  },
  {
    id: 3,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_4_NEW',
  },
  {
    id: 4,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_5_NEW',
  },
  {
    id: 5,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_6_NEW',
  },
  {
    id: 6,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_7_NEW',
  },
  {
    id: 7,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_8_NEW',
  },
  {
    id: 8,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_9_NEW',
  },
]
