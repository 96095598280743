import * as React from 'react'

function SvgClockCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 74 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37 74c17.397 0 31.5-14.103 31.5-31.5S54.397 11 37 11 5.5 25.103 5.5 42.5 19.603 74 37 74z"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 42.583l9.583 9.584L51.75 33M14.5 2L2.125 11M59.5 2l12.375 9"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgClockCheck
