import * as React from 'react'

function SvgGift(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.167 7.417H2.833c-.632 0-1.146.513-1.146 1.146v2.291c0 .633.514 1.146 1.146 1.146h18.334c.632 0 1.145-.513 1.145-1.146V8.563c0-.633-.513-1.146-1.145-1.146zM12 7.417v14.896"
        stroke="currentColor"
        strokeWidth={1.719}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.02 12v8.02a2.292 2.292 0 01-2.291 2.293H6.27A2.292 2.292 0 013.98 20.02V12M6.843 7.417a2.865 2.865 0 110-5.73c1.105-.019 2.189.518 3.108 1.54.92 1.022 1.634 2.482 2.048 4.19.415-1.708 1.128-3.168 2.048-4.19.92-1.022 2.003-1.559 3.109-1.54a2.865 2.865 0 010 5.73"
        stroke="currentColor"
        strokeWidth={1.719}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgGift
