import React from 'react'
import { Flex } from 'components/atoms'

export const ModalButtonBackground = ({ children }) => {
  return (
    <Flex
      borderTopColor="grey"
      borderTopWidth="1px"
      ml="-24px"
      position="absolute"
      bottom={0}
      bg="cardBackground"
      h="100px"
      w="100%"
      p="20px"
    >
      {children}
    </Flex>
  )
}
