import * as React from 'react'

function SvgCalendar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3.333H4c-.736 0-1.333.597-1.333 1.334v8C2.667 13.403 3.264 14 4 14h8c.736 0 1.333-.597 1.333-1.333v-8c0-.737-.597-1.334-1.333-1.334zM10.667 2v2.667M5.333 2v2.667M2.667 7.333h10.666"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.667 10H5.333v1.333h1.334V10z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCalendar
