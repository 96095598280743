import * as React from 'react'

function SvgScissorsBig(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.906 41.609a1.66 1.66 0 01.53-2.883l38.75-12.21a1.659 1.659 0 011.96 2.369c-1.514 2.81-3.69 5.454-6.294 7.642-2.666 2.241-5.665 3.902-8.671 4.804l-16.5 5.014a1.661 1.661 0 01-2.072-1.11 4.983 4.983 0 00-6.197-3.338 1.658 1.658 0 01-1.506-.288zm8.086-1.444a8.253 8.253 0 012.157 2.57l15.073-4.58c3.81-1.143 7.572-3.74 10.375-7.03l-27.924 8.8c.108.077.214.157.319.24zM11.55 51.282a8.235 8.235 0 01-2.789-4.118c-1.314-4.38 1.182-9.015 5.563-10.329a8.241 8.241 0 016.317.642 8.242 8.242 0 014.012 4.921 8.241 8.241 0 01-.642 6.317 8.242 8.242 0 01-4.92 4.012 8.243 8.243 0 01-6.318-.642 8.379 8.379 0 01-1.223-.803zm3.727-11.268a4.983 4.983 0 00-3.337 6.197 4.945 4.945 0 002.407 2.953c1.17.63 2.516.767 3.79.385a4.946 4.946 0 002.953-2.407c.63-1.171.767-2.517.385-3.79a4.944 4.944 0 00-2.408-2.953 4.946 4.946 0 00-3.79-.386z"
        fill="currentColor"
      />
      <path
        d="M22.034 44.168a1.66 1.66 0 01.451-2.856l6.87-2.558a1.659 1.659 0 011.158 3.11l-6.87 2.558a1.659 1.659 0 01-1.61-.254z"
        fill="currentColor"
      />
      <path
        d="M32.581 44.514a1.658 1.658 0 01-.63-1.398 4.983 4.983 0 00-4.68-5.257 1.66 1.66 0 01-1.561-1.754l1.016-17.165c.361-6.246 3.77-13.113 8.685-17.49a1.659 1.659 0 012.758 1.357L35.263 43.33a1.659 1.659 0 01-2.682 1.184zm1.935-37.157c-2.576 3.509-4.247 7.793-4.477 11.776L29.11 34.81a8.258 8.258 0 013.311 1.759l2.095-29.212zM27.2 63.637c-.38-.3-.737-.636-1.065-1.005a8.242 8.242 0 01-2.088-5.996 8.241 8.241 0 012.764-5.716 8.24 8.24 0 015.997-2.088c4.566.264 8.067 4.194 7.803 8.76-.263 4.568-4.194 8.068-8.76 7.804a8.239 8.239 0 01-4.651-1.76zm8.208-10.432a4.95 4.95 0 00-2.791-1.06 4.983 4.983 0 00-5.257 4.682 4.943 4.943 0 001.252 3.598 4.947 4.947 0 003.43 1.659 4.983 4.983 0 005.256-4.683 4.976 4.976 0 00-1.89-4.196zm-2.696-2.716z"
        fill="currentColor"
      />
      <path
        d="M31.686 51.794a1.658 1.658 0 01-.62-1.506l.897-7.277a1.66 1.66 0 013.293.406l-.897 7.277a1.658 1.658 0 01-2.673 1.1zM30.818 68.239a1.657 1.657 0 01-.63-1.356l.101-3.199a1.658 1.658 0 113.317.105l-.102 3.2a1.659 1.659 0 01-2.686 1.25zM5.505 48.244a1.659 1.659 0 01.594-2.902l3.088-.84a1.66 1.66 0 01.87 3.202l-3.088.84a1.656 1.656 0 01-1.464-.3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgScissorsBig
