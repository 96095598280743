import { t } from 'services/translation'
import React from 'react'
import { Text } from '..'
import { Flex } from '..'
import { Select } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles'

export const SelectFilter = ({ onChange, value, options, label, ...props }) => {
  return (
    <Flex
      mr={pxToRem(12)}
      flexDir="row"
      borderRadius={pxToRem(6)}
      bg="cardBackground"
      h={pxToRem(40)}
      align="center"
      justify="center"
      pl={pxToRem(12)}
      {...props}
      // minW={pxToRem(201)}
    >
      {label && (
        <Text fontSize={14} kind="medium" mr={pxToRem(8)} color="textGrey">
          {t(label)}:
        </Text>
      )}

      <Select
        name={label}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={props?.placeholder ? props?.placeholder : !props?.withoutPlaceholder && t('ALL')}
        w={
          props?.selW ? props?.selW : props?.fullWidth ? '100%' : !props?.noWidth && pxToRem((value?.length || 7) * 13)
        }
        minW={pxToRem(props?.minW || 80)}
        maxW={pxToRem(props?.maxW || 230)}
        fontFamily="Montserrat Bold"
        fontSize={14}
        textColor="textLight"
        iconColor="primary"
        borderColor="transparent"
        errorBorderColor="baseRed"
        focusBorderColor="transparent"
        variant="unstyled"
      >
        {options?.map((item) => (
          <option
            style={{
              backgroundColor: colors.menuBackground,
              fontFamily: 'Montserrat Medium',
            }}
            key={`select_${item?.name}_${item?.id}`}
            value={item?.value ?? item?.name}
          >
            {item?.name}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
