import React from 'react'
import { Text, TextInput } from 'components/atoms'
import { Flex } from '@chakra-ui/react'

export const InputCard = ({ text, ...props }) => {
  return (
    <Flex
      display="flex"
      flexDir="row"
      w="100%"
      h='64px'
      background="modalBorder"
      alignItems="center"
      justifyContent="space-between"
      px='12px'
      borderRadius='8px'
      mb='16px'
      py='4px'
      {...props} 
    >
        <Text
          w='424'
          kind="medium"
          fontSize='14px'
          color='white'
        >
          {text}
        </Text>
        <TextInput
          w="25%"
          {...props}
          isReadOnly={props?.disabled}
          value={props?.input_value}
          onChange={(e) => props?.onChange(e.target.value)}
        />
    </Flex>
  )
}
