import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalHeader, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { Button, Text, TextInput } from 'components/atoms'
import { formatPrice } from 'utils'
import { format, parseISO } from 'date-fns'

export const ChangeDueDateModal = ({ isOpen, onClose, onClick, loading, payment }) => {
  const theme = useTheme()
  const [dueDate, setDueDate] = useState()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {
        <ModalContent
          maxW={theme.pxToRem(700)}
          borderColor="primary"
          borderWidth={1}
          bg="backgroundLayout"
          flexDir="column"
          align="center"
          justify="center"
          p="32px"
          borderRadius="12px"
          maxH={theme.pxToRem(1028)}
        >
          <ModalHeader textAlign="center" w={'100%'}>
            <Flex flexDir="column" w="100%" alignItems="center" justifyContent="center">
              <Text mb="8px" kind="extraBold" fontSize={28} textAlign="center">
                Deseja alterar a data de vencimento?
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton mt="8px" color="white" onClick={onClose} />
          <Text mb="8px" kind="medium" fontSize={14} textAlign="left">
            Escolha a nova data de vencimento
          </Text>
          <TextInput
            noBorder
            w="100%"
            mt="12px"
            mb="24px"
            name="dueDate"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            h="56px"
          />
          <Flex justify="space-between" mt="24px">
            <Button kind={'secondary'} marginRight="32px" size="medium" onClick={onClose}>
              Cancelar
            </Button>
            <Button kind="primary" size="medium" onClick={() => onClick(dueDate)} isLoading={loading}>
              Alterar
            </Button>
          </Flex>
        </ModalContent>
      }
    </Modal>
  )
}
