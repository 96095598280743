import * as React from 'react'

function SvgCardExpired(props) {
  return (
    <svg
      width="49"
      height="42"
      viewBox="0 0 49 42"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.25 33.75C27.25 32.985 27.34 32.2425 27.4525 31.5H4.75V18H40.75V20.25C42.325 20.25 43.8325 20.5425 45.25 21.0375V4.5C45.25 2.0025 43.2475 0 40.75 0H4.75C2.2525 0 0.25 2.0025 0.25 4.5V31.5C0.25 32.6935 0.724106 33.8381 1.56802 34.682C2.41193 35.5259 3.55653 36 4.75 36H27.4525C27.34 35.2575 27.25 34.515 27.25 33.75ZM4.75 4.5H40.75V9H4.75V4.5ZM48.715 28.98L43.9225 33.75L48.715 38.52L45.52 41.715L40.75 36.9225L35.98 41.715L32.8075 38.52L37.5775 33.75L32.8075 28.98L35.98 25.8075L40.75 30.5775L45.52 25.785L48.715 28.98Z"
        fill="#FFC700"
        stroke="currentColor"
      />
    </svg>
  )
}

export default SvgCardExpired
