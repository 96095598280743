import React from 'react'
import { Flex, Text, Loader, CheckBox } from 'components/atoms'
import { t } from 'services/translation'
import { inputProps } from './settings'
import { getInputTimeProcessed } from 'utils'
import { TextInput } from 'components/atoms'
import { daysOfWeek } from 'utils'

export const WorkingHoursTable = ({ daysList, setDaysList, isLoading }) => {
    const onChangeValue = (day, key, value) => {
        const changes = daysList?.map((i) => {
            if (i?.day === day) {
                return {
                    ...i,
                    [key]: value,
                }
            }
            return i
        })
        setDaysList(changes)
    }
    return (
        <>
            <Flex w="100%" flexDir="row" align="baseline">
                {/* <Text kind="bold" fontSize="12px" mr="2" w="14%">
                    Aberto
                </Text> */}
                <Text kind="bold" fontSize="12px" minW="26%">
                    Dia da Semana
                </Text>
                <Text kind="bold" fontSize="12px" minW="28%">
                    Abre às
                </Text>
                <Text kind="bold" fontSize="12px" minW="28%">
                    Fecha às
                </Text>
            </Flex>
            {isLoading && <Loader mt="3vh" />}
            {!isLoading &&
                daysList
                    ?.sort((a, b) => a?.day - b?.day)
                    ?.map((it, idx) => (
                        <Flex key={idx} mt="8px" flexDir="row" align="center" w="100%">
                            <CheckBox
                                onChange={() =>
                                    onChangeValue(
                                        it?.day,
                                        // item?.id,
                                        'is_closed',
                                        !daysList[idx].is_closed
                                    )
                                }
                                isChecked={!daysList[idx].is_closed}
                                borderWidth="1px"
                                borderColor={!daysList[idx].is_closed ? 'primary' : 'grey'}
                                borderRadius="4px"
                                mr="10%"
                                size="lg"
                            />
                            <Text
                                w="80%"
                                fontSize={inputProps[0].fontSize}
                                opacity={daysList[idx]?.is_closed && 0.6}
                                kind="medium"
                            >
                                {t(`${daysOfWeek[it?.day - 1].label.toUpperCase()}_DESC`)}
                            </Text>

                            {inputProps?.map((input) => (
                                <TextInput
                                    value={getInputTimeProcessed(daysList[idx][input?.key])}
                                    disabled={daysList[idx]?.is_closed}
                                    onChange={(e) =>
                                        onChangeValue(
                                            it?.day,
                                            input?.key,
                                            getInputTimeProcessed(e.target.value)
                                        )
                                    }
                                    {...input}
                                />
                            ))}
                        </Flex>
                    ))}
        </>
    )
}
