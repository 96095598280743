import React, { useEffect, useState } from 'react'
import { firebaseAnalytics } from '../../../../services/firebase'
import { useLocation, useHistory } from 'react-router-dom'
import { Flex, LandingPageModal } from 'components'

import { Why } from '../../components/whySection'
import { Plans } from '../../components/plans'
import { HeroPartners } from '../../components/hero_partners'
import { FreeTestPartners } from '../../components/freeTest_partners'
import { About } from '../../components/about'
import { Header } from '../../components/header'
import { Footer } from '../../components/footer'

export const PartnersLandingPage = () => {
  const location = useLocation()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const split_name = String(location.search).split('&')
    if (split_name?.length > 1) {
      const split_name2 = String(split_name[0]).split('=')
      const split_name3 = String(split_name[1]).split('=')
      firebaseAnalytics.logEvent(`${split_name2[1]}_${split_name3[1]}`)
    }
  }, [])

  const options = {
    autoConfig: true,
    debug: false,
  }

  useEffect(() => {}, [])

  return (
    <Flex overflowY="auto" overflowX="hidden" overscrollY="contain" w={'100vw'} flexDir="column">
      <Header
        clickButton={() => {
          setShowModal(true)
        }}
      />
      <HeroPartners
        clickButton={() => {
          setShowModal(true)
        }}
      />
      <About
        clickButton={() => {
          setShowModal(true)
        }}
      />
      <Plans clickButton={() => setShowModal(true)} />
      <FreeTestPartners
        clickButton={() => {
          setShowModal(true)
        }}
      />
      <Why clickButton={() => setShowModal(true)} />
      <Footer />
      <LandingPageModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        params={{
          register_by: 'adm_web_landing_page_lucas_cohen',
          cupom: 'LUCASCOHEN23',
        }}
        handleFormSubmit={() => history.push('/lp/partners/complete-data/lucas-cohen')}
      />
    </Flex>
  )
}

PartnersLandingPage.path = '/lp/partners/lucas-cohen'
PartnersLandingPage.title = 'LandingPage'
PartnersLandingPage.secured = false
