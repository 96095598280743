import * as React from 'react'

function SvgLocationMarker2(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 17.425a3.636 3.636 0 100-7.273 3.636 3.636 0 000 7.273z" />
      <path d="M21.855 20.645l-5.143 5.143a2.42 2.42 0 01-3.427 0l-5.144-5.143a9.697 9.697 0 1113.714 0z" />
    </svg>
  )
}

export default SvgLocationMarker2
