import React, { useEffect, useState } from 'react'
import { Text, Button, Icon, Link, TextInput } from 'components/atoms'
import { FieldControl, SelectCard, InputJsonField } from 'components/molecules'
import { IssuerInvoiceItemsForm } from './IssuerInvoiceItemsForm'
import { IssuerInvoicePaymentForm } from './IssuerInvoicePaymentForm'
import { IssuerInvoiceRecipientForm } from './IssuerInvoiceRecipientForm'
import { IssuerInvoiceCarrierForm } from './IssuerInvoiceCarrierForm'
import { useInvoiceData, invoiceDataSchema, useBarbershopStore } from 'services/stores'
import { Formik, Form } from 'formik'

import { useInvoice, useInvoiceIssuer } from 'services/hooks'
import { useQuery, useMutation } from 'react-query'
import { getData } from 'services/storage'
import { Box, Collapse, Flex, VStack, Select, HStack, useToast } from '@chakra-ui/react'
import { IssuerInvoiceServicesForm } from './IssuerInvoiceServicesForm'
import { IssuerInvoiceAddressForm } from './IssuerInvoiceAddressForm'

export const IssuerInvoiceForm = ({
  appointmentId,
  isDisabledProduct,
  isDisabledService,
  onClickIssuerInvoice,
  onClickInvoiceCustom,
  titleProps = {},
  setAppointmentEmission,
  appointmentEmission,
  ...props
}) => {
  const toast = useToast()
  const { getAllInvoiceIssuer } = useInvoiceIssuer()
  const { createInvoice, createInvoiceNfse } = useInvoice()
  const { issueInvoice, setIssueInvoice, invoiceData, invoiceServiceData, setInvoiceServiceData, _submitInvoice } =
    useInvoiceData()
  const { userData } = useBarbershopStore()

  const [emission, setEmission] = useState({
    nfce: userData?.nfce_active,
    nfse: userData?.nfse_active,
  })
  const [userLogged, setUserLogged] = useState({})

  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),
    { enabled: !!userLogged?.id, select: (res) => res.data[0] }
  )

  const issuerInvoiceMutation = useMutation('invoice-records', createInvoice, {
    onSuccess: () => {
      toast({
        title: 'Solicitação de NFCe enviada com sucesso!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: () => {
      toast({
        title: 'Erro ao emitir nota fiscal de produto',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const issuerInvoiceServiceMutation = useMutation('invoice-records-services', createInvoiceNfse, {
    onSuccess: () => {
      toast({
        title: 'Solicitação de NFSe enviada com sucesso!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: () => {
      toast({
        title: 'Erro ao emitir nota fiscal de serviço!',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  useEffect(() => {
    if (isDisabledService) {
      setIssueInvoice(
        !isDisabledProduct
          ? {
              nfce: userData?.nfce_active,
              nfse: issueInvoice?.nfse,
            }
          : false
      )
      setAppointmentEmission({
        nfce: appointmentEmission?.nfce,
        nfse: false,
      })
      setEmission({
        nfce: emission?.nfce,
        nfse: false,
      })
    }

    if (!isDisabledService) {
      setIssueInvoice({
        nfce: issueInvoice?.nfce,
        nfse: userData?.nfse_active,
      })
      setAppointmentEmission({
        nfce: appointmentEmission?.nfce,
        nfse: userData?.nfse_active,
      })
      setEmission({
        nfce: emission?.nfce,
        nfse: userData?.nfse_active,
      })
      onClickIssuerInvoice(onClickIssuerInvoice)
    }
    if (isDisabledProduct) {
      setIssueInvoice(
        !isDisabledService
          ? {
              nfce: issueInvoice?.nfce,
              nfse: userData?.nfse_active,
            }
          : false
      )
      setAppointmentEmission({
        nfce: false,
        nfse: appointmentEmission?.nfse,
      })
      setEmission({
        nfce: false,
        nfse: emission?.nfse,
      })
    }
    if (!isDisabledProduct) {
      setIssueInvoice({
        nfce: userData?.nfce_active,
        nfse: issueInvoice?.nfse,
      })
      setAppointmentEmission({
        nfce: userData?.nfce_active,
        nfse: appointmentEmission?.nfce,
      })
      setEmission({
        nfce: userData?.nfce_active,
        nfse: emission?.nfse,
      })
      onClickIssuerInvoice(onClickIssuerInvoice)
    }
  }, [isDisabledService, isDisabledProduct])

  const handleClickIssuerInvoice = (newIssueInvoice) => {
    setAppointmentEmission(newIssueInvoice)
    setEmission(newIssueInvoice)
    setIssueInvoice(newIssueInvoice)
    onClickIssuerInvoice(onClickIssuerInvoice)
  }

  useEffect(() => {
    const handleSubmitInvoice = () => {
      const issuer_id = invoiceIssuerQuery.data?.id
      if (!_submitInvoice || !issuer_id || !appointmentId || issuerInvoiceMutation.isLoading) {
        return
      }

      if (invoiceData && emission.nfce) {
        const { helpers, ...rest } = invoiceData
        const {
          presenca_comprador,
          nome_transportador,
          cnpj_transportador,
          cpf_transportador,
          inscricao_estadual_transportador,
          endereco_transportador,
          municipio_transportador,
          uf_transportador,
          nome_destinatario,
          cpf_destinatario,
          telefone_destinatario,
          cnpj_destinatario,
          indicador_inscricao_estadual_destinatario,
          cep_destinatario,
          uf_destinatario,
          municipio_destinatario,
          bairro_destinatario,
          logradouro_destinatario,
          numero_destinatario,
          ...formData
        } = JSON.parse(JSON.stringify(rest).replaceAll('""', null))

        const additionalData = helpers?.showAdvancedFields ? helpers?.additionalData.jsObject : {}
        const newIssuerInvoice = {
          issuer_id,
          appointment_id: appointmentId,
          ...formData,
          presenca_comprador,
          ...(presenca_comprador === '4'
            ? {
                nome_transportador,
                cnpj_transportador: helpers?.carrierType === 'PJ' ? cnpj_transportador : '',
                cpf_transportador: helpers?.carrierType === 'PF' ? cpf_transportador : '',
                inscricao_estadual_transportador,
                endereco_transportador,
                municipio_transportador,
                uf_transportador,
              }
            : {}),
          ...(helpers?.addRecipientData
            ? {
                nome_destinatario,
                cpf_destinatario: helpers?.recipientType === 'PF' ? cpf_destinatario : '',
                cnpj_destinatario: helpers?.recipientType === 'PJ' ? cnpj_destinatario : '',
                telefone_destinatario,
                indicador_inscricao_estadual_destinatario,
                cep_destinatario,
                uf_destinatario,
                municipio_destinatario,
                bairro_destinatario,
                logradouro_destinatario,
                numero_destinatario,
              }
            : {}),
          ...additionalData,
          itens: additionalData.itens?.filter((i) => i?.type !== 'service')?.length
            ? formData.itens
                ?.filter((i) => i?.type !== 'service')
                ?.map((item, index) => ({
                  ...item,
                  ...additionalData.itens?.[index],
                }))
            : formData.itens?.filter((i) => i?.type !== 'service'),
          formas_pagamento: additionalData.formas_pagamento?.length
            ? formData.formas_pagamento.map((item, index) => ({
                ...item,
                ...additionalData.formas_pagamento?.[index],
              }))
            : formData.formas_pagamento,
        }
        issuerInvoiceMutation.mutate(newIssuerInvoice)
      }
      if (invoiceServiceData && emission.nfse) {
        issuerInvoiceServiceMutation.mutate(invoiceServiceData)
      }
    }

    if (_submitInvoice) {
      handleSubmitInvoice()
    }
  }, [_submitInvoice])
  return (
    <Box {...props} color="textLight">
      <Text fontSize={12} textTransform="uppercase" {...titleProps}>
        Emissão de nota fiscal
      </Text>

      <SelectCard
        item={{ title: 'Emitir nota fiscal dos Produtos (NFCe)' }}
        setSelected={() =>
          handleClickIssuerInvoice({
            nfce: !emission.nfce,
            nfse: emission.nfse,
          })
        }
        default={userData?.nfce_active}
        selected={emission.nfce}
        disabled={isDisabledProduct}
        mt={4}
      />

      <SelectCard
        item={{ title: 'Emitir nota fiscal dos Serviços (NFSe)' }}
        setSelected={() =>
          handleClickIssuerInvoice({
            nfce: emission.nfce,
            nfse: !emission.nfse,
          })
        }
        default={userData?.nfse_active}
        selected={emission.nfse}
        disabled={isDisabledService}
        mt={6}
        mb={4}
      />

      {emission.nfse && (
        <FieldControl
          label="CPF do cliente"
          error={invoiceServiceData?.tomador?.cpf?.length !== 11}
          render={({ error }) => (
            <TextInput
              mask="CPF_MASK"
              name="CPF inválido"
              error={!!error}
              value={invoiceServiceData?.tomador?.cpf}
              onChange={(e) => {
                setInvoiceServiceData({
                  ...invoiceServiceData,
                  tomador: {
                    ...invoiceServiceData?.tomador,
                    cpf: e.target.value?.replace(/[^0-9]/g, ''),
                  },
                })
              }}
            />
          )}
        />
      )}

      {/* <Collapse in={issueInvoice} animateOpacity>
        <Button
          mt={6}
          size="block"
          kind="appointmentOptions"
          isDisabled={isDisabled}
          borderColor={invoiceErrorsLength ? 'danger' : 'modalBorder'}
          onClick={onClickInvoiceCustom}
        >
          Revisar dados da nota fiscal
        </Button>

        {!!invoiceErrorsLength && (
          <Text fontSize={10} color="danger" mt={1}>
            Alguns dados precisão ser corrigidos para emitir esta nota fiscal
          </Text>
        )}
      </Collapse> */}
    </Box>
  )
}

export const IssueInvoiceCustomForm = ({ initialValues, goBack, isLoading, isDisabled, onSubmit, type, ...props }) => {
  const { invoiceData, setInvoiceData, invoiceServiceData, setInvoiceServiceData } = useInvoiceData()
  return (
    <Box mt={-6} color="textLight" {...props}>
      <Formik
        initialValues={initialValues ?? { ...invoiceData, ...invoiceServiceData }}
        validationSchema={invoiceDataSchema}
        validateOnMount={true}
      >
        {(formik) => (
          <Form>
            {goBack && (
              <Flex w="100%" flexDir="row" align="center">
                <Icon
                  name="ArrowLeft"
                  color="primary"
                  cursor="pointer"
                  w="fit-content"
                  size={20}
                  onClick={() => {
                    setInvoiceData(formik.values)
                    goBack?.()
                  }}
                />
                <Text kind="bold" ml={3} fontSize={18}>
                  Nota Fiscal
                </Text>
              </Flex>
            )}

            <VStack spacing={4} align="normal" mt={6}>
              {type !== 'NFSe' && invoiceData && (
                <>
                  {/* <HStack align="normal">
                    <FieldControl
                      label="Natureza operação"
                      error={formik.errors.natureza_operacao}
                      isLoading={isLoading}
                      isDisabled={isDisabled}
                      render={() => (
                        <TextInput
                          name="natureza_operacao"
                          value={formik.values.natureza_operacao}
                          onChange={formik.handleChange}
                        />
                      )}
                    />

                    <FieldControl
                      label="Presença comprador"
                      error={formik.errors.presenca_comprador}
                      isLoading={isLoading}
                      isDisabled={isDisabled}
                      render={() => (
                        <Select
                          name="presenca_comprador"
                          size="lg"
                          bg="backgroundLayout"
                          borderColor="gray.800"
                          h="52px"
                          value={formik.values.presenca_comprador}
                          onChange={formik.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Operação presencial</option>
                          <option value="4">Entrega a domicílio</option>
                        </Select>
                      )}
                    />
                  </HStack>

                  <HStack align="normal">
                    <FieldControl
                      label="Modalidade frete"
                      error={formik.errors.modalidade_frete}
                      isLoading={isLoading}
                      isDisabled={isDisabled}
                      render={() => (
                        <Select
                          name="modalidade_frete"
                          size="lg"
                          bg="backgroundLayout"
                          borderColor="gray.800"
                          h="52px"
                          value={formik.values.modalidade_frete}
                          onChange={formik.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Por conta do destinatário</option>
                          <option value="2">Por conta de terceiros</option>
                          <option value="9">Sem frete</option>
                        </Select>
                      )}
                    />
                    <FieldControl
                      label="Local de destino"
                      error={formik.errors.local_destino}
                      isLoading={isLoading}
                      isDisabled={isDisabled}
                      render={() => (
                        <Select
                          name="local_destino"
                          size="lg"
                          bg="backgroundLayout"
                          borderColor="gray.800"
                          h="52px"
                          value={formik.values.local_destino}
                          onChange={formik.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Operação interna</option>
                          <option value="2">Operação interestadual</option>
                          <option value="3">Operação com exterior</option>
                        </Select>
                      )}
                    />
                  </HStack> */}

                  <FieldControl
                    label="Informações adicionais"
                    error={formik.errors.informacoes_adicionais_contribuinte}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    render={({ error }) => (
                      <TextInput
                        name="informacoes_adicionais_contribuinte"
                        error={!!error}
                        value={formik.values.informacoes_adicionais_contribuinte}
                        onChange={formik.handleChange}
                      />
                    )}
                  />
                </>
              )}
              {invoiceServiceData && type !== 'NFCe' && (
                <HStack align="normal">
                  <FieldControl
                    label="CPF do cliente"
                    error={invoiceServiceData?.tomador?.cpf?.length !== 11 && formik.values.tomador?.cpf?.length !== 11}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    render={({ error }) => (
                      <TextInput
                        name="CPF inválido"
                        error={!!error}
                        value={invoiceServiceData?.tomador?.cpf}
                        onChange={(e) => {
                          setInvoiceServiceData({
                            ...invoiceServiceData,
                            tomador: {
                              cpf: e.target.value,
                              razao_social:
                                formik.values.tomador?.razao_social?.length > 0
                                  ? formik.values.tomador?.razao_social
                                  : invoiceServiceData?.tomador?.razao_social,
                            },
                          })
                        }}
                      />
                    )}
                  />
                  <FieldControl
                    label="Nome do cliente"
                    error={
                      !invoiceServiceData?.tomador?.razao_social?.length && !formik.values.tomador?.razao_social?.length
                    }
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    render={({ error }) => (
                      <TextInput
                        name="Nome inválido"
                        error={!!error}
                        value={
                          formik.values.tomador?.razao_social?.length > 0
                            ? formik.values.tomador?.razao_social
                            : invoiceServiceData?.tomador?.razao_social
                        }
                        onChange={(e) =>
                          setInvoiceServiceData({
                            ...invoiceServiceData,
                            tomador: {
                              ...invoiceServiceData.tomador,
                              razao_social: e.target.value,
                            },
                          })
                        }
                      />
                    )}
                  />
                </HStack>
              )}
            </VStack>

            {type !== 'NFCe' && <IssuerInvoiceServicesForm isLoading={isLoading} isDisabled={isDisabled} mt={6} />}

            {type !== 'NFCe' && (
              <Box mt="16px">
                <SelectCard
                  item={{ title: 'Deseja adicionar endereço do cliente?' }}
                  disabled={isDisabled}
                  // setSelected={(ev) => console.log(formik.values)}
                  setSelected={(ev) => {
                    formik.setFieldValue('helpers.sendClientAddress', ev)
                  }}
                  selected={formik.values.helpers?.sendClientAddress}
                />
                <Collapse in={formik.values.helpers?.sendClientAddress} animateOpacity>
                  <IssuerInvoiceAddressForm isLoading={isLoading} />
                </Collapse>
              </Box>
            )}

            {type !== 'NFSe' && invoiceData && (
              <IssuerInvoiceItemsForm isLoading={isLoading} isDisabled={isDisabled} mt={6} />
            )}

            {type !== 'NFSe' && invoiceData && (
              <IssuerInvoicePaymentForm isLoading={isLoading} isDisabled={isDisabled} mt={6} />
            )}
            {type !== 'NFSe' && invoiceData && (
              <IssuerInvoiceCarrierForm isLoading={isLoading} isDisabled={isDisabled} mt={6} />
            )}

            {type !== 'NFSe' && invoiceData && (
              <IssuerInvoiceRecipientForm isLoading={isLoading} isDisabled={isDisabled} mt={6} />
            )}

            {/* <Box mt={6}>
              <SelectCard
                item={{ title: 'Customização avançada' }}
                disabled={isDisabled}
                setSelected={(ev) => formik.setFieldValue('helpers?.showAdvancedFields', ev)}
                selected={formik.values.helpers?.showAdvancedFields}
              />
              <Collapse in={formik.values.helpers?.showAdvancedFields} animateOpacity>
                <InputJsonField
                  label="Campos adicionais (JSON)"
                  error={formik.errors.helpers?.additionalData}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  helperText={
                    <>
                      Caso deseje adicionar dados específicos a sua nota fiscal, considere acessar a{' '}
                      <Link href="https://campos.focusnfe.com.br/nfe/NotaFiscalXML.html" target="_blank">
                        documentação dos campos
                      </Link>{' '}
                      aceitos na emissão de nota fiscal, os adicionando em formato de JSON deste campo.
                    </>
                  }
                  name="helpers.additionalData"
                  value={formik.values.helpers?.additionalData}
                  onChange={(ev) => {
                    formik.setFieldValue('helpers.additionalData', ev)
                  }}
                  containerProps={{ mt: 6 }}
                />
              </Collapse>
            </Box> */}

            {onSubmit && (
              <Button
                // isDisabled={
                //   isDisabled || type === 'NFSe'
                //     ? invoiceServiceData?.tomador?.cpf?.length !== 11 &&
                //       invoiceServiceData?.tomador?.razao_social?.length !== 3
                //     : !formik.isValid ||
                //       (type === 'NFSe' && invoiceServiceData && invoiceServiceData?.tomador?.cpf?.length !== 11)
                // }
                isDisabled={isDisabled || (type !== 'NFSe' && !formik.isValid)}
                isLoading={isLoading}
                onClick={() => {
                  setInvoiceData(formik.values)
                  onSubmit?.(formik)
                }}
                mt={8}
                w="full"
              >
                Emitir nota fiscal
              </Button>
            )}

            {goBack && (
              <Button
                onClick={() => {
                  setInvoiceData(formik.values)
                  goBack?.()
                }}
                mt={8}
                w="full"
              >
                Salvar dados
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}
