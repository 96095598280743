import React, { useState, useMemo, useEffect } from 'react'
import { format, endOfMonth, startOfMonth, subDays, addDays } from 'date-fns'
import {
  Layout,
  Table,
  Text,
  AppointmentDetailsModal,
  HistoryList,
  SelectFilter,
  Icon,
  InvoiceDetailsDrawer,
  IssuerInvoiceDrawer,
  ModalAlert,
  Link,
  TextInput,
  Button,
} from 'components'
import { useToast, Flex } from '@chakra-ui/react'
import { useBreakpoint, useInvoiceIssuer, useInvoice } from 'services/hooks'
import { useAppointmentDetailStore, useBarbershopStore, useInvoiceData } from 'services/stores'
import { useBarbershop } from 'services/hooks'
import {
  formatAppointmentDateTime,
  formatAppointmentStatus,
  formatPrice,
  formatNFSeParams,
  formatNFCeParams,
} from 'utils'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { getData } from 'services/storage'
import { theme } from 'styles'
import { useGetDownloadNF } from 'services/api/endpoints-v2/getDownloadNF'

const columns = [
  {
    key: 'info',
    width: 180,
    label: 'INFORMAÇÕES',
    render: (item) => {
      return (
        <>
          {item?.type === 'singleProductBarber' && (
            <Text fontSize={12} kind="medium">
              Venda para Barbeiro
            </Text>
          )}
          {item?.barber?.name && (
            <Text fontSize={12} kind="regular">
              {`Barbeiro: ${item?.barber?.name}`}
            </Text>
          )}
          {item?.type !== 'singleProductBarber' && (
            <Text noOfLines={1} fontSize={12} kind="regular">
              {`Cliente: ${item?.client?.name}`}
            </Text>
          )}
        </>
      )
    },
  },
  {
    key: 'total_services',
    width: 80,
    label: 'TOTAL DE SERVIÇOS',
    render: (item) => (
      <Text kind="medium" maxW="120px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {formatPrice(
          item?.appointmentServices?.reduce((acumulador, item) => {
            return acumulador + Number((item?.service_value || 0) - (item?.discount || 0))
          }, 0) || '-'
        )}
      </Text>
    ),
  },
  {
    key: 'total_products',
    width: 90,
    label: 'TOTAL DE PRODUTOS',
    render: (item) => (
      <Text kind="medium">
        {formatPrice(
          item?.appointmentProducts?.reduce((acumulador, item) => {
            return acumulador + Number((item?.product_value || 0) - (item?.discount || 0))
          }, 0) || 0
        )}
      </Text>
    ),
  },
  {
    width: 80,
    key: 'status',
    label: 'STATUS DA COMANDA',
    render: (item) => (
      <Text
        kind="medium"
        fontSize={13}
        color={
          item?.status === 'pending'
            ? 'danger'
            : item?.status === 'awaiting'
            ? 'primary'
            : item?.status === 'client_missed'
            ? 'textMedium'
            : item?.status === 'confirmed'
            ? 'blue'
            : !item?.status
            ? 'textMedium'
            : item?.status === 'client_arrived'
            ? 'orange'
            : 'success'
        }
      >
        {formatAppointmentStatus(item)}
      </Text>
    ),
  },
]

const invoiceStatusOptions = [
  { value: '', name: 'Todas' },
  { value: 'nao_emitido', name: 'Não emitida' },
  { value: 'processando_autorizacao', name: 'Em processamento' },
  { value: 'autorizado', name: 'Emitida' },
]

const appointmentStatusOptions = [
  { value: 'finished', name: 'Finalizados' },
  { value: 'pending', name: 'Pendente' },
  { value: 'signature', name: 'Assinatura' },
]

export const HistoricNFScreen = (props) => {
  const toast = useToast()
  const { isDesktop } = useBreakpoint()

  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [barberFilter, setBarberFilter] = useState(null)
  const [clientFilter, setClientFilter] = useState(null)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [invoiceType, setInvoiceType] = useState()
  const [invoiceStatusFilter, setInvoiceStatusFilter] = useState('')
  const [openInvoiceId, setOpenInvoiceId] = useState(null)
  const [createInvoiceForAppointmentId, setCreateInvoiceForAppointmentId] = useState(null)
  const [startDate, setStartDate] = useState(format(subDays(new Date(), 7), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(addDays(new Date(), 1), 'yyyy-MM-dd'))
  const [loading, setLoading] = useState(true)

  const { userData } = useBarbershopStore()
  const { setSelectedAppointment } = useAppointmentDetailStore()
  const { getBarbers } = useBarbershop()
  const { getAllInvoiceIssuer, updateInvoiceServiceIssuer, getHistoric, getDownloadNF } = useInvoiceIssuer()
  const { createInvoice, createInvoiceNfse } = useInvoice()

  const { setInvoiceData, clearInvoiceData, setInvoiceServiceData, setAppointmentData } = useInvoiceData()
  const [app, setApp] = useState()

  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const onCloseModals = () => {
    setDetailsModalVisible(false)
    setSelectedAppointment(null)
    clearInvoiceData()
    setOpenInvoiceId(null)
    setCreateInvoiceForAppointmentId(null)
    refetch()
  }

  const onSelectAppointment = (appointment) => {
    setSelectedAppointment(appointment)
    setDetailsModalVisible(true)
  }
  const [userLogged, setUserLogged] = useState({})

  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id, userLogged?.barbershop_id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),
    { enabled: !!userLogged?.id, select: (res) => res.data[0] }
  )

  const { refetch: downloadNF, isLoading: downloadLoading } = useGetDownloadNF({
    barbershop_id: userData?.barbershop?.id || userData?.id,
    start_date: startDate,
    end_date: endDate,
  })

  const getDownload = async () => {
    const download_links = await downloadNF()
  }

  const { data: appointmentsList, refetch } = useQuery(
    ['get-historic-nf'],
    () =>
      getHistoric({
        barbershop_id: userData?.barbershop?.id || userData?.id,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => setLoading(false),
      onError: (error) => {
        setLoading(false)
        toast({
          title: t('APPOINTMENT_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const updateMutate = useMutation('update-invoice-issuers', (id) => updateInvoiceServiceIssuer(id), {
    onSuccess: (d) => {
      const queryState = queryClient?.getQueryData(['get-historic-nf'])
      const newState = [...queryState]
      const index = queryState.findIndex(
        (appointment) => appointment?.invoices?.filter((inv) => inv?.id === d.id)?.length > 0
      )
      if (index !== -1) {
        const invoices = newState[index]?.invoices
        const invoice_index = invoices.findIndex((inv) => inv?.id === d.id)
        invoices[invoice_index] = {
          ...invoices[invoice_index],
          ...d,
        }
        newState[index] = {
          ...newState[index],
          invoices,
        }
        queryClient.setQueryData(['get-historic-nf'], newState)
      }
    },
  })

  const { data: barbers } = useQuery('get-team', () => getBarbers({ barbershop_id: userData.id }))

  const filteredData = useMemo(() => {
    if (barberFilter !== null) return appointmentsList.filter((i) => i?.barber?.id === barberFilter?.id)
    if (clientFilter !== null) return appointmentsList.filter((i) => i?.client?.id === clientFilter?.client_id)

    if (invoiceStatusFilter) {
      return appointmentsList.filter(
        (i) =>
          (invoiceStatusFilter === 'nao_emitido' && !i?.invoice?.status) || i?.invoice?.status === invoiceStatusFilter
      )
    }

    return appointmentsList
  }, [barberFilter, clientFilter, invoiceStatusFilter, appointmentsList])

  useEffect(() => {
    if (barberFilter === null) return refetch()
    if (clientFilter === null) return refetch()
    if (invoiceStatusFilter === null) return refetch()

    return appointmentsList
  }, [barberFilter, clientFilter, appointmentsList])

  const handleCreateInvoice = (appointment) => {
    const check_discount =
      (appointment?.appointmentServices?.reduce((acc, val) => acc + Number(val.discount || 0), 0) || 0) +
      (appointment?.appointmentProducts?.reduce((acc, val) => acc + Number(val.discount || 0), 0) || 0)
    if (appointment.type === 'NFSe') {
      setCreateInvoiceForAppointmentId(appointment.id)
      setInvoiceType('NFSe')
      setInvoiceServiceData(
        formatNFSeParams({
          type: 'historic',
          invoiceIssuerQuery,
          services_list: appointment?.appointmentServices,
          client: appointment?.client,
          barber: appointment?.barber,
          appointment_id: appointment?.id,
          discount: check_discount > 0 ? null : appointment?.discount,
          total_value: appointment?.total_value,
        })
      )
    } else {
      setCreateInvoiceForAppointmentId(appointment.id)
      setInvoiceType('NFCe')
      setInvoiceData(
        formatNFCeParams({
          type: 'historic',
          products_list: appointment?.appointmentProducts,
          client: appointment?.client,
          payments: appointment.appointment_payments,
          discount: check_discount > 0 ? null : appointment?.discount,
          total_value: appointment?.total_value,
          appointment_id: appointment?.id,
          invoiceIssuerQuery,
        })
      )
    }
  }

  const issuerInvoiceServiceMutation = useMutation('invoice-records-nfse', createInvoiceNfse, {})

  const emitirTodasNFSe = async () => {
    for await (const appointment of filteredData) {
      const check_discount =
        (appointment?.appointmentServices?.reduce((acc, val) => acc + Number(val.discount || 0), 0) || 0) +
        (appointment?.appointmentProducts?.reduce((acc, val) => acc + Number(val.discount || 0), 0) || 0)
      issuerInvoiceServiceMutation.mutate(
        formatNFSeParams({
          type: 'historic',
          invoiceIssuerQuery,
          services_list: appointment?.appointmentServices,
          client: appointment?.client,
          barber: appointment?.barber,
          appointment_id: appointment?.id,
          discount: check_discount > 0 ? null : appointment?.discount,
          total_value: appointment?.total_value,
        })
      )
    }
    toast({
      title: 'Solicitação de NFSe enviada com sucesso!',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const issuerInvoiceMutation = useMutation('invoice-records', createInvoice, {})

  const emitirTodasNFCe = async () => {
    for await (const appointment of filteredData) {
      const total_products =
        appointment?.formatted_products?.reduce((acumulador, item) => {
          return acumulador + Number(item?.value || 0)
        }, 0) || 0

      if (total_products && total_products > 0) {
        const check_discount =
          (appointment?.appointmentServices?.reduce((acc, val) => acc + Number(val.discount || 0), 0) || 0) +
          (appointment?.appointmentProducts?.reduce((acc, val) => acc + Number(val.discount || 0), 0) || 0)

        const formParams = formatNFCeParams({
          type: 'historic',
          products_list: appointment?.appointmentProducts,
          client: appointment?.client,
          payments: appointment.appointment_payments,
          discount: check_discount > 0 ? null : appointment?.discount,
          total_value: appointment?.total_value,
          appointment_id: appointment?.id,
          invoiceIssuerQuery,
        })

        const { helpers, ...rest } = formParams

        const {
          presenca_comprador,
          nome_transportador,
          cnpj_transportador,
          cpf_transportador,
          inscricao_estadual_transportador,
          endereco_transportador,
          municipio_transportador,
          uf_transportador,
          nome_destinatario,
          cpf_destinatario,
          telefone_destinatario,
          cnpj_destinatario,
          indicador_inscricao_estadual_destinatario,
          cep_destinatario,
          uf_destinatario,
          municipio_destinatario,
          bairro_destinatario,
          logradouro_destinatario,
          numero_destinatario,
          ...formData
        } = JSON.parse(JSON.stringify(rest).replaceAll('""', null))

        const additionalData = helpers?.showAdvancedFields ? helpers?.additionalData.jsObject : {}

        const newIssuerInvoice = {
          ...formData,
          presenca_comprador,
          ...(presenca_comprador === '4'
            ? {
                nome_transportador,
                cnpj_transportador: helpers?.carrierType === 'PJ' ? cnpj_transportador : '',
                cpf_transportador: helpers?.carrierType === 'PF' ? cpf_transportador : '',
                inscricao_estadual_transportador,
                endereco_transportador,
                municipio_transportador,
                uf_transportador,
              }
            : {}),
          ...(helpers?.addRecipientData
            ? {
                nome_destinatario,
                cpf_destinatario: helpers?.recipientType === 'PF' ? cpf_destinatario : '',
                cnpj_destinatario: helpers?.recipientType === 'PJ' ? cnpj_destinatario : '',
                telefone_destinatario,
                indicador_inscricao_estadual_destinatario,
                cep_destinatario,
                uf_destinatario,
                municipio_destinatario,
                bairro_destinatario,
                logradouro_destinatario,
                numero_destinatario,
              }
            : {}),
          ...additionalData,
          itens: additionalData.itens?.length
            ? formData.itens.map((item, index) => ({
                ...item,
                ...additionalData.itens?.[index],
              }))
            : formData.itens,
          formas_pagamento: additionalData.formas_pagamento?.length
            ? formData.formas_pagamento.map((item, index) => ({
                ...item,
                ...additionalData.formas_pagamento?.[index],
              }))
            : formData.formas_pagamento,
          issuer_id: invoiceIssuerQuery.data.id,
          appointment_id: appointment?.id,
        }
        issuerInvoiceMutation.mutate(newIssuerInvoice)
      }
    }
    toast({
      title: 'Solicitação de NFCe enviada com sucesso!',
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  return (
    <Layout {...props} headerTitle={t('HISTORIC_TITLE')}>
      <InvoiceDetailsDrawer invoiceId={openInvoiceId} onClose={onCloseModals} />
      <IssuerInvoiceDrawer appointmentId={createInvoiceForAppointmentId} onClose={onCloseModals} type={invoiceType} />

      {/* Filters */}
      <Flex>
        <TextInput
          noBorder
          type="date"
          name="start_date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          label={t('Data Inicial')}
          padding={theme.pxToRem(10)}
          mr={theme.pxToRem(12)}
          w={theme.pxToRem(150)}
        />
        <TextInput
          noBorder
          type="date"
          name="end_date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          label={t('Data Final')}
          padding={theme.pxToRem(10)}
          mr={theme.pxToRem(12)}
          w={theme.pxToRem(150)}
        />
        <Flex mt={theme.pxToRem(28)}>
          <Button
            icon="Search"
            size="small"
            iconColor="black"
            mr="12px"
            onClick={() => {
              setLoading(true)
              refetch()
            }}
            disabled={loading}
          >
            Pesquisar
          </Button>
          <SelectFilter
            onChange={(value) => setBarberFilter(barbers?.find((i) => i?.name?.trim() === value?.trim()))}
            value={barberFilter?.name}
            placeholder="Todos"
            options={barbers || []}
            label="BARBER"
            h={42}
          />
          {/* <SelectFilter
            value={invoiceStatusFilter}
            onChange={setInvoiceStatusFilter}
            options={invoiceStatusOptions}
            withoutPlaceholder
            label="Nota fiscal"
          /> */}
          <Button
            icon="Download"
            size="small"
            iconColor="black"
            mr="12px"
            onClick={getDownload}
            isLoading={downloadLoading}
            disabled={downloadLoading || loading}
          >
            Download XML das notas do período
          </Button>
          {/* <Button
            icon="Download"
            size="small"
            iconColor="black"
            mr="12px"
            onClick={emitirTodasNFSe}
            isLoading={issuerInvoiceServiceMutation.isLoading}
            disabled={issuerInvoiceServiceMutation.isLoading || loading}
          >
            Emitir NFSe
          </Button> */}
          {/* <Button
            icon="Download"
            size="small"
            iconColor="black"
            mr="12px"
            onClick={emitirTodasNFCe}
            isLoading={issuerInvoiceServiceMutation.isLoading}
            disabled={issuerInvoiceServiceMutation.isLoading || loading}
          >
            Emitir NFCe
          </Button> */}
        </Flex>
      </Flex>
      {isDesktop ? (
        <Table
          loading={loading}
          data={filteredData || []}
          columns={[
            {
              key: 'command',
              width: 165,
              label: 'COMANDA',
              render: (item) => {
                return (
                  <>
                    <Text fontSize={12} kind="regular">
                      {formatAppointmentDateTime(item)}
                    </Text>
                    <Flex>
                      <Text fontSize={12} kind="regular">
                        Nº da comanda:
                      </Text>
                      <Link onClick={() => onSelectAppointment(item)} ml={2} fontSize={12} kind="regular">
                        {item?.id}
                      </Link>
                    </Flex>
                  </>
                )
              },
            },
            ...columns,
            {
              key: 'nfse',
              width: 100,
              label: 'Nota fiscal de serviço',
              render: (appointment) => {
                if (appointment.status !== 'finished') return <></>

                const total_services =
                  appointment?.formatted_services?.reduce((acumulador, item) => {
                    return acumulador + Number(item?.service_value || 0)
                  }, 0) || 0

                if (!total_services || total_services === 0) {
                  return <Text>-</Text>
                }
                const autorizado = appointment?.invoices?.filter(
                  (i) => i?.status === 'autorizado' && i?.type === 'NFSe'
                )
                const processando_autorizacao = appointment?.invoices?.filter(
                  (i) => i?.status === 'processando_autorizacao' && i?.type === 'NFSe'
                )

                if (autorizado?.length > 0) {
                  return autorizado?.map((item) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      p={2.5}
                      bg="gray.800"
                      borderRadius={6}
                      _hover={{ bg: 'gray.900' }}
                      onClick={(ev) => {
                        clearInvoiceData()
                        ev.stopPropagation()
                        setOpenInvoiceId(item?.id)
                      }}
                      cursor="pointer"
                    >
                      <Text fontSize={12}>Visualizar</Text>
                      <Icon
                        name={
                          item?.status === 'processando_autorizacao'
                            ? 'Clock'
                            : item?.status === 'autorizado'
                            ? 'EyeOpen'
                            : 'Doc'
                        }
                        size={16}
                        color="primary"
                      />
                    </Flex>
                  ))
                }
                if (processando_autorizacao?.length > 0) {
                  return processando_autorizacao?.map((item) => (
                    <Flex
                      onClick={() => updateMutate.mutate(item.id)}
                      align="center"
                      justify="space-between"
                      p={2.5}
                      bg="gray.700"
                      borderRadius={6}
                      cursor="pointer"
                    >
                      <Text fontSize={12}>Processando</Text>
                    </Flex>
                  ))
                }
                return (
                  <Flex
                    align="center"
                    p={2.5}
                    bg="gray.800"
                    borderRadius={6}
                    _hover={{ bg: 'gray.900' }}
                    onClick={() => {
                      handleCreateInvoice({ ...appointment, type: 'NFSe' })
                      setAppointmentData(appointment)
                    }}
                    cursor="pointer"
                  >
                    <Text fontSize={12}>Emitir NFSe</Text>
                  </Flex>
                )
              },
            },
            {
              key: 'nfce',
              width: 100,
              label: 'Nota fiscal de produto',
              render: (appointment) => {
                if (appointment.status !== 'finished') return <></>
                const total_products =
                  appointment?.formatted_products?.reduce((acumulador, item) => {
                    return acumulador + Number(item?.value || 0)
                  }, 0) || 0

                if (!total_products || total_products === 0) {
                  return <Text>-</Text>
                }
                const autorizado = appointment?.invoices?.filter(
                  (i) => i?.status === 'autorizado' && i?.type === 'NFCe'
                )
                const processando_autorizacao = appointment?.invoices?.filter(
                  (i) => i?.status === 'processando_autorizacao' && i?.type === 'NFCe'
                )
                if (autorizado?.length > 0) {
                  return autorizado?.map((item) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      p={2.5}
                      bg="gray.800"
                      borderRadius={6}
                      _hover={{ bg: 'gray.900' }}
                      onClick={(ev) => {
                        clearInvoiceData()
                        ev.stopPropagation()
                        setOpenInvoiceId(item?.id)
                      }}
                      cursor="pointer"
                    >
                      <Text fontSize={12}>Visualizar</Text>
                      <Icon
                        name={
                          item?.status === 'processando_autorizacao'
                            ? 'Clock'
                            : item?.status === 'autorizado'
                            ? 'EyeOpen'
                            : 'Doc'
                        }
                        size={16}
                        color="primary"
                      />
                    </Flex>
                  ))
                }
                if (processando_autorizacao?.length > 0) {
                  return processando_autorizacao?.map((item) => (
                    <Flex
                      onClick={() => updateMutate.mutate(item.id)}
                      align="center"
                      justify="space-between"
                      p={2.5}
                      bg="gray.700"
                      borderRadius={6}
                      cursor="pointer"
                    >
                      <Text fontSize={12}>Processando</Text>
                    </Flex>
                  ))
                }
                return (
                  <Flex
                    align="center"
                    p={2.5}
                    bg="gray.800"
                    borderRadius={6}
                    _hover={{ bg: 'gray.900' }}
                    onClick={() => {
                      handleCreateInvoice({ ...appointment, type: 'NFCe' })
                      setAppointmentData(appointment)
                    }}
                    cursor="pointer"
                  >
                    <Text fontSize={12}>Emitir NFCe</Text>
                  </Flex>
                )
              },
            },
          ]}
        />
      ) : (
        <HistoryList loading={loading} data={appointmentsList || []} onSelect={onSelectAppointment} />
      )}
      {detailsModalVisible && (
        <AppointmentDetailsModal
          app={app}
          setAppointmentId={setApp}
          isModalOpen={detailsModalVisible}
          onClose={onCloseModals}
        />
      )}
      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        // onConfirm={() => setAlertModalOpen(false)}
        // primaryButtonLabel={t('DELETE')}
        modalTitle="Nota fiscal ainda em processamento"
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          Aguarde que a nota ainda está sendo processada
        </Text>
      </ModalAlert>
    </Layout>
  )
}

HistoricNFScreen.path = '/nota-fiscal/historico'
HistoricNFScreen.title = 'Histórico'
HistoricNFScreen.secured = true
