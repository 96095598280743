import React, { useEffect, useMemo, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Table, Box } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useQuery } from 'react-query'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useBarbershopGoals } from 'services/hooks/use-barber-goals'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { resumeColumns } from './settings'
import { MultiColumnChart } from 'components/molecules/MultiColumnChart'
import { DetailGoalsModal } from './DetailsGoals'

export const ResumeGoals = ({ selectedDay }) => {
  const { userData } = useBarbershopStore()
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [selectedBarber, setSelectedBarber] = useState(null)
  const { onLogout } = useLogoutPaymentIssues()
  const { getAllGoalsResume } = useBarbershopGoals()
  const toast = useToast()

  useEffect(() => {
    onLogout()
  }, [])

  const queryPlans = useQuery(
    ['get-resume-goals', selectedDay],
    () =>
      getAllGoalsResume({
        body: {
          start_date: format(startOfMonth(selectedDay), 'yyyy-MM-dd'),
          end_date: format(endOfMonth(selectedDay), 'yyyy-MM-dd'),
          barbershop_id: userData?.id,
        },
      }),
    {
      onError: (error) => {
        toast({
          title: 'Erro ao buscar dados',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      refetchOnWindowFocus: false,
    }
  )

  const graphic_data = useMemo(() => {
    const series = [
      {
        name: 'Total Vendido',
        data: [],
      },
      {
        name: 'Meta Prevista',
        data: [],
      },
    ]

    const categories = []
    queryPlans.data?.goals?.forEach((i) => {
      series[0].data.push(i.total_value)
      series[1].data.push(i.barber_goals === 'Meta não cadastrada' ? 0 : i.barber_goals?.[i.barber_goals?.length - 1])
      categories.push(i.barber.name)
    })

    return {
      series,
      categories,
    }
  }, [queryPlans.data?.goals])

  return (
    <Box>
      <MultiColumnChart
        h="400px"
        w="100%"
        series={graphic_data?.series || []}
        type="money"
        title="Metas dos colaboradores"
        name="Metas dos colaboradores"
        categories={graphic_data?.categories || []}
      />

      <Box flex={1} mt="12px">
        <Table
          data={queryPlans.data?.goals ?? []}
          loading={queryPlans.isLoading}
          columns={resumeColumns}
          isSelectableRow
          onSelect={(item) => {
            setSelectedBarber(item)
            setDetailsModalOpen(true)
          }}
        />
      </Box>
      <DetailGoalsModal
        isOpen={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        selectedBarber={selectedBarber}
      />
    </Box>
  )
}
