import React from 'react'
import { Flex, Table } from 'components'
import {
  useAppointmentStore,
  useBarbershopStore,
} from 'services/stores'
import { useQuery } from 'react-query'
import { useBarber } from 'services/hooks/use-barber'
import { columns } from './settings'

export const AppointmentSignatureSelectBarber = ({
  goBack,
  clickEmpty,
}) => {
  const { userData } = useBarbershopStore()
  const { setBarber, barbersList, setBarbersList, setDate } =
    useAppointmentStore()
  const {getOnlyBarbers} = useBarber()

  const { isLoading } = useQuery(['get-only-barbers'], () => getOnlyBarbers({id: userData?.id}), {
    onSuccess: (data) => {
      setBarbersList(data)
    },
  })

  const handleSelectBarber = (barber) => {
    setBarber(barber)
    if (!clickEmpty) {
      setDate(null)
    }
    goBack()
  }

  return (
    <Flex flexDirection="column">
      <Table
        withoutHeader
        isSelectableRow
        data={barbersList || []}
        loading={isLoading}
        columns={columns}
        onSelect={handleSelectBarber}
      />
    </Flex>
  )
}