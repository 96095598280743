import { api } from 'services/api/config'

export const useBarberReleases = () => {
  const deleteBarberRelases = async (props) => {
    const response = await api.delete(`v3/barbershop/finances/delete-barber-releases/${props.id}`);
    return response.data
  }

  const deletePaidAppointments = async (props) => {
    const response = await api.put(`v3/barbershop/finances/delete-paid-barber`, props?.body);
    return response.data
  }

  const deleteCollaboratorPaidAppointments = async (props) => {
    const response = await api.put(`v3/barbershop/finances/delete-paid-collaborator`, props?.body);
    return response.data
  }

  return {
    deleteCollaboratorPaidAppointments,
    deletePaidAppointments,
    deleteBarberRelases,
  }
}
