import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, ModalContent, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { benefits } from './settings'
import { Box, Text, Button, Icon } from 'components/atoms'
import { FreeTrialModalBg } from 'assets/img/backgrounds'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useMutation } from 'react-query'

import { api } from 'services/api/config'
import { BARBERSHOP_SUBSCRIPTION } from 'services/api/endpoints'

export const SignPremiumModal = ({ isOpen }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const [step, setStep] = useState(0)

  async function create(params) {
    const res = await api
      .post(BARBERSHOP_SUBSCRIPTION, params.params)
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'create_subscription',
    (params) =>
      create({
        params: {
          plan_identifier: 'basic_plan',
        },
      }),
    {
      onSuccess: (data) => {
        setStep(data)
        userData.account_status = data?.account_status
        userData.iugu_plan_id = data?.iugu_plan_id
      },
    }
  )
  const onConfirm = async () => {
    if (step === 0) {
      mutate.mutate()
      return
    }
    var win = window.open(step?.invoice?.secure_url, '_blank')
    win.focus()
  }

  const Container = isDesktop ? Flex : ModalContent

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay>
        <Container
          borderRadius="xl"
          mt={0}
          h="100vh"
          align="center"
          justify="center"
          bg="transparent"
          pb={theme.pxToRem(isDesktop ? 0 : 800)}
        >
          <Flex
            bgColor="backgroundComponent"
            backgroundImage={`url(${FreeTrialModalBg})`}
            bgRepeat="no-repeat"
            pos={!isDesktop && 'absolute'}
            top={!isDesktop && 0}
            maxW={theme.pxToRem(530)}
            maxH={theme.pxToRem(850)}
            px={theme.pxToRem(32)}
            pt={theme.pxToRem(80)}
            pb={theme.pxToRem(40)}
            alignItems="center"
            justifyContent="center"
            flexDir="Column"
            borderRadius="xl"
          >
            <Flex
              flexDir="column"
              w="100%"
              alignItems="center"
              justifyContent="center"
              pt={'1vh'}
              mb={theme.pxToRem(12)}
            >
              <Flex wrap="wrap">
                <Text kind="extraBold" fontSize={22} mr={theme.pxToRem(6)}>
                  {t('SIGN_PREMIUM_MODAL_TITLE')}
                </Text>
                <Text kind="extraBold" fontSize={22} color="primary">
                  {t('SIGN_PREMIUM_MODAL_TITLE_SPAN')}
                </Text>
              </Flex>
            </Flex>

            <Text
              w="80%"
              textAlign="center"
              color="tabsBorder"
              fontSize={12}
              mb={theme.pxToRem(12)}
            >
              {t('FREE_TRIAL_MODAL_SUBTITLE')}
            </Text>

            <Box w="100%">
              <Text
                kind="extraBold"
                fontSize={18}
                textAlign="center"
                mb={theme.pxToRem(24)}
              >
                {t('MEMBERSHIP_BENEFITS')}
              </Text>

              <Flex w="100%" justify="space-between">
                <Flex w="62%" />
                <Flex
                  w="38%"
                  align="center"
                  justify="space-between"
                  mb={theme.pxToRem(8)}
                >
                  <Text
                    kind="bold"
                    fontSize={12}
                    w="40%"
                    textAlign="center"
                    color="grey"
                  >
                    {t('BASIC')}
                  </Text>
                  <Flex
                    flexDir="column"
                    align="center"
                    w="60%"
                    mt={theme.pxToRem(-16)}
                  >
                    <Icon name="Crown" fill="primary" size={16} />
                    <Text
                      kind="bold"
                      fontSize={12}
                      textAlign="center"
                      color="textColorLighter"
                    >
                      Premium
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexDir="column" w="100%">
                {benefits.map((item, index) => (
                  <Flex
                    key={`firstColumn_benefit_${index}`}
                    alignItems="center"
                    borderWidth={1}
                    py={!isDesktop && theme.pxToRem(8)}
                    h={theme.pxToRem(!isDesktop ? 40 : 36)}
                    borderColor="border"
                    borderTopRadius={theme.pxToRem(index === 0 && 8)}
                    borderBottomRadius={theme.pxToRem(index === 8 && 8)}
                    justify="space-between"
                    w="100%"
                  >
                    <Text
                      kind="semiBold"
                      fontSize={12}
                      ml={theme.pxToRem(8)}
                      w="82%"
                    >
                      {t(item.text)}
                    </Text>
                    <Flex w="40%">
                      <Icon
                        w="82%"
                        name={item?.basic ? 'CheckCircle' : 'CloseCircle'}
                        fill={item?.basic ? 'green' : 'danger'}
                        size={16}
                        mt={theme.pxToRem(3)}
                      />
                      <Icon
                        w="60%"
                        name="CheckCircle"
                        fill={item?.premium ? 'green' : 'danger'}
                        size={16}
                        mt={theme.pxToRem(3)}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Box>

            {step !== 0 && (
              <Text
                mt={theme.pxToRem(12)}
                mb={theme.pxToRem(8)}
                kind="bold"
                color="primary"
                align="center"
              >
                {step?.invoice?.secure_url}
              </Text>
            )}

            <Button
              isLoading={mutate.isLoading}
              kind="primary"
              w="100%"
              onClick={onConfirm}
              mt={theme.pxToRem(12)}
            >
              {step === 0
                ? t('SIGN_PREMIUM_MODAL_BUTTON', {
                    currency: t('CURRENCY'),
                  })
                : t('OPEN_LINK')}
            </Button>
          </Flex>
        </Container>
      </ModalOverlay>
    </Modal>
  )
}
