import firebase from 'firebase/app'
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCANgmKjQwKC4wyoUU9rHqEVXT9dLdchy8",
  authDomain: "bestbarbers-31a63.firebaseapp.com",
  databaseURL: "https://bestbarbers-31a63.firebaseio.com",
  projectId: "bestbarbers-31a63",
  storageBucket: "bestbarbers-31a63.appspot.com",
  messagingSenderId: "929037112293",
  appId: "1:929037112293:web:31714f89eade200231950c",
  measurementId: "G-BN40FLJ303"
};


firebase.initializeApp(firebaseConfig)
export const firebaseAnalytics = firebase.analytics()