import { api } from 'services/api/config'

export const useOccupancyRate = () => {
  const getOccupancyRate = async (props) => {
    const response = await api({
      url: `v3/barbershop/indicators/occupancy-rate/${props.id}`,
      params: {
        type: props?.type,
        start_date: props.startDate,
        end_date: props.endDate,
        barber_id: props?.barber?.id,
        status: props?.status,
      },
    })
    return response.data
  }

  return {
    getOccupancyRate,
  }
}
