import React, { useEffect } from 'react'
import {
  Center,
  useTheme,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useToast,
} from '@chakra-ui/react'
import { Flex, Text, Box, Button, Link } from 'components'
import { useHistory } from 'react-router-dom'
import { useBreakpoint } from 'services/hooks'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import activateImg from 'assets/img/backgrounds/barbershopOnboarding/bb-activate-illustration.png'
import { UPDATE_BARBERSHOP_STATUS } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useMutation } from 'react-query'

export const SuccessStepModal = ({ isOpen, onClose }) => {
  const toast = useToast()
  const { userData, setUserData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()

  async function changeStatus(params) {
    const res = await api
      .put(
        UPDATE_BARBERSHOP_STATUS(
          params,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-status',
    (params) => changeStatus(params),
    {
      onSuccess: (data) => {
        setUserData({
          ...userData,
          ...data?.userData,
        })
        toast({
          title: t('PUBLISH_SUCCESS'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/perfil/dados?register=true')
      },
      onError: (error) => {
        toast({
          title: t('PUBLISH_ERROR'),
          description: error.toString().substring(7),
          status: 'danger',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onClickContinue = async () => {
    mutate.mutate('publish')
  }

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_success')
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        padding={theme.pxToRem(isDesktop ? 40 : 24)}
        bg="menuBackground"
        borderWidth={1}
        borderColor="modalBorder"
        borderRadius={8}
      >
        <ModalBody w="100%">
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            w="100%"
            h="100%"
          >
            <Image src={activateImg} w={theme.pxToRem(330)} />

            <Text
              kind="extraBold"
              fontSize={theme.pxToRem(24)}
              align="center"
              mt={theme.pxToRem(24)}
            >
              {t('PROFILE_COMPLETED')}
            </Text>
            <Flex
              mt={theme.pxToRem(24)}
              flexDir="column"
              w="100%"
              align="center"
              justify="center"
            >
              <Flex w="100%" align="center" justify="center">
                <Text
                  kind="semiBold"
                  fontSize={theme.pxToRem(13)}
                  align="center"
                  mr={theme.pxToRem(6)}
                >
                  {t('USE_APP_1')}
                </Text>
                <Text
                  kind="semiBold"
                  fontSize={theme.pxToRem(13)}
                  align="center"
                  color="primary"
                >
                  {t('USE_APP_2')}
                </Text>
              </Flex>
              <Text
                kind="semiBold"
                fontSize={theme.pxToRem(13)}
                align="center"
                mr={theme.pxToRem(6)}
              >
                {t('USE_APP_3')}
              </Text>
              <Text
                kind="semiBold"
                fontSize={theme.pxToRem(13)}
                // mt={theme.pxToRem(24)}
                align="center"
                mr={theme.pxToRem(6)}
              >
                {t('USE_APP_4')}
              </Text>
            </Flex>

            <Button
              size="block"
              kind="primary"
              mt={theme.pxToRem(24)}
              onClick={onClickContinue}
              loading={mutate.isLoading}
            >
              {t('USE_APP_2')}
            </Button>
            <Link
              onClick={() => {
                mutate.mutate('unpublish')
                history.push('/perfil/dados?register=true')
              }}
            >
              <Text
                kind="bold"
                fontSize={theme.pxToRem(16)}
                mt={theme.pxToRem(24)}
                align="center"
              >
                {t('CONTINUE_WITHOUT_ACTIVATE')}
              </Text>
            </Link>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

SuccessStepModal.path = '/cadastro-concluido'
SuccessStepModal.title = ''
SuccessStepModal.secured = true
