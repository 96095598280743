import * as React from 'react'

function SvgCloseSlim(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7136 3.28586C12.9739 3.54621 12.9212 4.02108 12.5957 4.34652L4.34616 12.5961C4.02073 12.9215 3.54585 12.9743 3.2855 12.7139C3.02515 12.4536 3.07792 11.9787 3.40335 11.6533L11.6529 3.40371C11.9784 3.07827 12.4532 3.02551 12.7136 3.28586Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.28641 3.28586C3.54676 3.02551 4.02163 3.07827 4.34707 3.40371L12.5966 11.6533C12.9221 11.9787 12.9748 12.4536 12.7145 12.7139C12.4541 12.9743 11.9793 12.9215 11.6538 12.5961L3.40426 4.34652C3.07882 4.02108 3.02606 3.54621 3.28641 3.28586Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCloseSlim
