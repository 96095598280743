import React, { useEffect, useState } from 'react'
import { useTheme, Flex } from '@chakra-ui/react'
import { Layout, Table, TextInput, Box, Text, Icon, SubscriptionDetailModal, Link, SelectFilter } from 'components'
import { Search } from 'assets/icons'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { GET_BARBERSHOP_SUBSCRIPTION } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { formatPhone } from 'utils/phone-formater'
import { t } from 'services/translation'
import { statuses } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

const mobileColumns = [
  { key: 'image', label: '', type: 'image', placeholder: 'client' },
  { key: 'name', label: 'NAME_TABLE' },
  { key: 'phone', label: 'PHONE_TABLE' },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text color={item?.status === 'ACTIVE' && !item?.inactive ? 'success' : 'danger'}>
        {item?.inactive ? 'Cancelado' : t(item?.status)}
      </Text>
    ),
  },
]

export const SubscribersScreen = ({ ...props }) => {
  const [modalDetailOpen, setModalDetailOpen] = useState(false)
  const [subscribers, setSubscribers] = useState([])
  const [query, setQuery] = useState('')
  const [client, setClient] = useState()
  const [status, setStatus] = useState()
  const { t } = useTranslation()

  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const { onLogout } = useLogoutPaymentIssues()

  const desktopColumns = [
    {
      key: 'image',
      label: '',
      type: 'image',
      placeholder: 'client',
      render: (item) => item?.client?.profile_image_url,
    },
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => <Text>{item?.client?.name}</Text>,
    },
    {
      key: 'email',
      label: 'EMAIL_TABLE',
      render: (item) => <Text>{item?.client?.user?.email}</Text>,
    },
    {
      key: 'phone',
      label: 'PHONE_TABLE',
      render: (item) => (
        <Link href={`https://wa.me/${item?.client?.user?.phone}`}>
          {formatPhone(item?.client?.user?.phone, userData)}
        </Link>
      ),
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => (
        <Text color={item?.status === 'ACTIVE' && !item?.inactive ? 'success' : 'danger'}>
          {item?.inactive ? 'Cancelado' : t(item?.status)}
        </Text>
      ),
    },
  ]
  useEffect(() => {
    onLogout()
  }, [])
  async function getManagerSubscription({ queryKey }) {
    const res = await api
      .get(GET_BARBERSHOP_SUBSCRIPTION(userData?.id, queryKey[1]?.name))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading, refetch } = useQuery(['get-manager-subscription', status], getManagerSubscription, {
    onSuccess: (value) => {
      setSubscribers({ data: value, filtered: value })
    },
  })

  const onCloseModal = () => {
    refetch()
    setClient()
    setModalDetailOpen(false)
  }

  const emptyList = () => (
    <Flex align="center" flexDir="column" mt={theme.pxToRem(24)}>
      <Icon name="Scissors" size={24} mb={theme.pxToRem(12)} />
      <Text>{t('NO_SUBSCRIBERS')}</Text>
    </Flex>
  )

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setSubscribers({ ...subscribers, filtered: subscribers?.data })
    } else {
      const search_result = subscribers?.data.filter(
        (item) =>
          String(item?.client?.name.toLowerCase()).includes(query.toLowerCase()) ||
          String(item?.client?.user?.email?.toLowerCase()).includes(query.toLowerCase())
      )
      setSubscribers({ ...subscribers, filtered: search_result })
    }
  }

  const handleSelectCustomer = (client) => {
    setClient(client)
    setModalDetailOpen(true)
  }

  return (
    <Layout {...props} headerTitle={t('SUBSCRIBERS')} modalFilter modalFilterWidth="12%">
      <Box flex={1}>
        <TextInput
          noBorder
          type="text"
          width="full"
          color="white"
          placeholder={t('SEARCH_SUBSCRIBERS')}
          value={query}
          mb={!isDesktop && theme.pxToRem(16)}
          onChange={(event) => onSearch(event.target.value)}
          leftElement={<Search color={colors.primary} />}
        />
        <Flex w="10%" mt="16px">
          <SelectFilter
            onChange={(e) => {
              setStatus(statuses?.find((i) => i?.name === e))
            }}
            value={status?.name}
            options={statuses}
            label={'Status'}
          />
        </Flex>

        {subscribers?.filtered?.length === 0 && emptyList()}
        {subscribers?.filtered?.length !== 0 && (
          <Table
            isSelectableRow={isDesktop}
            data={subscribers?.filtered || []}
            loading={isLoading}
            columns={isDesktop ? desktopColumns : mobileColumns}
            onSelect={handleSelectCustomer}
          />
        )}
      </Box>
      <SubscriptionDetailModal
        isOpen={modalDetailOpen}
        onClose={onCloseModal}
        barbershop_id={client?.barbershop_id}
        signature_id={client?.signature_club_id}
        client_id={client?.client_id}
      />
    </Layout>
  )
}

SubscribersScreen.path = '/assinantes'
SubscribersScreen.title = 'Assinantes'
SubscribersScreen.secured = true
