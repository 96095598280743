import React, { useEffect, useMemo, useState } from 'react'
import { useToast, useTheme, Select } from '@chakra-ui/react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Flex, Box, Text, TextInput, Button, CheckBox, Icon } from 'components/atoms'
import { Calendar, WeekdayCard } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint, useBlockedTime, usePermissionCollaborator } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { ModalMenu } from 'components/molecules'
import { api } from 'services/api/config'
import { useMutation, useQuery } from 'react-query'
import { BLOCK_TIMES, BLOCK_TIMES_BY_PERIOD, ONLY_BARBERS } from 'services/api/endpoints'
import { daysOfWeek } from 'utils'
import { WEEK_DAYS } from 'utils/services'
import { ModalButtonBackground } from 'components-v2/atoms'

export const BlockedTimeModal = ({ isModalOpen, onClose, ...props }) => {
  const [selectedDay, setSelectedDay] = useState()
  const [startHour, setStartHour] = useState()
  const [finishHour, setFinishHour] = useState()
  const [everyWeekDayCheck, setEveryWeekDayCheck] = useState(false)
  const [everyDayCheck, setEveryDayCheck] = useState(false)
  const [byPeriod, setByPeriod] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [title, setTitle] = useState('')
  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const [value, setValue] = useState()
  const [all, setAll] = useState(true)
  const toast = useToast()
  const theme = useTheme()
  const { t } = useTranslation()
  const { deleteBlockedTime, createExceptionBlockedTime } = useBlockedTime()
  const blockedTimes = usePermissionCollaborator('blocked_hours')

  async function createBlockedTimes(params) {
    const res = await api
      .post(BLOCK_TIMES(), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function createPeriodBlockedTimes(params) {
    const res = await api
      .post(BLOCK_TIMES_BY_PERIOD, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('change-blocked-time', (params) => createBlockedTimes(params), {
    onSuccess: (data) => {
      handleSuccess()
      toast({
        title: t('BLOCKED_TIME_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_SAVE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const mutatePeriod = useMutation('change-blocked-time', (params) => createPeriodBlockedTimes(params), {
    onSuccess: (data) => {
      handleSuccess()
      toast({
        title: t('BLOCKED_TIME_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_SAVE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })
  const mutateDelete = useMutation('delete-blocked-time', () =>
    deleteBlockedTime({
      blocked_id: props?.blocked_time?.id,
      onSuccess: handleSuccess,
    })
  )

  const mutateException = useMutation('create-blocked-time-exception', () =>
    createExceptionBlockedTime({
      blocked_id: props?.blocked_time?.id,
      params: {
        barber_id: props?.blocked_time?.barber?.id,
        date: format(props?.selectedDate, 'yyyy-MM-dd'),
      },
      onSuccess: handleSuccess,
    })
  )
  const createBlockedTime = async () => {
    // if (!selectedDay && selectedDay !== 0 && !everyDayCheck) {
    //   toast({
    //     title: t('ERROR_SAVE_DATA'),
    //     description: 'Necessário escolher uma data',
    //     status: 'error',
    //     duration: 4000,
    //     isClosable: true,
    //   })
    //   return
    // }

    if (!byPeriod) {
      const params = {
        barbershop_id: userData?.id,
        date:
          everyWeekDayCheck || everyDayCheck
            ? 'Vazio'
            : format(!selectedDay ? new Date() : new Date(selectedDay), 'yyyy-MM-dd', { locale: ptBR }),
        start_hour: `${startHour}:00`,
        finish_hour: `${finishHour}:00`,
        repeat_every_day: everyDayCheck,
        repeat_every_week_day: everyWeekDayCheck ? selectedDay + 1 : -1,
        barber_id: all ? null : Number(value),
        all_barbers: all,
        title,
      }
      mutate.mutate(params)
    } else {
      const params = {
        barbershop_id: userData?.id,
        start_hour: `${startHour}:00`,
        finish_hour: `${finishHour}:00`,
        barber_id: all ? null : Number(value),
        all_barbers: all,
        title,
        end_date: endDate,
        start_date: startDate,
      }
      mutatePeriod.mutate(params)
    }
  }

  async function getTeam() {
    const res = await api
      .get(ONLY_BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data } = useQuery('get-team', getTeam)

  useEffect(() => {
    if (props?.preset?.time) {
      setStartHour(props?.preset?.time)
      setValue(props?.preset?.barber?.id)
      setAll(false)
      return
    }

    if (props?.blocked_time?.id) {
      const { repeat_every_week_day } = props?.blocked_time
      setStartHour(props?.blocked_time?.start_hour)
      setFinishHour(props?.blocked_time?.finish_hour)
      setEveryDayCheck(props?.blocked_time?.repeat_every_day)
      if (repeat_every_week_day > 0) {
        setEveryWeekDayCheck(true)
        setSelectedDay(repeat_every_week_day - 1)
      }
      setTitle(props?.blocked_time?.title)
      setAll(props?.blocked_time?.all_barbers)
      setValue(props?.blocked_time?.barber?.id)
      return
    }
    setValue(data?.[0]?.id)
  }, [data])

  const handleSuccess = () => {
    setStartHour()
    setFinishHour()
    setSelectedDay(new Date())
    setEveryWeekDayCheck(false)
    setEveryDayCheck(false)
    onClose()
    setAll(true)
  }

  const isDisabled = useMemo(() => {
    if (byPeriod && (!startDate || !endDate)) {
      return true
    }
    if (everyWeekDayCheck && !selectedDay && selectedDay !== 0) {
      return true
    }
    if (!startHour || !finishHour) {
      return true
    }
    if (finishHour?.replace('_', '')?.length < 5 || startHour?.replace('_', '')?.length < 5) {
      return true
    }
    if (finishHour < startHour || finishHour > '23:59' || startHour > '23:59') {
      return true
    }
    if (props?.blocked_time) {
      return false
    }
    if (everyDayCheck) {
      return !startHour || !finishHour
    }
    return !selectedDay && selectedDay !== 0
  }, [
    everyWeekDayCheck,
    selectedDay,
    startHour,
    finishHour,
    props?.blocked_time,
    everyDayCheck,
    startDate,
    endDate,
    byPeriod,
  ])

  const blockEditing = useMemo(() => {
    if (props?.blocked_time?.id) {
      return true
    }
    return false
  }, [props?.blocked_time?.id])

  return (
    <>
      <ModalMenu
        isOpen={isModalOpen}
        onClose={() => {
          onClose()
          setAll(true)
        }}
        title={t(blockEditing ? 'Horário bloqueado' : 'ADD_BLOCK_TIMES')}
        headerStyle={{ mb: pxToRem(-12) }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            createBlockedTime()
          }}
        >
          <Flex position="relative" direction="column" alignItems={!isDesktop && 'center'} pb={24}>
            {(everyWeekDayCheck || everyDayCheck) && (
              <Text fontSize={pxToRem(16)} fontWeight="semiBold" marginBottom={pxToRem(12)}>
                {t('Selecione o dia da semana')}
              </Text>
            )}

            {(everyWeekDayCheck || everyDayCheck) && (
              <Flex w="100%">
                {WEEK_DAYS.map((item, index) => (
                  <Box mr={item?.length !== index + 1 && theme.pxToRem(8)} key={index}>
                    <WeekdayCard
                      w={theme.pxToRem(isDesktop ? 60 : 43.5)}
                      h={theme.pxToRem(66)}
                      cursor={everyDayCheck || blockEditing ? 'auto' : 'pointer'}
                      selected={everyDayCheck ? true : index === selectedDay}
                      onClick={() => (everyDayCheck || blockEditing ? null : setSelectedDay(index))}
                      key={item.day}
                    >
                      {t(daysOfWeek[index]?.label)}
                    </WeekdayCard>
                  </Box>
                ))}
              </Flex>
            )}

            {!everyDayCheck && !everyWeekDayCheck && !byPeriod && (
              <Calendar
                selectedDate={selectedDay || new Date()}
                onSelectDate={blockEditing ? null : setSelectedDay}
                minDate={new Date()}
              />
            )}
            {byPeriod && (
              <Flex mt={pxToRem(8)} mb={pxToRem(24)} w="full">
                <TextInput
                  h="46px"
                  noBorder
                  type="date"
                  name="start_date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                  }}
                  label="Data inicial"
                  // padding={theme.pxToRem(10)}
                />

                <Box w="100%" ml={pxToRem(20)}>
                  <TextInput
                    h="46px"
                    noBorder
                    type="date"
                    name="end_date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                    label="Data final"
                    // padding={theme.pxToRem(10)}
                  />
                </Box>
              </Flex>
            )}
            <Flex mt={pxToRem(12)} w="full">
              <Box>
                <Text textTransform="uppercase" fontSize={pxToRem(12)} kind="semiBold" color="whiteTransparent.400">
                  {t('START_TIME')}
                </Text>
                <Box mt={theme.pxToRem(4)} w="100%">
                  <TextInput
                    isDisabled={blockEditing}
                    mask="HOUR_MASK"
                    noBorder
                    color={theme.colors.white}
                    value={startHour}
                    placeholder="00:00"
                    onChange={(event) => setStartHour(event?.target?.value)}
                    leftElement={<Icon name="Clock" size={16} />}
                  />
                </Box>
              </Box>
              <Box ml={pxToRem(20)}>
                <Text textTransform="uppercase" fontSize={pxToRem(12)} kind="semiBold" color="whiteTransparent.400">
                  {t('END_TIME')}
                </Text>
                <Box mt={theme.pxToRem(4)} w="100%">
                  <TextInput
                    isDisabled={blockEditing}
                    mask="HOUR_MASK"
                    noBorder
                    value={finishHour}
                    color={theme.colors.white}
                    placeholder="00:00"
                    onChange={(event) => setFinishHour(event?.target?.value)}
                    leftElement={<Icon name="Clock" size={16} />}
                    error={
                      finishHour < startHour
                        ? 'O horário do fim deve ser maior que o horário de início'
                        : finishHour > '23:59'
                        ? 'O horário final deve ser até 23:59'
                        : null
                    }
                  />
                </Box>
              </Box>
            </Flex>
            {(!blockEditing || (blockEditing && props?.blocked_time?.title)) && (
              <Box mt={theme.pxToRem(4)} w="100%">
                <Text
                  textTransform="uppercase"
                  fontSize={pxToRem(12)}
                  kind="semiBold"
                  color="whiteTransparent.400"
                  mb={theme.pxToRem(8)}
                >
                  {t('DESCRIPTION_')}
                </Text>
                <TextInput
                  isDisabled={blockEditing}
                  noBorder
                  value={title}
                  color={theme.colors.white}
                  placeholder={t('PLACEHOLDER_BLOCKED')}
                  onChange={(event) => setTitle(event?.target?.value)}
                />
              </Box>
            )}

            <Flex mt="16px" mb="16px" alignItems="center">
              <CheckBox
                onChange={(event) => {
                  if (blockEditing) {
                    return
                  }
                  setAll(event?.target?.checked)
                  setValue(data?.[0]?.id)
                }}
                color="primary"
                borderColor={!all && blockEditing ? 'textMedium' : 'primary'}
                isChecked={all}
                size="lg"
                disabled={blockEditing}
              />
              <Text
                ml={pxToRem(8)}
                fontWeight="bold"
                fontSize="small"
                color={!all && blockEditing ? 'textMedium' : 'textLight'}
              >
                {t('Bloquear este horário para TODOS OS BARBEIROS')}
              </Text>
            </Flex>

            {!all && (
              <Flex mb={theme.pxToRem(12)} flexDir="column">
                <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)}>
                  {t('SELECT_BARBER')}
                </Text>
                <Flex
                  bg="backgroundLayout"
                  flexDir="column"
                  h={theme.pxToRem(56)}
                  align="center"
                  justify="center"
                  px={theme.pxToRem(16)}
                  borderRadius={theme.pxToRem(8)}
                  borderWidth={1}
                  borderColor="border"
                >
                  <Select
                    name="service"
                    value={value}
                    w="full"
                    fontFamily="Montserrat Bold"
                    fontSize={14}
                    textColor="textLight"
                    iconColor={blockEditing ? 'transparent' : 'primary'}
                    borderColor="transparent"
                    errorBorderColor="baseRed"
                    focusBorderColor="transparent"
                    variant="unstyled"
                    marginBottom={theme.pxToRem(2)}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={blockEditing}
                  >
                    {data?.map((item, index) => (
                      <option
                        style={{
                          backgroundColor: theme.colors.menuBackground,
                          fontFamily: 'Montserrat Medium',
                        }}
                        key={`service_${item?.id}`}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            )}
            <Box mt={pxToRem(4)}>
              <Box>
                <Text textTransform="uppercase" fontSize={pxToRem(12)} kind="semiBold" color="whiteTransparent.400">
                  {t('OPTIONS')}
                </Text>
              </Box>
              <Box>
                <Flex mt={pxToRem(16)} alignItems="center">
                  <CheckBox
                    onChange={(event) => {
                      if (blockEditing) {
                        return
                      }
                      if (everyDayCheck) {
                        setEveryDayCheck(false)
                      }
                      setSelectedDay(null)
                      setEveryWeekDayCheck(event?.target?.checked)
                    }}
                    isChecked={everyWeekDayCheck}
                    borderColor={!everyWeekDayCheck && blockEditing ? 'textMedium' : 'primary'}
                    color="primary"
                    size="lg"
                    disabled={blockEditing}
                  />

                  <Text
                    ml={pxToRem(8)}
                    fontWeight="bold"
                    fontSize="small"
                    color={!everyWeekDayCheck && blockEditing ? 'textMedium' : 'textLight'}
                  >
                    {t('Repetir este bloqueio de horário UM DIA DA SEMANA')}
                  </Text>
                </Flex>
                <Flex mt={pxToRem(16)} alignItems="center">
                  <CheckBox
                    onChange={(event) => {
                      if (blockEditing) {
                        return
                      }
                      if (everyWeekDayCheck) {
                        setEveryWeekDayCheck(false)
                      }
                      setSelectedDay(null)
                      setEveryDayCheck(event?.target?.checked)
                    }}
                    color="primary"
                    borderColor={!everyDayCheck && blockEditing ? 'textMedium' : 'primary'}
                    isChecked={everyDayCheck}
                    size="lg"
                    disabled={blockEditing}
                  />
                  <Text
                    ml={pxToRem(8)}
                    fontWeight="bold"
                    fontSize="small"
                    color={!everyDayCheck && blockEditing ? 'textMedium' : 'textLight'}
                  >
                    {t('Repetir este bloqueio de horário TODOS OS DIAS')}
                  </Text>
                </Flex>
                <Flex mt={pxToRem(16)} alignItems="center">
                  <CheckBox
                    onChange={(event) => {
                      setByPeriod(!byPeriod)
                    }}
                    color="primary"
                    borderColor={'primary'}
                    isChecked={byPeriod}
                    size="lg"
                  />
                  <Text ml={pxToRem(8)} fontWeight="bold" fontSize="small" color={'textLight'}>
                    Bloquear um PERÍODO ESPECÍFICO
                  </Text>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </form>
        <ModalButtonBackground>
          {!blockEditing && (
            <Button
              kind="primary"
              size="block"
              isDisabled={isDisabled || mutate.isLoading || mutatePeriod.isLoading}
              type="submit"
              isLoading={mutate.isLoading || mutatePeriod.isLoading}
              onClick={createBlockedTime}
            >
              {t('Bloquear horário')}
            </Button>
          )}
          {blockEditing && blockedTimes === 'edit' && (
            <>
              {(props?.blocked_time?.repeat_every_day || props?.blocked_time?.repeat_every_week_day > 0) && (
                <Button
                  kind="danger"
                  // isDisabled={isDisabled}
                  type="submit"
                  isLoading={mutateException.isLoading}
                  onClick={mutateException.mutate}
                  w="54%"
                  mr="2%"
                >
                  <Text kind="semiBold">Excluir bloqueio APENAS </Text>
                  <Text kind="bold">deste dia ({String(format(props?.selectedDate, 'dd/MM'))})</Text>
                </Button>
              )}
              <Button
                kind="danger"
                // isDisabled={isDisabled}
                type="submit"
                isLoading={mutateDelete.isLoading}
                onClick={mutateDelete.mutate}
                w={
                  props?.blocked_time?.repeat_every_day || props?.blocked_time?.repeat_every_week_day > 0
                    ? '44%'
                    : '100%'
                }
              >
                <Text kind="semiBold">Excluir</Text>
                <Text kind="bold">permanentemente</Text>
              </Button>
            </>
          )}
        </ModalButtonBackground>
      </ModalMenu>
    </>
  )
}
