import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { format, getDay, parseISO, addDays } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Flex, Box, Text, TextInput, Button, CheckBox } from 'components/atoms'
import { DayPicker, BlockedHoursCard, ModalAlert } from 'components/molecules'
import { BlockedTimeModal, MobileList } from 'components/organisms'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles/colors'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/molecules'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { BLOCK_TIME_DELETE, BLOCK_TIMES } from 'services/api/endpoints'
import { formatBlockedDate } from 'utils/date'
import { theme } from 'styles'

export const BlockedTime = () => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [selectedItem, setSelectedItem] = useState(null)
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false)
  const [isModalAddOpen, setIsModalAddOpen] = useState(false)
  const [startHour, setStartHour] = useState()
  const [finishHour, setFinishHour] = useState()
  const [everyWeekDayCheck, setEveryWeekDayCheck] = useState(false)
  const [everyDayCheck, setEveryDayCheck] = useState(false)
  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const toast = useToast()
  const { t } = useTranslation()
  const dayName = format(addDays(new Date(selectedDay), 1), 'cccc', {
    locale: ptBR,
  })

  const columns = [
    {
      key: 'blocked_date',
      label: 'BLOCKED_DATE',
      width: 165,
      render: (item) => (
        <>
          <Text>{t(formatBlockedDate(item))}</Text>
          <Text mt="6px">{item?.title}</Text>
        </>
      ),
    },
    {
      key: 'hour',
      label: 'PERIOD',
      width: 100,
      render: (item) => (
        <Text>
          {item?.start_hour.substring(0, 5)} -{' '}
          {item?.finish_hour.substring(0, 5)}
        </Text>
      ),
    },
    {
      key: 'barber',
      label: 'BARBER',
      render: (item) => (
        <Text>
          {t('BARBER')}: {item?.barber?.name || t('EVERYBODY')}
        </Text>
      ),
    },
  ]

  async function getBlockedTimes() {
    const res = await api
      .get(BLOCK_TIMES('', userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading, refetch } = useQuery(
    'get-blocked-times',
    getBlockedTimes
  )

  async function createBlockedTimes(params) {
    const res = await api
      .post(BLOCK_TIMES(), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-blocked-time',
    (params) => createBlockedTimes(params),
    {
      onSuccess: (data) => {
        handleSuccess()
        toast({
          title: t('BLOCKED_TIME_TOAST'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: t('ERROR_SAVE_DATA'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  async function deleteBlockedTimes(params) {
    const res = await api
      .delete(BLOCK_TIME_DELETE(selectedItem?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation('change-blocked-time', deleteBlockedTimes, {
    onSuccess: (data) => {
      handleDelete()
      toast({
        title: t('UNBLOCKED_TIME_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_DELETE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const onClose = () => {
    setIsModalAddOpen(false)
    refetch()
  }

  const createBlockedTime = async () => {
    if (!selectedDay) {
      toast({
        title: t('ERROR_SAVE_DATA'),
        description: 'Necessário escolher uma data',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return
    }
    const params = {
      barbershop_id: userData?.id,
      date: format(parseISO(selectedDay), 'yyyy-MM-dd', { locale: ptBR }),
      start_hour: `${startHour}:00`,
      finish_hour: `${finishHour}:00`,
      repeat_every_day: everyDayCheck,
      repeat_every_week_day:
        (everyWeekDayCheck && getDay(addDays(new Date(selectedDay), 1))) || -1,
      all_barbers: true,
    }
    mutate.mutate(params)
  }

  const handleSuccess = () => {
    setStartHour()
    setFinishHour()
    refetch()
  }

  const handleDelete = () => {
    setIsModalAlertOpen(false)
    setSelectedItem()
    refetch()
  }

  return (
    <>
      <Box>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            createBlockedTime()
          }}
        >
          <Flex justify="space-between" align="center">
            <Text fontSize={pxToRem(18)} fontWeight="bold" w="50%">
              {t('BLOCKED_TIME')}
            </Text>
            <Button size={'small'} onClick={() => setIsModalAddOpen(true)}>
              {t('ADD_PLUS')}
            </Button>
          </Flex>
        </form>

        {isDesktop ? (
          <Box marginTop={pxToRem(48)} width="full">
            <Table
              deleteButtom
              isSelectableRow
              data={data?.filter((i) => !i?.past) || []}
              loading={isLoading}
              columns={columns}
              onSelect={(item) => {
                setIsModalAlertOpen(true)
                setSelectedItem(item)
              }}
            />
          </Box>
        ) : (
          <Box marginTop={pxToRem(48)} width="full">
            <MobileList
              data={data?.filter((i) => !i?.past) || []}
              loading={isLoading}
              renderItem={({ item, index }) => (
                <BlockedHoursCard
                  item={item}
                  index={index}
                  isDeletable
                  onDelete={(item) => {
                    setIsModalAlertOpen(true)
                    setSelectedItem(item)
                  }}
                />
              )}
            />
          </Box>
        )}
      </Box>
      <BlockedTimeModal isModalOpen={isModalAddOpen} onClose={onClose} />

      <ModalAlert
        isOpen={isModalAlertOpen}
        onClose={() => {
          setIsModalAlertOpen(false)
          setSelectedItem()
        }}
        loading={mutateDelete.isLoading}
        onConfirm={() => mutateDelete.mutate()}
        primaryButtonLabel={t('YES')}
        modalTitle={t('DELETE_BLOCKED_HOUR')}
      >
        <Text fontWeight="bold" m={`${pxToRem(16)} 0`}>
          {t('BLOCK_TIME_CONFIRMATION')}
        </Text>
      </ModalAlert>
    </>
  )
}
