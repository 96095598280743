import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Box, Layout } from 'components'
import { InvoiceIssuer } from 'components'

export const ConfigNF = (props) => {
  return (
    <Layout {...props} fullHeight={false}>
      <InvoiceIssuer />
    </Layout>
  )
}

ConfigNF.path = '/nota-fiscal/configuracoes'
ConfigNF.title = 'Configurations'
ConfigNF.secured = true
