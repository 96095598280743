import React, { useState } from 'react'
import { useBreakpoint } from 'services/hooks'
import { Flex, Form, Box, Image, Text, Button, Link } from 'components'

import { Plans } from '../components/plans'
import { data, initialValues, validation } from '../components/settings'
import { useHistory } from 'react-router-dom'
import { theme } from 'styles'
import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { useTranslation } from 'react-i18next'
import andre from '../images/section-garantia-bg.png'
import andreM from '../images/section-garantia-mobile.png'
import { useMutation } from 'react-query'
import { api } from 'services/api/config'
import { LP_COMPLETE_DATA } from 'services/api/endpoints'
import { useToast } from '@chakra-ui/react'
import { useBarbershopStore } from 'services/stores'

const FormHeader = () => {
  return (
    <Flex flexDir="column" w="100%" align="center" textAlign="center">
      <Image
        src={bestBarbersLogo}
        w={theme.pxToRem(140)}
        h="auto"
        mb={theme.pxToRem(12)}
      />
      <Text color="textGrey" fontSize={14}>
        Confirme os dados abaixo e ganhe
      </Text>
      <Text
        kind="extraBold"
        fontSize={20}
        mb={theme.pxToRem(12)}
        mr={theme.pxToRem(6)}
      >
        7 dias grátis na versão Premium
      </Text>
    </Flex>
  )
}

const FormContainer = () => {
  const toast = useToast()
  const history = useHistory()
  const { isDesktop } = useBreakpoint()
  const { setUser, user } = useBarbershopStore()
  const { t } = useTranslation()
  const [values, getValues] = useState({
    params: {
      ...initialValues,
      email: user.email,
      owner_name: user.name,
      phone: user.phone.substring(2),
    },
    isValid: false,
  })

  async function completeData() {
    const res = await api
      .post(LP_COMPLETE_DATA(), { ...values.params })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutation = useMutation('lp-complete-data', () => completeData(), {
    onSuccess: (data) => {
      setUser(data.user)
      toast({
        title: t('Dados cadastrados com sucesso!'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      history.push('/lp/bonus')
    },
    onError: (error) => {
      toast({
        title: t('Falha ao cadastrar dados. Por favor, tente novamente'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })
  return (
    <Box
      borderRadius={theme.pxToRem(12)}
      py="3vh"
      px={!isDesktop ? '5vw' : '2vw'}
      w={!isDesktop ? '92vw' : '35vw'}
      bg="menuBackground"
    >
      <FormHeader />
      <Form
        mb={isDesktop ? 36 : 30}
        getValues={getValues}
        initialValues={values?.params}
        validationSchema={validation}
        data={data}
      />
      <Button
        kind="primary"
        w="100%"
        mt={theme.pxToRem(16)}
        onClick={mutation.mutate}
        isLoading={mutation.isLoading}
      >
        Criar conta
      </Button>
      <Text
        align={'center'}
        fontSize={theme.pxToRem(10)}
        mt={theme.pxToRem(10)}
      >
        {t('TERMS_AND_POLICYS_1')} os
        <Link
          isExternal
          fontSize={theme.pxToRem(10)}
          href="https://www.bestbarbers.app/termos-de-uso"
        >
          {` ${t('TERMS')} `}
        </Link>
        {t('AND')}
        <Link
          fontSize={!theme.pxToRem(10)}
          isExternal
          href="https://www.bestbarbers.app/politica-de-privacidade"
        >
          {t('PRIVACY')}
        </Link>
      </Text>
    </Box>
  )
}

export const CompleteDataStep = () => {
  const { isDesktop } = useBreakpoint()
  if (!isDesktop) {
    return (
      <Flex w={'100vw'} flexDir="column" align="center" pt="4vh">
        <FormContainer />
        <Flex
          w={'100vw'}
          px="5vw"
          pt="5vh"
          h="100vh"
          flexDir="column"
          bgColor="backgroundLayout"
          overflow="hidden"
          backgroundImage={`url(${andreM})`}
          backgroundSize="cover"
          backgroundPosition="center center"
        >
          <Flex ml="5vw" w="100%" flexDir="column" align="flex-start">
            <Text lineHeight={9} color="primary" kind="extraBold" fontSize={32}>
              Uma semana inteira
            </Text>
            <Text lineHeight={9} color="white" kind="extraBold" fontSize={32}>
              para testar com tranquilidade
            </Text>
            <Text
              mt={theme.pxToRem(12)}
              w="90%"
              color="textLight"
              fontSize={12}
            >
              Temos certeza de que o BestBarbers será um grande avanço para a
              sua barbearia e que você terá ótimos resultados.
            </Text>
            <Text w="90%" color="textLight" fontSize={12}>
              Se até o sétimo dia de uso você não estiver satisfeito, por
              qualquer motivo que seja, basta nos enviar um simples e-mail que
              faremos a devolução de 100% do valor investido.
            </Text>
          </Flex>
        </Flex>
        <Plans />
      </Flex>
    )
  }

  return (
    <Flex w={'100vw'} flexDir="column" align="flex-start">
      <Flex
        maxH={theme.pxToRem(850)}
        h="100vh"
        w={'100vw'}
        align="flex-end"
        justify="flex-end"
      >
        <Flex
          h="72vh"
          w={'90vw'}
          flexDir="row"
          align="flex-end"
          justify="center"
          backgroundImage={`url(${andre})`}
          backgroundSize="cover"
          backgroundPosition="center center"
          px="5vw"
          py="5vh"
        >
          <FormContainer />
          <Flex
            ml="5vw"
            w="50%"
            maxW={theme.pxToRem(600)}
            mb="60vh"
            flexDir="column"
            align="flex-start"
          >
            <Text w="100%" color="primary" kind="extraBold" fontSize={36}>
              Uma semana inteira
            </Text>
            <Text
              mt={theme.pxToRem(-12)}
              w="100%"
              color="white"
              kind="extraBold"
              fontSize={36}
            >
              para testar com tranquilidade
            </Text>
            <Text w="100%" color="textLight" fontSize={14}>
              Temos certeza de que o BestBarbers será um grande avanço para a
              sua barbearia e que você terá ótimos resultados.
            </Text>
            <Text w="100%" color="textLight" fontSize={14}>
              Se até o sétimo dia de uso você não estiver satisfeito, por
              qualquer motivo que seja, basta nos enviar um simples e-mail que
              faremos a devolução de 100% do valor investido.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Plans />
    </Flex>
  )
}

CompleteDataStep.path = '/lp/complete-data'
CompleteDataStep.title = 'LandingPage Complete Data'
CompleteDataStep.secured = false
