import React, { useMemo, useState } from 'react'
import { Logo, Text, Button, Box, Link } from 'components'
import { Flex, useTheme, Center } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { useLocation, useHistory } from 'react-router-dom'
import { REGISTER_STEPS } from 'utils/register'
import patternBg from 'assets/img/backgrounds/bestbarbers-logo-paterns.png'
import { useTranslation } from 'react-i18next'
import { SuccessStepModal } from 'screens/Register/SuccessStep'

export const RegisterWrapper = ({
  children,
  title,
  subTitle,
  loading,
  noSteps,
  onContinue,
  continueDisabled,
  noBackground,
  loginOption,
}) => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const [currentStepIndex, setCurrentStepIndex] = useState()
  const [finishSetupModal, setFinishSetupModal] = useState(false)

  const { isMobile } = useBreakpoint()
  const theme = useTheme()

  const selectedStep = useMemo(() => {
    return REGISTER_STEPS.find((item) => item.path === location.pathname)
  }, [location])

  const navigateToStep = (step) => {
    history.push(step.path)
  }

  const onClickNext = () => {
    if (selectedStep.step === 8) {
      setFinishSetupModal(true)
    } else {
      const currentStepIndex = REGISTER_STEPS.findIndex(
        (item) => item.path === location.pathname
      )
      history.push(REGISTER_STEPS[currentStepIndex + 1].path)
    }
  }

  if (noBackground) {
    return (
      <Flex
        flexDir="column"
        alignContent="center"
        justifyContent="center"
        px={theme.pxToRem(isMobile ? 24 : 48)}
        py={theme.pxToRem(isMobile ? 24 : 32)}
        w={isMobile ? '100%' : theme.pxToRem(640)}
        h={isMobile ? 'auto' : theme.pxToRem(loginOption ? 700 : 640)}
        minH={isMobile ? '100%' : 'auto'}
        borderRadius={theme.pxToRem(8)}
        bg="menuBackground"
        borderWidth={1}
        borderColor="modalBorder"
      >
        <Flex w="100%" flexDirection="column">
          <Text kind="extraBold" fontSize={theme.pxToRem(isMobile ? 22 : 24)}>
            {title || t(selectedStep.title)}
          </Text>
          <Text
            kind="medium"
            color="textGrey"
            fontSize={theme.pxToRem(14)}
            mb={theme.pxToRem(36)}
          >
            {subTitle || t(selectedStep.subTitle)}
          </Text>
        </Flex>
        <Flex
          maxH={isMobile ? '60vh' : theme.pxToRem(480)}
          h={isMobile ? '60vh' : theme.pxToRem(480)}
          overflowY="auto"
          overflowX="hidden"
          overscrollY="contain"
          flexDirection="column"
          w="100%"
        >
          {children}
        </Flex>
        <Flex w="100%">
          <Button
            disabled={continueDisabled}
            isLoading={loading}
            onClick={onContinue || onClickNext}
            w="100%"
          >
            {t('CONTINUE_BUTTON')}
          </Button>
        </Flex>
        {loginOption && (
          <Box
            w="100%"
            mt={isMobile ? theme.pxToRem(12) : theme.pxToRem(24)}
            textAlign={'center'}
          >
            <Link
              href="/login"
              type="multiple"
              mainText={t('ALREADY_REGISTERED')}
              highlightText={t('LOG_IN')}
            />
          </Box>
        )}
      </Flex>
    )
  }

  return (
    <Flex
      alignItems="center"
      justifyContent={isMobile ? 'flex-start' : 'center'}
      py={theme.pxToRem(32)}
      w="100vw"
      h="100vh"
      overflowX="hidden"
      backgroundImage={`url(${patternBg})`}
      bgColor="transparent.700"
      backgroundBlendMode="overlay"
      backgroundRepeat="repeat"
      backgroundSize="fill"
    >
      <Flex
        flexDir="column"
        alignContent="flex-start"
        justifyContent="flex-start"
        px={theme.pxToRem(isMobile ? 24 : 48)}
        py={theme.pxToRem(36)}
        w={isMobile ? '100%' : theme.pxToRem(640)}
        h={isMobile ? '100vh' : theme.pxToRem(780)}
        minH={isMobile ? '100%' : 'auto'}
        borderRadius={theme.pxToRem(8)}
        bg="menuBackground"
        borderWidth={1}
        borderColor="modalBorder"
      >
        <Center pb={theme.pxToRem(24)}>
          <Logo size="small" />
        </Center>

        <Flex w="100%" flexDirection="column">
          <Flex mb={theme.pxToRem(24)} w="100%" h={theme.pxToRem(12)}>
            {!noSteps &&
              REGISTER_STEPS.map((item) => (
                <Flex
                  mr={theme.pxToRem(12)}
                  h="100%"
                  w="100%"
                  borderRadius={theme.pxToRem(4)}
                  bg={
                    selectedStep.step === item.step ? 'primary' : 'textMedium'
                  }
                  // onClick={() => navigateToStep(item)}
                />
              ))}
          </Flex>

          <Text kind="extraBold" fontSize={theme.pxToRem(isMobile ? 22 : 24)}>
            {title || t(selectedStep.title)}
          </Text>

          <Text
            kind="medium"
            fontSize={theme.pxToRem(16)}
            mb={theme.pxToRem(10)}
          >
            {subTitle || t(selectedStep.subTitle)}
          </Text>
        </Flex>

        <Flex
          maxH={isMobile ? '64vh' : theme.pxToRem(700)}
          h={isMobile ? '64vh' : theme.pxToRem(700)}
          overflowY="auto"
          overflowX="hidden"
          overscrollY="contain"
          flexDirection="column"
          w="100%"
        >
          {children}
        </Flex>

        <Flex mt={theme.pxToRem(24)} w="100%">
          {selectedStep.step > 0 && (
            <Button
              kind="outline"
              p={theme.pxToRem(16)}
              mr={theme.pxToRem(16)}
              w="fit-content"
              h="fit-content"
              onClick={() => history.goBack()}
            >
              {t('BACK')}
            </Button>
          )}

          <Button
            disabled={continueDisabled}
            isLoading={loading}
            onClick={onContinue || onClickNext}
            size="block"
          >
            {selectedStep.step === 8
              ? t('FINISH_CONFIGURATION')
              : t('CONTINUE_BUTTON')}
          </Button>
        </Flex>
      </Flex>

      {finishSetupModal && (
        <SuccessStepModal
          isOpen={finishSetupModal}
          onClose={() => setFinishSetupModal(false)}
        />
      )}
    </Flex>
  )
}
