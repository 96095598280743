import { useMemo } from 'react'
import { useBarbershopStore } from '../stores/'

export const usePermissionCollaborator = (screen) => {
  const { userData, user } = useBarbershopStore()

  return useMemo(() => {
    if (
      user?.type === 'barbershop' ||
      user?.type === 'barbershopAndBarber' ||
      user?.type === 'owner' ||
      user?.type === 'ownerAndBarber'
    ) {
      return 'edit'
    }
    return userData?.permissions[screen]
  }, [screen, userData])
}
