import * as React from 'react'

function SvgCellphone(props) { 
    return (
<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12 1.00268e-07C12.5046 -0.000159579 12.9906 0.190406 13.3605 0.533497C13.7305 0.876587 13.9572 1.34684 13.995 1.85L14 2V18C14.0002 18.5046 13.8096 18.9906 13.4665 19.3605C13.1234 19.7305 12.6532 19.9572 12.15 19.995L12 20H2C1.49542 20.0002 1.00943 19.8096 0.639452 19.4665C0.269471 19.1234 0.0428434 18.6532 0.00500021 18.15L1.00268e-07 18V2C-0.000159579 1.49542 0.190406 1.00943 0.533497 0.639452C0.876587 0.269471 1.34684 0.0428434 1.85 0.00500021L2 1.00268e-07H12ZM12 2H2V18H12V2ZM7.5 14C7.61703 14 7.73036 14.041 7.82026 14.1159C7.91016 14.1908 7.97094 14.2949 7.992 14.41L8 14.5V15.5C8.00004 15.617 7.95903 15.7304 7.88411 15.8203C7.80919 15.9102 7.70511 15.9709 7.59 15.992L7.5 16H6.5C6.38297 16 6.26964 15.959 6.17974 15.8841C6.08984 15.8092 6.02906 15.7051 6.008 15.59L6 15.5V14.5C5.99996 14.383 6.04097 14.2696 6.11589 14.1797C6.19081 14.0898 6.29489 14.0291 6.41 14.008L6.5 14H7.5Z" fill="currentColor"/>
</svg>
    )
}

export default SvgCellphone


