import * as React from 'react'

function SvgBarcode(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.333 4.583V3.5A2.167 2.167 0 013.5 1.333h2.166M1.333 15.417V16.5A2.167 2.167 0 003.5 18.667h2.166M14.332 1.334h2.167a2.167 2.167 0 012.166 2.167v1.083M14.332 18.668h2.167a2.167 2.167 0 002.166-2.167v-1.083"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="currentColor"
        d="M3.501 8.916H2.418v2.167h1.083V8.916z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.832 8.916v2.167"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="currentColor"
        d="M13.25 8.916h-1.083v2.167h1.083V8.916z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.583 8.916v2.167"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgBarcode
