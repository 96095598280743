import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Button,
  Text,
  Icon,
  ModalMenu,
  ObservationCard,
  TextInput,
  Avatar,
  SelectBarberModal,
  ModalAlert,
} from 'components'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useCustomerStore, useBarbershopStore, useAppointmentStore } from 'services/stores'
import { theme } from 'styles/theme'
import { useUserType } from 'services/hooks/use-user-type'
import { usePermissionCollaborator } from 'services/hooks'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { ONLY_BARBERS, OBSERVATION_GET, OBSERVATION_CREATE, OBSERVATION_DELETE } from 'services/api/endpoints'
import { useBarbershop } from 'services/hooks'

export const ClientObservationsModal = ({ isModalOpen, onClose }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { selectedCustomer, selectedObservation, setSelectedObservation } = useCustomerStore()
  const { userData } = useBarbershopStore()
  const { barber, setBarber } = useAppointmentStore()
  const [observation_text, setObservation_text] = useState('')
  // const [selectBarberModal, setSelectBarberModal] = useState(false)
  const [deleteObservationModal, setDeleteObservationModal] = useState(false)
  const type = useUserType()
  const clients = usePermissionCollaborator('clients')
  const { getObservations } = useBarbershop()

  // async function getTeam() {
  //   const res = await api
  //     .get(ONLY_BARBERS(userData.id))
  //     .then((response) => {
  //       return response.data
  //     })
  //     .catch((error) => {
  //       throw Error(error.response.data.error)
  //     })
  //   return res
  // }

  // useQuery('get-team', getTeam, {
  //   onSuccess: (data) => {
  //     setBarber(data[0])
  //   },
  // })

  async function createObs() {
    const params = {
      barbershop_id: Number(userData?.id),
      // barber_id: Number(barber?.id),
      client_id: Number(selectedCustomer?.client_id || selectedCustomer?.id),
      observation_text,
    }
    const res = await api
      .post(OBSERVATION_CREATE, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('create-obs', createObs, {
    onSuccess: () => {
      refetch()
      setObservation_text('')
    },
    onError: (error) => {
      toast({
        title: t('OBSERVATION_SAVE_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const { data, refetch } = useQuery(['get-observations', selectedCustomer], () =>
    getObservations({
      barbershop_id: userData?.barbershop?.id || userData?.id,
      client_id: selectedCustomer?.client_id || selectedCustomer?.id,
    })
  )

  async function deleteObs() {
    const res = await api
      .delete(OBSERVATION_DELETE(selectedObservation?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation('delete-obs', deleteObs, {
    onSuccess: () => {
      setDeleteObservationModal(false)
      refetch()
      toast({
        title: t('DELETE_OBSERVATION_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('DELETE_OBSERVATION_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  // const onAddImage = async () => {
  //   let options = {
  //     noData: true,
  //     maxWidth: 500,
  //     maxHeight: 500,
  //     mediaType: 'photo',
  //     includeBase64: true,
  //   }

  //   const res = await ImagePicker.openPicker(options)

  //   if (res.data) {
  //     await onAddObs({
  //       params: {
  //         barbershop_id: Number(
  //           userData?.barbershop ? userData.barbershop?.id : userData.id
  //         ),
  //         barber_id: Number(barber.id),
  //         client_id: Number(selectedCustomer.id),
  //         observation_image_mime: res.data,
  //         observation_image_base64: res.mime,
  //       },
  //     })
  //   } else {
  //     // showMessage({
  //     //   message: 'Falha ao adicionar imagem.',
  //     //   type: 'danger',
  //     // })
  //   }
  // }

  return (
    <ModalMenu isNavigating bodyStyle={{ padding: 0 }} isOpen={isModalOpen} handleBackNavigation={onClose}>
      <Box>
        <Text fontSize={24} kind="extraBold" ml={theme.pxToRem(24)} mb={theme.pxToRem(12)}>
          {t('OBSERVATIONS')}
        </Text>

        <Box flex={1} padding={theme.pxToRem(24)} mb={theme.pxToRem(150)}>
          {data && data?.length === 0 && (
            <Flex flexDir="column" alignItems="center" justifyItems="center" w="100%" py={theme.pxToRem(32)}>
              <Icon mb={theme.pxToRem(8)} color="textGrey" name="Edit" />
              <Text fontSize={14} kind="medium" color="textGrey">
                {t('NO_OBSERVATIONS')}
              </Text>
            </Flex>
          )}

          {data &&
            data?.length > 0 &&
            data?.map((item) => (
              <ObservationCard
                inModal
                key={item.id}
                item={item}
                mb={theme.pxToRem(12)}
                onDelete={() => {
                  if (clients === 'edit') {
                    setSelectedObservation(item)
                    setDeleteObservationModal(true)
                  } else {
                    toast({
                      title: t('PERMISSION_DENIED'),
                      status: 'error',
                      duration: 4000,
                      isClosable: true,
                    })
                  }
                }}
              />
            ))}
        </Box>
      </Box>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={theme.pxToRem(12)}
        width="100%"
        height={theme.pxToRem(120)}
        position=" absolute"
        bottom={theme.pxToRem(72)}
        backgroundColor="cardBackground"
      >
        <Flex flexDir="column" alignItems="center">
          <Avatar
            size={isDesktop ? 'menu' : 'medium'}
            src={userData?.employee_name ? userData?.employee_image : userData?.profile_image_url}
            // onClick={() =>
            //   !userData.barbershop_id && setSelectBarberModal(true)
            // }
          />
          <Text mt={theme.pxToRem(2)} textAlign="center" fontSize={10} w={50}>
            {userData?.employee_name || userData?.name}
          </Text>
        </Flex>

        <Box width={!isDesktop ? theme.pxToRem(220) : theme.pxToRem(260)}>
          <TextInput
            noBorder
            backgroundColor="background"
            placeholder={t('DESCRIPTION_PLACEHOLDER')}
            value={observation_text}
            onChange={(event) => setObservation_text(event.target.value)}
          />
        </Box>

        {/* <BaseTouch
          onPress={onAddImage}
          bg="backgroundComponentLight"
          height={44}
          width={44}
          br={8}
        >
          <Icon iconName="IMAGE_GALLERY" color="white" />
        </BaseTouch> */}

        <Button disabled={observation_text?.length === 0} size="square" onClick={() => mutate.mutate()}>
          <Icon name="Send" color="black" />
        </Button>
      </Flex>

      {/* {selectBarberModal && (
        <SelectBarberModal
          isModalOpen={selectBarberModal}
          goBack={() => setSelectBarberModal(false)}
        />
      )} */}

      <ModalAlert
        isOpen={deleteObservationModal}
        modalTitle={t('DELETE_OBSERVATION')}
        primaryButtonLabel={t('DELETE')}
        loading={mutateDelete.isLoading}
        onConfirm={() => mutateDelete.mutate()}
        onClose={() => {
          setSelectedObservation(null)
          setDeleteObservationModal(false)
        }}
      >
        {t('DELETE_OBSERVATION_DESC')}
      </ModalAlert>
    </ModalMenu>
  )
}
