import React, { useMemo, useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Box, Button, Flex, Text, TextInput, FileInput, Icon } from 'components/atoms'
import { ModalAlert, ModalMenu, WeekdaySelector, Form } from 'components/molecules'
import { Select, useTheme, useToast, useDisclosure } from '@chakra-ui/react'
import { colors } from 'styles/colors'
import { isEmpty } from 'lodash'
import { serviceStore, useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'services/hooks'
import { unformatCurrency } from 'utils/formatCurrency'
import { inputs, validationSchema, initialValues } from './settings'

import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { EXCHANGE_VALUE, EDIT_SERVICE, SERVICES_CATEGORIES } from 'services/api/endpoints'

const types = [
  {
    id: 0,
    name: 'CPF',
    value: 'CPF',
    mask: 'CPF_MASK',
  },
  {
    id: 1,
    name: 'CNPJ',
    value: 'CNPJ',
    mask: 'CNPJ_MASK',
  },
  {
    id: 2,
    name: 'E-mail',
    value: 'EMAIL',
    mask: '',
  },
  {
    id: 3,
    name: 'Telefone',
    value: 'PHONE',
    mask: 'PHONE_MASK',
    type: 'cel-phone',
  },
  {
    id: 4,
    name: 'Chave aleatória',
    value: 'EVP',
    mask: '',
  },
]

export const PixModal = ({ isOpen, onClose, total }) => {
  const { isOpen: isModalDeleteOpen, onOpen: onOpenModalAlert, onClose: onCloseModalAlert } = useDisclosure()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [values, getValues] = useState()
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()

  const formatKey = (key, keyType) => {
    switch (keyType) {
      case 'EMAIL':
        return key
      case 'EVP':
        return key
      default:
        return key.replace(/\D/g, '')
    }
  }

  async function exchangeValue() {
    const params = {
      pixAddressKeyType: values?.pixAddressKeyType?.value,
      value: unformatCurrency(values?.value),
      pixAddressKey: formatKey(values?.pixAddressKey, values?.pixAddressKeyType?.value),
    }

    const res = await api
      .post(EXCHANGE_VALUE(userData?.id), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('exchange-value', (params) => exchangeValue(params), {
    onSuccess: async (value) => {
      toast({
        title: 'Valor sacado com sucesso',
        status: 'success',
        duration: 4000,
      })
      onCloseModalAlert()
      onClose()
    },
    onError: (error) => {
      toast({
        message: 'Erro ao sacar valor',
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
      })
      onCloseModalAlert()
      onClose()
    },
  })

  const isButtonDisabled = useMemo(
    () =>
      mutate.isLoading ||
      Number(values?.value?.replace(',', '.')) > total ||
      values?.pixAddressKey?.length === 0 ||
      !values?.pixAddressKey ||
      !values?.value ||
      !values?.pixAddressKeyType ||
      values?.pixAddressKeyType?.value?.length === (0)[(mutate.isLoading, values)]
  )
  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title={t('EXCHANGE')}
    >
      <Flex align="flex-start" flexDir="column" w="100%" h="100%">
        <Box w="100%" flex="1" overflowY="auto" mb={theme.pxToRem(24)}>
          <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)}>
            {t('PIX_TYPES')}
          </Text>
          <Select
            name="pixAddressKeyType"
            placeholder={t('SELECT')}
            w="100%"
            h={theme.pxToRem(56)}
            mb={theme.pxToRem(16)}
            fontFamily="Montserrat Bold"
            textColor="textLight"
            iconColor="primary"
            backgroundColor="cardBackground"
            borderWidth={1}
            borderColor="modalBorder"
            borderRadius={theme.pxToRem(8)}
            value={values?.pixAddressKeyType?.value}
            errorBorderColor="baseRed"
            onChange={(e) => {
              getValues({
                ...values,
                pixAddressKeyType: {
                  value: e.target.value,
                  mask: types.find((item) => item?.value === e.target.value),
                },
              })
            }}
          >
            {types.map((item) => (
              <option
                style={{
                  backgroundColor: theme.colors.menuBackground,
                  fontFamily: 'Montserrat Medium',
                }}
                key={item?.id}
                value={item?.value}
              >
                {item?.name}
              </option>
            ))}
          </Select>
          <TextInput
            noBorder
            type="money"
            name="value"
            placeholder="R$ 10,00"
            paddingLeft={theme.pxToRem(16)}
            value={values?.value}
            label="VALUE"
            mask="CURRENCY_MASK"
            onChange={(e) => getValues({ ...values, value: e.target.value })}
            mb={theme.pxToRem(40)}
          />
          <TextInput
            noBorder
            mt="32px"
            type="text"
            name="pixAddressKey"
            placeholder={t('PIX_KEY')}
            paddingLeft={theme.pxToRem(16)}
            label="PIX_KEY"
            mask={values?.pixAddressKeyType?.mask?.mask}
            value={values?.pixAddressKey}
            onChange={(e) => getValues({ ...values, pixAddressKey: e.target.value })}
            mb={theme.pxToRem(40)}
          />

          <Button
            mt="52px"
            mb={!isDesktop && '20%'}
            flex="1"
            h={theme.pxToRem(52)}
            isLoading={mutate.isLoading}
            disabled={isButtonDisabled}
            w="100%"
            onClick={() => onOpenModalAlert()}
          >
            <Text fontSize="md" color={colors.textDark} kind="bold">
              {t('EXCHANGE')}
            </Text>
          </Button>
        </Box>

        <ModalAlert
          isOpen={isModalDeleteOpen}
          onClose={onCloseModalAlert}
          onConfirm={() => mutate.mutate()}
          primaryButtonLabel={t('CONFIRM')}
          modalTitle={t('PIX_EXCHANGE')}
        >
          <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
            {t('CONFIRM_PIX')} "{values?.value}" ?
          </Text>
        </ModalAlert>
      </Flex>
    </ModalMenu>
  )
}
