import * as Yup from 'yup'
import { t } from 'services/translation'

//LOCATION ------------------------------------------------------------------------
export const dataLocation = [
  {
    name: 'zip_code',
    type: 'string',
    noBorder: true,
    label: 'ZIP_CODE_PLACEHOLDER',
    mask: 'CEP_MASK',
  },
  {
    name: 'state',
    type: 'string',
    noBorder: true,
    label: 'STATE',
  },
  {
    name: 'city',
    type: 'string',
    noBorder: true,
    label: 'CITY',
  },
  {
    name: 'street',
    type: 'string',
    noBorder: true,
    label: 'ADDRESS',
  },
  {
    name: 'street_number',
    type: 'string',
    noBorder: true,
    label: 'NUMBER',
  },
  {
    name: 'complement',
    type: 'string',
    noBorder: true,
    label: 'COMPLEMENT',
  },
  {
    name: 'neighborhood',
    type: 'string',
    noBorder: true,
    label: 'NEIGHBORHOOD',
  },
]

export const validationLocation = Yup.object().shape({
  zip_code: Yup.string()
    .min(8, t('INCOMPLETE_CEP'))
    .required(t('CEP_REQUIRED')),
  state: Yup.string().required(t('STATE_REQUIRED')),
  city: Yup.string().required(t('CITY_REQUIRED')),
  street: Yup.string().required(t('STREET_REQUIRED')),
  street_number: Yup.string().required(t('STREET_NUMBER_REQUIRED')),
  neighborhood: Yup.string(),
})

export const initialLocation = {
  zip_code: '',
  state: '',
  city: '',
  street: '',
  street_number: '',
  neighborhood: '',
  country: '',
  latitude: '',
  longitude: '',
}
