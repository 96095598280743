import React from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react'
import { ArrowLeft } from '../../../assets/icons'
import { colors } from 'styles/colors'
import { Box, Text, Button } from 'components'
import { useTheme, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'

export const ModalMenu = ({
  children,
  isOpen,
  onClose,
  goBack,
  title,
  isNavigating,
  onAdd,
  handleBackNavigation,
  headerStyle = {},
  bodyStyle = {},
  ...props
}) => {
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={isDesktop ? 'md' : 'full'} {...props}>
      <DrawerOverlay />
      <DrawerContent bg="menuBackground" borderLeftWidth={1} borderColor="modalBorder">
        <DrawerHeader {...headerStyle}>
          {isNavigating && (
            <Box cursor="pointer" onClick={goBack || handleBackNavigation}>
              <ArrowLeft color={colors.primary} />
            </Box>
          )}
          <Flex align="center">
            <Text color="textLight" kind="extraBold" fontSize={20} w="90%">
              {title}
            </Text>

            {onAdd && (
              <Button onClick={onAdd} size="x-small" bg="primary" align="center" mb={2}>
                + Adicionar
              </Button>
            )}
          </Flex>

          {!isNavigating && <DrawerCloseButton mt={theme.pxToRem(8)} onClick={onClose} color="primary" zIndex="1" />}
        </DrawerHeader>

        <DrawerBody mt={-2} pb={theme.pxToRem(100)} {...bodyStyle}>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
