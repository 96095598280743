import React, { useMemo, useState } from 'react'
import { format } from 'date-fns'
import {
  Box,
  Text,
  Button,
  Flex,
  ClientCard,
  ServiceCard,
  BarberCard,
  PaymentMethodCard,
  AdditionalProductCard,
  DateTimeCard,
} from 'components'
import { useTheme, useToast } from '@chakra-ui/react'
import { APPOINTMENT_STEPS } from './settings'
import { useAppointmentStore, useBarbershopStore, useSingleSaleStore, useAppointmentDetailStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { usePremiumBlock, usePermissionCollaborator, useSingleProductsSale } from 'services/hooks'
import { useAppointment } from 'services/hooks/use-appointment'
import { useMutation, useQueryClient } from 'react-query'
import { calculateInitialHour } from 'utils/servicesTime'
import { TextInput } from 'components/atoms'
import { formatPrice } from 'utils'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { ModalButtonBackground } from 'components-v2/atoms'

const AppointmentSelectOptions = ({ setStep, onClose, isSingleSale, setClickEmpty }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const toast = useToast()
  const {
    type,
    setType,
    client,
    setClient,
    barber,
    setBarber,
    serviceArray,
    setServiceArray,
    date,
    setDate,
    clearState,
    time,
    setTime,
    finishHour,
    setFinishHour,
    barberSell,
    setSignature,
    setSignatureId,
    signature,
    setBarberSell,
    costPrice,
    setCostPrice,
    scheduleType,
    setScheduleType,
    packageService,
    setAppointmentType,
    setPackageService,
    setPackageSell,
    appointmentType,
  } = useAppointmentStore()
  const { selectedAppointment, setDiscount: setDiscountArray, setNormal } = useAppointmentDetailStore()
  const {
    client: clientSingleSale,
    setClient: setClientSingleSale,
    barber: barberSingleSale,
    setBarber: setBarberSingleSale,
    products,
    nullProducts,
    removeProduct,
    discount,
    setDiscount,
    paymentMethod,
    setPaymentMethod,
    loading: loadingSingleSale,
    clearState: clearStateSingleSale,
  } = useSingleSaleStore()
  const { additionalProductsAppointmentList } = additionalProductsStore()

  const { createSingleSaleForBarber, createSingleSaleForClient } = useSingleProductsSale()

  const { userData } = useBarbershopStore()
  const isBlocked = usePremiumBlock()
  const { newAppointment, newPackageService } = useAppointment()
  const appointment = usePermissionCollaborator('appointment')
  const [inputValue, setInputValue] = useState([])
  const queryClient = useQueryClient()
  const createAppointment = () => {
    if (type === 'fit') {
      if (!time || !finishHour) {
        toast({
          title: t('TIME_START_END'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })

        return
      }

      if (time > finishHour) {
        toast({
          title: t('START_TIME_UNDER_END'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return
      }
    }
    const params = {
      date: format(date, 'yyyy-MM-dd'),
      currentHour: format(new Date(), 'HH:mm'),
      currentDate: format(new Date(), 'yyyy-MM-dd'),
      barber_id: barber.id,
      servicesId: serviceArray.map((item) => item?.service_id || item.id),
      start_hour: time,
      type,
      barbershop_id: userData?.id,
      time_required: calculateInitialHour(serviceArray),
      signature: appointmentType === 'signature' ? true : false,
      plan_id: appointmentType === 'signature' ? signature?.plan_id : null,
      packageService: appointmentType === 'package' ? true : false,
      schedule_source: 'web_normal'
    }

    if (client.created) {
      params.name = client.client.name
      params.phone = client.client.user.phone
      params.email = client.client.user.email
    } else {
      params.client_id = client.client.id
    }

    if (type === 'fit') {
      params.finish_hour = finishHour
    }
    mutate.mutate({ params })
    setClickEmpty(false)
  }

  const mutate = useMutation('create-appointment', ({ params }) =>
    newAppointment({
      params,
      onSuccess: () => {
        onClose()
        clearState()
      },
    })
  )

  const mutatePackage = useMutation(
    'create-service-package',
    ({ params }) =>
      newPackageService({
        params,
        onSuccess: () => {
          onClose()
          clearState()
          setScheduleType('appointment')
        },
      }),
    {
      onSettled: () => {
        setPackageService(false)
        setPackageSell(false)
        queryClient.invalidateQueries('get-clients-package')
      },
    }
  )

  const mutation_single_sale_for_barber = useMutation(
    'create-single-sale-for-barber',
    (params) => createSingleSaleForBarber(params),
    {
      onSuccess: () => {
        toast({
          title: t('Venda de produto para barbeiro realizada com sucesso!'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        clearStateSingleSale()
        clearState()
        onClose()
      },
      onError: (error) =>
        toast({
          title: t('Erro ao realizar venda de produto. Por favor, tente novamente'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
    }
  )

  const mutation_single_sale_for_client = useMutation(
    'create-single-sale-for-barber',
    (params) => createSingleSaleForClient(params),
    {
      onSuccess: () => {
        toast({
          title: t('Venda de produto para cliente realizada com sucesso!'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        clearStateSingleSale()
        clearState()
        onClose()
      },
      onError: (error) =>
        toast({
          title: t('Erro ao realizar venda de produto. Por favor, tente novamente'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
    }
  )

  const createSingleSale = () => {
    if (barberSell) {
      const params = {
        barbershop_id: userData?.barbershop?.id || userData?.id,
        barber_id: !barberSingleSale?.type ? barberSingleSale?.id : null,
        employee_id: barberSingleSale?.type ? barberSingleSale?.id : null,
        products,
        discount: discount?.length > 0 ? discount?.filter((i) => i.discount_value > 0) : discount,
        payment_method: paymentMethod?.length > 1 ? inputValue : [{ id: paymentMethod[0].id, value: total_value }],
        cost_price: costPrice,
      }
      mutation_single_sale_for_barber.mutate(params)
      return
    }
    const params = {
      barbershop_id: userData?.barbershop?.id || userData?.id,
      barber_id: !barberSingleSale?.type ? barberSingleSale?.id : null,
      employee_id: barberSingleSale?.type ? barberSingleSale?.id : null,
      products: products,
      client_id: clientSingleSale?.client?.id || clientSingleSale?.id,
      discount: discount?.length > 0 ? discount?.filter((i) => i.discount_value > 0) : discount,
      payment_method: paymentMethod?.length > 1 ? inputValue : [{ id: paymentMethod[0].id, value: total_value }],
      cost_price: costPrice,
    }
    mutation_single_sale_for_client.mutate(params)
  }

  const createPackageSell = () => {
    const params = {
      barbershop_id: userData?.barbershop?.id || userData?.id,
      servicesId: serviceArray.map((item) => item?.service_id || item.id),
      client_id: client?.client_id,
      payment_method: paymentMethod?.length > 1 ? inputValue : [{ id: paymentMethod[0].id, value: total_value }],
    }
    mutatePackage.mutate({ params })
  }

  const addProductValue = products?.reduce((acumulador, item) => {
    return acumulador + Number(costPrice ? item?.cost_price : item?.price || item?.product?.price || 0)
  }, 0)
  const total_value = useMemo(() => {
    if (scheduleType === 'package') {
      return serviceArray.reduce((totalValue, item) => totalValue + Number(item?.price), 0)
    }
    if (products?.total_after_discounts) {
      return products?.total_after_discounts
    }
    const productsValue = additionalProductsAppointmentList.reduce(
      (totalValue, item) =>
        totalValue +
        Number(
          costPrice && item?.cost_price
            ? item?.cost_price
            : item?.item_value || item?.additional_product?.price || item?.additional_product?.value
        ),
      0
    )
    if (discount?.length) {
      const disc_value = discount?.reduce((totalValue, item) => totalValue + Number(item?.discount_value), 0)
      return productsValue + addProductValue - disc_value
    }
    return productsValue + addProductValue - (discount?.discount || 0)
  }, [scheduleType, products, additionalProductsAppointmentList, addProductValue, discount, serviceArray, costPrice])

  const totalValue = useMemo(() => {
    const valorDosInputs = inputValue.reduce((accumulator, currentValue) => accumulator + currentValue?.value, 0)
    return valorDosInputs
  }, [inputValue])

  const handleChange = (id, value) => {
    const existingItem = inputValue.find((item) => item.id === id)
    if (existingItem) {
      const updatedInputValue = inputValue.map((item) =>
        item.id === id ? { ...item, value: Number(value.replace(',', '.')) } : item
      )
      setInputValue(updatedInputValue)
    } else {
      setInputValue([...inputValue, { id, value: Number(value.replace(',', '.')) }])
    }
  }

  const isDisabled = useMemo(() => {
    if (isSingleSale) {
      return (
        !paymentMethod ||
        (barberSell ? !barberSingleSale : !clientSingleSale) ||
        !products.length ||
        (paymentMethod?.length > 1 && totalValue !== total_value)
      )
    }

    if (scheduleType === 'package') {
      return (
        !client ||
        !serviceArray ||
        serviceArray?.length === 0 ||
        !paymentMethod ||
        paymentMethod?.length === 0 ||
        (paymentMethod?.length > 1 && totalValue !== total_value)
      )
    }
    return (
      !barber ||
      serviceArray?.length < 1 ||
      !client ||
      !date ||
      !time ||
      mutate.isLoading ||
      loadingSingleSale ||
      mutatePackage.isLoading ||
      mutation_single_sale_for_barber.isLoading ||
      mutation_single_sale_for_client.isLoading
    )
  }, [
    barber,
    barberSell,
    barberSingleSale,
    client,
    clientSingleSale,
    date,
    isSingleSale,
    loadingSingleSale,
    mutate.isLoading,
    mutatePackage.isLoading,
    mutation_single_sale_for_barber.isLoading,
    mutation_single_sale_for_client.isLoading,
    paymentMethod,
    products.length,
    scheduleType,
    serviceArray,
    time,
    totalValue,
    total_value,
  ])

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      px={isBlocked && isSingleSale && theme.pxToRem(24)}
      pt={isBlocked && isSingleSale && theme.pxToRem(24)}
      height="100%"
      pointerEvents={isBlocked && isSingleSale && 'none'}
    >
      <Box pb={40}>
        {isSingleSale && (
          <Box marginBottom={theme.pxToRem(16)}>
            <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
              Esta venda é para um colaborador?
            </Text>
            <Flex>
              <Button
                kind={barberSell ? 'primary' : 'secondary'}
                size="medium"
                marginRight="2%"
                h={12}
                onClick={() => {
                  setClientSingleSale(null)
                  setBarberSell(true)
                }}
              >
                Sim
              </Button>
              <Button
                kind={!barberSell ? 'primary' : 'secondary'}
                size="medium"
                h={12}
                onClick={() => {
                  setBarberSell(false)
                }}
              >
                Não
              </Button>
            </Flex>
          </Box>
        )}
        {!barberSell && (
          <Box marginBottom={theme.pxToRem(16)}>
            <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
              {t('CUSTOMER')}
            </Text>

            <Flex>
              {client || (isSingleSale && clientSingleSale) ? (
                <ClientCard
                  client={isSingleSale ? clientSingleSale : client}
                  onClick={() => {
                    isSingleSale ? setClientSingleSale(null) : setClient(null)
                    setSignature(false)
                    setSignatureId(false)
                    setPackageService(false)
                    setServiceArray([])
                  }}
                />
              ) : (
                <Button
                  size="block"
                  kind="appointmentOptions"
                  onClick={() => setStep(APPOINTMENT_STEPS.selectCustomer)}
                >
                  {t('SELECT_CUSTOMER')}
                </Button>
              )}
            </Flex>
          </Box>
        )}

        {scheduleType !== 'package' && (
          <Box marginBottom={theme.pxToRem(16)}>
            <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
              {isSingleSale && !barberSell ? t('SELLER_OPTIONAL') : 'Colaborador'}
            </Text>

            <Flex>
              {barber || (isSingleSale && barberSingleSale) ? (
                <BarberCard
                  barber={isSingleSale ? barberSingleSale : barber}
                  isSingleSale={isSingleSale}
                  onClick={() => {
                    if (isSingleSale) {
                      nullProducts()
                      setBarberSingleSale(null)
                    } else {
                      setBarber(null)
                      setDate(null)
                      setTime(null)
                      setServiceArray([])
                    }
                  }}
                />
              ) : (
                <Button size="block" kind="appointmentOptions" onClick={() => setStep(APPOINTMENT_STEPS.selectBarber)}>
                  Selecionar colaborador
                </Button>
              )}
            </Flex>
          </Box>
        )}

        <Box marginBottom={theme.pxToRem(16)}>
          <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
            {isSingleSale ? t('PRODUCTS') : t('_SERVICE')}
          </Text>

          <Box>
            {isSingleSale && !!products.length && (
              <>
                {products?.map((product, index) => (
                  <ServiceCard
                    isSingleSale
                    service={product}
                    date={date}
                    mb={theme.pxToRem(8)}
                    onDelete={() => {
                      removeProduct(product)
                    }}
                    price={costPrice ? product?.cost_price : false}
                  />
                ))}
              </>
            )}
            {!!serviceArray.length &&
              serviceArray.map((item) => (
                <ServiceCard
                  mb={theme.pxToRem(8)}
                  service={item}
                  onDelete={() => {
                    setAppointmentType('normal')
                    setServiceArray(serviceArray.filter((r) => r !== item))
                  }}
                  date={date}
                />
              ))}
            <Button
              size="block"
              kind="appointmentOptions"
              onClick={() => {
                if (isSingleSale) {
                  setStep(APPOINTMENT_STEPS.selectProducts)
                } else {
                  setAppointmentType('normal')
                  setStep(APPOINTMENT_STEPS.selectService)
                }
              }}
              isDisabled={scheduleType === 'package' ? !client : isSingleSale ? false : !barber}
            >
              {isSingleSale ? t('SELECT_PRODUCTS') : t('SELECT_SERVICE')}
            </Button>
          </Box>
        </Box>

        {scheduleType !== 'package' && (
          <Box marginBottom={theme.pxToRem(16)} flex={1}>
            <Text marginBottom={theme.pxToRem(16)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
              {t(isSingleSale ? 'DISCOUNT' : 'APPOINTMENT_TYPE')}
            </Text>

            <Flex justifyContent="space-between" width="100%">
              {isSingleSale ? (
                discount ? (
                  discount?.length ? (
                    <Flex w="100%" flexDir="column">
                      {discount
                        ?.filter((i) => i?.discount_value > 0)
                        ?.map((d) => (
                          <AdditionalProductCard
                            br={8}
                            isDiscount
                            product={{
                              name: d?.name,
                              value: -d?.discount_value,
                            }}
                            onClick={() => {
                              setDiscount(null)
                              setDiscountArray(null)
                              setNormal(true)
                            }}
                          />
                        ))}
                    </Flex>
                  ) : (
                    <AdditionalProductCard
                      br={8}
                      product={{
                        name: discount?.discount_observation,
                        value: discount?.discount,
                      }}
                      onClick={() => setDiscount(null)}
                    />
                  )
                ) : (
                  <Button
                    size="block"
                    kind="appointmentOptions"
                    onClick={() =>
                      setStep(!barberSell ? APPOINTMENT_STEPS.selectDiscount : APPOINTMENT_STEPS.selectDiscountBarber)
                    }
                    isDisabled={!products.length || (!barberSell && !clientSingleSale)}
                  >
                    {t('SELECT_DISCOUNT')}
                  </Button>
                )
              ) : (
                <>
                  <Button
                    kind={type === 'normal' ? 'primary' : 'secondary'}
                    size="medium"
                    marginRight="32px"
                    onClick={() => {
                      setDate(null)
                      setTime(null)
                      setType('normal')
                    }}
                  >
                    {t('STANDARD')}
                  </Button>
                  <Button
                    kind={type === 'fit' ? 'primary' : 'secondary'}
                    size="medium"
                    onClick={() => {
                      setDate(null)
                      setTime(null)
                      setType('fit')
                    }}
                  >
                    {t('FIT')}
                  </Button>
                </>
              )}
            </Flex>
          </Box>
        )}

        <Box marginBottom={theme.pxToRem(16)}>
          <Text marginBottom={theme.pxToRem(12)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
            {t(isSingleSale || scheduleType === 'package' ? 'SELECT_PAYMENT_METHOD' : 'SELECT_DATE_TIME')}
          </Text>

          <Flex flexDirection="column">
            {/* MÉTODO PAYMENT */}
            {/* -> isSingleSale && paymentMethod -> igual ao ao DetailOptions */}
            {(isSingleSale || scheduleType === 'package') &&
              !!paymentMethod?.length &&
              paymentMethod.map((pm) => (
                <Box minHeight={paymentMethod?.length > 1 && theme.pxToRem(156)} mb={theme.pxToRem(16)}>
                  <PaymentMethodCard
                    marginBottom={theme.pxToRem(16)}
                    paymentMethod={pm}
                    onClick={
                      selectedAppointment?.status !== 'finished' && appointment === 'edit'
                        ? () => setPaymentMethod(paymentMethod.filter((r) => r !== pm))
                        : null
                    }
                  />
                  {paymentMethod?.length > 1 && (
                    // Fazer a verificação valor digitado é igual total_value
                    <TextInput
                      mask="CURRENCY_MASK"
                      value={inputValue?.find((i) => i?.id === pm?.id)?.value}
                      label={t('VALUE_PAYMENT')}
                      placeholder="Digite o valor"
                      mb={theme.pxToRem(16)}
                      onChange={(e) => handleChange(pm?.id, e.target.value)}
                    />
                  )}
                </Box>
              ))}

            {/* controla valores digitados no input */}

            {paymentMethod?.length > 1 && totalValue === 0 ? (
              <Text color="red"> Digite o valor igual ao total: {formatPrice(total_value - totalValue)}</Text>
            ) : (
              <Text color="red">
                {paymentMethod?.length > 1 && totalValue !== total_value && (
                  <>
                    O valor inserido é {totalValue > total_value ? 'maior' : 'menor'} que o total em{' '}
                    {formatPrice(Math.abs(totalValue - total_value))}
                  </>
                )}
              </Text>
            )}

            {/* MÉTODO -> !isSingleSale - AGENDAMENTO NORMAL  */}
            {!isSingleSale &&
              scheduleType !== 'package' &&
              (date && time ? (
                <DateTimeCard
                  date={date}
                  time={time}
                  finishHour={finishHour}
                  onClick={() => {
                    setDate(null)
                    setTime(null)
                    setFinishHour(null)
                  }}
                />
              ) : (
                <Button
                  size="block"
                  kind="appointmentOptions"
                  onClick={() => setStep(APPOINTMENT_STEPS.selectDateAndTime)}
                  isDisabled={!barber || !serviceArray || !client}
                >
                  {t('SELECT_DATE')}
                </Button>
              ))}
            {(isSingleSale || scheduleType === 'package') && (
              <>
                <Button
                  mt={theme.pxToRem(16)}
                  size="block"
                  kind="appointmentOptions"
                  onClick={() => setStep(APPOINTMENT_STEPS.selectPaymentMethod)}
                  disabled={scheduleType === 'package' && (!serviceArray || serviceArray.length === 0)}
                >
                  {t('SELECT_PAYMENT_METHOD')}
                </Button>
              </>
            )}

            {barberSell && (
              <Box marginBottom={theme.pxToRem(16)} mt={theme.pxToRem(16)}>
                <Text marginBottom={theme.pxToRem(16)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
                  Considerar preço de custo?
                </Text>
                <Flex>
                  <Button
                    kind={costPrice ? 'primary' : 'secondary'}
                    size="medium"
                    marginRight="32px"
                    onClick={() => {
                      setCostPrice(true)
                    }}
                  >
                    Sim
                  </Button>
                  <Button
                    kind={!costPrice ? 'primary' : 'secondary'}
                    size="medium"
                    onClick={() => {
                      setCostPrice(false)
                    }}
                  >
                    Não
                  </Button>
                </Flex>
              </Box>
            )}

            {/* {( !isSingleSale && date && time) && (
                <DateTimeCard
                  date={date}
                  time={time}
                  finishHour={finishHour}
                  onClick={() => {
                    setDate(null)
                    setTime(null)
                    setFinishHour(null)
                  }}
                />
            ) }
             : (
              <Button
                size="block"
                kind="appointmentOptions"
                onClick={() =>
                  setStep(
                    //isSingleSale
                      // ? APPOINTMENT_STEPS.selectPaymentMethod
                      // : 
                      APPOINTMENT_STEPS.selectDateAndTime
                  )
                }
                isDisabled={
                  isSingleSale
                    ? !clientSingleSale || !products.length
                    : !barber || !serviceArray || !client
                }
              >
                {t(isSingleSale ? 'SELECT_PAYMENT_METHOD' : 'SELECT_DATE')}
              </Button>
            )} */}
          </Flex>
        </Box>
      </Box>

      <ModalButtonBackground>
        <Button
          size="block"
          onClick={isSingleSale ? createSingleSale : scheduleType === 'package' ? createPackageSell : createAppointment}
          isLoading={
            mutate.isLoading ||
            loadingSingleSale ||
            mutatePackage.isLoading ||
            mutation_single_sale_for_barber.isLoading ||
            mutation_single_sale_for_client.isLoading
          }
          isDisabled={isDisabled}
        >
          {t('CONFIRM')}
        </Button>
      </ModalButtonBackground>
    </Flex>
  )
}

export default AppointmentSelectOptions
