import * as React from 'react'

function SvgGestaoFacil(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.285 5h10.667c.29 0 .524.235.524.524v15.238c0 .29-.235.524-.524.524H23.285a.524.524 0 01-.523-.524V5.524c0-.29.234-.524.523-.524zM6.556 20h10.888c.397 0 .556.273.556.417v14.166c0 .145-.159.417-.556.417H6.556C6.159 35 6 34.727 6 34.583V20.417c0-.145.159-.417.556-.417zM6.524 5H17.19c.29 0 .524.235.524.524v9.143c0 .289-.234.524-.523.524H6.524A.524.524 0 016 14.666V5.524c0-.29.235-.524.524-.524zM23.444 26h10.112c.292 0 .444.214.444.375v8.25c0 .161-.152.375-.444.375H23.444c-.292 0-.444-.214-.444-.375v-8.25c0-.161.152-.375.444-.375z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  )
}

export default SvgGestaoFacil
