import * as Yup from 'yup'
import { t } from 'services/translation'
import { Box } from '@chakra-ui/react'
import { Icon } from 'components/atoms'

export const input = ({
  theme,
  errors,
  setOpenPass,
  openPass,
  openNew,
  setOpenNew,
}) => {
  return [
    {
      id: 0,
      name: 'name',
      type: 'string',
      noBorder: true,
      label: 'BARBERSHOP_NAME',
      placeholder: t('BARBERSHOP_NAME_PLACEHOLDER'),
    },
    {
      id: 1,
      name: 'owner_name',
      type: 'string',
      noBorder: true,
      label: 'RESPONSABLED_NAME',
      placeholder: t('OWNER_NAME_PLACEHOLDER'),
    },
    {
      id: 2,
      name: 'phone',
      type: 'cel-phone',
      noBorder: true,
      label: 'Whatsapp do Responsável',
      mask: 'PHONE_MASK',
      placeholder: '(XX) XXXXX-XXXX',
    },
    {
      id: 3,
      name: 'email',
      type: 'string',
      noBorder: true,
      label: 'E-mail',
      placeholder: 'E-mail',
    },
    {
      id: 4,
      name: 'password',
      type: openPass ? 'text' : 'password',
      noBorder: true,
      label: 'NEW_PROFILE_PASSWORD',
      placeholder: t('PASSWORD_PLACEHOLDER'),
      rightElement: <Icon name={openPass ? 'EyeOpen' : 'EyeClosed'} />,
      buttonElement: () => setOpenPass(!openPass),
      leftElement: (
        <Box
          mr={theme.pxToRem(16)}
          color={errors?.password ? theme.colors.danger : theme.colors.primary}
        >
          <Icon
            name="Lock"
            color={
              errors?.password ? theme.colors.danger : theme.colors.primary
            }
            ml={theme.pxToRem(10)}
          />
        </Box>
      ),
    },
    {
      id: 4,
      name: 'confirmPassword',
      type: openNew ? 'type' : 'password',
      noBorder: true,
      label: 'REPEAT_NEW_PASSWORD',
      placeholder: t('CONFIRM_PASSWORD_PLACEHOLDER'),
      rightElement: <Icon name={openNew ? 'EyeOpen' : 'EyeClosed'} />,
      buttonElement: () => setOpenNew(!openNew),
      leftElement: (
        <Box
          mr={theme.pxToRem(16)}
          color={
            errors?.confirmPassword ? theme.colors.danger : theme.colors.primary
          }
        >
          <Icon
            name="Lock"
            color={
              errors?.confirmPassword
                ? theme.colors.danger
                : theme.colors.primary
            }
            ml={theme.pxToRem(10)}
          />
        </Box>
      ),
    },
    {
      id: 5,
      name: 'cupom',
      type: 'string',
      noBorder: true,
      label: 'Possui um código? Digite aqui',
      placeholder: 'Digite seu código',
    },
  ]
}

export const validation = Yup.object().shape({
  owner_name: Yup.string()
    .min(4, 'NAME')
    .required('Nome do responsável é obrigatório'),
  email: Yup.string().email(t('INVALID_EMAIL')).required(t('EMAIL_REQUIRED')),
  phone: Yup.string().required(t('CELLPHONE_REQUIRED')),
  name: Yup.string().min(3, t('NAME')).required(t('NAME_REQUIRED')),
  password: Yup.string().min(6, t('PASSWORD')).required(t('PASSWORD_REQUIRED')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], t('CONFIRM_PASSWORD'))
    .min(6, t('PASSWORD'))
    .required(t('PASSWORD_REQUIRED')),
})

export const initial = {
  name: '',
  owner_name: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  cupom: '',
}
