import React, { useState } from 'react'
import { Box, Center, Flex, Grid, GridItem, Spinner, useToast } from '@chakra-ui/react'
import { ColumnChart, FinanceResumeCard, Table } from 'components/molecules'
import { Select } from 'components-v2/atoms/Select'
import { cards, columns } from './settings'
import { useBarbershopStore } from 'services/stores'
import { useQuery } from 'react-query'
import { useAverageTicket } from 'services/hooks/use-average-ticket'

export const AvarageTicket = ({ startDate, endDate }) => {
  const [status, setStatus] = useState()
  const { userData } = useBarbershopStore()
  const { getAverageTicket } = useAverageTicket()
  const toast = useToast()

  const { isLoading, data } = useQuery(
    ['get-average-ticket', startDate, endDate, status],
    () => getAverageTicket({ id: userData?.id, startDate, endDate, status }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        toast({
          title: 'Erro ao buscar dados',
          description: error.response?.data?.error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <Box w="100%" mt="24px">
      <Select
        w="350px"
        label="Status do agendamento"
        name="status"
        onChange={(e) => setStatus(e.target.value)}
        value={status}
        list={['Finalizado', 'Todos']}
      />
      {isLoading ? (
        <Center w="100%">
          <Spinner color="textLight" />
        </Center>
      ) : (
        <Box w="100%" mt="16px">
          <Grid
            w="100%"
            justify="space-between"
            templateColumns={'repeat(3, 1fr)'}
            templateRows="auto"
            gap={4}
            flexDir="row"
          >
            {cards(data).map((item) => (
              <GridItem w="100%">
                <FinanceResumeCard {...item} />
              </GridItem>
            ))}
          </Grid>

          <Flex w="100%" mt="16px">
            <ColumnChart
              h="400px"
              w="100%"
              data={data?.data || []}
              type="money"
              title="Ticket Médio"
              name="Ticket Médio"
              categories={data?.categories || []}
            />
          </Flex>

          <Table
            loading={isLoading}
            data={
              data?.average_ticket_barbers?.sort((a, b) => {
                return a?.total_value > b?.total_value ? -1 : a?.total_value < b?.total_value ? 1 : 0
              }) || []
            }
            columns={columns}
          />
        </Box>
      )}
    </Box>
  )
}
