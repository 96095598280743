import React, { useMemo, useState } from 'react'
import { Center, Spinner, useTheme, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Text,
  Button,
  ClientCard,
  ServiceCard,
  BarberCard,
  PaymentMethodCard,
  AdditionalProductCard,
  ModalMenu,
  ModalAlert,
} from 'components'
import { useLazyFetch, usePermissionCollaborator, useBarbershop } from 'services/hooks'
import { SINGLE_PRODUCT_DELETE, SINGLE_PRODUCT_GET } from 'services/api/endpoints'
import { useQuery } from 'react-query'

export const SingleProductModal = ({ selectedSale, isOpen, onClose }) => {
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const permission = usePermissionCollaborator('single_product')
  const { getSingleProductDetail } = useBarbershop()

  const { data, loading } = useQuery('get-single-sale-detail', () => getSingleProductDetail({ id: selectedSale?.id }), {
    enabled: !!selectedSale?.id,
  })

  const [deleteSingleProduct, { loading: loadingDelete }] = useLazyFetch(SINGLE_PRODUCT_DELETE(selectedSale?.id), {
    method: 'delete',
    onCompleted: () => {
      toast({
        title: t('DELETE_SINGLE_SALE_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })

      onClose()
    },
    onError: ({ error }) => {
      toast({
        title: t('DELETE_SINGLE_SALE_ERROR'),
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })
  const handleDelete = async () => {
    await deleteSingleProduct()
  }
  const products = useMemo(
    () => data?.appointmentProducts || selectedSale?.formatted_products || selectedSale?.additional_product,
    [selectedSale, data]
  )
  const payment_methods = useMemo(
    () =>
      data?.appointment_payments ||
      selectedSale?.formatted_payments ||
      selectedSale?.payment_method_choosed ||
      selectedSale?.payment_methods ||
      [],
    [selectedSale, data]
  )

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={onClose}
      title={t(selectedSale?.type !== 'singleProductBarber' ? 'SINGLE_PRODUCT' : 'SINGLE_PRODUCT_BARBER')}
    >
      {loading ? (
        <Center mt={theme.pxToRem(24)}>
          <Spinner color="primary" size="lg" />
        </Center>
      ) : (
        <Box width="100%" height="100%">
          {selectedSale?.type !== 'singleProductBarber' && (
            <>
              <Text kind="medium" fontSize={14} casing="uppercase" mb={theme.pxToRem(16)}>
                {t('CUSTOMER')}
              </Text>
              <ClientCard client={selectedSale?.client} mb={theme.pxToRem(24)} />{' '}
            </>
          )}

          {(selectedSale?.barber || data?.barber || data?.employer) && (
            <>
              <Text kind="medium" fontSize={14} mb={theme.pxToRem(10)}>
                Nº da comanda: {data?.id}
              </Text>
              <Text kind="medium" fontSize={14} casing="uppercase" mb={theme.pxToRem(16)}>
                {t('BARBER')}
              </Text>
              <BarberCard barber={selectedSale?.barber || data?.barber || data?.employer} mb={theme.pxToRem(24)} />{' '}
            </>
          )}

          <Text kind="medium" fontSize={14} casing="uppercase" mb={theme.pxToRem(16)}>
            {t('PRODUCT')}
          </Text>
          {products?.map((i) => (
            <ServiceCard
              isSingleSale
              service={{
                name: i?.additional_product?.name,
              }}
              price={i?.product_value}
              mb={theme.pxToRem(24)}
            />
          ))}

          {!!data?.discount && (
            <>
              <Text kind="medium" fontSize={14} casing="uppercase" mb={theme.pxToRem(16)}>
                {t('DISCOUNT')}
              </Text>
              <AdditionalProductCard
                br={8}
                product={{
                  name: data?.discount?.discount_observation || data?.discount_observation,
                  value: data?.discount?.discount || data?.discount,
                }}
                mb={theme.pxToRem(24)}
              />
            </>
          )}

          <Text kind="medium" fontSize={14} casing="uppercase" mb={theme.pxToRem(16)}>
            {t('PAYMENT_METHOD')}
          </Text>
          {payment_methods?.length === 0 ? (
            <PaymentMethodCard
              paymentMethod={{
                id: 'comission_barber',
                name: 'Cobrado na comissão do barbeiro',
              }}
              mb={theme.pxToRem(42)}
            />
          ) : (
            payment_methods?.map?.((i) => (
              <PaymentMethodCard
                paymentMethod={{
                  name: i?.payment_method_choosed?.name,
                  payment_value: i?.payment_value,
                  due_in_days: i?.payment_method_choosed?.due_in_days,
                  percentage: i?.payment_method_choosed?.percentage,
                }}
                status="finished"
                mb={theme.pxToRem(42)}
              />
            ))
          )}

          {permission === 'edit' && (
            <Button w="100%" onClick={() => setDeleteModalOpen(true)}>
              {t('DELETE')}
            </Button>
          )}
        </Box>
      )}

      <ModalAlert
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
        primaryButtonLabel={t('DELETE')}
        modalTitle={t('DELETE')}
        loading={loadingDelete}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CONFIRM_DELETE_SINGLE_SALE')}
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}
export * from './create'
