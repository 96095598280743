import * as Yup from 'yup'
import { t } from 'services/translation'

export const form_data = [
  {
    field: 'token',
    name: 'token',
    type: 'string',
    noBorder: true,
    label: 'Chave de acesso',
    autocomplete: 'one-time-code',
  },
  {
    field: 'new_password',
    name: 'new_password',
    type: 'password',
    noBorder: true,
    label: 'Nova senha',
    autocomplete: 'one-time-code',
  },
  {
    field: 'confirm_password',
    name: 'confirm_password',
    type: 'password',
    noBorder: true,
    label: 'Confirmar nova senha',
    autocomplete: 'one-time-code',
  },
]

export const form_validation = Yup.object().shape({
  token: Yup.string()
    .length(6, t('INVALID_TOKEN'))
    .required(t('TOKEN_REQUIRED')),
  new_password: Yup.string()
    .min(6, t('PASSWORD'))
    .required(t('PASSWORD_REQUIRED')),
  confirm_password: Yup.string()
    .min(6, t('PASSWORD'))
    .required(t('PASSWORD_REQUIRED')),
})

export const form_initial_values = {
  token: '',
  new_password: '',
  confirm_password: '',
}
