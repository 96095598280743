import * as Yup from 'yup'
import { t } from 'services/translation'
import { People, Telephone, Email } from 'assets/icons'
import { colors } from 'styles'
import { pxToRem } from 'styles/metrics'
import { Icon, Box } from 'components/atoms'

export const dataBarber = ({ theme, errors, setOpenPass, openPass, openNew, setOpenNew, type, isEdit, userData }) => {
  const options = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      noBorder: true,
      label: 'BARBER_NAME',
      placeholder: t('BARBER_NAME'),
      leftElement: <People color={colors?.primary} />,
    },
    {
      id: 'phone',
      name: 'phone',
      type: 'text',
      noBorder: true,
      label: 'PHONE',
      mask: userData?.id === 10848 ? 'EUA_PHONE_MASK' : 'PHONE_MASK',
      placeholder: t('PHONE_PLACEHOLDER'),
      leftElement: <Telephone width={pxToRem(16)} color={colors?.primary} />,
    },
    {
      id: 'email',
      name: 'email',
      type: 'text',
      noBorder: true,
      label: 'E-mail',
      placeholder: 'E-mail',
      leftElement: <Email color={colors?.primary} />,
    },
    {
      id: 'identifier_doc',
      name: 'identifier_doc',
      type: 'text',
      noBorder: true,
      label: 'CNPJ do Colaborador',
      placeholder: 'CNPJ do Colaborador',
      mask: 'CNPJ_MASK',
    },
    {
      id: 'corporate_name',
      name: 'corporate_name',
      type: 'text',
      noBorder: true,
      label: 'Razão social do Colaborador',
      placeholder: 'Razão social do Colaborador',
    },
  ]
  if (isEdit && type === 'barber') {
    options.push({
      id: 'visible_name',
      name: 'visible_name',
      type: 'text',
      noBorder: true,
      label: 'Definir função',
      placeholder: 'Barbeiro',
    })
  }

  if (!isEdit) {
    options.push(
      {
        id: 4,
        name: 'password',
        type: openPass ? 'text' : 'password',
        noBorder: true,
        label: 'CREATE_COLLABORATOR_PASSWORD',
        placeholder: t('CREATE_COLLABORATOR_PASSWORD'),
        rightElement: <Icon name={openPass ? 'EyeOpen' : 'EyeClosed'} />,
        buttonElement: () => setOpenPass(!openPass),
        leftElement: (
          <Box mr={theme.pxToRem(16)} color={errors?.password ? colors.danger : colors.primary}>
            <Icon name="Lock" color={errors?.password ? colors.danger : colors.primary} ml={theme.pxToRem(10)} />
          </Box>
        ),
      },
      {
        id: 4,
        name: 'confirmPassword',
        type: openNew ? 'type' : 'password',
        noBorder: true,
        label: 'CONFIRM_COLLABORATOR_PASSWORD',
        placeholder: t('CONFIRM_COLLABORATOR_PASSWORD'),
        rightElement: <Icon name={openNew ? 'EyeOpen' : 'EyeClosed'} />,
        buttonElement: () => setOpenNew(!openNew),
        leftElement: (
          <Box mr={theme.pxToRem(16)} color={errors?.confirmPassword ? colors.danger : colors.primary}>
            <Icon name="Lock" color={errors?.confirmPassword ? colors.danger : colors.primary} ml={theme.pxToRem(10)} />
          </Box>
        ),
      }
    )
  }

  return options
}

export const validationBarber = Yup.object().shape({
  name: Yup.string().min(4, t('NAME')).required(t('NAME_REQUIRED')),
  phone: Yup.string(),
  percentage: Yup.string(),
  products_percentage: Yup.string(),
  description: Yup.string().min(10, t('DESCRIPTION')).required(t('DESCRIPTION_REQUIRED')),
  password: Yup.string().min(6, t('PASSWORD')).required(t('PASSWORD_REQUIRED')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], t('CONFIRM_PASSWORD'))
    .min(6, t('PASSWORD'))
    .required(t('PASSWORD_REQUIRED')),
})

export const initialBarber = {
  name: '',
  phone: '',
  email: '',
  percentage: 0,
  products_percentage: 0,
  type: 'barber',
  password: '',
  confirmPassword: '',
  identifier_doc: '',
}

export const select = [
  {
    id: 0,
    name: t('barber'),
    value: 'barber',
  },
  {
    id: 1,
    name: 'Recepcionista',
    value: 'recepcionist',
  },
  {
    id: 2,
    name: 'Gerente',
    value: 'manager',
  },
  {
    id: 3,
    name: 'Caixa',
    value: 'collector',
  },
  {
    id: 4,
    name: 'Financeiro',
    value: 'finance',
  },
]
