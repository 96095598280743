import React, { useState } from 'react'
import { BestNewsModal, Button, Flex, Link, Table, Text } from 'components'
import { useToast } from '@chakra-ui/react'
import { Layout } from '../Components'
import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { useBarbershop } from 'services/hooks'
import { Trash } from 'assets/icons'
import { colors } from 'styles'

export const BestNews = () => {
  const [edit, setEdit] = useState(null)
  const [open, setOpen] = useState(false)
  const toast = useToast()
  const { deleteBestNews } = useBarbershop()

  const { isLoading, data, refetch } = useQuery(
    ['get-news'],
    () =>
      api({
        url: `v3/best-news`,
      }),
    {
      onError: (error) => {
        toast({
          title: 'Erro ao buscar noticias',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
      refetchOnWindowFocus: false,
    }
  )

  const mutateDelete = useMutation('delete-best-news', (params) => deleteBestNews(params))

  const deleteNews = (item) => {
    mutateDelete.mutate(
      { id: item?.id },
      {
        onSuccess: () => {
          refetch()
          toast({ title: 'Lançamento excluido com sucesso', isClosable: true, status: 'success', duration: 4000 })
        },
        onSettled: () => {},
        onError: (error) => {
          toast({
            title: 'Erro ao excluir news',
            description: error.response?.data?.error,
            isClosable: true,
            status: 'error',
            duration: 4000,
          })
        },
      }
    )
  }

  const columns = [
    {
      key: 'title',
      label: 'TITULO',
      render: (item) => <Text>{item?.title}</Text>,
    },
    {
      key: 'description',
      label: 'DESCRICAO',
      render: (item) => <Text>{item?.description}</Text>,
    },
    {
      key: 'pdf',
      label: 'ARQUIVO',
      render: (item) => (
        <Link isExternal href={item?.file_url}>
          {item?.file_url}
        </Link>
      ),
    },
    {
      key: 'image',
      label: 'IMAGE',
      render: (item) => (
        <Link isExternal href={item?.image_url}>
          {item?.image_url}
        </Link>
      ),
    },
    {
      key: 'excluir',
      label: 'EXCLUIR',
      render: (item) => (
        <Flex onClick={() => deleteNews(item)}>
          <Trash color={colors.danger} style={{ fontWeigth: 'bold' }} />
        </Flex>
      ),
    },
  ]

  return (
    <Layout title="Cadastrar BestNews">
      <Flex
        flexDir="row"
        position="absolute"
        top="30px"
        right="30px"
        align="center"
        onClick={() => setOpen(true)}
        cursor="pointer"
      >
        <Button size="x-small">Adicionar news</Button>
      </Flex>
      <Table
        data={data?.data?.bestNews || []}
        loading={isLoading}
        columns={columns}
        isSelectableRow
        onSelect={(item) => {}}
      />

      <BestNewsModal
        edit={edit}
        isModalOpen={open}
        onClose={() => {
          setEdit(null)
          setOpen(false)
        }}
      />
    </Layout>
  )
}

BestNews.path = '/internal-adm/best-news'
BestNews.title = 'Cadastrar barbearia'
BestNews.secured = true
