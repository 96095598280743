import React from 'react'
import { Text } from 'components/atoms'
import { Box, useTheme, Flex, Switch, Skeleton } from '@chakra-ui/react'
import { theme } from 'styles/theme'

export const SelectCard = ({
  item,
  selected,
  setSelected,
  disabled,
  isLoading,
  ...props
}) => {
  return (
    <Box
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent="flex-start"
      px={theme.pxToRem(16)}
      py={theme.pxToRem(8)}
      w="100%"
      minH={theme.pxToRem(56)}
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Skeleton isLoaded={!isLoading} w="full" borderRadius={6}>
        <Flex
          w="100%"
          flexDir="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            kind="bold"
            color="textLight"
            fontSize={theme.pxToRem(14)}
            ml={theme.pxToRem(8)}
          >
            {item?.title}
          </Text>
          <Text
            kind="medium"
            color="textLight"
            fontSize={theme.pxToRem(12)}
            ml={theme.pxToRem(8)}
          >
            {item?.description}
          </Text>
          <Switch
            colorScheme="yellow"
            onChange={(e) => setSelected(e.target.checked)}
            isChecked={selected}
            isDisabled={disabled}
          />
        </Flex>
      </Skeleton>
    </Box>
  )
}
