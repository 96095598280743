import { Flex } from '@chakra-ui/react';
import React from 'react'
import Chart from 'react-apexcharts'
import { colors } from 'styles'

export const ColumnChart = (props) => {
  const columnChartOptions = {
    series: [{
      name: props?.name,
      // data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
      data: props?.data ?? []
    }],
    options: {
      chart: {
        height: props?.h,
        background: '#363D49',
          borderRadius: 100,
          type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
        dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      }, 
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return props?.type === 'money' ? "R$ " + parseFloat(val).toFixed(2) : val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#FFF"]
        }
      },
      
      xaxis: {
        categories: props?.categories,
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          style: {
            colors: '#fff',
        },
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#000',
              colorTo: '#FFF',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return props?.type === 'money' ? "R$ " + parseFloat(val).toFixed(2) : val + "%";
          },
          style: {
            colors: '#fff',
        },
        },
      },
          colors: ['#E5B817'],
      title: {
        text: props?.title,
        floating: true,
        offsetY: 0,
        align: 'center',
        style: {
          color: '#FFF'
        }
      }
    },
  };


  return (
    // <Flex
    //   flexDir="column"
    //   alignItems="center"
    //   justifyContent="center"
    //   p='16px'
    //   w="100%"
    //   bg="cardBackground"
    //   borderRadius='8px'
    //   borderWidth={1}
    //   borderColor="modalBorder"
    //   {...props}
    // >
    //  <Text kind="extraBold">{t(props?.title)}</Text>
      <Chart
        options={columnChartOptions?.options}
        series={columnChartOptions?.series}
        type="bar"
        // width={props?.w}
        height={props?.h}
        style={{width: props?.w}}
      />
    // </Flex>
  )
}
