import React from 'react'
import { Flex, Table } from 'components'
import { useAppointmentStore } from 'services/stores'
import { columnsSignature } from './settings'

export const AppointmentSignatureSelectService = ({ goBack, clickEmpt }) => {
  const {
    setServiceArray,
    serviceArray,
    setDate,
    client,
    setAppointmentType,
  } = useAppointmentStore()

  const handleSelect = (item, type) => {
      setAppointmentType(type)
      if(type === 'signature') {
      if (serviceArray.includes(item)) {
        const updateItems = serviceArray.filter((i) => i !== item)
        setServiceArray(updateItems)
        setDate(null)
      } else {
        setServiceArray([...serviceArray, item])
      }
    }
  }

    return (
      <Flex>
        <Table
            withoutHeader
            data={client?.plan?.services_plans || []}
            columns={columnsSignature}
            isSelectableRow
            onSelect={(sel) => handleSelect(sel, 'signature')}
            selectedList={serviceArray}
            isMultiSelectable
          />
      </Flex>
    )
}