import React from 'react'

const PremiumCrownSvg = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.9998 2.16602C7.009 2.16602 2.1665 7.04102 2.1665 12.9993C2.1665 18.9577 7.0415 23.8327 12.9998 23.8327C18.9582 23.8327 23.8332 18.9577 23.8332 12.9993C23.8332 7.04102 18.9582 2.16602 12.9998 2.16602ZM12.9998 21.666C8.2115 21.666 4.33317 17.7877 4.33317 12.9993C4.33317 8.21102 8.2115 4.33268 12.9998 4.33268C17.7882 4.33268 21.6665 8.21102 21.6665 12.9993C21.6665 17.7877 17.7882 21.666 12.9998 21.666ZM8.6665 15.166L7.58317 8.66602L10.8332 10.8327L12.9998 7.58268L15.1665 10.8327L18.4165 8.66602L17.3332 15.166H8.6665ZM9.27317 17.3327C8.90484 17.3327 8.6665 17.0943 8.6665 16.726V16.2493H17.3332V16.726C17.3332 17.0943 17.0948 17.3327 16.7265 17.3327H9.27317Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PremiumCrownSvg
