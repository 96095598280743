import { VStack, Text, Flex } from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'

export const GridTimeBar = ({
  rowHeight,
  rows30Min,
  minTime,
  agenda_interval,
  ...props
}) => {
  return (
    <VStack spacing={0} {...props}>
      {Array.from({ length: rows30Min }, (_e, i) => (
        <Flex
          key={i}
          h={`${(rowHeight + 1) * (agenda_interval / 5)}px`}
          w="full"
        >
          <Text mx="auto" mt="-20%" fontSize={14} color="gray.100">
            {format(
              addMinutes(
                new Date(`2000/01/01 ${minTime}`),
                i * agenda_interval
              ),
              'HH:mm'
            )}
          </Text>
        </Flex>
      ))}
    </VStack>
  )
}
