import React, { useEffect, useState } from 'react'
import { t } from 'services/translation'
import { Box, Flex, Table, Text } from 'components'
import { useAppointmentStore, useBarbershopStore } from 'services/stores'
import { formatPrice } from 'utils'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useQuery } from 'react-query'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import { useBarbershopSubscription } from 'services/hooks'
import { columnsSignature } from './settings'
import { format, parseISO } from 'date-fns'
const dayNamesShort = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Box>
        <Text w="100%" kind="bold" color="textLight">
          {item?.name}
        </Text>
        {item?.promotion_days && item?.promotion_price && (
          <Text w="100%" kind="medium" color="textLight">
            Valor de {formatPrice(item?.promotion_price)} às{' '}
            {item?.promotion_days?.map((i) => dayNamesShort[i])?.join(', ')}
          </Text>
        )}
      </Box>
    ),
  },
  {
    key: 'price',
    label: 'VALUES',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item?.type === 'signature'
          ? 'Serviço pelo clube'
          : item?.type === 'individual_service_package'
          ? 'Pacote de serviço'
          : formatPrice(item?.price)}
      </Text>
    ),
  },
  {
    key: 'time_required',
    label: 'LENGTH',
    render: (item) => <Text>{item?.time_required?.substring(0, 5)}</Text>,
  },
]

const AppointmentSelectService = ({ goBack, clickEmpt }) => {
  const {
    setServiceArray,
    serviceArray,
    setDate,
    setServiceList,
    serviceList,
    barber,
    setTime,
    client,
    setSignature,
    signature,
    signatureId,
    setSignatureId,
    scheduleType,
    packageService,
    setPackageService,
    setPackageSell,
    packageSell,
    appointmentType,
    setAppointmentType,
  } = useAppointmentStore()
  const [enabled, setEnabled] = useState(false)
  const [tabIndex, setTabIndex] = useState(false)
  const { userData } = useBarbershopStore()
  const { getServicesByBarber, getServices } = useBarbershop()
  const { getSubscriptionDetails } = useBarbershopSubscription()

  const { isLoading, data } = useQuery(
    ['get-services', scheduleType, packageService],
    () =>
      barber?.id ? getServicesByBarber({ id: userData?.id, barber_id: barber?.id }) : getServices({ id: userData?.id }),
    {
      onSuccess: (data) => {
        if (scheduleType === 'package') {
          setServiceList(data?.filter((i) => i?.type === 'individual_service_package'))
        } else {
          setServiceList(
            data?.filter((i) => (packageService ? i?.type === 'normal' : i?.active && i?.type === 'normal'))
          )
        }
      },
    }
  )

  const handleSelect = (item, type) => {
    setAppointmentType(type)
    if (type === 'signature') {
      if (serviceArray.includes(item)) {
        const updateItems = serviceArray.filter((i) => i !== item)
        setServiceArray(updateItems)
        setDate(null)
      } else {
        setServiceArray([...serviceArray, item])
      }
      return
    }
    if (item?.type !== 'normal') {
      setServiceArray([])
    }
    if (item?.type !== 'normal') {
      setServiceArray([item])
      setDate(null)
      return
    }
    if (serviceArray.includes(item)) {
      const updateItems = serviceArray.filter((i) => i !== item)
      setServiceArray(updateItems)
      setDate(null)
    } else {
      serviceArray?.find((i) => i.type !== 'normal')
        ? setServiceArray([item])
        : setServiceArray([...serviceArray, item])
    }
  }

  useEffect(() => {
    if (
      client?.client?.subscriptions?.length > 0 &&
      client?.client?.subscriptions?.some(t => t.type === 'individual_package_service') &&
      client?.client?.subscriptions?.some(b => b.barbershop_id === userData?.id) &&
      !packageSell
    ) {
      setEnabled(true)
      setPackageSell(false)
      return
    }
    if (userData?.signature_club_enable) {
      if (client?.client?.subscriptions?.length > 0 || client?.subscriptions?.length > 0) {
        setEnabled(true)
        return
      } else {
        setSignature(false)
        return
      }
    }
    setSignature(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, client])
  const onSuccess = (d) => {
    if (d?.packages) {
      setPackageService(d?.packages?.filter(a => a.barbershop_id === userData?.id))
    } else {
      setPackageService(null)
    }
    if (d?.signature?.success) {
      setSignature(d?.signature?.data)
      return
    }
    if (d?.success) {
      setSignature(Number(d?.data?.service_id))
      setSignatureId(Number(d?.data?.service_id))
      return
    } else {
      setSignature(false)
    }
  }

  const { isLoading: loadingSignature, data: signatureStatus } = useQuery(
    'get-subscriptions',
    () =>
      getSubscriptionDetails({
        barbershop_id: userData?.id,
        client_id: client?.client?.id || client?.id,
        // onSuccess,
        onError: () => setSignature(false),
      }),
    {
      onSuccess,
      enabled,
    }
  )
  const component = (type) => {
    if (type === 'package_service') {
      return (
        <Flex flexDir="column">
          <Box mb="8px">
            {packageService &&
              packageService?.map((item) => (
                <Text mt="8px" color={'primary'}>
                  {`Quantidade restante: ${item?.quantity}`}
                </Text>
              ))}
          </Box>

          <Table
            withoutHeader
            data={data?.filter((item) => packageService.some((obj) => obj.service === item.service_id)) || []}
            loading={isLoading || loadingSignature}
            columns={columns}
            isSelectableRow
            onSelect={(sel) => handleSelect(sel, 'package')}
            selectedList={serviceArray}
            isMultiSelectable
          />
        </Flex>
      )
    }

    if (type === 'signature') {
      return (
        <Flex flexDir="column">
          <Table
            withoutHeader
            data={signature?.plan?.services_plans || []}
            loading={isLoading || loadingSignature}
            columns={columnsSignature(signatureStatus?.signature?.data?.recurrent_quantity)}
            isSelectableRow
            // onSelect={(sel) => signatureStatus?.signature?.schedule?.status ? handleSelect(sel, 'signature') : null}
            onSelect={(sel) => handleSelect(sel, 'signature')}
            selectedList={serviceArray}
            isMultiSelectable
          />
          {signatureStatus?.signature?.schedule && !signatureStatus?.signature?.schedule?.status && (
            <>
              <Text mt="8px" color={'primary'}>
                Não é possível agendar pelo clube
              </Text>
              <Text mt="8px" color={'primary'}>
                {`${!signatureStatus?.signature?.schedule?.status && 'Cliente possui um horário marcado no dia '}${
                  !signatureStatus?.signature?.schedule?.status &&
                  format(parseISO(signatureStatus?.signature?.schedule?.date), 'dd/MM/yyyy')
                }`}
              </Text>
            </>
          )}
        </Flex>
      )
    }
    return (
      <Flex>
        <Table
          withoutHeader
          data={serviceList?.filter((i) => i?.active) || []}
          loading={isLoading || loadingSignature}
          columns={columns}
          isSelectableRow
          onSelect={handleSelect}
          selectedList={serviceArray}
          isMultiSelectable
        />
      </Flex>
    )
  }

  if (((userData?.signature_club_enable && signature) || packageService) && !packageSell) {
    return (
      <Tabs
        onChange={(index) => {
          setTabIndex(index)
          setAppointmentType('normal')
          setServiceArray([])
        }}
      >
        <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={1}>
          <Tab
            fontSize={14}
            fontWeight={500}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            {t('normal')}
          </Tab>
          {packageService && packageService?.length > 0 && (
            <Tab
              fontSize={14}
              fontWeight={500}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              {'Pacote de serviço'}
            </Tab>
          )}
          {signature && userData?.signature_club_enable && (
            <Tab
              fontSize={14}
              fontWeight={500}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              {t('signature')}
            </Tab>
          )}
        </TabList>

        <TabPanels p={0} marginTop="12px">
          <TabPanel p={0}>{component('normal')}</TabPanel>
          {packageService && packageService?.length > 0 && <TabPanel p={0}>{component('package_service')}</TabPanel>}
          {signature && userData?.signature_club_enable && <TabPanel p={0}>{component('signature')}</TabPanel>}
        </TabPanels>
      </Tabs>
    )
  } else {
    return (
      <Flex>
        <Table
          withoutHeader
          data={
            packageService ? serviceList?.filter((i) => i?.type === 'individual_service_package') : serviceList || []
          }
          loading={isLoading || loadingSignature}
          columns={columns}
          isSelectableRow
          onSelect={handleSelect}
          selectedList={serviceArray}
          isMultiSelectable
        />
      </Flex>
    )
  }
}

export default AppointmentSelectService
