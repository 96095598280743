import React from 'react'
import { Text, Icon, TextInput, Button } from 'components/atoms'
import { FieldControl } from 'components/molecules'
import { FieldArray, useFormikContext } from 'formik'

import { VStack, Select, HStack, Divider, IconButton } from '@chakra-ui/react'

const itemVoid = {
  forma_pagamento: '',
  valor_pagamento: '',
  tipo_integracao: '',
  cnpj_credenciadora: '',
  numero_autorizacao: '',
  bandeira_operadora: '',
}

const IssuerInvoicePaymentCardField = ({ formik, item, index, arrayHelpers, isLoading, isDisabled, ...props }) => (
  <VStack
    spacing={4}
    align="normal"
    bg="cardBackground"
    borderWidth={1}
    borderColor="modalBorder"
    borderRadius={6}
    p={4}
    key={index}
    {...props}
  >
    <HStack spacing={4}>
      <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
        # {item.numero_item}
      </Text>
      <Divider borderColor="gray.600" />
      {!isDisabled && (
        <HStack spacing={2}>
          <IconButton
            icon={<Icon name="Plus" size={18} color="white" />}
            size="sm"
            background="gray.900"
            opacity={0.7}
            _hover={{ opacity: 1 }}
            onClick={() =>
              arrayHelpers.insert(index, {
                ...itemVoid,
                numero_item: (+item.numero_item || 0) + 1,
              })
            }
          />

          {formik.values.formas_pagamento?.length > 1 && (
            <IconButton
              icon={<Icon name="Trash" size={18} color="danger" />}
              size="sm"
              background="gray.900"
              opacity={0.7}
              _hover={{ opacity: 1 }}
              onClick={() => arrayHelpers.remove(index)}
            />
          )}
        </HStack>
      )}
    </HStack>

    <HStack align="start">
      <FieldControl
        label="Forma pagamento"
        error={formik.errors.formas_pagamento?.[index]?.forma_pagamento}
        isLoading={isLoading}
        isDisabled={isDisabled}
        render={() => (
          <Select
            name={`formas_pagamento.${index}.forma_pagamento`}
            size="lg"
            bg="backgroundLayout"
            borderColor="gray.800"
            h="52px"
            value={item.forma_pagamento}
            onChange={formik.handleChange}
          >
            <option value="">Selecione</option>
            <option value="01">Dinheiro</option>
            <option value="17">Pix</option>
            <option value="02">Cheque</option>
            <option value="03">Cartão de Crédito</option>
            <option value="04">Cartão de Débito</option>
            <option value="05">Crédito Loja</option>
            <option value="10">Vale Alimentação</option>
            <option value="11">Vale Refeição</option>
            <option value="12">Vale Presente</option>
            <option value="13">Vale Combustível</option>
            <option value="18">Outros</option>
          </Select>
        )}
      />
      <FieldControl
        label="Valor pagamento"
        error={formik.errors.formas_pagamento?.[index]?.valor_pagamento}
        isLoading={isLoading}
        isDisabled={isDisabled}
        render={({ error }) => (
          <TextInput
            name={`formas_pagamento.${index}.valor_pagamento`}
            mask="CURRENCY_MASK"
            error={!!error}
            value={item.valor_pagamento}
            onChange={formik.handleChange}
          />
        )}
      />
    </HStack>

    <HStack align="start">
      {['03', '04'].includes(item.forma_pagamento) && (
        <FieldControl
          label="Bandeira da operadora"
          error={formik.errors.formas_pagamento?.[index]?.bandeira_operadora}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <Select
              name={`formas_pagamento.${index}.bandeira_operadora`}
              size="lg"
              bg="backgroundLayout"
              borderColor="gray.800"
              h="52px"
              value={item.bandeira_operadora}
              onChange={formik.handleChange}
            >
              <option value="">Selecione</option>
              <option value="01">Visa</option>
              <option value="02">Mastercard</option>
              <option value="03">American Express</option>
              <option value="04">Sorocred</option>
              <option value="99">Outros</option>
            </Select>
          )}
        />
      )}

      {/* <FieldControl
        label="Tipo integração"
        error={formik.errors.formas_pagamento?.[index]?.tipo_integracao}
        isLoading={isLoading}
        isDisabled={isDisabled}
        render={() => (
          <Select
            name={`formas_pagamento.${index}.tipo_integracao`}
            size="lg"
            bg="backgroundLayout"
            borderColor="gray.800"
            h="52px"
            value={item.tipo_integracao}
            onChange={formik.handleChange}
          >
            <option value="">Selecione</option>
            <option value="1">
              Pagamento integrado com o sistema de automação da empresa
            </option>
            <option value="2">
              Pagamento não integrado com o sistema de automação da empresa
            </option>
          </Select>
        )}
      /> */}
    </HStack>

    <HStack align="start">
      {item.tipo_integracao === '1' && (
        <FieldControl
          label="Número da autorização"
          error={formik.errors.formas_pagamento?.[index]?.numero_autorizacao}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`formas_pagamento.${index}.numero_autorizacao`}
              error={!!error}
              value={item.numero_autorizacao}
              onChange={formik.handleChange}
            />
          )}
        />
      )}

      {item.tipo_integracao === '1' && (
        <FieldControl
          label="CNPJ da credenciadora"
          error={formik.errors.formas_pagamento?.[index]?.cnpj_credenciadora}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`formas_pagamento.${index}.cnpj_credenciadora`}
              mask="CNPJ_MASK"
              error={!!error}
              value={item.cnpj_credenciadora}
              onChange={formik.handleChange}
            />
          )}
        />
      )}
    </HStack>
  </VStack>
)

export const IssuerInvoicePaymentForm = ({ isLoading, isDisabled, ...props }) => {
  const formik = useFormikContext()

  return (
    <VStack spacing={4} align="normal" {...props}>
      <HStack spacing={4}>
        <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
          Pagamento
        </Text>
        <Divider borderColor="gray.600" />
      </HStack>

      <FieldArray
        name="formas_pagamento"
        render={(arrayHelpers) => {
          if (!formik.values.formas_pagamento?.length) {
            return (
              <Button w="full" onClick={() => arrayHelpers.push(itemVoid)}>
                Adicionar
              </Button>
            )
          }

          return (
            <>
              {formik.values.formas_pagamento?.map?.((item, index) => (
                <IssuerInvoicePaymentCardField
                  formik={formik}
                  item={item}
                  index={index}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  arrayHelpers={arrayHelpers}
                />
              ))}
            </>
          )
        }}
      />
    </VStack>
  )
}
