import React from 'react'
import { Text, Icon } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { useAppointmentStore } from 'services/stores'
import { t } from 'services/translation'

export const SourceCard = ({
  source,
  outline,
  fullWidth,
  inList,
  onClick,
  detail,
}) => {
  const theme = useTheme()
  const { sourcesList } = useAppointmentStore()

  const name = (source) => {
    let value
    if (source === 'NOT_INFORMED') {
      return t('NOT_INFORMED')
    }
    if (typeof source !== 'object') {
      value = sourcesList.filter((item) => item.value === source)
      return value[0]?.name
    } else {
      value = source?.name
      return value
    }
  }

  return (
    <Box
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent="flex-start"
      px={theme.pxToRem(16)}
      mb={inList && theme.pxToRem(16)}
      w="100%"
      h={theme.pxToRem(56)}
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Flex
        w="100%"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex w="100%" alignItems="center" justifyContent="flex-start">
          <Text
            kind="bold"
            color="textLight"
            fontSize={theme.pxToRem(14)}
            ml={theme.pxToRem(16)}
          >
            {name(source)}
          </Text>
        </Flex>

        {onClick && (
          <Icon
            cursor="pointer"
            onClick={onClick}
            name="Trash"
            color="baseRed"
            size={16}
          />
        )}
      </Flex>
    </Box>
  )
}
