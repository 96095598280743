import React from 'react'
import { Text, Button } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

const handleStatus = (status) => {
  if (status === 'pending') {
    return {
      label: 'PENDING',
      color: 'white',
    }
  }

  if (status === 'paid') {
    return {
      label: 'PAID',
      color: 'success',
    }
  }

  if (status === 'canceled') {
    return {
      label: 'CANCELED',
      color: 'textMedium',
    }
  }

  if (status === 'partially_paid') {
    return {
      label: 'PARTIALLY_PAID',
      color: 'textMedium',
    }
  }

  if (status === 'refunded') {
    return {
      label: 'REFUNDED',
      color: 'textMedium',
    }
  }

  if (status === 'expired') {
    return {
      label: 'EXPIRED',
      color: 'dangerDark',
    }
  }

  if (status === 'authorized') {
    return {
      label: 'AUTHORIZED',
      color: 'textMedium',
    }
  }

  if (status === 'in_protest') {
    return {
      label: 'IN_PROTEST',
      color: 'textMedium',
    }
  }

  if (status === 'chargeback') {
    return {
      label: 'REFUNDED',
      color: 'textMedium',
    }
  }

  return {
    label: 'NO_STATUS',
    color: 'textMedium',
  }
}

export const PlanInvoiceCard = ({ item }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isMobile } = useBreakpoint()

  const onClick = () => {
    window.open(item.secure_url, isMobile ? '_self' : '_blank')
  }
  return (
    <Box
      display="flex"
      flexDir="row"
      w={isMobile ? '90vw' : theme.pxToRem(560)}
      h={theme.pxToRem(56)}
      borderRadius={theme.pxToRem(10)}
      backgroundColor="cardBackground"
      alignItems="center"
      justifyContent="space-between"
      px={theme.pxToRem(12)}
      mt={theme.pxToRem(24)}
    >
      <Flex
        w="104%"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Text w="20%" fontSize={theme.pxToRem(14)} mr={theme.pxToRem(24)}>
          {format(parseISO(item.due_date), 'dd/MM/yyyy')}
        </Text>
        <Text
          w="20%"
          fontSize={theme.pxToRem(14)}
          color={handleStatus(item.status).color}
          mr={theme.pxToRem(24)}
        >
          {t(handleStatus(item.status).label)}
        </Text>
        <Text w="20%" fontSize={theme.pxToRem(14)} mr={theme.pxToRem(24)}>
          {item.total}
        </Text>
        <Button onClick={onClick} kind="dashed" size="x-small">
          {' '}
          {t('VIEW')}
        </Button>
      </Flex>
    </Box>
  )
}
