import React, { useState } from 'react'
import { useBreakpoint } from 'services/hooks'
import {
  Flex,
  Form,
  Box,
  Image,
  Text,
  Button,
  Link,
  Icon,
  TextInput,
} from 'components'

import { Plans } from '../../components/plans'
import {
  initialCard,
  validationCard,
  inputsCard,
} from '../../components/settings'
import { theme } from 'styles'
import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { useTranslation } from 'react-i18next'
import andre from '../../images/section-garantia-bg.png'
import andreM from '../../images/section-garantia-mobile.png'
import { useMutation } from 'react-query'
import { api } from 'services/api/config'
import { LP_CREDIT_CARD } from 'services/api/endpoints'
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useBarbershopStore } from 'services/stores'
import lucasCohen from '../../images/lucas-cohen.png'

const FormHeader = ({ values }) => {
  const { isDesktop } = useBreakpoint()
  return (
    <Flex flexDir="column" w="100%" align="center" textAlign="center">
      <Image
        src={bestBarbersLogo}
        w={theme.pxToRem(140)}
        h="auto"
        mb={theme.pxToRem(12)}
      />
      <Text
        kind="extraBold"
        fontSize={20}
        mb={theme.pxToRem(12)}
        mr={theme.pxToRem(6)}
        w="70%"
      >
        15 dias na versão Premium por nossa conta!
      </Text>

      {/* credit card */}
      <Flex
        mt={'3%'}
        mb={'3%'}
        w={!isDesktop ? '80%' : '63%'}
        h={'22%'}
        bg="backgroundLayout"
        borderRadius={theme.pxToRem(16)}
        p={theme.pxToRem(!isDesktop ? 16 : 24)}
        flexDir="column"
        align="flex-start"
      >
        <Flex mb={'5%'} w="full" justify="space-between">
          <Icon name="Chip" />
          <Icon name="MasterCard" />
        </Flex>
        <Text
          color="textGrey"
          mt={'6%'}
          mb={'10%'}
          fontSize={theme.pxToRem(!isDesktop ? 12 : 14)}
        >
          {values?.params?.name || ''}
        </Text>
        <Flex justify="space-between" align="flex-end" w="full">
          <Text color="textGrey" fontSize={theme.pxToRem(!isDesktop ? 12 : 14)}>
            {values?.params?.number || 'XXX.XXX.XXX-XX'}
          </Text>
          <Text color="textGrey" fontSize={theme.pxToRem(12)}>
            {values?.params?.expire || 'MM/YYYY'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const FormContainer = () => {
  const { isDesktop } = useBreakpoint()
  const { user } = useBarbershopStore()
  const history = useHistory()
  const toast = useToast()
  const { t } = useTranslation()
  const [values, getValues] = useState({
    params: initialCard,
    isValid: false,
  })

  async function completeData() {
    const res = await api
      .post(LP_CREDIT_CARD(), { ...values.params, user_id: user.id })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutation = useMutation('lp-complete-data', () => completeData(), {
    onSuccess: (data) => {
      toast({
        title: t('Dados cadastrados com sucesso!'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      history.push('/login')
    },
    onError: (error) => {
      toast({
        title: t('Falha ao cadastrar dados. Por favor, tente novamente'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <Flex maxH={theme.pxToRem(950)} h={isDesktop ? "110vh" : "95vh"}  align="center" justify="center">
      <Box
        borderRadius={theme.pxToRem(12)}
        py="3vh"
        px={!isDesktop ? '5vw' : '2vw'}
        w={!isDesktop ? '92vw' : '35vw'}
        bg="menuBackground"
      >
        <FormHeader values={values} />

        <Form
          getValues={getValues}
          initialValues={initialCard}
          validationSchema={validationCard}
          data={inputsCard(values?.params?.identifier)}
        />
        {/* form last line */}
        <Flex justify="space-between" mb={theme.pxToRem(32)}>
          <TextInput
            noBorder
            w="28%"
            mr="3%"
            name="cvv"
            placeHolder="XXX"
            maxLength={3}
            value={values?.params?.cvv}
            onChange={(e) =>
              getValues({
                ...values,
                params: { ...values?.params, cvv: e.target.value },
              })
            }
            label={t('CVV')}
            padding={theme.pxToRem(10)}
            h={theme.pxToRem(56)}
          />
          <TextInput
            w="69%"
            noBorder
            name="expire_date"
            mask="EXPIRE_DATE_MASK"
            value={values?.params?.expire}
            onChange={(e) =>
              getValues({
                ...values,
                params: { ...values?.params, expire: e.target.value },
              })
            }
            placeHolder="XX/XXXX"
            label={t('EXPIRE_AT')}
            padding={theme.pxToRem(10)}
            h={theme.pxToRem(56)}
          />
        </Flex>
        <Button
          onClick={mutation.mutate}
          loading={mutation.isLoading}
          kind="primary"
          w="100%"
          mt={theme.pxToRem(16)}
        >
          Cadastrar
        </Button>
        <Text
          align={'center'}
          fontSize={theme.pxToRem(10)}
          mt={theme.pxToRem(10)}
        >
          {t('TERMS_AND_POLICYS_1')} os
          <Link
            isExternal
            fontSize={theme.pxToRem(10)}
            href="https://www.bestbarbers.app/termos-de-uso"
          >
            {` ${t('TERMS')} `}
          </Link>
          {t('AND')}
          <Link
            fontSize={!theme.pxToRem(10)}
            isExternal
            href="https://www.bestbarbers.app/politica-de-privacidade"
          >
            {t('PRIVACY')}
          </Link>
        </Text>
      </Box>
    </Flex>
  )
}

export const CreditCardPartners = () => {
  const { isDesktop } = useBreakpoint()

  if (!isDesktop) {
    return (
      <Flex w={'100vw'} flexDir="column" align="center" pt="4vh">
        <FormContainer />
        <Flex
          w={'100vw'}
          px="5vw"
          flexDir="column"
          bgColor="backgroundLayout"
          overflow="hidden"
        >
          <Flex w="100%" flexDir="column" align="flex-start">
            <Text lineHeight={9} color="white" kind="extraBold" fontSize={32}>
              Cadastre seu cartão e ganhe
            </Text>
            <Text lineHeight={9} color="primary" kind="extraBold" fontSize={32}>
              15 dias na versão Premium
            </Text>
            <Text
              w="90%"
              color="textLight"
              fontSize={12}
              mt={theme.pxToRem(12)}
            >
              Temos certeza de que o BestBarbers será um grande avanço para a
              sua barbearia e que você terá ótimos resultados. Cadastrando seu
              cartão agora, você irá ganhar 15 dias na versão Premium gratuitos!
              E fique tranquilo, não cobraremos nada até lá.
            </Text>
            <Image
              src={lucasCohen}
              maxH={theme.pxToRem(650)}
              w="auto"
            />
          </Flex>
        </Flex>
        <Plans />
      </Flex>
    )
  }

  return (
    <Flex w={'100vw'} flexDir="column" align="flex-start">
      <Flex
        maxH={theme.pxToRem(950)}
        h="110vh"
        w={'100vw'}
        align="flex-end"
        justify="center"
      >
        <Flex
          h="110vh"
          maxH={theme.pxToRem(950)}
          w={'90vw'}
          flexDir="row"
          align="flex-end"
          justify="center"
          px="5vw"
        >
          <FormContainer />
          <Flex
            ml="5vw"
            w="40vw"
            h="100vh"
            justify="flex-end"
            flexDir="column"
            align="center"
          >
            <Text w="100%" color="textLight" kind="extraBold" fontSize={36}>
              Cadastre seu cartão e ganhe
            </Text>
            <Text
              mt={theme.pxToRem(-12)}
              w="100%"
              color="primary"
              kind="extraBold"
              fontSize={36}
            >
              15 dias na versão Premium
            </Text>
            <Text w="100%" color="textLight" fontSize={13}>
              Temos certeza de que o BestBarbers será um grande avanço para a
              sua barbearia e que você terá ótimos resultados. Cadastrando seu
              cartão agora, você irá ganhar 15 dias na versão Premium gratuitos!
              E fique tranquilo, não cobraremos nada até lá.
            </Text>
            <Image
              src={lucasCohen}
              h="80vh"
              maxH={theme.pxToRem(650)}
              w="auto"
            />
          </Flex>
        </Flex>
      </Flex>
      <Plans />
    </Flex>
  )
}

CreditCardPartners.path = '/lp/partners/bonus/lucas-cohen'
CreditCardPartners.title = 'LandingPage Bonus'
CreditCardPartners.secured = false
