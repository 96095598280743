import { t } from 'services/translation'
import * as Yup from 'yup'
import { Text } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { TIME_REGEX } from 'utils/services'

export const data = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    noBorder: true,
    label: 'Nome do Serviço',
    placeholder: t('SERVICE_NAME_PLACEHOLDER'),
  },
  {
    id: 'time_required',
    name: 'time_required',
    type: 'text',
    noBorder: true,
    label: 'Tempo (horas : minutos)',
    placeholder: t('Tempo do serviço (horas : minutos)'),
    mask: 'HOUR_MASK',
  },
  {
    id: 'price',
    name: 'price',
    type: 'text',
    noBorder: true,
    label: 'SALE_PRICE',
    placeholder: '0,00',
    mask: 'CURRENCY_MASK',
    leftElement: (
      <Text color="textLight" kind="medium" mt={pxToRem(2)} width={pxToRem(40)}>
        {t('CURRENCY')}
      </Text>
    ),
  },
  {
    id: 'percentage',
    name: 'percentage',
    mask: 'PERCENTAGE_MASK',
    noBorder: true,
    label: 'Comissão padrão dos barbeiros',
    placeholder: '30%',
  },
]

export const validation = Yup.object().shape({
  name: Yup.string().min(4, t('SERVICE')).required(t('SERVICE_REQUIRED')),
  time_required: Yup.string()
    .required(t('TIME_REQUIRED'))
    .matches(TIME_REGEX, t('INVALID_TIME')),
  price: Yup.string().required(t('PRICE_REQUIRED')),
  percentage: Yup.string(t('Informe a comissão')).required(
    t('Comissão é obrigatória')
  ),
})

export const initial = {
  name: 'Corte de cabelo',
  time_required: '',
  price: '',
  percentage: '',
}
