import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Flex, Box, Text, Button } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { Form, GooglePlacesInput } from 'components/molecules'
import { dataLocation, validationLocation, initialLocation } from './settings'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { ADDRESS_BARBERSHOP } from 'services/api/endpoints'
import { getStyledSelectBox, getStyledOption } from 'utils/address'
import { useBarbershopStore } from 'services/stores'

export const RefreshAddressModal = ({ isOpen, onClose }) => {
  const toast = useToast()
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const [values, getValues] = useState({
    params: initialLocation,
    isValid: false,
  })
  const { userData } = useBarbershopStore()

  async function getLocation() {
    const res = await api
      .get(ADDRESS_BARBERSHOP(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-address-barbershop', getLocation, {
    onSuccess: (value) => {
      if (value) {
        getValues({
          params: value,
          isValid: false,
        })
      }
    },
  })

  async function changeLocation(params) {
    const res = await api
      .put(ADDRESS_BARBERSHOP(userData?.barbershop?.id || userData?.id), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-location',
    (params) => changeLocation(params),
    {
      onSuccess: (data) => {
        toast({
          title: t('LOCATION_TOAST'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        onClose()
      },
      onError: (error) => {
        toast({
          title: t('LOCATION_TOAST_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
        pos={!isDesktop && 'absolute'}
        top={!isDesktop && 0}
        maxW={theme.pxToRem(744)}
        maxH={theme.pxToRem(!isDesktop ? 1100 : 1028)}
      >
        <ModalHeader
          textAlign="flex-start"
          mt={theme.pxToRem(20)}
          w={!isDesktop ? '100%' : theme.pxToRem(744)}
        >
          <Flex
            flexDir="column"
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text kind="extraBold" fontSize={24} mr={theme.pxToRem(6)}>
              {t('MODAL_REFRESH_DATA_TITLE')}
            </Text>
          </Flex>
          <Text w={'100%'} color="textGrey" fontSize={14}>
            {t('MODAL_REFRESH_DATA_SUBTITLE')}
          </Text>
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody w="100%">
          <Box w="100%">
            <GooglePlacesInput
              {...{ getStyledSelectBox, getStyledOption }}
              onSelectAddress={(address) =>
                getValues({
                  params: address,
                  isValid: values?.isValid,
                })
              }
              fontColor="textGrey"
              fontSize={14}
            />
            <Form
              mb={40}
              mt={theme.pxToRem(12)}
              getValues={getValues}
              initialValues={values?.params}
              validationSchema={validationLocation}
              data={dataLocation}
            />
          </Box>
        </ModalBody>

        <ModalFooter flexDir="column">
          <Button
            isLoading={mutate.isLoading}
            kind="primary"
            w="100%"
            onClick={() => {
              const params = {
                ...values?.params,
              }
              mutate.mutate(params)
            }}
            mb={theme.pxToRem(24)}
          >
            {t('SAVE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
