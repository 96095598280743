import React, { useState } from 'react'
import { Table, Text, Avatar, Link, TextInput, Button } from 'components'
import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import './hideScrollbar.css'
import { formatPhone } from 'utils/phone-formater'
import { useBarbershop, usePermissionCollaborator } from 'services/hooks'
import { Search } from 'assets/icons'
import { colors } from 'styles'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { useQuery } from 'react-query'

export const Clients = ({ setCustomerDetailModal, setSelectedCustomer, userData }) => {
  const toast = useToast()
  const { t } = useTranslation()
  const permission = usePermissionCollaborator('notifications')
  const clients = usePermissionCollaborator('clients')
  const [query, setQuery] = useState('')
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const { getClientsFrequencySignature } = useBarbershop()
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const [filtered, setFiltered] = useState([])

  const columns = [
    {
      key: 'client',
      label: 'CLIENT',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Avatar size="medium" src={item.client.profile_image_url} mr="5%" />
            <Text>{item.client.name}</Text>
          </Flex>
        )
      },
    },
    {
      key: 'name',
      label: 'NOME',
      render: (item) => {
        if (permission === 'edit') {
          return (
            <Link href={`https://api.whatsapp.com/send?1=pt_BR&phone=${item['client.user.phone']}`} target="_blank">
              {formatPhone(item.client.user.phone, userData)}
            </Link>
          )
        } else {
          return <Text>{formatPhone(item['client.user.phone'], userData)}</Text>
        }
      },
    },
    {
      key: 'count',
      label: 'QUANTIDADE DE ATENDIMENTOS',
      render: (item) => <Text>{item?.count}</Text>,
    },
  ]

  const { refetch, isLoading, isRefetching } = useQuery(
    ['get-clients-resume', userData],
    () => getClientsFrequencySignature({ id: userData?.id, startDate, endDate }),
    {
      onSuccess: (d) => setFiltered({ data: d?.appointments_normal, filtered: d?.appointments_normal }),
      refetchOnWindowFocus: false,
    }
  )

  const handleSelectCustomer = (customer) => {
    if (clients !== 'blocked') {
      setSelectedCustomer(customer)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setFiltered({ ...filtered, filtered: filtered?.data })
    } else {
      const search_result = filtered?.data.filter(
        (item) =>
          String(item?.client?.name.toLowerCase()).includes(query.toLowerCase()) ||
          String(item?.client?.user?.email?.toLowerCase()).includes(query.toLowerCase())
      )
      setFiltered({ ...filtered, filtered: search_result })
    }
  }

  return (
    <Flex w="100%" flexDir="column" mt="24px" justify="center" align="center">
      <Flex w="40%" mr="20px" mb="24px">
        <TextInput
          noBorder
          type="date"
          name="start_date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          label={t('START')}
          padding="4%"
          width={'90%'}
        />
        <TextInput
          noBorder
          type="date"
          name="end_date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          label={t('END')}
          padding="5%"
          width={'90%'}
        />
        <Button
          isLoading={isRefetching || isLoading}
          icon="Search"
          size="small"
          iconColor="black"
          mt="26px"
          onClick={() => {
            refetch()
          }}
        >
          Pesquisar
        </Button>
      </Flex>
      <TextInput
        noBorder
        type="text"
        width="full"
        color="white"
        placeholder={t('SEARCH_SUBSCRIBERS')}
        value={query}
        mb="16px"
        onChange={(event) => onSearch(event.target.value)}
        leftElement={<Search color={colors.primary} />}
      />
      <Flex w="100%">
        <Table
          loading={isLoading}
          data={filtered?.filtered?.sort?.((a, b) => b.count - a.count) || []}
          columns={columns}
          isSelectableRow
          onSelect={(item) => handleSelectCustomer(item)}
        />
      </Flex>
    </Flex>
  )
}
