import React from 'react'
import ReactCalendar from 'react-calendar'
import './styles.css'
import { useTranslation } from 'react-i18next'

export const Calendar = ({
  onlyMonth,
  onSelectDate,
  type,
  minDate,
  maxDate,
  selectedDate,
  showNavigation,
  defaultActiveStartDate,
  tileDisabled,
}) => {
  const { t } = useTranslation()
  return (
    <ReactCalendar
      locale={t('LOCALE')}
      calendarType={type || 'ISO 8601'}
      onClickMonth={onSelectDate}
      onChange={onSelectDate}
      minDate={minDate}
      maxDate={maxDate}
      value={selectedDate}
      defaultValue={selectedDate}
      className="bestbarbers-calendar"
      view={onlyMonth ? 'year' : 'month'}
      showNavigation={showNavigation}
      showFixedNumberOfWeeks={true}
      defaultActiveStartDate={defaultActiveStartDate}
      tileDisabled={tileDisabled}
    />
  )
}
