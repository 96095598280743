import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useTheme,
  Box,
  Flex,
  Textarea as NativeTextarea,
} from '@chakra-ui/react'
import { Text } from '../Text'
import { useBreakpoint } from 'services/hooks'
import { t } from 'services/translation'

export const Textarea = React.forwardRef(
  ({ label, placeholder, error, onChange, value, ...props }, ref) => {
    const [count, countSet] = useState('')
    const theme = useTheme()
    const { isDesktop } = useBreakpoint()

    const handleChange = (event) => {
      countSet(event.target.value.length)
      onChange(event)
    }

    return (
      <Box w="100%">
        <Flex justifyContent="space-between">
          {label && (
            <Text kind="medium" fontSize={theme.pxToRem(12)}>
              {t(label)}
            </Text>
          )}
          <Text
            kind="medium"
            fontSize={theme.pxToRem(12)}
            color="textGrey"
          >{`${String(count || 0)}/180`}</Text>
        </Flex>

        <NativeTextarea
          placeholder={placeholder}
          _placeholder={{ fontSize: 14, color: 'textGrey' }}
          onChange={handleChange}
          borderWidth={theme.pxToRem(1)}
          borderColor={error ? 'danger' : 'modalBorder'}
          focusBorderColor="primary"
          bg="cardBackground"
          mt="2"
          resize="none"
          color="textLight"
          minH={isDesktop ? theme.pxToRem(116) : theme.pxToRem(172)}
          isFullWidth
          value={value}
          ref={ref}
          {...props}
        />
      </Box>
    )
  }
)

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
