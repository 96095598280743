import { t } from 'services/translation'

export const options = [
  {
    id: 0,
    name: t('BLOCKED'),
    value: 'blocked',
  },
  {
    id: 1,
    name: t('ONLY_READ'),
    value: 'only_read',
  },
  {
    id: 2,
    name: t('EDIT'),
    value: 'edit',
  },
]

export const booelanOptions = [
  {
    id: 0,
    name: 'Bloqueado',
    value: 'blocked',
  },
  {
    id: 1,
    name: 'Permitido',
    value: 'edit',
  },
  
] 
