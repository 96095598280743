import React from 'react'
import { Text, Button, Box } from 'components/atoms'
import { ModalMenu, Table } from 'components/molecules'
import { useTheme, useToast } from '@chakra-ui/react'

import { useQuery } from 'react-query'
import { t } from 'services/translation'
import { useServices } from 'services/hooks/use-services'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'time_required',
    label: 'TIME_REQUIRED',
    render: (item) => <Text>{item.time_required}</Text>,
  },
]

export const SelectServiceMethod = ({ isOpen, onClose, services, setServices, ...props }) => {
  const toast = useToast()
  const {getServices} = useServices()
  const theme = useTheme()

  const { data, isLoading } = useQuery(
    ['get-services-signature'],
    getServices,
    {
      onError: (error) => {
        toast({
          title: t('LOAD_SERVICES_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleSelectService = (pm) => {
      if (services?.includes(pm)) {
        const updateItems = services?.filter((i) => i !== pm)
        setServices(updateItems)
      } else {
        setServices([...services, pm])
      }
  }

  return (
    <ModalMenu 
    isOpen={isOpen}
      onClose={
        onClose
      }
      title={t('SELECT_SERVICE')}>
      <Box>
        <Table
          withoutHeader
          isMultiSelectable
          isSelectableRow
          data={data?.filter((i) => i?.type === 'signature') || []}
          loading={isLoading}
          columns={columns}
          onSelect={handleSelectService}
          selectedList={services}
        />
        <Button size="block" mt={theme.pxToRem(24)} onClick={onClose}>
          {t('CONFIRM')}
        </Button>
      </Box>
    </ModalMenu>
  )
}
