import * as React from 'react'

function SvgTrash(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.875 4.257h13.5M5.937 7.671v5.125M9.313 7.671v5.125M1.72 4.257l.843 10.25c0 .453.178.888.494 1.208.317.32.746.5 1.193.5H11c.448 0 .877-.18 1.194-.5a1.72 1.72 0 00.494-1.208l.844-10.25M5.094 4.256V1.694a.86.86 0 01.247-.604.839.839 0 01.596-.25h3.375c.224 0 .439.09.597.25a.86.86 0 01.247.604v2.562"
        stroke="currentColor"
        strokeWidth={1.266}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgTrash
