import React from 'react'
import { Flex, useTheme, Box } from '@chakra-ui/react'
import { Text } from 'components/atoms'
import {
  ModalMenu,
  BarberCard,
  ClientCard,
  ServiceCard,
  BillRow,
} from 'components/molecules'
import { formatPrice } from 'utils'
import { useTranslation } from 'react-i18next'

export const AppointmentFinanceModal = ({ isModalOpen, onClose, selected }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={onClose}
      title={t('APPOINTMENT_FINANCE_MODAL_TITLE')}
    >
      <Box mb={theme.pxToRem(12)}>
        <Text
          marginBottom={theme.pxToRem(16)}
          fontSize={theme.pxToRem(12)}
          textTransform="uppercase"
        >
          {t('CLIENT_TABLE')}
        </Text>

        <ClientCard client={selected?.client} />
      </Box>

      <Box mt={theme.pxToRem(24)} mb={theme.pxToRem(12)}>
        <Text
          marginBottom={theme.pxToRem(16)}
          fontSize={theme.pxToRem(12)}
          textTransform="uppercase"
        >
          {t('BARBER_TABLE')}
        </Text>

        <BarberCard barber={selected?.barber} />
      </Box>

      <Box mt={theme.pxToRem(24)} mb={theme.pxToRem(12)}>
        <Text
          marginBottom={theme.pxToRem(16)}
          fontSize={theme.pxToRem(12)}
          textTransform="uppercase"
        >
          {t('SERVICE_TABLE')}
        </Text>

        <ServiceCard date={selected?.date} service={selected?.product} />
      </Box>

      <Box mt={theme.pxToRem(24)} mb={theme.pxToRem(12)}>
        <Text
          marginBottom={theme.pxToRem(16)}
          fontSize={theme.pxToRem(12)}
          textTransform="uppercase"
        >
          {t('ADDITIONAL_PRODUCTS')}
        </Text>

        {selected?.additional_products &&
          selected?.additional_products.map((item) => (
            <Flex
              key={`additiona_value_${item.name}`}
              h={theme.pxToRem(36)}
              w="94%"
              flexDir="row"
              alignItems="center"
              justifyContent="space-between"
              borderColor="textMedium"
              borderTopWidth={theme.pxToRem(0.2)}
              borderBottomWidth={theme.pxToRem(0.2)}
            >
              <Text fontSize={theme.pxToRem(14)} kind="medium">
                {item.name}
              </Text>
              <Text fontSize={theme.pxToRem(14)} kind="medium">
                {formatPrice(Number(item.value || item.price))}
              </Text>
            </Flex>
          ))}
      </Box>

      <Box mt={theme.pxToRem(24)} mb={theme.pxToRem(24)}>
        <Text
          marginBottom={theme.pxToRem(16)}
          fontSize={theme.pxToRem(12)}
          textTransform="uppercase"
        >
          {t('FINANCIAL_DESCRIPTIVE')}
        </Text>
      </Box>
      <BillRow
        key="total"
        description="Total"
        value={formatPrice(selected.total_value)}
      />

      <BillRow
        key="payment_method"
        description={t('PAYMENT_METHOD') + ` ${
          selected.payment_method ? ` - ${selected.payment_method.name}` : ''
        } (${selected.payment_percentage}%)`}
        value={`- ${formatPrice(selected.payment_percentage_value)}`}
      />

      <BillRow
        key="barber"
        description={t('COMMISSION') + ` (${selected.barber_percentage}%)`}
        value={`- ${formatPrice(selected.barber_percentage_value)}`}
      />

      <BillRow
        key="total_liquid"
        description={t('NET_TOTAL')}
        value={`${formatPrice(selected.total_after_discounts)}`}
      />
    </ModalMenu>
  )
}
