import { Box, Flex } from '@chakra-ui/react'
import { Avatar, Text } from 'components/atoms'
import { formatPrice } from 'utils'
import { t } from 'services/translation'
import { format, parseISO } from 'date-fns'

export const GOALS_COLUMNS = (signature) => {
  const arr = [
    {
      width: 220,
      key: 'barber',
      label: 'COLLABORATOR',
      render: (item) => {
        return (
          <Flex flexDir="row" align="center">
            <Avatar
              size="medium"
              src={
                item?.barber?.profile_image_url ||
                item?.employee?.profile_image_url ||
                item?.barbers?.profile_image_url ||
                item?.other_employees?.profile_image_url
              }
              mr="16px"
            />
            <Box>
              <Text>
                {item?.barber?.name || item?.employee?.name || item?.barbers?.name || item?.other_employees?.name}
              </Text>
              <Text fontSize={12} color="textMedium">
                {t(item?.employee?.type || item?.other_employees?.type) ||
                  item?.barber?.visible_name ||
                  item?.barbers?.visible_name}
              </Text>
            </Box>
          </Flex>
        )
      },
    },
    {
      width: 100,
      key: 'type',
      label: 'TIPO DA META',
      render: (item) => <Text kind="semiBold">{t(item?.type)}</Text>,
    },
    {
      width: 130,
      key: 'value',
      label: 'VALOR DA META',
      render: (item) => formatPrice(item?.value),
    },
    {
      width: 95,
      key: 'bonus_active',
      label: 'BÔNUS ATIVO',
      render: (item) => (
        <Text color={item?.bonus_active ? 'success' : 'danger'} kind="semiBold">
          {item?.bonus_active ? 'Sim' : 'Não'}
        </Text>
      ),
    },
    {
      width: 60,
      key: 'value',
      label: 'RECORRÊNCIA',
      render: (item) => <Text>Mensal</Text>,
    },
    {
      width: 95,
      key: 'value',
      label: 'VALIDA ATÉ',
      render: (item) => (
        <Text color={!item?.end_date ? 'textMedium' : 'textLight'}>
          {item?.end_date ? format(parseISO(item?.end_date), 'dd/MM/yyyy') : 'Não definido'}
        </Text>
      ),
    },
  ]

  if (signature) {
    arr.push({
      width: 95,
      key: 'signature_enabled',
      label: 'Considerar assinatura',
      render: (item) => (
        <Text color={item?.signature_enabled ? 'success' : 'danger'} kind="semiBold">
          {item?.signature_enabled ? 'Sim' : 'Não'}
        </Text>
      ),
    })
  }

  return arr
}

export const tableInputColumns = [
  {
    label: 'Serviço',
    key: 'name',
    type: 'text',
    w: '55%',
  },
  {
    label: 'Comissão ao bater meta',
    key: 'percentage',
    type: 'input',
  },
  {
    label: 'Ativo',
    key: 'inactive',
    type: 'switch',
    w: '20%',
  },
]

export const tableInputAll = [
  {
    label: 'Serviço',
    key: 'name',
    type: 'text',
    w: '60%',
  },
  {
    label: 'Comissão ao bater meta',
    key: 'percentage',
    type: 'input',
  },
]

export const types = [
  {
    name: 'Total em vendas - Sem assinatura',
    value: 'total_sales',
    signature_enabled: false,
  },
  {
    name: 'Total em vendas - Considerar assinatura',
    value: 'total_sales',
    signature_enabled: true,
  },
  {
    name: 'Serviços - Considerar assinatura',
    value: 'service',
    signature_enabled: true,
  },
  {
    name: 'Serviços - Sem assinatura',
    value: 'service',
    signature_enabled: false,
  },
  {
    name: 'Produtos',
    value: 'product',
    signature_enabled: false,
  },
]
