import React from 'react'

const Ticket = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66675 5.33301H5.33341V26.6663H2.66675V5.33301ZM8.00008 5.33301H10.6667V26.6663H8.00008V5.33301ZM12.0001 5.33301H16.0001V26.6663H12.0001V5.33301ZM17.3334 5.33301H20.0001V26.6663H17.3334V5.33301ZM21.3334 5.33301H24.0001V26.6663H21.3334V5.33301ZM25.3334 5.33301H29.3334V26.6663H25.3334V5.33301Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Ticket
