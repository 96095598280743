import React, { useState } from 'react'
import { Box, Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/react'
import { t } from 'services/translation'
import { Text, Button, TextInput } from 'components/atoms'
import { useTheme } from '@chakra-ui/react'
import { SelectCard } from 'components/molecules'
import { Flex } from 'components/atoms'
import { ExchangeRewards } from './ExchangeRewards'
import { PointsForClients } from './PointsForClients'
import { ExchangeHistoric } from './ExchangeHistoric'
import { useMutation } from 'react-query'
import { useBarbershopStore } from 'services/stores'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useToast } from '@chakra-ui/react'

export const BestCoins = () => {
  const { userData, setUserData } = useBarbershopStore()
  const [value, setValue] = useState(userData?.bestcoins_points_value)
  const { updateBestcoins } = useBestcoins()

  const theme = useTheme()
  const toast = useToast()

  const mutateUpdate = useMutation(
    (body) =>
      updateBestcoins({
        params: { barbershop_id: userData?.barbershop?.id || userData?.id },
        body,
      }),
    {
      onSuccess: (data) => {
        setUserData({
          ...userData,
          bestcoins_active: data?.bestcoins_active,
          bestcoins_points_value: data?.bestcoins_points_value,
        })
        toast({
          title: 'Bestcoins atualizado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao atualizar bestcoins',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <Box h="100vh" w="100%">
      <Flex pb={theme.pxToRem(12)} w="100%" justify="space-between">
        <Text kind="extraBold" fontSize={theme.pxToRem(24)}>
          BestCoins
        </Text>
        {/* <Button
          w={162}
          h={10}
          kind={'primary'}
          ml={theme.pxToRem(12)}
          onClick={() => {}}
        >
          Assista tutorial
        </Button> */}
      </Flex>

      <Text>
        BestCoins é o programa de pontuação do BestBarbers onde você decide
        quantos pontos seu cliente ganhará a cada real gasto na sua barbearia.
        Você também poderá definir os prêmios que seu cliente vai poder trocar
        pelos pontos, como produtos, descontos em serviços, entre outros.
      </Text>
      <Text mt={4} kind="bold" fontSize="18px" align="flex-start">
        Configurações
      </Text>

      <Flex alignItems="center" justifyContent="space-between">
        <SelectCard
          px="8px"
          item={{
            title: 'Habilitar o BestCoins na minha Barbearia',
          }}
          setSelected={() =>
            mutateUpdate.mutate({
              bestcoins_active: !userData?.bestcoins_active,
            })
          }
          selected={userData?.bestcoins_active}
          h="52px"
          mr="5"
          w="43%"
        />
        {userData?.bestcoins_active && (
          <Box mb={theme.pxToRem(40)}>
            <Text
              fontSize={14}
              kind="semiBold"
              mb={theme.pxToRem(26)}
              // mb={theme.pxToRem(16)}
            >
              Defina quantos pontos seu cliente ganhará a cada 1 real gasto na
              sua barbearia
            </Text>
            <Flex justify="space-between">
              <TextInput
                mb={theme.pxToRem(16)}
                w="76%"
                h="52px"
                placeholder="0,00"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <Button
                w="20%"
                h="52px"
                kind="primary"
                size="block"
                type="submit"
                onClick={() =>
                  mutateUpdate.mutate({
                    bestcoins_points_value: Number(value?.replace(',', '.')),
                  })
                }
              >
                Salvar
              </Button>
            </Flex>
          </Box>
        )}
      </Flex>
      {userData?.bestcoins_active && (
        <>
          <Tabs w="100%" onChange={(index) => {}}>
            <TabList
              color="textGrey"
              borderColor="modalBorder"
              borderBottomWidth={4}
            >
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Prêmios para troca
              </Tab>
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Pontos para clientes
              </Tab>
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Histórico de resgates
              </Tab>
            </TabList>

            <TabPanels p={0} marginTop="12px">
              <TabPanel p={0}>
                <ExchangeRewards />
              </TabPanel>
              <TabPanel p={0}>
                <PointsForClients />
              </TabPanel>
              <TabPanel p={0}>
                <ExchangeHistoric />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  )
}
