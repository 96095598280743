import * as Yup from 'yup'

export const data = [
  {
    id: 0,
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'Nome da barbearia',
    placeholder: 'Digite o nome da barbearia',
  },
  {
    id: 1,
    name: 'owner_name',
    type: 'string',
    noBorder: true,
    label: 'Nome do responsável',
    placeholder: 'Digite o nome do responsável pela barbearia',
  },
  {
    id: 4,
    name: 'cpf',
    type: 'cpf',
    noBorder: true,
    label: 'CPF',
    placeholder: 'CPF do responsável',
    mask: 'CPF_MASK',
  },
  {
    id: 2,
    name: 'email',
    type: 'string',
    noBorder: true,
    label: 'Email',
    placeholder: 'Digite seu e-mail',
  },
  {
    id: 3,
    name: 'phone',
    type: 'cel-phone',
    noBorder: true,
    label: 'Telefone de contato (whatsapp)',
    mask: 'PHONE_MASK',
    placeholder: 'Digite seu telefone para contato',
  },
]

export const validation = Yup.object().shape({
  name: Yup.string().min(4, 'NAME').required('Nome do serviço é obrigatório'),
  owner_name: Yup.string(),
  phone: Yup.string(),
  email: Yup.string(),
  password: Yup.string(),
})

export const initialValues = {
  name: '',
  owner_name: '',
  phone: '',
  email: '',
  password: '',
}

const onlyNumbers = (data) => {
  let numStr = data.replace(/[^0-9]/g, '')
  return numStr
}

export const initialCard = {
  identifier: '',
  number: '',
  name: '',
  expire: '',
  cvv: '',
}

export const validationCard = Yup.object().shape({
  identifier: Yup.string(),
  number: Yup.string().min(16).required('Número do cartão é obrigatório'),
  name: Yup.string().required('Nome do titular é obrigatório'),
})

export const inputsCard = (data) => [
  {
    id: 0,
    name: 'number',
    noBorder: true,
    label: 'NUMBER_CARD',
    placeholder: 'XXXX-XXXX-XXXX-XXXX',
    mask: 'CREDIT_MASK',
    mb: '8%',
  },
  {
    id: 1,
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'SERVICE_NAME',
    placeholder: 'NOME IGUAL AO ESCRITO NO CARTÃO',
    mb: '8%',
  },
  {
    id: 2,
    name: 'identifier',
    noBorder: true,
    label: 'CPF_OR_CNPJ',
    placeholder: 'XXX.XXX.XXX-XX',
    mask: onlyNumbers(data).length > 11 ? 'CNPJ_MASK' : 'CPF_MASK_',
    mb: '8%',
  },
]

export const benefits = [
  {
    id: 0,
    basic: false,
    premium: true,
    text: 'Aplicativo exclusivo personalizado',
  },
  {
    id: 1.1,
    basic: false,
    premium: true,
    text: 'Clube de Assinaturas',
  },
  {
    id: 1.2,
    basic: false,
    premium: true,
    text: 'Emissão de Notas Fiscais',
  },
  {
    id: 1,
    basic: false,
    premium: true,
    text: 'Clientes acessam somente a minha barbearia',
  },
  {
    id: 2,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_3_NEW',
  },
  {
    id: 3,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_4_NEW',
  },
  {
    id: 4,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_5_NEW',
  },
  {
    id: 5,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_6_NEW',
  },
  {
    id: 6,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_7_NEW',
  },
  {
    id: 7,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_8_NEW',
  },
  {
    id: 8,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_9_NEW',
  },
]
