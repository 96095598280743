import React from 'react'
import { Box, Flex, Select as SelectChakra } from '@chakra-ui/react'
import { Text } from 'components/atoms'
import { t } from 'services/translation'
import { colors } from 'styles'

export const Select = (props) => {
  return (
    <Flex 
    flexDir="row"
    borderRadius='6px'
    bg="cardBackground"
    h='52px'
    align="center"
    justify="center"
    pl='12px'
    mr={props?.mr || '5'}
    {...props}>
      <Text fontSize={14} mr="8px" color="textGrey">
        {t(props?.label)}:
      </Text>
      <SelectChakra
        w="100%"
        h="56px"
        fontFamily="Montserrat regular"
        iconColor="primary"
        borderColor="transparent"
        borderRadius="8px"
        errorBorderColor="baseRed"
        placeholder={t(props?.placeholder || 'SELECT')}
        backgroundColor="layoutBackground"
        textColor="textLight"
        variant="unstyled"
        {...props}
      >
        
        {props?.children ?? props?.list?.map?.((item, index) => (
          <option
          style={{
            backgroundColor: colors.menuBackground,
            fontFamily: props?.font ?? 'Montserrat Medium',
          }}
          key={item[props?.key] ?? index ?? item?.id ?? item?.value}
        >
          {item[props?.render] ?? item?.text ?? item}
        </option>
        ))}
      </SelectChakra>
    </Flex>
  )
}

Select.defaultProps = {}

Select.propTypes = {
  ...Select.propTypes,
}
