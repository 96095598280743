import { Flex, useTheme } from '@chakra-ui/react'
import { Icon, Text } from 'components/atoms'
import { t } from 'services/translation'

export const PremiumBlockMessage = ({ message, ...props }) => {
  const theme = useTheme()

  return (
    <Flex justifyContent="center" width="100%" {...props}>
      <Flex flexDir="column" alignItems="center" maxWidth={theme.pxToRem(290)}>
        <Icon name="PremiumCrown" />

        <Text
          fontSize={14}
          kind="bold"
          color="primary"
          mt={theme.pxToRem(12)}
          align="center"
        >
          {t(message)}
        </Text>
      </Flex>
    </Flex>
  )
}
