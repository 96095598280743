import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { TextInput, Box, Textarea } from 'components/atoms'
import { useTheme } from '@chakra-ui/react'

export const Form = ({
  initialValues,
  validationSchema,
  data,
  getValues,
  mb,
  ...props
}) => {
  const {
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    isValid,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  })
  const theme = useTheme()
  const checkEmptyFields = (checkData) => {
    const toCheck = checkData || values
    const find = data?.find(
      (v) =>
        toCheck?.[v?.field_type || v?.field]?.length <= 0 && !v?.not_required
    )
    if (!!find) {
      return false
    }
    validateForm()
    return isValid
  }

  useEffect(() => {
    if (initialValues && values !== initialValues) {
      setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useEffect(() => {
    if (getValues) {
      checkEmptyFields(values === initialValues ? initialValues : null)
      getValues({ params: values, isValid, errors })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid])

  return (
    <Box w={props?.w || 'auto'}>
      {data?.map((item) => {
        if (item?.typeInput === 'textArea') {
          return (
            <Box mb={theme.pxToRem(16)} w="100%">
              <Textarea
                {...item}
                {...props}
                value={values?.[item?.name]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          )
        } else {
          return (
            <Box mb={mb ? theme.pxToRem(mb) : theme.pxToRem(26)}>
              <TextInput
                {...item}
                {...props}
                isReadOnly={props?.setEditable ? !props?.editable : false}
                value={values?.[item?.name]}
                error={errors?.[item?.name]}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          )
        }
      })}
    </Box>
  )
}
