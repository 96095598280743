import * as React from 'react'

function SvgTaxRecipe(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 52 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 38.666l20-20"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="currentColor"
        d="M17.667 22a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333zM34.333 38.667a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.666 62V8.667A6.667 6.667 0 019.333 2h33.333a6.667 6.667 0 016.667 6.667V62l-10-6.667L32.666 62l-6.667-6.667L19.333 62l-6.667-6.667-10 6.667z"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgTaxRecipe
