import { saveData, getData } from 'services/storage'


export const saveAdsData = async (
  tag,
  value,
) => {
  try {
    saveData(`@BestBarbers:${tag}`, value)
  } catch (error) {
    return { error }
  }
}

export const getAdsData = async (tag) => {
  try {
    const response = await getData(
      `@BestBarbers:${tag}`
    )
    return response
  } catch (error) {
    return { error }
  }
}


