import React, { useEffect, useState } from 'react'
import { useToast, useTheme } from '@chakra-ui/react'
import { Layout, BarberCard, Button, TextInput, Text, Flex } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useTranslation } from 'react-i18next'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { ONLY_BARBERS } from 'services/api/endpoints'
import { useTeam } from 'services/hooks/use-team'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const TeamNF = ({ ...props }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const toast = useToast()
  const { updateNFbarberData } = useTeam()
  const [barbersList, setBarbersList] = useState([])
  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])

  const { userData } = useBarbershopStore()

  async function getTeam() {
    const res = await api
      .get(ONLY_BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { refetch, isLoading } = useQuery('get-team', getTeam, {
    onSuccess: (data) => {
      setBarbersList(data?.sort((a, b) => a?.id - b?.id))
    },
  })

  const updateMutation = useMutation(
    'update-nf-barber-data',
    (b) =>
      updateNFbarberData({
        barber_id: b?.id,
        identifier_doc: b?.identifier_doc?.replace(/[^0-9]/g, ''),
        corporate_name: b?.corporate_name,
      }),
    {
      onSuccess: () => {
        refetch()
        toast({
          title: 'Dados do profissional atualizados com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao atualizar dados do profissional',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onChange = ({ id, index, value, key }) => {
    const remove_item = barbersList.filter((b) => b.id !== id)
    const item = barbersList[index]
    const new_item = {
      ...item,
      ...(key === 'cnpj' ? { identifier_doc: value } : {}),
      ...(key === 'corporate_name' ? { corporate_name: value } : {}),
    }
    remove_item.splice(index, 0, new_item)
    setBarbersList(remove_item)
  }

  return (
    <Layout {...props} headerTitle={t('Dados da Equipe')}>
      <Flex mb={4}>
        <Text w="26%" kind="medium" fontSize={theme.pxToRem(14)} color="textGrey">
          Profissional Parceiro
        </Text>
        <Text w="32%" kind="medium" fontSize={theme.pxToRem(14)} color="textGrey">
          CNPJ do Profissional Parceiro
        </Text>
        <Text w="32%" kind="medium" fontSize={theme.pxToRem(14)} color="textGrey">
          Razão Social do CNPJ do Profissional Parceiro
        </Text>
      </Flex>
      {barbersList?.length > 0 &&
        barbersList?.map((b, index) => {
          return (
            <Flex mb={4} align="flex-start" justify="space-between">
              <BarberCard p={1} minH={0} h={theme.pxToRem(54)} width="25%" barber={b} />
              <TextInput
                noBorder
                w="31%"
                type="text"
                width="full"
                color="white"
                placeholder={t('XX.XXX.XXX/XXXX-XX')}
                mask="CNPJ_MASK"
                value={barbersList?.[index]?.identifier_doc}
                onChange={(event) =>
                  onChange({
                    id: b.id,
                    index,
                    key: 'cnpj',
                    value: event.target.value,
                  })
                }
              />
              <TextInput
                noBorder
                w="31%"
                type="text"
                width="full"
                color="white"
                placeholder={t('Razão Social do CNPJ do Profissional')}
                value={barbersList?.[index]?.corporate_name}
                onChange={(event) =>
                  onChange({
                    id: b.id,
                    index,
                    key: 'corporate_name',
                    value: event.target.value,
                  })
                }
              />
              <Button w="10%" onClick={() => updateMutation.mutate(barbersList?.[index])}>
                Salvar
              </Button>
            </Flex>
          )
        })}
      <Flex h={20} />
    </Layout>
  )
}

TeamNF.path = '/nota-fiscal/dados-da-equipe'
TeamNF.title = 'Dados da Equipe'
TeamNF.secured = true
