import React from 'react'

const Notification = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.97 19.5C11.07 19.5 11.97 18.6 11.97 17.5H7.97C7.97 18.6 8.87 19.5 9.97 19.5ZM15.97 13.5V8.5C15.97 5.43 14.34 2.86 11.47 2.18V1.5C11.47 0.67 10.8 0 9.97 0C9.14 0 8.47 0.67 8.47 1.5V2.18C5.61 2.86 3.97 5.42 3.97 8.5V13.5L1.97 15.5V16.5H17.97V15.5L15.97 13.5ZM13.97 14.5H5.97V8.5C5.97 6.02 7.48 4 9.97 4C12.46 4 13.97 6.02 13.97 8.5V14.5ZM5.55 1.58L4.12 0.15C1.72 1.98 0.14 4.8 0 8H2C2.07084 6.73181 2.42683 5.49593 3.04147 4.38439C3.65611 3.27284 4.51357 2.31425 5.55 1.58V1.58ZM17.94 8H19.94C19.79 4.8 18.21 1.98 15.82 0.15L14.4 1.58C15.432 2.31787 16.2856 3.27751 16.8982 4.38846C17.5108 5.49942 17.8668 6.73347 17.94 8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Notification
