import React, { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

import { Text, Flex, Button, TextInput, Box } from 'components'
import { theme } from 'styles'
import { clearCurrency } from 'utils/clearCurrency'
import { useFetch } from 'services/hooks/use-fetch-old'
import {
  BARBER_PRODUCTS_AND_SERVICES,
  PRODUCTS_BY_BARBER,
} from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores/barbershop'
import { api } from 'services/api/config'
import { useMutation } from 'react-query'

export const ProductsSection = ({ barber }) => {
  const toast = useToast()
  const [currentServices, setCurrentServices] = useState(null)
  const { userData } = useBarbershopStore()

  const { data } = useFetch(
    PRODUCTS_BY_BARBER(
      userData?.barbershop?.id || userData?.id,
      barber.id,
      barber?.type ? 'employee' : 'barber'
    ),
    {
      method: 'get',
    }
  )

  async function changeService() {
    const params = currentServices.map((i) => {
      return {
        ...i,
        comission_percentage: Number(
          i?.comission_percentage.toString().replace('%', '')
        ),
      }
    })
    const res = await api
      .post(BARBER_PRODUCTS_AND_SERVICES, {
        type: 'products',
        data: params,
        barber_id: !barber?.type ? barber.id : null,
        employee_id: barber?.type ? barber.id : null,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-service',
    (params) => changeService(params),
    {
      onSuccess: (data) => {
        toast({
          title: 'Serviços atualizados com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao atualizar serviços',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onChangeValue = (id, key, value) => {
    const changed = currentServices?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: value,
        }
      }
      return item
    })
    setCurrentServices(changed)
  }

  useEffect(() => {
    if (!currentServices) {
      setCurrentServices(data)
    }
  }, [data])

  return (
    <Box>
      <Flex
        mt={theme.pxToRem(16)}
        mb={theme.pxToRem(16)}
        justify="space-between"
      >
        <Text kind="semiBold" w="30%">
          Nome
          <Text fontSize={12} kind="regular">
            do produto
          </Text>
        </Text>
        <Text kind="semiBold" w="33%">
          Valor
          <Text fontSize={12} kind="regular">
            do produto (R$)
          </Text>
        </Text>
        <Text kind="semiBold" w="33%">
          Comissão
          <Text fontSize={12} kind="regular">
            do produto (%)
          </Text>
        </Text>
      </Flex>
      {currentServices?.map((item, index) => (
        <Flex align="center" justify="space-between" mt={theme.pxToRem(4)}>
          <Text kind="semiBold" w="30%">
            {item?.name}
          </Text>
          <TextInput
            w="33%"
            placeholder="0,00"
            mask="CURRENCY_MASK"
            isReadOnly={false}
            value={currentServices[index].price}
            onChange={(e) =>
              onChangeValue(item?.id, 'price', clearCurrency(e.target.value))
            }
          />
          <TextInput
            w="33%"
            mask="PERCENTAGE_MASK"
            isReadOnly={false}
            value={
              currentServices[index].comission_percentage ??
              barber?.percentage ??
              0
            }
            onChange={(e) => {
              onChangeValue(item?.id, 'comission_percentage', e.target.value)
            }}
          />
        </Flex>
      ))}

      <Button
        onClick={mutate.mutate}
        mt={theme.pxToRem(24)}
        mb={theme.pxToRem(40)}
        size="block"
        isLoading={mutate.isLoading}
      >
        Salvar
      </Button>
    </Box>
  )
}
