import create from 'zustand'
import { api } from 'services/api/config'
import { handleError } from 'services/utils'
import { saveData } from 'services/storage'
import { saveLoginData, getLoginSavedData } from 'services/hooks'
import { INTERNAL_ADM_LOGIN, CLIENT_AVATAR, INTERNAL_UPDATE_USER_DATA } from 'services/api/endpoints'

export const internalAuthStore = create((set) => ({
  user: null,
  userData: null,
  token: null,
  loading: false,

  onLogin: async (data, toast) => {
    set({ loading: true })
    try {
      const response = await api.post(INTERNAL_ADM_LOGIN, data)
      const { user, userData, token, new_token } = response?.data
      saveLoginData(response?.data)
      set({
        loading: false,
        user,
        userData,
        token,
        new_token,
      })
      return { success: true }
    } catch (error) {
      set({
        loading: false,
        user: null,
        userData: null,
        token: null,
      })
      toast({
        title: handleError(error).error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return { error }
    }
  },

  onUpdatePhoto: async (data, toast) => {
    try {
      const response = await api.post(CLIENT_AVATAR, data)
      set({
        loading: false,
        userData: response?.data,
      })
      saveData('@BestBarbers:userData', response?.data)
      toast({
        title: 'Foto alterada com sucesso! =D',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      return { success: true }
    } catch (error) {
      toast({
        title: handleError(error).error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      set({ loading: false })
      return { error }
    }
  },

  onUpdateUser: async (data, toast) => {
    try {
      const response = await api.put(INTERNAL_UPDATE_USER_DATA, data)
      saveData('@BestBarbers:user', response?.data?.user)
      saveData('@BestBarbers:userData', response?.data?.userData)
      toast({
        title: 'Dados alterados com sucesso! =D',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      return { success: true }
    } catch (error) {
      toast({
        title: handleError(error).error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return { error }
    }
  },

  onRefreshUserData: async () => {
    try {
      const response = await getLoginSavedData()
      const { user, userData, token } = response
      set({
        user,
        userData,
        token,
      })
    } catch (error) {
      return { error }
    }
  },
}))
