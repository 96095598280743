import React, { useMemo } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useBarbershopStore } from 'services/stores/barbershop'
import { equivalentPermission } from 'utils'
import { useUserType } from 'services/hooks/use-user-type'

export const Protected = ({ children, ...props }) => {
  const { user, userData } = useBarbershopStore()
  const userType = useUserType()
  const hasPermission = useMemo(() => {
    if (props?.path === '/avaliacoes' || props?.path === '/atualizacoes') {
      return 'edit'
    }
    const equivalent = equivalentPermission[props?.path]
    if (
      userData &&
      !userData?.permissions?.[equivalent] &&
      userType === 'collaborator' &&
      props?.path !== '/conteudos' &&
      props?.path !== '/comandas'
    ) {
      return 'blocked'
    }
    return userData?.permissions?.[equivalent]
  }, [userData])

  return (
    <Route
      {...props}
      render={() => {
        if (!user?.id) {
          return <Redirect to={{ pathname: '/login' }} />
        }
        if (
          (props?.path === '/agenda' && hasPermission === 'blocked') ||
          hasPermission === 'blocked' ||
          (props?.path === '/perfil/:section' && userType === 'collaborator' && hasPermission !== 'edit')
        ) {
          return <Redirect to={{ pathname: '/conteudos' }} />
        }

        return children
      }}
    />
  )
}
