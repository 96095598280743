import React from 'react'
import { Image } from '@chakra-ui/react'

import { Text, Flex, Button } from 'components'

import { theme } from 'styles'
import { useBreakpoint } from 'services/hooks'

import heroSrc from './../images/section-hero.png'
import circleLogo from './../images/circle-logo.png'
import { StoreIcons } from './stores'

export const HeroPartners = ({ clickAppExclusive, img, ...props }) => {
  const { isDesktop } = useBreakpoint()

  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        backgroundImage={`url(${heroSrc})`}
        backgroundSize="cover"
        backgroundPosition="center center"
        align="flex-start"
        justify="center"
        px="5vw"
        pt="5vh"
        flexDir="column"
      >
        <Text fontSize={theme.pxToRem(32)} kind="extraBold" mb={theme.pxToRem(12)}>
          A revolução da sua barbearia começa aqui
        </Text>
        <Text mb={theme.pxToRem(12)} fontSize={theme.pxToRem(14)} kind="medium">
          O BestBarbers é a plataforma que vai te ajudar a alcançar resultados de verdade na sua barbearia.
        </Text>
        <Button mb={theme.pxToRem(12)} size="small" onClick={clickAppExclusive}>
          Quero ser premium
        </Button>
        <Text fontSize={theme.pxToRem(12)} kind="medium">
          App disponível para iOS, Android e navegadores web.
        </Text>
        <StoreIcons />
        <Flex w="100%" align="flex-end" justify="center">
          <Image src={img} h="92%" w="auto" {...props} />
        </Flex>
      </Flex>
    )
  }
  return (
    <Flex
      w={'100vw'}
      h={'88vh'}
      backgroundImage={`url(${heroSrc})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      align="center"
      justify="center"
      pl="10vw"
      pr="10vw"
    >
      <Flex w="50%" flexDir="column">
        <Flex>
          <Text w={theme.pxToRem(500)} fontSize={theme.pxToRem(40)} kind="extraBold" mb={theme.pxToRem(24)}>
            A revolução da sua barbearia começa aqui
          </Text>
        </Flex>
        <Text mb={theme.pxToRem(40)} fontSize={theme.pxToRem(16)} kind="medium" w={theme.pxToRem(500)}>
          O BestBarbers é a plataforma que vai te ajudar a alcançar resultados de verdade na sua barbearia.
        </Text>
        <Button mb={theme.pxToRem(12)} size="small" onClick={clickAppExclusive}>
          Quero um Aplicativo Personalizado
        </Button>
        <Text mt={theme.pxToRem(60)} fontSize={theme.pxToRem(14)} kind="medium">
          App disponível para iOS, Android e navegadores web.
        </Text>
        <StoreIcons />
      </Flex>
      <Flex h="100%" align="flex-end" justify="center" w="50%">
        <Image src={img} h="92%" w="auto" />
      </Flex>
      <Image position="absolute" bottom="-3vh" src={circleLogo} h={theme.pxToRem(140)} w={theme.pxToRem(140)} />
    </Flex>
  )
}
