import React from 'react'
import { Checkbox as ChakraCheckBox } from '@chakra-ui/react'

export const CheckBox = ({ children, ...props }) => {
  return (
    <ChakraCheckBox
      fontFamily="Montserrat Regular"
      background="transparent"
      colorScheme="blackAlpha"
      color="textLight"
      iconColor="brand.primary"
      sx={{ '[data-checked]': { borderColor: 'primary' } }}
      {...props}
    >
      {children}
    </ChakraCheckBox>
  )
}
