import React from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useLazyFetch, saveInternalLoginData } from 'services/hooks'
import { useBreakpoint } from 'services/hooks'
import { internalAuthStore } from 'services/stores'
import {
  Box,
  Button,
  Icon,
  Image,
  Link,
  Text,
  TextInput,
} from 'components/atoms'
import { useTheme, useToast, Flex } from '@chakra-ui/react'
import { colors } from 'styles/colors'
import { INTERNAL_ADM_LOGIN } from 'services/api/endpoints'
import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { useBarbershopStore } from 'services/stores/barbershop'
import { formikProps } from './settings'

export const InternalLoginScreen = (props) => {
  const { onLogin } = internalAuthStore()
  const history = useHistory()
  const theme = useTheme()
  const toast = useToast()

  const { isMobile } = useBreakpoint()

  const { values, handleChange, errors, setFieldValue } = useFormik(formikProps)

  const onChangeUser = (text) => {
    const unmasked = text.replace(/[^a-zA-Z0-9@._]/g, '')
    if (!isNaN(parseFloat(unmasked)) && isFinite(unmasked)) {
      setFieldValue('type', 'phone')
      setFieldValue('user', text)
      return
    }
    setFieldValue('type', 'e-mail')
    let unmaskedEmail = text.replace(/[^a-zA-Z0-9@._-]/g, '')
    if (values.type === 'phone') {
      unmaskedEmail = text.replace(/[^a-zA-Z0-9@._]/g, '')
    }
    setFieldValue('user', unmaskedEmail)
  }

  const handleSubmit = async () => {
    const response = await onLogin(values, toast)
    if (!response?.error) {
      history.push('/internal-adm/home')
    }
  }

  return (
    <Flex
      align="center"
      justifyContent="center"
      flexDir="column"
      w="100vw"
      h="100vh"
      bg="backgroundLayout"
    >
      <Flex
        bg="background"
        borderRadius={theme.pxToRem(12)}
        align="center"
        flexDir="column"
        w={theme.pxToRem(500)}
        py={theme.pxToRem(48)}
        px={theme.pxToRem(isMobile ? 24 : 48)}
      >
        <Image
          src={bestBarbersLogo}
          alt="BestBarbers"
          width={theme.pxToRem(160)}
          objectFit="contain"
        />

        <Text
          kind="extraBold"
          fontSize={theme.pxToRem(24)}
          mt={theme.pxToRem(40)}
        >
          Administrativo Interno
        </Text>
        <Text
          kind="semiBold"
          textAlign={isMobile ? 'center' : 'left'}
          fontSize={theme.pxToRem(16)}
          mt={theme.pxToRem(8)}
        >
          Acesso para usuários internos do BestBarbers
        </Text>

        <TextInput
          type="inputMask"
          mask={values.type === 'phone' ? '** *****-****' : null}
          alwaysShowMask={false}
          maskChar={null}
          mt={theme.pxToRem(48)}
          placeholder="E-mail ou telefone"
          error={errors.user}
          value={values.user}
          onChange={(e) => onChangeUser(e.target.value.toLowerCase())}
          paddingLeft={theme.pxToRem(50)}
          leftElement={
            <Box
              mr={theme.pxToRem(16)}
              color={errors.user ? colors.danger : colors.primary}
            >
              <Icon
                name="PersonBarber"
                color={errors.user ? colors.danger : colors.primary}
              />
            </Box>
          }
        />

        <TextInput
          type="password"
          name="password"
          placeholder="Sua senha"
          error={errors.password}
          value={values.password}
          mt={theme.pxToRem(24)}
          onChange={handleChange}
          paddingLeft={theme.pxToRem(50)}
          leftElement={
            <Box
              mr={theme.pxToRem(16)}
              color={errors.password ? colors.danger : colors.primary}
            >
              <Icon
                name="Lock"
                color={errors.password ? colors.danger : colors.primary}
              />
            </Box>
          }
        />

        <Button w="100%" mt={theme.pxToRem(48)} onClick={handleSubmit}>
          <Text
            fontSize={theme.pxToRem(16)}
            color={colors.textDark}
            kind="bold"
          >
            Entrar
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

InternalLoginScreen.path = '/internal-adm/login'
InternalLoginScreen.title = 'Internal Login'
InternalLoginScreen.secured = false
