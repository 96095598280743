import React, { useState, useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Button,
  Text,
  Icon,
  ModalMenu,
  TextInput,
  Avatar,
} from 'components'
import { useLazyFetch, useBreakpoint } from 'services/hooks'
import { internalAuthStore } from 'services/stores/internalAuth'
import { useTranslation } from 'react-i18next'
import { theme } from 'styles/theme'
import { INTERNAL_CREATE_OBSERVATION } from 'services/api/endpoints'

export const ObservationsModal = ({ isModalOpen, onClose, info, getObservations }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const [observation_text, setObservation_text] = useState('')
  const { userData, user, onRefreshUserData } = internalAuthStore()

  const [onCreate] = useLazyFetch(
    INTERNAL_CREATE_OBSERVATION,
    {
      method: 'post',
      onCompleted: async () => {
        await getObservations()
        toast({
          title: 'Observação adicionada com sucesso!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        onClose()
      },
      onError: () => {
        toast({
          title: 'Falha ao adicionar observação',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
    }
  )

  useEffect(() => {
    onRefreshUserData()
  }, [isModalOpen])

  return (
    <ModalMenu
      isNavigating
      bodyStyle={{ padding: 0 }}
      isOpen={isModalOpen}
      handleBackNavigation={onClose}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={theme.pxToRem(12)}
        width="100%"
        height={theme.pxToRem(120)}
        position=" absolute"
        bottom={0}
        backgroundColor="cardBackground"
      >
        <Flex flexDir="column" alignItems="center">
          <Avatar
            size={isDesktop ? 'menu' : 'medium'}
            src={userData?.profile_image_url}
          />
          <Text mt={theme.pxToRem(2)} textAlign="center" fontSize={10} w={50}>
            {userData?.name}
          </Text>
        </Flex>

        <Box width={!isDesktop ? theme.pxToRem(220) : theme.pxToRem(260)}>
          <TextInput
            noBorder
            backgroundColor="background"
            placeholder={t('DESCRIPTION_PLACEHOLDER')}
            value={observation_text}
            onChange={(event) => setObservation_text(event.target.value)}
          />
        </Box>

        <Button
          disabled={observation_text?.length === 0}
          size="square"
          onClick={() => {
            onCreate({
              params: {
                client_user_id: info?.user?.id,
                internal_user_id: user?.id,
                observation_text,
                flag: "teste",
              }
            })
          }}
        >
          <Icon name="Send" color="black" />
        </Button>
      </Flex>
    </ModalMenu>
  )
}
