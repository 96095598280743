import React, { useEffect, useState } from 'react'
import { Box, Button, Text, TextInput } from 'components/atoms'
import { Form, ModalAlert, ModalMenu, SelectCard, ServiceCard, WeekdaySelector } from 'components/molecules'
import { useMutation, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { options, initialValues } from './settings'
import { useToast } from '@chakra-ui/react'
import { WEEK_DAYS } from 'utils'
import { SelectServiceMethod } from './SelectService'
import { usePlans } from 'services/hooks/use-plans'
import { unformatCurrency } from 'utils/formatCurrency'
import { useBarbershopStore } from 'services/stores'

export const CreatePlanModal = ({ isOpen, onClose, plan }) => {
  const [isAlertOpen, setIsAlertOpen] = useState()
  const [services, setServices] = useState([])
  const [values, getValues] = useState({ params: plan ? plan : initialValues, isValid: false })
  const [type, setType] = useState(plan && plan?.days?.length > 0 ? plan.days : null)
  const [limited, setLimited] = useState(plan && plan?.limited ? true : false)
  const [paymentMethodOpen, setPaymentMethodOpen] = useState(false)
  const [weekDays, setWeekDays] = useState(WEEK_DAYS)
  const { createPlans, deletePlan, updatePlans } = usePlans()
  const { userData } = useBarbershopStore()
  const queryClient = useQueryClient()
  const toast = useToast()

  useEffect(() => {
    if (plan) {
      setServices(plan?.services_plans)
      getValues({ params: plan, isValid: false })
      setType(plan?.days)
      setLimited(plan?.limited)

      if (plan?.days?.length > 0) {
        const arr = []
        WEEK_DAYS.forEach((day) => {
          const isSelected = plan?.days?.some((i) => i === day?.id)
          arr.push({
            ...day,
            selected: isSelected,
          })
        })
        setWeekDays(arr)
      }
    } else {
      setServices([])
      setType(null)
      setLimited(false)
      setWeekDays(WEEK_DAYS)
      getValues({ params: initialValues, isValid: false })
    }
  }, [plan])

  const onNullStates = () => {
    setServices([])
    getValues({ params: initialValues, isValid: false })
    setType(null)
    setWeekDays(WEEK_DAYS)
    setPaymentMethodOpen(false)
  }

  const mutate = useMutation('create-plan', (params) => (plan ? updatePlans(params) : createPlans(params)), {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-plans'])
      toast({
        title: plan ? 'Plano alterado com sucesso' : 'Plano cadastrado com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onNullStates()
      onClose()
    },
  })

  const mutateDelete = useMutation('delete-plan', () => deletePlan({ id: plan?.id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-plans'])
      toast({
        title: 'Plano excluído com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setIsAlertOpen(false)
      onNullStates()
      onClose()
    },
  })

  const handleChange = (id, value, type) => {
    const existingItem = services.find((item) => item.id === id)
    if (existingItem) {
      const updatedInputValue = services.map((item) =>
        item.id === id ? { ...item, [type]: Number(value.replace(',', '.')) } : item
      )
      setServices(updatedInputValue)
    } else {
      setServices([...services, { id, [type]: Number(value.replace(',', '.')) }])
    }
  }

  return (
    <ModalMenu isOpen={isOpen} onClose={onClose} title="Plano do clube de assinatura">
      <Box>
        <Form mb={42} data={options(plan)} initialValues={values?.params} getValues={getValues} />
        <Box marginBottom="24px">
          <SelectCard
            mb="16px"
            item={{
              title: 'Possui limite de utilização?',
              description: 'Caso o serviço possua limite de utilização (por ex. 2 vezes no mês), marque essa opção.',
            }}
            setSelected={setLimited}
            selected={limited}
            disabled={plan}
          />
          <Text kind="medium" fontSize="14px" color="textGrey" mb="6px">
            {t('SELECT_SERVICES')}
          </Text>
          {!!services?.length &&
            services.map((item, index) => (
              <Box minHeight={services?.length > 1 && '156px'} mb={limited ? '40px' : '16px'}>
                <ServiceCard
                  key={index}
                  index={index}
                  marginBottom={'12px'}
                  fullWidth
                  service={item?.product || item}
                  onDelete={!plan ? () => setServices(services?.filter((i) => i?.id !== item?.id)) : null}
                />
                <TextInput
                  type="number"
                  value={services?.find((i) => i?.id === item?.id)?.points}
                  label="Peso"
                  placeholder="Digite o peso do serviço"
                  mb="16px"
                  onChange={(e) => handleChange(item?.id, e.target.value, 'points')}
                  disabled={plan}
                />
                {limited && (
                  <TextInput
                    type="number"
                    value={services?.find((i) => i?.id === item?.id)?.quantity}
                    label="Quantidade"
                    placeholder="Digite a quantidade de usos"
                    mt="32px"
                    onChange={(e) => handleChange(item?.id, e.target.value, 'quantity')}
                    disabled={plan}
                  />
                )}
              </Box>
            ))}
          {!plan && (
            <Button mt={'16px'} size="block" kind="appointmentOptions" onClick={() => setPaymentMethodOpen(true)}>
              {t('SELECT_SERVICES')}
            </Button>
          )}
        </Box>
        <SelectCard
          mb="16px"
          item={{
            title: t('SPECIFIC_DAYS'),
            description: t('SPECIFIC_DAYS_SUB'),
          }}
          setSelected={setType}
          selected={type}
          // disabled={plan}
        />
        {type && (
          <Box mt="12px" mb="12px">
            <WeekdaySelector
              locale={t('LOCALE')}
              weekDays={weekDays}
              onSetWeekdays={setWeekDays}
              // disabled={plan}
            />
          </Box>
        )}

        {plan && (
          <Button
            kind={'primary'}
            isLoading={mutate.isLoading}
            size="block"
            mb="16px"
            onClick={() => {
              const body = {
                plan_id: plan.id,
                days: !type ? [] : weekDays.filter((day) => day.selected)?.map((i) => i?.id),
                name: values?.params?.name,
              }

              mutate.mutate({ body })
            }}
          >
            {t('SAVE')}
          </Button>
        )}
        <Button
          kind={plan ? 'danger' : 'primary'}
          isDisabled={
            !values?.params?.name ||
            !values.params?.value ||
            (!values?.params?.comission && values?.params?.comission !== 0) ||
            services?.length === 0 ||
            (limited && services.find((s) => !s?.quantity)) ||
            services.find((s) => !s?.points && s?.points !== 0)
          }
          isLoading={mutate.isLoading}
          size="block"
          mb="16px"
          onClick={() => {
            if (plan) {
              setIsAlertOpen(true)
              return
            }
            const body = {
              ...values.params,
              value: unformatCurrency(values?.params?.value),
              comission: Number(values?.params?.comission?.toString()?.replace?.('%', '')),
              days: weekDays.filter((day) => day.selected)?.map((i) => i?.id),
              barbershop_id: userData?.id,
              services,
              limited,
            }

            mutate.mutate({ body })
          }}
        >
          {t(plan ? 'DELETE' : 'SAVE')}
        </Button>

        <SelectServiceMethod
          isOpen={paymentMethodOpen}
          onClose={() => setPaymentMethodOpen(false)}
          services={services}
          setServices={setServices}
        />

        <ModalAlert
          isOpen={isAlertOpen}
          onClose={() => {
            setIsAlertOpen(false)
          }}
          onConfirm={mutateDelete.mutate}
          primaryButtonLabel={t('DELETE')}
          modalTitle="Deseja deletar plano?"
        >
          <Text fontWeight="bold" m="16px">
            Deseja deletar plano do clube de assinatura?
          </Text>
        </ModalAlert>
      </Box>
    </ModalMenu>
  )
}
