import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api/config'

const mutationFn = async ({ appointmentId }) => {
  const { data } = await api.delete(`v3/appointment/discount/${appointmentId}`)

  return data
}

export const useRemoveAppointmentDiscount = ({ appointmentId, onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => mutationFn({ appointmentId }),
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(['get-appointment-details', appointmentId])
    },
  })
}
