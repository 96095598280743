import React, { useMemo, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, ModalContent, Flex, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Text, Button, Icon } from 'components/atoms'
import { FreeTrialModalBg } from 'assets/img/backgrounds'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useHistory } from 'react-router-dom'
import { Avatar } from 'components/atoms'
import { REVALIDATE_SUBSCRIPTION } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { saveLoginData } from 'services/hooks'
import { Loader } from 'components/atoms'

export const SelectUnityModal = ({ isOpen, onClose, data }) => {
  const theme = useTheme()
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { setData, user } = useBarbershopStore()
  const history = useHistory()
  const [select, setSelect] = useState()
  const [loading, setLoading] = useState(false)

  const Container = isDesktop ? Flex : ModalContent

  async function revalidateSubs(p) {
    const params = {
      barbershop_id: p,
      user_id: data?.user?.id,
    }
    setLoading(true)
    const res = await api
      .post(REVALIDATE_SUBSCRIPTION, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('revalidate-subscription', (params) => revalidateSubs(params), {
    onSuccess: async (d) => {
      const toSaveUserData = d?.userData.barbershop ? d?.userData.barbershop : d?.userData
      saveLoginData({
        user: d?.user,
        userData: toSaveUserData,
        token: d?.token,
        new_token: d?.new_token,
      })
      setData(d?.user, toSaveUserData, d?.subscription, d?.customer)
      if (!toSaveUserData.status || toSaveUserData.status === 'not_published') {
        history.push('/home?register=true')
        return
      }

      history.push('/agenda')
      setLoading(false)
    },
    onError: (error) => {
      setLoading(false)
      toast({
        title: t('toast.error.login.login_error'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
      })
    },
  })

  const renderData = useMemo(() => {
    if (user?.type === 'owner') {
      return data?.userData?.barbershops || data?.userData
    } else {
      return data?.userData?.barbershops
    }
  }, [data, user])

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay>
        <Container
          borderRadius="xl"
          mt={0}
          h="100vh"
          align="center"
          justify="center"
          bg="transparent"
          w="100%"
          pb={theme.pxToRem(isDesktop ? 0 : 850)}
        >
          <Flex
            bgColor="backgroundComponent"
            backgroundImage={`url(${FreeTrialModalBg})`}
            bgRepeat="no-repeat"
            pos={!isDesktop && 'absolute'}
            top={!isDesktop && 0}
            w={theme.pxToRem(530)}
            maxH={theme.pxToRem(850)}
            p={theme.pxToRem(20)}
            h={isDesktop ? '90vh' : 'auto'}
            alignItems="center"
            justifyContent="center"
            flexDir="Column"
            borderRadius="xl"
          >
            <Text align="center" kind="extraBold" fontSize={22} mr={theme.pxToRem(6)} w="80%">
              {t('SELECT_UNITY')}
            </Text>
            <Flex w={isDesktop ? '78%' : '100%'} align="center" justify="center" flexDir="column" mt={'3%'}>
              {renderData?.map((item) => (
                <>
                  <Flex
                    cursor="pointer"
                    key={item?.id}
                    flexDir="row"
                    align="center"
                    justify="space-between"
                    minH={theme.pxToRem(60)}
                    w="100%"
                    mb={theme.pxToRem(16)}
                    borderRadius={theme.pxToRem(8)}
                    borderWidth={1}
                    borderColor="white"
                    p={theme.pxToRem(16)}
                    onClick={() => {
                      mutate.mutate(item?.id)
                    }}
                  >
                    <Avatar src={item?.profile_image_url} size="medium" />
                    <Flex flexDir="column" ml="4%" mr="4%" w="95%">
                      <Text color={'white'} fontSize={theme.pxToRem(16)} kind="bold">
                        {item?.name}
                      </Text>
                      {item?.address ? (
                        <Text
                          mt="1%"
                          color={'white'}
                          fontSize={theme.pxToRem(12)}
                          maxW="95%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          kind="normal"
                        >
                          {`${item?.address?.street}${
                            item?.address?.street_number?.length > 0 ? ', ' + item?.address?.street_number + ', ' : ', '
                          } ${item?.address?.city}`}
                        </Text>
                      ) : (
                        <Text mt="1%" color={'white'} fontSize={theme.pxToRem(12)} kind="normal">
                          {t('PENDING_CONFIGURATION')}
                        </Text>
                      )}
                    </Flex>
                    <Icon size={20} name="ChevronRight" color={'white'} />
                  </Flex>
                </>
              ))}
            </Flex>
            {loading && <Loader />}
          </Flex>
        </Container>
      </ModalOverlay>
    </Modal>
  )
}
