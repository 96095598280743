import React, { useState } from 'react'
import { ModalMenu, Button } from 'components'
import { AppointmentSignatureSelectBarber } from './SelectBarber'
import { AppointmentSignatureSelectClient } from './SelectClient'
import { AppointmentSignatureSelectDate } from './SelectDate'
import { AppointmentSignatureSelectService } from './SelectService'
import { AppointmentSignatureSelectOptions } from './SelectOptions'
import { APPOINTMENT_STEPS } from './settings'
import { ModalButtonBackground } from 'components-v2/atoms'
import { t } from 'services/translation'

export const AppointmentsSignatureModal = ({
  isModalOpen,
  onClose,
  clickEmpty,
  setClickEmpty,
}) => {
  const [schedulingStep, setSchedulingStep] = useState(
    APPOINTMENT_STEPS.initialStep
  )

  const handleBackNavigation = () => {
    setSchedulingStep(APPOINTMENT_STEPS.initialStep)

  }

  const displayAppointmentSteps = () => {
    switch (schedulingStep) {
      case APPOINTMENT_STEPS.initialStep:
        return (
          <AppointmentSignatureSelectOptions
            setStep={setSchedulingStep}
            onClose={onClose}
            setClickEmpty={setClickEmpty}
          />
        )

      case APPOINTMENT_STEPS.selectService:
        return (
          <AppointmentSignatureSelectService
            clickEmpty={clickEmpty}
            goBack={handleBackNavigation}
          />
        )

      case APPOINTMENT_STEPS.selectCustomer:
        return (
          <AppointmentSignatureSelectClient
            goBack={handleBackNavigation}
          />
        )

      case APPOINTMENT_STEPS.selectBarber:
        return (
          <AppointmentSignatureSelectBarber
            goBack={handleBackNavigation}
            clickEmpty={clickEmpty}
          />
        )

      case APPOINTMENT_STEPS.selectDateAndTime:
        return (
          <AppointmentSignatureSelectDate
            goBack={handleBackNavigation}
          />
        )

      default:
        return <AppointmentSignatureSelectOptions />
    }
  }

    return (
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onClose}
        title={t(schedulingStep === APPOINTMENT_STEPS.initialStep
            ? 'Adicionar novo agendamento avulso do clube'
            : schedulingStep
        )}
        isNavigating={schedulingStep !== APPOINTMENT_STEPS.initialStep}
        handleBackNavigation={handleBackNavigation}
        size="lg"
      >
        {displayAppointmentSteps()}
        {schedulingStep === APPOINTMENT_STEPS.selectService && (
          <ModalButtonBackground>
            <Button size="block" onClick={handleBackNavigation}>
              {t('CONFIRM')}
            </Button>
          </ModalButtonBackground>
        )}
      </ModalMenu>
    )
}
