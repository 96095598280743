import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Table, Text, TextInput } from 'components'
import { Input, useToast } from '@chakra-ui/react'
import { new_api } from 'services/api/config-v2'
import { Layout } from '../Components'
import { useMutation } from 'react-query'
import { api } from 'services/api/config'

export const ImportNormalClients = () => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [barbershopId, setBarbershopId] = useState(null)
  const [resume, setResume] = useState(null)
  const toast = useToast()

  const columns = [
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => <Text color={item?.status === 'error' ? 'danger' : 'success'}>{item?.status}</Text>,
    },
    {
      key: 'description',
      label: 'DESCRIÇAO',
      render: (item) => <Text>{item?.description}</Text>,
    },
  ]

  useEffect(() => {
    setResume(null)
  }, [])
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }

  const mutate = useMutation(
    'create-batch-clients',
    (formData) =>
      api.post(`v3/barbershop/clients/import-batch`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onSuccess: ({ data }) => {
        setResume(data)
        toast({
          title: 'Clientes importados com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao importar clientes',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
  const handleSubmit = async (event) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', selectedFile)

    mutate.mutate(formData)
  }

  return (
    <Layout title="Importar clientes">
      <Flex align="center" justify="center" flexDir="column" w="100%" h="100%" p="60px">
        <Box mb="30px" as="form" p={4} borderWidth={1} borderRadius="lg" align="center" justify="center">
          <Input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            mb={4}
            w="100%"
            alignSelf="center"
            justifySelf="center"
          />
        </Box>
        {/* <TextInput
          noBorder
          w="28%"
          mr="3%"
          name="cvv"
          placeHolder="10"
          value={barbershopId}
          onChange={(e) => setBarbershopId(e.target.value)}
          label="Id da barbearia"
          padding="10px"
          h="56px"
        /> */}
        <Button isLoading={mutate.isLoading} onClick={handleSubmit} mt="80px">
          Importar
        </Button>
        {resume?.length > 0 && <Table data={resume} columns={columns} mt="24px" />}
      </Flex>
    </Layout>
  )
}

ImportNormalClients.path = '/internal-adm/import-normal-clients'
ImportNormalClients.title = 'Importar clientes'
ImportNormalClients.secured = true
