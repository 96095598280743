export const firstSection = (monthExtract) => {
  return [
    {
      description: 'Valor total (bruto) das comandas finalizados no período selecionado',
      label: 'Total de faturamento bruto das vendas no período',
      color: 'success',
      balance: 'cardBackground',
      value: monthExtract?.current_month_finished_appointments_total_value || 0,
      infoText: 'Este valor é referente a soma de todas as comandas FINALIZADAS no período selecionado',
    },
    {
      description: 'Previsão de faturamento bruto de todos os agendamentos',
      label: 'Previsão do total de faturamento bruto das vendas no período',
      color: 'success',
      balance: 'cardBackground',
      value: monthExtract?.current_month_not_finished_appointments_total_prevision || 0,
      infoText:
        'Este valor é referente a soma de TODAS as comandas (finalizadas e não finalizadas) no período selecionado',
    },
  ]
}

export const clientsInfoQuantity = (monthExtract) => {
  return [
    {
      label: 'TOTAL_CLIENTS',
      description: 'ATTENDED',
      value: monthExtract?.total_clients || 0,
    },
    // {
    //   label: 'TOTAL',
    //   description: 'NEW_CLIENTS',
    //   value: monthExtract?.new_clients || 0,
    // },
    {
      label: 'PER_DAY',
      description: 'CLIENT_AVERAGE',
      value: monthExtract?.average_number_of_clients_per_day || 0,
      last: true,
    },
  ]
}

export const entriesInfo = (monthExtract) => {
  return [
    {
      label: 'Total de entradas consolidadas no período',
      description: 'Total de receitas que foram recebidas e lançadas no período',
      color: 'success',
      value: (monthExtract?.appointments_paid_total_value || 0) + (monthExtract?.revenues_total || 0),
      balance: 'cardBackground',
      background: 'grey',
      infoText:
        'Este valor é referente a soma do total de receitas provisionadas de acordo com a data de recebimento + receitas lançadas manualmente',
    },
    {
      description: 'Provisionamento das comandas finalizadas de acordo com a data de recibimento',
      label: 'Total de valores consolidados para recebimento no período',
      color: 'success',
      balance: 'cardBackground',
      value: monthExtract?.appointments_paid_total_value || 0,
      infoText:
        'Este valor é referente a soma das comandas FINALIZADAS que tem DATA DE RECEBIMENTO PREVISTAS para o período. Exemplo: um agendamento que ocorreu no dia 05/10 mas foi pago com cartão de crédito (recibimento após 30 dias). Este valor estará provisionado para recebimento no dia 05/11',
    },
    {
      description: 'Lançamentos manuais de receita',
      label: 'Valor total de receitas (entradas) lançadas manualmente',
      color: 'success',
      balance: 'cardBackground',
      value: monthExtract?.revenues_total || 0,
      infoText: 'Este valor é referente a soma de todas as receitas (entradas) lançadas manualmente',
    },
  ]
}

export const resumeInfo = (monthExtract) => {
  return [
    {
      label: 'Saldo do mês até o momento',
      description: 'Valor líquido disponível até o momento',
      color: 'white',
      value: monthExtract?.month_balance || 0,
      balance: monthExtract?.month_balance >= 0 ? 'successDark' : 'dangerCard',
      background: monthExtract?.month_balance >= 0 ? 'success' : 'danger',
      info: 'lancamentos',
      infoText: 'Valor total de entradas consolidadas - total de despesas do período',
    },
    {
      description: 'Previsão do valor líquido do período selecionado',
      label: 'Previsão de saldo total do período',
      color: monthExtract?.month_total_prevision < 0 ? 'danger' : 'success',
      balance: 'cardBackground',
      background: 'grey',
      value: monthExtract?.month_total_prevision || 0,
      infoText:
        'Valor total de entradas consolidadas + previsão do valor líquido dos agendamentos não finalizados - total de despesas do período',
    },
    // {
    //   label: `${monthExtract?.total_open || 0} comandas em aberto`,
    //   description: `${monthExtract?.total_finished || 0} comandas finalizadas`,
    //   last: true,
    //   notCurrency: true,
    //   value: 'Resumo de agendamentos',
    //   infoText:
    //     'Valor total de entradas consolidadas + previsão do valor líquido dos agendamentos não finalizados - total de despesas do período',
    // },
  ]
}

export const exitsInfo = (monthExtract) => {
  return [
    {
      description: 'Valor total das comissões pagas aos barbeiros no período selecionado',
      label: 'Total de comissões pagas',
      balance: 'cardBackground',
      color: 'danger',
      value: -((monthExtract?.comission_paid_total_value || 0) + (monthExtract?.pending_worht_total_value || 0)) || 0,
      info: 'relatorio-barbeiros',
      infoText: 'Total de comissões pagas aos barbeiros referente as vendas de produtos e serviços',
    },
    {
      description: 'Valor total de despesas lançadas manualmente',
      label: 'Lançamentos manuais de despesas',
      color: 'danger',
      balance: 'cardBackground',
      value: -monthExtract?.expenses_total_value || 0,
    },
    {
      description: 'Valor total das despesas pagas no período',
      label: 'Total de despesas pagas',
      balance: 'cardBackground',
      color: 'danger',
      value: -monthExtract?.exist_calculated || 0,
      info: 'extrato-simplificado',
      infoText:
        'Este valor é referente ao total de taxas descontadas pelos métodos de pagamento + total de comissões dos barbeiros + despesas lançadas manualmente',
    },
  ]
}

export const settingsCards = (monthExtract) => {
  return [
    //Saldo do mes
    {
      label: 'BALANCE_MONTH',
      description: 'LIQUID_VALUE',
      color: 'white',
      value: monthExtract?.month_balance || 0,
      balance: monthExtract?.month_balance >= 0 ? 'successDark' : 'dangerCard',
      background: monthExtract?.month_balance >= 0 ? 'success' : 'danger',
      info: 'lancamentos',
      infoText: 'Total de agendamentos recebidos até o momento + total de receitas lançadas - total de despesas',
    },
    //total de entradas receitas
    //total de saidas despesas
    //previsao de saldo
    //lançamentos receitas
    //Total de comissoes
  ]
}

export const settingsClients = (monthExtract) => {
  return [
    {
      label: 'TOTAL_CLIENTS',
      description: 'ATTENDED',
      value: monthExtract?.total_clients || 0,
    },
    {
      label: 'TOTAL',
      description: 'NEW_CLIENTS',
      value: monthExtract?.new_clients || 0,
      last: true,
    },
  ]
}

export const secondarySettingsCards = (monthExtract) => {
  return [
    // agendamento recebido ate o momento
    {
      label: 'APPOINTMENTS_TOTAL',
      color: 'success',
      value: monthExtract?.appointments_paid_total_after_discounts || 0,
      description: 'RECEIVED_UNTIL_NOW',
    },
    //previsao fat bruto
    {
      label: 'Previsão de faturamento bruto no período',
      color: 'success',
      value: monthExtract?.appointments_total_prevision || 0,
      description: 'GROSS_REVENUE',
    },
    //total discontos ate o momento
  ]
}

export const cardsMobile = (monthExtract) => {
  return [
    // agendamento recebido ate o momento
    {
      label: 'SELLS',
      color: 'success',
      value: monthExtract?.appointments_paid_total_after_discounts || 0,
      description: 'RECEIVED_UNTIL_NOW',
    },
    //lançamentos receitas
    {
      label: 'RELEASES',
      color: 'success',
      value: monthExtract?.revenues_total || 0,
      description: 'REVENUES',
      info: 'lancamentos',
    },
    //previsao fat bruto
    {
      label: 'PREVISION',
      color: 'success',
      value: monthExtract?.entries_month_total_prevision || 0,
      description: 'GROSS_REVENUE',
    },
    //descontos met pagamento
    {
      label: 'DISCOUNTS',
      color: 'primary',
      value: -monthExtract?.appointment_payments_methods_discounts || 0,
      description: 'PAYMENT_METHODS',
      last: true,
    },
    //lancamentos despesas
    {
      label: 'RELEASES',
      color: 'danger',
      value: -monthExtract?.expenses_total || 0,
      description: 'EXPENSES_TITLE',
      info: 'lancamentos',
      last: true,
    },
    //outros descontos
    {
      label: 'DISCOUNTS',
      color: 'primary',
      value: -monthExtract?.appointment_others_discounts || 0,
      description: 'OTHER_DISCOUNTS',
      last: true,
    },
  ]
}
