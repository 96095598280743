import { Box, Flex } from '@chakra-ui/react'
import { Avatar, Text } from 'components/atoms'
import { formatPrice } from 'utils'

export const columns = [
  {
    key: 'barber',
    label: 'COLLABORATOR',
    render: (item) => {
      return (
        <Flex flexDir="row" align="center">
          <Avatar size="medium" src={item?.profile_image_url} mr="16px" />
          <Box>
            <Text>{item?.name}</Text>
          </Box>
        </Flex>
      )
    },
  },
  {
    key: 'total_value',
    label: 'TOTAL_SALES_TABLE',
    render: (item) => <Text>{formatPrice(item?.total)}</Text>,
  },
  {
    key: 'min_goal',
    label: 'META MINIMA PREVISTA',
    render: (item) => <Text>{formatPrice(item?.min)}</Text>,
  },
  {
    key: 'max_goal',
    label: 'META MAXIMA PREVISTA',
    render: (item) => <Text>{formatPrice(item?.max)}</Text>,
  },
]
