import React, { useEffect, useState } from 'react'
import { useToast, useTheme } from '@chakra-ui/react'
import { Layout, ClientsModal, Table, TextInput, Box, Flex, Button, Icon } from 'components'
import { Search } from 'assets/icons'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { ClientDetailModal } from 'components'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { useCustomerStore } from 'services/stores'
import { SendAdviceModal } from 'components'
import { usePermissionCollaborator } from 'services/hooks'
import { useUserType } from 'services/hooks/use-user-type'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useQuery } from 'react-query'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

const desktopColumns = [
  { key: 'image', label: '', type: 'image', placeholder: 'client' },
  { key: 'name', label: 'NAME_TABLE' },
  { key: 'email', label: 'EMAIL_TABLE' },
  { key: 'phone', label: 'PHONE_TABLE' },
]

const mobileColumns = [
  { key: 'image', label: '', type: 'image', placeholder: 'client' },
  { key: 'name', label: 'NAME_TABLE' },
  { key: 'phone', label: 'PHONE_TABLE' },
]

export const ClientsScreen = ({ ...props }) => {
  const [isAddCustomerModalOpen, setAddCustomerModal] = useState(false)
  const [isSendAdviceModalOpen, setSendAdviceModalOpen] = useState(false)
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const { setSelectedCustomer } = useCustomerStore()
  const [clients, setClients] = useState([])
  const [reloadClientList, setReloadClientsList] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true)
  const [query, setQuery] = useState('')
  const [skip, setSkip] = useState(0)
  const { t } = useTranslation()
  const { getClients } = useBarbershop()

  const permission = usePermissionCollaborator('clients')
  const type = useUserType()

  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const toast = useToast()

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const { isLoading, refetch } = useQuery(
    ['get-clients', query, reloadClientList, skip],
    () => getClients({ id: userData?.id, skip, query }),
    {
      onSuccess: (data) => {
        let res = [
          ...data?.map((item) => ({
            client_id: item.client.id,
            name: item.client.name,
            image: item.client.profile_image_url,
            phone: item.client.user.phone,
            email: item.client.user.email,
          })),
        ]
        if (skip !== 0 && query?.length % 2 === 0) {
          res = [...clients, ...res]
        }
        setClients(res)
        setRefreshing(false)
      },
    }
  )

  const onSearch = (text) => {
    if (text.length === 0) {
      setRefreshing(false)
      setSkip(0)
      setShowLoadMoreButton(true)
      return
    }
    if (text.length % 2 === 0) {
      setShowLoadMoreButton(false)
    }
  }

  const fetchMoreItems = () => {
    setRefreshing(true)
    setSkip(skip + 20)
  }

  const handleSelectCustomer = (customer) => {
    if (permission !== 'blocked') {
      setSelectedCustomer(customer)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const sendMessageMobile = () => (
    <Flex w="100%" h={theme.pxToRem(35)} bg="primary" align="center" justify="center" borderRadius={theme.pxToRem(8)}>
      <Icon name="NotificationSmall" />
    </Flex>
  )

  return (
    <Layout
      {...props}
      headerTitle={t('CLIENTS_TITLE')}
      headerCenterElement={() => !isDesktop && permission === 'edit' && sendMessageMobile()}
      modalFilter
      modalFilterWidth="12%"
      headerActionText={permission === 'edit' ? t('CLIENTS_ACTION_BUTTON') : null}
      iconSecondActionText={'NotificationSmall'}
      secondActionText={t('SEND_NOTIFICATION')}
      secondActionClick={permission === 'edit' ? () => setSendAdviceModalOpen(true) : null}
      headerActionClick={() => setAddCustomerModal(true)}
    >
      <Box flex={1}>
        <TextInput
          noBorder
          type="text"
          width="full"
          color="white"
          placeholder={t('SEARCH_CLIENT')}
          backgroundColor="menuBackground"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value)
            onSearch(event.target.value)
          }}
          leftElement={<Search color={colors.primary} />}
        />

        <Table
          isSelectableRow={isDesktop}
          data={clients || []}
          loading={!refreshing && isLoading}
          columns={isDesktop ? desktopColumns : mobileColumns}
          onSelect={handleSelectCustomer}
        />

        <Flex width="full" justifyContent="center" paddingY={theme.pxToRem(32)}>
          {!isLoading && showLoadMoreButton && clients?.length >= 20 && (
            <Button isLoading={refreshing} onClick={fetchMoreItems}>
              {t('LOAD_MORE')}
            </Button>
          )}
        </Flex>

        <ClientsModal
          isModalOpen={isAddCustomerModalOpen}
          onClose={() => {
            setAddCustomerModal(false)
            refetch()
          }}
          reloadClientsList={setReloadClientsList}
        />

        <SendAdviceModal
          isModalOpen={isSendAdviceModalOpen}
          onClose={() => {
            setSendAdviceModalOpen(false)
          }}
        />

        <ClientDetailModal
          isModalOpen={isCustomerDetailModalOpen}
          onClose={() => {
            setCustomerDetailModal(false)
            refetch()
          }}
          reloadClientsList={setReloadClientsList}
        />
      </Box>
    </Layout>
  )
}

ClientsScreen.path = '/clientes'
ClientsScreen.title = 'Clientes'
ClientsScreen.secured = true
