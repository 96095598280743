import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useToast } from '@chakra-ui/react'
import { Box, TextInput, Button, Flex } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { Text } from 'components/atoms/Text'
import { colors } from 'styles'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { ASSOCIATE_BARBERSHOP_AND_BARBER } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'

import { api } from 'services/api/config'
import { useMutation, useQueryClient } from 'react-query'

export const AssociateBarbershopAndBarberModal = ({
  isModalOpen,
  onClose,
  setBarberAssociate,
}) => {
  const toast = useToast()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { user, userData } = useBarbershopStore()

  async function associateBarbershopBarber(params) {
    const res = await api
      .post(ASSOCIATE_BARBERSHOP_AND_BARBER, params.params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'associate-barber',
    (params) => associateBarbershopBarber(params),
    {
      onSuccess: async (value) => {
        user.type = 'barbershopAndBarber'
        toast({
          title: t('BARBER_PROFILE'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        queryClient.invalidateQueries('get-team')
        onClose()
      },
      onError: (error) => {
        toast({
          title: error.toString().substring(7),
          status: 'danger',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Nome deve ter ao menos 3 caracteres')
        .required('Nome é obrigatório'),
    }),
    onSubmit: () =>
      mutate.mutate({
        params: {
          ...values,
          barbershop_id: userData?.id,
        },
      }),
  })

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={onClose}
      title="Associar conta à Barbeiro"
    >
      <Text mb={pxToRem(24)}>
        Os clientes poderão agendar horários com você e seu acesso continuará o
        mesmo, mas você poderá logar como Barbearia ou Barbeiro.
      </Text>
      <Box marginBottom={pxToRem(36)}>
        <Text
          fontSize={pxToRem(12)}
          marginBottom={pxToRem(16)}
          textTransform="uppercase"
          fontWeight="600"
        >
          Nome do Barbeiro
        </Text>
        <TextInput
          type="text"
          name="name"
          id="name"
          placeholder="Ex: José da Silva"
          color={colors.textLight}
          disabled={mutate.isLoading}
          error={touched.name && errors.name}
          onChange={handleChange}
        />
      </Box>

      <Flex marginTop="auto">
        <Button
          w="100%"
          type="submit"
          onClick={handleSubmit}
          isLoading={mutate.isLoading}
        >
          Salvar
        </Button>
      </Flex>
    </ModalMenu>
  )
}
