export const en = {
  finished: 'Finished',
  awaiting: 'Awaiting',
  confirmed: 'Confirmed',
  client_missed: 'Client missed',
  reopened: 'Reopened',
  pending: 'Pending',
  paid: 'Paid',
  // GENERAL
  OPEN_LINK: 'Open link',
  ERROR_LOADING_DATA: 'Error fetching data',
  ERROR_SAVE_DATA: 'Error saving data',

  // LOGIN
  ACCESS_ACCOUNT: 'Access your account',
  MANAGE_YOUR_BARBERSHOP: 'Manage your barbershop easily and quickly',
  EMAIL_PLACEHOLDER: 'E-mail or Telephone',
  PASSWORD_PLACEHOLDER: 'Your password',
  FORGOT_PASSWORD: 'Forgot my password',
  LOGIN_BUTTON: 'Log In',
  SIGNUP1: `Don't have an account yet?`,
  SIGNUP2: 'Sign Up',
  USER_NOT_AUTH: 'User not authorized to use this environment.',
  USER_NOT_AUTH_DESC: 'The user must be a barbershop.',
  LOGIN_ERROR: 'Failed to login',
  INVALID_USERNAME: 'User must contain at least 8 characters',
  USERNAME_REQUIRED: 'User is required',
  REGISTER_ERROR: 'Failed to register account. Please try again.',

  // FORGOT PASSWORD
  REDEFINE_PASSWORD_TEXT: 'Want to redefine your password?',
  ADD_EMAIL: 'Add your email',
  ADD_EMAIL_DESC: 'Register or update your email to set a new password.',
  RESET_PASSWORD: 'Redefine password',
  RESET_PASSWORD_SUCCESS: 'Password reset successfully!',
  RESET_PASSWORD_SUCCESS_DESC:
    'Download our app, and schedule your service anytime!',
  RESET_PASSWORD_DESC: 'Forgot your password? Do not worry!',
  ENTER_THE_EMAIL:
    'Inform the e-mail used in your registration and we will help you set a new password.',
  ENTER_THE_PHONE:
    'Inform the cellphone used in your registration and we will help you set a new password.',
  REDEFINE_PASSWORD_BUTTON: 'Redefine Password',
  CANCEL: 'Cancel',
  INVALID_TOKEN: 'Invalid token',
  INVALID_PHONE: 'Cellphone must contain at least 14 numbers',

  // LOGIN NOTIFICATION
  SUCCESS: 'Success!',
  PASSWORD_REDEFINED: () => (
    <>
      Your new password has been registered and can now be used <br />
      to access BestBarbers. <br />
      Enjoy!
    </>
  ),
  BACK: 'Back',

  // LOGIN SETTINGS
  EMAIL_SENT: 'Email sent!',
  EMAIL_SENT_DESC: (email) => (
    <>
      We sent an email to
      <br />
      {email}
      <br />
      containing the necessary steps to recover your account password.
    </>
  ),
  USER_NOT_FOUND: 'User not found!',
  USER_NOT_FOUND_DESC: (email) => (
    <>
      The e-mail
      <br />
      {email}
      <br />
      was not found in our
      <br />
      database. Check the data and
      <br />
      try again.
    </>
  ),
  SMS_SENT: 'Message sent!',
  SMS_SENT_DESC: (phone) => (
    <>
      We sent a message to
      <br />
      {phone}
      <br />
      containing the necessary steps to recover your account password.
    </>
  ),
  PHONE_NOT_FOUND: (phone) => (
    <>
      The phone
      <br />
      {phone}
      <br />
      was not found in our
      <br />
      database. Check the data and
      <br />
      try again.
    </>
  ),

  // CHANGE PASSWORD
  NEW_PASSWORD: 'Change Password',
  ENTER_TOKEN_FIRST: 'Enter the token sent to ',
  ENTER_TOKEN_LAST: ' and the new password desired.',
  CHANGE_PASSWORD_BUTTON: 'Change password',
  PASSWORD_CHANGED: 'Password changed successfully!',
  PASSWORD_NOT_CHANGED: 'Failed to change password!',
  TOKEN_REQUIRED: 'Token is required',

  // LOGIN AUTH FACTOR
  VERIFICATION_CODE: 'Verification code',
  ENTER_VERIFICATION_CODE:
    'Please enter the code we sent to your registered phone',
  RESEND_CODE: 'Resend verification code',

  // REGISTER

  // /cadastro
  CONTROL_YOUR_BARBERSHOP: 'Manage your barbershop in the palm of your hand.',
  BESTBARBERS_HELPS_YOU:
    'BestBarbers helps you manage your entire barbershop, from scheduling to finance. For that, we provide you on the platform:',
  ADMINISTRATION: 'Administration, history and scheduling.',
  PROFILE_MANAGEMENT:
    'Profile management (description, opening hours, location, space photos).',
  TEAM_MANAGEMENT: 'Barber team management.',
  SERVICES_MANAGEMENT: 'Creation and editing of services provided.',
  FINANCIAL_MANAGEMENT:
    'Complete financial management (monthly balance sheet, cash flow, expense and income management).',
  CLIENTS_MANAGEMENT: 'Client management.',
  APPOINTMENT_REMINDER:
    'Appointment reminder for your customers not to miss time.',
  MONTHLY_REPORTS: 'Monthly reports of barbers, services and commissions. ',
  EXCLUSIVE_ACCESS: 'Exclusive access for each barber.',
  CREATE_ACCOUNT: 'Create Account',
  START_NOW: 'Start managing your barbershop in an easy and practical way.',
  BARBERSHOP_NAME: 'Barbershop name',
  PHONE: 'Cellphone',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm your password',
  CONTINUE_BUTTON: 'Continue',
  ALREADY_REGISTERED: 'Already registered?',
  LOG_IN: 'Log In',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTION_TEXT:
    'BestBarbers does not limit the number of employees or scheduling. We only charge the monthly fee and you have unlimited access.',
  SUBSCRIPTION_FEE: 'BestBarbers Subscription',
  SUBSCRIPTION_FEE_TEXT: 'Monthly fee',
  FREE_TRIAL: '7-days free trial',
  CONTACT: 'Contact',
  TERMS: 'Terms of Use',
  PRIVACY: 'Privacy Policy',

  // /cadastro/barbershop-intro
  BARBERSHOP_INTRO_TITLE: "Let's set up your barbershop!",
  BARBERSHOP_INTRO_DESC:
    'Before proceeding, we need some important information such as office hours, services offered, photos and staff members. This information will make your barber shop profile gain more views and attract more customers.',
  BEGIN: 'Begin',

  // /cadastro/perfil-barbearia
  YOUR_BARBERSHOP: 'Your Barbershop',
  SHOW_WHO_YOU_ARE: 'Show your customers who you are.',
  BARBERSHOP_PHONE: "Barbershop's contact phone number",
  BARBERSHOP_DESCRIPTION: 'Barbershop description',
  BARBERSHOP_DESCRIPTION_PLACEHOLDER:
    'Describe here your services, differentials...',

  // /cadastro/localizacao
  LOCATION: 'Location',
  SET_UP_LOCATION: 'Set up your address for customers to find you.',
  ZIP_CODE_PLACEHOLDER: 'ZIP CODE',
  STATE: 'State',
  CITY: 'City',
  ADDRESS: 'Address',
  NUMBER: 'Number',
  NEIGHBORHOOD: 'Neighborhood',
  LOCATION_TOAST: 'Address updated successfully!',
  LOCATION_TOAST_ERROR: 'Failed to update address',
  SEARCH_ADDRESS: 'Search address',
  LOADING: 'Loading...',
  GOOGLE_MAPS_ERROR: 'Failed to load Google Maps API',

  // /cadastro/horario-funcionamento'
  WORKING_HOURS: 'Operating Hours',
  WORKING_HOURS_SUBTITLE:
    'We will make appointments available according to your hours of operation.',
  OPENING_TIME: 'Opening time',
  CLOSING_TIME: 'Closing time',
  CLOSED_DAY: 'Define as closed day',
  UPDATE_WORKING_HOURS: `Operating hours successfully updated!`,
  UPDATE_WORKING_HOURS_ERROR: 'Failed to update operating hours',

  // /cadastro/intervalo-agenda
  AGENDA_INTERVAL: 'Agenda Interval',
  AGENDA_INTERVAL_SUBTITLE:
    'Select the time interval between your schedule times',
  MINUTES: 'minutes',
  YOUR_AGENDA_INTERVAL: 'Your schedule will look like this',
  YOUR_AGENDA_INTERVAL_DESC:
    'Change the range above to see how your schedule times will be affected',
  AGENDA_INTERVAL_SAVED: 'Schedule interval successfully saved!',
  AGENDA_INTERVAL_SAVE_FAIL: 'Fail to save schedule interval!',

  // /cadastro/fotos-barbearia
  PHOTOS: 'Photos',
  PHOTOS_DESC:
    'Post photos of your barbershop so customers can get to know your establishment better.',
  PHOTO_LABEL: 'Photo',
  PHOTOS_TOAST: 'Photos updated successfully!',

  // /cadastro/servicos-barbearia
  SERVICES: 'Services',
  SERVICES_DESC:
    'Add the services your barbershop provides so customers can make appointments',
  SERVICE_NAME: 'Name',
  SERVICE_NAME_PLACEHOLDER: 'Service name',
  TIME: 'Time',
  TIME_PLACEHOLDER: 'hour:minute',
  PRICE: 'Price',
  SERVICES_LOAD_ERROR: 'Failed to load services',
  ADD_SERVICE: 'Service successfully added!',
  ADD_SERVICE_ERROR: 'Failed to save service',
  REMOVE_SERVICE: `Service removed successfully!`,
  REMOVE_SERVICE_ERROR: 'Failed to remove service!',

  // /cadastro/equipe
  TEAM: 'Team',
  TEAM_DESC:
    'Send invitations to your barber team or add a barber profile to this account.',
  BARBER_BUTTON: `I'm a barber`,
  BARBER_NAME: 'Name',
  INVITES: 'Invites',
  BARBERS: 'Barbers',
  DELETE_INVITE: 'Invite deleted!',
  BARBER_DELETED: 'Barber deleted!',
  INVITE: 'Invite sent!',
  FINISH_CONFIGURATION: 'Complete setup',

  // /cadastro-concluido
  REGISTER_COMPLETED: 'Registration Completed!',
  PROFILE_COMPLETED: 'Your barbershop profile is complete!',
  USE_APP: 'Now you can use BesBarbers to manage your appointments!',
  PUBLISH_SUCCESS: 'Barbershop published successfully!',
  PUBLISH_ERROR: 'Failed to publish barbershop.',

  // /checkout
  CREATE_MEMBERSHIP_SUCCESS: 'Subscription created successfully!',
  CREATE_MEMBERSHIP_ERROR: 'Failed to create subscription.',
  ADD_PAYMENT_CARD: 'Add payment card',
  ADD_PAYMENT_CARD_DETAILS:
    'To proceed with your subscription, please enter your card information below.',
  SAFE_BUY_1: 'Safe',
  SAFE_BUY_2: 'Buy',
  SATISFACTION_GUARANTEED_1: 'Satisfaction',
  SATISFACTION_GUARANTEED_2: 'Guaranteed',
  PRIVACY_PROTECTED_1: 'Privacy',
  PRIVACY_PROTECTED_2: 'Protected',
  CARD_NUMBER: 'Card Number',
  CARD_NUMBER_PLACEHOLDER: '0000 0000 0000 0000',
  VERIFICATION_CODE_PLACEHOLDER: '000',
  SURNAME: 'Surname',
  EXPIRATION_MONTH: 'Expiration Month',
  EXPIRATION_YEAR: 'Expiration Year',
  SUBSCRIBE: 'Subscribe',

  // TABLE
  NAME_TABLE: 'Name',
  EMAIL_TABLE: 'EMAIL',
  PHONE_TABLE: 'PHONE',
  TIME_TABLE: 'TIME',
  CLIENT_TABLE: 'CUSTOMER',
  BARBER_TABLE: 'BARBER',
  SERVICE_TABLE: 'SERVICE',
  PAYMENT_TABLE: 'PAYMENT',
  VALUE_TABLE: 'VALUE',
  STATUS_TABLE: 'STATUS',
  DATE_TABLE: 'DATE',
  DESCRIPTION_TABLE: 'DESCRIPTION',
  CATEGORY_TABLE: 'CATEGORY',
  TOTAL_VALUE_TABLE: 'TOTAL VALUE',
  RECEIVED_VALUE_TABLE: 'RECEIVED VALUE',
  APPOINTMENTS_TABLE: 'APPOINTMENTS',
  COMMISSION_TABLE: '% COMMISSION',
  TOTAL_SALES_TABLE: 'SALES TOTAL',
  TOTAL_COMMISSION_TABLE: 'COMMISSIONS TOTAL',
  GROSS_VALUE_TABLE: 'GROSS VALUE',
  NET_VALUE_TABLE: 'NET VALUE',
  COMMISSION_VALUE_TABLE: 'COMMISSION VALUE',
  PROMOTIONAL_TABLE: 'PROMOTIONAL',
  PROMOTIONAL_VALUE_TABLE: 'Promotional Value',
  RATING_TABLE: 'Rating',

  // LOCALE
  LOCALE: 'en',

  // HISTORIC SCREEN
  HISTORIC_TITLE: 'History',
  LOAD_HISTORIC_ERROR: 'Failed to load appointments!',

  // HISTORY LIST COMPONENT
  EMPTY_LIST_MESSAGE: 'No data found.',

  // SELECT DATE MODAL
  SELECT_DATE_MODAL_TITLE: 'Select Date',

  // CLIENTS SCREEN
  LOAD_CLIENTS_ERROR: 'Failed to load customers.',
  SEARCH_CLIENT_ERROR: 'Failed to search customers.',
  CLIENTS_TITLE: 'Customers',
  CLIENTS_ACTION_BUTTON: 'Add Customers',
  SEARCH_CLIENT: 'Search for client',
  LOAD_MORE: 'Load more',

  // ADD CLIENTS MODAL
  REGISTER_CLIENT: `Customer added successfully!`,
  REGISTER_CLIENT_ERROR: 'Failed to add customer.',
  ADD_CLIENT_TITLE: 'Add Customer',
  CLIENT_NAME: 'Name',
  CLIENT_NAME_PLACEHOLDER: `Customer's name`,
  CLIENT_PHONE_PLACEHOLDER: 'Cellphone',
  CLIENT_EMAIL: 'E-mail (optional)',
  REGISTER_CLIENT_BUTTON: 'Add customer',

  // EDIT CLIENTS MODAL
  EDIT_CLIENT: 'Edit customer',
  EDIT_CLIENT_SUCCESS: 'Customer edited successfully!',
  EDIT_CLIENT_ERROR: 'Failed to edit customer.',
  BIRTH_DATE: 'Birth date',

  // CLIENT DETAILS MODAL
  DELETE_CLIENT: 'Customer deleted successfully!',
  DELETE_CLIENT_ERROR: 'Failed to delete customer.',
  DELETE_CLIENT_BUTTON: 'Delete Customer',
  DELETE_CLIENT_MODAL_TITLE: 'Delete Customer',
  DELETE_CLIENT_MODAL: 'Do you want to delete customer ',
  DELETE_CLIENT_CONFIRM: 'Delete',
  DELETE_CLIENT_WARNING:
    'This action is irreversible and will erase all customer data related to your barbershop, such as amounts received and service history.',
  WHATSAPP_BLOCKED:
    'Calling customers on WhatsApp is exclusive to Premium subscribers',

  // FINANCIAL SCREEN
  FINANCIAL_TITLE: 'Financial',
  LOAD_FINANCIAL_DATA_ERROR: 'Failed to load financial data.',
  ADD_RELEASE: 'Add Release',
  APPOINTMENTS_TOTAL: 'Appointments',
  APPOINTMENTS_TOTAL_DESC: 'Total',
  APPOINTMENTS_RECEIVED: 'Appointments',
  APPOINTMENTS_RECEIVED_DESC: 'Received',
  EXPENSES: 'Expenses',
  EXPENSES_DESC: 'Posted',
  PROFITS: 'Profits',
  PROFITS_DESC: 'Posted',
  MONTH_PROJECTION: 'Month Projection',
  MONTH_PROJECTION_DESC: 'Month Projection',
  LATE_PAYMENT: 'Late',
  PENDING_PAYMENT: 'Pending',
  PAID_PAYMENT: 'Paid',
  ALL: 'All',

  // FINANCE RELEASE MODAL
  LOAD_CATEGORIES_ERROR: 'Failed to load categories.',
  DELETE_RELEASE: `Release deleted successfully!`,
  DELETE_RELEASE_ERROR: `Failed to delete release!`,
  ADD_RELEASE_SUCCESS: `Release created successfully!`,
  EDIT_RELEASE_SUCCESS: `Release updated successfully!`,
  ADD_RELEASE_ERROR: `Failed to create release!`,
  EDIT_RELEASE_ERROR: `Failed to update release!`,
  EDIT_RELEASE: 'Edit Release',
  RELEASE_TYPE: 'Release type',
  PROFIT: 'Profit',
  EXPENSE: 'Expense',
  EX_REVENUE: 'Eg: Products sale',
  EX_NOT_REVENUE: 'Eg: Energy bill',
  title: 'Title',
  VALUE: 'Value',
  DUE_DATE: 'Due Date',
  CATEGORY: 'Category',
  CATEGORY_SHOP: 'Infrastructure',
  CATEGORY_PEOPLE: 'Employees',
  CATEGORY_MONEY: 'Miscellaneous payments',
  CATEGORY_STOCKS: 'Material',
  CHOOSE_RELEASE_STATUS: 'What is the release status?',
  SAVE: 'Save',
  DELETE: 'Delete',
  UPDATE: 'Update',
  DELETE_RELEASE_TITLE: 'Delete Release',
  CONFIRM_DELETE_RELEASE: 'Do you want to delete release ',
  DISCOUNT_BARBER_TITLE: 'Is this percentage discounted from barbers?',
  DISCOUNT_BARBER_SUBTITLE:
    "If the barber's commission is calculated after discounting the payment percentage from the total amount of the service, check yes.",

  // APPOINTMENT FINANCE MODAL
  APPOINTMENT_FINANCE_MODAL_TITLE: "Appointment's Financial Details",
  ADDITIONAL_PRODUCTS: 'Products',
  FINANCIAL_DESCRIPTIVE: 'Financial Descriptive',
  PAYMENT_METHOD: 'Payment method',
  COMMISSION: 'Commission',
  NET_TOTAL: `Net value`,
  DISCOUNT_FROM_BARBER: 'Take it out of the barber shop',

  // PAYMENT METHODS SCREEN
  PAYMENT_METHODS: 'Payment Methods',
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  LOAD_PAYMENT_METHODS_ERROR: 'Failed to load payment methods.',
  PERCENTAGE: 'Percentage',
  DUE_IN_DAYS: 'Receipt (In days)',
  PAYMENT_METHODS_BLOCK_MESSAGE_HEADER: 'Limit to 2 in the free version',
  PAYMENT_METHODS_BLOCK_MESSAGE:
    'Add as many payment methods as you like in the premium version!',

  // PAYMENT METHOD MODAL
  DELETE_PAYMENT_METHOD_SUCCESS: `Payment method successfully deleted!`,
  DELETE_PAYMENT_METHOD_ERROR: `Failed to delete payment method!`,
  SAVE_PAYMENT_METHOD: `Payment method successfully saved!`,
  SAVE_PAYMENT_METHOD_ERROR: 'Failed to save payment method.',
  CREATE_PAYMENT_METHOD: 'Add payment method',
  UPDATE_PAYMENT_METHOD: 'Update payment method',
  PAYMENT_METHOD_EXAMPLE: 'Eg: Visa - Credit',
  PERCENTAGE_EXAMPLE: 'Eg: 30%',
  DUE_IN_DAYS_EXAMPLE: 'Eg: 0 (same day) or 30 (30 days from now)',
  DELETE_PAYMENT_METHOD: 'Delete Payment Method',
  CONFIRM_DELETE_PAYMENT_METHOD: 'Do you want to delete payment method',
  // PAYMENT METHOD CARD
  DAYS: 'days',

  // COMMISSIONS SCREEN
  LOAD_COMMISSIONS_ERROR: 'Failed to load commissions data.',
  COMMISIONS_SCREEN_TITLE: 'Commisions Report',
  APPOINTMENTS_COUNT: 'Appointments Total',
  APPOINTMENTS_COUNT_DESC:
    'Amount referring to appointments to be paid in that month.',
  TOTAL_SALES_DESC: 'Total amount billed for appointments.',
  TOTAL_COMMISSION_DESC: 'Sum of the value of all commissions.',
  SALES: 'Sales',
  COMMISSIONS: 'Commissions',

  // COMMISSIONS REPORT DETAILS SCREEN
  COMMISSION_REPORT_DETAILS_TITLE: 'Report:',
  GROSS_VALUE: 'Total in Sales (gross)',
  GROSS_VALUE_DESC: 'Gross total amount of appointments paid this month.',
  NET_VALUE: 'Total in Sales (net)',
  NET_VALUE_DESC:
    'Total amount of appointments paid this month, discounting fees and charges.',
  COMMISSIONS_TOTAL: 'Total in Commissions',
  COMMISSIONS_TOTAL_DESC: 'Total amount of commission paid this month.',

  // YUP
  PERCENTAGE_REQUIRED: 'Percentage is required.',
  DUE_IN_DAYS_REQUIRED: 'Due in days is required.',

  TITLE: 'Title must contain at least 3 characters',
  TITLE_REQUIRED: 'Title is required',
  DESCRIPTION: 'Description must contain more than 10 characters',
  DESCRIPTION_REQUIRED: 'Description is required',

  INCOMPLETE_CEP: 'Incomplete ZIP code',
  CEP_REQUIRED: 'ZIP code is required',
  STATE_REQUIRED: 'State is required',
  CITY_REQUIRED: 'City is required',
  STREET_REQUIRED: 'Address is required',
  STREET_NUMBER_REQUIRED: 'Street number is required',
  INVALID_CPF: 'Invalid ID!',
  CPF_REQUIRED: 'ID is required!',

  SERVICE: 'Service must contain at least 4 characters',
  SERVICE_REQUIRED: 'Service is required',
  TIME_REQUIRED: 'Time is required',
  INVALID_TIME: 'Invalid time interval',
  PRICE_REQUIRED: 'Service price is required',

  INVALID_EMAIL: 'Invalid e-mail',
  EMAIL_REQUIRED: 'E-mail is required!',
  INVALID_CELLPHONE: 'Invalid cellphone',
  CELLPHONE_REQUIRED: 'Cellphone is required!',
  NAME: 'Name must contain at least 3 characters',
  NAME_REQUIRED: 'Name is required',
  PASSWORD: 'Password must contain at least 6 characters',
  PASSWORD_REQUIRED: 'Password is required',
  CONFIRM_PASSWORD: 'Passwords must match',

  DUE_DATE_REQUIRED: 'Due date is required',
  RELEASE_VALUE_REQUIRED: 'Release value is required',
  CATEGORY_REQUIRED: `Category is required`,

  INVALID_CARD: 'Invalid card',
  CARD_REQUIRED: 'Card number is required',
  INVALID_VERIFICATION_CODE: 'Invalid verification code',
  VERIFICATION_CODE_REQUIRED: 'Verification code is required',
  INVALID_EXPIRATION_MONTH: 'Invalid expiration month!',
  EXPIRATION_MONTH_REQUIRED: 'Expiration month is required',
  INVALID_EXPIRATION_YEAR: 'Invalid expiration year',
  EXPIRATION_YEAR_REQUIRED: 'Expiration year is required',

  // TOASTS

  // success
  UPDATED_DATA: 'Data successfully updated',

  // error
  REVIEW_INPUTS: 'Please review the data entered',

  // DAYS OF THE WEEK
  MONDAY: 'Mon',
  MONDAY_DESC: 'Monday',
  MONDAY_LETTER: 'M',

  TUESDAY: 'Tue',
  TUESDAY_DESC: 'Tuesday',
  TUESDAY_LETTER: 'T',

  WEDNESDAY: 'Wed',
  WEDNESDAY_DESC: 'Wednesday',
  WEDNESDAY_LETTER: 'W',

  THURSDAY: 'Thu',
  THURSDAY_DESC: 'Thursday',
  THURSDAY_LETTER: 'T',

  FRIDAY: 'Fri',
  FRIDAY_DESC: 'Friday',
  FRIDAY_LETTER: 'F',

  SATURDAY: 'Sat',
  SATURDAY_DESC: 'Saturday',
  SATURDAY_LETTER: 'S',

  SUNDAY: 'Sun',
  SUNDAY_DESC: 'Sunday',
  SUNDAY_LETTER: 'S',

  MONDAY_DESC_DATE: 'Every monday',

  TUESDAY_DESC_DATE: 'Every tuesday',

  WEDNESDAY_DESC_DATE: 'Every wednesday',

  THURSDAY_DESC_DATE: 'Every thursday',

  FRIDAY_DESC_DATE: 'Every friday',

  SATURDAY_DESC_DATE: 'Every saturday',

  SUNDAY_DESC_DATE: 'Every sunday',

  // CURRENCY
  CURRENCY: 'U$',

  //APPOINTMENTS
  APPOINTMENTS: 'Appointments',
  NEW_APPOINTMENTS: 'New appointments',
  APPOINTMENT_ERROR: 'There was an error loading the schedules!',
  BARBERSHOP_CANNOT_SCHEDULE: 'Your barber shop cannot make appointments',
  VERIFY_STATUS_TRY_AGAIN: 'Check the status and try again.',
  SELECT_DATE: 'Select date',
  NEW_APPOINTMENT: 'New appointment',
  SELECT_CUSTOMER: 'Select customer',
  SELECT_BARBER: 'Select barber',
  SELECT_SERVICE: 'Select service',
  SELECT_DATE_TIME: 'Select date and time',

  MORNING: 'Morning',
  EVENING: 'Evening',
  NIGHT: 'Night',

  DATE: 'Date',

  //APPOINTMENT MODAL
  CUSTOMER: 'Customer',
  APPOINTMENT_TYPE: 'Schedule type',
  STANDARD: 'Standard',
  FIT: 'Fit',
  BARBER: 'Barber',

  DATE_TIME: 'date and time',
  CONFIRM: 'Confirm',
  APPOINTMENT_CREATED_SUCCESSFULLY: 'Schedule created successfully!',
  TIME_START_END: 'Start and End times are required.',
  START_TIME_UNDER_END: 'Start time must be less than End time.',
  AVAILABLE_STEPS:
    'To show available times, select the Date, Service, and Barber.',
  NO_AVAILABLE_TIME: 'no available time in the period',

  AVAILABLE_TIME: 'Available times',
  START_TIME: 'Start time',
  END_TIME: 'End time',

  NAME_SIZE: 'Name must have at least 3 characters',

  PHONE_REQUIRED: 'Phone number is required',

  CUSTOMER_REGISTERED: 'REGISTERED CLIENT',
  CUSTOMER_NOT_REGISTERED: 'UNREGISTERED CLIENT',
  SEARCH_CUSTOMER: 'Search client',
  CUSTOMER_NAME: 'Client name',
  PHONE_NUMBER: 'Phone number',
  ADD_CUSTOMER: 'Add client',

  //APPOINTMENT DETAIL MODAL
  APPOINTMENT_DETAILS: 'Appointment details',
  SELECT_PAYMENT_METHOD: 'Select payment method',

  FINISHED_APPOINTMENT: 'Appointment successfully finished!',
  FINISH_APPOINTMENT_ERROR: 'Failed to finish appointment!',
  CANCELED_APPOINTMENT: 'Appointment canceled successfully!',
  CANCEL_APPOINTMENT_ERROR: 'Failed to cancel appointment!',

  PRODUCT_NAME_SIZE: 'Products name must have at least 3 characters',
  PRODUCT_NAME_REQUIRED: 'Products name is required',
  PRODUCT_PRICE_REQUIRED: 'Products value is required',
  PRODUCT_NAME: 'Product name',
  ADD_PRODUCT: 'Add product',
  ADD_PRODUCTS: 'Add products',
  DISCOUNT: 'Discount',
  ADD_DISCOUNT: 'Add discount',
  DISCOUNT_DESCRIPTION: 'Discount description',
  VALUES: 'Price',

  SUBMIT: 'Submit',

  EXCLUDE_APPOINTMENT_CUSTOMER: 'Do you want to delete the appointment with "',
  EXCLUDE_APPOINTMENT: 'Delete appointment',

  OPTIONAL_EMAIL: 'E-mail (optional)',

  ERROR_LOADING_TIMES: 'There was an error loading the times',

  RATING: 'RATING',
  LENGTH: 'LENGTH',

  // HOME (FreeTrialModal)
  FREE_TRIAL_MODAL_TITLE_FIRST_PART: 'Win ',
  FREE_TRIAL_MODAL_TITLE_SPAN: '7 trial days ',
  FREE_TRIAL_MODAL_TITLE_SECOND_PART: 'on us!',
  FREE_TRIAL_MODAL_SUBTITLE:
    'Get access to all the exclusive features and improve the service and management experience of your barbershop!',
  FREE_TRIAL_PERIOD: 'First 7 days free',
  AFTER_FREE_TRIAL: 'After that period ',
  AFTER_FREE_TRIAL_SPAN: 'U$ 69,00/month',
  MEMBERSHIP_BENEFITS: 'See subscription benefits',
  MEMBERSHIP_BENEFIT_1: 'Schedule management',
  MEMBERSHIP_BENEFIT_2: 'Unlimited number of barbers',
  MEMBERSHIP_BENEFIT_3: 'Creating personalized services',
  MEMBERSHIP_BENEFIT_4: 'Automated financial control',
  MEMBERSHIP_BENEFIT_5: 'Client management',
  MEMBERSHIP_BENEFIT_6: 'Exclusive access for each barber',
  MEMBERSHIP_BENEFIT_7: 'Appointment notification',
  START_FREE_TRIAL: 'Start my free trial',
  AUTOMATIC_RENOVATION: 'Automatic renovation. Cancel whenever you want.',
  SELECT_SOURCE: 'Scheduling source',

  // HOME (ConfirmFreeTrialModal)
  CONFIRM_FREE_TRIAL_MODAL_TITLE: 'Your free trial has started!',
  CONFIRM_FREE_TRIAL_MODAL_SUBTITLE:
    'We have sent an email with your subscription and billing information to the email:',
  ATENTION: 'Attention',
  REMEMBER_TO_PAY:
    "Remember to pay the subscription after the trial period so you don't lose access to the features!",
  OPEN_BILLING_EMAIL: 'Open the billing email',
  WE_WILL_SEND_EMAIL:
    'We will send you an email with information about your subscription and a link to pay for your subscription.',
  PAY_MEMBERSHIP: 'Pay the subscription',
  OPEN_THE_LINK:
    "Access the link inside the email and fill in your payment details. You can rest assured, it's safe!",
  ALRIGHT: 'All right!',
  USE_THE_APP:
    "If all goes well with your payment, you don't need to do anything else. Now just use the app!",

  // ExpiredFreeTrialModal
  EXPIRED_FREE_TRIAL_MODAL_TITLE: 'Your free trial period has ended.',
  EXPIRED_FREE_TRIAL_MODAL_SUBTITLE:
    'Subscribe to continue enjoying all the resources and facilities for your barbershop.',
  SUBSCRIBE_PRICE: 'Subscribe for U$ 69.00/month',

  //HOME (subscription banner)
  TRIAL_ENDING: 'Only ',
  TRIAL_ENDING_2: ' days of Free Trial left',
  TRIAL_ENDING_SUBTITLE:
    'Subscribe to Premium and get access to exclusive in-app tools.',
  TRIAL_ENDED: 'Your free trial has ended',
  TRIAL_ENDED_SUBTITLE:
    'Subscribe to continue enjoying all the resources and facilities for your barbershop.',
  SUBSCRIPTION_SUSPENDED: 'Your subscription has been suspended',
  SUBSCRIPTION_SUSPENDED_SUBTITLE:
    'To gain access again, contact our support by clicking here.',
  SUBSCRIPTION_PENDING_FEE: 'There is a problem in your subscription fee',
  SUBSCRIPTION_LINK:
    'There is a problem in your subscription. Please click on the link below to solve it:',
  REDIRECT: 'Redirect',
  PENDING_BILL: 'Pending bill',
  BILL_LINK:
    'Your bill is now available for payment. To complete it, click on the link below:',

  //HOME (stat card)
  INCOME: 'Income',
  MONTHLY_NEW_CLIENTS: 'New customers',
  THIS_MONTH: 'This month',

  SERVICES_DETAILS: 'Services details',

  //HOME (Month appointments chart)
  DAILY_INCOMING_CHART: 'Daily incoming chart',
  INCOMING: 'Incoming',

  //HOME (Month appointments products)
  SERVICES_REPORT: 'Services report',
  INCOME_TABLE: 'Income',
  QUANTITY: 'Quantity',

  // RATINGS SCREEN
  RATINGS: 'Ratings',

  // BARBERSHOP RATING
  AVERAGE_BARBERSHOP_RATING: 'Average barbershop rating',
  BASED_IN_30_DAYS: 'Based in the last 30 days',
  RECEIVED_RATINGS_TOTAL: 'Total ratings received',

  // SERVICES SCREEN
  LOAD_SERVICES_ERROR: 'Failed to load services.',
  SERVICES_TITLE: 'Services',
  ADD_SERVICE_BUTTON: 'Add Service',
  SELECT: 'Select',
  SERVICES_BLOCK_MESSAGE_HEADER: 'Limit to 4 in the free version',
  SERVICES_BLOCK_MESSAGE:
    'Add as many services as you like in the premium version!',

  // SERVICES MODAL
  SERVICE_CREATED_SUCCESS: 'Service created/edited successfully!',
  ADD: 'Add',
  EDIT_SERVICE: 'Edit',
  _SERVICE: 'Service',
  MAKE_PROMOTIONAL: 'Make it promotional',
  IN_PROMOTION_DAYS: 'Days in promotion',
  DELETE_SERVICE: 'Delete Service',
  DELETE_SERVICE_CONFIRM: 'Do you want to delete ',
  SERVICE_UPDATED: 'Service successfully updated!',
  SERVICE_ADDED: 'Service successfully added!',
  ERROR_SAVING_SERVICE: 'Error saving service',
  SERVICE_DELETED: 'Service successfully deleted!',
  ERROR_EXCLUDING_SERVICE: 'Error excluding service!',

  // TEAM SCREEN
  DELETE_BARBER_SUCCESS: 'Barber successfully deleted.',
  TEAM_TITLE: 'Team',
  ADD_TEAM: 'Add Team',
  SEARCH_BARBER: 'Search Collaborator',
  DELETE_BARBER: 'Delete Collaborator',
  DELETE_BARBER_CONFIRM: 'Do you want to delete ',
  TEAM_BLOCK_MESSAGE_HEADER: 'Limit to 2 barbers in the free version',
  TEAM_BLOCK_MESSAGE: 'Add as many barbers as you want in the premium version!',
  ADD_OTHER_FUNCTIONS: 'Add other functions in the premium version!',

  // BARBER CARD
  BARBER_PHONE: 'Phone',

  //TEAM MODAL
  ADD_BARBER_SUCCESS: 'Barber successfully added!',
  ADD_BARBER_ERROR: 'Failed to add barber!',
  ADD_BARBER: 'Add Collaborator',
  BARBER_NAME_PLACEHOLDER: "Barber's name",
  PHONE_PLACEHOLDER: 'Phone',
  BARBER_LIMIT:
    'You cannot add more barbers. Subscribe to the plan to get access to more features.',

  // EDIT TEAM
  EDIT_TEAM: 'Edit Collaborator',
  EDIT_TEAM_SUCCESS: 'Collaborator successfully edited!',
  EDIT_TEAM_ERROR: 'Failed to edit collaborator.',

  // BARBER MODAL
  EDIT_COMMISSION_ERROR: 'Failed to edit commission!',
  EDIT_COMMISSION_SUCCESS: 'Commission successfully edited!',
  RATINGS_AVERAGE: 'Ratings average',
  BARBER_COMMISSION_SERVICE: "Commission's services",
  BARBER_COMMISSION_PRODUCT: "Commission's products",
  BARBER_COMMISSION_SERVICE_TABLE: "Commission's services %",
  BARBER_COMMISSION_PRODUCT_TABLE: "Commission's products %",
  SEE_ALL_MESSAGES: 'See all',

  // ACTION MODAL
  DELETING: 'Deleting',

  // EDIT APPOINTMENT
  EDIT_APPOINTMENT: 'Edit Appointment',
  EDIT_APPOINTMENT_SUCCESS: 'Appointment successfully updated!',
  EDIT_APPOINTMENT_ERROR: 'Failed to update appointment!',
  //MENU (Status Modal)
  BARBERSHOP_STATUS: 'Barbershop status updated!',
  BARBERSHOP_STATUS_ERROR: 'Error. Status was not updated.',
  SET_STATUS: 'Set status',
  CHOOSE_STATUS:
    'Choose from the options below what is the status of your barbershop',
  ACTIVE: 'Online',
  ACTIVE_DESC:
    'Your barbershop will be available for booking and customers will be able to find it on the app.',
  PRIVATE: 'Private',
  PRIVATE_DESC:
    'Your barbershop will be available for bookings, but customers will NOT be able to find it in the app.',
  PENDING: 'Ready to publish',
  PENDING_DESC:
    'Your barber shop is unpublished in the system. You have the necessary prerequisites to publish it.',
  NOT_PUBLISHED: 'Not published',
  NOT_PUBLISHED_DESC:
    'Your barbershop will NOT be able to take appointments and customers will NOT be able to find you on the app.',
  BLOCKED: 'Blocked',
  BLOCKED_DESC:
    'Your BestBarbers subscription is pending. Please go to our subscription center.',

  //MENU (Menu items)
  SCHEDULES: 'Schedules',
  AGENDA: 'Agenda',
  HISTORIC: 'Historic',
  FINANCES: 'Finances',
  PAYMENTS: 'Payments',
  REPORTS: 'Reports',
  ADM: 'Adm',
  CLIENTS: 'Clients',
  PROFILE: 'Profile',
  //BARBERS SCREEN
  BARBERS_REPORT: 'Barbers Report',
  FINISHED_APPOINTMENTS_COUNT: 'Finished appointments',
  FINISHED_APPOINTMENTS_COUNT_DESC:
    'Number of schedules that have already been finalized.',
  //PERFIL (profile menu)
  MAIN_DATA: 'Main data',
  LOGIN_DATA: 'Login data',
  BARBESHOP_LINK: 'Barbeshop link',
  BLOCKED_TIME: 'Blocked times',
  PASSWORD_PROFILE: 'Password',
  EXIT: 'Exit',

  //PROFILE (Dados principais)
  CHANGE_LOGO_BUTTON: 'Change logo',
  BARBERSHOP_DESC_PLACEHOLDER: 'Describe here your services',
  BARBERSHOP_LINK: 'Barbershop Link',
  MAIN_PHONE: 'Phone number',
  SAVE_CHANGES: 'Save changes',
  CPF: 'Doc ID',
  CNPJ: 'Doc ID',

  //PROFILE (Share link)
  UPDATED_LINK: 'Link Updated successfully',
  COPIED_LINK: 'Copied link',
  LINK_DESC:
    'The link is an easy way for you to share your barbershop profile with your customers and for them to be able to schedule with your barbershop in a practical and simple way.',
  LINK_NOTE:
    'Note: The Link cannot contain accents, special characters or blank spaces. It will only be available if your barber shop has the status "Active.',
  COPY_LINK: 'Copy link',

  //PROFILE (Location)
  ADDRESS_ERROR: 'failed to load address.',

  //PROFILE (Working hours)
  WORKING_HOURS_ERROR: 'Failed to load opening hours',
  WEEK_DAY: 'Day of the week',
  REPEAT_TIME: 'Repeat this schedule for other days of the week',

  //PROFILE (photos/action modal)
  EXCLUDING: 'Excluding',
  SENDING: 'Sending',
  EXCLUDE_PHOTO: 'Exclude photo',
  EXCLUDE_PHOTO_CONFIRMATION: 'Are you sure you want to delete this photo?',

  //PROFILE (Blocked times)
  BLOCKED_TIME_TOAST: 'Time successfully blocked.',
  UNBLOCKED_TIME_TOAST: 'Time successfully unblocked.',
  OPTIONS: 'Options',
  REPEAT_TIME_EVERYDAY: 'Repeat every day',
  ADD_TIME: 'Add time.',
  BLOCK_TIME_CONFIRMATION: 'Do you want to block this time?',
  YES: 'Yes',
  NO: 'No',

  //PROFILE (Plan/plan invoice card)
  MY_PLAN: 'My plan',
  CURRENT_PLAN: 'Current plan:',
  SING_UP_DATE: 'Sing up date:',
  LAST_PAYMENTS: 'Latest payments:',
  PAYMENT_NOT_FOUND: 'No payment found',
  VIEW: 'View',
  EXPIRED: 'Expired',

  //PROFILE (Change password)
  ACTUAL_PASSWORD: 'Current password',
  NEW_PROFILE_PASSWORD: 'New password',
  ACTUAL_PASSWORD_REQUIRED: 'Current password is required',
  NEW_PASSWORD_REQUIRED: 'New password is required',
  NEW_PASSWORD_LENGTH: 'New password must have at least 6 characters',

  //PROFILE (Logout modal)
  EXIT_CONFIRMATION: 'Do you really want to leave?',
  BARBERSHOP_PROFILE: 'Barbershop profile',
  IMAGE: 'Picture',

  //Profile -> Blocked times
  EVERYBODY: 'Everybody',
  EVERYDAY: 'Everyday',
  EVERY: 'Every',

  PAID: 'Paid',
  CANCELED: 'Canceled',
  PARTIALLY_PAID: 'Partially paid',
  REFUNDED: 'Refunded',
  AUTHORIZED: 'Authorized',
  IN_PROTEST: 'In protest',
  NO_STATUS: 'No status',

  // ClientDetails
  INFORMATIONS: 'Informations',
  RECENT_OBSERVATIONS: 'Recent observations',
  SEE_ALL: 'See all',
  NO_OBSERVATIONS: 'No observations have been made',
  NEXT_APPOINTMENT: 'Next appointment',
  NO_APPOINTMENT: 'No appointments scheduled for this client.',
  OBSERVATIONS: 'Observations',
  OBSERVATION_SAVE_ERROR: 'Error on save observation.',
  DESCRIPTION_PLACEHOLDER: 'Description',
  CUSTOMER_STATISTICS: 'Customer statistics',
  FINISHED: 'Finished',
  AVERAGE_TICKET: 'Average ticket',
  TOTAL_PROFIT: 'Total received',
  DELETE_OBSERVATION: 'Delete observation',
  DELETE_OBSERVATION_DESC: 'Do you want to delete this observation?',
  DELETE_OBSERVATION_SUCCESS: 'Observation deleted successfully!',
  DELETE_OBSERVATION_ERROR: 'Failed to delete observation.',

  // PREMIUM FEATURES
  BLOCKED_FEATURE:
    'This functionality is exclusive to our subscribers. Click the button below to purchase your subscription and gain access to this and many other features.',
  SIGN_NOW: 'I want to be premium!',
  HOME_BLOCKED: 'Month report is exclusive to subscribers.',
  LINK_BLOCKED: 'Custom Scheduling Link is exclusive to subscribers.',
  BARBERS_REPORT_BLOCKED: 'Barbers report is exclusive to subscribers.',
  COMMISSIONS_REPORT_BLOCKED: 'Commissions report is exclusive to subscribers.',
  BLOCKED_FEATURE_TITLE: '{{section}} is exclusive to subscribers.',

  //source
  CLIENT_MOBILE_APP: 'Scheduled by Client - in App',
  BARBER_MOBILE_APP: 'Scheduled by Barber - in the App',
  BARBERSHOP_MOBILE_APP: 'Scheduled by Barber Shop in the App',
  BARBERSHOP_MOBILE_APP_WPP: 'Scheduled by Barbershop - WhatsApp',
  BARBERSHOP_MOBILE_APP_PRESENTIAL: 'Scheduled by Barbershop - Face to Face',

  //ADDITIONAL PRODUCTS
  ERROR_LOADING_PRODUCTS: 'Error when loading additional products',
  SUCCESS_PRODUCTS_CREATED: 'Product created successfully',
  ERROR_CREATE_PRODUCTS: 'Error creating additional product',
  SUCCESS_PRODUCTS_DELETE: 'Product deleted successfully',
  ERROR_DELETE_PRODUCTS: 'Error deleting product',
  SUCCESS_PRODUCTS_UPDATED: 'Product changed successfully',
  ERROR_UPDATE_PRODUCTS: 'Error when changing product',
  ADD_PRODUCT_BUTTON: 'Add product',
  CONFIRM_DELETE_PRODUCT: 'Do you want to delete the product',
  ERROR_REMOVE_PRODUCT_APPOINTMENT: 'Error when removing product',
  ADDITIONAL_PRODUCTS_PREMIUM_MESSAGE:
    'Products is exclusive to Premium subscribers',

  TOTAL_REVENUE_TITLE: 'Gross revenue',
  TOTAL_REVENUE_SUBTITLE: 'Amount Billed So Far',
  TOTAL_PREDICTION_SUBTITLE: 'Forecast Billing',
  // EXPENSES_TITLE: 'Expenses',
  EXPENSES_SUBTITLE: 'Posted',
  BALANCE: 'Balance Sheet',
  MONTH: 'Month',
  TOTAL: 'Total',
  BARBERS_COMMISSION: "Barber's Commission",
  TOTAL_CLIENTS: 'Total of clients',
  NEW_CLIENTS: 'New clients',
  ATTENDED: 'Attended',
  AWAITING: 'Awaiting',
  OUTSTANDING: 'Pending',
  FINALIZED: 'Finalized',
  SALES_PRODUCT: 'Product sales',
  SALES_SERVICE: 'Sales of services',
  SALES_PRODUCT_TABLE: 'Sales - products',
  SALES_SERVICE_TABLE: 'Sales - Services',

  BALANCE_MONTH: 'Balance of the month',
  RELEASES: 'Postings',
  REVENUES: 'Revenue posted',
  EXPENSES_TITLE: 'Expenses posted',
  PREDITICTION: 'Balance forecast of the month',
  PREDITICTION_SUBTITLE: 'Approximate Net Value',
  PREVISION: 'Forecast',
  GROSS_REVENUE: 'Gross revenue',
  APPOINTMENT: 'Scheduling',
  RECEIVED_UNTIL_NOW: 'Received so far',
  DISCOUNTS_UNTIL_NOW: 'Discounted so far',
  NO_DATA_RESUME: 'No data to show for the selected month',
  EXTRACT: 'Extract',
  TOTAL_SALES: 'Total Sales',
  TOTAL_COMMISSION: 'Total amount for the period',
  APPOINTMENTS_BY_DAY: 'Appointments per day',
  _REVENUES: 'Revenue',
  _EXPENSES: 'Expenses',
  SELL_SERVICE: 'Service Sales',
  FILTER: 'Filters',
  APPLY: 'Apply',
  NOT_INFORMED: 'Not Informed',
  START: 'Start',
  END: 'End',
  PAYMENT: 'Payment',
  IN_TOTAL: 'customers in total',
  CLIENT_CREATE_APPOINTMENT:
    'Select the client from the list of registered clients',
  BF_BANNER_1: 'The best investment for your barbershop',
  BF_BANNER_2: 'with an unmissable discount',
  FOR_MONTH: '/month',
  FOR_ONLY: 'For only',
  DURING_MONTH: 'for 3 months or',
  ANUAL_PLAN: 'on the annual plan ($598.80)',
  BUT_HURRY: "But hurry, it's for a limited time only!",
  OFFER_UNTIL: 'Offer available until 11/30/2022',
  SUBSCRIBE_NOW: 'Subscribe now',
  LEARN_MORE: 'Learn more',

  MODAL_1: "Get a week's trial on us!",
  GO_BACK: 'Back',
  STATUS_APPOINTMENT: 'Appointment status: ',
  AWAITING_: 'Awaiting',
  PENDING_: 'Pending',
  FINISHED_: 'Finished',
  NO_DISCOUNT: 'No discount added',
  NO_PRODUCT: 'No products added',
  PAYMENT_FORM: 'Payment method',
  PAYMENT_DATE_DESC: 'You will receive this payment on the day ',
  PAYMENT_DONE: 'Payment received on the day ',
  PAYMENT_PENDING: 'Pending payment',
  SUBTOTAL: 'Subtotal',
  SOURCE_FORM: 'Form of appointment not specified',
  PROFESSIONAL: 'Professional',
  ADD_SOURCE: 'Add scheduling source',
  PERCENTAGE_PROD: 'Product sale - barber: ',
  PERCENTAGE_SERV: 'Barber service: ',
  PERCENTAGE_PAY: 'Payment method: ',
  PRODUCTS_COMISSION: 'Product commission',
  SERVICE_COMISSION: 'Service commission',
  BARBER_PROFILE: 'Barber added',

  TOTAL_ENTRIES: 'Total entries (revenues)',
  UNTIL_MOMENT: 'So far',
  TOTAL_EXITS: 'Total Exits (expenses)',
  PER_DAY: 'Per day',
  CLIENT_AVERAGE: 'Average number of clients',
  DISCOUNTS: 'Discounts',
  OTHER_DISCOUNTS: 'Other discounts offered',
  LIQUID_VALUE: 'Net value (Revenues - Expenses)',
  LIQUID_VALUE_MOBILE: 'Net Value (Revenues - Expenses)',

  APPOINTMENTS_MONTH: 'bookings this month',
  NEXT_APPOINTMENTS: 'Next appointments today:',
  NO_APPOINTMENT_TODAY: 'No upcoming appointments',
  SEE_APPOINTMENTS: 'View all appointments for the day',

  SELECT_PRODUCTS: 'Select products',
  BLOCKED_TIMES: 'Blocked schedule',

  NOTIFICATIONS: 'Notifications',
  NOTIFICATIONS_DESC:
    'Set your preferences regarding sending email and push notifications',
  SEND_EMAIL: 'Your customers and barbers will receive email notifications',
  SEND_PUSH:
    'Your customers and barbers will receive push notifications on their cell phones',

  COMPLEMENT: 'Complement',
  SELECT_PRODUCTS: 'Select products',
  PERCENTAGE_SERVICES: '(%) Services',
  PAYMENT_METHOD_REPORT: 'Payment Details',
  CLIENTS_REPORT: 'Client breakdown',
  LAST_APPOINTMENT: 'LAST_ATTENDANCE',
  TOTAL_PRODUCTS: 'Total amount of products',
  TOTAL_SERVICES: 'Total amount services',
  MORE_SERVICE: 'Most used service',
  SALES_PRODUCT_CLIENT: 'Product Sales (Finished)',
  SALES_SERVICE_CLIENT: 'Service sales (finalized)',
  CLIENT: 'Customer',
  TOTAL_DISCOUNTS_TABLE: 'TOTAL IN DISCOUNTS',
  COMISSIONS_REPORT: 'Commission breakdown',
  APPOINTMENTS_1: 'APPOINT-',
  APPOINTMENTS_2: 'MENTS',
  HAVE: 'There are',
  TOTAL_DISCOUNTS: 'Discounts offered',
  CLIENT_UNTIL: 'Customer since ',
  product: 'Product',
  BILLING_PERCENTAGE: '(%) BILLING',
  SELLS_REPORT: 'Sales detail',
  SEE_MORE: 'See More',
  PRODUCTS: 'Products',
  RESPONSABLED_NAME: "Responsible Person's Name",
  RECURRENT: 'Recurrent?',
  SELLS: 'Sales',

  TERMS_AND_POLICYS_1: 'By registering on the platform, you agree with',
  TERMS_AND_POLICYS_2: 'the ',
  AND: 'and ',
  CELLPHONE: 'Cellphone',
  BARBERSHOP_NAME_PLACEHOLDER: 'Ex: Barbearia Best Barbers',
  OWNER_NAME_PLACEHOLDER: 'Ex: Antônio José',
  FREE_TRIAL_MODAL_TITLE_FIRST_PART_NEW: 'You won',
  BASIC: 'Basic',

  PAYMENT_MODAL_TITLE: 'Choose the payment method that suits you best',
  PIX: 'Pix',
  CREDIT: 'Credit',
  TICKET: 'Ticket',
  PAYMENT_MODAL_SUBTITLE_1: 'Register your card',
  NOW: 'now',
  AND_WIN: 'and win',
  DISCOUNT_10_PERCENT: '15 free days',
  CPF_OR_CNPJ: 'CPF or CNPJ of the holder',
  NUMBER_CARD: 'Credit card number',
  COMPLETE_NAME_CARD: 'Full name (written on the card)',
  CVV: 'CVV',
  EXPIRE_AT: 'Expiration date',
  CREDIT_CARD_ADVISE:
    'An amount of up to BRL 1.00 may be charged for validating your credit card',
  ADD_CREDIT_CARD: 'Card successfully added!',
  ADD_CREDIT_CARD_ERROR: 'Failed to add card!',
  ADD_CREDIT_CARD_ERROR_DESC: 'Please review the data and try again.',

  PREMIUM_EXCLUSIVE: 'Only available to Premium subscribers',
  BEGIN_PREMIUM: 'Start Premium',

  // SIGN PREMIUM MODAL
  SIGN_PREMIUM_MODAL_TITLE: 'Your barbershop can do ',
  SIGN_PREMIUM_MODAL_TITLE_SPAN: 'much more!',
  MEMBERSHIP_BENEFIT_1_NEW: 'Appointment management',
  MEMBERSHIP_BENEFIT_2_NEW: 'Exclusive access for each barber',
  MEMBERSHIP_BENEFIT_3_NEW: 'Custom booking link',
  MEMBERSHIP_BENEFIT_4_NEW: 'Unlimited number of barbers',
  MEMBERSHIP_BENEFIT_5_NEW: 'Unlimited number of services',
  MEMBERSHIP_BENEFIT_6_NEW: 'Automated financial control',
  MEMBERSHIP_BENEFIT_7_NEW: 'Business reports and metrics',
  MEMBERSHIP_BENEFIT_8_NEW: 'Notification of appointments',
  MEMBERSHIP_BENEFIT_9_NEW: 'Products and discounts on appointments',
  SIGN_PREMIUM_MODAL_BUTTON: 'Subscribe now for {{currency}} 69.90/month',

  MEMBERSHIP_BENEFIT_1_NEW: 'Gestão de agendamentos',
  MEMBERSHIP_BENEFIT_2_NEW: 'Acesso exclusivo para cada barbeiro',
  MEMBERSHIP_BENEFIT_3_NEW: 'Link de agendamento personalizado',
  MEMBERSHIP_BENEFIT_4_NEW: 'Número ilimitado de barbeiros',
  MEMBERSHIP_BENEFIT_5_NEW: 'Número ilimitado de serviços',
  MEMBERSHIP_BENEFIT_6_NEW: 'Controle financeiro automatizado',
  MEMBERSHIP_BENEFIT_7_NEW: 'Relatórios e métricas do seu negócio',
  MEMBERSHIP_BENEFIT_8_NEW: 'Notificação de agendamentos',
  MEMBERSHIP_BENEFIT_9_NEW: 'Produtos e descontos nos agendamentos',
  UNITYS: 'Unitys',
  SEND_NOTIFICATION: 'Send notification',
  SEND_NOTIFICATION_TITLE:
    'Send a mass message to all your customers in a simple and convenient way.',
  SEND_NOTIFICATION_PLACEHOLDER: 'Ex: New promotion at the barbershop',
  SEND_NOTIFICATION_SUCCESS: 'Message sent successfully',
  SEND_NOTIFICATION_ERROR: 'Error sending message',
  SEND: 'Send',

  // SINGLE PRODUCT SELL
  SINGLE_PRODUCT_SELL: 'Single sale',
  SINGLE_PRODUCT: 'Single product',
  BARBER_OPTIONAL: 'Barber (optional)',
  SINGLE_SALE_SUCCESS: 'Sale successfully created',
  SINGLE_SALE_ERROR: 'Failed to create the sale',
  SINGLE_SALE_DELETE_SUCCESS: 'One-off sale successfully deleted',
  SINGLE_SALE_DELETE_ERROR: 'There was an error deleting a single sale',
  SELECT_DISCOUNT: 'Select discount',
  GET_SINGLE_SALE_ERROR: 'Failed to load single sale',
  CONFIRM_DELETE_SINGLE_SALE: 'Do you want to delete the single sale?',
  DELETE_SINGLE_SALE_SUCCESS: 'Single sale deleted successfully',
  DELETE_SINGLE_SALE_ERROR: 'There was an error deleting a single sale',
  PRODUCT_SALE: 'Products Sale',

  ERROR_GET_UNITYS: 'Failed to load units.',
  CHANGE_UNITY: 'Change unity',

  MEET_US: 'How did you meet us?',
  MEET_US_DESC:
    'Help us by letting us know where you found BestBarbers so that we can reach more barbershops.',
  SAVE_DATA_SUCCESS: 'Data saved successfully',

  EDIT_PERMISSION: 'Edit Permission',
  EDIT_PERMISSION_TITLE: 'Edit contributor permission',
  FINANCE: 'Financial',
  SINGLE_PRODUCT_PERMISSION: 'Sale of separate products',
  APPOINTMENT_PERMISSION: 'Appointments',
  CLIENTS_PERMISSION: 'Customers',
  HISTORIC_PERMISSION: 'History',
  SERVICES_PERMISSION: 'Services',
  BLOCKED_HOURS_PERMISSION: 'Blocked hours',
  TEAM_PERMISSION: 'Team',
  ADDITIONAL_PRODUCTS_PERMISSION: 'Additional products',
  UNITYS_PERMISSION: 'Units',
  PAYMENT_METHODS_PERMISSION: 'Payment methods',
  NOTIFICATIONS_PERMISSION: 'Notifications',
  BLOCKED_PERMISSION: 'NO access',
  ONLY_READ: 'Read only',
  EDIT: 'Create and edit',
  PERMISSION_SUCCESS: 'Permissions updated successfully',
  PERMISSION_ERROR: 'Failed to update permissions',
  PERMISSION_DENIED: "You don't have permission to perform this action.",
  NOTIFICATIONS_TITLE: 'Barbershop notifications',
  NOTIFICATIONS_DESC_BARBERSHOP:
    'Set your barbershop notification preferences for email and push notifications',
  FLAG: 'Flag',

  //Subscription --> Benefits -> SubscriptionModal
  REGULARIZE_SIGNATURE: 'Regularize signature',
  SUBSCRIPTION_BENEFITS: 'See subscription benefits',
  SUBSCRIPTION_HAS_EXPIRED: 'Your subscription has expired!',
  SUBSCRIPTION_PLAN_BENEFITS:
    "Your BestBarbers subscription plan has expired! Don't leave your customers hanging! Regularize your plan right now and enjoy all the benefits of the premium version again!",
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_1: 'Appointment notification',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_2: 'Custom booking link',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_3: '100% automated financial control',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_4: 'Schedule management',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_5: 'Unlimited number of barbers',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_6: 'Exclusive access for each barber',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_7: 'Client management',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_8: 'Creating personalized services',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_9: 'Reports and metrics for your business',
  TO_GO_BACK: 'To go back',
  MEET_US: 'How did you meet us?',
  MEET_US_DESC:
    'Help us by letting us know where you found BestBarbers so that we can reach more barbershops.',
  SAVE_DATA_SUCCESS: 'Data successfully saved',

  PAYMENTS_MANAGER: 'Payment Management',
  SIGNATURE_CLUB: 'Subscription Club',
  PAYMENT_ON_APP: 'Payment on App',
  SUBSCRIBERS: 'Subscribers',
  ANTECIPATIONS: 'Balance and prepayments',
  SIGNATURE_CLUB_DESC:
    'With the signature club, your customers will pay by credit card for a range of services offered by the barbershop and will have the right to enjoy these services with a fixed amount per month',
  SIGNATURE_BLOCKED: 'Subscription Club is exclusive to subscribers.',
  PAYMENT_ON_APP_BLOCKED: 'Payment on the app is exclusive to subscribers.',
  ANTECIPATIONS_BLOCKED:
    'Balance and prepayments are exclusive to subscribers.',
  ACTIVATE_SIGNATURE_CLUB: 'Activate subscription service',
  SIGNATURE_WARN_1:
    'The subscription amount will be automatically charged to your customer on a monthly basis',
  SIGNATURE_WARN_2:
    "Don't worry! All subscription management will be done by BestBarbers",
  SIGNATURE_WARN_3: 'Remember to click the save button to apply the changes',
  PAYMENT_APP_ON: 'Enable in-app payment',
  PAYMENT_ON_APP_DESC:
    'By enabling payment through the app, the customer will be able to pay by credit card when booking',
  CHARGE_FORM: 'Billing method',
  ON_SCHEDULE: 'Charge the customer at the time of booking',
  ON_FINISH: 'Charge the customer when finalizing the appointment',
  PAYMENT_ON_APP_DESC_2:
    "Payment at the barbershop will remain active, remaining at the customer's option.", //ok
  CLICK_TO_SAVE: 'You must click save to change preferences',
  ON_SCHEDULE_DESC:
    'Description: The card will be charged at the time the customer makes the appointment', //ok
  ON_FINISH_DESC:
    'Description: The card will be charged when the barbershop finalizes the appointment', //ok
  CANCEL_TAXES_WARN:
    'If the customer cancels the appointment that has already been paid, a fee of 5% of the value of the transaction carried out will be charged.',
  HABILITY: 'Enable',
  DAYS_TO_CHARGE:
    'The withdrawal of the amount will be available 32 days after payment',
  ANTECIPATION_SUCCESS: 'Anticipation done successfully',
  ANTECIPATION_ERROR: 'Failed to perform anticipation',
  BALANCE_EXCHANGE: 'Balance available for withdrawal',
  PENDING_VALUES: 'Amounts pending receipt',
  SELECT_VALUES_ANTECIPATION: 'Select the amounts you want to anticipate',
  LIQUID_VALUE_ANTECIPATION: 'Net value',
  PREVISION_EXCHANGE: 'Withdrawal forecast',
  ANTECIPATE: 'Anticipate',
  EXCHANGE: 'Withdraw per PIX',
  PIX_SUCCESS: 'PIX made successfully',
  PIX_ERROR: 'Failed to perform PIX transaction',
  PIX_TYPES: 'Key types',
  PIX_KEY: 'PIX key',
  CONFIRM_PIX: 'Do you really want to withdraw the value of ',
  NO_SUBSCRIBERS: 'No subscribers found',
  SEARCH_SUBSCRIBERS: 'Search by subscriber',
  PLAN_DETAIL: 'Plan details',
  CANCEL_SIGNATURE: 'Unsubscribe',
  EXPIRED_AT: 'Due date',
  CONFIRMED: 'Confirmed',
  STATUS: 'Status',
  CANCEL_SIGNATURE_DESC: "Do you want to cancel the customer's subscription?",
  CANCEL_SIGNATURE_SUCCESS: 'Subscription canceled successfully',
  CANCEL_SIGNATURE_ERROR: 'Failed to cancel subscription',
  TYPE: 'Type',
  signature: 'Subscription',
  normal: 'Normal',
  FLAG: 'Flag',
  MOBILE_PHONE: 'Mobile phone',
  CONFIRMATE_DATA: 'Confirm data',
  MODAL_CONFIRMATE_DATA_SUBTITLE:
    'So that there are no problems with your barbershop payments, please check your details',
}
