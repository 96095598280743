import * as React from 'react'

function SvgMoney(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20.667 14.097c-1.104-1.357-2.365-2.439-3.75-3.216a2.8 2.8 0 00.521-.934c.454-1.38-.208-3.02-1.899-3.555l2.37-4.132a.929.929 0 00-.546-1.353 18.954 18.954 0 00-5.294-.751c-1.8 0-3.58.252-5.294.75a.929.929 0 00-.546 1.354l2.37 4.132c-1.684.532-2.355 2.17-1.9 3.556.115.35.294.664.522.932-1.385.778-2.647 1.86-3.75 3.217C1.265 16.811 0 20.234 0 23.49c0 6.752 5.387 8.355 12.069 8.355 6.686 0 12.069-1.606 12.069-8.355 0-3.255-1.266-6.678-3.471-9.392zm-12.22-11.7a17.157 17.157 0 013.622-.384c1.223 0 2.436.129 3.62.384l-2.044 3.565a11.387 11.387 0 00-3.153 0L8.448 2.397zm.607 5.798a9.599 9.599 0 014.895-.298h.006c.378.077.755.176 1.127.298a.93.93 0 01.593 1.172.926.926 0 01-.782.633 9.417 9.417 0 00-2.824-.437c-.953 0-1.895.147-2.819.435a.924.924 0 01-.787-.63.93.93 0 01.591-1.173zM12.07 30c-7.254 0-10.212-1.895-10.212-6.511 0-2.794 1.142-5.867 3.055-8.22 2.017-2.482 4.559-3.849 7.157-3.849 2.86 0 5.36 1.639 7.157 3.848 1.913 2.354 3.055 5.427 3.055 8.22 0 4.617-2.959 6.512-10.212 6.512z"
      />
      <path
        fill="currentColor"
        d="M12.073 14.774a.664.664 0 00-.665.665v1.016h-.086c-1.264 0-2.297 1.086-2.297 2.416 0 1.329 1.032 2.415 2.297 2.415h1.492c.534 0 .972.492.972 1.092 0 .605-.438 1.091-.973 1.091h-2.68a.664.664 0 100 1.33h1.276v1.032a.664.664 0 101.329 0v-1.038h.113c1.254-.016 2.264-1.097 2.264-2.415 0-1.335-1.032-2.416-2.296-2.416h-1.492c-.535 0-.972-.492-.972-1.091 0-.6.437-1.092.972-1.092h2.632a.664.664 0 100-1.33h-1.221v-1.015a.667.667 0 00-.665-.66z"
      />
    </svg>
  )
}

export default SvgMoney
