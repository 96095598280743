import React from 'react'

import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
} from '@chakra-ui/react'

export const FieldControl = ({
  label,
  render,
  help,
  error,
  isLoading,
  ...props
}) => (
  <FormControl isInvalid={error} {...props}>
    {label && <FormLabel>{label}</FormLabel>}

    <Skeleton isLoaded={!isLoading} borderRadius={6}>
      {render({ label, help, error })}
    </Skeleton>

    {error ? (
      <FormErrorMessage mt={0}>{error}</FormErrorMessage>
    ) : (
      help && <FormHelperText mt={0}>{help}</FormHelperText>
    )}
  </FormControl>
)
