export const APPOINTMENT_STEPS = {
    initialStep: 'NEW_APPOINTMENTS',
    singleSale: 'SINGLE_SALE',
    blockedTimes: 'BLOCKED_TIMES',
    editAppointment: 'EDIT_APPOINTMENT',
    selectCustomer: 'SELECT_CUSTOMER',
    selectBarber: 'SELECT_BARBER',
    selectService: 'SELECT_SERVICE',
    selectDateAndTime: 'SELECT_DATE_TIME',
    selectProducts: 'SELECT_PRODUCTS',
    selectDiscount: 'SELECT_DISCOUNT',
    selectDiscountBarber: 'SELECT_DISCOUNT_BARBER',
    selectPaymentMethod: 'SELECT_PAYMENT_METHOD',
  }