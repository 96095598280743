import * as React from 'react'

function SvgCalendarCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 146 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M124.997 20.334h-104c-9.572 0-17.333 7.76-17.333 17.333v104c0 9.573 7.76 17.334 17.333 17.334h104c9.573 0 17.334-7.761 17.334-17.334v-104c0-9.573-7.761-17.333-17.334-17.333zM107.664 3v34.667M38.336 3v34.667M3.664 72.334h138.667M61 119.259l6.128 6.128M67.125 125.385L85.51 107"
        stroke="currentColor"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCalendarCheck
