import React, { useState, useEffect } from 'react'
import { Box, Text, Button } from 'components/atoms'
import { ModalButtonBackground } from 'components-v2/atoms'
import { ModalMenu } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { useMutation, useQueryClient } from 'react-query'
import { useTheme, useToast } from '@chakra-ui/react'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { SelectClientModal } from '../SelectClientModal'
import { useBarbershopStore } from 'services/stores'

import { SelectRewardModal } from '../SelectRewardModal'

export const RescueRewardsModal = ({ isOpen, onClose }) => {
  const [selectClientModalOpen, setSelectClientModalOpen] = useState(false)
  const [selectRewardModalOpen, setSelectRewardModalOpen] = useState(false)
  const { createRewardExchange } = useBestcoins()
  const toast = useToast()
  const { userData } = useBarbershopStore()

  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedReward, setSelectedReward] = useState(null)
  const queryClient = useQueryClient()

  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    if (!isOpen) {
      setSelectedClient()
      setSelectedReward()
    }
  }, [isOpen])

  const onclickSelectClientModal = () => {
    setSelectClientModalOpen(true)
  }

  const onCloseSelectClientModal = () => {
    setSelectClientModalOpen(false)
  }

  const onCloseSelectRewardModal = () => {
    setSelectRewardModalOpen(false)
  }

  const onclickSelectRewardModal = () => {
    setSelectRewardModalOpen(true)
  }

  const mutateCreateRewardExchange = useMutation(
    () => {
      const body = {
        barbershop_id: userData?.barbershop?.id || userData?.id,
        client_id: selectedClient?.client?.id,
        reward_id: selectedReward?.id,
      }
      return createRewardExchange({ body })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('bestcoins-points-for-client')
        queryClient.invalidateQueries('get-rewards-exchange')
        onClose()
        toast({
          title: 'Troca de prêmio adicionado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao adicionar troca de prêmio',
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <ModalMenu
      title={'Resgatar prêmio para cliente'}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <Box>
        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {'Cliente'}
        </Text>

        <Button
          size="block"
          kind="appointmentOptions"
          onClick={() => onclickSelectClientModal()}
        >
          <Text>
            {selectedClient
              ? `${selectedClient?.client?.name} - ${
                  selectedClient?.bestcoins_balance || 0
                } pontos`
              : 'Selecionar um cliente'}
          </Text>
        </Button>

        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {'Prêmio'}
        </Text>
        <Button
          size="block"
          kind="appointmentOptions"
          onClick={() => onclickSelectRewardModal()}
        >
          <Text>
            {selectedReward
              ? `${
                  selectedReward?.type === 'discount'
                    ? `Desconto de R$ ${selectedReward?.discount_value} - ${selectedReward?.required_points} Pontos`
                    : `${selectedReward?.product?.name} - ${selectedReward?.required_points} Pontos`
                } `
              : 'Selecionar um prêmio'}
          </Text>
        </Button>
      </Box>
      <SelectClientModal
        isOpen={selectClientModalOpen}
        onClose={onCloseSelectClientModal}
        selected={selectedClient}
        setSelected={setSelectedClient}
      />
      <SelectRewardModal
        isOpen={selectRewardModalOpen}
        onClose={onCloseSelectRewardModal}
        selected={selectedReward}
        setSelected={setSelectedReward}
      />

      <ModalButtonBackground>
        <Button
          flex="1"
          h={theme.pxToRem(52)}
          w="100%"
          disabled={!selectedClient || !selectedReward}
          onClick={() => mutateCreateRewardExchange.mutate()}
        >
          <Text fontSize="md" color={colors.textDark} kind="bold">
            {t('CONFIRM')}
          </Text>
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
