import * as React from 'react'

function SvgSent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.464 25.288a3.72 3.72 0 013.72-3.72h41.664a3.72 3.72 0 013.72 3.72v29.76a3.72 3.72 0 01-3.72 3.72h-6.013a15.125 15.125 0 010 4.464h6.013a8.184 8.184 0 008.184-8.184v-29.76a8.184 8.184 0 00-8.184-8.184H19.184A8.184 8.184 0 0011 25.288v29.76a8.184 8.184 0 008.184 8.184h5.98a15.125 15.125 0 010-4.464h-5.98a3.72 3.72 0 01-3.72-3.72v-29.76z"
        fill="#E6B817"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.182 53.98a1.654 1.654 0 012.361 2.315l-8.802 11.003a1.654 1.654 0 01-2.382.044l-5.833-5.834a1.653 1.653 0 112.338-2.338l4.617 4.616 7.659-9.758a.52.52 0 01.044-.049h-.002zM17.103 25.7a1.786 1.786 0 012.476-.496L40.017 38.83l20.437-13.625a1.786 1.786 0 111.981 2.971L41.007 42.46c-.6.4-1.381.4-1.981 0L17.598 28.175a1.786 1.786 0 01-.495-2.476z"
        fill="#E6B817"
      />
    </svg>
  )
}

export default SvgSent
