import { productiveCapacity } from "utils/indicators"

export const filters = [
    {
        id: 0,
        name: 'Por dia',
        value: 'daily',
    },
    // {
    //     id: 1,
    //     name: 'Por dia da semana',
    // },
    {
        id: 2,
        name: 'Por barbeiro',
        value: 'barbers'
    }
]

export const renderCards = [
    {
        name: 'Quantas horas sua barbearia funcionou em média por dia? (hh:mm)',
        key: 'working_hours',
        working_hours: '00:00',
    },
    {
        name: 'Quantos dias por mês sua barbearia funcionou?',
        key: 'working_days',
        working_days: 0,
    },
    {
        name: 'Quantos minutos em média por atendimento? (hh:mm)',
        key: 'working_minutes',
        working_minutes: '00:00',
    },
    {
        name: 'Quantos barbeiros atuam na sua barbearia?',
        key: 'working_barbers',
        working_barbers: 0,
    },
    {
        name: 'Quantos serviços executados no período de análise?',
        key: 'working_services',
        working_services: 0,
    },
]