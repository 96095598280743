import * as React from 'react'

function SvgCart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      {...props}
    >
      <circle
        cx={13.33}
        cy={29.75}
        r={2.25}
        className="cart_svg__clr-i-outline cart_svg__clr-i-outline-path-1"
      />
      <circle
        cx={27}
        cy={29.75}
        r={2.25}
        className="cart_svg__clr-i-outline cart_svg__clr-i-outline-path-2"
      />
      <path
        d="M33.08 5.37a1 1 0 00-.77-.37H11.49l.65 2H31l-2.67 12h-15L8.76 4.53a1 1 0 00-.66-.65L4 2.62a1 1 0 10-.59 1.92L7 5.64l4.59 14.5-1.64 1.34-.13.13A2.66 2.66 0 009.74 25 2.75 2.75 0 0012 26h16.69a1 1 0 000-2H11.84a.67.67 0 01-.56-1l2.41-2h15.44a1 1 0 001-.78l3.17-14a1 1 0 00-.22-.85z"
        className="cart_svg__clr-i-outline cart_svg__clr-i-outline-path-3"
      />
      <path fill="transparent" stroke="transparent" d="M0 0h36v36H0z" />
    </svg>
  )
}

export default SvgCart
