import React, { useState, useEffect } from 'react'
import {
  Layout,
  Table,
  Text,
  ClientDetailModal,
  Avatar,
  Link,
  SendAdviceModal,
  TextInput,
  SelectDateButton,
  Button,
  LineChartRechart,
} from 'components'
import { Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore, useCustomerStore } from 'services/stores'
import { parseISO, differenceInDays, format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'
import { formatPhone } from 'utils/phone-formater'
import { useMutation, useQuery } from 'react-query'
import { UnitysModal } from 'components/organisms/Unitys'
import { usePermissionCollaborator } from 'services/hooks'
import { financesStore } from 'services/stores/finances'
import { useBarbershop } from 'services/hooks'
import { useUserType } from 'services/hooks/use-user-type'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { formatAppointmentsToChart, formatSimpleToChart } from 'utils'

export const NewClientsReport = (props) => {
  const { userData } = useBarbershopStore()
  const { getNewClients, exportNewClients } = useBarbershop()
  const toast = useToast()
  const { t } = useTranslation()
  const [isSendAdviceModalOpen, setSendAdviceModalOpen] = useState(false)
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore()
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const permission = usePermissionCollaborator('notifications')
  const clients = usePermissionCollaborator('clients')
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))

  const { data, refetch, isLoading, isRefetching } = useQuery(
    ['get-clients-resume', userData],
    () => getNewClients({ id: userData?.id, startDate, endDate }),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const columns = [
    {
      key: 'client',
      label: 'CLIENT',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Avatar size="medium" src={item?.client?.profile_image_url} mr="5%" />
            <Text>{item?.client?.name}</Text>
          </Flex>
        )
      },
    },
    {
      key: 'cellphone',
      label: 'PHONE',
      render: (item) => {
        if (permission === 'edit') {
          return (
            <Link href={`https://api.whatsapp.com/send?1=pt_BR&phone=${item?.client?.user?.phone}`} target="_blank">
              {formatPhone(item?.client?.user?.phone, userData)}
            </Link>
          )
        } else {
          return <Text>{formatPhone(item?.client?.user?.phone, userData)}</Text>
        }
      },
    },
    {
      key: 'createdAt',
      label: 'DATA DE CRIAÇÃO',
      render: (item) => <Text>{format(parseISO(item?.createdAt), 'dd/MM/yyyy')}</Text>,
    },
  ]

  const handleSelectCustomer = (customer) => {
    if (clients !== 'blocked') {
      setSelectedCustomer(customer)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const mutate = useMutation(
    ['get-new-clients-excel'],
    () => exportNewClients({ id: userData?.id, params: { startDate, endDate } }),
    {
      onSuccess: (value) => {
        const url = URL.createObjectURL(
          new Blob([value], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        window.open(url)
      },
    }
  )

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Relatório de novos clientes"
      widthTitle="100%"
      iconColor="black"
      iconSecondActionText={'NotificationSmall'}
      secondActionText={t('SEND_NOTIFICATION')}
      secondActionClick={permission === 'edit' ? () => setSendAdviceModalOpen(true) : null}
      thirtyClick={{
        icon: 'Excel',
        iconColor: 'black',
        onClick: () => mutate.mutate(),
        text: 'Exportar em excel',
        isLoading: mutate.isLoading,
      }}
      headerCenterElement={() => (
        <Flex w="100%" mr="20px">
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            label={t('START')}
            padding="4%"
            width={'90%'}
          />
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            label={t('END')}
            padding="5%"
            width={'90%'}
          />
          <Button
            isLoading={isRefetching || isLoading}
            icon="Search"
            size="small"
            iconColor="black"
            mt="26px"
            onClick={() => {
              refetch()
            }}
          >
            Pesquisar
          </Button>
        </Flex>
      )}
    >
      <>
        {isLoading ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : (
          <Flex w="100%" flexDir="column">
            <LineChartRechart data={formatSimpleToChart(startDate, endDate, data?.newClients)} />
            <Text kind="extraBold" fontSize={18} w="100%">
              {`Total: ${data?.newClients?.length}`}
            </Text>
            <Table
              loading={isLoading}
              data={data?.newClients?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))}
              columns={columns}
              isSelectableRow
              onSelect={(item) => handleSelectCustomer(item)}
            />
          </Flex>
        )}
      </>

      <SendAdviceModal
        isModalOpen={isSendAdviceModalOpen}
        onClose={() => {
          setSendAdviceModalOpen(false)
        }}
        clients={data?.newClients}
      />

      {isCustomerDetailModalOpen && (
        <ClientDetailModal
          isModalOpen={isCustomerDetailModalOpen}
          onClose={() => {
            setCustomerDetailModal(false)
            refetch()
          }}
          customerData={selectedCustomer}
        />
      )}
    </Layout>
  )
}

NewClientsReport.path = '/relatorio-novos-clientes'
NewClientsReport.title = 'Relatório Novos Clientes'
NewClientsReport.secured = true
