import React, { useState } from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { RegisterWrapper, MobileList, MeetUsCard } from 'components'

import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { FINISH_ONBOARDING } from 'services/api/endpoints'
import { t } from 'services/translation'
import { SuccessStepModal } from 'screens/Register/SuccessStep'

const options = [
  {
    id: 0,
    name: 'Indicação',
    value: 'indication',
  },
  {
    id: 1,
    name: 'Youtube',
    value: 'youtube',
  },
  {
    id: 2,
    name: 'Podcast',
    value: 'podcast',
  },
  {
    id: 3,
    name: 'Spotify',
    value: 'spotify',
  },
  {
    id: 4,
    name: 'Instagram',
    value: 'instagram',
  },
  {
    id: 5,
    name: 'Google',
    value: 'google',
  },
  {
    id: 6,
    name: 'Anúncio',
    value: 'ads',
  },
]

export const MeetUsStepScreen = () => {
  const [select, setSelect] = useState(null)
  const toast = useToast()
  const [finishSetupModal, setFinishSetupModal] = useState(false)

  async function whereMeetUs() {
    const params = {
      met_through: select.value,
    }
    const res = await api
      .put(FINISH_ONBOARDING, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('where-meet-us', whereMeetUs, {
    onSuccess: async () => {
      toast({
        title: t('SAVE_DATA_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setFinishSetupModal(true)
    },
    onError: (error) => {
      toast({
        title: t('ERROR_SAVE_DATA'),
        status: 'danger',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <RegisterWrapper
      barbershopSetup
      continueDisabled={!select}
      loading={mutate.isLoading}
      onContinue={() => mutate.mutate()}
    >
      <Flex flexDir="column" w="100%" h="100%">
        <Flex w="100%">
          <MobileList
            data={options || []}
            renderItem={({ item }) => (
              <MeetUsCard
                select={select === item}
                onClick={() => setSelect(item)}
                item={item}
              />
            )}
          />
        </Flex>
      </Flex>
      {finishSetupModal && (
        <SuccessStepModal
          isOpen={finishSetupModal}
          onClose={() => setFinishSetupModal(false)}
        />
      )}
    </RegisterWrapper>
  )
}

MeetUsStepScreen.path = '/cadastro/onde-nos-conheceu'
MeetUsStepScreen.title = 'Conheceu'
MeetUsStepScreen.secured = true
