import { Box, Flex } from '@chakra-ui/react'
import { Avatar, Text } from 'components/atoms'
import { formatPrice } from 'utils'
import { t } from 'services/translation'
import { format, parseISO } from 'date-fns'

export const GOALS_COLUMNS = [
  {
    width: 220,
    key: 'barber',
    label: 'COLLABORATOR',
    render: (item) => {
      return (
        <Flex flexDir="row" align="center">
          <Avatar size="medium" src={item?.barber?.profile_image_url || item?.employee?.profile_image_url} mr="16px" />
          <Box>
            <Text>{item?.barber?.name || item?.employee?.name}</Text>
            <Text fontSize={12} color="textMedium">
              {t(item?.employee?.type) || item?.barber?.visible_name}
            </Text>
          </Box>
        </Flex>
      )
    },
  },
  {
    width: 100,
    key: 'type',
    label: 'TIPO DA META',
    render: (item) => 'Faturamento',
  },
  {
    width: 130,
    key: 'value',
    label: 'VALOR DA META',
    render: (item) => formatPrice(item?.value),
  },
  {
    width: 95,
    key: 'bonus_active',
    label: 'BÔNUS ATIVO',
    render: (item) => (
      <Text color={item?.bonus_active ? 'success' : 'danger'} kind="semiBold">
        {item?.bonus_active ? 'Sim' : 'Não'}
      </Text>
    ),
  },
  {
    width: 60,
    key: 'value',
    label: 'RECORRÊNCIA',
    render: (item) => <Text>Mensal</Text>,
  },
  {
    width: 95,
    key: 'value',
    label: 'VALIDA ATÉ',
    render: (item) => (
      <Text color={!item?.end_date ? 'textMedium' : 'textLight'}>
        {item?.end_date ? format(parseISO(item?.end_date), 'dd/MM/yyyy') : 'Não definido'}
      </Text>
    ),
  },
]

export const detailsColumns = [
  {
    key: 'barber',
    label: 'VALORES DE META',

    render: (item) => <Text fontSize={12}>{formatPrice(item)}</Text>,
  },
]

export const resumeColumns = [
  {
    key: 'barber',
    label: 'COLLABORATOR',
    render: (item) => {
      return (
        <Flex flexDir="row" align="center">
          <Avatar size="medium" src={item?.barber?.profile_image_url} mr="16px" />
          <Box>
            <Text>{item?.barber?.name}</Text>
            <Text fontSize={12}>{item?.quantity} agendamentos</Text>
          </Box>
        </Flex>
      )
    },
  },
  {
    key: 'total_value',
    label: 'TOTAL_SALES_TABLE',
    render: (item) => <Text>{formatPrice(item?.total_value)}</Text>,
  },
  {
    key: 'min_goal',
    label: 'META MINIMA PREVISTA',
    render: (item) => (
      <Text>
        {item?.barber_goals === 'Meta não cadastrada'
          ? item?.barber_goals
          : formatPrice(item?.barber_goals?.sort((a, b) => a - b)?.[0])}
      </Text>
    ),
  },
  {
    key: 'max_goal',
    label: 'META MAXIMA PREVISTA',
    render: (item) => (
      <Text>
        {item?.barber_goals === 'Meta não cadastrada'
          ? item?.barber_goals
          : formatPrice(item?.barber_goals?.sort((a, b) => a - b)?.[item?.barber_goals?.length - 1])}
      </Text>
    ),
  },
  // {
  //   key: 'status',
  //   label: '%',
  //   render: (item) => {
  //     const goal = item?.barber_goals === 'Meta não cadastrada' ? 0 : item?.goal
  //     const dif = (item?.total_value / goal) * 100
  //     return (
  //       <Text color={item?.barber_goals === 'Meta não cadastrada' ? 'primary' : dif < 100 ? 'error' : 'success'}>
  //         {item?.goal === 'Meta não cadastrada' ? 'Meta não cadastrada' : `${dif.toFixed(2)}%`}
  //       </Text>
  //     )
  //   },
  // },
  //   {
  //     key: 'total_comission',
  //     label: 'TOTAL DE COMISSÕES',
  //     render: (item) => <Text>{formatPrice(item?.total_comission)}</Text>,
  //   },
  //   {
  //     key: 'sales_services',
  //     label: 'BARBER_COMMISSION_SERVICE',
  //     render: (item) => <Text>{formatPrice(item?.services_comission)}</Text>,
  //   },
]

export const tableInputColumns = [
  {
    label: 'Serviço',
    key: 'name',
    type: 'text',
    w: '55%',
  },
  {
    label: 'Comissão ao bater meta',
    key: 'percentage',
    type: 'input',
  },
  {
    label: 'Ativo',
    key: 'inactive',
    type: 'switch',
    w: '20%',
  },
]

export const tableInputAll = [
  {
    label: 'Serviço',
    key: 'name',
    type: 'text',
    w: '60%',
  },
  {
    label: 'Comissão ao bater meta',
    key: 'percentage',
    type: 'input',
  },
]
