import React, { useState } from 'react'
import { dataMaximum, validationMaximum } from './settings'
import { Box, Flex, Text, Button } from 'components/atoms'
import { Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { useMutation } from 'react-query'
import { useBarbershop } from 'services/hooks'

export const MaximumSchedule = () => {
  const { t } = useTranslation()
  const { userData, setUserData } = useBarbershopStore()
  const {changeMaximumSchedule} = useBarbershop()
  const [values, getValues] = useState({ params: {maximum_schedule_date: userData?.maximum_schedule_date}, isValid: false })

  const mutate = useMutation(
    'change-minimum-time',
    (params) => changeMaximumSchedule(params),
  )

  const handleFormSubmit = async () => {
    const params = {
      ...values?.params,
      barbershop_id: userData?.barbershop?.id || userData?.id
    }
    mutate.mutate({params, onSuccess: (data) => setUserData({...userData, maximum_schedule_date: data?.maximum_schedule_date})})
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box flex="1">
        <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
          {t('MAXIMUM_SCHEDULE_TIME')}
        </Text>

        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          {t('MAXIMUM_SCHEDULE_TIME_DESC')}
        </Text>

        <Box mt={pxToRem(32)} mb={pxToRem(32)}>
          <Form
            initialValues={values?.params}
            validationSchema={validationMaximum}
            data={dataMaximum}
            getValues={getValues}
          />
        </Box>
        <Text kind="semiBold" fontSize={pxToRem(14)} mt={pxToRem(16)}>
          {`Prazo: ${
            values?.params?.maximum_schedule_date || 15
          } dias`}
        </Text>
      </Box>
      <Button
        disabled={!values?.isValid || mutate.isLoading}
        isLoading={mutate.isLoading}
        onClick={handleFormSubmit}
        mt={pxToRem(32)}
        size="block"
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Flex>
  )
}
