import React from 'react'
import { Button, useTheme } from '@chakra-ui/react'

export const TimeCard = ({ children, selected, onClick }) => {
  const theme = useTheme()
  return (
    <Button
      onClick={onClick}
      h={theme.pxToRem(48)}
      w={theme.pxToRem(80)}
      borderWidth={1}
      background="background"
      color={selected ? "primary" : "textLight"}
      fontFamily="Montserrat Regular"
      borderColor={selected ? "primary" : "background"}
      fontSize={theme.pxToRem(12)}
      mr={theme.pxToRem(4)}
      mb={theme.pxToRem(12)}
      _hover={{
        bg: "primary",
        color: "textDark"
      }}
    >
      {children}
    </Button>
  )
}
