import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Box, useTheme, useToast } from '@chakra-ui/react'
import {
  FileInput,
  RegisterWrapper,
  Text,
  Form,
  FreeTrialModal,
} from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useHistory } from 'react-router-dom'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'
import { initialProfile, dataProfile, validationProfile } from './settings'
import { cpfFormatter } from 'utils'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { MAIN_DATA, UPDATE_PROFILE_IMAGE } from 'services/api/endpoints'

export const ProfileStepScreen = () => {
  const theme = useTheme()
  const toast = useToast()
  const history = useHistory()
  const { userData, setUserData, setMainData } = useBarbershopStore()
  const { t } = useTranslation()
  const [trialModalOpen, setTrialModalOpen] = useState(false)
  // const [introModalOpen, setIntroModalOpen] = useState(true)
  const [values, getValues] = useState({
    params: initialProfile,
    isValid: false,
  })

  const handleSuccess = (data) => {
    setUserData({
      ...userData,
      ...data,
    })
    toast({
      title: t('UPDATED_DATA'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    })
  }

  const handleError = (message) => {
    toast({
      title: message,
      status: 'error',
      duration: 4000,
      isClosable: true,
    })
  }

  async function getMainData() {
    if (!userData?.barberhsop?.id && !userData?.id) {
      throw new Error('Barbearia não encontrada')
    }
    const res = await api
      .get(MAIN_DATA(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-main-data', getMainData, {
    onSuccess: (value) => {
      if (value) {
        setMainData(value)
        getValues({
          params: {
            name: value?.name ? value?.name : values?.params?.name,
            owner_name: value?.owner_name
              ? value?.owner_name
              : values?.params?.owner_name,
            phone: value?.phone ? value?.phone : values?.params?.phone,
            // identifier_doc: value?.identifier_doc
            //   ? cnpjFormatter(value?.identifier_doc)
            //   : cnpjFormatter(values?.params?.identifier_doc),
            secondary_identifier_doc: value?.secondary_identifier_doc
              ? cpfFormatter(value?.secondary_identifier_doc)
              : cpfFormatter(values?.params?.secondary_identifier_doc),
            profile_image_url: value?.profile_image_url
              ? value?.profile_image_url
              : values?.params?.profile_image_url,
            // description: value?.description
            //   ? value?.description
            //   : values?.params?.description,
          },
          isValid: false,
        })
      }
    },
  })

  async function changeMainData(params) {
    const res = await api
      .put(MAIN_DATA(userData?.barbershop?.id || userData?.id), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function changeImageProfile(params) {
    const res = await api
      .post(
        UPDATE_PROFILE_IMAGE('owner', userData?.barbershop?.id || userData?.id),
        params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  const mutate = useMutation(
    'change-main-data',
    (params) => changeMainData(params),
    {
      onSuccess: (data) => {
        handleSuccess(data)
        history.push('/cadastro/localizacao')
      },
      onError: (error) => {
        handleError(error.toString().substring(7))
      },
    }
  )

  const mutateAvatar = useMutation(
    'change-image-profile',
    (params) => changeImageProfile(params),
    {
      onSuccess: (data) => {
        handleSuccess(data)
      },
      onError: (error) => {
        handleError(error.toString().substring(7))
      },
    }
  )

  const handleSelectPhoto = async (photo) => {
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onload = () => {
      const splitted = reader.result.split(',')
      const mime = splitted[0].toString()
      const mime1 = mime.split(':')
      const mime2 = mime1[1].split(';')
      const params = {
        base64: splitted[1],
        mime: mime2[0],
      }

      mutateAvatar.mutate(params)
    }
  }

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_profile_step_opened')
  }, [])

  const continueDisabled = useMemo(() => {
    if (
      // values.params?.description?.length <= 10 ||
      // !values?.params?.description ||
      values?.params?.phone?.length <= 11 ||
      !values?.params?.phone ||
      values?.params?.secondary_identifier_doc?.length <= 11 ||
      !values?.params?.secondary_identifier_doc ||
      !values?.params?.owner_name
    ) {
      return true
    }
    return false
  }, [userData, values])

  return (
    <RegisterWrapper
      loading={mutate.isLoading}
      onContinue={() => {
        const params = {
          ...values.params,
        }
        mutate.mutate(params)
      }}
      continueDisabled={continueDisabled}
    >
      <Flex flexDir="column" w="100%" h="100%">
        {/* <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
        >
          <FileInput
            type="logo"
            defaultImage={userData?.profile_image_url}
            canDelete={false}
            onChange={handleSelectPhoto}
            loading={mutateAvatar.isLoading}
          />

          <Text fontSize={12} color="textGrey" mt={theme.pxToRem(16)}>
            {t('CHANGE_LOGO_BUTTON')}
          </Text>
        </Flex> */}

        <Box w="100%">
          <Form
            initialValues={values?.params}
            validationSchema={validationProfile}
            data={dataProfile}
            getValues={getValues}
            mb={32}
          />
        </Box>
      </Flex>

      {/* <BarbershopIntroModal
        isOpen={introModalOpen}
        onClose={() => setIntroModalOpen(false)}
      /> */}
      {userData?.iugu_plan_id?.substring(0, 10) !== 'whitelabel' && (
        <FreeTrialModal
          isOpen={trialModalOpen}
          onClose={() => setTrialModalOpen(false)}
        />
      )}
    </RegisterWrapper>
  )
}

ProfileStepScreen.path = '/cadastro/perfil-barbearia'
ProfileStepScreen.title = 'Perfil da Barbearia'
ProfileStepScreen.secured = true
