export const commonProps = {
    noBorder: true,
    fontSize: { base: '12px', ssm: '14px', md: '16px' },
    // padding: { base: '8px', ssm: '10px', md: '16px' },
    // w: '100%',
}

export const inputProps = [
    {
        ml: '5%',
        mr: { ssm: '5%', sm: '5%', md: '16px' },
        key: 'start_hour',
        ...commonProps,
    },
    {
        ml: '0',
        mr: '5%',
        key: 'close_hour',
        ...commonProps,
    },
]
