import React from 'react'
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useTheme,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Text, Icon, Button } from 'components/atoms'
import { useMenuCollapse, useModalStore } from 'services/stores'
import { useBreakpoint } from 'services/hooks'

export const FeatureBlockedMessage = ({
  title,
  section,
  isModal,
  ...props
}) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { collapsed } = useMenuCollapse()
  const { setCurrentModal } = useModalStore()

  const onClickSign = () => {
    window.open('https://wa.me/5531971105643')
  }

  if (isModal)
    return (
      <Modal isCentered isOpen={props.isOpen} onClose={props.onClose} size="xs">
        <ModalOverlay />

        <ModalContent
          flexDir="column"
          align="center"
          justify="center"
          px={theme.pxToRem(isDesktop ? 44 : 24)}
          pt={theme.pxToRem(32)}
          pb={theme.pxToRem(16)}
          width="90%"
          maxW={theme.pxToRem(470)}
          bgColor="primary"
          borderRadius={theme.pxToRem(12)}
        >
          <ModalHeader
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={theme.pxToRem(16)}
          >
            <Icon name="FeatureIcon" size={48} />

            <Text
              mt={theme.pxToRem(16)}
              align="center"
              kind="extraBold"
              fontSize={theme.pxToRem(isDesktop ? 24 : 20)}
              color="black"
            >
              {t(title)}
            </Text>
          </ModalHeader>

          <ModalBody>
            <Text
              align="center"
              kind="medium"
              fontSize={theme.pxToRem(14)}
              color="black"
            >
              {t('BLOCKED_FEATURE')}
            </Text>

            <Button
              size="block"
              mt={theme.pxToRem(40)}
              bgColor="textColorDark"
              color="primary"
              onClick={onClickSign}
            >
              {t('SIGN_NOW')}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    )

  return (
    <Flex
      align={isDesktop && 'center'}
      justify="center"
      pt={!isDesktop && theme.pxToRem(42)}
      width={
        isDesktop
          ? theme.pxToRem(window.innerWidth - (collapsed ? 88 : 200))
          : '100%'
      }
      height="100%"
      backgroundColor="rgba(17,17,17,0.7)"
      pos="absolute"
      top={0}
      zIndex={5}
    >
      <Flex
        flexDir="column"
        align="center"
        justify="center"
        px={theme.pxToRem(isDesktop ? 44 : 24)}
        py={theme.pxToRem(64)}
        width="90%"
        maxW={theme.pxToRem(470)}
        bgColor="primary"
        borderRadius={theme.pxToRem(12)}
        pos="absolute"
      >
        <Icon name="FeatureIcon" size={48} />

        <Box maxW={378}>
          <Text
            mt={theme.pxToRem(16)}
            align="center"
            kind="extraBold"
            fontSize={theme.pxToRem(isDesktop ? 24 : 20)}
            color="black"
          >
            {t('BLOCKED_FEATURE_TITLE', { section: t(section) })}
          </Text>

          <Text
            mt={theme.pxToRem(16)}
            align="center"
            kind="medium"
            fontSize={theme.pxToRem(14)}
            color="black"
          >
            {t('BLOCKED_FEATURE')}
          </Text>
        </Box>

        <Button
          size="block"
          mt={theme.pxToRem(40)}
          bgColor="textColorDark"
          color="primary"
          onClick={onClickSign}
        >
          {t('SIGN_NOW')}
        </Button>
      </Flex>
    </Flex>
  )
}
