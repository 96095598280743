import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Text, Button, Flex, TextInput } from 'components/atoms'

export const ConfirmReceivedCashModal = ({ isOpen, onClose, onClick, isLoading }) => {
  const theme = useTheme()
  const [date, setDate] = useState()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor="cardBackground" bgRepeat="no-repeat" bgPosition="top">
        <ModalHeader textAlign="flex-start" mt={theme.pxToRem(20)} w={'100%'}>
          <Text kind="extraBold" fontSize={24} w="100%">
            Selecione a data de recebimento da fatura
          </Text>

          <Flex>
            <TextInput
              //   noBorder
              mt="16px"
              type="date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              label="Data de recebimento"
              padding="5%"
              width="100%"
            />
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody mt={theme.pxToRem(24)} w="100%">
          <Flex mb={theme.pxToRem(24)} align="center" justify="flex-start" flexDir="column">
            <Text kind="bold" fontSize={14} color="danger">
              Essa ação não pode ser desfeita.
            </Text>
          </Flex>
          <Button
            isLoading={isLoading}
            isDisabled={!date || isLoading}
            w="100%"
            mb={theme.pxToRem(24)}
            onClick={() => onClick(date)}
          >
            Confirmar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
