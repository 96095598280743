import React from 'react'
import { FinanceResumeCard, AppointmentsResumeCard } from 'components'
import { Flex, useTheme } from '@chakra-ui/react'
import { settingsClients, cardsMobile } from './settings'
import { useTranslation } from 'react-i18next'

export const RenderMobile = ({ data, isBlocked }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Flex
      flexDirection="column"
      w="100%"
      pb={theme.pxToRem(60)}
      padding={isBlocked && theme.pxToRem(24)}
      pointerEvents={isBlocked && 'none'}
    >
      <FinanceResumeCard
        percentage="100%"
        h={theme.pxToRem(120)}
        mb={16}
        label={t('BALANCE_MONTH')}
        color={'white'}
        value={data?.month_balance}
        description={t('LIQUID_VALUE')}
        balance={data?.month_balance >= 0 ? 'successDark' : 'dangerCard'}
        background={data?.month_balance >= 0 ? 'success' : 'danger'}
        info={'lancamentos'}
      />
      <Flex w="100%">
        <FinanceResumeCard
          percentage="100%"
          h={theme.pxToRem(120)}
          mb={16}
          label={t('UNTIL_MOMENT')}
          color={'success'}
          value={data?.total_entries}
          description={t('TOTAL_ENTRIES')}
          info={'lancamentos'}
        />
        <FinanceResumeCard
          percentage="100%"
          h={theme.pxToRem(120)}
          mb={16}
          label={t('UNTIL_MOMENT')}
          color={'danger'}
          value={-data?.total_exits}
          description={t('TOTAL_EXITS')}
          info={'lancamentos'}
          last
        />
      </Flex>
      <FinanceResumeCard
        percentage="100%"
        h={theme.pxToRem(120)}
        mb={16}
        label={t('BARBERS')}
        color={'primary'}
        value={-data?.appointments_paid_total_comissions}
        description={t('BARBERS_COMMISSION')}
        info={'relatorio-barbeiros'}
      />
      <Flex w="100%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(410)}>
        {cardsMobile(data)?.map((item) => (
          <FinanceResumeCard
            {...item}
            percentage="47.5%"
            h={theme.pxToRem(120)}
            mb={16}
            label={t(item?.label)}
            description={t(item?.description)}
            last={item?.last}
          />
        ))}
      </Flex>
      <FinanceResumeCard
        full
        mb={16}
        label={t('PREDITICTION')}
        color={'success'}
        value={data?.month_total_prevision || 0}
        description={t('PREDITICTION_SUBTITLE')}
        balance={'cardBackground'}
        background="grey"
      />

      <AppointmentsResumeCard full item={data} info={'relatorio-barbeiros'} />
      <Flex flexDir="row" width="100%">
        {settingsClients(data)?.map((item) => (
          <FinanceResumeCard
            {...item}
            full
            notCurrency
            label={t(item?.label)}
            description={t(item?.description)}
          />
        ))}
      </Flex>
      {/* <Flex flexDir="row" width="100%">
        {settingsSignature(data)?.map((item) => (
          <FinanceResumeCard
            {...item}
            full
            notCurrency
            label={t(item?.label)}
            description={t(item?.description)}
          />
        ))}
      </Flex> */}
    </Flex>
  )
}
