import * as React from 'react'

function SvgFilter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.6274 3.60938H3.37275C2.79853 3.60938 2.43994 4.23516 2.72822 4.73438L8.17978 14.0016V19.6406C8.17978 20.0555 8.51259 20.3906 8.92509 20.3906H15.0751C15.4876 20.3906 15.8204 20.0555 15.8204 19.6406V14.0016L21.2743 4.73438C21.5602 4.23516 21.2017 3.60938 20.6274 3.60938ZM14.1423 18.7031H9.85791V15.0469H14.1446V18.7031H14.1423ZM14.3673 13.1578L14.1446 13.5469H9.85556L9.63291 13.1578L4.98525 5.29688H19.0149L14.3673 13.1578Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFilter
