import React, { useMemo } from 'react'
import {
  FinanceResumeCard,
  AppointmentsResumeCard,
  Text,
  MonthAppointmentsChart,
} from 'components'
import { Flex, useTheme } from '@chakra-ui/react'
import {
  resumeInfo,
  entriesInfo,
  firstSection,
  clientsInfoQuantity,
  exitsInfo,
} from './settings'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatHomeChart } from 'utils/chartData'
import { formatCurrency } from 'utils/formatCurrency'

export const RenderDesktop = ({
  data,
  averageClients,
  isBlocked,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const appointmentsByDate = useMemo(() => {
    if (data?.all_appointments) {
      return formatHomeChart(startDate, endDate, data)
    } 
    return []
  }, [data, startDate, endDate])


  return (
    <Flex
      flexDir="column"
      padding={isBlocked && theme.pxToRem(24)}
      w="100%"
      h="100vh"
      pointerEvents={isBlocked && 'none'}
    >
      <Flex flexDir="column" w="100%" align="flex-start">
        <Text mb="12px" kind="bold" fontSize="18px" align="center">
          {t('Faturamento Bruto no período selecionado')}
        </Text>
        <Flex w="100%" h="320px">
          <Flex
            alignItems="center"
            justifyContent="center"
            w={'60%'}
            h="100%"
            mr={theme.pxToRem(10)}
          >
            <MonthAppointmentsChart appointmentsByDate={appointmentsByDate} />
          </Flex>
          <Flex flexDir="column" w="40%" h="100%" justify="space-between">
            <FinanceResumeCard
              w="100%"
              label={'Total bruto de vendas no período'}
              description={
                'Valor total bruto das comandas finalizados no período selecionado'
              }
              color="success"
              balance="cardBackground"
              value={data?.current_month_finished_appointments_total_value || 0}
              h="auto"
              infoText={`Este valor é referente a soma de todas as comandas FINALIZADAS no período selecionado. ${
                data?.total_finished > 0
                  ? `Foram finalizadas ${data?.total_finished} comandas neste período.`
                  : ''
              }`}
            />
            <Flex flexDir="row" w="100%">
              <FinanceResumeCard
                label={'Total das comandas em aberto'}
                color="primary"
                balance="cardBackground"
                value={
                  data?.current_month_not_finished_appointments_total_value
                }
                h="auto"
                infoText={`Este valor é referente a soma de todas as comandas qua ainda NÃO FORAM FINALIZADAS no período selecionado". ${
                  data?.total_appointments > data?.total_finished
                    ? `Existem ${
                        data?.total_appointments - data?.total_finished
                      } comandas em aberto neste período.`
                    : ''
                }`}
              />
              {clientsInfoQuantity(data)?.map((item) => (
                <FinanceResumeCard
                  last={item.last}
                  full
                  notCurrency
                  label={t(item?.label)}
                  color={item?.color}
                  value={item?.last ? averageClients() : item?.value}
                  description={t(item?.description)}
                  h="auto"
                />
              ))}
            </Flex>

            <FinanceResumeCard
              w="100%"
              label={'Previsão do total bruto de vendas no período'}
              description={
                'Previsão do valor total bruto de todos as comandas do período'
              }
              color="success"
              balance="cardBackground"
              value={
                data?.current_month_not_finished_appointments_total_prevision
              }
              h="auto"
              infoText="Este valor é referente a soma de TODAS as comandas (finalizadas e não finalizadas) no período selecionado"
            />
          </Flex>
        </Flex>

        <Text mt="12px" mb="12px" kind="bold" fontSize="18px" align="center">
          {'Saldo do período'}
        </Text>
        <Flex w="100%" flexDir="row" justifyContent="space-between">
          <FinanceResumeCard
            label={'Saldo do período'}
            description={'Total de recebimentos - Total de saídas'}
            balance={
              data?.balance_calculated >= 0 ? 'successDark' : 'dangerCard'
            }
            background={data?.balance_calculated >= 0 ? 'success' : 'danger'}
            value={data?.balance_calculated || 0}
            h="auto"
          />
          <FinanceResumeCard
            label="Total de Recebimentos no período"
            description="(taxas de pagamento já descontadas)"
            color="success"
            balance="cardBackground"
            value={data?.entries_calculated || 0}
            h="auto"
          />
          <FinanceResumeCard
            label="Total de Saídas no período"
            description="Total de despesas no período"
            color="danger"
            balance="cardBackground"
            value={-data?.exist_calculated || 0}
            h="auto"
            last
          />
        </Flex>
        <Text mt="12px" mb="12px" kind="bold" fontSize="18px" align="center">
          {'Valores a receber no período selecionado'}
        </Text>
        <Flex w="100%" flexDir="row" justifyContent="space-between">
          <FinanceResumeCard
            label="Vendas realizadas DENTRO do período e com data de recebimento dentro período selecionado"
            color="success"
            balance="cardBackground"
            value={
              data?.current_month_paid_appointments_total_discounting_payment_taxes
            }
            h="auto"
          />
          <FinanceResumeCard
            label="Vendas realizadas ANTES do período e com data de recebimento dentro período selecionado"
            color="success"
            balance="cardBackground"
            value={
              data?.past_month_paid_appointments_total_discounting_payment_taxes ||
              0
            }
            h="auto"
          />
          <FinanceResumeCard
            label="Lançamentos manuais de receita"
            description={
              'Valor total de receitas (entradas) lançadas manualmente no período selecionado'
            }
            color="success"
            balance="cardBackground"
            value={data?.revenues_total_value || 0}
            h="auto"
          />
          <FinanceResumeCard
            label="Venda de pacotes e Assinaturas"
            description={
              'Valor total de vendas de pacote de serviços e planos de assinatura'
            }
            color="success"
            balance="cardBackground"
            value={data?.signatures_paid_total_with_discounts || 0}
            h="auto"
          />
          <FinanceResumeCard
            label={'Valor total líquido a receber no período selecionado'}
            description={'(taxas de pagamento já descontadas)'}
            balance="successDark"
            background="success"
            value={data?.entries_calculated}
            h="auto"
            last
          />
        </Flex>
        <Text mt="12px" mb="12px" kind="bold" fontSize="18px" align="center">
          {t('Despesas pagas no período selecionado')}
        </Text>
        <Flex w="100%" flexDir="row" justifyContent="space-between">
          {exitsInfo(data)?.map((item, index) => (
            <FinanceResumeCard
              {...item}
              h="auto"
              mb={16}
              label={t(item?.label)}
              description={t(item?.description)}
              last={index === 3}
              info={item?.info}
            />
          ))}
        </Flex>
        <Text mb="12px" kind="bold" fontSize="18px" align="center">
          {t('Vendas do período selecionado')}
        </Text>
        <Flex w="100%" flexDir="row" justifyContent="space-between">
          <FinanceResumeCard
            label={'Valor total bruto de vendas'}
            description={'das comandas finalizadas no período'}
            color="success"
            balance="cardBackground"
            value={data?.current_month_finished_appointments_total_value || 0}
            h="auto"
            infoText={`Este valor é referente a soma de todas as comandas FINALIZADAS no período selecionado. ${
              data?.total_finished > 0
                ? `Foram finalizadas ${data?.total_finished} comandas neste período.`
                : ''
            }`}
          />
          <FinanceResumeCard
            label={'Taxas de pagamento'}
            description={'das comandas finalizadas no período'}
            color="primary"
            balance="cardBackground"
            value={
              -data?.current_month_finished_appointments_payment_taxes || 0
            }
            h="auto"
          />
          <FinanceResumeCard
            label={'Total líquido das vendas'}
            description={'das comandas finalizadas no período'}
            balance="cardBackground"
            color="success"
            value={
              data?.current_month_finished_appointments_total_discounting_payment_taxes ||
              0
            }
            h="auto"
          />
          <FinanceResumeCard
            label={'Total previsto para receber no período selecionado'}
            balance="cardBackground"
            color="success"
            value={
              data?.current_month_paid_appointments_total_discounting_payment_taxes
            }
            h="auto"
          />
          <FinanceResumeCard
            label={'Total previsto para receber APÓS o período selecionado'}
            balance="cardBackground"
            color="success"
            value={
              data?.next_month_payments_appointments_total_discounting_payment_taxes
            }
            h="auto"
          />
        </Flex>
        <Text mt="12px" kind="regular" fontSize="12px" align="center">
          Comandas finalizadas no período e com data de recebimento DENTRO
          período selecionado:{' '}
          {formatCurrency(data?.current_month_paid_appointments_total_value)} -{' '}
          {formatCurrency(
            data?.current_month_paid_appointments_payment_discounts
          )}{' '}
          (taxas) ={' '}
          {formatCurrency(
            data?.current_month_paid_appointments_total_discounting_payment_taxes
          )}
        </Text>
        <Text mt="8px" mb="48px" kind="regular" fontSize="12px" align="center">
          Comandas finalizadas no período e com data de recebimento APÓS o
          período selecionado:{' '}
          {formatCurrency(data?.next_month_payments_appointments_total_value)} -{' '}
          {formatCurrency(
            data?.next_month_payments_appointments_payment_discounts
          )}{' '}
          (taxas) ={' '}
          {formatCurrency(
            data?.next_month_payments_appointments_total_discounting_payment_taxes
          )}
        </Text>
      </Flex>
    </Flex>
  )
}
