import React, { useState } from 'react'
import { Button, Flex, Text, TextInput } from 'components'
import { Box, useToast } from '@chakra-ui/react'
import { Layout } from '../Components'
import { useMutation, useQuery } from 'react-query'
import { api } from 'services/api/config'
import { formatCurrency, unformatCurrency } from 'utils/formatCurrency'

export const VerifyAsaasBarbershopStatus = () => {
  const [barbershopId, setBarbershopId] = useState(null)
  const [enabled, setEnabled] = useState(false)
  const toast = useToast()

  const { data, isLoading } = useQuery(
    'verify-asaas-barbershop',
    () => api.get(`v3/barbershop/subscribe-documents/${barbershopId}`),
    {
      onError: (error) => {
        toast({
          title: 'Erro ao verificar barbearia',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
      enabled,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Layout title="Verificar status asaas">
      <Flex align="center" justify="center" flexDir="column" w="100%" h="100%" p="60px">
        <TextInput
          noBorder
          w="28%"
          mr="3%"
          name="barbershopId"
          placeHolder="10"
          value={barbershopId}
          onChange={(e) => setBarbershopId(e.target.value)}
          label="Id da barbearia"
          padding="10px"
          h="56px"
        />
        <Button isLoading={isLoading} onClick={() => setEnabled(true)} mt="80px">
          Verificar
        </Button>
        {data && (
          <Box mt="16px">
            <Box w="500px" border="1px" borderColor="grey" h="32px" mb="12px">
              <Text align="center" justify="center">{`Status: ${data?.data?.status}`}</Text>
            </Box>
            <Box w="500px" border="1px" borderColor="grey" h="32px" mb="12px">
              <Text align="center" justify="center">{`Nome do responsável: ${data?.data?.name}`}</Text>
            </Box>
            <Box w="500px" border="1px" borderColor="grey" h="32px" mb="12px">
              <Text align="center" justify="center">{`Link para envio: ${data?.data?.onboardingUrl}`}</Text>
            </Box>
            {data?.data?.rejectReasons && (
              <Box w="500px" border="1px" borderColor="grey" h="250px" mb="12px">
                <Text align="center" justify="center">{`Motivo rejeição`}</Text>
                <Text align="center" justify="center">
                  {data?.data?.rejectReasons}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Flex>
    </Layout>
  )
}

VerifyAsaasBarbershopStatus.path = '/internal-adm/verify-asaas-barbershop'
VerifyAsaasBarbershopStatus.title = 'Verificar barbearia'
VerifyAsaasBarbershopStatus.secured = true
