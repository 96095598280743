import React from 'react'
import { Box, useTheme } from '@chakra-ui/react'
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts'

export const LineChartRechart = ({ data }) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="flex-start"
      p={theme.pxToRem(16)}
      w="100%"
      h="100%"
      bg="cardBackground"
      borderRadius={theme.pxToRem(12)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart width={224} height={250} data={data?.data} margin={{ left: -20 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#70C270" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#70C270" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey={data?.key} stroke="#70C270" fillOpacity={1} fill="url(#colorUv)" dot={true} />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  )
}
