import * as React from 'react'

function SvgStar(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 11.95L4.292 14 5 9.658 2 6.583l4.146-.633L8 2l1.854 3.95L14 6.583l-3 3.075.708 4.342L8 11.95z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgStar
