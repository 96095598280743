import * as React from 'react'

function SvgSearch(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.333 27.167c7.088 0 12.834-5.746 12.834-12.834C27.167 7.246 21.42 1.5 14.333 1.5 7.246 1.5 1.5 7.246 1.5 14.333c0 7.088 5.746 12.834 12.833 12.834zM34.5 34.5l-11-11"
        stroke="currentColor"
        strokeWidth={2.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgSearch
