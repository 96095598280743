import * as React from 'react'

function SvgMoneyBag(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.041 5.709a5.96 5.96 0 00-1.585 1.387C3.468 8.312 2.9 9.846 2.9 11.31c0 .759.152 1.38.43 1.883.28.504.684.885 1.18 1.168.987.563 2.342.74 3.822.74 1.481 0 2.836-.177 3.823-.74.496-.284.9-.664 1.179-1.168.279-.504.43-1.125.43-1.883 0-1.463-.568-2.997-1.556-4.213a5.96 5.96 0 00-1.585-1.387c.074-.106.134-.224.176-.352h0a1.328 1.328 0 00-.788-1.656L11 1.98h0a.51.51 0 00-.3-.743A8.474 8.474 0 008.332.9c-.804 0-1.6.113-2.367.336m.076 4.473l-.076-4.473m.076 4.473a1.335 1.335 0 01-.176-.352h0a1.328 1.328 0 01.788-1.656M6.04 5.71a5.33 5.33 0 01.087-.05L6.653 3.7m-.688-2.465s0 0 0 0l.028.096-.028-.096zM6.653 3.7a1.57 1.57 0 01.095-.034L5.752 1.93a.41.41 0 01.241-.598A8.374 8.374 0 018.332 1c.795 0 1.582.112 2.339.332a.41.41 0 01.241.598l-.996 1.737-3.263.034zm0 0l.05.088-.05-.088zm2.49.817h.003s0 0 0 0c.163.033.325.076.486.129a.311.311 0 01-.044.6 4.257 4.257 0 00-1.256-.19c-.424 0-.843.063-1.254.19a.308.308 0 01-.244-.208.311.311 0 01.198-.392 4.14 4.14 0 012.111-.129zM8.332 1.92c.487 0 .97.047 1.444.14l-.8 1.397a5.13 5.13 0 00-1.287 0L6.888 2.06c.473-.093.957-.14 1.444-.14zm0 12.165c-1.598 0-2.702-.21-3.404-.658-.691-.44-1.008-1.123-1.008-2.118 0-1.21.496-2.546 1.328-3.57.877-1.078 1.973-1.662 3.084-1.662 1.223 0 2.303.701 3.084 1.663.832 1.023 1.328 2.36 1.328 3.569 0 .995-.317 1.677-1.008 2.118-.702.448-1.806.658-3.404.658z"
        fill="#EBE9E1"
        stroke="#EBE9E1"
        strokeWidth={0.2}
      />
      <path
        d="M7.94 7.752v.35c-.59.035-1.053.55-1.053 1.166 0 .638.496 1.167 1.115 1.167h.659c.17 0 .33.162.33.383 0 .223-.16.382-.33.382H7.477a.393.393 0 100 .787h.463v.356a.393.393 0 10.787 0v-.36c.59-.034 1.05-.55 1.05-1.165 0-.64-.495-1.167-1.114-1.167h-.659c-.17 0-.33-.162-.33-.383 0-.22.16-.382.33-.382h1.163a.393.393 0 100-.787h-.44v-.35h0a.394.394 0 00-.787.003z"
        fill="#EBE9E1"
        stroke="#EBE9E1"
        strokeWidth={0.2}
      />
    </svg>
  )
}

export default SvgMoneyBag
