import React from 'react'
import { Image } from '@chakra-ui/react'
import { Text, Flex, Button } from 'components'
import { theme } from 'styles'
import devices from '../images/dispositivos.png'
import { StoreIcons } from './stores'
import { useBreakpoint } from 'services/hooks'

const texts = [
  'O BestBarbers é o melhor e mais completo aplicativo do mercado de barbearias do Brasil!',
  'Na versão Premium, além do agendamento, estão disponíveis as funções de controle financeiro com extrato, divisão de comissões por barbeiro e relatórios para análise de lucro.',
  'E mais, as barbearias Premium possuem um perfil do estabelecimento no app e link personalizado para disponibilizar aos clientes via redes sociais e WhatsApp',
  'Além disso, os seus barbeiros também terão acesso individual para checarem a própria agenda, histórico de atendimento e comissões!',
]

export const About = ({ clickButton }) => {
  const { isDesktop } = useBreakpoint()
  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        align="flex-start"
        justify="center"
        px="5vw"
        py="5vh"
        flexDir="column"
        bgColor="menuBackground"
      >
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(14)}
          kind="medium"
          mb={theme.pxToRem(8)}
        >
          SOBRE O APP
        </Text>
        <Flex bg="primary" h="2px" w="10%" mb={theme.pxToRem(12)} />
        <Text
          fontSize={theme.pxToRem(30)}
          kind="extraBold"
          mb={theme.pxToRem(12)}
        >
          Feito para barbearias e barbeiros
        </Text>
        {texts.map((item) => (
          <Text
            mb={theme.pxToRem(8)}
            color="textLight"
            fontSize={theme.pxToRem(12)}
            kind="medium"
          >
            {item}
          </Text>
        ))}
        <Button mt={theme.pxToRem(16)} w="100%" onClick={clickButton}>
          Começar agora
        </Button>
        <Flex w="100%" flexDir="column" align="center" mt={theme.pxToRem(40)}>
          <Image src={devices} w="80vw" h="auto" />
          <Text
            mt={theme.pxToRem(24)}
            fontSize={theme.pxToRem(12)}
            kind="medium"
            color="primary"
          >
            App disponível para iOS, Android e navegadores web.
          </Text>
          <StoreIcons />
        </Flex>
      </Flex>
    )
  }
  return (
    <Flex
      w={'100vw'}
      h={'97vh'}
      align="center"
      justify="center"
      pl="10vw"
      pr="5vw"
      flexDir="row"
      bgColor="menuBackground"
    >
      <Flex w="40%" mr="4%" h="100%" flexDir="column" justify="center">
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(14)}
          kind="medium"
          mb={theme.pxToRem(8)}
          mt={theme.pxToRem(24)}
        >
          SOBRE O APP
        </Text>
        <Flex bg="primary" h="2px" w="5%" mb={theme.pxToRem(24)} />
        <Text
          w={theme.pxToRem(600)}
          fontSize={theme.pxToRem(36)}
          kind="extraBold"
          mb={theme.pxToRem(24)}
        >
          Feito para barbearias e barbeiros
        </Text>
        {texts.map((item) => (
          <Text
            mb={theme.pxToRem(8)}
            color="textLight"
            fontSize={theme.pxToRem(16)}
            kind="medium"
          >
            {item}
          </Text>
        ))}
        <Text fontSize={theme.pxToRem(14)} kind="medium" color="primary">
          App disponível para iOS, Android e navegadores web.
        </Text>

        <StoreIcons />
      </Flex>
      <Flex flexDir="column" align="center">
        <Image src={devices} w="50vw" mt="8vh" h="auto" />
        <Button mt={theme.pxToRem(40)} size="small" onClick={clickButton}>
          Começar agora
        </Button>
      </Flex>
    </Flex>
  )
}
