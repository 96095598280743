import * as React from 'react'

function SvgChangeAccount(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M14.947 2.85c.06 0 .119.009.176.026h0c2.561.804 4.752 2.418 6.2 4.572 1.449 2.154 2.065 4.714 1.744 7.249-.321 2.535-1.56 4.885-3.504 6.655-1.864 1.697-4.267 2.76-6.832 3.032l.887.838h0c.107.102.169.24.169.387a.534.534 0 01-.168.387l-.002.001h0a.581.581 0 01-.789 0h0l-1.818-1.695h0a.504.504 0 01-.1-.132l-.017-.028-.01-.015-.005-.018-.013-.04-.005-.016-.002-.017a.373.373 0 010-.037.524.524 0 01.003-.195l.004-.018.008-.018a.298.298 0 01.018-.03l.005-.017.003-.01.004-.008a.4.4 0 01.027-.049.437.437 0 01.1-.128l1.79-1.696a.567.567 0 01.402-.172.59.59 0 01.412.156.543.543 0 01.169.402.527.527 0 01-.187.39l-.7.662c2.248-.3 4.337-1.276 5.955-2.785 1.724-1.608 2.81-3.724 3.078-5.993a9.533 9.533 0 00-1.603-6.484c-1.306-1.925-3.273-3.371-5.576-4.094a.565.565 0 01-.308-.231.526.526 0 01.12-.704.583.583 0 01.364-.127zm0 0s0 0 0 0V3v-.15s0 0 0 0zM9.053 24.15a.593.593 0 01-.176-.026h0c-2.561-.804-4.752-2.418-6.2-4.572C1.228 17.398.612 14.838.933 12.303c.321-2.535 1.56-4.885 3.504-6.655C6.3 3.95 8.704 2.888 11.269 2.616l-.887-.838h0a.533.533 0 01-.169-.387c0-.147.062-.286.168-.387l.002-.001h0a.581.581 0 01.789 0h0l1.818 1.696h0a.5.5 0 01.1.131l.017.028.01.015.005.018.013.04.005.016.002.017a.374.374 0 010 .037c.012.064.01.13-.003.195l-.004.019-.008.016a.3.3 0 01-.018.031l-.005.018-.003.009-.004.008a.403.403 0 01-.027.049.436.436 0 01-.1.128L11.18 5.17a.568.568 0 01-.402.173.59.59 0 01-.412-.157.542.542 0 01-.169-.402.527.527 0 01.187-.39l.7-.662c-2.248.3-4.337 1.276-5.955 2.785-1.724 1.608-2.81 3.724-3.078 5.993a9.533 9.533 0 001.603 6.484c1.306 1.925 3.274 3.371 5.576 4.094.127.04.236.12.308.232a.526.526 0 01-.12.703.582.582 0 01-.365.127zm0 0s0 0 0 0V24v.15s0 0 0 0z"
        strokeWidth={0.3}
      />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M12.01 8a4.006 4.006 0 014.006 4.006c0 1.304-.606 2.515-1.677 3.26a6.329 6.329 0 013.68 3.96c.093.326-.047.652-.373.746-.326.093-.652-.047-.745-.373-.652-2.143-2.656-3.587-4.892-3.587A5.088 5.088 0 007.165 19.6c-.093.326-.42.466-.745.373-.326-.094-.513-.42-.373-.746a6.328 6.328 0 013.68-3.96 3.979 3.979 0 01-1.724-3.26C8.003 9.816 9.82 8 12.01 8zm0 1.211a2.803 2.803 0 00-2.795 2.795c0 1.584 1.257 2.842 2.794 2.842a2.823 2.823 0 002.842-2.842c0-1.537-1.258-2.795-2.842-2.795z"
      />
    </svg>
  )
}

export default SvgChangeAccount
