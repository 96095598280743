import React from 'react'

export default function StockSvg(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.7076 1.71929C21.8897 1.53069 21.9905 1.27808 21.9882 1.01589C21.986 0.75369 21.8808 0.502878 21.6954 0.31747C21.51 0.132062 21.2592 0.0268924 20.997 0.0246139C20.7348 0.0223355 20.4822 0.12313 20.2936 0.305288L18.0006 2.59829L15.7076 0.305288C15.6153 0.209778 15.505 0.133596 15.383 0.0811869C15.261 0.0287779 15.1298 0.00119157 14.997 3.77571e-05C14.8642 -0.00111606 14.7325 0.0241854 14.6096 0.0744663C14.4867 0.124747 14.3751 0.199 14.2812 0.292893C14.1873 0.386786 14.113 0.498438 14.0627 0.621334C14.0125 0.74423 13.9872 0.87591 13.9883 1.00869C13.9895 1.14147 14.0171 1.27269 14.0695 1.39469C14.1219 1.5167 14.1981 1.62704 14.2936 1.71929L16.5866 4.01229L14.2936 6.30529C14.1114 6.49389 14.0106 6.74649 14.0129 7.00869C14.0152 7.27089 14.1203 7.5217 14.3058 7.70711C14.4912 7.89251 14.742 7.99768 15.0042 7.99996C15.2664 8.00224 15.519 7.90145 15.7076 7.71929L18.0006 5.42629L20.2936 7.71929C20.4812 7.9068 20.7357 8.01208 21.0009 8.01199C21.2662 8.0119 21.5206 7.90643 21.7081 7.71879C21.8956 7.53115 22.0009 7.2767 22.0008 7.01143C22.0007 6.74616 21.8952 6.4918 21.7076 6.30429L19.4146 4.01229L21.7076 1.71929Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.684448 20.4617L4.00045 21.5667V30.0127C4.00045 30.2206 4.06528 30.4234 4.1859 30.5927C4.30652 30.7621 4.47694 30.8897 4.67345 30.9577L17.6654 35.4547C17.8708 35.5284 18.0947 35.5323 18.3024 35.4657L18.3164 35.4617L18.3314 35.4567L31.3274 30.9577C31.524 30.8897 31.6944 30.7621 31.815 30.5927C31.9356 30.4234 32.0004 30.2206 32.0005 30.0127V21.5667L35.3165 20.4617C35.4694 20.4108 35.6078 20.3237 35.7198 20.2078C35.8319 20.0919 35.9143 19.9507 35.9599 19.7961C36.0056 19.6415 36.0132 19.4782 35.9821 19.32C35.9511 19.1618 35.8822 19.0135 35.7814 18.8877L31.7814 13.8877C31.6628 13.74 31.5047 13.629 31.3254 13.5677L18.3274 9.06767C18.1156 8.99437 17.8853 8.99437 17.6734 9.06767L4.67545 13.5677C4.49618 13.6289 4.33803 13.7399 4.21945 13.8877L0.219448 18.8877C0.118686 19.0135 0.0498342 19.1618 0.0187585 19.32C-0.0123172 19.4782 -0.00469527 19.6415 0.0409748 19.7961C0.0866449 19.9507 0.169009 20.0919 0.28105 20.2078C0.393092 20.3237 0.531488 20.4108 0.684448 20.4617ZM15.3194 24.5857L17.0004 22.1857V33.1087L6.00045 29.3007V22.2327L14.1844 24.9607C14.3889 25.0286 14.6098 25.0287 14.8143 24.961C15.0188 24.8932 15.196 24.7622 15.3194 24.5857ZM8.05745 14.5127L18.0004 17.9547L27.9434 14.5127L18.0004 11.0707L8.05745 14.5127ZM20.6814 24.5857L19.0004 22.1867V33.1097L30.0004 29.3017V22.2327L21.8164 24.9607C21.612 25.0286 21.3911 25.0287 21.1866 24.961C20.9821 24.8932 20.8049 24.7622 20.6814 24.5857ZM5.34045 15.6887L2.67745 19.0177L8.18845 20.8547L14.1084 22.8277L16.4214 19.5247L16.2864 19.4777L5.34045 15.6877V15.6887ZM33.3235 19.0177L30.6604 15.6877L19.5794 19.5247L21.8924 22.8277L33.3235 19.0177Z"
        fill="currentColor"
      />
    </svg>
  )
}
