import * as React from 'react'

function SvgBarberShop(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.2655 12.8C28.2655 12.6448 28.2247 12.4904 28.1351 12.356L25.0695 7.7576V6.4C25.0695 5.0768 23.9927 4 22.6695 4H9.86953C8.54633 4 7.46953 5.0768 7.46953 6.4V7.7576L4.40393 12.356C4.31433 12.4904 4.27353 12.6448 4.27353 12.8H4.26953V14.8C4.26953 16.0456 4.90553 17.144 5.86953 17.7904V27.2C5.86953 27.6416 6.22793 28 6.66953 28H12.2695H20.2695H25.8695C26.3111 28 26.6695 27.6416 26.6695 27.2V17.7904C27.6335 17.1432 28.2695 16.0448 28.2695 14.8V12.8H28.2655ZM9.86953 5.6H22.6695C23.1103 5.6 23.4695 5.9592 23.4695 6.4V7.2H9.06953V6.4C9.06953 5.9592 9.42873 5.6 9.86953 5.6ZM8.69753 8.8H23.8415L25.9751 12H6.56473L8.69753 8.8ZM5.86953 14.8V13.6H9.86953V14.8C9.86953 15.9024 8.97193 16.8 7.86953 16.8C6.76713 16.8 5.86953 15.9024 5.86953 14.8ZM11.4695 13.6H15.4695V14.8C15.4695 15.9024 14.5719 16.8 13.4695 16.8C12.3671 16.8 11.4695 15.9024 11.4695 14.8V13.6ZM17.0695 13.6H21.0695V14.8C21.0695 15.9024 20.1719 16.8 19.0695 16.8C17.9671 16.8 17.0695 15.9024 17.0695 14.8V13.6ZM13.0695 26.4V20.8H19.4695V26.4H13.0695ZM25.0695 26.4H21.0695V20C21.0695 19.5584 20.7111 19.2 20.2695 19.2H12.2695C11.8279 19.2 11.4695 19.5584 11.4695 20V26.4H7.46953V18.3592C7.60313 18.3744 7.73273 18.4 7.86953 18.4C9.00633 18.4 10.0095 17.86 10.6695 17.0344C11.3295 17.86 12.3327 18.4 13.4695 18.4C14.6063 18.4 15.6095 17.86 16.2695 17.0344C16.9295 17.86 17.9327 18.4 19.0695 18.4C20.2063 18.4 21.2095 17.86 21.8695 17.0344C22.5295 17.86 23.5327 18.4 24.6695 18.4C24.8063 18.4 24.9359 18.3744 25.0695 18.3592V26.4ZM24.6695 16.8C23.5671 16.8 22.6695 15.9024 22.6695 14.8V13.6H26.6695V14.8C26.6695 15.9024 25.7719 16.8 24.6695 16.8Z"
        fill="#E6B817"
      />
    </svg>
  )
}

export default SvgBarberShop
