import { t } from "i18next"

export const subscriptionBenefitsNew = [
    {
      id: 0,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_1'),
      premium: 'Check',
    },
    {
      id: 1,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_2'),
      premium: 'Check',
    },
    {
      id: 2,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_3'),
      premium: 'Check',
    },
    {
      id: 3,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_4'),
      premium: 'Check',
    },
    {
      id: 4,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_5'),
      premium: 'Check',
    },
    {
      id: 5,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_6'),
      premium: 'Check',
    },
    {
      id: 6,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_7'),
      premium: 'Check',
    },
    {
      id: 7,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_8'),
      premium: 'Check',
    },
    {
      id: 8,
      name: t('CREATE_ACCOUNT_BENEFITS_BARBERSHOP_9'),
      premium: 'Check',
    },
  ]