import React, { useState, useEffect, useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import barbershopSrc from 'assets/img/placeholders/barbershop.png'
import { pxToRem } from 'styles/metrics'
import { formatPhone } from 'utils/phone-formater'
import { useLazyFetch } from 'services/hooks'
import { useToast } from '@chakra-ui/react'
import { Box, Button, Flex, Text, Icon, Image } from 'components/atoms'
import { ModalMenu, ObservationCard, ModalAlert, FinanceResumeCard } from 'components/molecules'
import { useTheme } from '@chakra-ui/react'
import { ObservationsModal } from '../ObservationsModal'

import {
  INTERNAL_GET_USER_APPOINTMENTS,
  INTERNAL_GET_USER_OBSERVATIONS,
  INTERNAL_DELETE_OBSERVATION,
} from 'services/api/endpoints'
import { colors } from 'styles/colors'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'

const UserInfo = ({ icon, label, text }) => {
  return (
    <Box display="flex" alignItems="center" mb={pxToRem(8)}>
      <Box
        marginRight={pxToRem(16)}
        padding={pxToRem(12)}
        backgroundColor={colors.whiteTransparent[100]}
        borderRadius={pxToRem(5)}
        width={pxToRem(44)}
        height={pxToRem(44)}
        display="flex"
        alignItems="center"
      >
        <Icon name={icon} color={'primary'} size={pxToRem(16)} />
      </Box>
      <Box>
        <Text kind="regular">{label}</Text>
        <Text kind="bold">{text}</Text>
      </Box>
    </Box>
  )
}

const handleStatus = (status) => {
  switch (status) {
    case 'published':
      return 'Publicada'
    case 'ready_to_publish':
      return 'Completou o cadastro e não ativou'
    case 'not_published':
      return 'Cadastro incompleto'
    default:
      return ''
  }
}

export const UserDetailsModal = ({ isOpen, onClose, user: info }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const toast = useToast()
  const { userData } = useBarbershopStore()

  const [getAppointments, { data: appointments }] = useLazyFetch(INTERNAL_GET_USER_APPOINTMENTS(info?.user?.id), {
    method: 'get',
  })

  const [getObservations, { data: observations }] = useLazyFetch(INTERNAL_GET_USER_OBSERVATIONS(info?.user?.id), {
    method: 'get',
  })

  const [deleteObservation] = useLazyFetch(INTERNAL_DELETE_OBSERVATION(selected?.id), {
    method: 'delete',
    onCompleted: async () => {
      await getObservations()
      setSelected(null)
      toast({
        title: 'Observação excluida com sucesso!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: () => {
      toast({
        title: 'Falha ao excluida observação',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const totalReceived = useMemo(() => {
    const total =
      !!appointments?.data?.length &&
      appointments?.data.map((item) => item?.total_value).reduce((total, current) => total + current, 0)
    return total
  }, [appointments])

  useEffect(() => {
    if (info?.user?.id) {
      getAppointments()
      getObservations()
    }
  }, [info])

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title="Detalhes do usuário"
    >
      <Flex align="flex-start" flexDir="column" w="100%" h="100%">
        <Flex w="100%" justify="center">
          <Image
            borderRadius={pxToRem(16)}
            w="10vw"
            h="10vw"
            fit={info?.profile_image_url ? 'cover' : 'contain'}
            src={info?.profile_image_url || barbershopSrc}
            mb={pxToRem(12)}
          />
        </Flex>
        <Text kind="extraBold" fontSize={pxToRem(24)} mb={pxToRem(12)}>
          {info?.name}
        </Text>
        <UserInfo icon={'Phone'} label="Telefone" text={formatPhone(info?.user?.phone.substring(2), userData)} />
        <UserInfo icon={'Email'} label="Email" text={info?.user?.email} />
        <UserInfo icon={'Person'} label="Status" text={handleStatus(info?.status)} />
        <Text kind="extraBold" fontSize={pxToRem(18)} mb={pxToRem(12)} mt={pxToRem(12)}>
          Agendamentos
        </Text>
        <Flex mb={pxToRem(24)} w="96%" flexDir="row" align="center" justify="space-between">
          <FinanceResumeCard
            notCurrency
            label="Agendamentos"
            description="Realizados"
            color="textLight"
            value={appointments?.total || 0}
            width="33%"
            fontSize={16}
          />
          <FinanceResumeCard
            notCurrency
            label="Último"
            description="Agendamento"
            color="textLight"
            value={
              appointments?.data?.[0]?.simple_date
                ? format(parseISO(appointments?.data?.[0]?.simple_date), 'dd/MM/yyyy')
                : '-'
            }
            width="33%"
            fontSize={16}
          />
          <FinanceResumeCard
            label="Total"
            description="Faturado"
            color="textLight"
            value={totalReceived || 0}
            width="33%"
            fontSize={16}
          />
        </Flex>
        <Flex w="93%" flexDir="row" align="center" justify="space-between" mb={pxToRem(24)} mt={pxToRem(12)}>
          <Text kind="extraBold" fontSize={pxToRem(18)}>
            Observações
          </Text>
          <Button size="small" onClick={() => setModalOpen(true)}>
            Nova observação
          </Button>
        </Flex>
        {observations?.length ? (
          observations.map((item) => (
            <ObservationCard
              inModal
              key={item.id}
              mb={theme.pxToRem(12)}
              item={item}
              onDelete={() => setSelected(item)}
            />
          ))
        ) : (
          <Flex w="100%" align="center" flexDir="column">
            <Icon name={'Search'} color={'textMedium'} />
            <Text kind="regular" fontSize={pxToRem(14)} mt={pxToRem(12)}>
              Nenhuma observação encontrada
            </Text>
          </Flex>
        )}
        <Text kind="regular" color="transparent" mb={pxToRem(64)} mt={pxToRem(12)}>
          BestBarbers
        </Text>
      </Flex>
      <ObservationsModal
        getObservations={getObservations}
        onClose={() => setModalOpen(false)}
        isModalOpen={modalOpen}
        info={info}
      />

      <ModalAlert
        isOpen={!!selected}
        onClose={() => setSelected(null)}
        onConfirm={() => deleteObservation()}
        primaryButtonLabel={t('DELETE')}
        modalTitle="Deletar observação"
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          Tem certeza que desjea deletar esta observação?
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}
