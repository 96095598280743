import React from 'react'
import { Flex as ChakraFlex } from '@chakra-ui/react'

export const Flex = (props) => {
  return (
    <ChakraFlex {...props}>
      { props.children }
    </ChakraFlex>
  )
}

Flex.defaultProps = {
}

Flex.propTypes = {
  ...Flex.propTypes,
}
