import { api } from 'services/api/config'
import { useToast } from '@chakra-ui/react'
import { t } from 'services/translation'

function isObject(obj) {
  return obj === Object(obj)
}

const handleError = (error) => {
  if (error.response?.data?.error && isObject(error?.response?.data?.error)) {
    return {
      error: 'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
    }
  }
  if (error?.response?.data?.error) {
    return {
      error: error.response.data.error,
    }
  }
  return {
    error: 'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
  }
}

export const useFetch = () => {
  const toast = useToast()
  async function onFetch({ url, method, params, onSuccess, onError, message, headers, config }) {
    const res = await api[method || 'get'](url, params || {}, {
      ...config,
      ...headers,
    })
      .then(async (response) => {
        !!onSuccess && onSuccess(response.data)
        if (message?.success) {
          toast({
            title: t(message?.success),
            status: 'success',
            duration: 4000,
            isClosable: true,
          })
        }
        return response.data
      })
      .catch((error) => {
        const errorMessage = handleError(error)
        !!onError && onError(errorMessage)
        if (message?.error) {
          toast({
            title: t(message?.error),
            description: error?.response?.data?.error?.message || error?.response?.data?.error,
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        }
      })
    return res
  }
  async function onFetchExport({ url, method, params, onSuccess, onError, message, headers, config }) {
    const res = await api[method || 'get'](url, params || {}, config, headers)
      .then(async (response) => {
        !!onSuccess && onSuccess(response.data)
        if (message?.success) {
          toast({
            title: t(message?.success),
            status: 'success',
            duration: 4000,
            isClosable: true,
          })
        }
        return response.data
      })
      .catch((error) => {
        const errorMessage = handleError(error)
        !!onError && onError(errorMessage)
        if (message?.error) {
          toast({
            title: t(message?.error),
            description: error?.response?.data?.error?.message || error?.response?.data?.error,
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        }
      })
    return res
  }
  return { onFetch, onFetchExport }
}
