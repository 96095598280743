import React from 'react'

function ReportChart(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.65536 2.65548C3.17108 2.13976 3.87056 1.85002 4.5999 1.85002H15.3999C16.1292 1.85002 16.8287 2.13976 17.3444 2.65548C17.8602 3.17121 18.1499 3.87068 18.1499 4.60002V15.4C18.1499 16.1294 17.8602 16.8288 17.3444 17.3446C16.8287 17.8603 16.1292 18.15 15.3999 18.15H4.5999C3.87056 18.15 3.17108 17.8603 2.65536 17.3446C2.13963 16.8288 1.8499 16.1294 1.8499 15.4V4.60002C1.8499 3.87068 2.13963 3.17121 2.65536 2.65548ZM4.5999 0.150024C3.41969 0.150024 2.28781 0.618863 1.45328 1.4534C0.61874 2.28794 0.149902 3.41981 0.149902 4.60002V15.4C0.149902 16.5802 0.61874 17.7121 1.45328 18.5466C2.28781 19.3812 3.41969 19.85 4.5999 19.85H15.3999C16.5801 19.85 17.712 19.3812 18.5465 18.5466C19.3811 17.7121 19.8499 16.5802 19.8499 15.4V4.60002C19.8499 3.41981 19.3811 2.28794 18.5465 1.4534C17.712 0.618863 16.5801 0.150024 15.3999 0.150024H4.5999ZM5.7499 8.00003C5.33569 8.00003 4.9999 8.33582 4.9999 8.75003V14.25C4.9999 14.6642 5.33569 15 5.7499 15C6.16411 15 6.4999 14.6642 6.4999 14.25V8.75003C6.4999 8.33582 6.16411 8.00003 5.7499 8.00003ZM9.2499 5.75003C9.2499 5.33582 9.58569 5.00003 9.9999 5.00003C10.4141 5.00003 10.7499 5.33582 10.7499 5.75003V14.25C10.7499 14.6642 10.4141 15 9.9999 15C9.58569 15 9.2499 14.6642 9.2499 14.25V5.75003ZM14.2499 9.00003C13.8357 9.00003 13.4999 9.33582 13.4999 9.75003V14.25C13.4999 14.6642 13.8357 15 14.2499 15C14.6641 15 14.9999 14.6642 14.9999 14.25V9.75003C14.9999 9.33582 14.6641 9.00003 14.2499 9.00003Z" fill="currentColor" />
        </svg>
    )
}

export default ReportChart
