import React from 'react'
import { useTheme } from '@chakra-ui/react'

import { Box, Flex, Text, Icon } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { formatBlockedDate } from 'utils/date'
import { useTranslation } from 'react-i18next'

export const BlockedHoursCard = ({
  item,
  index,
  isDeletable,
  onDelete = () => {},
}) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()

  return (
    <Box
      width="full"
      height="fit-content"
      paddingX={theme.pxToRem(20)}
      paddingY={theme.pxToRem(24)}
      marginBottom={theme.pxToRem(16)}
      backgroundColor="cardBackground"
      borderRadius={8}
      key={`data_blocked_hour_${index}`}
    >
      <Flex alignItems="center">
        <Flex
          flexDirection={isDesktop ? 'row' : 'column'}
          alignItems={isDesktop && 'center'}
          justifyContent={isDesktop && 'space-between'}
          width="full"
        >
          <Flex flexDirection="column">
            <Text
              fontColor="textLight"
              opacity={0.9}
              fontSize={theme.pxToRem(12)}
              fontWeight={500}
              width={isDesktop ? '33%' : 'full'}
            >
              {t(formatBlockedDate(item))}
            </Text>
            <Text
              fontColor="textLight"
              opacity={0.9}
              fontSize={theme.pxToRem(12)}
              mt={theme.pxToRem(8)}
              mb={theme.pxToRem(8)}
              fontWeight={500}
              width={isDesktop ? '33%' : 'full'}
            >
              {item?.title}
            </Text>
          </Flex>

          <Text
            fontColor="textLight"
            fontSize={theme.pxToRem(14)}
            fontWeight={600}
            marginBottom={!isDesktop && theme.pxToRem(4)}
            width={isDesktop ? '33%' : 'full'}
            textAlign={isDesktop && 'center'}
          >
            {item?.start_hour.substring(0, 5)} -{' '}
            {item?.finish_hour.substring(0, 5)}
          </Text>

          <Text
            fontColor="textLight"
            opacity={0.9}
            fontSize={theme.pxToRem(12)}
            fontWeight={500}
            width={isDesktop ? '33%' : 'full'}
          >
            {t('BARBER')}: {item?.barber?.name || t('EVERYBODY')}
          </Text>
        </Flex>

        {isDeletable ? (
          <Icon
            cursor="pointer"
            onClick={() => onDelete(item)}
            name="Trash"
            color="danger"
            size={16}
          />
        ) : (
          <Box width={theme.pxToRem(16)} />
        )}
      </Flex>
    </Box>
  )
}
