import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useTheme,
  useToast,
} from '@chakra-ui/react'
import { ModalMenu, ModalAlert } from 'components/molecules'
import { Button, Text, TextInput, Icon, CheckBox, SelectDateButton } from 'components/atoms'
import { clearCurrency } from 'utils/clearCurrency'
import { useTranslation } from 'react-i18next'

import { format, addDays, parseISO } from 'date-fns'
import { useBarbershopStore } from 'services/stores'

import { api } from 'services/api/config'
import {
  FINANCE_CATEGORY,
  CHANGE_FINANCE_RELEASE,
  CREATE_FINANCE_RELEASE,
} from 'services/api/endpoints'
import { useQuery, useMutation } from 'react-query'
import { useBreakpoint } from 'services/hooks'
import { SingleSaleOptions } from '../AppointmentsModal/SingleSaleOptions'
import { usePermissionCollaborator } from 'services/hooks'

export const FinanceReleaseModal = ({ isModalOpen, onClose, releaseData }) => {
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const { isDesktop } = useBreakpoint()
  const [recurrent, setRecurrent] = useState(false)
  const [selectedEndDate, setSelectedEndDate] = useState(new Date())
  const { userData } = useBarbershopStore()
  const finance = usePermissionCollaborator('finance')
  const single_product = usePermissionCollaborator('single_product')

  async function getFinanceCategories() {
    const res = await api
      .get(FINANCE_CATEGORY)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: financeCategory } = useQuery(
    'get-categories-service',
    getFinanceCategories
  )

  async function changeFinanceRelease(params) {
    const res = await api[releaseData ? 'put' : 'post'](
      releaseData
        ? CHANGE_FINANCE_RELEASE(releaseData?.id)
        : CREATE_FINANCE_RELEASE,
      params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-finance-release',
    (params) => changeFinanceRelease(params),
    {
      onSuccess: async (value) => {
        toast({
          title: releaseData
            ? t('EDIT_RELEASE_SUCCESS')
            : t('ADD_RELEASE_SUCCESS'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        resetForm()
        onClose()
      },
      onError: (error) => {
        toast({
          title: releaseData ? t('EDIT_RELEASE_ERROR') : t('ADD_RELEASE_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  async function deleteFinanceRelease() {
    const res = await api
      .delete(
        CHANGE_FINANCE_RELEASE(
          releaseData.id,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation(
    'delete-finance-release',
    () => deleteFinanceRelease(),
    {
      onSuccess: async (value) => {
        toast({
          title: t('DELETE_RELEASE'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        resetForm()
        onClose()
      },
      onError: (error) => {
        toast({
          title: t('DELETE_RELEASE_ERROR'),
          description: error.toString().substring(7),
          status: 'danger',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleFinanceCategoryIcon = (categoryName) => {
    switch (categoryName) {
      case 'Infraestrutura':
        return 'Shop'
      case 'Funcionários':
        return 'People'
      case 'Pagamentos Diversos':
        return 'Money'
      case 'Material':
        return 'Stocks'
      default:
        return 'Shop'
    }
  }

  const handleFinanceCategoryTranslation = (categoryName) => {
    switch (categoryName) {
      case 'Infraestrutura':
        return 'CATEGORY_SHOP'
      case 'Funcionários':
        return 'CATEGORY_PEOPLE'
      case 'Pagamentos Diversos':
        return 'CATEGORY_MONEY'
      case 'Material':
        return 'CATEGORY_STOCKS'
      default:
        return 'CATEGORY_SHOP'
    }
  }

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      type: releaseData ? releaseData?.type : 'revenue',
      title: releaseData ? releaseData?.title : '',
      due_at_date: format(
        releaseData?.due_at_date
          ? parseISO(releaseData?.due_at_date)
          : new Date(),
        'yyyy-MM-dd'
      ),
      value: releaseData ? Number(releaseData?.value) : '',
      status: releaseData ? releaseData?.status : 'pending',
      finance_categories_id: releaseData
        ? releaseData?.finance_categories_id
        : null,
      recurrent: releaseData ? releaseData?.recurrent : false,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(3, t('TITLE')).required(t('TITLE_REQUIRED')),
      due_at_date: Yup.date().required(t('DUE_DATE_REQUIRED')),
      value: Yup.string().required(t('RELEASE_VALUE_REQUIRED')),
      finance_categories_id: Yup.number().required(t('CATEGORY_REQUIRED')),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const date = parseISO(values?.due_at_date)
      const params = {
        ...values,
        value:
          typeof values?.value === 'number'
            ? values?.value
            : clearCurrency(values?.value),
        due_at_date: date,
        recurrent: recurrent,
        barbershop_id: userData?.barbershop?.id || userData?.id,
        limit_date: recurrent ? format(selectedEndDate, 'yyyy-MM-dd') : null
      }
      // OBS: there is a bug that when create a new Date(values.due_at_at) it comes a day - 1
      mutate.mutate(params)
    },
  })

  const isDisabled = useMemo(() => {
    if (
      !values?.title ||
      !values?.value ||
      !values?.due_at_date ||
      !values?.finance_categories_id ||
      !values?.status
    ) {
      return true
    }
  }, [values])

  useEffect(() => {
    if (releaseData) {
      setValues({
        type: releaseData ? releaseData?.type : 'revenue',
        title: releaseData ? releaseData?.title : '',
        due_at_date: format(
          releaseData?.due_at_date
            ? parseISO(releaseData?.due_at_date)
            : new Date(),
          'yyyy-MM-dd'
        ),
        value: releaseData ? Number(releaseData?.value) : '',
        status: releaseData ? releaseData?.status : 'pending',
        finance_categories_id: releaseData
          ? releaseData?.finance_categories_id
          : null,
      })
    }
  }, [releaseData])

  return (
    <ModalMenu
      isOpen={isModalOpen}
      title={releaseData ? t('EDIT_RELEASE') : t('ADD_RELEASE')}
      onClose={onClose}
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
    >
      <Tabs>
        <TabList
          color="textGrey"
          borderColor="modalBorder"
          borderBottomWidth={1}
          mb={theme.pxToRem(12)}
        >
          <Tab
            fontSize={14}
            fontWeight={500}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            {t('ADD_RELEASE')}
          </Tab>
          {single_product === 'edit' && (
            <Tab
              fontSize={14}
              fontWeight={500}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              {t('PRODUCT_SALE')}
            </Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel m={0} p={0}>
            <Flex flexDirection="column" flex="1">
              <Box flex="1">
                <Box marginBottom={theme.pxToRem(12)}>
                  <Text
                    marginBottom={theme.pxToRem(16)}
                    fontSize={theme.pxToRem(14)}
                    color="textGrey"
                  >
                    {t('RELEASE_TYPE')}
                  </Text>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <Button
                      kind={values.type === 'revenue' ? 'primary' : 'outline'}
                      size="small"
                      w="100%"
                      marginRight="32px"
                      onClick={() => setFieldValue('type', 'revenue')}
                      isDisabled={finance !== 'edit'}
                    >
                      {t('PROFIT')}
                    </Button>
                    <Button
                      kind={values.type === 'expense' ? 'primary' : 'outline'}
                      size="small"
                      w="100%"
                      onClick={() => setFieldValue('type', 'expense')}
                      isDisabled={finance !== 'edit'}
                    >
                      {t('EXPENSE')}
                    </Button>
                  </Flex>
                </Box>

                <TextInput
                  type="text"
                  name="title"
                  placeholder={
                    values.type === 'revenue'
                      ? t('EX_REVENUE')
                      : t('EX_NOT_REVENUE')
                  }
                  error={errors.title}
                  value={values.title}
                  onChange={handleChange}
                  label={t('title')}
                  paddingLeft={theme.pxToRem(16)}
                  readOnly={finance !== 'edit'}
                />

                <TextInput
                  noBorder
                  mt={theme.pxToRem(32)}
                  label={t('VALUE')}
                  name="value"
                  mask="CURRENCY_MASK"
                  placeholder="0,00"
                  leftElement={
                    <Text
                      color="textGrey"
                      kind="medium"
                      mt={theme.pxToRem(2)}
                      width={theme.pxToRem(40)}
                    >
                      {t('CURRENCY')}
                    </Text>
                  }
                  error={errors.value}
                  value={values.value}
                  onChange={handleChange}
                  paddingLeft={theme.pxToRem(45)}
                  readOnly={finance !== 'edit'}
                />

                <TextInput
                  noBorder
                  mt={theme.pxToRem(32)}
                  type="date"
                  name="due_at_date"
                  error={errors.due_at_date}
                  value={values.due_at_date}
                  onChange={handleChange}
                  label={t('DUE_DATE')}
                  paddingLeft={theme.pxToRem(16)}
                  paddingRight={theme.pxToRem(16)}
                  readOnly={finance !== 'edit'}
                />  

                {recurrent && (
<Flex w='100%' mt='40px' flexDir='column'> 
<Text
            kind="medium"
            fontSize={theme.pxToRem(14)}
            color="textGrey"
            mb={theme.pxToRem(6)}
          >
            Mês limite da recorrência
          </Text>
<SelectDateButton width='100%' h='42px'  onlyMonth date={selectedEndDate}
setDate={setSelectedEndDate}/>
</Flex>

                )}

                {!releaseData && <Flex mt={theme.pxToRem(recurrent ? 24 :42)} alignItems="center">
                  <CheckBox
                    onChange={(event) => setRecurrent(!recurrent)}
                    isChecked={recurrent}
                    color="primary"
                    borderColor="primary"
                    size="lg"
                    isReadOnly={finance !== 'edit'}
                  />

                  <Text
                    ml={theme.pxToRem(8)}
                    fontWeight="bold"
                    fontSize="small"
                  >
                    {t(
                      `Esta ${
                        values.type === 'revenue' ? 'receita' : 'despesa'
                      } é recorrente?`
                    )}
                  </Text>
                </Flex>}

                <Box mt={theme.pxToRem(releaseData ? 32 : 16)}>
                  <Text
                    marginBottom={theme.pxToRem(10)}
                    fontSize={theme.pxToRem(14)}
                    color="textGrey"
                  >
                    {t('CATEGORY')}
                  </Text>
                  <Flex justifyContent="space-between">
                    {financeCategory &&
                      financeCategory
                        ?.filter((f) => f?.id < 5)
                        ?.map((item) => (
                          <Box
                            display="flex"
                            flexDir="column"
                            alignItems="center"
                            justifyContent="flex-start"
                            key={item.id}
                            bg="component2"
                            borderWidth={theme.pxToRem(1)}
                            borderColor={
                              values.finance_categories_id === item.id
                                ? 'primary'
                                : 'background'
                            }
                            h={theme.pxToRem(100)}
                            w={theme.pxToRem(!isDesktop ? 76 : 110)}
                            borderRadius={theme.pxToRem(8)}
                            pt={theme.pxToRem(24)}
                            cursor={
                              finance === 'edit' ? 'pointer' : 'not-allowed'
                            }
                            onClick={
                              finance === 'edit'
                                ? () =>
                                    setFieldValue(
                                      'finance_categories_id',
                                      item.id
                                    )
                                : null
                            }
                          >
                            <Icon name={handleFinanceCategoryIcon(item.name)} />
                            <Text
                              mt={theme.pxToRem(4)}
                              fontSize={theme.pxToRem(!isDesktop ? 8.5 : 11)}
                              kind="semiBold"
                              align="center"
                              color={
                                values.finance_categories_id === item.id
                                  ? 'primary'
                                  : 'textLight'
                              }
                            >
                              {t(handleFinanceCategoryTranslation(item.name))}
                            </Text>
                          </Box>
                        ))}
                  </Flex>
                </Box>

                <Box mt={theme.pxToRem(12)}>
                  <Text
                    marginBottom={theme.pxToRem(16)}
                    fontSize={theme.pxToRem(14)}
                    color="textGrey"
                  >
                    {t('CHOOSE_RELEASE_STATUS')}
                  </Text>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <Button
                      kind={values.status === 'pending' ? 'primary' : 'outline'}
                      size="small"
                      w="100%"
                      marginRight="32px"
                      onClick={() => setFieldValue('status', 'pending')}
                      isDisabled={finance !== 'edit'}
                    >
                      {t('PENDING_PAYMENT')}
                    </Button>
                    <Button
                      kind={values.status === 'paid' ? 'primary' : 'outline'}
                      size="small"
                      w="100%"
                      onClick={() => setFieldValue('status', 'paid')}
                      isDisabled={finance !== 'edit'}
                    >
                      {t('PAID_PAYMENT')}
                    </Button>
                  </Flex>
                </Box>
              </Box>

              <Flex mt={theme.pxToRem(60)} mb={theme.pxToRem(16)}>
                {releaseData && finance === 'edit' ? (
                  <>
                    <Button
                      flex="1"
                      mr={theme.pxToRem(8)}
                      isLoading={mutateDelete.isLoading}
                      h={theme.pxToRem(52)}
                      kind="danger"
                      textTransform="uppercase"
                      onClick={() => setAlertModalOpen(true)}
                    >
                      {t('DELETE')}
                    </Button>
                    <Button
                      flex="1"
                      ml={theme.pxToRem(8)}
                      onClick={handleSubmit}
                      isLoading={mutate.isLoading}
                    >
                      {t('UPDATE')}
                    </Button>
                  </>
                ) : (
                  finance === 'edit' && (
                    <Button
                      w="100%"
                      disabled={mutate.isLoading || isDisabled}
                      onClick={handleSubmit}
                    >
                      {t('SAVE')}
                    </Button>
                  )
                )}
              </Flex>
            </Flex>
          </TabPanel>

          <TabPanel m={0} p={0}>
            <SingleSaleOptions onClose={onClose} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        onConfirm={() => mutateDelete.mutate()}
        primaryButtonLabel={t('DELETE')}
        modalTitle={t('CONFIRM_DELETE_RELEASE')}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('CONFIRM_DELETE_RELEASE')} "{values.title}" ?
        </Text>
      </ModalAlert>
    </ModalMenu>
  )
}
