import React, { useEffect, useState } from 'react'
import {
  Layout,
  Table,
  AdditionalProductsModal,
  MobileList,
  AdditionalProductCard,
  Text,
  FeatureBlockedMessage,
  Icon,
  StockModal,
} from 'components'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { useTheme, useToast, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks/use-breakpoint'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { GET_ADDITIONAL_PRODUCTS } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'
import { api } from 'services/api/config'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { usePermissionCollaborator, useBarbershop } from 'services/hooks'
import { productsColumns, batchColumns, mockData } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const AdditionalProductsScreen = ({ ...props }) => {
  const { setAdditionalProductsList } = additionalProductsStore()
  const [productModalOpen, setProductModalOpen] = useState(false)
  const [product, setProduct] = useState(null)
  const [stockModalOpen, setStockModalOpen] = useState(false)
  const [batch, setBatch] = useState(null)
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { userData, setData } = useBarbershopStore()
  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const { getStock } = useBarbershop()
  const permission = usePermissionCollaborator('additional_products')
  const [sections, setSections] = useState({
    products: true,
    stock: true,
  })
  const {onLogout} = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const SectionHeader = ({ onClick, isOpen, title }) => {
    return (
      <Flex
        onClick={onClick}
        cursor={'pointer'}
        align={'center'}
        mt={theme.pxToRem(12)}
      >
        <Text fontSize={18} kind="bold">
          {t(title)}
        </Text>
        <Icon
          name={isOpen ? 'ChevronUp' : 'ChevronDown'}
          color={'white'}
          size={18}
          ml="4px"
        />
      </Flex>
    )
  }

  async function getAdditionalProducts(params) {
    const res = await api
      .get(GET_ADDITIONAL_PRODUCTS(userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const {
    data: products,
    refetch,
    isLoading: productsLoading,
  } = useQuery(
    'get-additional-products',
    (params) => getAdditionalProducts(params),
    {
      onSuccess: (value) => {
        setAdditionalProductsList(value)
      },
      onError: (error) => {
        toast({
          title: t('ERROR_LOADING_PRODUCTS'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const { data: batchs, isLoading: loadingBatch } = useQuery(
    'get-stocks-releases',
    () => getStock({ barbershop_id: userData?.id })
  )

  const onCloseServiceModal = () => {
    setProductModalOpen(false)
    setStockModalOpen(false)
    setProduct(null)
    setBatch(null)
    refetch()
  }

  const onClickAddServiceButton = () => {
    setProduct(null)
    setProductModalOpen(true)
  }

  const onClickTableRow = (selectedRow, type) => {
    if (permission === 'edit') {
      if (type === 'batch') {
        setBatch(selectedRow)
        setStockModalOpen(true)
        return
      }
      setProduct(selectedRow)
      setProductModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('PRODUCTS_MANAGER')}
      headerActionText={permission === 'edit' && t('Cadastrar novo produto')}
      headerActionClick={() => onClickAddServiceButton()}
      paddingButton={80}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      isBlocked={isBlocked}
      // iconSecondActionText="Plus"
      iconColor="black"
      // secondActionText={t('Adicionar estoque')}
      // secondActionClick={
      //   permission === 'edit' ? () => setStockModalOpen(true) : null
      // }
    >
      {isBlocked && (
        <FeatureBlockedMessage
          title="FINANCIAL_BLOCKED"
          section="ADDITIONAL_PRODUCTS"
        />
      )}

      <SectionHeader
        isOpen={sections?.products}
        onClick={() =>
          setSections({ ...sections, products: !sections?.products })
        }
        title="PRODUCTS_REGISTERED"
      />

      {sections?.products &&
        (isDesktop ? (
          <Table
            isSelectableRow
            onSelect={(select) => onClickTableRow(select, 'product')}
            data={(isBlocked ? mockData : products) || []}
            loading={productsLoading}
            columns={productsColumns}
          />
        ) : (
          <MobileList
            data={(isBlocked ? mockData : products) || []}
            loading={productsLoading}
            renderItem={({ item, index }) => (
              <AdditionalProductCard
                inList
                product={item}
                fullWidth
                onSelect={(select) => onClickTableRow(select, 'product')}
              />
            )}
          />
        ))}

      <SectionHeader
        isOpen={sections?.stock}
        onClick={() => setSections({ ...sections, stock: !sections?.stock })}
        title="Extrato de lançamentos de produtos ao estoque"
      />

      {sections?.stock && (
        <Table
          isSelectableRow
          onSelect={(select) => onClickTableRow(select, 'batch')}
          data={batchs || []}
          loading={loadingBatch}
          columns={isDesktop ? batchColumns : batchColumns}
        />
      )}

      <StockModal
        isModalOpen={stockModalOpen}
        onClose={onCloseServiceModal}
        selectedBatch={batch}
        selectedProduct={product}
      />

      <AdditionalProductsModal
        isModalOpen={productModalOpen}
        onClose={onCloseServiceModal}
        selectedProduct={product}
        addQuantityAction={() => {
          setProductModalOpen(false)
          setStockModalOpen(true)
        }}
      />
    </Layout>
  )
}

AdditionalProductsScreen.path = '/produtos-adicionais'
AdditionalProductsScreen.title = 'Produtos Adicionais'
AdditionalProductsScreen.secured = true
