import * as React from 'react'

function SvgCheckBox(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={0.5}
        width={19}
        height={19}
        rx={3.5}
        stroke="currentColor"
      />
    </svg>
  )
}

export default SvgCheckBox
