import { Text, Icon } from 'components/atoms'
import { formatPrice } from 'utils'
import { t } from 'services/translation'

export const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'price',
    label: 'VALUE_TABLE',
    render: (item) => <Text>{formatPrice(item?.price)}</Text>,
  },
  {
    key: 'time_required',
    label: 'TIME_TABLE',
    render: (item) => <Text>{item?.time_required?.substring?.(0, 5)}</Text>,
  },
  {
    key: 'visible_for_clients',
    label: 'VISÍVEL P/ CLIENTES',
    render: (item) => <Text>{!item?.visible_for_clients ? 'Não' : 'Sim'}</Text>,
  },
  {
    key: 'type',
    label: 'TYPE',
    render: (item) => <Text>{t(item?.type)}</Text>,
  },
]

export const WEEK_DAYS = [
  {
    id: 0,
    letter: 'SUNDAY_LETTER',
    min: 'Dom',
    day: 'SUNDAY_DESC',
  },
  {
    id: 1,
    letter: 'MONDAY_LETTER',
    min: 'Seg',
    day: 'MONDAY_DESC',
  },
  {
    id: 2,
    letter: 'TUESDAY_LETTER',
    min: 'Ter',
    day: 'TUESDAY_DESC',
  },
  {
    id: 3,
    letter: 'WEDNESDAY_LETTER',
    min: 'Qua',
    day: 'WEDNESDAY_DESC',
  },
  {
    id: 4,
    letter: 'THURSDAY_LETTER',
    min: 'Qui',
    day: 'THURSDAY_DESC',
  },
  {
    id: 5,
    letter: 'FRIDAY_LETTER',
    min: 'Sex',
    day: 'FRIDAY_DESC',
  },
  {
    id: 6,
    letter: 'SATURDAY_LETTER',
    min: 'Sab',
    day: 'SATURDAY_DESC',
  },
]

export const TIME_REGEX = /([01][0-9]|2[0-3]):([0-5][0-9])/
export const TIME_REGEX_WITHOUT_COLON = /([01][0-9]|2[0-3])([0-5][0-9])/

const unmaskTimeInput = (str) => (str || '').replace(/\D/g, '')
const maskTimeInput = (str) => (str || '').replace(TIME_REGEX_WITHOUT_COLON, '$1:$2')

export const getInputTimeProcessed = (value) => {
  const unmaskedValue = unmaskTimeInput(value)
  const trunkedValue = unmaskedValue.substring(0, 4)
  return maskTimeInput(trunkedValue)
}

export const getDayIds = (weekDays) => {
  return weekDays.filter((day) => day.selected).map((day) => day.id)
}

export const toastErrorMessage = {
  title: 'Falha ao salvar serviço',
  description: 'Por favor, revise os dados inseridos',
  status: 'error',
  duration: 4000,
  isClosable: true,
}
