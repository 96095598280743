import React, { useEffect, useState } from 'react'
import { firebaseAnalytics } from '../../../services/firebase'
import { useLocation, useHistory } from 'react-router-dom'
import { Flex, LandingPageModal } from 'components'

import { Why } from '../components/whySection'
import { Plans } from '../components/plans'
import { Hero } from '../components/hero'
import { Israel } from '../components/israel'
import { About } from '../components/about'
import { FreeTest } from '../components/freeTest'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

export const LandingPage = () => {
  const location = useLocation()
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const split_name = String(location.search).split('&')
    if (split_name?.length > 1) {
      const split_name2 = String(split_name[0]).split('=')
      const split_name3 = String(split_name[1]).split('=')
      firebaseAnalytics.logEvent(`${split_name2[1]}_${split_name3[1]}`)
    }
  }, [])

  return (
    <Flex
      overflowY="auto"
      overflowX="hidden"
      overscrollY="contain"
      w={'100vw'}
      flexDir="column"
    >
      <Header clickButton={() => setShowModal(true)} />
      <Hero clickButton={() => setShowModal(true)} />
      <Israel clickButton={() => setShowModal(true)} />
      <Plans clickButton={() => setShowModal(true)} />
      <About clickButton={() => setShowModal(true)} />
      <FreeTest clickButton={() => setShowModal(true)} />
      <Why clickButton={() => setShowModal(true)} />
      <Footer />
      <LandingPageModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        handleFormSubmit={() => history.push('/lp/complete-data')}
      />
    </Flex>
  )
}

LandingPage.path = '/lp'
LandingPage.title = 'LandingPage'
LandingPage.secured = false
