function isObject(obj) {
  return obj === Object(obj);
}

export const handleError = (error) => {
  if (error.response?.data?.error && isObject(error?.response?.data?.error)) {
    return {
      error:
        'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
    };
  }

  if (error?.response?.data?.error) {
    return {
      error: error.response.data.error,
    };
  }
  return {
    error: 'Ocorreu um erro ao realizar esta ação. Por favor, tente novamente.',
  };
};
