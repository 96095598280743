import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'components/atoms'
import { colors } from 'styles/colors'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { theme } from 'styles'

export const ModalAlert = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
  modalTitle,
  primaryButtonLabel,
  secondaryButtonLabel,
  children: modalBody,
  primaryButtonColor,
}) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        background={colors.cardBackground}
        borderWidth={1}
        borderColor={colors.modalBorder}
        borderRadius={theme.pxToRem(8)}
      >
        <ModalHeader
          mt={4}
          fontFamily="Montserrat ExtraBold"
          color={colors.white}
          r
        >
          {modalTitle}
        </ModalHeader>
        <ModalCloseButton color={colors.white} />
        <ModalBody fontFamily="Montserrat Medium" color="textLight">
          {modalBody}
        </ModalBody>

        <ModalFooter mb={4} display="flex" justifyContent="space-between">
          <Button
            disabled={loading}
            kind="outline"
            size="medium"
            onClick={onClose}
            w="47%"
          >
            {secondaryButtonLabel || t('CANCEL')}
          </Button>
          <Button
            disabled={loading}
            kind={primaryButtonColor || 'danger'}
            size="medium"
            onClick={onConfirm}
            loading={loading}
            w="47%"
          >
            {primaryButtonLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

ModalAlert.defaultProps = {
  type: '',
  modalTitle: '',
  primaryButtonLabel: 'Aceitar',
}

ModalAlert.propTypes = {
  ...ModalAlert.propTypes,
  type: PropTypes.string,
  modalTitle: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
}
