import { api } from 'services/api/config'
import { new_api } from 'services/api/config-v2'

export const useBarbershopGoalsV2 = () => {
  const getAllGoals = async (props) => {
    // const response = await new_api({
    //   url: `barber-goals/${props.barbershop_id}`,
    // })
    const response = await api({
      url: `v3/barbershop/barber-goals/${props.barbershop_id}`,
    })
    return response.data
  }

  const getAllGoalsResume = async (props) => {
    const response = await api.post(`v3/barbershop/barber-goals-resume`, props?.body)
    // const response = await new_api.post(`barber-goals/resume/${props?.barbershop_id}`, props?.body)
    return response.data
  }

  const createGoal = async (props) => {
    const response = await api.post(`v3/barbershop/barber-goals`, props?.body)
    // const response = await new_api.post(`barber-goals`, props?.body)
    return response.data
  }

  const getProductsAndServicesByBarbershop = async (props) => {
    const response = await api({
      url: `v3/barber-products-and-services/${props?.barbershop_id}`,
    })
    // const response = await new_api({
    //   url: `barbershops/products-and-services/${props?.barbershop_id}`,
    // })
    return response.data
  }

  return {
    getProductsAndServicesByBarbershop,
    getAllGoals,
    createGoal,
    getAllGoalsResume,
  }
}
