import React from 'react'
import { useTheme } from '@chakra-ui/react'
import { Box, Flex, Text, Icon, Image } from 'components/atoms'

export const ReleasesCard = ({ item, index }) => {
  const theme = useTheme()

  return (
    <Box
      key={`data_rating_card_${index}`}
      width="full"
      height='155px'
      marginBottom={theme.pxToRem(16)}
      bg="border"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      cursor='pointer'
      onClick={() => window.open(item?.link, '_blank')}
    >
      <Flex alignItems="center" width="full">
        <Image
          src={item?.image}
          width='265px'
          height='150px'
          mr='22px'
        />

        <Flex
          alignItems={'flex-start'}
          width="full"
        flexDirection='column'
        >
            <Text
              kind="extraBold"
              fontColor="white"
              fontSize={theme.pxToRem(20)}
              mb='6px'
            >
              {item?.title}
            </Text>
            <Text
              fontColor="white"
              fontSize={theme.pxToRem(14)}
              mb='6px'
              w='99%'
              
            >
              {item?.desc}
            </Text>
            <Flex>
            <Flex bg='white' borderRadius='4px' mr='8px' padding='8px' align='center' justify='center'>
        <Icon name={item?.icon} size={20} color='black' mr='4px' />
        <Text
              kind="bold"
              color="black"
              fontSize={theme.pxToRem(12)}
            >
              {item?.category}
            </Text>
    </Flex>
    <Flex bg='white' borderRadius='4px' mr='8px' padding='8px' align='center' justify='center'>
        <Icon name={item?.iconPlatform} size={20} color='black' mr='4px' />
        <Text
              kind="bold"
              color="black"
              fontSize={theme.pxToRem(12)}
            >
              {item?.platform}
            </Text>
    </Flex>
            </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
