import React from 'react'
import PropTypes from 'prop-types'
import { Image, useTheme } from '@chakra-ui/react'
import logoSrc from 'assets/img/brand/logo_white.png'
import logoAvatar from 'assets/img/brand/logo_simple_white.png'

const sizes = {
  small: 20,
  medium: 40,
  large: 60,
}

export const Logo = ({ avatar, size, ...props }) => {
  const theme = useTheme()
  return (
    <Image
      {...props}
      src={avatar ? logoAvatar : logoSrc}
      h={avatar ? theme.pxToRem(32) : theme.pxToRem(sizes[size])}
      w={avatar ? theme.pxToRem(32) : theme.pxToRem(sizes[size] * 5)}
      objectFit="contain"
    />
  )
}

Logo.defaultProps = {
  size: 'small',
}

Logo.propTypes = {
  ...Image.propTypes,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}
