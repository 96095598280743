import React, { useEffect, useState } from 'react'
import { ModalMenu, Text, FieldControl, TextInput, Link, Button, Box, Icon, Flex } from 'components'
import { IssueInvoiceCustomForm } from './index'

import { useInvoiceData } from 'services/stores'
import { useInvoice } from 'services/hooks'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { HStack, VStack, Divider, useToast } from '@chakra-ui/react'

export const InvoiceDetailsDrawer = ({ invoiceId = null, onClose }) => {
  const { getOneInvoice, removeInvoice } = useInvoice()
  const { setInvoiceData } = useInvoiceData()
  const [invoiceType, setInvoiceType] = useState()
  const toast = useToast()
  const queryClient = useQueryClient()

  const invoiceQuery = useQuery(['invoice-records', invoiceId], () => getOneInvoice(invoiceId), {
    enabled: !!invoiceId,
  })

  const mutateCancelInvoice = useMutation(['cancel-invoice', invoiceId], () => removeInvoice({ id: invoiceId }), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-historic-nf'])
      const queryState = queryClient?.getQueryData(['get-historic-nf'])
      const newState = [...queryState]
      const index = queryState.findIndex(
        (appointment) => appointment?.invoices?.filter((inv) => inv?.id === invoiceId)?.length > 0
      )
      if (index !== -1) {
        const invoices = newState[index]?.invoices
        const invoice_index = invoices.findIndex((inv) => inv?.id === invoiceId)
        invoices[invoice_index] = {
          ...invoices[invoice_index],
          ...data,
        }
        newState[index] = {
          ...newState[index],
          invoices,
        }
        queryClient.setQueryData(['get-historic-nf'], newState)
      }
      toast({
        title: 'Nota fiscal cancelada com sucesso!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClose()
    },
    onError: (error) => {
      toast({
        title: `${error.message ?? 'Erro ao cancelar nota fiscal.'}`,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  useEffect(() => {
    const form_metadata = invoiceQuery.data?.form_metadata
    if (!form_metadata) return

    const newInvoiceData = {
      helpers: {
        carrierType: form_metadata.cpf_transportador ? 'PF' : form_metadata.cnpj_transportador ? 'PJ' : '',
        addRecipientData: !!form_metadata.cpf_destinatario || !!form_metadata.cnpj_destinatario,
        recipientType: form_metadata.cpf_destinatario ? 'PF' : form_metadata.cnpj_destinatario ? 'PJ' : '',
        showAdvancedFields: !!form_metadata.additionalData,
        additionalData: form_metadata.additionalData || '{}',
      },

      natureza_operacao: form_metadata.natureza_operacao ?? '',
      presenca_comprador: form_metadata.presenca_comprador ?? '',
      modalidade_frete: form_metadata.modalidade_frete ?? '',
      local_destino: form_metadata.local_destino ?? '',
      informacoes_adicionais_contribuinte: form_metadata.informacoes_adicionais_contribuinte ?? '',

      // IssuerInvoiceItemsForm
      itens: form_metadata.itens ?? [],

      // IssuerInvoicePaymentForm
      formas_pagamento: form_metadata.formas_pagamento ?? [],

      // IssuerInvoiceCarrierForm
      nome_transportador: form_metadata.nome_transportador ?? '',
      cnpj_transportador: form_metadata.cnpj_transportador ?? '',
      cpf_transportador: form_metadata.cpf_transportador ?? '',
      inscricao_estadual_transportador: form_metadata.inscricao_estadual_transportador ?? '',
      endereco_transportador: form_metadata.endereco_transportador ?? '',
      municipio_transportador: form_metadata.municipio_transportador ?? '',
      uf_transportador: form_metadata.uf_transportador ?? '',

      // IssuerInvoiceRecipientForm
      nome_destinatario: form_metadata.nome_destinatario ?? '',
      cpf_destinatario: form_metadata.cpf_destinatario ?? '',
      telefone_destinatario: form_metadata.telefone_destinatario ?? '',
      cnpj_destinatario: form_metadata.cnpj_destinatario ?? '',
      indicador_inscricao_estadual_destinatario: form_metadata.indicador_inscricao_estadual_destinatario ?? '',
      cep_destinatario: form_metadata.cep_destinatario ?? '',
      uf_destinatario: form_metadata.uf_destinatario ?? '',
      municipio_destinatario: form_metadata.municipio_destinatario ?? '',
      bairro_destinatario: form_metadata.bairro_destinatario ?? '',
      logradouro_destinatario: form_metadata.logradouro_destinatario ?? '',
      numero_destinatario: form_metadata.numero_destinatario ?? '',
    }

    setInvoiceData(newInvoiceData)
    setInvoiceType(invoiceQuery.data?.type)
  }, [invoiceQuery.data?.form_metadata, invoiceQuery.data?.type, setInvoiceData])
  return (
    <ModalMenu
      isOpen={!!invoiceId}
      onClose={onClose}
      title="Nota Fiscal"
      size="lg"
      bodyStyle={{ pb: 6, color: 'white' }}
    >
      <VStack spacing={4} align="normal">
        <HStack align="normal">
          <FieldControl
            label="Situação"
            isDisabled={invoiceQuery.data?.id}
            isLoading={invoiceQuery.isLoading}
            render={() => (
              <TextInput
                value={
                  invoiceQuery.data?.status
                    ? invoiceQuery.data.status[0].toUpperCase() + invoiceQuery.data.status.slice(1)
                    : ''
                }
              />
            )}
          />
          <FieldControl
            label="Tipo"
            isDisabled={invoiceQuery.data?.id}
            isLoading={invoiceQuery.isLoading}
            render={() => <TextInput value={invoiceQuery.data?.type} />}
          />
        </HStack>
        <Flex w="100%" justify="space-between">
          {invoiceQuery.data?.url && (
            <Link href={invoiceQuery.data.url} target="_blank" w="30%">
              <Button w="100%">
                <HStack>
                  <Icon name="Download" size={22} color="gray.900" />
                  <p>Visualizar PDF</p>
                </HStack>
              </Button>
            </Link>
          )}
          {invoiceQuery.data?.api_metadata?.caminho_xml_nota_fiscal && (
            <Link
              href={`https://api.focusnfe.com.br/${invoiceQuery.data?.api_metadata?.caminho_xml_nota_fiscal}?download=1`}
              target="_blank"
              w="30%"
              ml="2%"
            >
              <Button w="100%">
                <HStack>
                  <Icon name="Download" size={22} color="gray.900" />
                  <p>Download XML</p>
                </HStack>
              </Button>
            </Link>
          )}

          {invoiceQuery.data?.status === 'autorizado' && (
            <Button
              kind="danger"
              w="100%"
              ml="2%"
              onClick={() => mutateCancelInvoice.mutate()}
              isLoading={mutateCancelInvoice.isLoading}
            >
              <HStack>
                <Icon name="Trash" size={18} color="gray.900" />
                <p>Cancelar nota fiscal</p>
              </HStack>
            </Button>
          )}
        </Flex>
        <Box w="full" bg="white" borderRadius={6}>
          <iframe
            title="Exibição da nota fiscal"
            width="100%"
            height="600px"
            id="myIframe"
            src={invoiceQuery.data?.url}
          />
        </Box>
      </VStack>

      <HStack spacing={4} mt={8}>
        <Text fontSize="xl" kind="bold" whiteSpace="nowrap">
          Dados da emissão
        </Text>
        <Divider borderColor="gray.600" />
      </HStack>
      {invoiceType === 'NFCe' && (
        <IssueInvoiceCustomForm
          isDisabled={invoiceQuery.data?.id}
          isLoading={invoiceQuery.isLoading}
          type={invoiceType}
        />
      )}
    </ModalMenu>
  )
}
