import React, { useState } from 'react'
import { Box, FormAtom, Text, Button } from 'components/atoms'
import {
  initialChangePassword,
  validationChangePassword,
  dataChangePassword,
} from './settings'
import { Form } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useToast } from '@chakra-ui/react'

import { api } from 'services/api/config'
import { useMutation } from 'react-query'
import { UPDATE_PASSWORD } from 'services/api/endpoints'

export const RedefinePassword = () => {
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const toast = useToast()
  const [values, getValues] = useState({
    params: initialChangePassword,
    isValid: false,
  })

  async function changePassword({ params }) {
    const res = await api
      .put(UPDATE_PASSWORD, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-password',
    (params) => changePassword(params),
    {
      onSuccess: (data) => {
        toast({
          title: t('UPDATED_DATA'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: t('ERROR_UPDATE_DATA'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <FormAtom>
      <Box flex={isDesktop && '1'}>
        <Text
          fontSize={pxToRem(18)}
          fontWeight="bold"
          marginBottom={pxToRem(26)}
        >
          {t('PASSWORD_PROFILE')}
        </Text>
        <Form
          initialValues={values?.params}
          validationSchema={validationChangePassword}
          data={dataChangePassword}
          mb={32}
          getValues={getValues}
        />
      </Box>
      <Button
        w="100%"
        onClick={() => mutate.mutate(values)}
        marginTop={pxToRem(60)}
        disabled={!values?.isValid}
        isLoading={mutate.isLoading}
      >
        {t('SAVE_CHANGES')}
      </Button>
    </FormAtom>
  )
}
