import React from 'react'
import { t } from 'services/translation'
import { Flex, Table, Text, Icon } from 'components'
import {
  useAppointmentStore,
  useBarbershopStore,
  useSingleSaleStore,
} from 'services/stores'
import { BARBERS, ONLY_BARBERS } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { useTheme } from '@chakra-ui/react'

const AppointmentSelectBarber = ({
  goBack,
  isSingleSale,
  clickEmpty,
  ...props
}) => {
  const { userData } = useBarbershopStore()
  const { setBarber, barbersList, setBarbersList, setDate } =
    useAppointmentStore()
  const { setBarber: setBarberSingleSale, nullProducts } = useSingleSaleStore()
  const theme = useTheme()

  const columns = [
    {
      key: 'profile_image_url',
      label: '',
      type: 'image',
      placeholder: 'barber',
    },
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <>
          <Text w="100%" kind="bold" color="textLight">
            {item.name}
          </Text>
          {isSingleSale && (
            <Text w="100%" color="textLight">
              Função: {t(item?.type || 'BARBER')}
            </Text>
          )}
        </>
      ),
    },
  ]

  async function getTeam() {
    const res = await api
      .get(isSingleSale ? BARBERS(userData.id) : ONLY_BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading } = useQuery('get-team-all', getTeam, {
    onSuccess: (data) => {
      setBarbersList(data)
    },
  })

  const handleSelectBarber = (barber) => {
    if (isSingleSale) {
      setBarberSingleSale(barber)
      nullProducts()
    } else {
      setBarber(barber)
    }
    if (!clickEmpty) {
      setDate(null)
    }
    goBack()
  }

  return (
    <Flex flexDirection="column">
      {isSingleSale && (
        <Flex
          mt={theme.pxToRem(-12)}
          mb={theme.pxToRem(12)}
          w="100%"
          flexDir="row"
          align="center"
        >
          <Icon
            name="ArrowLeft"
            color="primary"
            cursor="pointer"
            w="fit-content"
            size={20}
            mr={theme.pxToRem(12)}
            onClick={goBack}
          />
          {props?.withSubtitle && (
            <Text kind="bold" fontSize={18}>
              {t('BARBER')}
            </Text>
          )}
        </Flex>
      )}

      <Table
        withoutHeader
        isSelectableRow
        data={barbersList || []}
        loading={isLoading}
        columns={columns}
        onSelect={handleSelectBarber}
      />
    </Flex>
  )
}

export default AppointmentSelectBarber
