import React, { useState } from 'react'
import {
  Box,
  Image,
  Layout,
  ReleasesCard,
  SelectFilter,
  Text,
  TextInput,
} from 'components'
import { t } from 'services/translation'
import { categorys, lastUpdateds, releases } from './settings'
import { Search } from 'assets/icons'
import { colors } from 'styles'
import { Flex } from '@chakra-ui/react'

export const Releases = ({ ...props }) => {
  const [query, setQuery] = useState()
  const [filtered, setFiltered] = useState(releases)
  const [category, setCategory] = useState()

  const onFilter = (objeto, palavra) => {
    return Object.values(objeto).some(valor => {
      if (typeof valor === 'string' && valor.includes(palavra)) {
        return true;
      }
      return false;
    });
  }

  const onSearch = (text) => {
    if(text?.length === 0) {
      setFiltered(releases)
    // eslint-disable-next-line no-lone-blocks
    } {
      setFiltered(releases.filter(objeto => onFilter(objeto, text)))
    }
  }

  return (
    <Layout
      {...props}
      h="auto"
      headerTitle='Atualizações e tutoriais'
      px={100}
    >
      <Flex>
    <Box  w='74%' borderRightColor='border' borderRightWidth={2} mr='12px'>
      <Box w='97%'>
    <TextInput
          noBorder
          type="text"
          width="full"
          color="white"
          placeholder={t('SEARCH_CLIENT')}
          backgroundColor="menuBackground"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value)
            onSearch(event.target.value)
          }}
          leftElement={<Search color={colors.primary} />}
          mb='16px'
        />

<SelectFilter
                onChange={(e) => {
                  setCategory(e)
                  if(e?.length === 0) {
                    setFiltered(releases)
                    return
                  }
                  setFiltered(releases?.filter(i => i?.category === e))
                }}
                value={category}
                options={categorys}
                label='Categoria'
                maxW={400}
                noWidth
                mr='8px'
          mb='16px'
          /> 
              {/* <SelectFilter
                onChange={(e) => {
                  setCategory(e)
                  setFiltered(releases?.filter(i => i?.category === e))
                }}
                value={category}
                options={categorys}
                label='Categoria'
                maxW={300}
                mr='8px'
              /> */}
          {filtered?.map(i => (
            <ReleasesCard item={i} />
          ))}
    </Box>
    </Box>
    <Box w='26%'>

    <Text
              kind="extraBold"
              fontColor="white"
              fontSize='24px'
              mb='6px'
              mt='-40px'
            >
              Últimas Novidades
            </Text>
          {lastUpdateds?.map(last => (
            <Box w='100%' cursor='pointer' onClick={() => window.open(last?.link, '_blank')}>
              <Image w='100%' mb='12px' src={last?.image} key={last?.id} h='auto'/>
               </Box>
            
          ))}
          </Box>
    </Flex>
    </Layout>
  )
}

Releases.path = '/atualizacoes'
Releases.title = 'Atualizações'
Releases.secured = true
