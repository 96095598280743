import { api } from 'services/api/config'
import { new_api } from 'services/api/config-v2'
import { saveData, getData } from 'services/storage'
import { LOGIN } from 'services/api/endpoints'

export const saveLoginData = async ({ user, userData, token, new_token, subscription, customer, tutorial }) => {
  try {
    api.defaults.headers.common.Authorization = `bearer ${token}`
    saveData('@BestBarbers:token', token)
    saveData('@BestBarbers:user', user)
    saveData('@BestBarbers:userData', userData)
    saveData('@BestBarbers:type', user.type)
    saveData('@BestBarbers:subscription', subscription)
    saveData('@BestBarbers:customer', customer)
    if (new_token) {
      saveData('@BestBarbers:new_token', new_token)
    }
  } catch (error) {
    return { error }
  }
}

export const saveRefreshData = async ({ modalDateConfirmation, modalDataRefresh, modalRefreshAddress }) => {
  try {
    saveData('@BestBarbers:modalDateConfirmation', modalDateConfirmation || new Date())
    saveData('@BestBarbers:modalDataRefresh', modalDataRefresh || new Date())
    saveData('@BestBarbers:modalRefreshAddress', modalRefreshAddress || new Date())
  } catch (error) {
    return { error }
  }
}

export const saveSeeTutorial = async ({ tutorial }) => {
  try {
    saveData('@BestBarbers:seeTutorial', tutorial)
  } catch (error) {
    return { error }
  }
}

export const getSeeTutorial = async () => {
  try {
    const seeTutorial = await getData('@BestBarbers:seeTutorial')
    return {
      seeTutorial,
    }
  } catch (error) {
    return { error }
  }
}

export const saveSeeNews = async ({ news }) => {
  try {
    saveData('@BestBarbers:seeNews', news)
  } catch (error) {
    return { error }
  }
}

export const getSeeNews = async () => {
  try {
    const seeNews = await getData('@BestBarbers:seeNews')
    return {
      seeNews,
    }
  } catch (error) {
    return { error }
  }
}

export const saveInternalLoginData = async ({ user, token }) => {
  try {
    api.defaults.headers.common.Authorization = `bearer ${token}`
    saveData('@BestBarbers:token', token)
    saveData('@BestBarbers:user', user)
  } catch (error) {
    return { error }
  }
}

export const getLoginSavedData = async () => {
  try {
    const token = await getData('@BestBarbers:token')
    const user = await getData('@BestBarbers:user')
    const userData = await getData('@BestBarbers:userData')

    api.defaults.headers.common.Authorization = `bearer ${token}`

    return {
      token,
      user,
      userData,
    }
  } catch (error) {
    return { error }
  }
}

export const getRefreshData = async () => {
  try {
    const modalDateConfirmation = await getData('@BestBarbers:modalDateConfirmation')
    const modalDataRefresh = await getData('@BestBarbers:modalDataRefresh')
    const modalRefreshAddress = await getData('@BestBarbers:modalRefreshAddress')

    return {
      modalDateConfirmation,
      modalDataRefresh,
      modalRefreshAddress,
    }
  } catch (error) {
    return { error }
  }
}

export const logoutCleanData = async () => {
  try {
    api.defaults.headers.common.Authorization = `bearer ${null}`
    saveData('@BestBarbers:token', null)
    saveData('@BestBarbers:user', null)
    saveData('@BestBarbers:userData', null)
    saveData('@BestBarbers:type', null)
    saveData('@BestBarbers:subscription', null)
    saveData('@BestBarbers:customer', null)
  } catch (error) {
    return { error }
  }
}

export const login = async (params) => {
  const res = await api
    .post(LOGIN, params)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw Error(error.response.data.error)
    })

  // await new_api
  //   .post('auth/login', { ...params, distinct_login: false })
  //   .then((response) => {
  //     saveData('@BestBarbers:new_token', response.data.token)
  //   })
  //   .catch((error) => {})
  return res
}
