import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import * as Screens from 'screens'
import { Protected } from './Protected'

import ScrollToTop from 'utils/scrollToTop'

export const AppRouter = () => {
  const routes = React.useMemo(() => Object.values(Screens), [])

  const protectedRoutes = routes.filter((item) => item.secured)
  const unprotectedRoutes = routes.filter((item) => !item.secured)

  return (
    <BrowserRouter>
      <Switch>
        {protectedRoutes.map((Page, index) => (
          <Protected
            key={`protected_route_${Page.name}_${index}`}
            path={Page.path}
            exact={!Page.path.includes(':')}
          >
            <ScrollToTop />
            <Page {...Page} />
          </Protected>
        ))}
        {unprotectedRoutes.map((Page, index) => (
          <Route
            key={`common_route_${Page.name}_${index}`}
            path={Page.path}
            exact
          >
            <ScrollToTop />
            <Page />
          </Route>
        ))}
      </Switch>
    </BrowserRouter>
  )
}
