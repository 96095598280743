import React, { useEffect, useState } from 'react'
import { TextInput, Button, Flex, Text, FileInput } from 'components'
import { useTheme, useToast } from '@chakra-ui/react'
import { internalAuthStore } from 'services/stores'
import { sections } from './settings'
import { Layout } from '../Components'
import { getLoginSavedData } from 'services/hooks'
import { clearPhone } from 'utils/clearPhone'

export const ProfileAdm = () => {
  const theme = useTheme()
  const toast = useToast()
  const { onUpdatePhoto, onUpdateUser } = internalAuthStore()

  const [user, setUser] = useState({})

  const refreshData = async () => {
    const response = await getLoginSavedData()
    setUser({
      ...response?.user,
      ...response?.userData,
      phone: clearPhone(response?.user?.phone)?.substring(2),
    })
  }

  useEffect(() => {
    refreshData()
  }, [])

  const handleChangeAvatar = async (file) => {
    const data = new FormData()
    data.append('file', file)
    const response = await onUpdatePhoto(data, toast)
    if (!response?.error) {
      refreshData()
    }
  }

  const handleUpdateData = async () => {
    const { name, phone, email } = user
    const response = await onUpdateUser(
      {
        name,
        phone: `55${clearPhone(phone)}`,
        email,
      },
      toast
    )
    if (!response?.error) {
      refreshData()
    }
  }

  return (
    <Layout title="Meu perfil">
      <Flex flexDir="column" align="center">
        <FileInput
          defaultImage={user?.profile_image_url}
          label="Profile"
          onChange={handleChangeAvatar}
          canDelete={false}
          w={130}
          h={130}
          br={130}
        />
        <Text
          mt={theme.pxToRem(12)}
          fontSize={theme.pxToRem(12)}
          kind="semiBold"
          color="primary"
          cursor="pointer"
        >
          Alterar foto de perfil
        </Text>
        {sections.map((field) => {
          return (
            <TextInput
              {...field}
              noBorder
              mt={theme.pxToRem(16)}
              mb={theme.pxToRem(24)}
              label={field.label}
              value={user[field.name]}
              w={theme.pxToRem(480)}
              onChange={(event) =>
                setUser({
                  ...user,
                  ...{ [field.name]: event?.target?.value },
                })
              }
            />
          )
        })}
        <Button
          onClick={handleUpdateData}
          mt={theme.pxToRem(24)}
          w={theme.pxToRem(480)}
        >
          Salvar
        </Button>
      </Flex>
    </Layout>
  )
}

ProfileAdm.path = '/internal-adm/profile'
ProfileAdm.title = 'Users adm'
ProfileAdm.secured = false
