export const forgotPasswordInputs = [
  {
    type: 'e-mail',
    name: 'email',
    placeholder: 'E-mail',
    icon: 'Email',
    text: 'e-mail',
  },
  {
    type: 'phone',
    name: 'phone',
    placeholder: 'PHONE',
    mask: 'PHONE_MASK',
    icon: 'Phone',
    text: 'PHONE',
  },
];


export const notifications = (email, phone) => [
  {
    success: {
      iconName: 'Email',
      messageTitle: 'EMAIL_SENT',
      messageDescription: 'EMAIL_SENT_DESC',
      buttonLabel: 'REDEFINE_PASSWORD_BUTTON',
    },
    error: {
      iconName: 'EmailAlert',
      messageTitle: 'USER_NOT_FOUND',
      messageDescription: 'USER_NOT_FOUND_DESC',
      buttonLabel: 'BACK',
    }
  },
  {
    success: {
      iconName: 'Phone',
      messageTitle: 'SMS_SENT',
      messageDescription: 'SMS_SENT_DESC',
      buttonLabel: 'REDEFINE_PASSWORD_BUTTON',
    },
    error: {
      iconName: 'ErrorAlert',
      messageTitle: 'USER_NOT_FOUND',
      messageDescription: 'PHONE_NOT_FOUND',
      buttonLabel: 'BACK',
    } 
  },
]