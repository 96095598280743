import * as React from 'react'

function SvgCreditCard(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.857 1H5.144a3.428 3.428 0 00-3.428 3.428v9.142A3.428 3.428 0 005.144 17h13.713a3.428 3.428 0 003.428-3.429V4.428A3.428 3.428 0 0018.857 1zM1.715 6.716h20.57M6.286 12.428h.01M10.858 12.428h2.286"
        stroke="currentColor"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCreditCard
