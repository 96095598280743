import React, { useState } from 'react'
import { Flex, MobileList, Text, ModalMenu, Avatar, Icon } from 'components'
import { UNITYS } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import { t } from 'services/translation'
import { financesStore } from 'services/stores/finances'
import { useSingleSaleStore } from 'services/stores'
import { theme } from 'styles'

const columns = [
  { key: 'profile_image_url', label: '', type: 'image', placeholder: 'barber' },
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
]

export const UnitysModal = ({ goBack, isModalOpen, onClose }) => {
  const toast = useToast()
  const [data, setData] = useState()
  const { unity, setUnity } = financesStore()

  async function getUnitys() {
    const res = await api
      .get(UNITYS)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading } = useQuery('get-unitys', getUnitys, {
    onSuccess: (d) => {
      setData([
        {
          id: 'all',
          name: 'Todas as unidades',
          address: {
            street: 'Visualizar financeiro de todas as unidades',
            city: '',
          },
        },
        ...d,
      ])
    },
    onError: (error) => {
      toast({
        title: t('ERROR_GET_UNITYS'),
        description: error.toString().substring(7),
        type: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const renderItem = ({ item, selected }) => {
    return (
      <Flex
        align="center"
        justify="space-between"
        h={theme.pxToRem(64)}
        w="100%"
        bg="component"
        mb={theme.pxToRem(16)}
        padding={theme.pxToRem(16)}
        borderRadius={theme.pxToRem(8)}
        borderWidth={1}
        onClick={() => handleSelectBarber(item)}
        cursor="pointer"
        borderColor={selected ? 'primary' : 'transparent'}
      >
        <Avatar
          size="medium"
          src={item?.profile_image_url}
          type="image"
          placeholder="barber"
        />
        <Text w="75%" kind="bold" color="textLight">
          {item.name}
        </Text>
        <Icon name="ChevronRight" />
      </Flex>
    )
  }

  const handleSelectBarber = (unity) => {
    setUnity(unity)
    onClose()
  }

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={onClose}
      title={t('SELECT_UNITY_')}
    >
      <MobileList
        data={data || []}
        loading={isLoading}
        onSelect={handleSelectBarber}
        renderItem={({ item, index }) =>
          renderItem({
            item,
            selected:
              unity?.id === 'all' ? item?.id === 'all' : unity?.id === item?.id,
          })
        }
      />
    </ModalMenu>
  )
}
