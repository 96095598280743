import { Text } from "components/atoms"
export const columns = [
    {
      key: 'profile_image_url',
      label: '',
      type: 'image',
      placeholder: 'barber',
    },
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <>
          <Text w="100%" kind="bold" color="textLight">
            {item.name}
          </Text>
        </>
      ),
    },
    {
      key: 'visible_name',
      label: '',
    },
  ]