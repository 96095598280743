const currentPrefix = 'v3'

export const CREATE_INVOICE_RECORD = `${currentPrefix}/invoice-records`
export const CREATE_INVOICE_RECORD_NFSE = `${currentPrefix}/invoice-records-servicos`

export const GET_ALL_INVOICE_RECORD = ({
  take = 10,
  skip = 0,
  ...params
} = {}) => {
  const urlParams = new URLSearchParams({ take, skip, ...params })
  return `${currentPrefix}/invoice-records?${urlParams}`
}

export const GET_ONE_INVOICE_RECORD = (id) =>
  `${currentPrefix}/invoice-records/${id}`

export const UPDATE_INVOICE_RECORD = (id) =>
  `${currentPrefix}/invoice-records/${id}`

export const DELETE_INVOICE_RECORD = (id) =>
  `${currentPrefix}/invoice-records/${id}`
