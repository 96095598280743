import { api } from 'services/api/config'

export const useAverageTicket = () => {
  const getAverageTicket = async (props) => {
    const response = await api({
      url: `v3/barbershop/indicators/average-ticket/${props.id}`,
      params: {
        start_date: props.startDate,
        end_date: props.endDate,
        status: props?.status,
      },
    })
    return response.data
  }

  return {
    getAverageTicket,
  }
}
