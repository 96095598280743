import { addDays, isSameDay, format, parseISO } from 'date-fns'

export const formatAppointmentsToChart = (startDate, endDate, barber, data) => {
  if (!data) {
    return []
  }
  var dateArray = []
  var currentDate = parseISO(startDate)
  while (currentDate <= parseISO(endDate)) {
    // eslint-disable-next-line no-loop-func
    const appointmentsOfTheDay = data?.filter(
      // eslint-disable-next-line no-loop-func
      (item) => isSameDay(new Date(item?.date), new Date(currentDate))
    )
    // const totalValue =
    //   appointmentsOfTheDay?.length > 0
    //     ? appointmentsOfTheDay?.reduce(
    //       (acc, app) => acc + Number(app?.total_value),
    //       0
    //     )
    //     : 0
    dateArray.push({
      date: format(new Date(currentDate), 'dd/MM'),
      // receita: totalValue,
      Agendamentos: appointmentsOfTheDay?.length,
    })
    currentDate = addDays(currentDate, 1)
  }
  return { data: dateArray, key: 'Agendamentos' }
}

export const formatSimpleToChart = (startDate, endDate, data) => {
  if (!data) {
    return []
  }
  var dateArray = []
  var currentDate = parseISO(startDate)
  while (currentDate <= parseISO(endDate)) {
    const appointmentsOfTheDay = data?.filter((item) => isSameDay(new Date(item?.createdAt), new Date(currentDate)))
    dateArray.push({
      date: format(new Date(currentDate), 'dd/MM'),
      'Novos clientes': appointmentsOfTheDay?.length,
    })
    currentDate = addDays(currentDate, 1)
  }
  return { data: dateArray, key: 'Novos clientes' }
}

export const formatAppointmentsByProduct = (data) => {
  if (!data) {
    return []
  }
  const groups = data?.appointmentsThisMonth?.reduce((groups, appointment) => {
    const product = appointment.product.name
    const foundIndexOfProduct = groups.findIndex((item) => item.service === product)
    if (foundIndexOfProduct >= 0) {
      groups[foundIndexOfProduct] = {
        ...groups[foundIndexOfProduct],
        quantity: groups[foundIndexOfProduct].quantity + 1,
        receita: groups[foundIndexOfProduct].receita + appointment.product_price,
      }
    } else {
      groups.push({
        service: product,
        quantity: 1,
        receita: appointment.product_price,
      })
    }
    return groups
  }, [])
  return groups
}

export const formatHomeChart = (startDate, stopDate, data) => {
  if (!data) {
    return []
  }
  // get all days of the month
  const sDate = parseISO(startDate)
  const fDate = parseISO(stopDate)
  var dateArray = []
  var currentDate = sDate
  while (currentDate <= fDate) {
    // eslint-disable-next-line no-loop-func
    const appointmentsOfTheDay = data?.all_appointments?.filter(
      (item) => item?.simple_date === format(currentDate, 'yyyy-MM-dd')
    )
    const totalValue =
      appointmentsOfTheDay?.length > 0
        ? appointmentsOfTheDay?.reduce((acc, app) => acc + Number(app?.total_value), 0)
        : 0
    dateArray.push({
      date: format(new Date(currentDate), 'dd/MM'),
      receita: totalValue,
      agendamentos: appointmentsOfTheDay?.length,
    })
    currentDate = addDays(currentDate, 1)
  }
  return dateArray
}
