import { useFetch } from './use-fetch'
import { BLOCK_TIME_DELETE, BLOCK_TIME_EXCEPTION } from 'services/api/endpoints'

export const useBlockedTime = () => {
  const { onFetch } = useFetch()

  async function deleteBlockedTime(props) {
    const response = await onFetch({
      ...props,
      url: BLOCK_TIME_DELETE(props?.blocked_id),
      method: 'delete',
      message: {
        success: 'UNBLOCKED_TIME_TOAST',
        error: 'ERROR_DELETE_DATA',
      },
    })
    return response
  }

  async function createExceptionBlockedTime(props) {
    const response = await onFetch({
      ...props,
      url: BLOCK_TIME_EXCEPTION(props?.blocked_id),
      method: 'put',
      message: {
        success: 'UNBLOCKED_TIME_TOAST',
        error: 'ERROR_DELETE_DATA',
      },
    })
    return response
  }

  return {
    deleteBlockedTime,
    createExceptionBlockedTime,
  }
}
