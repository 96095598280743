import * as React from 'react'

function SvgEditSmall(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.593 3.962H2.437A1.437 1.437 0 001 5.399v6.467a1.437 1.437 0 001.437 1.437h6.467a1.437 1.437 0 001.437-1.437V9.71"
        stroke="currentColor"
        strokeWidth={1.078}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.593 9.71h2.155l6.108-6.108a1.524 1.524 0 10-2.155-2.156L4.593 7.554V9.71zM9.623 2.524l2.155 2.156"
        stroke="currentColor"
        strokeWidth={1.078}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgEditSmall
