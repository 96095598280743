import React, { useState } from 'react'
import { Text, Button, Box, TextInput } from 'components/atoms'
import { ModalMenu, Table } from 'components/molecules'
import { Flex, useTheme, useToast } from '@chakra-ui/react'

import { useQuery } from 'react-query'
import { t } from 'services/translation'
import { useServices } from 'services/hooks/use-services'
import { useBarbershop } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { colors } from 'styles'
import { formatPhone } from 'utils/phone-formater'

export const SelectClient = ({ isOpen, onClose, client, setClient, ...props }) => {
  const toast = useToast()
  const { getServices } = useServices()
  const theme = useTheme()
  const { getClients } = useBarbershop()
  const { userData } = useBarbershopStore()
  const [query, setQuery] = useState('')
  const [clientsList, setClientsList] = useState([])
  const [skip, setSkip] = useState(0)
  const [refreshing, setRefreshing] = useState(false)

  const columns = [
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <Text w="100%" kind="bold" color="textLight">
          {item.client.name}
        </Text>
      ),
    },
    {
      key: 'phone',
      label: 'PHONE_NUMBER',
      width: 130,
      render: (item) => (
        <Text w="100%" kind="medium" color="textGrey">
          {formatPhone(item.client.user.phone, userData)}
        </Text>
      ),
    },
  ]

  const { refetch, isLoading } = useQuery(['get-clients', skip], () => getClients({ id: userData?.id, skip, query }), {
    onSuccess: (data) => {
      let newList = data
      if (skip !== 0 && query?.length % 2 === 0) {
        newList = [...clientsList, ...data]
      }
      setClientsList(newList)
      setRefreshing(false)
    },
  })

  const handleSelectCustomer = (item) => {
    setClient(item)
    onClose()
  }

  const fetchMoreItems = () => {
    setRefreshing(true)
    setSkip(skip + 20)
  }

  return (
    <ModalMenu isOpen={isOpen} onClose={onClose} title={t('SELECT_SERVICE')}>
      <Flex w="100%" justify="space-between" mb="12px">
        <TextInput
          noBorder
          type="text"
          color={colors.white}
          placeholder={t('SEARCH_CLIENT')}
          backgroundColor="backgroundLayout"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={12}
          paddingLeft={theme.pxToRem(16)}
          mb={theme.pxToRem(12)}
          onChange={(event) => setQuery(event?.target?.value)}
          w="90%"
          mr="5"
        />
        <Button onClick={refetch} icon="Search" iconColor="black" iconSize={20} w="10%" />
      </Flex>

      <Table
        withoutHeader
        loading={!refreshing && isLoading}
        columns={columns}
        data={clientsList}
        isSelectableRow
        onSelect={handleSelectCustomer}
      />
      <Flex width="full" justifyContent="center" paddingY={theme.pxToRem(32)}>
        {!isLoading && <Button onClick={fetchMoreItems}>Carregar mais</Button>}
      </Flex>
    </ModalMenu>
  )
}
