import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalContent } from '@chakra-ui/react'
import { Icon, Text, Button, Link } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { subscriptionBenefitsNew } from 'services/utils/subscriptionBenefitsNew'

export const SubscriptionModal = ({ isOpen, onClose, onClick, ...props }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const subscriptionItems = (item) => {
    return (
      <Flex align="flex-start" justify="flex-start" mb={'12px'} flexDir="row">
        <Flex
          align="center"
          justify="center"
          bg="success"
          width={5}
          height={5}
          borderRadius={'100%'}
        >
          <Icon color="black" size={12} name={item?.premium} />
        </Flex>
        <Text fontSize={18} kind={'bold'} ml={3}>
          {item?.name}
        </Text>
      </Flex>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={theme.pxToRem(600)}
        borderColor="primary"
        borderWidth={1}
        bg="backgroundLayout"
        flexDir="column"
        py="32px"
        borderRadius="12px"
        alignItems="center"
        mt="14px"
      >
        <Flex w="90%" flexDir="column" align="center" justify="center">
          <Icon name="CardExpired" color="primary" size={48} />
          <Text
            kind="bold"
            fontSize={theme.pxToRem(32)}
            textDecoration="none"
            color="primary"
            mb={4}
            ml={4}
          >
            {t('SUBSCRIPTION_HAS_EXPIRED')}
          </Text>
          <Text w="95%" fontSize={theme.pxToRem(16)} mb={4} align="center">
            {t('SUBSCRIPTION_PLAN_BENEFITS')}
          </Text>
          <Text mb={8} fontSize={theme.pxToRem(24)} kind="bold" align="center">
            {t('SUBSCRIPTION_BENEFITS')}
          </Text>
          <Flex flexDir="column" align="flex-start" justify="flex-start">
            {subscriptionBenefitsNew?.map((item) => subscriptionItems(item))}
          </Flex>
          <Button
            onClick={onClick}
            _hover={{ cursor: 'pointer' }}
            mb="16px"
            mt="12px"
            isLoading={props?.isLoading}
          >
            {t('REGULARIZE_SIGNATURE')}
          </Button>
          <Button
            kind="outline"
            onClick={onClose}
            href="/login"
            _hover={{ cursor: 'pointer' }}
          >
            {t('TO_GO_BACK')}
          </Button>
          {/* <Link
            onClick={goBack}
            fontSize={theme.pxToRem(18)}
            kind="bold"
            color="white"
            href="/login"
            _hover={{ cursor: 'pointer' }}
          >
            {t('TO_GO_BACK')}
          </Link> */}
        </Flex>
      </ModalContent>
    </Modal>
  )
}
