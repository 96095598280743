import * as React from 'react'

function SvgPoint(props) {
  return (
    <svg
      {...props}
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="4" r="4" fill={props.color} />
    </svg>
  )
}

export default SvgPoint
