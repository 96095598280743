import React from 'react'
import { Text, TextInput } from 'components/atoms'
import { FieldControl } from 'components/molecules'
import { useFormikContext } from 'formik'

import { VStack, Select, HStack, Divider, Collapse } from '@chakra-ui/react'

export const IssuerInvoiceCarrierForm = ({
  isLoading,
  isDisabled,
  ...props
}) => {
  const formik = useFormikContext()

  return (
    <Collapse
      in={formik.values.presenca_comprador === '4'}
      animateOpacity
      {...props}
    >
      <VStack spacing={4} align="normal" mt={6}>
        <HStack spacing={4}>
          <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
            Transportador
          </Text>
          <Divider borderColor="gray.600" />
        </HStack>

        <FieldControl
          label="Tipo de transportador"
          error={formik.errors.helpers?.carrierType}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <Select
              name="helpers.carrierType"
              size="lg"
              bg="backgroundLayout"
              borderColor="gray.800"
              h="52px"
              value={formik.values.helpers?.carrierType}
              onChange={formik.handleChange}
            >
              <option value="">Selecione</option>
              <option value="PF">Pessoa Física</option>
              <option value="PJ">Pessoa Jurídica</option>
            </Select>
          )}
        />

        <HStack align="normal">
          <FieldControl
            label="Nome"
            error={formik.errors.nome_transportador}
            isLoading={isLoading}
            isDisabled={isDisabled}
            render={() => (
              <TextInput
                name="nome_transportador"
                value={formik.values.nome_transportador}
                onChange={formik.handleChange}
              />
            )}
          />
          {formik.values.helpers?.carrierType === 'PF' && (
            <FieldControl
              label="CPF"
              error={formik.errors.cpf_transportador}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={({ error }) => (
                <TextInput
                  name="cpf_transportador"
                  mask="CPF_MASK"
                  error={!!error}
                  value={formik.values.cpf_transportador}
                  onChange={formik.handleChange}
                />
              )}
            />
          )}
          {formik.values.helpers?.carrierType === 'PJ' && (
            <FieldControl
              label="CNPJ"
              error={formik.errors.cnpj_transportador}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={({ error }) => (
                <TextInput
                  name="cnpj_transportador"
                  mask="CNPJ_MASK"
                  error={!!error}
                  value={formik.values.cnpj_transportador}
                  onChange={formik.handleChange}
                />
              )}
            />
          )}
        </HStack>

        <HStack align="normal">
          <FieldControl
            label="Inscrição estadual"
            error={formik.errors.inscricao_estadual_transportador}
            isLoading={isLoading}
            isDisabled={isDisabled}
            render={() => (
              <TextInput
                name="inscricao_estadual_transportador"
                value={formik.values.inscricao_estadual_transportador}
                onChange={formik.handleChange}
              />
            )}
          />

          <FieldControl
            label="UF"
            error={formik.errors.uf_transportador}
            isLoading={isLoading}
            isDisabled={isDisabled}
            render={() => (
              <Select
                name="uf_transportador"
                size="lg"
                bg="backgroundLayout"
                borderColor="gray.800"
                h="52px"
                value={formik.values.uf_transportador}
                onChange={formik.handleChange}
              >
                <option value="">Selecione</option>
                <option value="AC">Acre (AC)</option>
                <option value="AL">Alagoas (AL)</option>
                <option value="AP">Amapá (AP)</option>
                <option value="AM">Amazonas (AM)</option>
                <option value="BA">Bahia (BA)</option>
                <option value="CE">Ceará (CE)</option>
                <option value="DF">Distrito Federal (DF)</option>
                <option value="ES">Espírito Santo (ES)</option>
                <option value="GO">Goiás (GO)</option>
                <option value="MA">Maranhão (MA)</option>
                <option value="MT">Mato Grosso (MT)</option>
                <option value="MS">Mato Grosso do Sul (MS)</option>
                <option value="MG">Minas Gerais (MG)</option>
                <option value="PA">Pará (PA)</option>
                <option value="PB">Paraíba (PB)</option>
                <option value="PR">Paraná (PR)</option>
                <option value="PE">Pernambuco (PE)</option>
                <option value="PI">Piauí (PI)</option>
                <option value="RJ">Rio de Janeiro (RJ)</option>
                <option value="RN">Rio Grande do Norte (RN)</option>
                <option value="RS">Rio Grande do Sul (RS)</option>
                <option value="RO">Rondônia (RO)</option>
                <option value="RR">Roraima (RR)</option>
                <option value="SC">Santa Catarina (SC)</option>
                <option value="SP">São Paulo (SP)</option>
                <option value="SE">Sergipe (SE)</option>
                <option value="TO">Tocantins (TO)</option>
              </Select>
            )}
          />
        </HStack>

        <HStack align="normal">
          <FieldControl
            label="Município"
            error={formik.errors.municipio_transportador}
            isLoading={isLoading}
            isDisabled={isDisabled}
            render={() => (
              <TextInput
                name="municipio_transportador"
                value={formik.values.municipio_transportador}
                onChange={formik.handleChange}
              />
            )}
          />
          <FieldControl
            label="Endereço"
            error={formik.errors.endereco_transportador}
            isLoading={isLoading}
            isDisabled={isDisabled}
            render={() => (
              <TextInput
                name="endereco_transportador"
                value={formik.values.endereco_transportador}
                onChange={formik.handleChange}
              />
            )}
          />
        </HStack>
      </VStack>
    </Collapse>
  )
}
