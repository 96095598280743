import { api } from 'services/api/config'

export const useBarbershopGoals = () => {
  const getAllGoals = async (props) => {
    const response = await api({
      url: `v3/barbershop/barber-goals`,
      params: { barbershop_id: props.barbershop_id },
    })
    return response.data
  }

  const getAllGoalsResume = async (props) => {
    const response = await api.post(`v3/barbershop/barber-goals-resume`, props?.body)
    return response.data
  }

  const createGoal = async (props) => {
    const response = await api.post(`v3/barbershop/barber-goals`, props?.body)
    return response.data
  }
  const updateGoal = async (props) => {
    const response = await api.put(`v3/barbershop/barber-goals/${props?.goal_id}`, props?.body)
    return response.data
  }

  const deleteGoal = async (props) => {
    const response = await api.delete(`v3/barbershop/barber-goals/${props?.id}`)
    return response.data
  }
  const getBarbershopProducts = async (props) => {
    const response = await api({
      url: `v3/barbershop/${props?.barbershop_id}/barber/${props?.barber_id}/products`,
      params: { type: props?.type },
    })
    return response.data
  }

  const getProductsByBarber = async (props) => {
    const response = await api({
      url: `v3/barber-products-and-services`,
      params: {
        barbershop_id: props?.barbershop_id,
        barber_id: props?.barber_id,
      },
    })
    return response.data
  }

  return {
    getBarbershopProducts,
    getProductsByBarber,
    deleteGoal,
    updateGoal,
    getAllGoals,
    createGoal,
    getAllGoalsResume,
  }
}
