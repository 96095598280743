import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { Box, Flex, Text, Button, CheckBox, Loader } from 'components/atoms'
import { FeatureBlockedMessage } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'

import { api } from 'services/api/config'
import { BARBERSHOP_NOTIFICATION } from 'services/api/endpoints'
import { useQuery, useMutation } from 'react-query'
import { Layout } from 'components'
import { usePermissionCollaborator, usePremiumBlock } from 'services/hooks'

export const NotificationsBarbershop = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData, user, subscription } = useBarbershopStore()
  const [sendEmail, setSendEmail] = useState(null)
  const [sendPush, setSendPush] = useState(null)
  const permission = usePermissionCollaborator('notifications')
  const isBlocked = usePremiumBlock()

  async function getAccessData() {
    const res = await api
      .get(BARBERSHOP_NOTIFICATION(userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading } = useQuery('get-access-data', getAccessData, {
    onSuccess: (value) => {
      setSendEmail(value.email_notification)
      setSendPush(value.push_notification)
    },
    refetchOnWindowFocus: false,
  })

  async function changeAccessData() {
    const params = {
      ...user,
      email_notification: sendEmail,
      push_notification: sendPush,
      notification: true,
    }
    const res = await api
      .put(BARBERSHOP_NOTIFICATION(userData?.id), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('change-access-data', changeAccessData, {
    onSuccess: (data) => {
      toast({
        title: t('UPDATED_DATA'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_UPDATE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  if (isBlocked) {
    return <FeatureBlockedMessage icon="BarbershopLink" title="LINK_BLOCKED" />
  }

  return (
    <Layout headerTitle={t('NOTIFICATIONS_TITLE')}>
      <Box flex="1">
        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          {t('NOTIFICATIONS_DESC_BARBERSHOP')}
        </Text>
        <Box mt={pxToRem(32)}>
          {isLoading && (
            <Flex>
              <Loader />
            </Flex>
          )}
          {/* {!isLoading && (
            <Flex mt={pxToRem(16)} alignItems="center">
              <CheckBox
                onChange={(event) => setSendEmail(event?.target?.checked)}
                color="primary"
                borderColor="black"
                size="lg"
                defaultChecked={sendEmail}
                isReadOnly={permission !== 'edit'}
              />

              <Text ml={pxToRem(8)} fontWeight="bold" fontSize="medium">
                {t('SEND_EMAIL')}
              </Text>
            </Flex>
          )} */}
          {!isLoading && (
            <Flex mt={pxToRem(16)} alignItems="center">
              <CheckBox
                onChange={(event) => setSendPush(event?.target?.checked)}
                color="primary"
                borderColor="primary"
                size="lg"
                defaultChecked={sendPush}
                isReadOnly={permission !== 'edit'}
              />

              <Text ml={pxToRem(8)} fontWeight="bold" fontSize="medium">
                {t('SEND_PUSH')}
              </Text>
            </Flex>
          )}
        </Box>
      </Box>
      {permission === 'edit' && (
        <Button
          disabled={mutate.isLoading}
          onClick={() => mutate.mutate()}
          mt={pxToRem(32)}
          size="block"
        >
          {t('SAVE_CHANGES')}
        </Button>
      )}
    </Layout>
  )
}

NotificationsBarbershop.path = '/notificacoes'
NotificationsBarbershop.title = 'Notificações'
NotificationsBarbershop.secured = true
