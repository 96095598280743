import * as React from 'react'

function SvgInterrogationCircle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 24.667c6.443 0 11.666-5.224 11.666-11.667S19.443 1.333 13 1.333C6.556 1.333 1.333 6.557 1.333 13S6.556 24.667 13 24.667z"
        stroke="currentColor"
        strokeWidth={1.111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M13.206 7.75c-1.636 0-2.697.67-3.53 1.867a.493.493 0 00.107.674l.885.67a.492.492 0 00.682-.084c.513-.644.895-1.014 1.697-1.014.63 0 1.411.406 1.411 1.017 0 .463-.382.7-1.005 1.05-.726.407-1.687.914-1.687 2.182v.2c0 .272.22.493.492.493h1.486c.272 0 .492-.22.492-.492v-.119c0-.879 2.57-.916 2.57-3.294 0-1.791-1.859-3.15-3.6-3.15zm-.205 7.659c-.783 0-1.42.637-1.42 1.42 0 .784.637 1.421 1.42 1.421.783 0 1.42-.637 1.42-1.42 0-.784-.637-1.421-1.42-1.421z"
      />
    </svg>
  )
}

export default SvgInterrogationCircle
