import * as React from 'react'

function SvgPencil(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="transparent"
        d="M.835 21.944h.148l7.124-1.55a.702.702 0 00.37-.185l12.07-12.07a4.652 4.652 0 001.366-3.323 4.799 4.799 0 00-1.366-3.36A4.651 4.651 0 0017.225.092a4.54 4.54 0 00-3.323 1.403L1.87 13.564a.664.664 0 00-.222.333L.097 21.02a.812.812 0 00.221.701.738.738 0 00.517.222zm16.39-20.376a3.212 3.212 0 013.211 3.248 3.101 3.101 0 01-.923 2.252l-4.54-4.578a3.175 3.175 0 012.252-.922zM13.939 3.56l4.54 4.54L7.96 18.585 3.42 14.08l10.52-10.52zM2.83 15.558l3.654 3.654-4.688 1.034 1.033-4.688z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPencil
