export const options = [
  {
    id: 1,
    kind: 'success',
    icon: 'Plus',
    iconColor: 'white',
    text: 'Lançamento de nova ENTRADA',
    modalTitle: 'ENTRADA',
    finance_category_id: 6,
    type: 'revenue',
  },
  {
    id: 2,
    kind: 'success',
    icon: 'Shampoo',
    iconColor: 'white',
    text: 'Lançamento de VENDA DE PRODUTO',
    modalTitle: 'VENDA DE PRODUTO',
  },
  {
    id: 3,
    kind: 'danger',
    icon: 'ExclamationCircle',
    iconColor: 'white',
    text: 'Lançamento de nova SAÍDA',
    modalTitle: 'SAÍDA',
    finance_category_id: 7,
    type: 'expense',
  },
  {
    id: 4,
    kind: 'danger',
    icon: 'ExclamationCircle',
    iconColor: 'white',
    text: 'Lançamento de SANGRIA',
    modalTitle: 'SANGRIA',
    finance_category_id: 8,
    type: 'expense',
  },
]
