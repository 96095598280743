import React, { useEffect, useMemo, useState } from 'react'
import { format, addMinutes, addHours, parseISO } from 'date-fns'
import { Flex, Box, Text, Calendar, TimeCard, Icon, TextInput, Button } from 'components'
import { useTheme, Spinner, Center } from '@chakra-ui/react'
import { useAppointmentStore, useBarbershopStore } from 'services/stores'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useAppointment } from 'services/hooks/use-appointment'
import { calculateInitialHour } from 'utils/servicesTime'
import { WEEK_DAYS } from 'utils'

const AppointmentSelectDate = ({ goBack }) => {
  const {
    time,
    setTime,
    date,
    setDate,
    barber,
    service,
    packageService,
    type,
    finishHour,
    setFinishHour,
    signature,
    appointmentType,
    client,
  } = useAppointmentStore()
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const { t } = useTranslation()
  const { getAvailableTime } = useAppointment()
  const { serviceArray } = useAppointmentStore()

  const turns = [
    {
      id: 0,
      key: 'morning',
    },
    {
      id: 1,
      key: 'evening',
      mr: 4,
      ml: 4,
    },
    {
      id: 2,
      key: 'night',
    },
  ]
  const [selectedTurn, setSelectedTurn] = useState('morning')

  const currentHour = date && format(date, 'HH:mm')
  const currentDate = date && format(new Date(), 'yyyy-MM-dd')
  const barber_id = barber && barber.id
  const barbershop_id = userData?.id
  // const product_id = service && service.id

  useEffect(() => {
    if (!date) {
      setDate(new Date())
    }
  }, [])

  useEffect(() => {
    if (currentDate && currentHour && date) {
      mutate.mutate()
    }
  }, [currentDate, currentHour, date])

  const mutate = useMutation('get-available-times', () =>
    getAvailableTime({
      params: {
        date: date && format(date, 'yyyy-MM-dd'),
        currentHour: format(new Date(), 'HH:mm'),
        currentDate,
        barber_id,
        barbershop_id,
        time_required: calculateInitialHour(serviceArray),
      },
    })
  )

  const handleSelectTime = (time) => {
    setTime(time)
    goBack()
  }
  const tileDisabled = ({ date }) => {
    return !signature?.plan?.days
      ? false
      : !signature?.plan?.days?.find((i) => i === date?.getDay()) &&
          signature?.plan?.days?.find((i) => i === date?.getDay()) !== 0
  }
  const renderAvailableTimeSlots = () => {
    if (mutate.isLoading) {
      return (
        <Center height={theme.pxToRem(450)} w="100%">
          <Spinner color="white" />
        </Center>
      )
    }
    if (!date || !barber_id || !barbershop_id) {
      return (
        <Center mt={theme.pxToRem(24)} flexDir="column">
          <Icon name="ErrorAlert" color="danger" />
          <Text mt={theme.pxToRem(12)} mb={theme.pxToRem(12)} align="center" fontSize={theme.pxToRem(14)}>
            {t('AVAILABLE_STEPS')}
          </Text>
        </Center>
      )
    }

    if (mutate.data?.[selectedTurn]?.length > 0) {
      return (
        <Flex flexDir="column" w="100%">
          <Flex dir="row" flexWrap="wrap">
            {mutate.data?.[selectedTurn]?.map((item, index) => (
              <TimeCard key={`morning_${index}`} onClick={() => handleSelectTime(item)}>
                {item}
              </TimeCard>
            ))}
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex flexDir="column" w="100%" m={theme.pxToRem(16)}>
        <Text mb={theme.pxToRem(16)} align="center" fontSize={theme.pxToRem(12)}>
          {t('NO_AVAILABLE_TIME')}
        </Text>
      </Flex>
    )
  }

  const fitTypeButtonDisabled = useMemo(() => {
    if (!time || !finishHour) {
      return true
    }
    if (finishHour?.replace('_', '')?.length < 5 || time?.replace('_', '')?.length < 5) {
      return true
    }
    if (finishHour < time || finishHour > '23:59' || time > '23:59') {
      return true
    }
  }, [finishHour, time])

  return (
    <Flex flexDirection="column">
      <Box marginBottom={theme.pxToRem(32)}>
        <Calendar
          selectedDate={date}
          onSelectDate={(date) => setDate(date)}
          minDate={type === 'normal' && new Date()}
          tileDisabled={appointmentType === 'signature' && type === 'normal' && tileDisabled}
          // maxDate={
          //   signature && parseISO(client?.client?.subscriptions[0]?.due_date)
          // }
        />
      </Box>
      <Box>
        {signature?.plan?.days && appointmentType && type === 'fit' && (
          <Text mb="8px" color={'primary'}>
            Dias permitidos para agendar:{' '}
            {signature?.plan?.days?.map((day) => t(WEEK_DAYS.find((i) => i.id === day)?.min)).join(', ')}
          </Text>
        )}
        <Text marginBottom={theme.pxToRem(16)} fontSize={theme.pxToRem(12)} textTransform="uppercase">
          {type === 'fit' ? t('TIME') : t('AVAILABLE_TIME')}
        </Text>
        {type !== 'fit' && (
          <Flex flexDir="column">
            <Flex w="100%" justify="space-between" mb={theme.pxToRem(24)}>
              {turns.map((item) => (
                <Button
                  {...item}
                  size="x-small"
                  kind={selectedTurn !== item.key ? 'outline-primary' : 'primary'}
                  onClick={() => setSelectedTurn(item.key)}
                >
                  {t(item.key.toUpperCase())}
                </Button>
              ))}
            </Flex>
            {renderAvailableTimeSlots()}
          </Flex>
        )}
        {type === 'fit' && (
          <>
            <Flex mb={theme.pxToRem(64)} flexDir="row" w="100%" justify="space-between">
              <TextInput
                label={t('START_TIME')}
                value={time}
                onChange={(e) => setTime(e.target.value)}
                type="time"
                mr={theme.pxToRem(12)}
              />

              <TextInput
                label={t('END_TIME')}
                value={finishHour}
                onChange={(e) => setFinishHour(e.target.value)}
                type="time"
                min={`${time}:00`}
                error={
                  finishHour < time
                    ? 'O horário do fim deve ser maior que o horário de início'
                    : finishHour > '23:59'
                    ? 'O horário final deve ser até 23:59'
                    : null
                }
              />
            </Flex>
            <Button disabled={fitTypeButtonDisabled} w="100%" onClick={goBack}>
              {t('CONFIRM')}
            </Button>
          </>
        )}
      </Box>
    </Flex>
  )
}

export default AppointmentSelectDate
