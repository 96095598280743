import React from 'react'

import { useTheme } from '@chakra-ui/react'

import { Box, Button, Icon, Text, Flex } from 'components'
import { useBreakpoint } from 'services/hooks'

import { LOGIN_SCREEN_STEPS as SCREEN_STEPS } from 'constants/login'
import { colors } from 'styles/colors'
import { notifications } from './settings'
import { useTranslation } from 'react-i18next'

export const LoginNotification = ({ values, ...props }) => {
  const { isMobile } = useBreakpoint()
  const { email, phone, tabIndex } = values
  const step = React.useMemo(() => props?.selectedStep, [props?.selectedStep])
  const theme = useTheme()
  const { t } = useTranslation()

  const onChangeStep = () => {
    if (screenInfo[step]?.proceedTo) {
      props.changeSelectedStep(screenInfo[step].proceedTo)
    }
  }

  const screenInfo = {
    [SCREEN_STEPS.MAIL_SENT]: {
      iconColor: colors.primary,
      ...notifications(email, phone)[tabIndex].success,
      proceedTo: SCREEN_STEPS.RESET_PASSWORD,
    },

    [SCREEN_STEPS.MAIL_NOT_SENT]: {
      iconColor: colors.danger,
      ...notifications(email, phone)[tabIndex].error,
      proceedTo: SCREEN_STEPS.FORGOT_PASSWORD,
    },

    [SCREEN_STEPS.PASSWORD_CHANGED]: {
      iconColor: colors.green[400],
      iconName: 'ShieldLock',
      messageTitle: t('SUCCESS'),
      messageDescription: t('PASSWORD_REDEFINED'),
      buttonLabel: t('BACK'),
      proceedTo: SCREEN_STEPS.LOGIN,
    },
  }

  return (
    <>
      <Flex flexDir="column">
        <Box
          mt={theme.pxToRem(12)}
          ml={theme.pxToRem(48)}
          mr={theme.pxToRem(48)}
        >
          <Flex justifyContent="center" color={screenInfo[step].iconColor}>
            <Icon
              size={72}
              name={screenInfo[step].iconName}
              color={screenInfo[step].iconColor}
            />
          </Flex>
          <Box mt={isMobile ? theme.pxToRem(8) : theme.pxToRem(48)}>
            <Text
              textAlign="center"
              fontSize={theme.pxToRem(22)}
              fontWeight="bold"
              mt={theme.pxToRem(8)}
            >
              {t(screenInfo[step].messageTitle)}
            </Text>

            <Text
              textAlign="center"
              fontSize={theme.pxToRem(16)}
              mt={theme.pxToRem(16)}
            >
              {t(screenInfo[step].messageDescription)}
            </Text>
          </Box>
        </Box>

        <Box mt={theme.pxToRem(32)}>
          <Box ml={theme.pxToRem(48)} mr={theme.pxToRem(48)}>
            <Button w="100%" onClick={onChangeStep}>
              {t(screenInfo[step].buttonLabel)}
            </Button>
          </Box>
        </Box>
      </Flex>
    </>
  )
}
