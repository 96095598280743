import React from 'react'
import { Flex, Box } from '@chakra-ui/react'
import { Icon, Text, Button } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { theme } from 'styles'
import { useTranslation } from 'react-i18next'
import { formatPhone } from 'utils/phone-formater'
import { useBarbershopStore } from 'services/stores'

const InfoCard = ({ name, value, icon }) => {
  return (
    <Flex mb="5">
      <Flex
        alignItems="center"
        justifyContent="center"
        w={pxToRem(44)}
        h={pxToRem(44)}
        bg="whiteTransparent.100"
        borderRadius="5px"
      >
        <Icon name={icon} />
      </Flex>
      <Box ml="4">
        <Text fontSize={14}>{name}</Text>
        <Text fontSize={16} kind="bold">
          {value}
        </Text>
      </Box>
    </Flex>
  )
}

const InfoSection = ({ barber, onDelete, onOpenEditModal, onOpenPermissionModal, ...props }) => {
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()

  return (
    <Flex flexDir="column" {...props}>
      <InfoCard icon="Person" name={t('FUNCTION')} value={t(barber?.type || barber?.visible_name)} />

      {barber?.rating_average && (
        <InfoCard icon="Star" name={t('RATINGS_AVERAGE')} value={barber?.rating_average?.toFixed(2)} />
      )}

      {barber?.user?.email && <InfoCard icon="Email" name="E-mail" value={barber?.user?.email} />}

      {barber?.user?.phone && (
        <InfoCard icon="Phone" name={t('BARBER_PHONE')} value={formatPhone(barber?.user?.phone, userData)} />
      )}

      <Button size="block" mt={theme.pxToRem(12)} onClick={onOpenEditModal}>
        {t('EDIT_TEAM')}
      </Button>

      {barber?.user?.type !== 'barbershopAndBarber' && (
        <Button size="block" mt={theme.pxToRem(12)} onClick={onOpenPermissionModal}>
          {t('EDIT_PERMISSION')}
        </Button>
      )}

      <Button size="block" kind="danger" mt={theme.pxToRem(24)} mb={theme.pxToRem(32)} onClick={() => onDelete(barber)}>
        {t('DELETE_BARBER')}
      </Button>
    </Flex>
  )
}

export default InfoSection
