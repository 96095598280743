import { useState } from 'react'

import { getleftDays } from 'utils/getLeftDays'
import {
  saveRefreshData,
  getRefreshData,
  getLoginSavedData,
  getSeeTutorial,
  getSeeNews,
  saveSeeTutorial,
  saveSeeNews,
} from './use-auth'
import { DATE } from 'constants/date'
import { api } from 'services/api/config'
import { parseISO } from 'date-fns'

export default function useDateReviewed() {
  const [showReviewedModal, setShowReviewedModal] = useState({
    premium: false,
    babershopUpdatedData: false,
    babershopUpdatedAddress: false,
  })
  const [seeTutorial, setSeeTutorial] = useState(true)
  const [seeNewsState, setSeeNews] = useState(false)

  // const onPremiumReview = async () => {
  //   const { account_status = '', iugu_plan_id = '' } = userData?.barbershop
  //   const lastDateReviewed = await getPremiumItem()
  //   const isPremium =
  //     account_status === 'SUBSCRIBED' && iugu_plan_id === 'free_barbershop_plan'

  //   if (lastDateReviewed !== null) {
  //     const leftDays = getleftDays(lastDateReviewed)

  //     if (leftDays > DATE.PREMIUM_DATE_REVIEWED) {
  //       await setPremiumItem(new Date().toString())
  //       setShowReviewedModal((prevState) => ({
  //         ...prevState,
  //         premium: !isPremium,
  //       }))
  //     }
  //   } else {
  //     await setPremiumItem(new Date().toString())
  //   }
  // }

  // const onBabershopUpdatedDataReview = async () => {
  //   const { modalDataRefresh } = await getRefreshData()
  //   const { userData } = await getLoginSavedData()
  //   const createdAt = getleftDays(userData.created_at)
  //   if (modalDataRefresh !== null) {
  //     const leftDays = getleftDays(modalDataRefresh)
  //     if (leftDays > DATE.BARBERSHOP_UPDATE_DATA_DATE_REVIEWED) {
  //       await saveRefreshData({ modalDataRefresh: new Date().toString() })
  //       setShowReviewedModal((prevState) => ({
  //         ...prevState,
  //         babershopUpdatedData: true,
  //       }))
  //     }
  //     return
  //   }
  //   if (createdAt < 15 && !modalDataRefresh) {
  //     await saveRefreshData({ modalDataRefresh: new Date().toString() })
  //     setShowReviewedModal((prevState) => ({
  //       ...prevState,
  //       babershopUpdatedData: true,
  //     }))
  //   } else {
  //     await saveRefreshData({ modalDataRefresh: new Date().toString() })
  //   }
  // }

  // const onBabershopUpdatedAddressReview = async () => {
  //   const { modalRefreshAddress } = await getRefreshData()
  //   if (modalRefreshAddress !== null) {
  //     const leftDays = getleftDays(modalRefreshAddress)
  //     if (leftDays > DATE.BARBERSHOP_UPDATE_ADDRESS_DATE_REVIEWED) {
  //       await saveRefreshData({ modalRefreshAddress: new Date().toString() })
  //       setShowReviewedModal((prevState) => ({
  //         ...prevState,
  //         babershopUpdatedAddress: true,
  //       }))
  //     }
  //     return
  //   } else {
  //     await saveRefreshData({ modalRefreshAddress: new Date().toString() })
  //   }
  // }

  const onSeeTutorial = async () => {
    const { seeTutorial } = await getSeeTutorial()
    if (!seeTutorial) {
      await saveSeeTutorial({ tutorial: true })
      setSeeTutorial(false)
      return
    } else {
      await saveSeeTutorial({ tutorial: true })
      setSeeTutorial(true)
    }
  }

  const onSeeNews = async () => {
    const [{ seeNews }, lastTutorial] = await Promise.all([
      getSeeNews(),
      api({
        url: 'v3/best-last-news',
      }),
    ])
    const lastDate = lastTutorial.data.bestNews[0]?.createdAt

    const parsed = parseISO(lastDate)

    if (!seeNews) {
      await saveSeeNews({ news: lastDate })
      setSeeNews(lastTutorial.data.bestNews[0])
      return
    }

    if (seeNews) {
      const parsedSeeNews = parseISO(seeNews)

      if (parsedSeeNews >= parsed) {
        setSeeNews(false)
        return
      } else {
        await saveSeeNews({ news: lastDate })
        setSeeNews(lastTutorial.data.bestNews[0])
      }
    }
    // if (!seeTutorial) {
    //   await saveSeeTutorial({ tutorial: true })
    //   setSeeTutorial(false)
    //   return
    // } else {
    //   await saveSeeTutorial({ tutorial: true })
    //   setSeeTutorial(true)
    // }
  }

  return {
    onSeeNews,
    seeNews: seeNewsState,
    showReviewedModal,
    seeTutorial,
    onSeeTutorial,
    // onPremiumReview,
    // onBabershopUpdatedDataReview,
    // onBabershopUpdatedAddressReview,
  }
}
