
import React from 'react'
import PropTypes from 'prop-types'
import { Text as ChakraText } from '@chakra-ui/react'

const fonts = {
  regular: 'Montserrat Regular',
  medium: 'Montserrat Medium',
  semiBold: 'Montserrat SemiBold',
  bold: 'Montserrat Bold',
  extraBold: 'Montserrat ExtraBold',
  black: 'Montserrat Black'
}

export const Text = ({ children, kind, ...props }) => (
  <ChakraText {...props} fontFamily={fonts[kind]}>
    {children}
  </ChakraText>
)

Text.defaultProps = {
  kind: 'regular',
  color: '#EBE9E1',
}

Text.propTypes = {
  ...ChakraText.PropTypes,
  children: PropTypes.node,
  kind: PropTypes.oneOf(['regular', 'medium', 'bold', 'semiBold', 'bold', 'extraBold', 'black']),
  color: PropTypes.string,
}
