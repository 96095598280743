import { Box } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import {
  WorkingHours,
  Location,
  RedefinePassword,
  ProfileData,
  Photos,
  BlockedTime,
  AccessData,
  LogoutModal,
  ShareLink,
  Plan,
  Notifications,
  DeleteAccountModal,
} from 'components/organisms'

export const ProfileContainer = ({ route }) => {
  function mapRouteToComponent(route) {
    const map = {
      dados: <ProfileData />,
      acesso: <AccessData />,
      link: <ShareLink />,
      senha: <RedefinePassword />,
      localizacao: <Location />,
      horarios: <WorkingHours />,
      fotos: <Photos />,
      'horario-bloqueado': <BlockedTime />,
      notificacoes: <Notifications />,
      'meu-plano': <Plan />,
      deletar: <DeleteAccountModal />,
      sair: <LogoutModal />,
    }

    return map[route]
  }

  return (
    <>
      {route === 'sair' || route === 'deletar' ? (
        <></>
      ) : (
        <Box
          flex="1"
          p={pxToRem(32)}
          maxW={pxToRem(680)}
          background="cardBackground"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={pxToRem(12)}
        >
          {mapRouteToComponent(route)}
        </Box>
      )}
    </>
  )
}
