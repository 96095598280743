export const  options = (plan) => [
      {
        id: 'name',
        name: 'name',
        type: 'text',
        noBorder: true,
        label: 'CLIENT_NAME',
        placeholder: 'Corte + Barba - Clube de assinatura',
        disabled: false,
      },
      {
        id: 'value',
        name: 'value',
        label: 'VALUE',
        noBorder: true,
        mask: 'CURRENCY_MASK',
        type: 'money',
        placeholder: 'R$ 10,00',
        disabled: plan,
      },
      {
        id: 'comission',
        name: 'comission',
        label: 'COMISSION',
        noBorder: true,
        mask: 'PERCENTAGE_MASK',
        placeholder: '30%',
        disabled: plan,
      },
  ]

export const initialValues = {
  name: '',
  value: '',
  comission: '',
}
