import * as React from 'react'

function ArrowDown(props) {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.9994 0.000383377L0.999426 0.000383377C0.817185 0.000954708 0.63855 0.0512179 0.48275 0.145761C0.32695 0.240305 0.199884 0.375548 0.115231 0.536936C0.0305767 0.698323 -0.00845994 0.879744 0.00232315 1.06167C0.0131062 1.24359 0.0733 1.41913 0.176426 1.56938L9.17643 14.5694C9.54943 15.1084 10.4474 15.1084 10.8214 14.5694L19.8214 1.56938C19.9256 1.41944 19.9867 1.24381 19.9981 1.06159C20.0094 0.879362 19.9706 0.697505 19.8859 0.535776C19.8012 0.374047 19.6738 0.23863 19.5175 0.144239C19.3612 0.0498492 19.182 9.57934e-05 18.9994 0.000383377Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowDown
