import React, { useState } from 'react'
import AppointmentSelectOptions from './SelectOptions'
import AppointmentSelectClient from './SelectClient'
import AppointmentSelectBarber from './SelectBarber'
import AppointmentSelectProducts from './SelectProducts'
import { APPOINTMENT_STEPS } from './settings'
import { Discount } from '../AppointmentDetailsModal/Discount'
import { PaymentMethod } from '../AppointmentDetailsModal/PaymentMethod'
import { usePremiumBlock } from 'services/hooks'
import { Flex, Icon, Text, Button } from 'components/atoms'
import { useTheme } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ModalButtonBackground } from 'components-v2/atoms'

export const SingleSaleOptions = ({ onClose }) => {
  const [schedulingStep, setSchedulingStep] = useState(
    APPOINTMENT_STEPS.singleSale
  )

  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const { t } = useTranslation()

  const handleBackNavigation = () => {
    setSchedulingStep(APPOINTMENT_STEPS.singleSale)
  }

  const BlockedFeature = () => {
    return (
      <Flex
        flexDir="column"
        align="center"
        pt={theme.pxToRem(320)}
        width="100%"
        height="100%"
        position="absolute"
        bg="rgba(17, 17, 17, 0.7)"
        zIndex={5}
      >
        <Icon name="PremiumCrown" size={26} />

        <Text kind="bold" fontSize={14} color="primary" mt={theme.pxToRem(2)}>
          {t('PREMIUM_EXCLUSIVE')}
        </Text>
      </Flex>
    )
  }

  const displaySingleSaleSteps = () => {
    switch (schedulingStep) {
      case APPOINTMENT_STEPS.singleSale:
        return (
          <>
            {isBlocked && <BlockedFeature />}

            <AppointmentSelectOptions
              isSingleSale
              setStep={setSchedulingStep}
              onClose={onClose}
              goBack={handleBackNavigation}
            />
          </>
        )

      case APPOINTMENT_STEPS.selectProducts:
        return (
          <>
            <AppointmentSelectProducts
              isSingleSale
              goBack={handleBackNavigation}
              withSubtitle
            />
            {schedulingStep === APPOINTMENT_STEPS.selectProducts && (
              <ModalButtonBackground>
                <Button size="block" onClick={handleBackNavigation}>
                  {t('CONFIRM')}
                </Button>
              </ModalButtonBackground>
            )}
          </>
        )

      case APPOINTMENT_STEPS.selectCustomer:
        return (
          <AppointmentSelectClient
            withSubtitle
            isSingleSale
            goBack={handleBackNavigation}
          />
        )

      case APPOINTMENT_STEPS.selectBarber:
        return (
          <AppointmentSelectBarber
            withSubtitle
            isSingleSale
            goBack={handleBackNavigation}
          />
        )

      case APPOINTMENT_STEPS.selectDiscount:
        return <Discount isSingleSale goBack={handleBackNavigation} />

      case APPOINTMENT_STEPS.selectDiscountBarber:
        return (
          <Discount
            isSingleSale
            goBack={handleBackNavigation}
            hideDiscountFromBarber
          />
        )

      case APPOINTMENT_STEPS.selectPaymentMethod:
        return (
          <PaymentMethod
            // withSubtitle
            isSingleSale
            goBack={handleBackNavigation}
          />
        )

      default:
        return <AppointmentSelectOptions isSingleSale onClose={onClose} />
    }
  }

  return displaySingleSaleSteps()
}
