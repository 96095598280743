import * as React from 'react'

function SvgBox(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="currentColor"
        d="M16 4l10.667 6v12l-10.666 6-10.667-6V10l10.667-6zM16 16l10.667-6M16 16v12M16 16L5.335 10M21.333 7l-10.667 6"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgBox
