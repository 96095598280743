const currentPrefix = 'v3'

export const CREATE_INVOICE_ISSUER = `${currentPrefix}/invoice-issuers`

export const GET_ALL_INVOICE_ISSUER = ({
  take = 10,
  skip = 0,
  ...params
} = {}) => {
  const urlParams = new URLSearchParams({ take, skip, ...params })
  return `${currentPrefix}/invoice-issuers?${urlParams}`
}

export const GET_ONE_INVOICE_ISSUER = (id) =>
  `${currentPrefix}/invoice-issuers/${id}`

export const UPDATE_INVOICE_ISSUER = (id) =>
  `${currentPrefix}/invoice-issuers/${id}`

  export const UPDATE_INVOICE_SERVICE_ISSUER = (id) =>
  `${currentPrefix}/invoice-records/sync/${id}`

export const DELETE_INVOICE_ISSUER = (id) =>
  `${currentPrefix}/invoice-issuers/${id}`
