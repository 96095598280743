import React, { useState, useEffect } from 'react'
import { Text, Icon, TextInput, Button } from 'components/atoms'
import { FieldControl } from 'components/molecules'
import { FieldArray, useFormikContext } from 'formik'

import { VStack, Select, HStack, Divider, IconButton } from '@chakra-ui/react'

import { useInvoiceIssuer } from 'services/hooks'
import { useQuery } from 'react-query'
import { getData } from 'services/storage'

const itemVoid = {
  numero_item: '',
  codigo_produto: '',
  descricao: '',
  quantidade_comercial: '',
  quantidade_tributavel: '',
  codigo_ncm: '',
  cfop: '',
  cest: '',
  cst: '',
  valor_unitario_comercial: '',
  valor_unitario_tributavel: '',
  valor_bruto: '',
  valor_desconto: '',
  unidade_comercial: '',
  unidade_tributavel: '',
  icms_origem: '',
  icms_situacao_tributaria: '',
  icms_aliquota: '',
  icms_base_calculo: '',
  icms_modalidade_base_calculo: '',
}

const IssuerInvoiceItemCardField = ({ formik, item, index, arrayHelpers, isLoading, isDisabled, ...props }) => {
  const { getAllInvoiceIssuer } = useInvoiceIssuer()
  const [userLogged, setUserLogged] = useState({})

  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),
    { enabled: !!userLogged?.id, select: (res) => res.data[0] }
  )

  return (
    <VStack
      spacing={4}
      align="normal"
      bg="cardBackground"
      borderWidth={1}
      borderColor="modalBorder"
      borderRadius={6}
      p={4}
      key={index}
      {...props}
    >
      <HStack spacing={4}>
        <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
          # {item.numero_item}
        </Text>
        <Divider borderColor="gray.600" />
        {!isDisabled && (
          <HStack spacing={2}>
            <IconButton
              icon={<Icon name="Plus" size={18} color="white" />}
              size="sm"
              background="gray.900"
              opacity={0.7}
              _hover={{ opacity: 1 }}
              onClick={() =>
                arrayHelpers.insert(index, {
                  ...itemVoid,
                  numero_item: (+item.numero_item || 0) + 1,
                })
              }
            />

            {formik.values.itens.length > 1 && (
              <IconButton
                icon={<Icon name="Trash" size={18} color="danger" />}
                size="sm"
                background="gray.900"
                opacity={0.7}
                _hover={{ opacity: 1 }}
                onClick={() => arrayHelpers.remove(index)}
              />
            )}
          </HStack>
        )}
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Código do produto"
          error={formik.errors.itens?.[index]?.codigo_produto}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <TextInput
              name={`itens.${index}.codigo_produto`}
              value={item.codigo_produto}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Descrição"
          error={formik.errors.itens?.[index]?.descricao}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <TextInput name={`itens.${index}.descricao`} value={item.descricao} onChange={formik.handleChange} />
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Código NCM"
          error={formik.errors.itens?.[index]?.codigo_ncm}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`itens.${index}.codigo_ncm`}
              mask="NCM_MASK"
              error={!!error}
              value={item.codigo_ncm}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="CFOP"
          error={formik.errors.itens?.[index]?.cfop}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`itens.${index}.cfop`}
              mask="CFOP_MASK"
              error={!!error}
              value={item.cfop}
              onChange={formik.handleChange}
            />
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="CEST"
          error={formik.errors.itens?.[index]?.cest}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput name={`itens.${index}.cest`} error={!!error} value={item.cest} onChange={formik.handleChange} />
          )}
        />
        <FieldControl
          label="CST"
          error={formik.errors.itens?.[index]?.cst}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput name={`itens.${index}.cst`} error={!!error} value={item.cst} onChange={formik.handleChange} />
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Quantidade comercial"
          error={formik.errors.itens?.[index]?.quantidade_comercial}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <TextInput
              name={`itens.${index}.quantidade_comercial`}
              type="number"
              min={0}
              value={item.quantidade_comercial}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Quantidade tributável"
          error={formik.errors.itens?.[index]?.quantidade_tributavel}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <TextInput
              name={`itens.${index}.quantidade_tributavel`}
              type="number"
              min={0}
              value={item.quantidade_tributavel}
              onChange={formik.handleChange}
            />
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Valor bruto"
          error={formik.errors.itens?.[index]?.valor_bruto}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`itens.${index}.valor_bruto`}
              mask="CURRENCY_MASK"
              error={!!error}
              value={item.valor_bruto}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Valor desconto"
          error={formik.errors.itens?.[index]?.valor_desconto}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`itens.${index}.valor_desconto`}
              mask="CURRENCY_MASK"
              error={!!error}
              value={item.valor_desconto}
              onChange={formik.handleChange}
            />
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Valor unitário comercial"
          error={formik.errors.itens?.[index]?.valor_unitario_comercial}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`itens.${index}.valor_unitario_comercial`}
              mask="CURRENCY_MASK"
              error={!!error}
              value={item.valor_unitario_comercial}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Unidade comercial"
          error={formik.errors.itens?.[index]?.unidade_comercial}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <Select
              name={`itens.${index}.unidade_comercial`}
              size="lg"
              bg="backgroundLayout"
              borderColor="gray.800"
              h="52px"
              value={item.unidade_comercial}
              onChange={formik.handleChange}
            >
              <option value="">Selecione</option>
              <option value="UN">Unidade</option>
              <option value="KG">Quilograma</option>
              <option value="L">Litro</option>
              <option value="MT">Metro</option>
              <option value="CX">Caixa</option>
              <option value="PCT">Pacote</option>
              <option value="DUZ">Duzia</option>
            </Select>
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="Valor unitário tributável"
          error={formik.errors.itens?.[index]?.valor_unitario_tributavel}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={({ error }) => (
            <TextInput
              name={`itens.${index}.valor_unitario_tributavel`}
              mask="CURRENCY_MASK"
              error={!!error}
              value={item.valor_unitario_tributavel}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Unidade tributavel"
          error={formik.errors.itens?.[index]?.unidade_tributavel}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <Select
              name={`itens.${index}.unidade_tributavel`}
              size="lg"
              bg="backgroundLayout"
              borderColor="gray.800"
              h="52px"
              value={item.unidade_tributavel}
              onChange={formik.handleChange}
            >
              <option value="">Selecione</option>
              <option value="UN">Unidade</option>
              <option value="KG">Quilograma</option>
              <option value="L">Litro</option>
              <option value="MT">Metro</option>
              <option value="CX">Caixa</option>
              <option value="PCT">Pacote</option>
              <option value="DUZ">Duzia</option>
            </Select>
          )}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="ICMS de origem"
          error={formik.errors.itens?.[index]?.icms_origem}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <Select
              name={`itens.${index}.icms_origem`}
              size="lg"
              bg="backgroundLayout"
              borderColor="gray.800"
              h="52px"
              value={item.icms_origem}
              onChange={formik.handleChange}
            >
              <option value="">Selecione</option>
              <option value="0">Nacional</option>
              <option value="1">Estrangeira (importação direta)</option>
              <option value="2">Estrangeira (adquirida no mercado interno)</option>
              <option value="3">Nacional com mais de 40% de conteúdo estrangeiro</option>
              <option value="4">Nacional produzida através de processos produtivos básicos</option>
              <option value="5">Nacional com menos de 40% de conteúdo estrangeiro</option>
              <option value="6">Estrangeira (importação direta) sem produto nacional similar</option>
              <option value="7">Estrangeira (adquirida no mercado interno) sem produto nacional similar</option>
            </Select>
          )}
        />
        <FieldControl
          label="ICMS situação tributaria"
          error={formik.errors.itens?.[index]?.icms_situacao_tributaria}
          isLoading={isLoading}
          isDisabled={isDisabled}
          render={() => (
            <Select
              name={`itens.${index}.icms_situacao_tributaria`}
              size="lg"
              bg="backgroundLayout"
              borderColor="gray.800"
              h="52px"
              value={item.icms_situacao_tributaria}
              onChange={formik.handleChange}
            >
              <option value="">Selecione</option>
              {['1', '2'].includes(invoiceIssuerQuery.data?.regime_tributario) ? (
                <>
                  <option value="102">Tributada pelo Simples Nacional sem permissão de crédito</option>
                  <option value="300">Imune</option>
                  <option value="500">
                    CMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação
                  </option>
                </>
              ) : (
                <>
                  <option value="00">Tributada integralmente</option>
                  <option value="40">Isenta</option>
                  <option value="41">Não tributada</option>
                  <option value="60">ICMS cobrado anteriormente por substituição tributária</option>
                </>
              )}
            </Select>
          )}
        />
      </HStack>

      {item.icms_situacao_tributaria === '00' && (
        <>
          <HStack align="start">
            <FieldControl
              label="ICMS alíquota"
              error={formik.errors.itens?.[index]?.icms_aliquota}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <TextInput
                  name={`itens.${index}.icms_aliquota`}
                  type="number"
                  min={0}
                  max={100}
                  value={item.icms_aliquota}
                  onChange={formik.handleChange}
                />
              )}
            />
            <FieldControl
              label="ICMS base de calculo"
              error={formik.errors.itens?.[index]?.icms_base_calculo}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={({ error }) => (
                <TextInput
                  name={`itens.${index}.icms_base_calculo`}
                  mask="CURRENCY_MASK"
                  error={!!error}
                  value={item.icms_base_calculo}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
          <FieldControl
            label="ICMS modalidade base de calculo"
            error={formik.errors.itens?.[index]?.icms_modalidade_base_calculo}
            isLoading={isLoading}
            isDisabled={isDisabled}
            render={() => (
              <Select
                name={`itens.${index}.icms_modalidade_base_calculo`}
                size="lg"
                bg="backgroundLayout"
                borderColor="gray.800"
                h="52px"
                value={item.icms_modalidade_base_calculo}
                onChange={formik.handleChange}
              >
                <option value="">Selecione</option>
                <option value="0">Margem de valor agregado (%)</option>
                <option value="1">Pauta (valor)</option>
                <option value="2">Preço tabelado máximo (valor)</option>
                <option value="3">Valor da operação.</option>
              </Select>
            )}
          />
        </>
      )}
    </VStack>
  )
}

export const IssuerInvoiceItemsForm = ({ isLoading, isDisabled, ...props }) => {
  const formik = useFormikContext()
  return (
    <VStack spacing={4} align="normal" {...props}>
      <HStack spacing={4}>
        <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
          Itens
        </Text>
        <Divider borderColor="gray.600" />
      </HStack>

      <FieldArray
        name="itens"
        render={(arrayHelpers) => {
          if (!formik.values.itens?.length) {
            return (
              <Button w="full" onClick={() => arrayHelpers.push(itemVoid)}>
                Adicionar
              </Button>
            )
          }

          return (
            <VStack spacing={4} align="normal">
              {formik.values.itens.map((item, index) => (
                <IssuerInvoiceItemCardField
                  formik={formik}
                  item={item}
                  index={index}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  arrayHelpers={arrayHelpers}
                />
              ))}
            </VStack>
          )
        }}
      />
    </VStack>
  )
}
