const currentPrefix = 'v3'

export const LOGIN = `${currentPrefix}/login`
export const CREATE_BARBERSHOP = `${currentPrefix}/register/barbershop/web_barbershop`
export const CREATE_CLIENT = `${currentPrefix}/register/client/web_barbershop`
export const CHECK_PHONE = `${currentPrefix}/check-phone`
export const UPDATE_EMAIL = `${currentPrefix}/update-email`
export const RESET_PASSWORD = `${currentPrefix}/reset-password`
export const REVALIDATE_TOKEN = `${currentPrefix}/revalidate-token`
export const REVALIDATE_SUBSCRIPTION = `${currentPrefix}/revalidate-subscription`
