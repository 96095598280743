import * as React from 'react'

function MinusSvg(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33325 10C3.33325 9.53978 3.70635 9.16669 4.16659 9.16669H15.8333C16.2935 9.16669 16.6666 9.53978 16.6666 10C16.6666 10.4603 16.2935 10.8334 15.8333 10.8334H4.16659C3.70635 10.8334 3.33325 10.4603 3.33325 10Z"
        fill="#E5B817"
      />
    </svg>
  )
}

export default MinusSvg
