import React from 'react'
import { Circle, useTheme } from '@chakra-ui/react'
import { Text, Box, Flex } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'utils/price'
import { ChevronRight } from 'assets/icons'

export const ClientReportCard = ({
  item,
  index,
  color,
  totalAppointmentsThisMoth,
  onSelect,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Flex
      key={`service_card_${index}`}
      alignItems="center"
      px={theme.pxToRem(16)}
      py={theme.pxToRem(16)}
      mb={theme.pxToRem(8)}
      w="100%"
      onClick={() => onSelect(item)}
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Box
        mr={theme.pxToRem(8)}
        h={theme.pxToRem(8)}
        w={theme.pxToRem(8)}
        bgColor={color || 'transparent'}
        borderRadius={100}
      />

      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" w="50%">
          <Circle size={theme.pxToRem(8)} />
          <Box>
            <Text w="95%" fontSize={14} kind="medium" color="textLight">
              {item?.client?.name}
            </Text>
            <Text color="textGrey" fontSize={12}>
              {`${item?.quantity} ${t('APPOINTMENTS')}`}
            </Text>
          </Box>
        </Flex>

        <Box textAlign="end" w="40%">
          <Text kind="extraBold" color="textLight">
            {formatPrice(item?.product_value + item?.service_value)}
          </Text>
          <Text kind="medium" fontSize={14} color="textGrey">
            {((item?.quantity / totalAppointmentsThisMoth) * 100).toFixed(2)}%
          </Text>
        </Box>
        {onSelect && (
          <ChevronRight
            color={theme.colors.primary}
            style={{ fontWeigth: 'bold' }}
          />
        )}
      </Flex>
    </Flex>
  )
}
