import React from 'react'
import { Text } from 'components/atoms'
import { Flex, useTheme } from '@chakra-ui/react'
import { Table } from '../Table'
import { Pie, PieChart, ResponsiveContainer, Cell } from 'recharts'

const columns = [
  {
    key: 'type',
    label: 'TIPO',
  },
  {
    key: 'total',
    label: 'QUANTIDADE',
  },
]

const colors = [
  '#1C2833',
  '#D5DBDB',
  '#A04000',
  '#283747',
  '#BFC9CA',
  '#5D6D7E',
]

export const NewUsersChart = ({ chartData, total }) => {
  const theme = useTheme()

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.15
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fontSize={theme.pxToRem(14)}
        fontFamily="Montserrat Medium"
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}% \n`}
        {chartData[index].type}
      </text>
    )
  }

  return (
    <Flex
      flexDir="column"
      p={theme.pxToRem(24)}
      bg="background"
      borderRadius={theme.pxToRem(12)}
      width={theme.pxToRem(750)}
    >
      <Text kind="semiBold">Total de usuários cadastrados: {total}</Text>
      <Flex flexDir="row" align="center" w="100%">
        {chartData.length > 0 && (
          <ResponsiveContainer height={200}>
            <PieChart>
              <Pie
                dataKey="total"
                isAnimationActive={false}
                labelLine={false}
                data={chartData}
                cy="50%"
                outerRadius={80}
                fill={theme.colors.success}
                label={renderCustomizedLabel}
              >
                {chartData
                  ?.filter((c) => c?.total > 0)
                  .map((entry, index) => (
                    <Cell fill={colors[index]} />
                  ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
        <Table data={chartData} columns={columns} bgRow="backgroundLayout" />
      </Flex>
    </Flex>
  )
}
