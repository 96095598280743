import React from 'react'
import { Box, Text } from 'components/atoms'
import { ModalAlert } from 'components/molecules'
import { useTheme, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useBarbershopStore } from 'services/stores/barbershop'
import { saveLoginData } from 'services/hooks'
import { useTranslation } from 'react-i18next'

import { api } from 'services/api/config'
import { DELETE_USER } from 'services/api/endpoints'
import { useMutation } from 'react-query'

export const DeleteAccountModal = ({ isOpen, onClose }) => {
  const { setData, user, userData } = useBarbershopStore()
  const history = useHistory()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()

  const onConfirmLogout = () => {
    saveLoginData({})
    setData({}, {})
    localStorage.clear()
    history.replace('/login')
  }

  async function deleteAccount() {
    const params = {
      userId: user?.id,
      barbershop_id: userData?.barbershop?.id || userData?.id,
    }
    const res = await api
      .put(DELETE_USER, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('delete-account', deleteAccount, {
    onSuccess: () => {
      onConfirmLogout()
      toast({
        title: t('DELETE_ACCOUNT_SUCCESS'),
        status: 'success',
        duration: 4000,
      })
    },
    onError: (error) => {
      toast({
        title: t('DELETE_ACCOUNT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
      })
    },
  })

  return (
    <Box>
      <ModalAlert
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={mutate.mutate}
        primaryButtonLabel={t('YES')}
        modalTitle={t('DELETE')}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          {t('DELETE_CONFIRMATION')}
        </Text>
      </ModalAlert>
    </Box>
  )
}
