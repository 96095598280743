import React, { useState } from 'react'
import { Button, Flex, Text, TextInput } from 'components'
import { useToast } from '@chakra-ui/react'
import { Layout } from '../Components'
import { useMutation } from 'react-query'
import { api } from 'services/api/config'
import { formatCurrency, unformatCurrency } from 'utils/formatCurrency'

export const RegisterAsaasBarbershop = () => {
  const [barbershopId, setBarbershopId] = useState(null)
  const [incomeValue, setIncomeValue] = useState(0)
  const toast = useToast()

  const mutate = useMutation(
    'create-asaas-barbershop',
    () =>
      api.post(`v3/barbershop/create-asaas-barbershop`, {
        barbershop_id: barbershopId,
        incomeValue: unformatCurrency(incomeValue),
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Barbearia Cadastrada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Erro ao cadastrar barbearia',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )
  const handleSubmit = async () => {
    mutate.mutate()
  }

  return (
    <Layout title="Cadastrar barbearia">
      <Flex align="center" justify="center" flexDir="column" w="100%" h="100%" p="60px">
        <TextInput
          noBorder
          w="28%"
          mr="3%"
          name="barbershopId"
          placeHolder="10"
          value={barbershopId}
          onChange={(e) => setBarbershopId(e.target.value)}
          label="Id da barbearia"
          padding="10px"
          h="56px"
        />
        <TextInput
          noBorder
          w="28%"
          mr="3%"
          mt="32px"
          name="incomeValue"
          mask="CURRENCY_MASK"
          placeHolder="10"
          value={incomeValue}
          onChange={(e) => setIncomeValue(formatCurrency(e.target.value))}
          label="Faturamento da barbearia"
          // padding="10px"
          paddingLeft="45px"
          h="56px"
          leftElement={
            <Text color="textGrey" kind="medium" mt="2px" width="40px">
              R$
            </Text>
          }
        />
        <Button isLoading={mutate.isLoading} onClick={handleSubmit} mt="80px">
          Cadastrar
        </Button>
      </Flex>
    </Layout>
  )
}

RegisterAsaasBarbershop.path = '/internal-adm/create-asaas-barbershop'
RegisterAsaasBarbershop.title = 'Cadastrar barbearia'
RegisterAsaasBarbershop.secured = true
