import React from 'react'
import { Circle, useTheme } from '@chakra-ui/react'
import { Text, Box, Flex } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/formatCurrency'

export const ServiceDetailsCard = ({
  item,
  index,
  color,
  totalAppointmentsThisMoth,
  isBlocked,
  ...props
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  let [mockAppointmentsThisMonth, mockServiceQuantity] = [
    Number(((Math.random() + 1) * 50).toFixed(0)),
    Number(((Math.random() + 1) * 10).toFixed(0)),
  ]

  const renderInfo = () => {
    if (isBlocked)
      return `${mockServiceQuantity} de ${mockAppointmentsThisMonth} ${t(
        'APPOINTMENTS'
      )}`

    return `${item?.quantity} de ${totalAppointmentsThisMoth} ${t(
      'APPOINTMENTS'
    )}`
  }

  const renderPercentage = () => {
    if (isBlocked)
      return `${(
        (mockServiceQuantity / mockAppointmentsThisMonth) *
        100
      ).toFixed(2)}%`

    return `${((item?.quantity / totalAppointmentsThisMoth) * 100).toFixed(2)}%`
  }
  return (
    <Flex
      key={`service_card_${index}`}
      alignItems="center"
      px={theme.pxToRem(16)}
      py={theme.pxToRem(16)}
      mb={theme.pxToRem(props?.mb || 8)}
      w="100%"
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Box
        mr={theme.pxToRem(8)}
        h={theme.pxToRem(8)}
        w={theme.pxToRem(8)}
        bgColor={color}
        borderRadius={100}
      />

      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" w="68%">
          <Circle size={theme.pxToRem(8)} />
          <Box w="100%">
            <Text fontSize={14} kind="medium" color="textLight">
              {item?.service || item?.name || item?.title}
            </Text>
            <Text color="textGrey" fontSize={12}>
              {item?.description || renderInfo()}
            </Text>
          </Box>
        </Flex>

        <Box w="32%" textAlign="end">
          <Text kind="extraBold" color={item?.value_color || 'textLight'}>
            {item?.value === 0 ? formatCurrency(0) : item?.value ? formatCurrency(item?.value) : renderPercentage()}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
