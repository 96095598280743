import * as React from 'react'

function ArrowRightSvg(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.998214 9.00002C0.998213 9.26523 1.10357 9.51959 1.29111 9.70712C1.47864 9.89466 1.733 10 1.99821 10L15.5842 10L10.2902 15.292C10.1972 15.385 10.1235 15.4954 10.0732 15.6169C10.0228 15.7383 9.99695 15.8685 9.99695 16C9.99695 16.1315 10.0228 16.2617 10.0732 16.3832C10.1235 16.5047 10.1972 16.615 10.2902 16.708C10.3832 16.801 10.4936 16.8747 10.615 16.9251C10.7365 16.9754 10.8667 17.0013 10.9982 17.0013C11.1297 17.0013 11.2599 16.9754 11.3814 16.9251C11.5029 16.8747 11.6132 16.801 11.7062 16.708L18.7062 9.70802C18.7993 9.61513 18.8732 9.50478 18.9236 9.38329C18.9741 9.2618 19 9.13155 19 9.00002C19 8.86848 18.9741 8.73824 18.9236 8.61675C18.8732 8.49526 18.7993 8.38491 18.7062 8.29202L11.7062 1.29202C11.6132 1.19904 11.5029 1.12529 11.3814 1.07497C11.2599 1.02465 11.1297 0.998756 10.9982 0.998756C10.8667 0.998756 10.7365 1.02465 10.615 1.07497C10.4936 1.12529 10.3832 1.19904 10.2902 1.29202C10.1972 1.38499 10.1235 1.49537 10.0732 1.61685C10.0228 1.73833 9.99695 1.86853 9.99695 2.00002C9.99695 2.13151 10.0228 2.26171 10.0732 2.38319C10.1235 2.50466 10.1972 2.61504 10.2902 2.70802L15.5842 8.00002L1.99821 8.00002C1.733 8.00002 1.47864 8.10537 1.29111 8.29291C1.10357 8.48045 0.998214 8.7348 0.998214 9.00002Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  )
}

export default ArrowRightSvg
