import * as React from 'react'

function SvgCalendarBasic(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.857 2.143h-2.143V.714a.714.714 0 10-1.428 0v1.429H5.714V.714a.714.714 0 10-1.428 0v1.429H2.143C.959 2.143 0 3.103 0 4.286v13.571C0 19.041.96 20 2.143 20h15.714C19.041 20 20 19.04 20 17.857V4.286c0-1.184-.96-2.143-2.143-2.143zm.714 15.714c0 .395-.32.714-.714.714H2.143a.714.714 0 01-.714-.714V8.571H18.57v9.286zm0-10.714H1.43V4.286c0-.395.32-.715.714-.715h2.143V5a.714.714 0 101.428 0V3.571h8.572V5a.714.714 0 101.428 0V3.571h2.143c.395 0 .715.32.715.715v2.857h0z"
        fill="currentColor"
        stroke="transparent"
      />
    </svg>
  )
}

export default SvgCalendarBasic
