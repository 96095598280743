import { t } from 'services/translation'
import * as Yup from 'yup'

export const data = [
  {
    id: 'due_in_days',
    name: 'due_in_days',
    type: 'number',
    noBorder: true,
    label: 'DUE_IN_DAYS',
    placeholder: t('DUE_IN_DAYS_EXAMPLE'),
  },
  {
    id: 'percentage',
    name: 'percentage',
    type: 'number',
    noBorder: true,
    label: 'PERCENTAGE',
    placeholder: t('PERCENTAGE_EXAMPLE'),
  },
]

export const validateData = (type) => {
  if (type === 'Outro') {
    return [
      {
        id: 'name',
        name: 'name',
        type: 'string',
        noBorder: true,
        label: 'PAYMENT_METHOD',
        placeholder: t('PAYMENT_METHOD_DESCRIPTION'),
      },
      {
        id: 'due_in_days',
        name: 'due_in_days',
        type: 'number',
        noBorder: true,
        label: 'DUE_IN_DAYS',
        placeholder: t('DUE_IN_DAYS_EXAMPLE'),
      },
      {
        id: 'percentage',
        name: 'percentage',
        type: 'number',
        noBorder: true,
        label: 'PERCENTAGE',
        placeholder: t('PERCENTAGE_EXAMPLE'),
      },
    ]
  } else {
    return data
  }
}

export const validation = Yup.object().shape({
  percentage: Yup.number().required(t('PERCENTAGE_REQUIRED')),
  due_in_days: Yup.number().required(t('DUE_IN_DAYS_REQUIRED')),
})

export const initial = {
  percentage: '',
  due_in_days: '',
}

export const flagPayment = [
  {
    id: 0,
    name: 'Todas as bandeiras',
  },
  {
    id: 1,
    name: 'Mastercard',
  },
  {
    id: 2,
    name: 'Visa',
  },
  {
    id: 3,
    name: 'Elo',
  },
  {
    id: 4,
    name: 'American Express',
  },
  {
    id: 5,
    name: 'Hipercard',
  },
]

export const functionPayment = [
  {
    id: 0,
    name: 'Débito',
  },
  {
    id: 1,
    name: 'Crédito',
  },
  {
    id: 2,
    name: 'Pix',
  },
  {
    id: 3,
    name: 'Dinheiro',
  },
  {
    id: 4,
    name: 'Outro',
  },
]
