import React from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

import {
  WorkingHours,
  Location,
  RedefinePassword,
  ProfileData,
  Photos,
  BlockedTime,
  AccessData,
  LogoutModal,
  ShareLink,
  Plan,
  Notifications,
} from 'components/organisms'

export const ProfileModal = ({
  route,
  isOpen,
  onClose,
  title,
  headerStyle = {},
  bodyStyle = {},
}) => {
  function mapRouteToComponent(route) {
    const map = {
      dados: <ProfileData />,
      acesso: <AccessData />,
      link: <ShareLink />,
      senha: <RedefinePassword />,
      localizacao: <Location />,
      horarios: <WorkingHours />,
      fotos: <Photos />,
      'horario-bloqueado': <BlockedTime />,
      notificacoes: <Notifications />,
      'meu-plano': <Plan />,
      sair: <LogoutModal />,
    }

    return map[route]
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
      <DrawerOverlay />
      <DrawerContent bg="background">
        <DrawerCloseButton onClick={onClose} color="textLight" zIndex="1" />

        <DrawerHeader
          fontFamily="Montserrat Bold"
          color="textLight"
          {...headerStyle}
        >
          {title}
        </DrawerHeader>

        <DrawerBody {...bodyStyle}>{mapRouteToComponent(route)}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
