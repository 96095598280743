export const mockHistoricClient = {
  appointments: [
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: {
        name: 'Matheus Barbeiro',
        profile_image_url: null,
        user: [Object],
      },
      barber_id: 3386,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 6356,
      cancelable: true,
      canceled_at: null,
      client: {
        birth_date: '1997-05-03T00:00:00.000Z',
        createdAt: '2020-12-05T13:14:38.905Z',
        id: 4578,
        identifier_doc: null,
        inactive: false,
        name: 'Matheus Franco',
        profile_image_url:
          'https://bestbarbers-images.s3.amazonaws.com/profile_image_5267_1674159440156',
        updatedAt: '2023-01-25T20:51:39.418Z',
        user: [Object],
        user_id: 5267,
      },
      client_id: 4578,
      createdAt: '2023-02-09T18:34:41.734Z',
      created_by_user_id: null,
      date: '2023-02-10T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 191995,
      net_value: null,
      past: false,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        id: 13102,
        name: 'Teste',
        price: '25',
        promotion_price: null,
        time_required: '00:40:00',
      },
      product_id: 13102,
      product_price: null,
      promotional: false,
      review: null,
      simple_date: '2023-02-10',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 25,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T18:34:41.734Z',
    },
  ],
}

export const mockObservation = {
  observations: [
    {
      barber: {
        id: 684,
        name: 'André Rangel',
        profile_image_url:
          'http://res.cloudinary.com/sevenapps-com-br/image/upload/v1624397699/profile_images/barber/15.jpg',
      },
      barber_id: 684,
      barbershop: {
        id: 1,
        name: 'Bestbarbers',
        profile_image_url:
          'https://bestbarbers-images.s3.amazonaws.com/profile_image_15_1675714427533',
      },
      barbershop_id: 1,
      client: {
        id: 20809,
        name: 'Gabriel Ávila Rangel',
        profile_image_url:
          'https://bestbarbers-images.s3.amazonaws.com/profile_image_24648_1675033128116',
      },
      client_id: 20809,
      createdAt: '2022-03-31T14:18:36.591Z',
      date: '2022-03-31T14:18:36.591Z',
      id: 10,
      observation_image_url: '',
      observation_text: 'Gosta do corte com fade na máquina 0',
      updatedAt: '2022-03-31T14:18:36.591Z',
    },
  ],
}
