import React from 'react'
import PropTypes from 'prop-types'
import { Link as ChakraLink, Text } from '@chakra-ui/react'

export const Link = ({ children, type, mainText, highlightText, ...props }) => {
  if (type === 'multiple') {
    return (
      <Text color="textLight">
        {mainText}{' '}
        <ChakraLink
          {...props}
          fontFamily="Montserrat Bold"
          color="brand.primary"
        >
          {highlightText}
        </ChakraLink>
      </Text>
    )
  }

  return (
    <ChakraLink fontFamily="Montserrat Medium" color="brand.primary" {...props}>
      {children}
    </ChakraLink>
  )
}

ChakraLink.defaultProps = {
  type: 'standard',
}

ChakraLink.propTypes = {
  type: PropTypes.oneOf(['standard', 'multiple']),
  highlightText: PropTypes.string,
  mainText: PropTypes.string,
}
