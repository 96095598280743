import { Link } from 'react-router-dom'
import { Flex, Spacer } from '@chakra-ui/react'
import { Text } from 'components/atoms/Text'
import { Icon } from 'components/atoms/Icon'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint } from 'services/hooks'

export const ProfileMenuItem = ({
  title,
  to,
  active,
  icon,
  size = 30,
  onSelect,
}) => {
  const { isDesktop } = useBreakpoint()

  return (
    <Flex
      as={Link}
      alignItems="center"
      justifyContent="center"
      px="4"
      py="2"
      w={isDesktop ? pxToRem(335) : 'full'}
      background="cardBackground"
      borderWidth={1}
      borderColor={active ? 'primary' : 'modalBorder'}
      borderRadius={pxToRem(12)}
      to={to}
      onClick={onSelect}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        w={pxToRem(40)}
        h={pxToRem(40)}
        p={4}
        bg="whiteTransparent.100"
        mr="4"
        borderRadius={pxToRem(8)}
      >
        <Icon name={icon} size={20} />
      </Flex>
      <Text kind="semiBold" fontSize={pxToRem(16)}>
        {title}
      </Text>
      <Spacer />
      <Icon
        name="ChevronRight"
        color={active ? 'primary' : 'textGrey'}
        size={20}
      />
    </Flex>
  )
}
