import React, { useState, useMemo } from 'react'
import { Box, Button, Flex, Text } from 'components/atoms'
import { GooglePlacesInput, Form } from 'components/molecules'
import { dataLocation, initialLocation, validationLocation } from './settings'
import { useTheme, useToast } from '@chakra-ui/react'
import { getStyledSelectBox, getStyledOption } from 'utils/address'
import { colors } from 'styles/colors'
import { useTranslation } from 'react-i18next'

import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { ADDRESS_BARBERSHOP } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'

export const Location = () => {
  const [values, getValues] = useState({
    params: initialLocation,
    isValid: false,
  })
  const { pxToRem } = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()

  async function getLocation() {
    const res = await api
      .get(ADDRESS_BARBERSHOP(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-address-barbershop', getLocation, {
    onSuccess: (value) => {
      if (value) {
        getValues({
          params: value,
          isValid: false,
        })
      }
    },
  })

  async function changeLocation() {
    const res = await api
      .put(
        ADDRESS_BARBERSHOP(userData?.barbershop?.id || userData?.id),
        values?.params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  const mutate = useMutation('change-location', () => changeLocation(), {
    onSuccess: (data) => {
      toast({
        title: t('LOCATION_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('LOCATION_TOAST_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const continueDisabled = useMemo(() => {
    if (
      !values?.params?.zip_code ||
      !values?.params?.state ||
      !values?.params?.city ||
      !values?.params?.street ||
      !values?.params?.street_number ||
      !values?.params?.neighborhood ||
      !values?.params?.country ||
      !values?.params?.latitude ||
      !values?.params?.longitude
    ) {
      return true
    }
    return false
  }, [values])

  return (
    <Box width="100%" color={colors.white}>
      <Text fontSize={pxToRem(18)} mb={pxToRem(20)} kind="bold">
        {t('LOCATION')}
      </Text>

      <Flex autocomplete="off" flexDir="column" w="100%" h="100%">
        <GooglePlacesInput
          {...{ getStyledSelectBox, getStyledOption }}
          onSelectAddress={(address) =>
            getValues({ params: address, isValid: values?.isValid })
          }
        />

        <Flex flexDirection={['column']} mb={pxToRem(40)} mt={pxToRem(16)}>
          <Form
            mb={36}
            validationSchema={validationLocation}
            initialValues={values?.params}
            data={dataLocation}
            getValues={getValues}
          />
        </Flex>
      </Flex>

      <Button
        isLoading={mutate.isLoading}
        disabled={!values?.isValid}
        onClick={() => mutate.mutate()}
        w="100%"
        mt={pxToRem(40)}
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Box>
  )
}
