import React from 'react'
import { Center, Spinner, useTheme } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Avatar, Box, Flex, Text } from 'components/atoms'

const defaultValues = {
  emptyListMessage: 'EMPTY_LIST_MESSAGE',
  emptyValueMessage: '',
  data: [],
}

export const HistoryList = ({
  data,
  loading,
  emptyListMessage = defaultValues.emptyListMessage,
  onSelect = () => {},
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const handleBgColor = (appointment) => {
    if (appointment && appointment.status === 'finished') {
      return 'success'
    }
    if (appointment && appointment.past) {
      return 'baseRed'
    }
    if (appointment) {
      return 'primary'
    }
    return 'transparent'
  }

  return (
    <>
      {!!data.length &&
        data.map((item, index) => (
          <Flex
            key={`data_${index}`}
            width="full"
            height={theme.pxToRem(108)}
            paddingX={theme.pxToRem(20)}
            paddingY={theme.pxToRem(12)}
            marginBottom={theme.pxToRem(16)}
            background="cardBackground"
            borderRadius={theme.pxToRem(8)}
            borderWidth={1}
            borderColor="modalBorder"
            pos="relative"
            onClick={() => onSelect(item)}
          >
            <Box
              h={theme.pxToRem(108)}
              w={theme.pxToRem(8)}
              pos="absolute"
              top="0"
              left="0"
              borderLeftRadius={theme.pxToRem(8)}
              background={handleBgColor(item)}
            />

            <Flex width="full" height="full">
              <Box marginRight={theme.pxToRem(16)}>
                <Avatar src={item?.client?.profile_image_url} size="medium" />
              </Box>

              <Box width="full">
                <Flex
                  flexDir="row"
                  alignItems="baseline"
                  justifyContent="flex-start"
                >
                  <Text
                    kind="bold"
                    fontColor="textLight"
                    fontSize={theme.pxToRem(12)}
                  >
                    {item?.start_hour.substring(0, 5)} -{' '}
                    {item?.finish_hour.substring(0, 5)}
                  </Text>
                </Flex>

                <Box marginBottom={theme.pxToRem(8)}>
                  <Text kind="bold" fontColor="textLight">
                    {item?.client?.name}
                  </Text>
                </Box>

                <Box width="full">
                  <Flex width="100%">
                    <Box marginRight={theme.pxToRem(24)}>
                      <Text
                        kind="medium"
                        fontColor="textLight"
                        fontSize={theme.pxToRem(10)}
                        opacity={0.6}
                      >
                        {t('BARBER')}
                      </Text>
                      <Text
                        kind="bold"
                        fontColor="textLight"
                        fontSize={theme.pxToRem(12)}
                      >
                        {item?.barber?.name}
                      </Text>
                    </Box>

                    <Box>
                      <Text
                        kind="medium"
                        fontColor="textLight"
                        fontSize={theme.pxToRem(10)}
                        opacity={0.6}
                      >
                        {t('_SERVICE')}
                      </Text>
                      <Text
                        kind="bold"
                        fontColor="textLight"
                        fontSize={theme.pxToRem(12)}
                      >
                        {item?.product?.map((i) => i?.name).join(', ')}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Flex>
        ))}

      {(!data || !data.length) && !loading && emptyListMessage !== 'none' && (
        <Center width="100%" height={theme.pxToRem(450)}>
          <Text color="textLight">{t(emptyListMessage)}</Text>
        </Center>
      )}
      {loading && (
        <Center height={theme.pxToRem(450)}>
          <Spinner color="white" />
        </Center>
      )}
    </>
  )
}
