import * as React from 'react'

function SvgFinances(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.691 11.248h-1.38c-.727 0-1.317-.59-1.317-1.317 0-.726.59-1.316 1.316-1.316h2.762a.755.755 0 000-1.51h-1.317V5.79a.755.755 0 10-1.509 0v1.317a2.829 2.829 0 00-2.761 2.825 2.829 2.829 0 002.825 2.826h1.381c.726 0 1.317.59 1.317 1.316 0 .726-.591 1.317-1.317 1.317H9.93a.755.755 0 100 1.509h1.316v1.317a.755.755 0 101.51 0v-1.318a2.829 2.829 0 002.76-2.825 2.829 2.829 0 00-2.825-2.825z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.22}
      />
      <path
        d="M19.856 4.144A11.038 11.038 0 0012 .89a11.038 11.038 0 00-7.856 3.254l.078.078-.078-.078A11.038 11.038 0 00.89 12c0 2.967 1.156 5.758 3.254 7.856A11.038 11.038 0 0012 23.11c2.967 0 5.758-1.156 7.856-3.254A11.038 11.038 0 0023.11 12c0-2.967-1.156-5.758-3.254-7.856zM12 21.601c-5.294 0-9.6-4.307-9.6-9.601 0-5.294 4.306-9.6 9.6-9.6s9.6 4.306 9.6 9.6-4.306 9.6-9.6 9.6z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.22}
      />
    </svg>
  )
}

export default SvgFinances
