import { api } from 'services/api/config'

export const useBarbershopFinance = () => {
  const getExtract = async ({ barbershop_id, start_date, end_date, date_filter }) => {
    const response = await api({
      url: `v3/barbershop/finances/extract-ref/${barbershop_id}`,
      params: {
        start_date,
        end_date,
        date_filter,
      },
    })
    return response.data
  }

  const getDashboard = async ({ barbershop_id, start_date, end_date }) => {
    const response = await api({
      url: `v3/barbershop/finances/dashboard-ref/${barbershop_id}`,
      params: {
        start_date,
        end_date,
      },
    })
    return response.data
  }

  return {
    getExtract,
    getDashboard,
  }
}
