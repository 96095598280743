import { onboarding, login, agendamento, bestnews1, bestnews2, bestnews3, financeiro, administrativo, AgendarClinteSemCadastro, EncaixeDeCliente, CadastrarDebtoCreditoCliente, AgendamentoComMultiplosServicos, AgendaInteligente, ConhecendoDashboard, ConhecendoExtrato, AdicionandoColaborador, CadastroNovaSenhaColaborador, EditarColaborador, EditarPermissoesColaborador, PermitirDesabilitarServicoBarbeiro, EditarComissoesProdBarbeiro, EditComissaoTempoServBarbeiro, CriandoNovoServico, AdicionarUnidades, AlternarEntreMultiplasUnidades, DesabilitarNotifAgendaClienteEBarbeiro, LinkAgendamentoBarbearia, BloqueioAgendaRecorrente, Login, PrimeirosPassos } from "assets/img/releases"
import { AgendarClienteJaCadastrado } from "assets/img/releases"
export const releases = [
    {
      id: 0,
      category: 'Autenticação e onboarding',
      platform: 'Web (computador)',
      image: PrimeirosPassos,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/sistema-de-gerenciamento-web/primeiros-passos-onboarding',
      title: 'Primeiros Passos (Onboarding)',
      desc: 'O primeiro treinamento não poderia ser diferente e explica como você deve iniciar a sua jornada de sucesso com o BestBarbers.',
      icon: 'Person',
      iconPlatform: 'Computer'
    }, 
    {
      id: 1,
      category: 'Autenticação e onboarding',
      platform: 'Web (computador)',
      image: Login,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/sistema-de-gerenciamento-web/login',
      title: 'Login',
      icon: 'Person',
      desc: 'Veja como realizar o seu login.',
      iconPlatform: 'Computer'
    }, 
    {
      id: 2,
      category: 'Agenda',
      platform: 'Web (computador)',
      image: AgendarClienteJaCadastrado,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/horarios/agenda/agendamento-de-cliente-cadastrado',
      title: 'Agendamento de cliente cadastrado',
      icon: 'Calendar',
      iconPlatform: 'Computer',
      desc: 'Para agendar um cliente já cadastrado no sistema, basta seguir o tutorial:'
    },
    {
      id: 3,
      category: 'Agenda',
      platform: 'Web (computador)',
      image: AgendarClinteSemCadastro,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/horarios/agenda/agendamento-de-cliente-sem-cadastro',
      title: 'Agendamento de cliente sem cadastro',
      icon: 'Calendar',
      iconPlatform: 'Computer',
      desc: 'Para agendar um cliente sem cadastrado no sistema, basta seguir o tutorial:'
    },
    {
      id: 4,
      category: 'Agenda',
      platform: 'Web (computador)',
      image: EncaixeDeCliente,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/horarios/agenda/encaixe-de-cliente-agendamento-em-cima-da-hora-ou-em-horario-bloqueado',
      title: 'Encaixe de cliente',
      icon: 'Calendar',
      iconPlatform: 'Computer',
      desc: 'O Agendamento do Cliente por Encaixe deve ser realizado quando o cliente executa o serviço sem o agendamento prévio no app ou sistema web. Isso...'
    },
    {
      id: 5,
      category: 'Agenda',
      platform: 'Web (computador)',
      image: CadastrarDebtoCreditoCliente,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/horarios/agenda/new-cadastrando-um-debito-credito-no-historico-do-cliente-para-cobranca-abatimento-futura',
      title: 'Cadastrando um débito/crédito no histórico do cliente',
      icon: 'Calendar',
      iconPlatform: 'Computer',
      desc: 'Durante a rotina da barbearia é muito comum que o cliente pague pelos serviços com uma nota de valor maior e peça para que o troco seja registrado como...'
    },
    {
      id: 6,
      category: 'Agenda',
      platform: 'Web (computador)',
      image: AgendamentoComMultiplosServicos,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/horarios/agenda/new-agendamento-com-multiplos-servicos',
      title: 'Agendamento com múltiplos serviços',
      icon: 'Calendar',
      iconPlatform: 'Computer',
      desc: 'Agendamento com múltiplos serviços.'
    },
    {
      id: 7,
      category: 'Agenda',
      platform: 'Web (computador)',
      image: AgendaInteligente,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/horarios/agenda/new-agenda-inteligente',
      title: 'Agenda inteligente',
      icon: 'Calendar',
      iconPlatform: 'Computer',
      desc: 'Com a agenda inteligente, otimizamos os seus horários e diminuímos os "buracos" causados entre os agendamentos, possibilitando assim o maior fluxo de...'
    },
    {
      id: 8,
      category: 'Financeiro',
      platform: 'Web (computador)',
      image: ConhecendoDashboard,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/financeiro/conhecendo-o-ambiente-dashboard',
      title: 'Conhecendo o ambiente "Dashboard"',
      icon: 'Money',
      iconPlatform: 'Computer',
      desc: 'Conhecendo o ambiente "Dashboard"'
    },
    {
      id: 9,
      category: 'Financeiro',
      platform: 'Web (computador)',
      image: ConhecendoExtrato,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/financeiro/extrato',
      title: 'Conhecendo o ambiente "Extrato"',
      icon: 'Money',
      iconPlatform: 'Computer',
      desc: 'Conhecendo o ambiente "Extrato"'
    },
    {
      id: 10,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: AdicionandoColaborador,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/financeiro/extrato',
      title: '+ Adicionar Colaborador',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Para adicionar um novo colaborador basta seguir os passos do tutorial abaixo. O processo é o mesmo para a criação de Barbeiro, Recepcionista, Gerente, Caixa e Financeiro...'
    },
    {
      id: 11,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: CadastroNovaSenhaColaborador,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/equipe/cadastro-de-nova-senha',
      title: 'Cadastro de Nova Senha do colaborador',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Cadastro de Nova Senha do colaborador.'
    },
    {
      id: 12,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: EditarColaborador,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/equipe/editar-colaborador',
      title: 'Editar colaborador',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Caso seja necessário alterar algum dado do colaborador basta seguir o tutorial abaixo. O processo...'
    },
    {
      id: 13,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: EditarPermissoesColaborador,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/equipe/editar-permissoes-do-colaborador',
      title: 'Editar permissões do colaborador',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Cada cargo possui um nível de permissão de acesso pré-configurado. Todas essas permissões podem ser modificadas conforme tutorial abaixo...'
    },
    {
      id: 14,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: PermitirDesabilitarServicoBarbeiro,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/equipe/permitir-desabilitar-servico-para-barbeiro',
      title: 'Permitir/Desabilitar serviço para barbeiro',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Alguns serviços da sua barbearia podem ser exclusivos de alguns barbeiros como é o caso de um corte exclusivo com o fundador ou serviços químicos de barbeiros...'
    },
    {
      id: 15,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: EditarComissoesProdBarbeiro,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/equipe/editar-comissao-de-produto-para-barbeiro',
      title: 'Editar comissão de produto para barbeiro',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Ao cadastrar um comissionamento de produto na etapa de onboarding (configuração da conta), é solicitado apenas uma taxa que será...'
    },
    {
      id: 16,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: EditComissaoTempoServBarbeiro,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/equipe/editando-o-valor-comissao-e-tempo-de-servico-por-barbeiro',
      title: 'Editando o valor, comissão e tempo de serviço por barbeiro',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Editando o valor, comissão e tempo de serviço por barbeiro.'
    },
    {
      id: 17,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: CriandoNovoServico,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/servicos/criando-um-novo-servico',
      title: 'Criando um novo serviço',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Criando um novo serviço.'
    },
    {
      id: 19,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: AdicionarUnidades,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/unidades/+adicionar-unidades',
      title: '+ Adicionar Unidades',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: '+ Adicionar Unidades.'
    },
    {
      id: 21,
      category: 'Administrativo',
      platform: 'Web (computador)',
      image: AlternarEntreMultiplasUnidades,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/unidades/alternando-entre-as-multiplas-unidades-da-barbearia',
      title: 'Alternando entre as múltiplas unidades da barbearia',
      icon: 'Gears',
      iconPlatform: 'Computer',
      desc: 'Alternando entre as múltiplas unidades da barbearia.'
    },
    {
      id: 22,
      category: 'Perfil',
      platform: 'Web (computador)',
      image: DesabilitarNotifAgendaClienteEBarbeiro,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/perfil/desabilitar-notificacoes-de-agendamento-para-cliente-e-barbeiro',
      title: 'Desabilitar notificações de agendamento para cliente e barbeiro',
      icon: 'Person',
      iconPlatform: 'Computer',
      desc: 'Desabilitar notificações de agendamento para cliente e barbeiro.'
    },
    {
      id: 23,
      category: 'Perfil',
      platform: 'Web (computador)',
      image: LinkAgendamentoBarbearia,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/perfil/desabilitar-notificacoes-de-agendamento-para-cliente-e-barbeiro',
      title: 'Link de agendamento da barbearia',
      icon: 'Person',
      iconPlatform: 'Computer',
      desc: 'O link é uma forma fácil de você compartilhar com seus clientes o perfil da sua barbearia e eles conseguirem fazer o agendamento com sua barbearia...'
    },
    {
      id: 24,
      category: 'Perfil',
      platform: 'Web (computador)',
      image: BloqueioAgendaRecorrente,
      link: 'https://bestbarbers.gitbook.io/sistema-de-gerenciamento-web/adminsitrativo/perfil/bloqueio-de-agenda-recorrente',
      title: 'Bloqueio de agenda recorrente',
      icon: 'Person',
      iconPlatform: 'Computer',
      desc: 'Bloqueio de agenda recorrente.'
    },
  ]

  export const categorys = [
    {
 name: 'Autenticação e onboarding',
 value: 'Autenticação e onboarding'
    },
    {
      name: 'Agenda',
      value: 'Agenda'
    },
    {
      name: 'Financeiro',
      value: 'Financeiro'
    },
    {
      name: 'Administrativo',
      value: 'Administrativo'
    },
    {
      name: 'Perfil',
      value: 'Perfil'
    },
    
  ]

  export const lastUpdateds = [
    {
      id: 0,
      link: 'https://www.instagram.com/bestbarbers.app/',
      image: bestnews1,
    },
    {
      id: 1,
      link: 'https://www.instagram.com/bestbarbers.app/',
      image: bestnews2,
    },
    {
      id: 2,
      link: 'https://www.instagram.com/bestbarbers.app/',
      image: bestnews3,
    },
  ]