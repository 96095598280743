import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthLayout } from 'components-v2/organisms/AuthLayout'
import { Form } from 'components'
import { t } from 'services/translation'
import { useResetPassword } from 'services/hooks'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import {
  form_data,
  form_initial_values,
  form_validation,
} from './new_password_form_settings'

export const ResetPasswordNewPassword = (props) => {
  const [values, getValues] = useState({
    params: form_initial_values,
    isValid: false,
  })

  const history = useHistory()
  const toast = useToast()

  const { updatePassword } = useResetPassword()

  const mutation = useMutation(
    'reset-password-update-password',
    () =>
      updatePassword({
        user_id: history?.location?.state?.data?.user?.id,
        token: values?.params?.token,
        new_password: values?.params?.new_password,
        confirm_password: values?.params?.confirm_password,
      }),
    {
      onSuccess: (data) => {
        toast({
          title: t('PASSWORD_CHANGED'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        history.push('/login')
      },
      onError: (error) => {
        toast({
          title: t('PASSWORD_NOT_CHANGED'),
          description:
            error?.response?.data?.error || 'Por favor, tente novamente',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <AuthLayout
      title="Redefinir senha"
      subtitle="Enviamos a chave de acesso no seu número de whatsapp cadastrado. Informe abaixo a chave e a nova senha desejada"
      button={{
        text: 'Confirmar',
        onClick: () => mutation.mutate(),
        disabled: !values?.isValid || values?.params === form_initial_values,
        isLoading: mutation.isLoading,
      }}
      link={{
        text: 'Cancelar',
        onClick: () => history.push('/login'),
      }}
    >
      <Form
        w="100%"
        mb={32}
        data={form_data}
        initialValues={form_initial_values}
        validationSchema={form_validation}
        getValues={getValues}
      />
    </AuthLayout>
  )
}
ResetPasswordNewPassword.path = '/reset-password/new-password'
ResetPasswordNewPassword.title = 'Esqueceu senha'
ResetPasswordNewPassword.secured = false
