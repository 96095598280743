import { Flex } from "@chakra-ui/react"
import { Avatar, Text } from "components/atoms"
import { formatPrice } from "utils"

export const cards = (data) => {
    return [
      {
        label: 'Por cliente',
        description: 'Ticket médio',
        color: 'white',
        notCurrency: false,
        value: data?.average_ticket_clients || 0,
      },
      {
        label: 'Serviços',
        description: 'Ticket médio',
        color: 'white',
        notCurrency: false,
        value: data?.average_ticket_services || 0,
      },
      {
        label: 'Produtos',
        description: 'Ticket médio',
        color: 'white',
        notCurrency: false,
        value: data?.average_ticket_products || 0,
      },
    ]
  }

  export const columns = [
    {
      key: 'barber',
      label: 'COLLABORATOR',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Avatar
              size="medium"
              src={item?.image}
              mr='16px'
            />
            <Text>{item?.name}</Text>
          </Flex>
        )
      },
    },
    {
      key: 'total_sales',
      label: 'TOTAL_SALES_TABLE',
      render: (item) => <Text>{formatPrice(item?.total_value)}</Text>,
    },
    {
      key: 'shopping',
      label: 'TICKET MÉDIO POR AGENDAMENTO',
      render: (item) => <Text>{formatPrice(item?.quantity === 0 ? 0 : item?.total_value / item?.quantity)}</Text>,
    },
    {
      key: 'worth',
      label: 'TOTAL EM SERVIÇOS',
      render: (item) => <Text>{formatPrice(item?.services_value)}</Text>,
    },
    {
      key: 'sales_services',
      label: 'TICKET MÉDIO DE SERVIÇOS',
      render: (item) => <Text>{formatPrice(item?.services_quantity === 0 ? 0 : item?.services_value / item?.services_quantity)}</Text>,
    },
    {
      key: 'worth',
      label: 'TOTAL EM PRODUTOS',
      render: (item) => <Text>{formatPrice(item?.products_value)}</Text>,
    },
    {
      key: 'sales_services',
      label: 'TICKET MÉDIO DE PRODUTOS',
      render: (item) => <Text>{formatPrice(item?.products_quantity === 0 ? 0 :item?.products_value / item?.products_quantity)}</Text>,
    },
  ]