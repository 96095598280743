import React, { useState } from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Flex, ModalMenu, Button } from 'components'
import AppointmentSelectOptions from './SelectOptions'
import AppointmentSelectService from './SelectService'
import AppointmentSelectClient from './SelectClient'
import AppointmentSelectBarber from './SelectBarber'
import AppointmentSelectDate from './SelectDate'
import EditAppointment from './EditAppointment'
import { APPOINTMENT_STEPS } from './settings'
import { theme } from 'styles'
import { useAppointmentStore, useSingleSaleStore } from 'services/stores'
import { usePremiumBlock } from 'services/hooks'
import { usePermissionCollaborator } from 'services/hooks'
import { useBreakpoint } from 'services/hooks'
import { ModalButtonBackground } from 'components-v2/atoms'

export const RecurrentAppointmentsModal = ({
  isModalOpen,
  onClose,
  clickEmpty,
  setClickEmpty,
  goBack,
  isEditing,
  selectedAppointment,
  withoutTabs,
}) => {
  const { t } = useTranslation()
  const { clearState } = useSingleSaleStore()
  const { clearState: clearStateSingleSale } = useAppointmentStore()
  const isBlocked = usePremiumBlock()
  const { isMobile } = useBreakpoint()
  const appointment = usePermissionCollaborator('appointment')
  const singleProduct = usePermissionCollaborator('single_product')

  const [tabIndex, setTabIndex] = useState(0)
  const [schedulingStep, setSchedulingStep] = useState(
    APPOINTMENT_STEPS.initialStep
  )

  React.useEffect(() => {
    isEditing && setSchedulingStep(APPOINTMENT_STEPS.editAppointment)
  }, [isEditing])

  const handleBackNavigation = () => {
    if (!isEditing) {
      setSchedulingStep(APPOINTMENT_STEPS.initialStep)
      return
    }
    if (schedulingStep === APPOINTMENT_STEPS.editAppointment) {
      goBack()
      return
    }
    setSchedulingStep(APPOINTMENT_STEPS.editAppointment)
  }

  const displayAppointmentSteps = () => {
    switch (schedulingStep) {
      case APPOINTMENT_STEPS.initialStep:
        return (
          <AppointmentSelectOptions
            setStep={setSchedulingStep}
            onClose={onClose}
            setClickEmpty={setClickEmpty}
            isEditing={isEditing}
          />
        )

      case APPOINTMENT_STEPS.editAppointment:
        return (
          <EditAppointment
            setStep={setSchedulingStep}
            goBack={goBack}
            handleBack={handleBackNavigation}
            selectedAppointment={selectedAppointment}
            isEditing={isEditing}
          />
        )

      case APPOINTMENT_STEPS.selectService:
        return (
          <AppointmentSelectService
            clickEmpty={clickEmpty}
            goBack={handleBackNavigation}
            isEditing={isEditing}
          />
        )

      case APPOINTMENT_STEPS.selectCustomer:
        return (
          <AppointmentSelectClient
            goBack={handleBackNavigation}
            isEditing={isEditing}
          />
        )

      case APPOINTMENT_STEPS.selectBarber:
        return (
          <AppointmentSelectBarber
            goBack={handleBackNavigation}
            clickEmpty={clickEmpty}
            isEditing={isEditing}
          />
        )

      case APPOINTMENT_STEPS.selectDateAndTime:
        return (
          <AppointmentSelectDate
            goBack={handleBackNavigation}
            isEditing={isEditing}
          />
        )

      default:
        return <AppointmentSelectOptions />
    }
  }

  const clearStates = () => {
    clearState()
    clearStateSingleSale()
  }

  const onCloseModal = () => {
    clearStates()
    onClose()
    setTabIndex(0)
  }

  if (withoutTabs) {
    return (
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onCloseModal}
        title={t(
          schedulingStep === APPOINTMENT_STEPS.initialStep
            ? 'Novo agendamento Recorrente'
            : schedulingStep
        )}
        isNavigating={schedulingStep !== APPOINTMENT_STEPS.initialStep}
        handleBackNavigation={handleBackNavigation}
        size="lg"
        // bodyStyle={isBlocked && tabIndex === 1 && { padding: 0 }}
      >
        {displayAppointmentSteps()}
        {schedulingStep === APPOINTMENT_STEPS.selectService && (
          <>
            <ModalButtonBackground>
              <Button size="block" onClick={handleBackNavigation}>
                {t('CONFIRM')}
              </Button>
            </ModalButtonBackground>
          </>
        )}
      </ModalMenu>
    )
  }

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={onCloseModal}
      title={t(
        schedulingStep === APPOINTMENT_STEPS.initialStep
          ? 'Novo agendamento Recorrente'
          : schedulingStep
      )}
      isNavigating={schedulingStep !== APPOINTMENT_STEPS.initialStep}
      handleBackNavigation={handleBackNavigation}
      bodyStyle={isBlocked && tabIndex === 1 && { padding: 0 }}
    >
      <Tabs
        onChange={(index) => {
          setTabIndex(index)
        }}
      >
        {schedulingStep === APPOINTMENT_STEPS.initialStep && (
          <TabList
            color="textGrey"
            borderColor="modalBorder"
            borderBottomWidth={1}
            mb={
              tabIndex === 1
                ? isBlocked
                  ? 0
                  : theme.pxToRem(24)
                : theme.pxToRem(24)
            }
          >
            {appointment === 'edit' && (
              <Tab
                fontSize={14}
                fontWeight={500}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                {t('NEW_APPOINTMENT')}
              </Tab>
            )}
            {singleProduct === 'edit' && (
              <Tab
                fontSize={14}
                fontWeight={500}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                {t('SINGLE_PRODUCT_SELL')}
              </Tab>
            )}
            <Tab
              fontSize={14}
              fontWeight={500}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              {t('BLOCKED_TIMES')}
            </Tab>
          </TabList>
        )}

        <TabPanels pb={isMobile ? 100 : 1}>
          <TabPanel p={0}>{displayAppointmentSteps()}</TabPanel>
        </TabPanels>
      </Tabs>
    </ModalMenu>
  )
}
