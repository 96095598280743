import { format } from 'date-fns'
import { useFetch } from './use-fetch'
import * as e from 'services/api/endpoints'

export const useBarbershopSubscription = () => {
  const { onFetch } = useFetch()

  async function getIuguNewInvoice(props) {
    const response = await onFetch({
      ...props,
      url: e.BARBERSHOP_SUBSCRIPTION_GET_NEW_INVOICE(props?.iugu_subscription_id),
      method: 'post',
    })
    return response
  }

  async function getSubscriptionDetails(props) {
    if (props?.client_id) {
      const response = await onFetch({
        ...props,
        url: e.GET_SUBSCRIPTION_CLIENT(props?.barbershop_id, props?.client_id),
        method: 'get',
      })
      return response
    }
  }

  async function deleteSubscription(props) {
    const response = await onFetch({
      ...props,
      url: e.DELETE_SIGNATURE(props?.id),
      method: 'delete',
      message: {
        success: 'CANCEL_SIGNATURE_SUCCESS',
        error: 'CANCEL_SIGNATURE_ERROR',
      },
    })
    return response
  }

  async function changeSignature(props) {
    const response = await onFetch({
      ...props,
      url: e.UPDATE_SIGNATURE(props?.barbershop_id, props?.client_id),
      method: 'post',
      message: {
        success: 'CHANGE_SIGNATURE_SUCCESS',
        error: 'CHANGE_SIGNATURE_ERROR',
      },
    })
    return response
  }

  async function reactivateSignature(props) {
    const response = await onFetch({
      ...props,
      url: e.UPDATE_SIGNATURE(props?.barbershop_id, props?.client_id),
      method: 'put',
      message: {
        success: 'REACTIVE_SIGNATURE_SUCCESS',
        error: 'REACTIVE_SIGNATURE_ERROR',
      },
    })
    return response
  }

  async function getSignatureResume(props) {
    const response = await onFetch({
      ...props,
      url: e.GET_SIGNATURE_FINANCES({
        id: props?.queryKey[2],
        start_date: format(props?.queryKey[1], 'yyyy-MM-dd'),
        end_date: format(props?.queryKey[1], 'yyyy-MM-dd'),
      }),
      method: 'post',
      message: {
        error: 'ERROR_SIGNATURE',
      },
    })
    return response
  }

  async function getSignatureGlobalResume(props) {
    const response = await onFetch({
      ...props,
      url: e.GET_SIGNATURE_RESUME_FINANCES({
        id: props?.queryKey[3],
        start_date: props?.queryKey[1],
        end_date: props?.queryKey[2],
        type: props?.queryKey[4],
      }),
      method: 'post',
      message: {
        error: 'ERROR_SIGNATURE',
      },
    })
    return response
  }
  return {
    getSignatureGlobalResume,
    getSubscriptionDetails,
    getIuguNewInvoice,
    deleteSubscription,
    changeSignature,
    reactivateSignature,
    getSignatureResume,
  }
}
