import React, { useMemo } from 'react'
import {
  MonthAppointmentsChart,
  Text,
  ServiceDetailsCard,
  AppointmentsResumeCard,
  AppointmentHomeCard,
  FinanceResumeCard,
  PieChart,
  Icon,
  Button,
} from 'components'
import { Flex, Box, useTheme } from '@chakra-ui/react'
import { formatHomeChart } from 'utils/chartData'
import { useTranslation } from 'react-i18next'
import { resumeMobile } from './settings'
import { useHistory } from 'react-router-dom'

export const RenderMobile = ({
  data,
  startDate,
  endDate,
  appointments,
  setSelectedAppointment,
  setDetailsModalVisible,
  isBlocked,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const dataPie = () => {
    const newArr = data?.services_info?.data?.sort((a, b) => b.quantity - a.quantity)
    let label = newArr?.map((item) => item.service)
    let d = newArr?.map((item) => item.quantity)

    const values = {
      label,
      data: d,
    }
    return values
  }

  const appointmentsArr = () => {
    if (appointments) {
      const newArr = appointments.filter((item) => !item?.past)
      if (newArr.length > 2) {
        const slice = newArr.slice(0, 2)
        return slice
      }
      return newArr
    }
    return []
  }
  const cellColors = [...theme.colors.pieChart]

  return (
    <Flex
      flexDir="column"
      w="100%"
      padding={isBlocked && theme.pxToRem(16)}
      pointerEvents={isBlocked && 'none'}
    >
      <Flex w="100%" flexDir="column" flexWrap="wrap" h={theme.pxToRem(272)}>
        {resumeMobile(data)?.map((item) => (
          <FinanceResumeCard {...item} percentage="47.5%" h={120} />
        ))}
      </Flex>
      <AppointmentsResumeCard full item={data} info={'lancamentos'} />
      <Text kind="extraBold" fontSize={14}>
        {t('NEXT_APPOINTMENTS')}
      </Text>
      {appointmentsArr()?.length > 0 ? (
        <Flex w="100%" justify="space-between" mt={theme.pxToRem(16)}>
          {appointmentsArr()?.map((item) => (
            <AppointmentHomeCard
              h={135}
              data={item}
              onClick={() => {
                setSelectedAppointment(item)
                setDetailsModalVisible(true)
              }}
            />
          ))}
        </Flex>
      ) : (
        <Flex flexDir="column" align="center" mt={theme.pxToRem(16)}>
          <Icon name="Calendar" size={30} />
          <Text>{t('NO_APPOINTMENT_TODAY')}</Text>
        </Flex>
      )}
      <Button
        onClick={() => history.push('agenda')}
        mt={theme.pxToRem(16)}
        mb={theme.pxToRem(16)}
        kind="outline"
        size="block"
      >
        {t('SEE_APPOINTMENTS')}
      </Button>
      {data?.services_info?.data?.length !== 0 ? (
        <PieChart
          h="105%"
          w="100%"
          data={dataPie()}
          dataLabels
          mb={theme.pxToRem(12)}
          title={t('SERVICES_DETAILS')}
        />
      ) : (
        <Flex flexDir="column" align="center">
          <Icon name="Chart" size={50} />
          <Text>{t('NO_DATA_RESUME')}</Text>
        </Flex>
      )}
      {!!data?.services_info &&
        data?.services_info?.data
          ?.sort((a, b) => b.quantity - a.quantity)
          ?.slice(0, 3)
          ?.map((item, index) => (
            <ServiceDetailsCard
              item={item}
              index={index}
              color={cellColors[index % cellColors?.length]}
              totalAppointmentsThisMoth={data?.total_entries}
            />
          ))}
    </Flex>
  )
}
