import * as React from 'react'

function SvgErrorAlert(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33 63c16.569 0 30-13.431 30-30C63 16.431 49.569 3 33 3 16.431 3 3 16.431 3 33c0 16.569 13.431 30 30 30zM33 19.666V33M33 46.334h.033"
        stroke="currentColor"
        strokeWidth={4.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgErrorAlert
