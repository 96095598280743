import { Text } from "components";
import { WEEK_DAYS, formatPrice } from "utils";
import { t } from "services/translation";

export const statuses = [
  {
    name: 'Inativo',
    value: 'INACTIVE',
  },
  {
    name: 'Cancelado',
    value: 'CANCELLED'
  },
  {
    name: 'Ativo',
    value: 'ACTIVE',
  },
]

export const serviceColumns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'price',
    label: 'VALUE_TABLE',
    render: (item) => <Text>{formatPrice(item.value)}</Text>,
  },
  {
    key: 'days',
    label: 'DIAS HABILITADOS',
    render: (item) => <Text>{!item?.days ? 'Todos os dias' : item?.days?.map(day => t(WEEK_DAYS.find(i => i.id === day)?.day)).join(', ')}</Text>,
  },
  {
    key: 'services',
    label: 'SERVIÇOS',
    render: (item) => <Text maxW='190px'
    overflow="hidden"
    textOverflow="ellipsis"
    whiteSpace="nowrap">{item?.services_plans?.map(i => i?.name)?.join(', ')}</Text>,
  },
];
