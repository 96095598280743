import React, { useEffect } from 'react'
import { Text, Flex, Logo, Icon, Avatar } from 'components'
import { internalMenuOptions } from './settings'
import { theme } from 'styles'
import { useHistory } from 'react-router-dom'
import { logoutCleanData } from 'services/hooks'
import { internalAuthStore } from 'services/stores'
import { userManagementStore } from 'services/stores'
import { Grid, GridItem } from '@chakra-ui/react'

export const HomeAdm = () => {
  const history = useHistory()
  const { onRefreshUserData, user } = internalAuthStore()
  const { cleanUserData } = userManagementStore()

  useEffect(() => {
    onRefreshUserData()
  }, [history])
  const onLogout = async () => {
    await logoutCleanData()
    cleanUserData()
    history.push('/internal-adm/login')
  }
  return (
    <Flex align="center" flexDir="column" w="100vw" h="100vh" p={theme.pxToRem(40)}>
      <Flex
        flexDir="row"
        position="absolute"
        top={theme.pxToRem(30)}
        right={theme.pxToRem(30)}
        align="center"
        onClick={onLogout}
        cursor="pointer"
      >
        <Text kind="semiBold">Sair</Text>
        <Icon name="Logout" size={18} color="white" ml={theme.pxToRem(8)} />
      </Flex>
      <Logo size="large" />
      <Text align="center" kind="bold" fontSize={theme.pxToRem(18)} mt={theme.pxToRem(14)}>
        GERENCIADOR INTERNO
      </Text>
      {/* {!!userData && <Avatar src={userData?.profile_image_url} size={130} br={130} mt={theme.pxToRem(36)} />} */}
      <Text align="center" kind="bold" fontSize={theme.pxToRem(32)} mt={theme.pxToRem(24)}>
        {`Olá ${user?.phone}!`}
      </Text>
      <Text align="center" kind="bold" fontSize={theme.pxToRem(24)}>
        {`Seja bem-vindo(a) novamente 😀`}
      </Text>
      <Text align="center" kind="bold" fontSize={theme.pxToRem(20)} mt={theme.pxToRem(16)}>
        O que você gostaria de acessar hoje?
      </Text>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap={theme.pxToRem(16)}
        align="center"
        GridDir="row"
        mt={theme.pxToRem(52)}
      >
        {internalMenuOptions.map((item) => (
          <GridItem>
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              w={theme.pxToRem(220)}
              h={theme.pxToRem(160)}
              mr={theme.pxToRem(16)}
              borderRadius={theme.pxToRem(12)}
              bg="background"
              cursor="pointer"
              onClick={() => history.push(item.path)}
            >
              <Icon name={item.icon} size={46} />
              <Text
                align="center"
                w={theme.pxToRem(165)}
                kind="bold"
                fontSize={theme.pxToRem(14)}
                mt={theme.pxToRem(14)}
              >
                {item.title}
              </Text>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Flex>
  )
}

HomeAdm.path = '/internal-adm/home'
HomeAdm.title = 'Adminstrador Interno - BestBarbers'
HomeAdm.secured = false
