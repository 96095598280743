import React from 'react'
import { useTheme } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Avatar, Box, Flex, Text, Icon } from 'components/atoms'

export const TeamCard = ({
  item,
  index,
  isInvite,
  onSelect = () => {},
  onDelete = () => {},
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      key={`data_team_card_${index}`}
      paddingX={theme.pxToRem(20)}
      paddingY={theme.pxToRem(24)}
      marginBottom={theme.pxToRem(16)}
      width="full"
      height="fit-content"
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      onClick={() => onSelect(item)}
    >
      <Flex width="full">
        <Box marginRight={theme.pxToRem(16)}>
          <Avatar src={item?.profile_image_url} size="medium" />
        </Box>

        <Box width="full">
          <Flex marginBottom={!isInvite && theme.pxToRem(16)} width="full">
            <Box marginRight={theme.pxToRem(16)} width={!isInvite && '40%'}>
              <Text
                fontColor="textLight"
                opacity={0.6}
                fontSize={theme.pxToRem(10)}
                fontWeight={500}
                marginBottom={theme.pxToRem(4)}
              >
                {t('BARBER_NAME')}
              </Text>
              <Text
                fontColor="textLight"
                fontSize={theme.pxToRem(14)}
                fontWeight={500}
              >
                {item?.name}
              </Text>
            </Box>

            <Box>
              <Text
                fontColor="textLight"
                opacity={0.6}
                fontSize={theme.pxToRem(10)}
                fontWeight={500}
                marginBottom={theme.pxToRem(4)}
              >
                {t('BARBER_PHONE')}
              </Text>
              <Text
                fontColor="textLight"
                fontSize={theme.pxToRem(14)}
                fontWeight={500}
              >
                {isInvite ? item?.phone : item?.user?.phone}
              </Text>
            </Box>
          </Flex>

          {!isInvite && (
            <Flex>
              <Box marginRight={theme.pxToRem(16)} width="40%">
                <Text
                  fontColor="textLight"
                  opacity={0.6}
                  fontSize={theme.pxToRem(10)}
                  fontWeight={500}
                  marginBottom={theme.pxToRem(4)}
                >
                  {t('RATING_TABLE')}
                </Text>
                <Text
                  fontColor="textLight"
                  fontSize={theme.pxToRem(14)}
                  fontWeight={500}
                >
                  {item?.rating_average || ' - '}
                </Text>
              </Box>

              <Box>
                <Text
                  fontColor="textLight"
                  opacity={0.6}
                  fontSize={theme.pxToRem(10)}
                  fontWeight={500}
                  marginBottom={theme.pxToRem(4)}
                >
                  {t('COMMISSION')}
                </Text>
                <Text
                  fontColor="textLight"
                  fontSize={theme.pxToRem(14)}
                  fontWeight={500}
                >
                  {item?.percentage}%
                </Text>
              </Box>
            </Flex>
          )}
        </Box>

        {isInvite && (
          <Flex alignItems="center" flex={1}>
            <Icon
              name="Trash2"
              color="textLight"
              size={16}
              onClick={() => onDelete(item)}
            />
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
