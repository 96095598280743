import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import {
  Table,
  Text,
  Link,
  Avatar,
  SelectDateModal,
  SelectDateButton,
  Layout,
} from 'components'
import { Flex, Spinner, Center, useToast, useTheme } from '@chakra-ui/react'
import { INTERNAL_ADM_INACTIVE_BARBERSHOPS } from 'services/api/endpoints'
import { useLazyFetch } from 'services/hooks'

const columns = [
  {
    key: 'image',
    label: '',
    render: (item) => <Avatar src={item.profile_image_url} size="medium" />,
  },
  {
    key: 'name',
    label: 'NOME',
    render: (item) => <Text>{item.name}</Text>,
  },

  {
    key: 'phone',
    label: 'TELEFONE',
    render: (item) => (
      <Link
        href={`https://wa.me/${item.user.phone.replace(/[^\w]/gi, '')}`}
        isExternal
      >
        {item.user.phone}
      </Link>
    ),
  },
  {
    key: 'email',
    label: 'EMAIL',
    render: (item) => <Text>{item.user.email}</Text>,
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => <Text>{item.status}</Text>,
  },
  {
    key: 'createdAt',
    label: 'DATA CRIAÇÃO',
    render: (item) => (
      <Text>{format(parseISO(item.createdAt), 'dd/MM/yyyy')}</Text>
    ),
  },
]

export const InactiveBarbershops = () => {
  const toast = useToast()
  const theme = useTheme()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dateModalOpen, setDateModalOpen] = useState(false)

  const [getData, { loading, data }] = useLazyFetch(
    INTERNAL_ADM_INACTIVE_BARBERSHOPS(parseISO(selectedDate).getTime()),
    {
      onError: (error) => {
        console.log({ error })
        toast({
          title: 'Houve um erro ao carregar as barbearias!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    getData()
  }, [selectedDate])

  return (
    <Layout
      headerCenterElement={() => (
        <SelectDateButton
          onlyMonth
          date={selectedDate}
          setDate={setSelectedDate}
          onClickCenter={() => setDateModalOpen(true)}
        />
      )}
      title="Novas barbearias por Data"
      isInternalAdm
    >
      <SelectDateModal
        onlyMonth
        onClose={() => setDateModalOpen(false)}
        isModalOpen={dateModalOpen}
        selectedDate={selectedDate}
        onSelectDate={(date) => {
          setSelectedDate(date)
          setDateModalOpen(false)
        }}
      />

      {loading ? (
        <Flex
          flexDir="column"
          w="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Center>
            <Spinner color="textLight" />
          </Center>
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          w="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Text mt="2vh">Número de barbearias cadastradas: {data?.total}</Text>
          <Text mt="2vh" mb="2vh">
            Número de barbearias inativas: {data?.totalInactive}
          </Text>
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <Table
              loading={loading}
              data={data?.barbershops || []}
              columns={columns}
            />
          </Flex>
        </Flex>
      )}
    </Layout>
  )
}

InactiveBarbershops.path = '/internal-adm/inactive'
InactiveBarbershops.title = 'Barbearias Inativas'
InactiveBarbershops.secured = true
