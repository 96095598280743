import React from 'react'
import { Text, TextInput } from 'components/atoms'
import { FieldControl, SelectCard } from 'components/molecules'
import { useFormikContext } from 'formik'

import {
  VStack,
  Select,
  HStack,
  Divider,
  Collapse,
  Box,
} from '@chakra-ui/react'

export const IssuerInvoiceRecipientForm = ({
  isLoading,
  isDisabled,
  ...props
}) => {
  const formik = useFormikContext()

  return (
    <Box {...props}>
      <SelectCard
        item={{ title: 'Adicionar dados do comprador na nota fiscal' }}
        disabled={isDisabled}
        setSelected={(ev) =>
          formik.setFieldValue('helpers.addRecipientData', ev)
        }
        selected={formik.values.helpers?.addRecipientData}
        mt={6}
      />

      <Collapse in={formik.values.helpers?.addRecipientData} animateOpacity>
        <VStack spacing={4} align="normal" mt={6}>
          <HStack spacing={6}>
            <FieldControl
              label="Tipo de comprador"
              error={formik.errors.recipientType}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <Select
                  name="recipientType"
                  size="lg"
                  bg="backgroundLayout"
                  borderColor="gray.800"
                  h="52px"
                  value={formik.values.helpers?.recipientType}
                  onChange={formik.handleChange}
                >
                  <option value="">Selecione</option>
                  <option value="PF">Pessoa Física</option>
                  <option value="PJ">Pessoa Jurídica</option>
                </Select>
              )}
            />
            <FieldControl
              label={`Nome ${
                formik.values.helpers?.recipientType === 'PF'
                  ? 'do consumidor'
                  : 'da empresa'
              }`}
              error={formik.errors.nome_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <TextInput
                  name="nome_destinatario"
                  value={formik.values.nome_destinatario}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>

          {formik.values.helpers?.recipientType === 'PF' && (
            <HStack spacing={6}>
              <FieldControl
                label="CPF"
                error={formik.errors.cpf_destinatario}
                isLoading={isLoading}
                isDisabled={isDisabled}
                render={({ error }) => (
                  <TextInput
                    name="cpf_destinatario"
                    mask="CPF_MASK"
                    error={!!error}
                    value={formik.values.cpf_destinatario}
                    onChange={formik.handleChange}
                  />
                )}
              />

              <FieldControl
                label="Telefone"
                error={formik.errors.telefone_destinatario}
                isLoading={isLoading}
                isDisabled={isDisabled}
                render={({ error }) => (
                  <TextInput
                    name="telefone_destinatario"
                    mask="PHONE_MASK"
                    error={!!error}
                    value={formik.values.telefone_destinatario}
                    onChange={formik.handleChange}
                  />
                )}
              />
            </HStack>
          )}
          {formik.values.helpers?.recipientType === 'PJ' && (
            <HStack spacing={6}>
              <FieldControl
                label="CNPJ"
                error={formik.errors.cnpj_destinatario}
                isLoading={isLoading}
                isDisabled={isDisabled}
                render={({ error }) => (
                  <TextInput
                    name="cnpj_destinatario"
                    mask="CPF_MASK"
                    error={!!error}
                    value={formik.values.cnpj_destinatario}
                    onChange={formik.handleChange}
                  />
                )}
              />
              <FieldControl
                label="Inscrição municipal"
                error={formik.errors.indicador_inscricao_estadual_destinatario}
                isLoading={isLoading}
                isDisabled={isDisabled}
                render={() => (
                  <TextInput
                    name="indicador_inscricao_estadual_destinatario"
                    type="email"
                    value={
                      formik.values.indicador_inscricao_estadual_destinatario
                    }
                    onChange={formik.handleChange}
                  />
                )}
              />
            </HStack>
          )}

          <HStack spacing={4} pt={4}>
            <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
              Endereço
            </Text>
            <Divider borderColor="gray.600" />
          </HStack>
          <HStack spacing={6}>
            <FieldControl
              label="CEP"
              error={formik.errors.cep_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={({ error }) => (
                <TextInput
                  name="cep_destinatario"
                  mask="CEP_MASK"
                  error={!!error}
                  value={formik.values.cep_destinatario}
                  onChange={formik.handleChange}
                />
              )}
            />

            <FieldControl
              label="UF"
              error={formik.errors.uf_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <Select
                  name="uf_destinatario"
                  size="lg"
                  bg="backgroundLayout"
                  borderColor="gray.800"
                  h="52px"
                  value={formik.values.uf_destinatario}
                  onChange={formik.handleChange}
                >
                  <option value="">Selecione</option>
                  <option value="AC">Acre (AC)</option>
                  <option value="AL">Alagoas (AL)</option>
                  <option value="AP">Amapá (AP)</option>
                  <option value="AM">Amazonas (AM)</option>
                  <option value="BA">Bahia (BA)</option>
                  <option value="CE">Ceará (CE)</option>
                  <option value="DF">Distrito Federal (DF)</option>
                  <option value="ES">Espírito Santo (ES)</option>
                  <option value="GO">Goiás (GO)</option>
                  <option value="MA">Maranhão (MA)</option>
                  <option value="MT">Mato Grosso (MT)</option>
                  <option value="MS">Mato Grosso do Sul (MS)</option>
                  <option value="MG">Minas Gerais (MG)</option>
                  <option value="PA">Pará (PA)</option>
                  <option value="PB">Paraíba (PB)</option>
                  <option value="PR">Paraná (PR)</option>
                  <option value="PE">Pernambuco (PE)</option>
                  <option value="PI">Piauí (PI)</option>
                  <option value="RJ">Rio de Janeiro (RJ)</option>
                  <option value="RN">Rio Grande do Norte (RN)</option>
                  <option value="RS">Rio Grande do Sul (RS)</option>
                  <option value="RO">Rondônia (RO)</option>
                  <option value="RR">Roraima (RR)</option>
                  <option value="SC">Santa Catarina (SC)</option>
                  <option value="SP">São Paulo (SP)</option>
                  <option value="SE">Sergipe (SE)</option>
                  <option value="TO">Tocantins (TO)</option>
                </Select>
              )}
            />
          </HStack>
          <HStack spacing={6}>
            <FieldControl
              label="Município"
              error={formik.errors.municipio_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <TextInput
                  name="municipio_destinatario"
                  value={formik.values.municipio_destinatario}
                  onChange={formik.handleChange}
                />
              )}
            />
            <FieldControl
              label="Bairro"
              error={formik.errors.bairro_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <TextInput
                  name="bairro_destinatario"
                  value={formik.values.bairro_destinatario}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>

          <HStack spacing={6} align="start">
            <FieldControl
              flex={3}
              label="Logradouro"
              error={formik.errors.logradouro_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={() => (
                <TextInput
                  name="logradouro_destinatario"
                  value={formik.values.logradouro_destinatario}
                  onChange={formik.handleChange}
                />
              )}
            />
            <FieldControl
              flex={1}
              label="Número"
              error={formik.errors.numero_destinatario}
              isLoading={isLoading}
              isDisabled={isDisabled}
              render={({ error }) => (
                <TextInput
                  name="numero_destinatario"
                  mask="NUMBER_MASK"
                  error={!!error}
                  value={formik.values.numero_destinatario}
                  onChange={formik.handleChange}
                />
              )}
            />
          </HStack>
        </VStack>
      </Collapse>
    </Box>
  )
}
