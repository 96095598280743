import { format } from 'date-fns'

const BARBERSHOP = {
  PUBLISH: 'published',
  PRIVATE: 'private',
  NOT_PUBLISHED: 'not_published',
  READY_TO_PUBLISH: 'ready_to_publish',
  BLOCKED: 'blocked',
}

export const BARBERSHOP_STATUSES = [
  {
    status: BARBERSHOP.PUBLISH,
    label: 'ACTIVE',
    description: 'ACTIVE_DESC',
    color: 'green',
    action: 'publish',
  },
  {
    status: BARBERSHOP.PRIVATE,
    label: 'PRIVATE',
    description: 'PRIVATE_DESC',
    color: 'grey',
    action: 'private',
  },
  {
    status: BARBERSHOP.READY_TO_PUBLISH,
    label: 'PENDING',
    description: 'PENDING_DESC',
    color: 'primary',
  },
  {
    status: BARBERSHOP.NOT_PUBLISHED,
    label: 'NOT_PUBLISHED',
    description: 'NOT_PUBLISHED_DESC',
    color: 'red',
    action: 'unpublish',
  },
  {
    status: BARBERSHOP.BLOCKED,
    label: 'BLOCKED',
    description: 'BLOCKED_DESC',
    color: 'dangerDark',
  },
]

export const getCurrentStatus = (userData) => {
  return (
    BARBERSHOP_STATUSES.find((item) => item.status === userData.status) ||
    BARBERSHOP_STATUSES[4]
  )
}

/*
export const checkBarbershopIsBlocked = (
  barbershop_status,
  iugu,
  subscription,
  state
) => {
  if (barbershop_status === 'SUBSCRIBED' && state === 'paid') {
    return false
  }
  if (barbershop_status === 'TRIAL_END') {
    return true
  }
  if (barbershop_status === 'SUBSCRIPTION_SUSPENDED') {
    return true
  }
  if (barbershop_status === 'PAYMENT_ISSUES') {
    return true
  }
  if (iugu === 'free_barbershop_plan') {
    return true
  }
  if (subscription?.recent_invoices?.length) {
    const last_invoice = subscription?.recent_invoices?.[0]
    if (
      last_invoice?.status === 'pending' &&
      format(new Date(), 'yyyy-MM-dd') > last_invoice?.due_date
    ) {
      return true
    }
    return false
  }
  return false
}
*/

export const handleAppoitmentStatusColor = (status) => {
  switch (status) {
    case 'awaiting':
      return 'primary'
    case 'client_arrived':
      return 'orange'
    case 'pending':
      return 'danger'
      case 'PENDING':
        return 'danger'
    case 'finished':
      return 'successDark'
    case 'reopened':
      return 'primary'
    case 'confirmed':
      return 'blue'
    case 'client_missed':
      return 'textMedium'
    case 'paid':
      return 'successDark'
    case 'PAID':
      return 'successDark'
    default:
      return {}
  }
}
