import React from 'react'
import { Switch } from '@chakra-ui/react'

import { Text, Flex, TextInput, Box } from 'components'
import { theme } from 'styles'

export const TableInputs = ({ columns, data, setData }) => {
  const onChangeValue = (id, key, value) => {
    const changed = data?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]:
            typeof value === 'string' && value?.includes('%')
              ? value?.replace('%', '')
              : value,
        }
      }
      return item
    })
    setData(changed)
  }

  return (
    <Box w="100%">
      <Flex
        mt={theme.pxToRem(16)}
        mb={theme.pxToRem(16)}
        justify="space-between"
        w="100%"
      >
        {columns?.map((c, index) => {
          const cell_props = {
            borderColor: 'border',
            borderWidth: 1,
            h: 16,
            align: 'center',
            p: 4,
          }
          return (
            <Box w={c?.w || 'auto'}>
              <Flex
                {...cell_props}
                h={8}
                borderTopLeftRadius={index === 0 && 8}
                borderTopRightRadius={index === columns?.length - 1 && 8}
              >
                <Text fontSize={12} kind="bold">
                  {c.label}
                </Text>
              </Flex>
              {data.map((item, index) => {
                if (c?.type === 'input') {
                  return (
                    <Flex {...cell_props} justify="center">
                      <TextInput
                        w="95%"
                        mask="PERCENTAGE_MASK"
                        disabled={item?.inactive}
                        value={item?.[`${c?.key}`] || 0}
                        onChange={(e) => {
                          onChangeValue(item?.id, `${c.key}`, e.target.value)
                        }}
                      />
                    </Flex>
                  )
                }
                if (c?.type === 'switch') {
                  return (
                    <Flex {...cell_props} justify="center">
                      <Switch
                        colorScheme="yellow"
                        onChange={(e) =>
                          onChangeValue(
                            item?.id,
                            `${c.key}`,
                            !item?.[`${c?.key}`]
                          )
                        }
                        isChecked={!item?.[`${c?.key}`]}
                      />
                    </Flex>
                  )
                }
                return (
                  <Flex {...cell_props}>
                    <Text
                      fontSize={14}
                      kind="semiBold"
                      color={item?.inactive ? 'textMedium' : 'textLight'}
                    >
                      {item?.[`${c.key}`]}
                    </Text>
                  </Flex>
                )
              })}
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}
