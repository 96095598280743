import React from 'react'
import { Table, Text, ModalMenu, Button } from 'components'
import { flagPayment } from './settings'
import { useSingleSaleStore } from 'services/stores'
import { t } from 'services/translation'
import { theme } from 'styles'

const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item?.name}
      </Text>
    ),
  },
]

const SelectFlag = ({ isOpen, onClose }) => {
  const { products } = useSingleSaleStore()
  return (
    <ModalMenu
      title={t('SELECT_FLAG')}
      isOpen={isOpen}
      onClose={onClose}
      flexDirection="column"
    >
      <Table
        isProductsTable
        isFlag
        isMultiSelectable
        isSelectableRowVariabled={
          products && products?.find((item) => item?.id === 0)
            ? flagPayment?.map((i) => i?.id === 0)
            : flagPayment
        }
        data={flagPayment || []}
        columns={columns}
      />
      <Button onClick={() => onClose()} size="block" mt={theme.pxToRem(120)}>
        Selecionar
      </Button>
    </ModalMenu>
  )
}

export default SelectFlag
