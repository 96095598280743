import React, { useEffect, useMemo, useState } from 'react'
import { Text, Flex, Icon, Logo, Button, Box, ModalMenu, Table, TextInput, Form } from 'components'
import { theme } from 'styles'
import { useHistory } from 'react-router-dom'
import { Spinner, useTheme, useToast } from '@chakra-ui/react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { useServices } from 'services/hooks/use-services'
import { usePlans } from 'services/hooks/use-plans'
import { unformatCurrency } from 'utils/formatCurrency'

export const Layout = ({ title, withoutHeader, children, addButton, secondButton }) => {
  const history = useHistory()
  return (
    <Flex align="flex-start" minH="100vh" flexDir="column" w="100vw" h="100%" p={theme.pxToRem(40)}>
      {!withoutHeader && (
        <Flex flexDir="row" w="100%" justify="space-between" mb={theme.pxToRem(32)}>
          <Flex flexDir="row">
            <Icon
              name={'ArrowLeft'}
              size={32}
              color="white"
              onClick={() => history.push('/internal-adm/home')}
              cursor="pointer"
            />
            <Text align="center" kind="bold" fontSize={theme.pxToRem(32)} ml={theme.pxToRem(24)}>
              {title}
            </Text>
          </Flex>
          <Flex flexDir="row">
            {addButton && (
              <Button onClick={addButton?.onClick} size="fit-small">
                {addButton?.text}
              </Button>
            )}
            {secondButton && (
              <Button ml={4} onClick={secondButton?.onClick} size="fit-small">
                {secondButton?.text}
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      {children}
    </Flex>
  )
}

export const ResultCard = ({ value, description, label }) => {
  return (
    <Flex
      bg="background"
      align="flex-start"
      flexDir="column"
      p={theme.pxToRem(24)}
      borderRadius={theme.pxToRem(10)}
      mt={theme.pxToRem(24)}
      w={theme.pxToRem(437)}
    >
      <Text kind="extraBold" fontSize={theme.pxToRem(20)} mb={theme.pxToRem(8)}>
        {value}
      </Text>
      <Text fontSize={theme.pxToRem(12)} color="textGrey">
        {label}
      </Text>
      <Text fontSize={theme.pxToRem(12)} color="textLight">
        {description}
      </Text>
    </Flex>
  )
}

export const Loader = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      bg="background"
      align="center"
      justify="center"
      flexDir="column"
      position="absolute"
      bottom={0}
      left={0}
      opacity={0.5}
    >
      <Logo size="medium" />
      <Spinner mt={theme.pxToRem(48)} color="textLight" />
    </Flex>
  )
}

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'time_required',
    label: 'TIME_REQUIRED',
    render: (item) => <Text>{item.time_required}</Text>,
  },
]

export const SelectServiceMethod = ({ isOpen, onClose, plan, ...props }) => {
  const toast = useToast()
  const [selected, setSelected] = useState()
  const [enabled, setEnabled] = useState(false)
  const [points, setPoints] = useState()
  const [quantity, setQuantity] = useState()
  const { getServices, addServicePlan } = useServices()
  const theme = useTheme()

  const { data, isLoading } = useQuery(
    ['get-services-signature-backoffice', plan],
    () => getServices({ id: plan?.barbershop_id }),
    {
      onError: (error) => {
        toast({
          title: t('LOAD_SERVICES_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      enabled,
    }
  )

  useEffect(() => {
    if (plan) {
      setEnabled(true)
    }
  }, [plan])

  const mutate = useMutation(
    'add-service-plan',
    () =>
      addServicePlan({
        body: {
          points,
          quantity,
          service_id: selected?.id,
          plan_id: plan?.id,
        },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Serviço adicionado com sucesso',
          status: 'success',
          duration: 3000,
        })
      },
      onSettled: () => {
        setSelected(null)
      },
      onError: () => {
        toast({
          title: 'Erro ao adicionar serviço',
          status: 'error',
          duration: 3000,
        })
      },
    }
  )

  const handleSelectService = (pm) => {
    setSelected(pm)
  }

  const isDisabled = useMemo(() => {
    if (!points || points.length === 0) {
      return true
    }

    if (plan?.limited && (!quantity || quantity?.length === 0)) {
      return true
    }

    if (!selected) {
      return true
    }

    return false
  })

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setSelected(null)
      }}
      title={t('SELECT_SERVICE')}
    >
      <Box>
        <Table
          withoutHeader
          isSelectableRow
          data={data?.filter((i) => i?.type === 'signature') || []}
          loading={isLoading}
          columns={columns}
          onSelect={handleSelectService}
          selectedList={[selected]}
          isMultiSelectable
        />
        {selected && (
          <>
            <TextInput
              type="number"
              value={points}
              label="Peso"
              placeholder="Digite o peso do serviço"
              mb="16px"
              onChange={(e) => setPoints(e.target.value)}
            />
            {plan?.limited && (
              <TextInput
                type="number"
                value={quantity}
                label="Quantidade"
                placeholder="Digite a quantidade de usos"
                mt="32px"
                mb="32px"
                onChange={(e) => setQuantity(e.target.value)}
              />
            )}
          </>
        )}
        <Button isLoading={mutate.isLoading} isDisabled={isDisabled} size="block" mt="30%" onClick={mutate.mutate}>
          Salvar
        </Button>
      </Box>
    </ModalMenu>
  )
}

export const ServiceCard = ({ service, onClick, ...props }) => {
  const theme = useTheme()
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(service?.name)

  return (
    <Box
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      p={theme.pxToRem(16)}
      minH="56px"
      w="100%"
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Flex w="100%" flexDir="row" alignItems="center" justifyContent="space-between">
        <Flex alignItems="left" w="90%" flexDir="row">
          {!edit ? (
            <Text
              kind="bold"
              color="textLight"
              fontSize={theme.pxToRem(14)}
              mr={theme.pxToRem(8)}
              maxW={theme.pxToRem(300)}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {name}
            </Text>
          ) : (
            <TextInput
              w="85%"
              placeholder="0,00"
              isReadOnly={false}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          )}
        </Flex>
        <Icon
          mr="28px"
          name={edit ? 'Check' : 'Pencil'}
          size={16}
          ml="5px"
          color="white"
          onClick={() => {
            if (edit) {
              onClick({ id: service.id, name })
            }
            setEdit(!edit)
          }}
          cursor="pointer"
        />
      </Flex>
    </Box>
  )
}

export const DeleteServiceModal = ({ isOpen, onClose, plan, ...props }) => {
  const toast = useToast()
  const [enabled, setEnabled] = useState(false)
  const { deleteServicePlan } = useServices()
  const { getServicesPlans } = usePlans()
  const theme = useTheme()

  const { isLoading, data, refetch } = useQuery(
    ['get-services-plans-management'],
    () =>
      getServicesPlans({
        plan_id: plan?.id,
      }),
    {
      enabled,
    }
  )

  useEffect(() => {
    if (plan) {
      setEnabled(true)
    }
  }, [plan])

  const mutate = useMutation(
    'delete-service-plan',
    (item) =>
      deleteServicePlan({
        body: {
          service_plan_id: item?.id,
          service_id: item?.service_id,
          plan_id: plan?.id,
        },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Serviço excluido com sucesso',
          status: 'success',
          duration: 3000,
        })
        refetch()
      },
      onError: () => {
        toast({
          title: 'Erro ao excluir serviço',
          status: 'error',
          duration: 3000,
        })
      },
    }
  )

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title="Excluir serviço"
    >
      <Box>
        <Table
          withoutHeader
          isSelectableRow
          data={data?.services_plans || []}
          loading={isLoading || mutate.isLoading}
          columns={columns}
          onSelect={(item) => {
            mutate.mutate(item)
          }}
          deleteButtom
        />
      </Box>
    </ModalMenu>
  )
}

export const UpdateValueModal = ({ isOpen, onClose, plan, ...props }) => {
  const toast = useToast()
  const [value, setValue] = useState(plan?.value?.toString())
  const [changeAll, setChangeAll] = useState(false)
  const { updatePlanValue } = usePlans()
  const theme = useTheme()
  const queryClient = useQueryClient()

  const mutate = useMutation(
    'update-plan-value',
    () =>
      updatePlanValue({
        body: {
          change_all: changeAll,
          value: unformatCurrency(value),
          plan_id: plan?.id,
        },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Plano alterado com sucesso',
          status: 'success',
          duration: 3000,
        })
        onClose()
        setValue(null)
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get-plans-barbershop'])
      },
      onError: () => {
        toast({
          title: 'Erro ao alterar plano',
          status: 'error',
          duration: 3000,
        })
      },
    }
  )

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setValue(null)
      }}
      title="Alterar valor do plano"
    >
      <Box>
        <TextInput
          noBorder
          name="value"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="0,00"
          mask="CURRENCY_MASK"
          masked
          leftElement={
            <Text mt={theme.pxToRem(2)} fontWeight="bold" ml="0" width={theme.pxToRem(40)}>
              {t('CURRENCY')}
            </Text>
          }
        />
        <Box mt="32px">
          <Text kind="extraBold" fontSize={20} marginBottom={theme.pxToRem(10)}>
            ALTERAR PARA TODOS OS CLIENTES?
          </Text>
          <Flex alignItems="center" marginBottom="36px">
            <Button
              flex="1"
              mr={theme.pxToRem(8)}
              h={theme.pxToRem(52)}
              kind={changeAll ? 'primary' : 'outline'}
              onClick={() => {
                setChangeAll(true)
              }}
            >
              {t('YES')}
            </Button>
            <Button
              flex="1"
              ml={theme.pxToRem(8)}
              onClick={() => {
                setChangeAll(false)
              }}
              kind={!changeAll ? 'primary' : 'outline'}
            >
              {t('NO')}
            </Button>
          </Flex>
        </Box>

        <Button isLoading={mutate.isLoading} size="block" mt="30%" onClick={mutate.mutate}>
          Salvar
        </Button>
      </Box>
    </ModalMenu>
  )
}

const options = (plan) => [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    noBorder: true,
    label: 'CLIENT_NAME',
    placeholder: 'Corte + Barba - Clube de assinatura',
    disabled: false,
  },
  {
    id: 'comission',
    name: 'comission',
    label: 'COMISSION',
    noBorder: true,
    mask: 'PERCENTAGE_MASK',
    placeholder: '30%',
  },
]
const initialValues = {
  name: '',
  comission: '',
}

export const UpdateInfos = ({ isOpen, onClose, plan, ...props }) => {
  const toast = useToast()
  const { updatePlan, updateService } = usePlans()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const [values, getValues] = useState({ params: plan, isValid: false })
  const [services, setServices] = useState(plan?.services_plans)

  useEffect(() => {
    getValues({ params: plan, isValid: false })
    setServices(plan?.services_plans)
  }, [plan])
  const mutate = useMutation(
    'update-plan-value',
    () =>
      updatePlan({
        body: {
          name: values?.params?.name,
          comission: Number(values?.params?.comission?.toString()?.replace?.('%', '')),
          services,
          plan_id: plan?.id,
        },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Plano alterado com sucesso',
          status: 'success',
          duration: 3000,
        })
        onClose()
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get-plans-barbershop'])
      },
      onError: () => {
        toast({
          title: 'Erro ao alterar plano',
          status: 'error',
          duration: 3000,
        })
      },
    }
  )

  const mutateService = useMutation(
    'update-service-name',
    (params) =>
      updateService({
        body: {
          name: params?.name,
          service_id: params?.id,
        },
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Serviço alterado com sucesso',
          status: 'success',
          duration: 3000,
        })
        onClose()
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get-plans-barbershop'])
      },
      onError: () => {
        toast({
          title: 'Erro ao alterar servico',
          status: 'error',
          duration: 3000,
        })
      },
    }
  )

  const handleChange = (id, value, type) => {
    const existingItem = services.find((item) => item.id === id)
    if (existingItem) {
      const updatedInputValue = services.map((item) =>
        item.id === id ? { ...item, [type]: Number(value.replace(',', '.')) } : item
      )
      setServices(updatedInputValue)
    } else {
      setServices([...services, { id, [type]: Number(value.replace(',', '.')) }])
    }
  }
  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title="Alterar dados do plano"
    >
      <Box>
        <Form mb={42} data={options(plan)} initialValues={values?.params} getValues={getValues} />
        <Box marginBottom="24px">
          <Text kind="medium" fontSize="14px" color="textGrey" mb="6px">
            {t('SELECT_SERVICES')}
          </Text>
          {!!services?.length &&
            services.map((item, index) => (
              <Box minHeight={services?.length > 1 && '156px'} mb={'32px'}>
                <ServiceCard
                  key={index}
                  index={index}
                  marginBottom={'12px'}
                  fullWidth
                  service={item?.product || item}
                  onClick={mutateService.mutate}
                />
                <TextInput
                  type="number"
                  value={services?.find((i) => i?.id === item?.id)?.points}
                  label="Peso"
                  placeholder="Digite o peso do serviço"
                  mb="16px"
                  onChange={(e) => handleChange(item?.id, e.target.value, 'points')}
                />
                {plan?.limited && (
                  <TextInput
                    type="number"
                    value={services?.find((i) => i?.id === item?.id)?.quantity}
                    label="Quantidade"
                    onChange={(e) => handleChange(item?.id, e.target.value, 'quantity')}
                    mt="32px"
                  />
                )}
              </Box>
            ))}
        </Box>
        <Button
          kind="primary"
          isDisabled={
            !values?.params?.name ||
            !values.params?.value ||
            (!values?.params?.comission && values?.params?.comission !== 0) ||
            services?.length === 0 ||
            services.find((s) => !s?.points && s?.points !== 0)
          }
          isLoading={mutate.isLoading}
          size="block"
          mb="16px"
          onClick={() => {
            mutate.mutate()
          }}
        >
          {t('SAVE')}
        </Button>
      </Box>
    </ModalMenu>
  )
}
