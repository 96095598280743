import React from 'react'

const MasterCard = (props) => {
  return (
    <svg
      width="38"
      height="24"
      viewBox="0 0 38 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.5" cy="12" r="12" fill="#F79E1B" />
      <circle cx="12.5" cy="12" r="12" fill="#EB001B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19 22.1777C22.3093 20.0412 24.5 16.3208 24.5 12.0888C24.5 7.85683 22.3093 4.13649 19 2C15.6907 4.13649 13.5 7.85683 13.5 12.0888C13.5 16.3208 15.6907 20.0412 19 22.1777Z"
        fill="#FF5F00"
      />
    </svg>
  )
}

export default MasterCard
