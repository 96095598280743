import { useBarbershopStore } from 'services/stores'
import { saveLoginData } from './use-auth'
import { useHistory } from 'react-router-dom'

export const useLogoutPaymentIssues = () => {
  const { userData, setData } = useBarbershopStore()
  const history = useHistory()
  const onLogout = async () => {
    const positive_access = ['LEAD', 'TRIAL', 'ONBOARDING', 'SUBSCRIBED', 'SUBSCRIBED_PARTNER']

    // const ids = [
    //   1, 9030, 9031, 9334, 9512, 10387, 10489, 10562, 10593, 10811, 11070, 11071, 11682, 11764, 11839, 12139, 12229,
    //   11271, 11379, 11406, 11408, 11559, 11560, 11565, 11968, 12034, 12125, 12205, 2116, 2877, 6634, 7649, 7652, 8023,
    //   7654, 7655, 7657, 7666, 7758, 7668, 7683, 7703, 7756, 7704, 7705, 7757, 7750, 7752, 7753, 7754, 7779, 7660, 7895,
    //   8132, 8133, 8134, 8468, 8469, 8482, 8505, 8553, 8671, 8740, 8742, 8860, 8861, 8825, 9004, 9005, 9006, 8930, 8938,
    //   8974, 9109, 9594, 10313, 10351, 10369, 10401, 12652, 10512, 10589, 10631, 10659, 10679, 11417,
    // ]

    // const v6ids = [
    //   2264, 7608, 7651, 7659, 7662, 7663, 7665, 7751, 7797, 7825, 7928, 8129, 8934, 9034, 9260, 9569, 9996, 10683,
    //   10684, 10704, 10706, 10810, 10814, 10907, 11023, 11047, 11340, 11446, 11454, 11458, 11480, 11541, 11542, 11545,
    //   11549, 11558, 11599, 11656, 11663, 11667, 11668, 11689, 11698, 11705, 11729, 11752, 11754, 11763, 11765, 11811,
    //   11851, 11853, 11864, 11871, 11872, 11890, 11894, 12003, 12007, 12011, 12017, 12038, 12130, 12191, 12199, 12243,
    //   12251, 12255, 12291, 12321, 12350, 12357, 12416, 12419, 12450, 12499, 12516, 12535, 12547, 12561, 12580, 12581,
    //   12606, 12607, 12611, 12655, 12659, 12685, 12690, 12704, 12771, 12942, 12956, 13035, 13049, 13069, 13094, 13116,
    // ]
    if (userData?.version !== 'v4') {
      saveLoginData({})
      setData({}, {})
      localStorage.clear()
      history.replace('/')
      return
    }

    if (!positive_access.includes(userData?.account_status)) {
      saveLoginData({})
      setData({}, {})
      localStorage.clear()
      history.replace('/')
      return
    }
  }

  return { onLogout }
}
