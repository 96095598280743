export const sections = [
  {
    id: 1,
    name: 'name',
    label: 'Nome do usuário',
  },
  {
    id: 2,
    name: 'email',
    label: 'E-mail',
  },
  {
    id: 3,
    name: 'phone',
    label: 'Telefone',
    type: "inputMask",
    mask: '** *****-****',
    alwaysShowMask: false,
    maskChar: null,
  },
  // {
  //   id: 4,
  //   name: 'password',
  //   label: 'Senha',
  // },
]