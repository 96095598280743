import React from 'react'
import { Box, Flex, useTheme } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Icon, Text, Logo, Avatar } from 'components/atoms'
import { MenuItem } from 'components/molecules'
import { StatusModal } from 'components/organisms'
import { useTranslation } from 'react-i18next'

export const MobileMenu = ({
  isOpen,
  onToggle,
  path,
  userData,
  onClickToNavigate,
  setOpenStatusModal,
  handleUserStatus,
  openStatusModal,
  options,
  isInternalAdm,
  isBannerOpen,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Flex w="100%" zIndex={5}>
      {!isOpen && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingY={theme.pxToRem(16)}
          paddingX={theme.pxToRem(20)}
          w="100%"
          bg="cardBackground"
          borderBottomWidth={1}
          borderColor="modalBorder"
          onClick={onToggle}
        >
          <Logo size="small" />

          <HamburgerIcon
            width={theme.pxToRem(30)}
            height={theme.pxToRem(30)}
            color="primary"
          />
        </Flex>
      )}

      {isOpen && (
        <Box w="100%" style={{ zIndex: 5 }}>
          <Flex
            flexDirection="column"
            width="full"
            height="full"
            backgroundColor="background"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              paddingY={theme.pxToRem(16)}
              paddingX={theme.pxToRem(20)}
              w="100%"
              bg="cardBackground"
              borderBottomWidth={1}
              borderColor="modalBorder"
              onClick={onToggle}
            >
              <Logo size="small" />

              <Icon
                name="Close"
                color="primary"
                width={theme.pxToRem(24)}
                height={theme.pxToRem(24)}
              />
            </Flex>

            {!isInternalAdm && (
              <>
                <Flex
                  alignItems="center"
                  px={theme.pxToRem(12)}
                  pb={theme.pxToRem(24)}
                  pt={theme.pxToRem(44)}
                  w="100%"
                  backgroundColor="cardBackground"
                  borderBottomWidth={1}
                  borderColor="modalBorder"
                >
                  <Avatar size="menu" src={userData?.profile_image_url} />

                  <Box marginLeft={theme.pxToRem(16)}>
                    <Text
                      fontSize={theme.pxToRem(14)}
                      lineHeight={theme.pxToRem(20)}
                      kind="extraBold"
                      marginBottom={theme.pxToRem(8)}
                    >
                      {userData?.name}
                    </Text>

                    <Flex
                      alignItems="center"
                      padding={theme.pxToRem(8)}
                      backgroundColor="menuBackground"
                      borderRadius={25}
                      cursor="pointer"
                      onClick={() => setOpenStatusModal(true)}
                    >
                      <Box
                        backgroundColor={handleUserStatus?.color}
                        borderRadius={100}
                        width={theme.pxToRem(14)}
                        height={theme.pxToRem(14)}
                      />
                      <Text
                        fontSize={theme.pxToRem(14)}
                        marginLeft={theme.pxToRem(8)}
                        kind="normal"
                        color="textGrey"
                      >
                        {t(handleUserStatus?.label)}
                      </Text>

                      <Icon
                        name="ChevronDown"
                        marginLeft={theme.pxToRem(8)}
                        size={13}
                      />
                    </Flex>
                  </Box>

                  <StatusModal
                    isModalOpen={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                  />
                </Flex>
              </>
            )}

            <Box w="100%" bg="menuBackground">
              {!!options.length &&
                options.map((item) => {
                  if (item?.permission !== 'blocked') {
                    return (
                      <Box
                        key={item?.id}
                        px={theme.pxToRem(12)}
                        py={theme.pxToRem(4)}
                        marginBottom={theme.pxToRem(14)}
                        w="full"
                      >
                        <Text
                          color="textMedium"
                          marginLeft={theme.pxToRem(6)}
                          marginBottom={theme.pxToRem(6)}
                          kind="semiBold"
                          fontSize={theme.pxToRem(12)}
                        >
                          {t(item?.section).toUpperCase()}
                        </Text>
                        {!!item?.options?.length &&
                          (item?.options).map((subItem) => {
                            if (subItem?.permission !== 'blocked') {
                              return (
                                <MenuItem
                                  selected={subItem?.path === path}
                                  key={subItem?.id}
                                  item={{ ...subItem }}
                                  onClick={() =>
                                    onClickToNavigate(subItem?.path)
                                  }
                                />
                              )
                            }
                          })}
                      </Box>
                    )
                  }
                })}
            </Box>
          </Flex>
        </Box>
      )}
    </Flex>
  )
}
