import React, { useState, useEffect, useMemo } from 'react'
import { differenceInDays, format, parseISO } from 'date-fns'
import {
  Box,
  Text,
  Button,
  Flex,
  ClientCard,
  ServiceCard,
  BarberCard,
  DateTimeCard,
  WeekdayCard,
} from 'components'
import { useTheme, useToast } from '@chakra-ui/react'
import { APPOINTMENT_STEPS, renderButton } from './settings'
import {
  useAppointmentStore,
  useBarbershopStore,
  useSingleSaleStore,
} from 'services/stores'
import { useTranslation } from 'react-i18next'
import { usePremiumBlock } from 'services/hooks'
import { useAppointment } from 'services/hooks/use-appointment'
import { useMutation } from 'react-query'
import { TextInput } from 'components/atoms'
import { daysOfWeek } from 'utils'
import { ModalButtonBackground } from 'components-v2/atoms'

const labelButtonProps = {
  marginTop: 4,
  marginBottom: 2,
  fontSize: 12,
  textTransform: 'uppercase',
}

const AppointmentSelectOptions = ({ setStep, onClose, setClickEmpty }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const toast = useToast()
  const {
    client,
    setClient,
    barber,
    setBarber,
    serviceArray,
    setServiceArray,
    date,
    simpleDate,
    setDate,
    clearState,
    time,
    setTime,
    finishHour,
    setFinishHour,
  } = useAppointmentStore()
  const { loading: loadingSingleSale } = useSingleSaleStore()
  const { userData } = useBarbershopStore()
  const isBlocked = usePremiumBlock()
  const { newRecurrentAppointment } = useAppointment()
  const [weekDays, setWeekDays] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [type, setType] = useState('month')
  const [weekDaySelectedIndex, setWeekDaySelectedIndex] = useState(0)

  const createAppointment = () => {
    const params = {
      barber_id: barber?.id,
      services: serviceArray?.map((i) => {
        return {
          service_id: i?.service_id,
          barber_service_id: i?.id,
          name: i?.name,
          price: i?.price,
        }
      }),
      start_hour: `${time}:00`,
      finish_hour: `${finishHour}:00`,
      start_date: startDate,
      end_date: endDate,
      type,
      barbershop_id: userData?.id,
    }
    if (
      differenceInDays(parseISO(startDate), new Date()) < 0 ||
      differenceInDays(parseISO(endDate), new Date()) < 0
    ) {
      toast({
        title: 'Data inicial e final devem ser maiores que a data de hoje',
        status: 'error',
        duration: 4000,
      })
      return
    }
    if (client.created) {
      params.name = client.client.name
      params.phone = client.client.user.phone
      params.email = client.client.user.email
    } else {
      params.client_id = client.client.id
    }

    if (type === 'week' || type === 'biweekly') {
      params.repeat_every_week_day = daysOfWeek[weekDaySelectedIndex]?.day
    }
    if (type === 'month') {
      params.simple_date = simpleDate
    }
    mutate.mutate({ params })
    setClickEmpty(false)
  }

  const mutate = useMutation('create-appointment', ({ params }) =>
    newRecurrentAppointment({
      params,
      onSuccess: () => {
        onClose()
        clearState()
      },
    })
  )

  const setSelected = (selectedIndex) => {
    const tempWeekDays = weekDays.map((item, index) => {
      item.selected = selectedIndex === index
      return item
    })
    setWeekDays(tempWeekDays)
    setWeekDaySelectedIndex(selectedIndex)
  }

  useEffect(() => {
    setWeekDays(daysOfWeek)
  }, [])

  const isDisabled = useMemo(() => {
    if (!startDate || !endDate) {
      return true
    }
    if (startDate < format(new Date(), 'yyyy-MM-dd')) {
      return true
    }
    if (endDate < startDate) {
      return true
    }

    if (finishHour < time) {
      return true
    }

    if (!barber || !serviceArray || !client) {
      return true
    }

    if (type === 'month' && (!date || !time)) {
      return true
    }

    if (
      type !== 'month' &&
      !weekDaySelectedIndex &&
      weekDaySelectedIndex !== 0
    ) {
      return true
    }

    return false
  }, [
    barber,
    client,
    date,
    endDate,
    finishHour,
    serviceArray,
    startDate,
    time,
    type,
    weekDaySelectedIndex,
  ])

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      px={isBlocked && theme.pxToRem(24)}
      pt={isBlocked && theme.pxToRem(24)}
      height="95%"
      pointerEvents={isBlocked && 'none'}
    >
      <Box mt={-4} pb={40}>
        <Text {...labelButtonProps}>{t('CUSTOMER')}</Text>

        {client ? (
          <ClientCard client={client} onClick={() => setClient(null)} />
        ) : (
          <Button
            size="block"
            kind="appointmentOptions"
            onClick={() => setStep(APPOINTMENT_STEPS.selectCustomer)}
          >
            {t('SELECT_CUSTOMER')}
          </Button>
        )}

        <Text {...labelButtonProps}>{t('BARBER')}</Text>

        {barber ? (
          <BarberCard
            barber={barber}
            onClick={() => {
              setBarber(null)
              setDate(null)
              setTime(null)
              setServiceArray([])
            }}
          />
        ) : (
          <Button
            size="block"
            kind="appointmentOptions"
            onClick={() => setStep(APPOINTMENT_STEPS.selectBarber)}
          >
            {t('SELECT_BARBER')}
          </Button>
        )}

        <Text {...labelButtonProps}>{t('_SERVICE')}</Text>

        <Box>
          {!!serviceArray.length &&
            serviceArray.map((item) => (
              <ServiceCard
                mb={theme.pxToRem(8)}
                service={item}
                onDelete={() =>
                  setServiceArray(serviceArray.filter((r) => r !== item))
                }
                date={date}
              />
            ))}
          <Button
            size="block"
            kind="appointmentOptions"
            onClick={() => {
              setStep(APPOINTMENT_STEPS.selectService)
            }}
            isDisabled={!barber}
            mb={4}
          >
            {t('SELECT_SERVICE')}
          </Button>
        </Box>
        <Flex
          mb={theme.pxToRem(42)}
          flexDir="row"
          w="100%"
          justify="space-between"
        >
          <TextInput
            label={t('START_TIME')}
            value={time}
            onChange={(e) => setTime(e.target.value)}
            type="time"
            mr={theme.pxToRem(12)}
          />

          <TextInput
            label={t('END_TIME')}
            value={finishHour}
            onChange={(e) => setFinishHour(e.target.value)}
            type="time"
            min={`${time}:00`}
            error={
              finishHour < time
                ? 'O horário do fim deve ser maior que o horário de início'
                : null
            }
          />
        </Flex>

        <Text {...labelButtonProps}>
          {t('Tipo de recorrência do agendamento')}
        </Text>

        <Flex mb={4} justifyContent="space-between" width="100%">
          {renderButton?.map((i, index) => (
            <Button
              kind={type === i?.type ? 'primary' : 'secondary'}
              size="medium"
              h={12}
              mr={index !== 2 && '2%'}
              onClick={() => {
                setDate(null)
                setType(i?.type)
              }}
            >
              {i?.text}
            </Button>
          ))}
        </Flex>
        {type !== 'month' && (
          <Flex flexDir="column" w="100%" mt="16px">
            <Text kind="semiBold" fontSize={14} mb={theme.pxToRem(16)}>
              {t('WEEK_DAY')}
            </Text>

            <Flex justify="space-between">
              {weekDays?.map((item, index) => (
                <WeekdayCard
                  w="13%"
                  h={theme.pxToRem(64)}
                  selected={index === weekDaySelectedIndex}
                  onClick={() => setSelected(index)}
                  key={item.day}
                >
                  {t(daysOfWeek[index]?.label)}
                </WeekdayCard>
              ))}
            </Flex>
          </Flex>
        )}

        {type === 'month' &&
          (date ? (
            <DateTimeCard
              date={date}
              recurrent={true}
              finishHour={finishHour}
              onClick={() => {
                setDate(null)
                setTime(null)
                setFinishHour(null)
              }}
            />
          ) : (
            <Button
              size="block"
              kind="appointmentOptions"
              onClick={() => setStep(APPOINTMENT_STEPS.selectDateAndTime)}
            >
              {t('SELECT_DATE')}
            </Button>
          ))}

        <Text {...labelButtonProps}>
          {t('Informe o período em que essa recorrência deve acontecer')}
        </Text>
        <Flex w="100%" justify="space-between">
          <TextInput
            noBorder
            type="date"
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            label={t('Data de início')}
            padding={6}
            mr={2}
            error={
              startDate < format(new Date(), 'yyyy-MM-dd')
                ? 'A data inicial deve ser maior ou igual a hoje'
                : null
            }
          />
          <TextInput
            noBorder
            type="date"
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            label={t('Data final')}
            padding={6}
            error={
              endDate < startDate
                ? 'A data final deve ser maior que a data inicial'
                : null
            }
          />
        </Flex>
      </Box>

      <ModalButtonBackground>
        <Button
          size="block"
          onClick={createAppointment}
          isLoading={mutate.isLoading || loadingSingleSale}
          isDisabled={isDisabled}
        >
          {t('CONFIRM')}
        </Button>
      </ModalButtonBackground>
    </Flex>
  )
}

export default AppointmentSelectOptions
