import * as React from 'react'

function SvgEmail(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 1H3a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2V3a2 2 0 00-2-2z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 3.001l9 6 9-6"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgEmail
