import React, { useState } from 'react'
import { Box, Flex, Text, Button } from 'components/atoms'
import { SelectCard } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useBarbershopStore } from 'services/stores'
import { useMutation } from 'react-query'
import { t } from 'services/translation'
import { useBarbershopSignature } from 'services/hooks/use-barbershop-signature'
import { useToast } from '@chakra-ui/react'

export const SignatureAppEnabled = () => {
  const { userData, setUserData } = useBarbershopStore()
  const {changeSubscriptionApp} = useBarbershopSignature()
  const [values, setValues] = useState(userData?.signature_app_club_enable)
  const toast = useToast()
  const mutate = useMutation(
    'change-subscription-app-config',
    (params) => changeSubscriptionApp(params),
    {
      onSuccess: () => {
        toast({
          title: 'Informações salvas com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        setUserData({...userData, signature_app_club_enable: values})
      },
      onError: () => {
        toast({
          title: 'Erro ao salvar informações',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
    }
  )

  const handleFormSubmit = async () => {
    const params = {
      signature_app_club_enable: values,
      barbershop_id: userData?.barbershop?.id || userData?.id
    }
    mutate.mutate({body: params})
  }

  return (
    <Flex flexDirection="column" h="100%">
      <Box flex="1">
        <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
          Gerenciamento do clube para clientes
        </Text>

        <Text fontSize={pxToRem(16)} marginBottom={pxToRem(12)}>
          Selecione se os seus clientes devem possuir acesso aos serviços do clube pelo aplicativo (aquisição e upgrade de planos e criação de agendamentos)
        </Text>

       <SelectCard item={{
        title: 'Habilitar',
        description: 'Seus clientes conseguirão agendar e fazer administração do plano.'
       }}
       selected={values}
       setSelected={() => setValues(!values)}
       borderWidth={2}
       borderColor='grey'
       />
      </Box>
      <Button
        disabled={mutate.isLoading}
        isLoading={mutate.isLoading}
        onClick={handleFormSubmit}
        mt={pxToRem(32)}
        size="block"
      >
        {t('SAVE_CHANGES')}
      </Button>
    </Flex>
  )
}
