import * as React from 'react'

function SvgBox(props) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.9091 25.8182C20.934 25.8182 25.8182 20.934 25.8182 14.9091C25.8182 8.88417 20.934 4 14.9091 4C8.88417 4 4 8.88417 4 14.9091C4 20.934 8.88417 25.8182 14.9091 25.8182Z"
        stroke="currentColor"
        strokeWidth="1.54545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2637 10.7051L10.7354 19.2334"
        stroke="currentColor"
        strokeWidth="1.54545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2637 19.2344L10.7354 10.7061"
        stroke="currentColor"
        strokeWidth="1.54545"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgBox
