import React, { useMemo, useState } from 'react'
import { useDisclosure, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { Text, ModalMenu, Flex } from 'components'
import InfoSection from './InfoSection'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { theme } from 'styles'
import { TeamModal } from '..'
import barberSrc from 'assets/img/placeholders/barber.png'
import { ServicesSection } from './ServicesSection'
import { ProductsSection } from './ProductsSection'
import { PermissionModal } from '..'
import { usePermissionCollaborator } from 'services/hooks'
import { useUserType } from 'services/hooks/use-user-type'

const tabProps = {
  fontSize: 16,
  fontWeight: 800,
  w: '25%',
  _selected: {
    color: '#E5B817',
    borderColor: '#E5B817',
    borderBottomWidth: 4,
  },
}

export const BarberModal = ({ barber, isModalOpen, onClose, onDelete, onRefresh }) => {
  const { t } = useTranslation()
  const team = usePermissionCollaborator('team')
  const [permissionModalOpen, setPermissionModalOpen] = useState(false)
  const type = useUserType()

  const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure()

  return (
    <ModalMenu
      isOpen={isModalOpen}
      size="xl"
      onClose={() => {
        onClose()
      }}
      headerStyle={{
        p: 0,
      }}
      bodyStyle={{
        p: 0,
      }}
    >
      {/* Foto e nome */}
      <Flex
        height={pxToRem(386)}
        backgroundImage={!barber.profile_image_url ? `url(${barberSrc})` : `url(${barber.profile_image_url})`}
        backgroundSize="cover"
        alignItems="flex-end"
        backgroundPosition="center center"
      >
        <Flex
          flex="1"
          alignItems="flex-end"
          justifyContent="flex-start"
          px="2vw"
          height={pxToRem(300)}
          bgGradient="linear-gradient(180deg, rgba(32, 36, 42, 0) 0%, rgba(32, 36, 42, 0.7) 40%, #20242A 100%)"
        >
          <Text kind="extraBold" fontSize={pxToRem(24)} mb={pxToRem(24)}>
            {barber.name}
          </Text>
        </Flex>
      </Flex>
      <Tabs px="2vw">
        <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={1} mb={theme.pxToRem(24)}>
          <Tab {...tabProps}>{t('INFORMATIONS')}</Tab>
          {!barber?.type && <Tab {...tabProps}>{t('SERVICES')}</Tab>}
          <Tab {...tabProps}>{t('PRODUCTS')}</Tab>
        </TabList>
        <TabPanels>
          {/* Tab INFO */}
          <TabPanel m={0} p={0}>
            <InfoSection
              onOpenEditModal={onOpenEditModal}
              onOpenPermissionModal={() => setPermissionModalOpen(true)}
              barber={barber}
              onDelete={onDelete}
            />
          </TabPanel>
          {!barber?.type && (
            <TabPanel m={0} p={0}>
              <ServicesSection barber={barber} />
            </TabPanel>
          )}
          <TabPanel m={0} p={0}>
            <ProductsSection barber={barber} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isEditModalOpen && (
        <TeamModal
          isEdit
          collaboratorData={barber}
          isModalOpen={isEditModalOpen}
          onClose={() => {
            onCloseEditModal()
            onClose()
            onRefresh()
          }}
        />
      )}
      {permissionModalOpen && (
        <PermissionModal
          collaboratorData={barber}
          isModalOpen={permissionModalOpen}
          onClose={() => {
            setPermissionModalOpen(false)
            onCloseEditModal()
            onClose()
            onRefresh()
          }}
        />
      )}
    </ModalMenu>
  )
}

BarberModal.propTypes = {
  barber: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
}
