import React, { useState, useEffect } from 'react'
import { Text, Icon, TextInput, Button } from 'components/atoms'
import { FieldControl } from 'components/molecules'
import { FieldArray, useFormikContext } from 'formik'

import { VStack, Select, HStack, Divider, IconButton } from '@chakra-ui/react'

import { useInvoiceIssuer } from 'services/hooks'
import { useQuery } from 'react-query'
import { getData } from 'services/storage'

const itemVoid = {
  tomador: {
    cpf: '',
  },
  servico: {
    aliquota: '',
    discriminacao: '',
    iss_retido: '',
    item_lista_servico: '',
    codigo_tributario_municipio: '',
    valor_servicos: '',
  },
}

const IssuerInvoiceItemCardField = ({ formik, item, index, isLoading, isDisabled, ...props }) => {
  const { getAllInvoiceIssuer } = useInvoiceIssuer()
  const [userLogged, setUserLogged] = useState({})

  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),
    { enabled: !!userLogged?.id, select: (res) => res.data[0] }
  )

  return (
    <VStack
      spacing={4}
      align="normal"
      bg="cardBackground"
      borderWidth={1}
      borderColor="modalBorder"
      borderRadius={6}
      p={4}
      key={index}
      {...props}
    >
      <HStack align="start">
        <FieldControl
          label="Alíquota"
          isLoading={isLoading}
          isDisabled={true}
          render={() => <TextInput name="aliquota" value={`${item.aliquota}%`} onChange={formik.handleChange} />}
        />
        <FieldControl
          label="discriminacao"
          isLoading={isLoading}
          isDisabled={true}
          render={() => <TextInput name={'Discriminacao'} value={item.discriminacao} onChange={formik.handleChange} />}
        />
      </HStack>

      <HStack align="start">
        <FieldControl
          label="ISS retido"
          isLoading={isLoading}
          isDisabled={true}
          render={() => (
            <TextInput
              name="iss_retido"
              value={!item.iss_retido ? 'Não retido' : 'Retido'}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Item do serviço"
          isLoading={isLoading}
          isDisabled={true}
          render={() => (
            <TextInput name={'item_lista_servico'} value={item.item_lista_servico} onChange={formik.handleChange} />
          )}
        />
      </HStack>
      <HStack align="start">
        <FieldControl
          label="Codigo tributário"
          isLoading={isLoading}
          isDisabled={true}
          render={() => (
            <TextInput
              name="codigo_tributario_municipio"
              value={item.codigo_tributario_municipio}
              onChange={formik.handleChange}
            />
          )}
        />
        <FieldControl
          label="Valor dos serviços"
          isLoading={isLoading}
          isDisabled={true}
          render={() => (
            <TextInput
              name={'valor_servicos'}
              mask="CURRENCY_MASK"
              value={item.valor_servicos}
              onChange={formik.handleChange}
            />
          )}
        />
      </HStack>
    </VStack>
  )
}

export const IssuerInvoiceServicesForm = ({ isLoading, isDisabled, ...props }) => {
  const formik = useFormikContext()

  return (
    <VStack spacing={4} align="normal" {...props}>
      <HStack spacing={4}>
        <Text fontSize="lg" kind="bold" whiteSpace="nowrap">
          Serviços
        </Text>
        <Divider borderColor="gray.600" />
      </HStack>

      <IssuerInvoiceItemCardField
        formik={formik}
        item={formik.values.servico}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />

      {/* <FieldArray
        name="service"
        render={(arrayHelpers) => {
        //   if (!formik.values.service) {
        //     return (
        //       <Button w="full" onClick={() => arrayHelpers.push(itemVoid)}>
        //         Adicionar
        //       </Button>
        //     )
        //   }

          return (
            <VStack spacing={4} align="normal">
                
            </VStack>
          )
        }}
      /> */}
    </VStack>
  )
}
