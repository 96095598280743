const currentPrefix = 'v3'

export const GET_BARBERSHOP_WITHOUT_LOCATION = skip =>
  `${currentPrefix}/client/barbershops-without-location?skip=${skip || 0}`

export const GET_BARBERSHOP_WITH_LOCATION = data =>
  `${currentPrefix}/client/barbershops-with-location?skip=${
    data?.skip || 0
  }&latitude=${data?.latitude}&longitude=${data?.longitude}`

export const GET_APPOINTMENTS_PAGINATED = skip =>
  `${currentPrefix}/client/appointments?skip=${skip || 0}`

export const ADD_APPOINTMENT_REVIEW = `${currentPrefix}/client/add-review`

export const GET_BARBERSHOPS_BY_NAME = skip =>
  `${currentPrefix}/client/barbershops-by-name?skip=${skip || 0}`
