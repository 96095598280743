import { onFetch } from 'utils/fetch'
import create from 'zustand'
import * as e from 'services/api/endpoints'
import { t } from 'services/translation'
import { useBarbershopStore } from './barbershop'
import { useAppointmentDetailStore } from './appointmentDetail'

export const useSingleSaleStore = create((set, get) => ({
  selectedSingleSale: null,
  setSelectedSingleSale: (selectedSingleSale) => set({ selectedSingleSale }),

  client: null,
  setClient: (client) => set({ client }),

  barber: null,
  setBarber: (barber) => set({ barber }),

  products: [],
  setProducts: (product) =>
    set((state) => ({
      products: [...state.products, product],
    })),
  removeProduct: (product) => {
    set((state) => ({
      products: state.products.filter(
        (item) => item?.index_id !== product?.index_id
      ),
    }))
  },
  nullProducts: () => set({ products: [] }),
  discount: null,
  setDiscount: (discount) => set({ discount }),

  setProductsFull: (products) => set({ products }),

  paymentMethod: [],
  setPaymentMethod: (paymentMethod) => set({ paymentMethod }),

  loading: false,
  setLoading: (loading) => set({ loading }),

  barbersList: [],
  setBarbersList: (barbersList) => set({ barbersList }),

  clientsList: [],
  setClientsList: (clientsList) => set({ clientsList }),

  productList: [],
  setProductList: (productList) => set({ productList }),

  onGetProducts: async (props) => {
    set({ loading: true })
    await onFetch({
      url: e.GET_ADDITIONAL_PRODUCTS(
        useBarbershopStore.getState().userData?.id
      ),
      method: 'get',
      ...props,
      message: {
        error: {
          title: t('ERROR_LOADING_PRODUCTS'),
        },
      },
      onCompleted: (data) => {
        set({ loading: false, productList: data })
      },
      onError: () => {
        set({ loading: false })
      },
    })
  },
  singleSaleCreated: null,
  onCreateSingleSale: async (props) => {
    set({ loading: true, singleSaleCreated: null })
    let newSingleSaleCreated = null
    onFetch({
      url: e.SINGLE_PRODUCT_POST,
      method: 'post',
      params: props?.params,
      toast: props?.toast,
      message: {
        success: {
          title: t('SINGLE_SALE_SUCCESS'),
          status: 'success',
        },
        error: {
          title: t('SINGLE_SALE_ERROR'),
          status: 'error',
        },
      },
      onCompleted: (data) => {
        get()?.clearState()
        props?.onClose?.()
        props?.onSuccess?.(data)
        newSingleSaleCreated = data
        set({ loading: false, singleSaleCreated: newSingleSaleCreated })
      },
      onError: (error) => {
        set({ loading: false })
      },
    })

    return newSingleSaleCreated
  },

  onGetSingleSale: async (props) => {
    set({ loading: true })
    onFetch({
      url: e.SINGLE_PRODUCT_GET(props?.id),
      method: 'get',
      toast: props?.toast,
      message: {
        success: {
          title: t('GET_SINGLE_SALE_SUCCESS'),
          status: 'success',
        },
        error: {
          title: t('GET_SINGLE_SALE_ERROR'),
          status: 'error',
        },
      },
      onCompleted: (data) => {
        get()?.clearState()
        props?.onClose()
        set({ loading: false, selectedSingleSale: data })
      },
      onError: (error) => {
        set({ loading: false })
      },
    })
  },

  onDeleteSingleSale: async (props) => {
    set({ loading: true })
    onFetch({
      url: e.SINGLE_PRODUCT_DELETE(props?.id),
      method: 'delete',
      ...props,
      message: {
        success: {
          title: t('SINGLE_SALE_DELETE_SUCCESS'),
        },
        error: {
          title: t('SINGLE_SALE_DELETE_ERROR'),
        },
      },
      onCompleted: (data) => {
        props?.onClose()
        set({ loading: false })
      },
      onError: (error) => {
        set({ loading: false })
      },
    })
  },

  clearState: () => {
    set({
      client: null,
      barber: null,
      products: [],
      discount: null,
      paymentMethod: [],
    })
    useAppointmentDetailStore.getState().setDiscount([])
  },
}))
