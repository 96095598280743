import React from 'react'

const Pix = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.6 22.0264L16.5867 18.0131C16.44 17.8664 16.2667 17.8397 16.1734 17.8397C16.08 17.8397 15.9067 17.8664 15.76 18.0131L11.7334 22.0397C11.28 22.4931 10.5734 23.2264 8.21338 23.2264L13.16 28.1597C13.91 28.9088 14.9267 29.3295 15.9867 29.3295C17.0467 29.3295 18.0634 28.9088 18.8134 28.1597L23.7734 23.2131C22.56 23.2131 21.5467 22.9731 20.6 22.0264ZM11.7334 9.95974L15.76 13.9864C15.8667 14.0931 16.0267 14.1597 16.1734 14.1597C16.32 14.1597 16.48 14.0931 16.5867 13.9864L20.5734 9.99974C21.52 9.01307 22.6 8.7864 23.8134 8.7864L18.8534 3.83974C18.1034 3.09067 17.0867 2.66992 16.0267 2.66992C14.9667 2.66992 13.95 3.09067 13.2 3.83974L8.25338 8.77307C10.6 8.77307 11.32 9.5464 11.7334 9.95974Z"
        fill="currentColor"
      />
      <path
        d="M28.1466 13.1338L25.1466 10.1204H23.4666C22.7466 10.1204 22.0266 10.4137 21.5333 10.9337L17.5333 14.9337C17.16 15.3071 16.6666 15.4937 16.1733 15.4937C15.6653 15.4866 15.1791 15.2864 14.8133 14.9337L10.7866 10.8937C10.28 10.3871 9.58665 10.0938 8.85331 10.0938H6.89331L3.83998 13.1604C3.09091 13.9104 2.67017 14.9271 2.67017 15.9871C2.67017 17.0471 3.09091 18.0637 3.83998 18.8137L6.89331 21.8804H8.86665C9.58665 21.8804 10.28 21.5871 10.8 21.0804L14.8266 17.0538C15.2 16.6804 15.6933 16.4937 16.1866 16.4937C16.68 16.4937 17.1733 16.6804 17.5466 17.0538L21.56 21.0671C22.0666 21.5737 22.76 21.8671 23.4933 21.8671H25.1733L28.1733 18.8538C28.9255 18.0902 29.345 17.06 29.34 15.9882C29.335 14.9165 28.906 13.8902 28.1466 13.1338Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Pix
