import * as React from 'react'

function SvgAddBarber(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M23.075.5c8.384 0 15.022 6.813 15.022 15.023 0 4.891-2.27 9.433-6.288 12.228 6.638 2.62 11.704 8.21 13.8 14.848.35 1.223-.175 2.445-1.398 2.795-1.222.35-2.445-.175-2.795-1.398-2.445-8.035-9.956-13.45-18.341-13.45-8.21 0-15.722 5.415-18.167 13.45-.35 1.223-1.573 1.747-2.795 1.398C.89 45.044.19 43.822.715 42.599c2.096-6.638 7.162-12.228 13.8-14.848-4.018-2.795-6.463-7.337-6.463-12.228C8.052 7.313 14.865.5 23.075.5zm0 4.542c-5.765 0-10.481 4.716-10.481 10.48 0 5.94 4.716 10.656 10.48 10.656 5.94 0 10.656-4.716 10.656-10.655 0-5.765-4.716-10.481-10.655-10.481z"
      />
      <path
        d="M51 19.5l6.333 6.5L70 13"
        stroke="currentColor"
        strokeWidth={3.55}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgAddBarber
