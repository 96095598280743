import React from 'react'
import { Flex, Text } from 'components/atoms'
import { ModalMenu, Table } from 'components/molecules'
import { t } from 'services/translation'
import { useTheme } from '@chakra-ui/react'
import { detailsColumns } from './settings'
import { formatCurrency } from 'utils/formatCurrency'

export const DetailGoalsModal = ({ isOpen, onClose, selectedBarber }) => {
  const theme = useTheme()

  return (
    <ModalMenu isOpen={isOpen} onClose={onClose} title="Resumo das metas" size="md">
      <Flex flexDirection="column" h="90%">
        <Flex flexDirection="row">
          <Text mr={theme.pxToRem(6)}>Barbeiro:</Text>
          <Text kind="bold" color="primary">
            {selectedBarber?.barber?.name}
          </Text>
        </Flex>
        <Flex mt={theme.pxToRem(24)} flexDirection="row">
          <Text mr={theme.pxToRem(6)}>Total em vendas:</Text>
          <Text kind="bold">{formatCurrency(selectedBarber?.total_value)}</Text>
        </Flex>
        <Flex mt={theme.pxToRem(24)} flexDirection="column" w="100%">
          <Table data={selectedBarber?.barber_goals ?? []} columns={detailsColumns} />
        </Flex>
      </Flex>
    </ModalMenu>
  )
}
