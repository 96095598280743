import * as Yup from 'yup'
import { t } from 'services/translation'

export const dataMainData = [
  {
    name: 'owner_name',
    type: 'string',
    noBorder: true,
    label: 'RESPONSABLED_NAME',
  },
  {
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'BARBERSHOP_NAME',
  },
  {
    name: 'secondary_identifier_doc',
    type: 'string',
    noBorder: true,
    label: 'CPF',
    mask: 'CPF_MASK',
  },
  {
    name: 'identifier_doc',
    type: 'string',
    noBorder: true,
    label: 'CNPJ',
    mask: 'CNPJ_MASK',
  },
]

export const validationMainData = Yup.object().shape({
  name: Yup.string().min(4, 'NAME').required('Nome do serviço é obrigatório'),
  owner_name: Yup.string()
    .min(4, 'NAME')
    .required('Nome do responsável é obrigatório'),
  identifier_doc: Yup.string(),
  secondary_identifier_doc: Yup.string()
    .length(14, 'CPF inválido!')
    .required('CPF é obrigatório!'),
})

export const initialMainData = {
  owner_name: '',
  name: '',
  identifier_doc: '',
  secondary_identifier_doc: '',
}
