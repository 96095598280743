import * as React from 'react'

function SvgShop(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 19h18M1 5v1a3 3 0 005.121 2.121C6.684 7.56 7 5.796 7 5M1 5h6M1 5h18M1 5l2-4h14l2 4M7 5c0 .796.316 2.559.879 3.121A3 3 0 0013 6M7 5h6v1m0 0a3 3 0 006 0V5M2.999 19V8.85M17.001 19V8.85M7 19v-4a2 2 0 012-2h2a2 2 0 012 2v4"
        stroke="currentColor"
        strokeWidth={0.9}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgShop
