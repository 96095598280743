import React from 'react'
import { useTheme } from '@chakra-ui/react'

import { Avatar, Box, Flex, Text, Icon } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import {format, parseISO} from 'date-fns'

export const RatingCard = ({ item, index }) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()

  return (
    <Box
      key={`data_rating_card_${index}`}
      width="full"
      height="fit-content"
      padding={theme.pxToRem(16)}
      marginBottom={theme.pxToRem(16)}
      bg="menuBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Flex alignItems="center" width="full" marginBottom={theme.pxToRem(24)}>
        <Avatar
          src={item?.client?.profile_image_url}
          size="medium"
          marginRight={theme.pxToRem(12)}
        />

        <Flex
          justifyContent={!isDesktop && 'space-between'}
          alignItems={isDesktop && 'center'}
          width="full"
        >
          <Flex
            flexDirection={isDesktop ? 'row' : 'column'}
            alignItems={isDesktop && 'center'}
          >
            <Text
              kind="bold"
              fontColor="textLight"
              fontSize={theme.pxToRem(14)}
              width={isDesktop && theme.pxToRem(160)}
            >
              {item?.client?.name}
            </Text>

            <Flex
              alignItems="center"
              marginRight={isDesktop && theme.pxToRem(24)}
            >
              <Text
                kind="bold"
                fontColor="textLight"
                fontSize={theme.pxToRem(14)}
              >
                {item?.rate.toFixed(1)}
              </Text>

              <Flex paddingX={isDesktop && theme.pxToRem(8)}>
                <Icon
                  name="Star"
                  fill={item?.rate >= 1 && 'primary'}
                  size={14}
                />
                <Icon
                  name="Star"
                  fill={item?.rate >= 2 && 'primary'}
                  size={14}
                />
                <Icon
                  name="Star"
                  fill={item?.rate >= 3 && 'primary'}
                  size={14}
                />
                <Icon
                  name="Star"
                  fill={item?.rate >= 4 && 'primary'}
                  size={14}
                />
                <Icon
                  name="Star"
                  fill={item?.rate === 5 && 'primary'}
                  size={14}
                />
              </Flex>
            </Flex>
          </Flex>

          <Text
            kind="medium"
            fontColor="textGrey"
            fontSize={theme.pxToRem(14)}
            width={isDesktop && theme.pxToRem(100)}
          >
            {format(parseISO(item?.date), 'dd/MM/yyyy')}
          </Text>
        </Flex>
      </Flex>

      <Text kind="medium" fontColor="textGrey" fontSize={theme.pxToRem(14)}>
        {item?.comment}
      </Text>
    </Box>
  )
}
