import * as Yup from 'yup'

//Minimum ------------------------------------------------------------------------
export const dataMinimum = [
    {
      name: 'minimum_cancel_time',
      type: 'time',
      noBorder: true,
      label: 'Tempo Mínimo (horas:minutos)',
    },
  ]
  
  export const validationMinimum = Yup.object().shape({
    minimum_cancel_time: Yup.string()
      .required('Tempo mínimo é obrigatório'),
  })
  
  export const initialMinimum = {
    minimum_cancel_time: '',
  }

  //Maximum ------------------------------------------------------------------------
export const dataMaximum = [
    {
      name: 'maximum_schedule_date',
      type: 'string',
      noBorder: true,
      label: 'Prazo Máximo (dias)',
    },
  ]
  
  export const validationMaximum = Yup.object().shape({
    maximum_schedule_date: Yup.string()
      .required('Tempo máximo é obrigatório'),
  })
  
  export const initialMaximum = {
    maximum_schedule_date: '',
  }

  export const dataMinumumHour = [
    {
      name: 'minimum_schedule_hour',
      type: 'string',
      noBorder: true,
      label: 'Prazo Mínimo (minutos)',
    },
  ]
  
  export const validationMinumumHour = Yup.object().shape({
    minimum_schedule_hour: Yup.string()
      .required('Tempo mínimo é obrigatório'),
  })
  
  export const initialMinumumHour = {
    minimum_schedule_hour: '',
  }