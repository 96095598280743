import React from 'react'
import { Image } from '@chakra-ui/react'
import { Flex } from 'components'
import { theme } from 'styles'

import appleStore from 'assets/img/stores/apple-black-white.png'
import googlePlay from 'assets/img/stores/google-black-white.png'

export const StoreIcons = () => {
  return (
    <Flex flexDir="row" align="flex-end" mt={theme.pxToRem(16)}>
      <Image
        src={appleStore}
        alt="israel-apple-store"
        width={theme.pxToRem(120)}
        mr={theme.pxToRem(12)}
        objectFit="contain"
        cursor="pointer"
        onClick={() =>
          window.open('https://apps.apple.com/br/app/bestbarbers/id1501336370')
        }
      />
      <Image
        src={googlePlay}
        alt="israel-google-play"
        width={theme.pxToRem(120)}
        objectFit="contain"
        cursor="pointer"
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=bestbarbers.app&hl=pt_BR&gl=US'
          )
        }
      />
    </Flex>
  )
}
