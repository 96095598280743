import axios from 'axios'
import create from 'zustand'
import { handleError } from 'services/utils'
import { getData } from 'services/storage'
import {
  INTERNAL_GET_USER_DATA,
  INTERNAL_UPDATE_PASSWORD,
  INTERNAL_INACTIVATE_USER,
  INTERNAL_GET_BY_NAME,
  INTERNAL_GET_BY_BARBERSHOP_ID,
  INTERNAL_GET_BY_USER_ID,
} from 'services/api/endpoints'
import { api } from 'services/api/config'

api.interceptors.request.use(
  async (config) => {
    const token = await getData('@BestBarbers:token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export const userManagementStore = create((set, get) => ({
  loading: false,
  userData: null,
  cleanUserData: () => set({ userData: null }),

  onGetUserData: async (data, toast, withLoading = true) => {
    if (withLoading) set({ loading: 'getUser' })
    try {
      const response = await api.get(INTERNAL_GET_USER_DATA(data))
      set({
        loading: false,
        userData: response?.data,
      })
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
          userData: null,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
    }
  },
  onGetByUserId: async (data, toast, withLoading = true) => {
    if (withLoading) set({ loading: 'getByUserId' })
    try {
      const response = await api.get(INTERNAL_GET_BY_USER_ID(data))
      set({
        loading: false,
        userData: response?.data,
      })
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
          userData: null,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
    }
  },
  onGetByBarbershopId: async (data, toast, withLoading = true) => {
    if (withLoading) set({ loading: 'getByBarbershopId' })
    try {
      const response = await api.get(INTERNAL_GET_BY_BARBERSHOP_ID(data))
      set({
        loading: false,
        userData: response?.data,
      })
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
          userData: null,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
    }
  },
  onGetByName: async (data, toast, withLoading = true) => {
    if (withLoading) set({ loading: 'getByName' })
    try {
      const response = await api.get(INTERNAL_GET_BY_NAME(data))
      set({
        loading: false,
        userData: response?.data,
      })
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
          userData: null,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
    }
  },
  onUpdatePassword: async (data, toast) => {
    try {
      await api.put(INTERNAL_UPDATE_PASSWORD(data?.user_id), { password: data?.password })
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: 'Senha alterada com sucesso! =D',
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error }
    }
  },
  onInactivateUser: async (params, toast, withLoading = true) => {
    if (withLoading) set({ loading: 'action' })
    try {
      await api.put(INTERNAL_INACTIVATE_USER(params?.id), {
        status: params?.status,
      })
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: `Barbearia ${params?.status === false ? 'ativada' : 'desativada'} com sucesso! =D`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: `Erro ao ${params?.status === false ? 'ativar' : 'desativada'} barbearia!`,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error: true }
    }
  },
  onUpdateBarbershopForm: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.put(`v3/internal-adm/barbershop-form/${data?.barbershop_id}`, data)
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: 'Dados alterados com sucesso! =D',
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error }
    }
  },
  onUpdateSignatureActive: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.put(`v3/internal-adm/signature-enable/${data?.barbershop_id}`, { status: data?.status })
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: 'Assinatura alterada com sucesso! =D',
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error }
    }
  },
  onUpdateNfActive: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.put(`v3/internal-adm/nf-enable/${data?.barbershop_id}`, { status: data?.status })
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: 'Nota fiscal alterada com sucesso! =D',
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error }
    }
  },
  onUpdateBarbershopInfo: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.put(`v3/internal-adm/update-barbershop-info/${data?.barbershop_id}`, data.data)
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: 'Dados alterados com sucesso! =D',
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error }
    }
  },
  onUpdateClientSignature: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.put(`v3/internal-adm/update-client-signature/${data?.signature_id}`, data.data)
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: 'Assinatura alterada com sucesso! =D',
          status: 'success',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { success: true }
    } catch (error) {
      await Promise.all([
        set({
          loading: false,
        }),
        toast({
          title: handleError(error).error,
          status: 'error',
          duration: 4000,
          isClosable: true,
        }),
      ])
      return { error }
    }
  },
  onUpdateClientSignatureDueDate: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.put(`v3/internal-adm/signature-due-date/${data?.signature_id}`, data.data)
      set({
        loading: false,
      })
      toast({
        title: 'Assinatura alterada com sucesso! =D',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      return { success: true }
    } catch (error) {
      set({
        loading: false,
      })
      toast({
        title: handleError(error).error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return { error }
    }
  },
  onAddUnityPromise: async (data, toast) => {
    set({ loading: 'action' })
    try {
      await api.post(`v3/register/new-unity-whitelabel/${data?.user_id}`)
      set({
        loading: false,
      })
      toast({
        title: 'Unidade criada com sucesso! =D',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      return { success: true }
    } catch (error) {
      set({
        loading: false,
      })
      toast({
        title: handleError(error).error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return { error }
    }
  },
}))
