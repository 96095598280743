import * as Yup from 'yup'
import { t } from 'services/translation'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles'
import { format } from 'date-fns'

export const inputs = [
  {
    name: 'title',
    type: 'string',
    noBorder: true,
    label: 'Título',
  },
  {
    name: 'value',
    type: 'string',
    noBorder: true,
    label: 'VALUE',
    mask: 'CURRENCY_MASK',
  },
  {
    name: 'due_at_date',
    type: 'date',
    noBorder: true,
    label: 'DATE',
  },
]

export const validation = Yup.object().shape({
  title: Yup.string().required('Título é obrigatório'),
  value: Yup.string().required('Valor é obrigatório'),
  due_at_date: Yup.string(),
})

export const initialValues = {
  title: '',
  value: '',
  due_at_date: format(new Date(), 'yyyy-MM-dd'),
  status: 'paid',
  payment_method: '',
}

export const selectProps = {
  name: 'product_category_id',
  placeholder: t('SELECT'),
  w: '100%',
  h: pxToRem(56),
  fontFamily: 'Montserrat Bold',
  textColor: 'textLight',
  iconColor: 'primary',
  backgroundColor: 'cardBackground',
  borderWidth: 1,
  borderColor: 'modalBorder',
  borderRadius: pxToRem(8),
  errorBorderColor: 'baseRed',
  optStyle: {
    backgroundColor: colors.menuBackground,
    fontFamily: 'Montserrat Medium',
  },
}
