import React from 'react'
import { Image } from '@chakra-ui/react'
import { Text, Flex } from 'components'
import { theme } from 'styles'
import { useBreakpoint } from 'services/hooks'

import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { StoreIcons } from './stores'

export const Footer = () => {
  const { isDesktop } = useBreakpoint()
  if (!isDesktop) {
    return (
      <Flex w="100vw" bgColor="black" py={'4vh'} px="10vw" justify="space-between" flexDir="column">
        <Image src={bestBarbersLogo} w={theme.pxToRem(120)} mb={theme.pxToRem(4)} />
        <Text cursor="pointer" kind="medium" fontSize={theme.pxToRem(12)}>
          © 2019 BestBarbers. All rights reserved.
        </Text>
      </Flex>
    )
  }
  return (
    <Flex w="100vw" bgColor="black" p={theme.pxToRem(60)} pl={'10vw'} pr="10vw" justify="space-between">
      <Image src={bestBarbersLogo} w={theme.pxToRem(140)} h={theme.pxToRem(30)} mr={theme.pxToRem(40)} />
      <Flex flexDir="column" mr={theme.pxToRem(40)}>
        <Text cursor="pointer" kind="bold" fontSize={theme.pxToRem(16)}>
          BestBarbers
        </Text>
        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          Barbearias e barbeiros
        </Text>

        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          Clientes
        </Text>
        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          Nossos planos
        </Text>
      </Flex>
      <Flex flexDir="column" mr={theme.pxToRem(40)}>
        <Text cursor="pointer" kind="bold" fontSize={theme.pxToRem(16)}>
          Transparência
        </Text>
        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          Termos de uso
        </Text>

        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          Políticas de privacidade
        </Text>
      </Flex>
      <Flex flexDir="column" mr={theme.pxToRem(40)}>
        <Text cursor="pointer" kind="bold" fontSize={theme.pxToRem(16)}>
          Fale conosco
        </Text>
        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          +55 31 97168-4757
        </Text>

        <Text cursor="pointer" kind="medium" color="textMedium" fontSize={theme.pxToRem(14)} mt={theme.pxToRem(6)}>
          help@bestbarbers.app
        </Text>
      </Flex>
      <Flex flexDir="column">
        <Text cursor="pointer" kind="bold" fontSize={theme.pxToRem(16)}>
          Baixe nosso app
        </Text>
        <StoreIcons />
      </Flex>
    </Flex>
  )
}
