export const internalMenuOptions = [
  // {
  //   id: '01',
  //   title: 'Por Data',
  //   icon: 'Calendar',
  //   path: '/internal-adm/new-barbershops'
  // },
  // {
  //   id: '02',
  //   title: 'Ativas',
  //   icon: 'AddBarber',
  //   path: '/internal-adm/active'
  // },
  // {
  //   id: '04',
  //   title: 'Por Agendamentos',
  //   icon: 'Star',
  //   path: '/internal-adm/barbershops-by-appointments'
  // },
  // {
  //   id: '05',
  //   title: 'Últimos meses',
  //   icon: 'Calendar',
  //   path: '/internal-adm/new-barbershops-last-months'
  // },
  // {
  //   id: '06',
  //   title: 'Novos Usuários Cadastrados',
  //   icon: 'People',
  //   path: '/internal-adm/new-registers'
  // },
  {
    id: '07',
    title: 'Gerenciamento de Usuários',
    icon: 'Barber',
    path: '/internal-adm/users-management',
  },
  {
    id: '08',
    title: 'Importar Clientes de assinatura',
    icon: 'Person',
    path: '/internal-adm/import-clients',
  },
  {
    id: '08',
    title: 'Importar Clientes normais',
    icon: 'Person',
    path: '/internal-adm/import-normal-clients',
  },
  {
    id: '09',
    title: 'Cadastrar barbearia na Asaas',
    icon: 'Shop',
    path: '/internal-adm/create-asaas-barbershop',
  },
  {
    id: '10',
    title: 'Status documentos da barbearia na Asaas',
    icon: 'Shop',
    path: '/internal-adm/verify-asaas-barbershop',
  },
  {
    id: '11',
    title: 'Estornar cobrança na Asaas',
    icon: 'Shop',
    path: '/internal-adm/refund-asaas-payments',
  },
  {
    id: '12',
    title: 'BestNews',
    icon: 'Notification',
    path: '/internal-adm/best-news',
  },
  {
    id: '13',
    title: 'Modificações de planos',
    icon: 'Scissors',
    path: '/internal-adm/planos',
  },
  // {
  //   id: '08',
  //   title: 'Meu Perfil',
  //   icon: 'GestaoFacil',
  //   path: '/internal-adm/profile'
  // },
]
