import { api } from 'services/api/config'

export const useSingleProductsSale = () => {
  const createSingleSaleForBarber = async (data) => {
    const response = await api.post(`v3/single-products/sale`, data)
    return response.data
  }
  const createSingleSaleForClient = async (data) => {
    const response = await api.post(`v3/single-products/client/sale`, data)
    return response.data
  }

  return {
    createSingleSaleForBarber,
    createSingleSaleForClient,
  }
}
