import React from 'react'
import PropTypes from 'prop-types'
import { Avatar as ChakraAvatar, useTheme } from '@chakra-ui/react'
import clientSrc from 'assets/img/placeholders/client.png'
import barberSrc from 'assets/img/placeholders/barber.png'
import barbershopSrc from 'assets/img/placeholders/barbershop.png'
import serviceSrc from 'assets/img/placeholders/service.png'

const sizes = {
  small: {
    size: 12,
    br: 6,
  },
  mobile: {
    size: 24,
    br: 6,
  },
  medium: {
    size: 46,
    br: 8,
  },
  large: {
    size: 64,
    br: 8,
  },
  menu: {
    size: 56,
    br: 12,
  },
}

const typesSrc = {
  client: clientSrc,
  barber: barberSrc,
  barbershop: barbershopSrc,
  service: serviceSrc,
}

export const Avatar = ({ size, src, type, ...props }) => {
  const theme = useTheme()
  const parseSize = theme.pxToRem(sizes[size]?.size || size)
  return (
    <ChakraAvatar
      {...props}
      src={src || typesSrc[type]}
      h={parseSize}
      w={parseSize}
      borderRadius={theme.pxToRem(sizes[size]?.br || props?.br || 24)}
      bg="cardBackground"
    />
  )
}

Avatar.defaultProps = {
  size: 'small',
  type: 'barbershop',
}

Avatar.propTypes = {
  ...ChakraAvatar.propTypes,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}
