import React from 'react'
import { Text } from 'components/atoms'
import { Flex, useTheme } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'
import { t } from 'services/translation'

export const PaymentMethodResumeCard = ({
  last,
  width,
  item,
  mb,
  wMobile,
  total,
  full,
}) => {
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      flexDir="row"
      paddingLeft={theme.pxToRem(16)}
      mb={!isDesktop ? theme.pxToRem(16) : theme.pxToRem(mb)}
      mr={!last && theme.pxToRem(16)}
      w={
        isDesktop
          ? width || '100%'
          : wMobile
          ? theme.pxToRem(wMobile)
          : full
          ? 'full'
          : theme.pxToRem(140)
      }
      h={theme.pxToRem(56)}
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
    >
      <Flex flexDir="column">
        <Text fontSize={theme.pxToRem(14)} color="textGrey">
          {item?.payment_method?.name}
        </Text>
        <Text fontSize={theme.pxToRem(12)} color="textLight">
          {item?.quantity} de {total} {t('APPOINTMENTS')}
        </Text>
      </Flex>
      <Text
        kind="extraBold"
        mr={theme.pxToRem(16)}
        fontSize={theme.pxToRem(16)}
        color="white"
      >
        {((item?.quantity / total) * 100).toFixed(2)}%
      </Text>
    </Flex>
  )
}
