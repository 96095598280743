import create from 'zustand'
import { getData, saveData } from 'services/storage'

import * as Yup from 'yup'

export const invoiceDataSchema = Yup.object().shape({
  helpers: Yup.object().shape({
    carrierType: Yup.string(),
    addRecipientData: Yup.boolean(),
    recipientType: Yup.string(),
    showAdvancedFields: Yup.boolean(),
    sendClientAddress: Yup.boolean(),
    additionalData: Yup.object().test(
      'test-json',
      'Este campo deve ser um json válido',
      (field, context) => !context.parent.showAdvancedFields || !field || !field.error
    ),
  }),

  natureza_operacao: Yup.string().required('Este campo é obrigatório'),
  presenca_comprador: Yup.string().required('Este campo é obrigatório'),
  modalidade_frete: Yup.string().required('Este campo é obrigatório'),
  local_destino: Yup.string().required('Este campo é obrigatório'),
  informacoes_adicionais_contribuinte: Yup.string(),

  // IssuerInvoiceItemsForm
  itens: Yup.array().of(
    Yup.object().shape({
      numero_item: Yup.string().required('Este campo é obrigatório'),
      codigo_produto: Yup.string().required('Este campo é obrigatório'),
      descricao: Yup.string().required('Este campo é obrigatório'),
      codigo_ncm: Yup.string()
        .required('Este campo é obrigatório')
        .test('test-invalide', 'O campo está incompleto', (field) => field && !field.includes('_')),
      cfop: Yup.string()
        .required('Este campo é obrigatório')
        .test('test-invalide', 'O campo está incompleto', (field) => field && !field.includes('_')),
      quantidade_comercial: Yup.string().required('Este campo é obrigatório'),
      quantidade_tributavel: Yup.string().required('Este campo é obrigatório'),
      valor_bruto: Yup.string().required('Este campo é obrigatório'),
      valor_desconto: Yup.string(),
      valor_unitario_comercial: Yup.string().required('Este campo é obrigatório'),
      unidade_comercial: Yup.string().required('Este campo é obrigatório'),
      valor_unitario_tributavel: Yup.string().required('Este campo é obrigatório'),
      unidade_tributavel: Yup.string().required('Este campo é obrigatório'),
      icms_origem: Yup.string().required('Este campo é obrigatório'),
      icms_situacao_tributaria: Yup.string().required('Este campo é obrigatório'),
      icms_aliquota: Yup.string().test(
        'test-required',
        'Este campo é obrigatório',
        (field, context) => context.parent.icms_situacao_tributaria !== '00' || field
      ),
      icms_base_calculo: Yup.string().test(
        'test-required',
        'Este campo é obrigatório',
        (field, context) => context.parent.icms_situacao_tributaria !== '00' || field
      ),
      icms_modalidade_base_calculo: Yup.string().test(
        'test-required',
        'Este campo é obrigatório',
        (field, context) => context.parent.icms_situacao_tributaria !== '00' || field
      ),
    })
  ),

  // IssuerInvoicePaymentForm
  formas_pagamento: Yup.array().of(
    Yup.object().shape({
      forma_pagamento: Yup.string().required('Este campo é obrigatório'),
      valor_pagamento: Yup.string().required('Este campo é obrigatório'),
      // bandeira_operadora: Yup.string().test(
      //   'test-required',
      //   'Este campo é obrigatório',
      //   (field, context) => !['03', '04'].includes(context.parent.forma_pagamento) || field
      // ),
      // tipo_integracao: Yup.string().required('Este campo é obrigatório'),
      // cnpj_credenciadora: Yup.string()
      //   .test(
      //     'test-required',
      //     'Este campo é obrigatório',
      //     (field, context) => context.parent.tipo_integracao !== '1' || field
      //   )
      //   .test(
      //     'test-invalide',
      //     'O campo está incompleto',
      //     (field, context) => context.parent.tipo_integracao !== '1' || (field && !field.includes('_'))
      //   ),
      // numero_autorizacao: Yup.string()
      //   .test(
      //     'test-required',
      //     'Este campo é obrigatório',
      //     (field, context) => context.parent.tipo_integracao !== '1' || field
      //   )
      //   .test(
      //     'test-invalide',
      //     'O campo está incompleto',
      //     (field, context) => context.parent.tipo_integracao !== '1' || (field && !field.includes('_'))
      //   ),
    })
  ),

  // IssuerInvoiceCarrierForm
  nome_transportador: Yup.string().test(
    'test-required',
    'Este campo é obrigatório',
    (field, context) => context.parent.presenca_comprador !== '4' || field
  ),
  cnpj_transportador: Yup.string()
    .test(
      'test-required',
      'Este campo é obrigatório',
      (field, context) =>
        context.parent.presenca_comprador !== '4' || (context.parent.helpers?.carrierType === 'PF' ? true : field)
    )
    .test(
      'test-invalide',
      'O campo está incompleto',
      (field, context) => context.parent.presenca_comprador !== '4' || (field && !field.includes('_'))
    ),
  cpf_transportador: Yup.string()
    .test(
      'test-required',
      'Este campo é obrigatório',
      (field, context) =>
        context.parent.presenca_comprador !== '4' || (context.parent.helpers?.carrierType === 'PJ' ? true : field)
    )
    .test(
      'test-invalide',
      'O campo está incompleto',
      (field, context) => context.parent.presenca_comprador !== '4' || (field && !field.includes('_'))
    ),
  inscricao_estadual_transportador: Yup.string().test(
    'test-required',
    'Este campo é obrigatório',
    (field, context) => context.parent.presenca_comprador !== '4' || field
  ),
  endereco_transportador: Yup.string().test(
    'test-required',
    'Este campo é obrigatório',
    (field, context) => context.parent.presenca_comprador !== '4' || field
  ),
  municipio_transportador: Yup.string().test(
    'test-required',
    'Este campo é obrigatório',
    (field, context) => context.parent.presenca_comprador !== '4' || field
  ),
  uf_transportador: Yup.string().test(
    'test-required',
    'Este campo é obrigatório',
    (field, context) => context.parent.presenca_comprador !== '4' || field
  ),

  // IssuerInvoiceRecipientForm
  nome_destinatario: Yup.string(),
  cpf_destinatario: Yup.string(),
  telefone_destinatario: Yup.string(),
  cnpj_destinatario: Yup.string(),
  indicador_inscricao_estadual_destinatario: Yup.string(),
  cep_destinatario: Yup.string(),
  uf_destinatario: Yup.string(),
  municipio_destinatario: Yup.string(),
  bairro_destinatario: Yup.string(),
  logradouro_destinatario: Yup.string(),
  numero_destinatario: Yup.string(),
})

export const invoiceDataServiceSchema = Yup.object().shape({
  issuer_id: Yup.number().required('Este campo é obrigatório'),
  tomador: Yup.object().shape({
    cpf: Yup.string().required('Este campo é obrigatório').length(11),
  }),
  servico: Yup.object().shape({
    aliquota: Yup.number().required('Este campo é obrigatório'),
    discriminacao: Yup.string().required('Este campo é obrigatório'),
    iss_retido: Yup.boolean().required('Este campo é obrigatório'),
    item_lista_servico: Yup.string().required('Este campo é obrigatório'),
    codigo_tributario_municipio: Yup.string().required('Este campo é obrigatório'),
    valor_servicos: Yup.number().required('Este campo é obrigatório'),
  }),
})

const invoiceServiceDataVoid = {
  issuer_id: '',
  tomador: {
    cpf: '',
  },
  servico: {
    aliquota: '',
    discriminacao: '',
    iss_retido: '',
    item_lista_servico: '0601', // conferir
    codigo_tributario_municipio: '', // banco
    valor_servicos: '',
  },
}

const invoiceDataVoid = {
  helpers: {
    carrierType: '',
    addRecipientData: false,
    recipientType: '',
    showAdvancedFields: false,
    additionalData: '{}',
    sendClientAddress: false,
  },

  natureza_operacao: '',
  presenca_comprador: '',
  modalidade_frete: '',
  local_destino: '',
  informacoes_adicionais_contribuinte: '',

  // IssuerInvoiceItemsForm
  itens: [
    {
      numero_item: '1',
      codigo_produto: '',
      descricao: '',
      codigo_ncm: '',
      cfop: '',
      quantidade_comercial: '',
      quantidade_tributavel: '',
      valor_bruto: '',
      valor_desconto: '',
      valor_unitario_comercial: '',
      unidade_comercial: '',
      valor_unitario_tributavel: '',
      unidade_tributavel: '',
      icms_origem: '',
      icms_situacao_tributaria: '',
      icms_aliquota: '',
      icms_base_calculo: '',
      icms_modalidade_base_calculo: '',
    },
  ],

  // IssuerInvoicePaymentForm
  formas_pagamento: [
    {
      forma_pagamento: '',
      valor_pagamento: '',
      bandeira_operadora: '',
      tipo_integracao: '',
      cnpj_credenciadora: '',
      numero_autorizacao: '',
    },
  ],

  // IssuerInvoiceCarrierForm
  nome_transportador: '',
  cnpj_transportador: '',
  cpf_transportador: '',
  inscricao_estadual_transportador: '',
  endereco_transportador: '',
  municipio_transportador: '',
  uf_transportador: '',

  // IssuerInvoiceRecipientForm
  nome_destinatario: '',
  cpf_destinatario: '',
  telefone_destinatario: '',
  cnpj_destinatario: '',
  indicador_inscricao_estadual_destinatario: '',
  cep_destinatario: '',
  uf_destinatario: '',
  municipio_destinatario: '',
  bairro_destinatario: '',
  logradouro_destinatario: '',
  numero_destinatario: '',
}

export const useInvoiceData = create((set, get) => ({
  issueInvoice: false,
  setIssueInvoice(issueInvoice) {
    saveData('@BestBarbers:issueInvoice', issueInvoice)
    set({ issueInvoice })
  },

  invoiceErrorsLength: 0,
  setInvoiceErrorsLength(invoiceErrorsLength) {
    saveData('@BestBarbers:invoiceErrorsLength', invoiceErrorsLength)
    set({ invoiceErrorsLength })
  },

  invoiceErrors: {},
  setInvoiceErrors(invoiceErrors = {}) {
    saveData('@BestBarbers:invoiceErrors', invoiceErrors)
    set({
      invoiceErrors,
      invoiceErrorsLength: Object.keys(invoiceErrors).length,
    })
  },

  invoiceServiceData: invoiceServiceDataVoid,
  async setInvoiceServiceData(invoiceServiceData) {
    saveData('@BestBarbers:invoiceServiceData', invoiceServiceData)
    set({ invoiceServiceData })

    try {
      await invoiceDataServiceSchema.validate(invoiceServiceData, { abortEarly: false })
      const invoiceErrors = {}
      const invoiceErrorsLength = 0

      saveData('@BestBarbers:invoiceErrors', invoiceErrors)
      saveData('@BestBarbers:invoiceErrorsLength', invoiceErrorsLength)
      set({ invoiceErrors, invoiceErrorsLength })
    } catch (err) {
      const errosArray = err.inner.map((inner) => [inner.path, inner.errors])
      if (!errosArray.length) return

      const invoiceErrors = Object.fromEntries(errosArray)
      const invoiceErrorsLength = Object.keys(invoiceErrors).length
      const oldInvoiceErrosLength = await getData('@BestBarbers:invoiceErrorsLength')
      const oldInvoiceErros = await getData('@BestBarbers:invoiceErrors')

      saveData('@BestBarbers:invoiceErrors', { ...oldInvoiceErros, invoiceErrors })
      saveData('@BestBarbers:invoiceErrorsLength', { ...oldInvoiceErrosLength, invoiceErrorsLength })
      set({
        invoiceErrors: { ...oldInvoiceErros, invoiceErrors },
        invoiceErrorsLength: { ...oldInvoiceErrosLength, invoiceErrorsLength },
      })
    }
  },

  invoiceData: invoiceDataVoid,
  async setInvoiceData(invoiceData) {
    saveData('@BestBarbers:invoiceData', invoiceData)
    set({ invoiceData })
    try {
    await invoiceDataSchema.validate(invoiceData, { abortEarly: false })
      const invoiceErrors = {}
      const invoiceErrorsLength = 0

      saveData('@BestBarbers:invoiceErrors', invoiceErrors)
      saveData('@BestBarbers:invoiceErrorsLength', invoiceErrorsLength)
      set({ invoiceErrors, invoiceErrorsLength })
    } catch (err) {
      const errosArray = err.inner.map((inner) => [inner.path, inner.errors])
      if (!errosArray.length) return

      const invoiceErrors = Object.fromEntries(errosArray)
      const invoiceErrorsLength = Object.keys(invoiceErrors).length

      const oldInvoiceErrosLength = await getData('@BestBarbers:invoiceErrorsLength')
      const oldInvoiceErros = await getData('@BestBarbers:invoiceErrors')

      saveData('@BestBarbers:invoiceErrors', { ...oldInvoiceErros, invoiceErrors })
      saveData('@BestBarbers:invoiceErrorsLength', { ...oldInvoiceErrosLength, invoiceErrorsLength })
      set({
        invoiceErrors: { ...oldInvoiceErros, invoiceErrors },
        invoiceErrorsLength: { ...oldInvoiceErrosLength, invoiceErrorsLength },
      })
    }
  },
  clearInvoiceData() {
    saveData('@BestBarbers:invoiceData', invoiceDataVoid)
    set({ invoiceData: invoiceDataVoid, invoiceServiceData: invoiceServiceDataVoid })
  },

  _submitInvoice: false,
  submitInvoice() {
    const { invoiceErrors, invoiceErrorsLength, issueInvoice } = get()
    if (invoiceErrorsLength) {
      return {
        submitted: false,
        code: 'submit_invoice_form_erro',
        message: 'A emissão de nota fiscal não está habilitada',
        errors: invoiceErrors,
      }
    }

    if (!issueInvoice) {
      return {
        submitted: false,
        code: 'submit_invoice_inactive',
        message: 'A emissão de nota fiscal não está habilitada',
      }
    }

    set({ _submitInvoice: true })
    setTimeout(() => set({ _submitInvoice: false }), 2000)

    return {
      submitted: true,
      code: 'submit_invoice_success',
      message: 'A emissão de nota fiscal foi realizada com sucesso',
    }
  },

  appointmentData: null,
  setAppointmentData: (appointmentData) => set({ appointmentData }),
}))
