import React, { useState } from 'react'
import { Table, ModalMenu, Button, Flex } from 'components'
import { useQuery, useMutation } from 'react-query'
import { t } from 'services/translation'
import { useBarbershopStore } from 'services/stores'
import { useBarbershop, useBarbershopSubscription } from 'services/hooks'
import { columns } from './settings'

export const SelectServiceModal = ({
  isModalOpen,
  client_id,
  barbershop_id,
  onClose,
}) => {
  const { userData } = useBarbershopStore()
  const [service, setService] = useState()
  const { getServices } = useBarbershop()
  const { changeSignature } = useBarbershopSubscription()

  const { data, isLoading } = useQuery('get-services', () =>
    getServices({ id: userData?.id })
  )

  const mutate = useMutation('mutate-reactive', (params) =>
    changeSignature(params)
  )

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={onClose}
      title={t('SELECT_SERVICE')}
    >
      <Flex flexDir="column" justify="space-between" h="100%">
        <Table
          withoutHeader
          data={data?.filter((item) => item?.type === 'signature') || []}
          loading={isLoading}
          columns={columns}
          isSelectableRow
          onSelect={setService}
          selected={service}
        />
        <Button
          w="100%"
          type="submit"
          onClick={() =>
            mutate.mutate({
              client_id,
              barbershop_id,
              params: {
                value: Number(service?.price),
                description: service?.id,
              },
            })
          }
        >
          {t('CHANGE_SIGNATURE')}
        </Button>
      </Flex>
    </ModalMenu>
  )
}
