import * as React from 'react'

function Info(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00016 13.333C9.41465 13.333 10.7712 12.7711 11.7714 11.7709C12.7716 10.7707 13.3335 9.41416 13.3335 7.99967C13.3335 6.58519 12.7716 5.22863 11.7714 4.22844C10.7712 3.22824 9.41465 2.66634 8.00016 2.66634C6.58567 2.66634 5.22912 3.22824 4.22893 4.22844C3.22873 5.22863 2.66683 6.58519 2.66683 7.99967C2.66683 9.41416 3.22873 10.7707 4.22893 11.7709C5.22912 12.7711 6.58567 13.333 8.00016 13.333ZM8.00016 14.6663C4.31816 14.6663 1.3335 11.6817 1.3335 7.99967C1.3335 4.31767 4.31816 1.33301 8.00016 1.33301C11.6822 1.33301 14.6668 4.31767 14.6668 7.99967C14.6668 11.6817 11.6822 14.6663 8.00016 14.6663ZM8.00016 7.33301C7.63197 7.33301 7.3335 7.63148 7.3335 7.99967V10.6663C7.3335 11.0345 7.63197 11.333 8.00016 11.333C8.36835 11.333 8.66683 11.0345 8.66683 10.6663V7.99967C8.66683 7.63148 8.36835 7.33301 8.00016 7.33301ZM7.3335 5.33301C7.3335 4.96482 7.63197 4.66634 8.00016 4.66634C8.36835 4.66634 8.66683 4.96482 8.66683 5.33301C8.66683 5.7012 8.36835 5.99967 8.00016 5.99967C7.63197 5.99967 7.3335 5.7012 7.3335 5.33301Z"
        fill="#AEB0B2"
      />
    </svg>
  )
}

export default Info
