import { Text } from "components/atoms"

export const APPOINTMENT_STEPS = {
  initialStep: 'NEW_APPOINTMENTS',
  singleSale: 'SINGLE_SALE',
  blockedTimes: 'BLOCKED_TIMES',
  editAppointment: 'EDIT_APPOINTMENT',
  selectCustomer: 'SELECT_CUSTOMER',
  selectBarber: 'SELECT_BARBER',
  selectService: 'SELECT_SERVICE',
  selectDateAndTime: 'SELECT_DATE_TIME',
  selectProducts: 'SELECT_PRODUCTS',
  selectDiscount: 'SELECT_DISCOUNT',
  selectDiscountBarber: 'SELECT_DISCOUNT_BARBER',
  selectPaymentMethod: 'SELECT_PAYMENT_METHOD',
}

export const columnsSignature = (services) => [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item?.name}
      </Text>
    ),
  },
  {
    key: 'time_required',
    label: 'LENGTH',
    render: (item) => <Text>Tempo: {item?.service?.time_required?.substring(0, 5)}</Text>,
  },
  {
    key: 'quantity',
    label: 'QUANTITY',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item?.quantity && services && services?.length > 0 ? `Qtd restante: ${services?.find(s => s?.service_id === item?.service_id)?.quantity}` : '' }
      </Text>
    ),
  },
]
