import React, { useEffect, useState } from 'react'
import { useBoolean, useToast, useTheme, Switch, Spinner } from '@chakra-ui/react'
import { Layout, Table, MobileList, TeamCard, TextInput, PremiumBlockMessage, Text, Flex, Icon } from 'components'
import { BarberModal, TeamModal, ActionModal } from 'components/organisms'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useBreakpoint } from 'services/hooks'
import { Search } from 'assets/icons'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { formatPhone } from 'utils/phone-formater'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { BARBERS, BARBERSHOP_DELETE_BARBER } from 'services/api/endpoints'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { usePermissionCollaborator } from 'services/hooks'
import { useBarbershop } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { CreateGoalModal } from 'components-v2/organisms/BarberGoals/CreateGoals'
import { useBarber } from 'services/hooks/use-barber'

export const TeamScreen = ({ ...props }) => {
  const toast = useToast()
  const theme = useTheme()
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const isBlocked = usePremiumBlock()
  const [selectedBarber, selectedBarberSet] = useState({})
  const [barbersList, setBarbersList] = useState({ data: [], filtered: [] })
  const [query, setQuery] = useState('')
  const [goalModalOpen, setGoalModalOpen] = useState(false)
  const [visible, setVisible] = useState([])
  const { changeBarberVisible } = useBarbershop()
  const { onLogout } = useLogoutPaymentIssues()
  const [sections, setSections] = useState({
    team: true,
    goals: true,
  })
  const { updateBarberOrder } = useBarber()

  useEffect(() => {
    onLogout()
  }, [])
  const [addBarberModalOpen, { on: addBarberModalOn, off: addBarberModalOff }] = useBoolean(false)

  const mutate = useMutation('change-barber-visible', (params) => changeBarberVisible(params))

  function SectionHeader({ onClick, isOpen, title }) {
    return (
      <Flex onClick={onClick} cursor="pointer" align="center" mt={theme.pxToRem(12)}>
        <Text fontSize={18} kind="bold">
          {t(title)}
        </Text>
        <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} color="white" size={18} ml="4px" />
      </Flex>
    )
  }

  const handleChangeVisible = (key, value) => {
    const find = visible?.filter((i) => i.id !== key)
    setVisible([...find, { id: key, value }])
    mutate.mutate({ params: { barber_id: key, visible_for_clients: value } })
  }

  const [viewBarberModalOpen, { on: viewBarberModalOn, off: viewBarberModalOff }] = useBoolean(false)

  const [deleteBarberModalOpen, { on: deleteBarberModalOn, off: deleteBarberModalOff }] = useBoolean(false)

  const { userData } = useBarbershopStore()
  const permission = usePermissionCollaborator('team')

  const mutateOrder = useMutation('delete-barber', (params) => updateBarberOrder(params), {
    onSuccess: async () => {
      toast({
        title: 'Ordem atualizada com sucesso',
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      refetch()
    },
    onError: (error) => {
      toast({
        title: 'Erro ao atualizar ordem',
        description: error.toString().substring(7),
        status: 'danger',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const BARBER_COLUMNS = [
    {
      key: 'order',
      label: 'ORDENAR',
      type: 'order',
      width: 50,
      render: (item, index) => {
        return (
          !item?.type && (
            <Flex flexDirection="column">
              {mutateOrder.isLoading && <Spinner />}
              {index !== 0 && !mutateOrder.isLoading && (
                <Icon
                  name="ArrowUp"
                  color="primary"
                  onClick={(e) => {
                    mutateOrder.mutate({ body: { barber_id: item?.id, barbershop_id: userData?.id, type: 'up' } })
                  }}
                  disabled={true}
                />
              )}
              {barbersList?.filtered?.filter((i) => !i?.type)?.length - 1 !== index && !mutateOrder.isLoading && (
                <Icon
                  name="ArrowDown"
                  color="primary"
                  onClick={(e) => {
                    mutateOrder.mutate({ body: { barber_id: item?.id, barbershop_id: userData?.id, type: 'down' } })
                  }}
                />
              )}
            </Flex>
          )
        )
      },
    },
    {
      key: 'profile_image_url',
      label: '',
      type: 'image',
      placeholder: 'barber',
    },
    { key: 'name', label: 'NAME_TABLE' },
    {
      key: 'phone',
      label: 'PHONE_TABLE',
      width: 150,
      render: (item) => formatPhone(item?.user.phone, userData),
    },
    {
      key: 'type',
      label: 'FUNCTION',
      render: (item) => t(item?.type) || item?.visible_name,
    },
    {
      key: 'rating_average',
      label: 'RATING_TABLE',
      render: (item) => !item?.type && (item?.rating_average?.toFixed(2) || 'Sem avaliação'),
    },
    {
      key: 'visible_for_clients',
      label: 'VISÍVEL P/ CLIENTES',
      type: 'switch',
      width: 70,
      render: (item) =>
        !item?.type && (
          <Switch
            colorScheme="yellow"
            onChange={(e) => {
              handleChangeVisible(item?.id, e.target.checked)
            }}
            isChecked={
              !visible?.find((i) => i?.id === item?.id)
                ? barbersList?.data?.find?.((i) => i?.id === item?.id)?.visible_for_clients
                : visible?.find((i) => i?.id === item?.id)?.value
            }
          />
        ),
    },
  ]

  async function getTeam() {
    const res = await api
      .get(BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const {
    refetch,
    isLoading,
    data: barbers,
  } = useQuery('get-team', getTeam, {
    onSuccess: (data) => {
      setBarbersList({ data: data, filtered: data })
    },
  })

  async function deleteBarber() {
    const res = await api
      .delete(
        BARBERSHOP_DELETE_BARBER(
          selectedBarber?.id,
          selectedBarber?.type || selectedBarber?.user?.type,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation('delete-barber', () => deleteBarber(), {
    onSuccess: async (value) => {
      toast({
        title: t('DELETE_BARBER_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      viewBarberModalOff()
      deleteBarberModalOff()
      refetch()
      selectedBarberSet({})
    },
    onError: (error) => {
      toast({
        title: t('DELETE_BARBER_ERROR'),
        description: error.toString().substring(7),
        status: 'danger',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setBarbersList({ ...barbersList, filtered: barbersList?.data })
    } else {
      const search_result = barbersList?.data.filter((item) =>
        String(item?.name.toLowerCase()).includes(query.toLowerCase())
      )
      setBarbersList({ ...barbersList, filtered: search_result })
    }
  }

  const handleBarberSelect = (barber) => {
    if (permission !== 'blocked') {
      viewBarberModalOn()
      selectedBarberSet(barber)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const handleBarberSelectClose = () => {
    viewBarberModalOff()
    selectedBarberSet({})
  }

  const handleDeleteBarberOpen = () => {
    deleteBarberModalOn()
  }

  const handleDeleteBarberCancel = () => {
    deleteBarberModalOff()
  }

  const freeAccountLimit = barbersList?.data?.length >= 2

  return (
    <Layout
      {...props}
      h="auto"
      headerTitle={t('TEAM_TITLE')}
      px={100}
      headerActionText={permission === 'edit' && t('ADD_BARBER')}
      headerActionClick={addBarberModalOn}
      premiumBlockMessage={isBlocked && freeAccountLimit && 'TEAM_BLOCK_MESSAGE_HEADER'}
    >
      <SectionHeader
        isOpen={sections?.team}
        onClick={() => setSections({ ...sections, team: !sections?.team })}
        title="Gerenciamento da equipe"
      />
      {sections?.team && (
        <>
          <TextInput
            noBorder
            type="text"
            width="full"
            color="white"
            placeholder={t('SEARCH_BARBER')}
            value={query}
            mb={!isDesktop && theme.pxToRem(16)}
            onChange={(event) => onSearch(event.target.value)}
            leftElement={<Search color={colors.primary} />}
            mt="12px"
          />

          {isDesktop ? (
            <Table
              isSelectableRow
              onSelect={(item, index, column) => {
                if (column?.key !== 'visible_for_clients' && column?.key !== 'order') {
                  handleBarberSelect(item)
                }
              }}
              data={barbersList?.filtered || []}
              loading={isLoading}
              columns={BARBER_COLUMNS}
              widthOptions="100%"
            />
          ) : (
            <MobileList
              data={barbersList?.filtered || []}
              loading={isLoading}
              renderItem={({ item, index }) => <TeamCard item={item} index={index} onSelect={handleBarberSelect} />}
            />
          )}
        </>
      )}

      {isBlocked && freeAccountLimit && <PremiumBlockMessage message="TEAM_BLOCK_MESSAGE" mt={theme.pxToRem(16)} />}

      <TeamModal
        isModalOpen={addBarberModalOpen}
        onClose={addBarberModalOff}
        onCompleted={refetch}
        barbersList={barbersList?.data}
        isBlocked={isBlocked}
      />

      <BarberModal
        barber={selectedBarber}
        isModalOpen={viewBarberModalOpen}
        onClose={handleBarberSelectClose}
        onDelete={handleDeleteBarberOpen}
        onRefresh={refetch}
      />

      <CreateGoalModal isOpen={goalModalOpen} onClose={() => setGoalModalOpen(false)} barbers={barbers} />

      <ActionModal
        kind="warning"
        title={t('DELETE_BARBER')}
        description={`${t('DELETE_BARBER_CONFIRM')} ${selectedBarber?.name}?`}
        isOpen={deleteBarberModalOpen}
        onConfirm={() => mutateDelete.mutate()}
        onCancel={handleDeleteBarberCancel}
        loading={mutateDelete.isLoading}
      />
    </Layout>
  )
}

TeamScreen.path = '/equipe'
TeamScreen.title = 'Equipe'
TeamScreen.secured = true
