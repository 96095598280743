import React from 'react'
import { Image as ChakraImage } from '@chakra-ui/react'

export const Image = (props) => {
  return (
    <ChakraImage  {...props}>
      { props.children }
    </ChakraImage>
  )
}

Image.defaultProps = {
}

Image.propTypes = {
  ...Image.propTypes,
}
