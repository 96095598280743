import React from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Text, Button } from 'components/atoms'

export const SelectOptionsModal = ({ isOpen, onClose, onSelectOption, options, title, description }) => {
  const theme = useTheme()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
      >
        <ModalHeader textAlign="center" mt={theme.pxToRem(20)} w={'100%'}>
          <Text kind="extraBold" fontSize={24} w="100%" >
            {title}
          </Text>
          <Text mt={theme.pxToRem(12)} w="100%" color="textGrey" fontSize={14}>
            {description}
          </Text>
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody w="100%">
          {options.map((opt) => (
            <Button
              {...opt}
              w="100%"
              mb={theme.pxToRem(24)}
              onClick={() => onSelectOption(opt)}
            >
              {opt.text}
            </Button>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
