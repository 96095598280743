import React, { useState, useEffect, useMemo } from 'react'
import { Flex, Grid, GridItem, useToast } from '@chakra-ui/react'
import { FileInput, RegisterWrapper, ActionModal } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'

import {
  BARBERSHOP_IMAGES,
  BARBERSHOP_UPLOAD_IMAGES,
  BARBERSHOP_UPLOAD_IMAGES_EDIT,
} from 'services/api/endpoints'
import { useQuery, useMutation } from 'react-query'
import { api } from 'services/api/config'

export const PhotosStepScreen = () => {
  const toast = useToast()
  const { photos, setPhotos, userData } = useBarbershopStore()
  const [selectedPhoto, selectedPhotoSet] = useState({})
  const [currentPhotos, currentPhotosSet] = useState([])
  const { t } = useTranslation()
  const [load, setLoad] = useState(false)

  const handleDeleteCancel = () => {
    selectedPhotoSet({})
  }

  async function changePhoto(params) {
    const res = await api[params.method](
      params.method === 'put'
        ? BARBERSHOP_UPLOAD_IMAGES_EDIT(
            params?.params?.id,
            userData?.barbershop?.id || userData?.id
          )
        : BARBERSHOP_UPLOAD_IMAGES(userData?.barbershop?.id || userData?.id),
      params?.params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function deletePhoto(params) {
    const res = await api
      .delete(
        BARBERSHOP_UPLOAD_IMAGES_EDIT(
          selectedPhoto?.id,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function getPhotos() {
    const res = await api
      .get(BARBERSHOP_IMAGES(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { refetch } = useQuery('get-photos', getPhotos, {
    onSuccess: (data) => {
      setPhotos(data)
    },
  })

  const mutate = useMutation('change-photos', (params) => changePhoto(params), {
    onSuccess: (data) => {
      refetch()
      toast({
        title: t('PHOTOS_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setLoad(false)
    },
    onError: (error) => {
      toast({
        title: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const mutateDelete = useMutation(
    'change-photos',
    (params) => deletePhoto(params),
    {
      onSuccess: (data) => {
        selectedPhotoSet({})
        refetch()
        toast({
          title: t('PHOTOS_TOAST'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleDeletePhoto = (photo, index) => {
    if (photo.id) {
      selectedPhotoSet(photo)
    } else {
      currentPhotosSet((photos) => {
        photos[index] = {
          url: null,
          raw: null,
        }
        return [...photos]
      })
    }
  }

  const handleSelectPhoto = async (photo, index) => {
    setLoad(index)
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onload = () => {
      const splitted = reader.result.split(',')
      const mime = splitted[0].toString()
      const mime1 = mime.split(':')
      const mime2 = mime1[1].split(';')
      const params = {
        method: 'post',
        params: {
          base64: splitted[1],
          mime: mime2[0],
        },
      }
      mutate.mutate(params)
    }
    currentPhotosSet((photos) => {
      const url = URL.createObjectURL(photo)
      photos[index] = {
        url,
        raw: photo,
      }
      return [...photos]
    })
  }

  useEffect(() => {
    const photoList = Array.from({ length: 8 }, (_, key) => {
      return { ...photos?.[key] } || {}
    })
    currentPhotosSet(photoList)
  }, [photos])

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_photos_step_opened')
  }, [])

  // const continueDisabled = useMemo(() => {
  //   return currentPhotos.filter((item) => item.url).length < 3 || load
  // }, [currentPhotos, load])

  return (
    <RegisterWrapper
      barbershopSetup
      // continueDisabled={continueDisabled}
      loading={mutate.isLoading}
    >
      <Flex flexDir="column" w="100%" h="100%">
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
        >
          {currentPhotos.map((item, index) => {
            return (
              <GridItem>
                <FileInput
                  loading={load === index}
                  defaultImage={item?.url}
                  canOverwrite={!item?.url}
                  key={`photo-${index}`}
                  label={t('PHOTO_LABEL')}
                  onChange={(file) => handleSelectPhoto(file, index)}
                  onClear={() => {
                    handleDeletePhoto(item, index)
                  }}
                  disabled={load}
                />
              </GridItem>
            )
          })}
        </Grid>
      </Flex>

      <ActionModal
        kind="warning"
        title="Excluir foto"
        description={`Tem certeza que deseja excluir essa foto?`}
        isOpen={selectedPhoto?.id}
        onConfirm={() => mutateDelete.mutate()}
        onCancel={handleDeleteCancel}
        loading={mutateDelete.isLoading}
      />
    </RegisterWrapper>
  )
}

PhotosStepScreen.path = '/cadastro/fotos-barbearia'
PhotosStepScreen.title = 'Fotos da Barbearia'
PhotosStepScreen.secured = true
