export const settings = (barbersMonthExtract) => {
  return [
    {
      label: 'TOTAL',
      description: 'SALES_SERVICE',
      color: 'success',
      notCurrency: false,
      value: barbersMonthExtract?.appointments_total_services || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a serviços',
    },
    {
      label: 'TOTAL',
      description: 'SALES_PRODUCT',
      color: 'success',
      notCurrency: false,
      value: barbersMonthExtract?.appointments_total_products || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a produtos',
    },
    // {
    //   label: 'COMMISSIONS',
    //   description: 'TOTAL',
    //   color: 'primary',
    //   notCurrency: false,
    //   value: barbersMonthExtract?.appointments_total_comissions || 0,
    //   info: 'disabled',
    //   infoText: 'Comissões pagas em produtos e serviços',
    // },
  ]
}

export const settingsMobile = (barbersMonthExtract) => {
  return [
    {
      label: 'COMMISSIONS',
      description: 'TOTAL',
      color: 'primary',
      notCurrency: false,
      value: barbersMonthExtract?.appointments_total_comissions || 0,
    },
    {
      label: 'TOTAL',
      description: 'SALES_PRODUCT',
      color: 'success',
      notCurrency: false,
      value: barbersMonthExtract?.appointments_total_products || 0,
    },
    {
      notCurrency: true,
      label: 'SELLS',
      description: 'TOTAL',
      value: barbersMonthExtract?.total_appointments || 0,
      color: 'white',
    },
    {
      label: 'TOTAL',
      color: 'error',
      value: -barbersMonthExtract?.appointments_total_discount || 0,
      description: 'DISCOUNTS',
      last: true,
    },
    {
      label: 'TOTAL',
      description: 'SALES_SERVICE',
      color: 'success',
      notCurrency: false,
      value: barbersMonthExtract?.appointments_total_services || 0,
      last: true,
    },
    {
      notCurrency: true,
      label: 'SELLS',
      description: 'FINISHED',
      value: barbersMonthExtract?.total_finished || 0,
      color: 'white',
      last: true,
    },
  ]
}
