import * as React from 'react'

function SvgShieldLock(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 64 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 2.91c7.561 7.097 17.43 10.792 27.514 10.302a43.624 43.624 0 011.321 16.345 42.983 42.983 0 01-4.793 15.596c-2.566 4.806-6.017 9.015-10.148 12.378-4.13 3.362-8.855 5.81-13.894 7.196-5.04-1.386-9.764-3.834-13.894-7.196-4.13-3.363-7.581-7.572-10.148-12.378a42.983 42.983 0 01-4.793-15.596 43.624 43.624 0 011.321-16.345C14.571 13.702 24.439 10.007 32 2.91"
        stroke="currentColor"
        strokeWidth={5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.666 30.331h16.667a3.333 3.333 0 013.333 3.333v10a3.333 3.333 0 01-3.333 3.334H23.666a3.333 3.333 0 01-3.333-3.334v-10a3.333 3.333 0 013.333-3.333zm11.97 8.336a3.637 3.637 0 11-7.273 0 3.637 3.637 0 017.274 0z"
      />
      <path
        fill="currentColor"
        d="M40.333 29.081H23.666v2.5h16.667v-2.5zm4.583 4.583a4.583 4.583 0 00-4.583-4.583v2.5c1.15 0 2.083.933 2.083 2.083h2.5zm0 10v-10h-2.5v10h2.5zm-4.583 4.584a4.583 4.583 0 004.583-4.584h-2.5c0 1.151-.933 2.084-2.083 2.084v2.5zm-16.667 0h16.667v-2.5H23.666v2.5zm-4.583-4.584a4.583 4.583 0 004.583 4.584v-2.5a2.083 2.083 0 01-2.083-2.084h-2.5zm0-10v10h2.5v-10h-2.5zm4.583-4.583a4.583 4.583 0 00-4.583 4.583h2.5c0-1.15.933-2.083 2.083-2.083v-2.5zM32 43.554a4.887 4.887 0 004.887-4.887h-2.5A2.387 2.387 0 0132 41.054v2.5zm-4.887-4.887A4.887 4.887 0 0032 43.554v-2.5a2.387 2.387 0 01-2.387-2.387h-2.5zM32 33.78a4.887 4.887 0 00-4.887 4.887h2.5A2.387 2.387 0 0132 36.28v-2.5zm4.887 4.887A4.887 4.887 0 0032 33.78v2.5a2.387 2.387 0 012.387 2.387h2.5z"
      />
      <path
        d="M25.333 30.333v-6.666a6.667 6.667 0 1113.333 0v6.666"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgShieldLock
