import React, { useEffect, useState } from 'react'
import {
  Layout,
  Table,
  Text,
  FeatureBlockedMessage,
  StockModal,
} from 'components'
import { formatPrice } from 'utils'
import { useTheme, useToast } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks/use-breakpoint'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useBarbershopStore } from 'services/stores'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { usePermissionCollaborator, useBarbershop } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const Stock = ({ ...props }) => {
  const [stockModalOpen, setStockModalOpen] = useState(false)
  const [batch, setBatch] = useState(null)
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const isBlocked = usePremiumBlock()
  const theme = useTheme()
  const permission = usePermissionCollaborator('additional_products')
  const { getStock } = useBarbershop()
  //   const permission = usePermissionCollaborator('stock')
  const {onLogout} = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [])
  const columns = [
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => <Text>{item?.product?.name}</Text>,
    },
    {
      key: 'quantity_',
      label: 'QUANTITY_',
      render: (item) => <Text>{item.quantity}</Text>,
    },
    {
      key: 'cost_price',
      label: 'COST_PRICE',
      render: (item) => <Text>{formatPrice(item.cost_price)}</Text>,
    },
    {
      key: 'sale_price',
      label: 'SALE_PRICE',
      render: (item) => <Text>{formatPrice(item.sale_price)}</Text>,
    },
  ]

  const { data, isLoading } = useQuery('get-stocks-releases', () =>
    getStock({ barbershop_id: userData?.id })
  )

  const onClickTableRow = (selectedRow) => {
    if (permission === 'edit') {
      setBatch(selectedRow)
      setStockModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('STOCK')}
      headerActionText={permission === 'edit' && t('INSERT_BATCH')}
      headerActionClick={() => setStockModalOpen(true)}
      paddingButton={80}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      contentPosition={isBlocked && 'relative'}
      isBlocked={isBlocked}
    >
      {isBlocked && (
        <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="STOCK" />
      )}

      <Table
        isSelectableRow
        onSelect={onClickTableRow}
        data={data || []}
        loading={isLoading}
        columns={isDesktop ? columns : columns}
      />

      <StockModal
        isModalOpen={stockModalOpen}
        onClose={() => {
          setBatch(null)
          setStockModalOpen(false)
        }}
        selectedBatch={batch}
      />
    </Layout>
  )
}

Stock.path = '/estoque'
Stock.title = 'Estoque'
Stock.secured = true
