import React from 'react'
import { Box, Text, Button } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'

import { useTheme } from '@chakra-ui/react'

export const HistoricalClientModal = ({ isOpen, onClose }) => {
  const theme = useTheme()

  const { t } = useTranslation()

  return (
    <ModalMenu
      title={'Histórico do cliente'}
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <Box>
        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {'Cliente'}
        </Text>
       
        <Text
          fontSize={14}
          kind="medium"
          mt={theme.pxToRem(16)}
          mb={theme.pxToRem(16)}
        >
          {'Extrato de pontos'}
        </Text>

      
        <Button
          flex="1"
          h={theme.pxToRem(52)}
          // disabled={isButtonDisabled}
          w="100%"
          mt={theme.pxToRem(24)}
        >
          <Text fontSize="md" color={colors.textDark} kind="bold">
            {t('SAVE')}
          </Text>
        </Button>
      </Box>
    </ModalMenu>
  )
}