export const settings = (barbersMonthExtract) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL',
      value: barbersMonthExtract?.finished_appointments_total_value || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de agendamentos finalizados - descontos oferecidos',
    },
    {
      label: 'DISCOUNTS',
      description: 'TOTAL',
      value: barbersMonthExtract?.finished_appointments_payments_methods_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos dos métodos de pagamentos',
    },
  ]
}

export const settingsMobile = (barbersMonthExtract) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL',
      value: barbersMonthExtract?.finished_appointments_total_value || 0,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de agendamentos finalizados',
    },
    {
      label: 'DISCOUNTS',
      description: 'TOTAL',
      value: barbersMonthExtract?.finished_appointments_payments_methods_discounts,
      color: 'danger',
      info: 'disabled',
      infoText: 'Total de descontos dos métodos de pagamentos',
      last: true,
    },
  ]
}
