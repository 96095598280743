import React, { useState } from 'react'
import { Text, Icon, TextInput } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { formatPrice } from 'utils/price'
import { useBarbershop } from 'services/hooks'
import { useMutation } from 'react-query'
import { clearCurrency } from 'utils/clearCurrency'
import { additionalProductsStore } from 'services/stores/additionalProducts'

export const AdditionalProductCard = ({
  product,
  onClick,
  br,
  reopened,
  index,
  ...props
}) => {
  const theme = useTheme()
  const [edit, setEdit] = useState(false)
  const {
    additionalProductsAppointmentList,
    setAdditionalProductsAppointmentList,
  } = additionalProductsStore()
  const { updateProduct } = useBarbershop()
  const mutate = useMutation('update-product', (params) =>
    updateProduct(params)
  )

  const onChangeValue = (value) => {
    const changed = additionalProductsAppointmentList?.map((item) => {
      if (item?.id === product?.id) {
        return {
          ...item,
          item_value: value,
        }
      }
      return item
    })
    setAdditionalProductsAppointmentList(changed)
  }

  return (
    <Box
      marginBottom={3}
      display="flex"
      flexDir="row"
      w="100%"
      h={theme.pxToRem(56)}
      background="cardBackground"
      alignItems="center"
      justifyContent="flex-start"
      px={theme.pxToRem(16)}
      borderRadius={br ? theme.pxToRem(br) : theme.pxToRem(16)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Flex
        w="100%"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          maxW={theme.pxToRem(260)}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          kind="bold"
          fontSize={theme.pxToRem(14)}
          w="70%"
        >
          {product?.name}
        </Text>

        {!edit ? (
          <Text
            color={props?.isDiscount && 'red'}
            kind="bold"
            fontSize={theme.pxToRem(14)}
          >
            {formatPrice(Number(product.value || product?.price))}
          </Text>
        ) : (
          <TextInput
            w="25%"
            placeholder="0,00"
            mask="CURRENCY_MASK"
            isReadOnly={false}
            value={additionalProductsAppointmentList[index]?.item_value}
            onChange={(e) => onChangeValue(clearCurrency(e.target.value))}
          />
        )}

        {reopened && (
          <Icon
            mr="6px"
            name={edit ? 'Check' : 'Pencil'}
            size={16}
            ml="-55px"
            color="white"
            onClick={() => {
              if (edit) {
                const params = {
                  id: product?.id,
                  product_value:
                    additionalProductsAppointmentList[index]?.item_value,
                }
                mutate.mutate({ params })
              }
              setEdit(!edit)
            }}
            cursor="pointer"
          />
        )}

        {onClick && (
          <Icon
            cursor="pointer"
            onClick={onClick}
            name="Trash"
            color="baseRed"
            size={16}
          />
        )}
      </Flex>
    </Box>
  )
}
