import * as React from 'react'

function SvgChevronLeftBold(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.679.321a1.097 1.097 0 010 1.551l-2.88 2.88 2.88 2.88a1.097 1.097 0 01-1.551 1.55L.214 5.27a.731.731 0 010-1.034L4.128.321a1.097 1.097 0 011.55 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgChevronLeftBold
