import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalMenu, Calendar } from 'components'


export const SelectDateModal = ({ isModalOpen, onClose, ...props }) => {
  const {t} = useTranslation()
  
  return (
    <ModalMenu isOpen={isModalOpen} title={t('SELECT_DATE_MODAL_TITLE')} onClose={onClose}>
      <Calendar {...props} />
    </ModalMenu>
  )
}
