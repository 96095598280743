export const steps = [
  {
    number: 'SvgOne',
    title: 'OPEN_BILLING_EMAIL',
    text: 'WE_WILL_SEND_EMAIL',
  },
  {
    number: 'SvgTwo',
    title: 'PAY_MEMBERSHIP',
    text: 'OPEN_THE_LINK',
  },
  {
    number: 'SvgThree',
    title: 'ALRIGHT',
    text: 'USE_THE_APP',
  },
]
