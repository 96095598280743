import React, { useState, useEffect, useMemo } from 'react'
import { Flex, Box, useTheme, useToast, Spinner } from '@chakra-ui/react'
import {
  Text,
  RegisterWrapper,
  Button,
  MobileList,
  BarberCard,
  AssociateBarbershopAndBarberModal,
  TeamModal,
} from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { firebaseAnalytics } from 'services/firebase'
import { useTranslation } from 'react-i18next'
import { useAppointmentStore } from 'services/stores'

import { api } from 'services/api/config'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { BARBERS, BARBERSHOP_DELETE_BARBER } from 'services/api/endpoints'

export const TeamStepScreen = () => {
  const [associateModalOpen, setAssociateModalOpen] = useState(false)
  const { user, userData } = useBarbershopStore()
  const { barbersList, setBarbersList } = useAppointmentStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [barberModalOpen, setBarberModalOpen] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    firebaseAnalytics.logEvent('web_register_barbershop_services_step_opened')
  }, [])

  const onCloseBarberModal = () => {
    setBarberModalOpen(false)
    refetch()
  }

  const onCloseAssociateModal = () => {
    refetch()
    setAssociateModalOpen(false)
    queryClient.invalidateQueries('get-main-data')
  }

  async function getTeam() {
    const res = await api
      .get(BARBERS(userData.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { refetch, isLoading } = useQuery('get-team', getTeam, {
    onSuccess: (data) => {
      setBarbersList(data)
    },
  })

  async function deleteBarber(params) {
    const res = await api
      .delete(BARBERSHOP_DELETE_BARBER(params.id, 'barber', userData.id))
      .then((response) => {
        if (user?.email === params?.user?.email) {
          user.type = 'barbershop'
        }
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDelete = useMutation(
    'delete-barber',
    (params) => deleteBarber(params),
    {
      onSuccess: async (value) => {
        toast({
          title: t('DELETE_BARBER_SUCCESS'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        refetch()
      },
      onError: (error) => {
        toast({
          title: t('DELETE_BARBER_ERROR'),
          description: error.toString().substring(7),
          status: 'danger',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const continueDisabled = useMemo(() => {
    return !barbersList || barbersList.length === 0 || mutateDelete.isLoading
  }, [barbersList, mutateDelete.isLoading])
  return (
    <RegisterWrapper
      barbershopSetup
      continueDisabled={continueDisabled}
      loading={mutateDelete.isLoading}
    >
      <Flex flexDir="column" w="100%" h="100%">
        {user.type !== 'barbershopAndBarber' &&
          user?.type !== 'ownerAndBarber' && (
            <Button
              onClick={() => setAssociateModalOpen(true)}
              mb={theme.pxToRem(24)}
              w="100%"
              h={theme.pxToRem(50)}
              kind="outline-primary"
              disabled={mutateDelete.isLoading}
            >
              {t('BARBER_BUTTON')}
            </Button>
          )}
        <Button
          onClick={() => setBarberModalOpen(true)}
          mb={theme.pxToRem(24)}
          w="100%"
          h={theme.pxToRem(50)}
          kind="outline-primary"
          disabled={mutateDelete.isLoading}
        >
          {t('ADD_BARBER_REGISTER')}
        </Button>
        <Flex w="100%">
          <MobileList
            emptyListMessage="Nenhum barbeiro encontrado."
            data={barbersList || []}
            loading={isLoading}
            renderItem={({ item }) => (
              <BarberCard
                inList
                fullWidth
                outline
                onClick={() => mutateDelete.mutate(item)}
                barber={item}
              />
            )}
          />
        </Flex>
      </Flex>

      {associateModalOpen && (
        <AssociateBarbershopAndBarberModal
          isModalOpen={associateModalOpen}
          onClose={() => onCloseAssociateModal()}
          // setBarberAssociate={setBarberAssociate}
        />
      )}

      {barberModalOpen && (
        <TeamModal
          isRegister
          isModalOpen={barberModalOpen}
          onClose={onCloseBarberModal}
          barbersList={barbersList}
        />
      )}
    </RegisterWrapper>
  )
}

TeamStepScreen.path = '/cadastro/equipe'
TeamStepScreen.title = 'Equpe'
TeamStepScreen.secured = true
