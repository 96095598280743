import React, { useState, useEffect } from 'react'
import { Box, Text, Button } from 'components/atoms'
import { ModalButtonBackground } from 'components-v2/atoms'
import { ModalMenu } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { useTheme } from '@chakra-ui/react'
import { SelectCard } from 'components-v2/molecules'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useBarbershopStore } from 'services/stores'
import { useQuery } from 'react-query'

export const SelectRewardModal = ({
  isOpen,
  onClose,
  setSelected,
  selected,
}) => {
  const { userData } = useBarbershopStore()

  const [rewards, setRewards] = useState([])
  const { getRewards } = useBestcoins()
  const theme = useTheme()
  const { t } = useTranslation()

  const { isLoading, data: initialRewards } = useQuery(
    ['get-rewards-for-exchange'],
    () =>
      getRewards({
        params: { barbershop_id: userData?.barbershop?.id || userData?.id },
      })
  )

  useEffect(() => {
    if (initialRewards) {
      const sortedRewards = [...initialRewards].sort((a, b) =>
        a?.reward?.discount_value.localeCompare(b?.reward?.discount_value)
      )
      setRewards(sortedRewards)
    }
  }, [initialRewards])

  if (isLoading || !initialRewards) {
    return <Text>Carregando prêmios...</Text>
  }

  return (
    <ModalMenu
      isNavigating
      title={'Selecionar um prêmio'}
      isOpen={isOpen}
      handleBackNavigation={onClose}
    >
      <Box>
        {initialRewards.map((reward) => (
          <SelectCard
            key={reward?.id}
            reward={reward}
            onSelect={() => setSelected(reward)}
            isSelected={reward === selected}
          />
        ))}
      </Box>
      <ModalButtonBackground>
        <Button
          flex="1"
          h={theme.pxToRem(52)}
          w="100%"
          onClick={onClose}
          disabled={!selected}
        >
          <Text fontSize="md" color={colors.textDark} kind="bold">
            {t('CONFIRM')}
          </Text>
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
