import React from 'react'
import { useTheme } from '@chakra-ui/react'

import { Box, Flex, Text } from 'components/atoms'
import { formatPrice, formatToSimpleDate } from 'utils'
import { ChevronRight } from 'assets/icons'

export const BarberCommissionReportDetailCard = ({ item, index, onSelect }) => {
  const theme = useTheme()

  return (
    <Flex
      key={`data_comission_detail_${index}`}
      alignItems="center"
      justifyContent="space-between"
      padding={theme.pxToRem(16)}
      marginBottom={theme.pxToRem(16)}
      width="full"
      height="fit-content"
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      onClick={() => onSelect(item)}
    >
      <Text kind="medium" fontSize={theme.pxToRem(14)} w="20%">
        {formatToSimpleDate(item?.payment_date || item?.date)}
      </Text>

      <Box w="55%">
        <Text kind="medium" fontSize={theme.pxToRem(14)}>
          {item?.client?.name}
        </Text>

        <Text
          maxW="90%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          kind="medium"
          fontSize={theme.pxToRem(14)}
          color="textGrey"
        >
          {item?.product?.name}
        </Text>
      </Box>

      <Box w="25%">
        <Text kind="medium" fontSize={theme.pxToRem(14)} color="success">
          {formatPrice(item?.total_value)}
        </Text>

        <Text kind="medium" fontSize={theme.pxToRem(14)} color="primary">
          {formatPrice(item?.barber_percentage_value)}
        </Text>
      </Box>
      {onSelect && (
        <ChevronRight
          color={theme.colors.primary}
          style={{ fontWeigth: 'bold' }}
        />
      )}
    </Flex>
  )
}
