import * as React from 'react'

function SvgCardDelete(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 81 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.854 2H10.57A8.57 8.57 0 002 10.57v22.856a8.57 8.57 0 008.57 8.57h34.284a8.57 8.57 0 008.57-8.57V10.571A8.57 8.57 0 0044.854 2zM2 16.29h51.424"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 3L64 17m0-14l14 14L64 3z"
        stroke="currentColor"
        strokeWidth={4.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.426 30.57h.027M24.857 30.57h5.714"
        stroke="currentColor"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCardDelete
