export const es = {
  finished: 'Finalizado',
  awaiting: 'Aguardando',
  confirmed: 'Confirmado',
  client_missed: 'Cliente Faltou',
  reopened: 'Reaberto',
  pending: 'Pendente',
  paid: 'Pago',
  // GENERAL
  OPEN_LINK: 'Abrir link da fatura',
  ERROR_LOADING_DATA: 'Error al obtener datos',
  ERROR_SAVE_DATA: 'Error al guardar datos',

  // LOGIN
  ACCESS_ACCOUNT: 'Acceder a tu cuenta',
  MANAGE_YOUR_BARBERSHOP: 'Gestiona tu peluquería de forma fácil y rápida',
  EMAIL_PLACEHOLDER: 'E-mail o Teléfono',
  PASSWORD_PLACEHOLDER: 'Tu contraseña',
  FORGOT_PASSWORD: 'Olvidé mi contraseña',
  LOGIN_BUTTON: 'Entrar',
  SIGNUP1: 'No tienes una cuenta?',
  SIGNUP2: 'Regístrate ahora',
  USER_NOT_AUTH: 'Usuario no autorizado para utilizar este entorno.',
  USER_NOT_AUTH_DESC: 'El usuario debe ser barbería.',
  LOGIN_ERROR: 'Error al iniciar sesión',
  INVALID_USERNAME: 'El usuario debe tener al menos 8 caracteres',
  USERNAME_REQUIRED: 'El usuario es obligatorio',
  REGISTER_ERROR: 'Hubo un error durante el registro. Inténtalo de nuevo.',

  // FORGOT PASSWORD
  REDEFINE_PASSWORD_TEXT: '¿Quieres restablecer tu contraseña?',
  ADD_EMAIL: 'Registre su correo electrónico',
  ADD_EMAIL_DESC:
    'Regístrese o actualice su correo electrónico para establecer una nueva contraseña.',
  RESET_PASSWORD_SUCCESS: 'Restablecimiento de contraseña con éxito!',
  RESET_PASSWORD_SUCCESS_DESC:
    '¡Descarga nuestra aplicación y programa tu servicio en cualquier momento!',
  RESET_PASSWORD: 'Redefinir contraseña',
  RESET_PASSWORD_DESC: 'Olvidaste tu contraseña? Tranquilo!',
  ENTER_THE_EMAIL:
    'Informe el email utilizado en su registro y le ayudaremos a establecer una nueva contraseña.',
  ENTER_THE_PHONE:
    'Informe el teléfono utilizado en su registro y le ayudaremos a establecer una nueva contraseña.',
  REDEFINE_PASSWORD_BUTTON: 'Redefinir Contraseña',
  CANCEL: 'Cancelar',
  INVALID_TOKEN: 'Token inválido',
  INVALID_PHONE: 'El teléfono debe tener al menos 14 caracteres',

  // LOGIN NOTIFICATION
  SUCCESS: 'Éxito!',
  PASSWORD_REDEFINED: () => (
    <>
      Su nueva contraseña ha sido registrada y ahora se puede usar <br />
      para acceder a BestBarbers. <br />
      Disfruta!
    </>
  ),
  BACK: 'Volver',

  // LOGIN SETTINGS
  EMAIL_SENT: 'Email enviado!',
  EMAIL_SENT_DESC: (email) => (
    <>
      Enviamos un email a
      <br />
      {email}
      <br />
      que contiene los pasos necesarios para recuperar la contraseña de su
      cuenta.
    </>
  ),
  USER_NOT_FOUND: 'Usuario no encontrado!',
  USER_NOT_FOUND_DESC: (email) => (
    <>
      El e-mail
      <br />
      {email}
      <br />
      no fue encontrado en nuestro
      <br />
      banco de datos. Verifique los datos y
      <br />
      inténtalo de nuevo.
    </>
  ),
  SMS_SENT: 'Mensaje enviado!',
  SMS_SENT_DESC: (phone) => (
    <>
      Enviamos un SMS a
      <br />
      {phone}
      <br />
      que contiene los pasos necesarios para recuperar la contraseña de su
      cuenta.
    </>
  ),
  PHONE_NOT_FOUND: (phone) => (
    <>
      El teléfono
      <br />
      {phone}
      <br />
      no fue encontrado en nuestro
      <br />
      Banco de datos. Verifique los datos y
      <br />
      Inténtalo de nuevo.
    </>
  ),

  // CHANGE PASSWORD
  NEW_PASSWORD: 'Crear Nueva Contraseña',
  ENTER_TOKEN_FIRST: 'Ingrese el token enviado a ',
  ENTER_TOKEN_LAST: ' y la nueva contraseña deseado.',
  CHANGE_PASSWORD_BUTTON: 'Cambiar contraseña',
  PASSWORD_CHANGED: 'Contraseña alterada con éxito!',
  PASSWORD_NOT_CHANGED: 'Error al cambiar la contraseña!',
  TOKEN_REQUIRED: 'Token es obligatorio',

  // LOGIN AUTH FACTOR
  VERIFICATION_CODE: 'Código de verificación',
  ENTER_VERIFICATION_CODE:
    'Introduzca el código que le enviamos a su teléfono registrado',
  RESEND_CODE: 'Vuelva a enviar el código de verificación',

  // REGISTER

  // /cadastro
  CONTROL_YOUR_BARBERSHOP: 'Gestiona tu peluquería en la palma de tu mano.',
  BESTBARBERS_HELPS_YOU:
    'BestBarbers lo ayuda a administrar toda su peluquería, desde la programación hasta las finanzas. Para eso, te proporcionamos en la plataforma:',
  ADMINISTRATION: 'Administración, historial y programación.',
  PROFILE_MANAGEMENT:
    'Gestión de perfiles (descripción, horario de apertura, ubicación, fotos del espacio).',
  TEAM_MANAGEMENT: 'Gestión del equipo.',
  SERVICES_MANAGEMENT: 'Creación y edición de servicios prestados.',
  FINANCIAL_MANAGEMENT:
    'Gestión financiera completa (balance mensual, flujo de caja, gestión de gastos e ingresos).',
  CLIENTS_MANAGEMENT: 'Gestión de clientes.',
  APPOINTMENT_REMINDER:
    'Recordatorio de cita para que sus clientes no pierdan tiempo.',
  MONTHLY_REPORTS: 'Reportes mensuales de barberos, servicios y comisiones.',
  EXCLUSIVE_ACCESS: 'Acceso exclusivo para cada peluquero.',
  CREATE_ACCOUNT: 'Crear Cuenta',
  START_NOW:
    'Empieza ahora a gestionar tu barbería de una forma fácil y práctica.',
  BARBERSHOP_NAME: 'Nombre de la peluquería',
  PHONE: 'Teléfono',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirma tu contraseña',
  CONTINUE_BUTTON: 'Continuar',
  ALREADY_REGISTERED: 'Ya tienes registro?',
  LOG_IN: 'Hacer login',
  SUBSCRIPTION: 'Suscripción',
  SUBSCRIPTION_TEXT:
    'BestBarbers no limita el número de empleados ni la programación. Solo cobramos la tarifa mensual y tienes acceso ilimitado.',
  SUBSCRIPTION_FEE: 'Suscripción BestBarbers',
  SUBSCRIPTION_FEE_TEXT: 'Cuota mensual',
  FREE_TRIAL: 'Pruébelo gratis durante 7 días',
  CONTACT: 'Contacto',
  TERMS: 'Terminos de uso',
  PRIVACY: 'Política de privacidad',

  // /cadastro/barbershop-intro
  BARBERSHOP_INTRO_TITLE: 'Vamos a montar tu barbería!',
  BARBERSHOP_INTRO_DESC:
    'Antes de continuar, necesitamos información importante, como el horario de atención, los servicios ofrecidos, las fotos y los miembros del personal. Esta información hará que su perfil de peluquería obtenga más visitas y atraiga a más clientes.',
  BEGIN: 'Iniciar',

  // /cadastro/perfil-barbearia
  YOUR_BARBERSHOP: 'Su Peluquería',
  SHOW_WHO_YOU_ARE: 'Muestre a sus clientes quién es usted.',
  BARBERSHOP_PHONE: 'Número de teléfono de contacto de la barbería',
  BARBERSHOP_DESCRIPTION: 'Descripción de la barbería',
  BARBERSHOP_DESCRIPTION_PLACEHOLDER:
    'Describe aquí tus servicios, diferenciales ...',

  // /cadastro/localizacao
  LOCATION: 'Localización',
  SET_UP_LOCATION:
    'Configure su dirección para que los clientes lo encuentren.',
  ZIP_CODE_PLACEHOLDER: 'Código postal',
  STATE: 'Estado',
  CITY: 'Ciudad',
  ADDRESS: 'Dirección',
  NUMBER: 'Número',
  NEIGHBORHOOD: 'Distrito',
  LOCATION_TOAST: 'Dirección actualizada correctamente!',
  LOCATION_TOAST_ERROR: 'No se pudo actualizar la dirección',
  SEARCH_ADDRESS: 'Dirección de búsqueda',
  LOADING: 'Cargando...',
  GOOGLE_MAPS_ERROR: 'No se pudo cargar la API de Google Maps',

  // /cadastro/horario-funcionamento'
  WORKING_HOURS: 'Horas de Oficina',
  WORKING_HOURS_SUBTITLE:
    'Haremos citas disponibles de acuerdo a su horario de atención.',
  OPENING_TIME: 'Horario de apertura',
  CLOSING_TIME: 'Horario de cierre',
  CLOSED_DAY: 'Establecer el día de la semana como cerrado',
  UPDATE_WORKING_HOURS: `Horarios actualizados con éxito!`,
  UPDATE_WORKING_HOURS_ERROR: 'No se pudieron actualizar los horarios',

  // /cadastro/intervalo-agenda
  AGENDA_INTERVAL: 'Intervalo de programación',
  AGENDA_INTERVAL_SUBTITLE:
    'Selección o intervalo de tiempo entre los horarios de su agenda',
  MINUTES: 'minutos',
  YOUR_AGENDA_INTERVAL: 'Su horario se verá así',
  YOUR_AGENDA_INTERVAL_DESC:
    'Cambie el rango anterior para ver cómo se verán afectados los horarios de su programación',
  AGENDA_INTERVAL_SAVED: 'Intervalo de programación guardado con éxito!',
  AGENDA_INTERVAL_SAVE_FAIL: 'No se pudo guardar el intervalo de programación!',

  // /cadastro/fotos-barbearia
  PHOTOS: 'Fotos',
  PHOTOS_DESC:
    'Publique fotos de su barbería para que los clientes puedan conocer mejor su establecimiento.',
  PHOTO_LABEL: 'Foto',
  PHOTOS_TOAST: 'Fotos actualizadas correctamente!',

  // /cadastro/servicos-barbearia
  SERVICES: 'Servicios',
  SERVICES_DESC:
    'Agregue los servicios que brinda su barbería para que los clientes puedan programar citas',
  SERVICE_NAME: 'Nombre',
  SERVICE_NAME_PLACEHOLDER: 'Nombre del servicio',
  TIME: 'Tiempo',
  TIME_PLACEHOLDER: 'hora:minuto',
  PRICE: 'Tarifa',
  SERVICES_LOAD_ERROR: 'No se pudieron cargar los servicios',
  ADD_SERVICE: 'Servicio agregado exitosamente!',
  ADD_SERVICE_ERROR: 'No se pudo guardar el servicio',
  REMOVE_SERVICE: `Servicio eliminado con éxito!`,
  REMOVE_SERVICE_ERROR: 'No se pudo quitar el servicio',

  // /cadastro/equipe
  TEAM: 'Equipo',
  TEAM_DESC:
    'Envíe invitaciones a su equipo de peluqueros o agregue un perfil de peluquero a esta cuenta.',
  BARBER_BUTTON: 'Soy barbero',
  BARBER_NAME: 'Nombre',
  INVITES: 'Invitaciones',
  BARBERS: 'Barberos',
  DELETE_INVITE: 'Invitación eliminada!',
  BARBER_DELETED: 'Barbero eliminado!',
  INVITE: 'Invitación enviada!',
  FINISH_CONFIGURATION: 'Concluir configuración',

  // /cadastro-concluido
  REGISTER_COMPLETED: 'Registro completado!',
  PROFILE_COMPLETED: 'Tu perfil de barbería está completo!',
  USE_APP: 'Ahora puede utilizar BesBarbers para gestionar sus citas!',
  PUBLISH_SUCCESS: 'Barbería publicada con éxito!',
  PUBLISH_ERROR: 'Hubo un error al publicar barbería.',

  // /checkout
  CREATE_MEMBERSHIP_SUCCESS: 'Suscripción creada con éxito!',
  CREATE_MEMBERSHIP_ERROR: 'Hubo un error al firmar..',
  ADD_PAYMENT_CARD: 'Añadir método de pago',
  ADD_PAYMENT_CARD_DETAILS:
    'Para continuar con su suscripción, ingrese la información de su tarjeta a continuación..',
  SAFE_BUY_1: 'Compra',
  SAFE_BUY_2: 'Segura',
  SATISFACTION_GUARANTEED_1: 'Satisfacción',
  SATISFACTION_GUARANTEED_2: 'Garantizada',
  PRIVACY_PROTECTED_1: 'Privacidad',
  PRIVACY_PROTECTED_2: 'Protegida',
  CARD_NUMBER: 'Numero de Tarjeta',
  CARD_NUMBER_PLACEHOLDER: '0000 0000 0000 0000',
  VERIFICATION_CODE_PLACEHOLDER: '000',
  SURNAME: 'Apellido',
  EXPIRATION_MONTH: 'Mes de Expiración',
  EXPIRATION_YEAR: 'Año de Expiración',
  SUBSCRIBE: 'Suscribir',

  // TABLE
  NAME_TABLE: 'Nombre',
  EMAIL_TABLE: 'EMAIL',
  PHONE_TABLE: 'TELÉFONO',
  TIME_TABLE: 'HORARIO',
  CLIENT_TABLE: 'CLIENTE',
  BARBER_TABLE: 'BARBERO',
  SERVICE_TABLE: 'SERVICIO',
  PAYMENT_TABLE: 'PAGO',
  VALUE_TABLE: 'VALOR',
  STATUS_TABLE: 'STATUS',
  DATE_TABLE: 'FECHA',
  DESCRIPTION_TABLE: 'DESCRIPCIÓN',
  CATEGORY_TABLE: 'CATEGORÍA',
  TOTAL_VALUE_TABLE: 'VALOR TOTAL',
  RECEIVED_VALUE_TABLE: 'VALOR RECIBIDO',
  APPOINTMENTS_TABLE: 'CITAS',
  COMMISSION_TABLE: '% COMISIÓN',
  TOTAL_SALES_TABLE: 'TOTAL EN VENTAS',
  TOTAL_COMMISSION_TABLE: 'TOTAL EN COMISIÓN',
  GROSS_VALUE_TABLE: 'VALOR BRUTO',
  NET_VALUE_TABLE: 'VALOR NETO',
  COMMISSION_VALUE_TABLE: 'VALOR DE COMISIÓN',
  PROMOTIONAL_TABLE: 'PROMOCIONAL',
  PROMOTIONAL_VALUE_TABLE: 'Valor Promocional',
  RATING_TABLE: 'Clasificación',

  // LOCALE
  LOCALE: 'es',

  // HISTORIC SCREEN
  HISTORIC_TITLE: 'Histórico',
  LOAD_HISTORIC_ERROR: 'Error al cargar el histórico!',

  // HISTORY LIST COMPONENT
  EMPTY_LIST_MESSAGE: 'No se encontró información.',

  // SELECT DATE MODAL
  SELECT_DATE_MODAL_TITLE: 'Seleccione Fecha',

  // CLIENTS SCREEN
  LOAD_CLIENTS_ERROR: 'Hubo un error al cargar los clientes.',
  SEARCH_CLIENT_ERROR: 'Hubo un error al buscar clientes.',
  CLIENTS_TITLE: 'Clientes',
  CLIENTS_ACTION_BUTTON: 'Registrar Clientes',
  SEARCH_CLIENT: 'Buscar cliente',
  LOAD_MORE: 'Cargar más',

  // ADD CLIENTS MODAL
  REGISTER_CLIENT: `Cliente registrado con éxito!`,
  REGISTER_CLIENT_ERROR: 'Error al registrar cliente.',
  ADD_CLIENT_TITLE: 'Registrar Cliente',
  CLIENT_NAME: 'Nombre',
  CLIENT_NAME_PLACEHOLDER: 'Nombre del cliente',
  CLIENT_PHONE_PLACEHOLDER: 'Teléfono',
  CLIENT_EMAIL: 'E-mail (opcional)',
  REGISTER_CLIENT_BUTTON: 'Registrar cliente',

  // EDIT CLIENTS MODAL
  EDIT_CLIENT: 'Editar cliente',
  EDIT_CLIENT_SUCCESS: 'Cliente editado correctamente!',
  EDIT_CLIENT_ERROR: 'Hubo un error al editar cliente.',
  BIRTH_DATE: 'Fecha de nacimiento',

  // CLIENT DETAILS MODAL
  DELETE_CLIENT: 'Cliente eliminado correctamente!',
  DELETE_CLIENT_ERROR: 'Error al eliminar el cliente.',
  DELETE_CLIENT_BUTTON: 'Eliminar Cliente',
  DELETE_CLIENT_MODAL_TITLE: 'Eliminar Servicio',
  DELETE_CLIENT_MODAL: 'Quieres eliminar al cliente ',
  DELETE_CLIENT_CONFIRM: 'Eliminar',
  DELETE_CLIENT_WARNING:
    'Esta acción es irreversible y borrará todos los datos del cliente relacionados con tu barbería, como montos recibidos e historial de servicios.',
  WHATSAPP_BLOCKED:
    'Llamar a clientes en WhatsApp es exclusivo para suscriptores Premium',

  // FINANCIAL SCREEN
  FINANCIAL_TITLE: 'Financiero',
  LOAD_FINANCIAL_DATA_ERROR:
    'Se produjo un error al cargar los datos financieros.',
  ADD_RELEASE: 'Agregar lanzamiento',
  APPOINTMENTS_TOTAL: 'Citas',
  APPOINTMENTS_TOTAL_DESC: 'Total',
  APPOINTMENTS_RECEIVED: 'Citas',
  APPOINTMENTS_RECEIVED_DESC: 'Recibido',
  EXPENSES: 'Gastos',
  EXPENSES_DESC: 'Contabilizados',
  PROFITS: 'Recetas',
  PROFITS_DESC: 'Contabilizados',
  MONTH_PROJECTION: 'Saldo del mes',
  MONTH_PROJECTION_DESC: 'Saldo del mes',
  LATE_PAYMENT: 'Tarde',
  PENDING_PAYMENT: 'Pendiente',
  PAID_PAYMENT: 'Pagado',
  ALL: 'Todos',

  // FINANCE RELEASE MODAL
  LOAD_CATEGORIES_ERROR: 'Hubo un error al cargar las categorías.',
  DELETE_RELEASE: `Lanzamiento eliminado con éxito!`,
  DELETE_RELEASE_ERROR: `Hubo un error al eliminar el lanzamiento!`,
  ADD_RELEASE_SUCCESS: `Lanzamiento creado con éxito!`,
  EDIT_RELEASE_SUCCESS: `Lanzamiento actualizado con éxito!`,
  ADD_RELEASE_ERROR: `Hubo un error al crear el lanzamiento!`,
  EDIT_RELEASE_ERROR: `Hubo un error al actualizar el lanzamiento!`,
  EDIT_RELEASE: 'Editar Lanzamiento',
  RELEASE_TYPE: 'Tipo de Lanzamiento',
  PROFIT: 'Receta',
  EXPENSE: 'Gasto',
  EX_REVENUE: 'Ex: Venta de Productos',
  EX_NOT_REVENUE: 'Ex: Factura de Electricidad',
  title: 'Título',
  VALUE: 'Valor',
  DUE_DATE: 'Fecha de Vencimiento',
  CATEGORY: 'Categoría',
  CATEGORY_SHOP: 'Infraestructura',
  CATEGORY_PEOPLE: 'Empleados',
  CATEGORY_MONEY: 'Pagos varios',
  CATEGORY_STOCKS: 'Material',
  CHOOSE_RELEASE_STATUS: 'Cómo quieres poner el estado de lanzamiento?',
  SAVE: 'Ahorrar',
  DELETE: 'Eliminar',
  UPDATE: 'Actualizar',
  DELETE_RELEASE_TITLE: 'Eliminar Lanzamiento',
  CONFIRM_DELETE_RELEASE: 'Quieres eliminar el lanzamiento ? ',

  // APPOINTMENT FINANCE MODAL
  APPOINTMENT_FINANCE_MODAL_TITLE: 'Detalles Financieros de la Cita ',
  ADDITIONAL_PRODUCTS: 'Productos',
  FINANCIAL_DESCRIPTIVE: 'Descriptivo financiero',
  PAYMENT_METHOD: 'Medio de pago',
  COMMISSION: 'Comisión',
  NET_TOTAL: `Total después de los descuentos`,
  DISCOUNT_FROM_BARBER: 'Sácalo de la barbería',

  // PAYMENT METHODS SCREEN
  PAYMENT_METHODS: 'Medios de pago',
  ADD_PAYMENT_METHOD: 'Añadir Método de Pago',
  LOAD_PAYMENT_METHODS_ERROR: 'Hubo un error al cargar los métodos de pago.',
  PERCENTAGE: 'Porcentaje',
  DUE_IN_DAYS: 'Recibo (En días)',
  PAYMENT_METHODS_BLOCK_MESSAGE_HEADER: 'Límite de 2 en la versión gratuita',
  PAYMENT_METHODS_BLOCK_MESSAGE:
    'Agregue tantos métodos de pago como desee en la versión premium!',

  // PAYMENT METHOD MODAL
  DELETE_PAYMENT_METHOD_SUCCESS: `El método de pago se eliminó correctamente!`,
  DELETE_PAYMENT_METHOD_ERROR: `Hubo un error al eliminar el método de pago.`,
  SAVE_PAYMENT_METHOD: `Método de pago guardado correctamente!`,
  SAVE_PAYMENT_METHOD_ERROR: 'Hubo un error al guardar el método de pago.',
  CREATE_PAYMENT_METHOD: 'Agregar método de pago',
  UPDATE_PAYMENT_METHOD: 'Actualizar método de pago',
  PAYMENT_METHOD_EXAMPLE: 'Ex: Visa - Crédito',
  PERCENTAGE_EXAMPLE: 'Ex: 30%',
  DUE_IN_DAYS_EXAMPLE: 'Ex: 0 (mismo día) o 30 (30 días a partir de hoy)',
  DELETE_PAYMENT_METHOD: 'Eliminar Método de Pago',
  CONFIRM_DELETE_PAYMENT_METHOD: 'Quieres eliminar la forma de pago',
  DISCOUNT_BARBER_TITLE: '¿Este porcentaje se descuenta de los barberos?',
  DISCOUNT_BARBER_SUBTITLE:
    'Si la comisión del peluquero se calcula tras descontar el porcentaje de pago del importe total del servicio, marque sí.',

  // PAYMENT METHOD CARD
  DAYS: 'días',

  // COMMISSIONS SCREEN
  LOAD_COMMISSIONS_ERROR: 'Hubo un error al cargar los datos de la comisión.',
  COMMISIONS_SCREEN_TITLE: 'Informe de la Comisión',
  APPOINTMENTS_COUNT: 'Horarios totales',
  APPOINTMENTS_COUNT_DESC: 'Importe referido a las citas a pagar en ese mes.',
  TOTAL_SALES_DESC: 'Monto total facturado por citas.',
  TOTAL_COMMISSION_DESC: 'Suma del valor de todas las comisiones.',
  SALES: 'Ventas',
  COMMISSIONS: 'Comisiones',

  // COMMISSIONS REPORT DETAILS SCREEN
  COMMISSION_REPORT_DETAILS_TITLE: 'Reporte:',
  GROSS_VALUE: 'Total en ventas (bruto)',
  GROSS_VALUE_DESC: 'Cantidad total bruta de nombramientos pagados este mes.',
  NET_VALUE: 'Total en ventas (neto)',
  NET_VALUE_DESC:
    'Monto total de citas pagadas este mes, descontadas tasas y cargos.',
  COMMISSIONS_TOTAL: 'Total en comisiones',
  COMMISSIONS_TOTAL_DESC: 'Monto total de la comisión pagada este mes.',

  // YUP
  PERCENTAGE_REQUIRED: 'El porcentaje es obligatorio.',
  DUE_IN_DAYS_REQUIRED: 'Los días para recibir es obligatorio.',

  TITLE: 'El título debe contener más de 3 caracteres',
  TITLE_REQUIRED: 'Se requiere un título',
  DESCRIPTION: 'La descripción debe contener más de 10 caracteres',
  DESCRIPTION_REQUIRED: 'Se requiere una descripción',

  INCOMPLETE_CEP: 'Código postal incompleto',
  CEP_REQUIRED: 'El código postal es obligatorio',
  STATE_REQUIRED: 'El estado es obligatorio',
  CITY_REQUIRED: 'La ciudad es obligatoria',
  STREET_REQUIRED: 'La dirección es obligatoria',
  STREET_NUMBER_REQUIRED: 'El número es obligatorio',
  INVALID_CPF: 'CPF invalido!',
  CPF_REQUIRED: 'CPF es obligatorio!',

  SERVICE: 'El servicio debe tener al menos 4 caracteres',
  SERVICE_REQUIRED: 'El servicio es obligatorio',
  TIME_REQUIRED: 'Tiempo es obligatorio',
  INVALID_TIME: 'Duración de tiempo inválida',
  PRICE_REQUIRED: 'La tarifa de servicio es obligatoria',

  INVALID_EMAIL: 'E-mail inválido',
  EMAIL_REQUIRED: 'E-mail es obligatorio!',
  INVALID_CELLPHONE: 'Teléfono inválido',
  CELLPHONE_REQUIRED: 'Teléfono es obligatorio!',
  NAME: 'El nombre debe tener al menos 3 caracteres',
  NAME_REQUIRED: 'Nombre es obligatorio!',
  PASSWORD: 'Contraseña debe tener al menos 6 caracteres',
  PASSWORD_REQUIRED: 'Contraseña es obligatorio!',
  CONFIRM_PASSWORD: 'Las contraseñas deben ser las mismas',

  DUE_DATE_REQUIRED: 'La fecha de vencimiento es obligatoria',
  VALUE_REQUIRED: 'El valor del lanzamiento es obligatorio',
  CATEGORY_REQUIRED: 'La categoría es obligatoria',

  INVALID_CARD: 'Tarjeta invalida',
  CARD_REQUIRED: 'Se requiere el número de tarjeta',
  INVALID_VERIFICATION_CODE: 'Código de verificación invalido',
  VERIFICATION_CODE_REQUIRED: 'El código de verificación es obligatorio',
  INVALID_EXPIRATION_MONTH: 'Mes de vencimiento invalido!',
  EXPIRATION_MONTH_REQUIRED: 'El mes de vencimiento es obligatorio',
  INVALID_EXPIRATION_YEAR: 'Año de vencimiento invalido',
  EXPIRATION_YEAR_REQUIRED: 'El año de vencimiento es obligatorio',

  // TOASTS

  // success
  UPDATED_DATA: 'Datos actualizados correctamente',

  // error
  REVIEW_INPUTS: 'Revise los datos ingresados',

  // DAYS OF THE WEEK
  MONDAY: 'Lun',
  MONDAY_DESC: 'Lunes',
  MODAY_LETTER: 'L',

  TUESDAY: 'Mar',
  TUESDAY_DESC: 'Martes',
  TUESDAY_LETTER: 'M',

  WEDNESDAY: 'Mié',
  WEDNESDAY_DESC: 'Miércoles',
  WEDNESDAY_LETTER: 'M',

  THURSDAY: 'Jue',
  THURSDAY_DESC: 'Jueves',
  THURSDAY_LETTER: 'J',

  FRIDAY: 'Vie',
  FRIDAY_DESC: 'Viernes',
  FRIDAY_LETTER: 'V',

  SATURDAY: 'Sáb',
  SATURDAY_DESC: 'Sábado',
  SATURDAY_LETTER: 'S',

  SUNDAY: 'Dom',
  SUNDAY_DESC: 'Domingo',
  SUNDAY_LETTER: 'D',

  // CURRENCY
  CURRENCY: 'U$',

  //APPOINTMENTS
  APPOINTMENTS: 'calendario',
  NEW_APPOINTMENTS: 'Nuevo horario',
  APPOINTMENT_ERROR: '¡Hubo un error al cargar los horarios!',
  BARBERSHOP_CANNOT_SCHEDULE: 'Tu peluquería no puede concertar citas',
  VERIFY_STATUS_TRY_AGAIN: 'Verifique el estado y vuelva a intentarlo.',
  SELECT_DATE: 'seleccione fecha',
  NEW_APPOINTMENT: 'Nuevo nombramiento',
  SELECT_CUSTOMER: 'Seleccionar cliente',
  SELECT_BARBER: 'Seleccione el barbero',
  SELECT_SERVICE: 'Seleccione el servicio',
  SELECT_DATE_TIME: 'Seleccione la fecha y la hora',

  MORNING: 'mañana',
  EVENING: 'Tarde',
  NIGHT: 'Noche',

  DATE: 'Fecha',

  //APPOINTMENT MODAL
  CUSTOMER: 'Cliente',
  APPOINTMENT_TYPE: 'Tipo de reserva',
  STANDARD: 'Estándar',
  FIT: 'Encajar',
  BARBER: 'Barbero',

  DATE_TIME: 'Fecha y hora',
  CONFIRM: 'Confirmar',
  APPOINTMENT_CREATED_SUCCESSFULLY: '¡Reserva creada con éxito!',
  TIME_START_END: 'Las horas de inicio y fin son obligatorias.',
  START_TIME_UNDER_END:
    'La hora de inicio debe ser menor que la hora de finalización.',
  AVAILABLE_STEPS:
    'Para mostrar los horarios disponibles, seleccione la fecha, el servicio y el barbero.',
  NO_AVAILABLE_TIME: 'no hay tiempo disponible en el periodo',

  NAME_SIZE: 'El nombre debe contener al menos 3 caracteres',

  PHONE_REQUIRED: 'El teléfono es obligatorio',

  CUSTOMER_REGISTERED: 'CLIENTE REGISTRADO',
  CUSTOMER_NOT_REGISTERED: 'CLIENTE NO REGISTRADO',
  SEARCH_CUSTOMER: 'Buscar cliente',
  CUSTOMER_NAME: 'Nombre del cliente',
  PHONE_NUMBER: 'teléfono móvil',
  ADD_CUSTOMER: 'agregar cliente',

  //APPOINTMENT DETAIL MODAL
  APPOINTMENT_DETAILS: 'Detalles de la cita',
  SELECT_PAYMENT_METHOD: 'Seleccione el método de pago',

  FINISHED_APPOINTMENT: 'Cita completada con éxito!',
  FINISH_APPOINTMENT_ERROR: 'Hubo un error al finalizar la cita!',
  CANCELED_APPOINTMENT: 'Cita cancelada con éxito!',
  CANCEL_APPOINTMENT_ERROR: 'Hubo un error al cancelar la cita!',

  PRODUCT_NAME_SIZE:
    'El nombre del producto debe contener al menos 3 caracteres',
  PRODUCT_NAME_REQUIRED: 'el nombre del producto es obligatorio',
  PRODUCT_PRICE_REQUIRED: 'el valor del producto es obligatorio',
  PRODUCT_NAME: 'Nombre del producto',
  ADD_PRODUCT: 'agregar producto',
  ADD_PRODUCTS: 'Agregar producto',
  DISCOUNT: 'descuento',
  ADD_DISCOUNT: 'Agregar descuento',
  DISCOUNT_DESCRIPTION: 'Descripcíon del descuento',
  VALUES: 'Valores',

  SUBMIT: 'Finalizar',

  EXCLUDE_APPOINTMENT_CUSTOMER: 'Desea eliminar la cita con el cliente "',
  EXCLUDE_APPOINTMENT: 'eliminar cita',

  OPTIONAL_EMAIL: 'E-mail (opcional)',

  // HOME (FreeTrialModal)
  FREE_TRIAL_MODAL_TITLE_FIRST_PART: 'Gana ',
  FREE_TRIAL_MODAL_TITLE_SPAN: '7 días ',
  FREE_TRIAL_MODAL_TITLE_SECOND_PART: 'de prueba por nuestra cuenta!',
  FREE_TRIAL_MODAL_SUBTITLE:
    'Obtenga acceso a todas las funciones exclusivas y mejore el servicio y la experiencia de gestión de su barbería!',
  FREE_TRIAL_PERIOD: 'Primeros 7 días gratis',
  AFTER_FREE_TRIAL: 'Después de este período ',
  AFTER_FREE_TRIAL_SPAN: '$ 69,00/mes',
  MEMBERSHIP_BENEFITS: 'Ver beneficios de suscripción',
  MEMBERSHIP_BENEFIT_1: 'Gestión de horarios',
  MEMBERSHIP_BENEFIT_2: 'Número ilimitado de barberos',
  MEMBERSHIP_BENEFIT_3: 'Creación de servicios personalizados.',
  MEMBERSHIP_BENEFIT_4: 'Control financiero automatizado',
  MEMBERSHIP_BENEFIT_5: 'Gestión de clientes',
  MEMBERSHIP_BENEFIT_6: 'Acceso exclusivo para cada barbero',
  MEMBERSHIP_BENEFIT_7: 'Notificación de cita',
  START_FREE_TRIAL: 'Iniciar mi prueba gratuita',
  AUTOMATIC_RENOVATION: 'Renovación automática. Cancela cuando quieras.',

  // HOME (ConfirmFreeTrialModal)
  CONFIRM_FREE_TRIAL_MODAL_TITLE: 'Tu prueba gratuita ha comenzado!',
  CONFIRM_FREE_TRIAL_MODAL_SUBTITLE:
    'Hemos enviado un correo electrónico con su suscripción y datos de facturación a la dirección de correo electrónico:',
  ATENTION: 'Aviso',
  REMEMBER_TO_PAY:
    'Recuerde pagar la suscripción después del período de prueba para no perder el acceso a las funciones!',
  OPEN_BILLING_EMAIL: 'Abra el correo electrónico de facturación',
  WE_WILL_SEND_EMAIL:
    'Le enviaremos un correo electrónico con información sobre su suscripción y un enlace para pagar su suscripción.',
  PAY_MEMBERSHIP: 'Realiza el pago de la suscripción',
  OPEN_THE_LINK:
    'Acceda al enlace dentro del correo electrónico y complete sus detalles de pago. ¡Puedes estar tranquilo, es seguro!',
  ALRIGHT: 'Todo cierto!',
  USE_THE_APP:
    'Si todo va bien con su pago, no necesita hacer nada más. ¡Ahora solo usa la aplicación!',
  SELECT_SOURCE: 'fuente de programación',

  // ExpiredFreeTrialModal
  EXPIRED_FREE_TRIAL_MODAL_TITLE:
    'Su período de prueba gratuito ha finalizado.',
  EXPIRED_FREE_TRIAL_MODAL_SUBTITLE:
    'Suscríbete para seguir disfrutando de todos los recursos y facilidades para tu barbería.',
  SUBSCRIBE_PRICE: 'Suscríbete por $ 69,00/mes',

  //HOME (subscription banner)
  TRIAL_ENDING: 'Solo quedan ',
  TRIAL_ENDING_2: ' días de prueba gratis',
  TRIAL_ENDING_SUBTITLE:
    'Suscríbase a Premium y obtenga acceso a herramientas exclusivas en la aplicación.',
  TRIAL_ENDED: 'Su prueba gratuita ha finalizado',
  TRIAL_ENDED_SUBTITLE:
    'Suscríbete para seguir disfrutando de todos los recursos y facilidades para tu barbería.',
  SUBSCRIPTION_SUSPENDED: 'Su suscripción ha sido suspendida',
  SUBSCRIPTION_SUSPENDED_SUBTITLE:
    'Para volver a acceder, póngase en contacto con nuestro soporte haciendo clic aquí.',
  SUBSCRIPTION_PENDING_FEE: 'Su suscripción tiene problemas!',
  SUBSCRIPTION_LINK:
    'Hay un problema con su suscripción. Para corregirlo, haga clic en el siguiente enlace:',
  REDIRECT: 'Redirigir',
  PENDING_BILL: 'Factura pendiente',
  BILL_LINK:
    'Su factura ya está disponible para el pago. Para completarlo, haga clic en el siguiente enlace:',

  //HOME (stat card)
  INCOME: 'Facturación',
  MONTHLY_NEW_CLIENTS: 'Nuevos clientes',
  THIS_MONTH: 'Este mes',

  SERVICES_DETAILS: 'Detalle de los servicios',

  //HOME (Month appointments chart)
  DAILY_INCOMING_CHART: 'gráfico de ingresos por día',
  INCOMING: 'ingresos',

  //HOME (Month appointments products)
  SERVICES_REPORT: 'informe de servicio',
  INCOME_TABLE: 'ingreso',
  QUANTITY: 'cantidad',

  // RATINGS SCREEN
  RATINGS: 'Calificaciones',

  // BARBERSHOP RATING
  AVERAGE_BARBERSHOP_RATING: 'Calificación promedio de peluquería',
  BASED_IN_30_DAYS: 'Basado en los últimos 30 días',
  RECEIVED_RATINGS_TOTAL: 'Total de calificaciones recibidas',

  // SERVICES SCREEN
  LOAD_SERVICES_ERROR: 'Hubo un error al cargar los servicios.',
  SERVICES_TITLE: 'Servicios',
  ADD_SERVICE_BUTTON: 'Crear Servicio',
  SELECT: 'Seleccionar',
  SERVICES_BLOCK_MESSAGE_HEADER: 'Límite de 4 en la versión gratuita',
  SERVICES_BLOCK_MESSAGE:
    'Agregue tantos servicios como desee en la versión premium!',

  // SERVICES MODAL
  SERVICE_CREATED_SUCCESS: 'Servicio creado/actualizado con éxito!',
  ADD: 'Crear',
  EDIT_SERVICE: 'Actualizar',
  _SERVICE: 'Servicio',
  MAKE_PROMOTIONAL: 'Poner en promoción',
  IN_PROMOTION_DAYS: 'Días de promoción',
  DELETE_SERVICE: 'Eliminar servicio',
  DELETE_SERVICE_CONFIRM: 'Quieres eliminar el servicio ',
  SERVICE_UPDATED: '¡Servicio actualizado con éxito!',
  SERVICE_ADDED: '¡Servicio añadido con éxito!',
  ERROR_SAVING_SERVICE: 'Servicio de ahorro de errores',
  SERVICE_DELETED: '¡Servicio eliminado con éxito!',
  ERROR_EXCLUDING_SERVICE: '¡Error al excluir el servicio!',

  // TEAM SCREEN
  DELETE_BARBER_SUCCESS: 'Barbero eliminado con éxito!',
  TEAM_TITLE: 'Equipo',
  ADD_TEAM: 'Agregar Equipo',
  SEARCH_BARBER: 'Buscar por colaborador',
  DELETE_BARBER: 'Eliminar colaborador',
  DELETE_BARBER_CONFIRM: 'Quieres eliminar ',
  TEAM_BLOCK_MESSAGE_HEADER: 'Límite de 2 peluqueros en la versión gratuita',
  TEAM_BLOCK_MESSAGE:
    'Añade tantos peluqueros como quieras en la versión premium!',
  ADD_OTHER_FUNCTIONS: 'Agregue otras funciones en la versión premium!',

  // BARBER CARD
  BARBER_PHONE: 'Teléfono',

  //TEAM MODAL
  ADD_BARBER_SUCCESS: 'Barbero agregado con éxito!',
  ADD_BARBER_ERROR: 'Hubo un error al agregar barbero',
  ADD_BARBER: 'Agregar Colaborador',
  BARBER_NAME_PLACEHOLDER: 'Nombre del Barbero',
  PHONE_PLACEHOLDER: 'Teléfono',
  BARBER_LIMIT:
    'No se pueden agregar más barberos. Suscríbase al plan para obtener acceso a más funciones.',

  // EDIT TEAM
  EDIT_TEAM: 'Editar Colaborador',
  EDIT_TEAM_SUCCESS: 'Colaborador editado con éxito!',
  EDIT_TEAM_ERROR: 'Hubo un error al editar colaborador.',

  // BARBER MODAL
  EDIT_COMMISSION_ERROR: 'Hubo un error al editar la comisión del peluquero',
  EDIT_COMMISSION_SUCCESS: 'Comisión cambiada con éxito',
  RATINGS_AVERAGE: 'Promedio de calificaciones',
  BARBER_COMMISSION_SERVICE: 'Comisión del servicios',
  BARBER_COMMISSION_PRODUCT: 'Comisión del productos',
  BARBER_COMMISSION_SERVICE_TABLE: 'Comisión del servicios %',
  BARBER_COMMISSION_PRODUCT_TABLE: 'Comisión del productos %',
  SEE_ALL_MESSAGES: 'Ver todo',

  // ACTION MODAL
  DELETING: 'Eliminando',

  // EDIT APPOINTMENT
  EDIT_APPOINTMENT: 'Actualizar Cita',
  EDIT_APPOINTMENT_SUCCESS: 'Cita actualizada con éxito!',
  EDIT_APPOINTMENT_ERROR: 'Hubo un error al actualizar la cita!',
  //MENU (Status Modal)
  BARBERSHOP_STATUS: 'Actualización del estado de la barbería!',
  BARBERSHOP_STATUS_ERROR: 'Error. El estado no se ha actualizado.',
  SET_STATUS: 'Establecer el estado',
  CHOOSE_STATUS:
    'Elija entre las siguientes opciones cuál es el estado de su barbería',
  ACTIVE: 'Activo',
  ACTIVE_DESC:
    'Tu barbería estará disponible para reservar y los clientes podrán encontrarla en la app.',
  PRIVATE: 'Privado',
  PRIVATE_DESC:
    'Su barbería estará disponible para reservas, pero los clientes NO podrán encontrarla en la aplicación.',
  PENDING: 'Listo para publicar',
  PENDING_DESC:
    'Su barbería no está publicada en el sistema. Tiene los requisitos necesarios para publicarla.',
  NOT_PUBLISHED: 'No publicado',
  NOT_PUBLISHED_DESC:
    'Tu barbería NO podrá aceptar citas y los clientes NO podrán encontrarte en la app.',
  BLOCKED: 'bloqueado',
  BLOCKED_DESC:
    'Su suscripción a BestBarbers está pendiente. Por favor, vaya a nuestro centro de suscripción.',

  //MENU (Menu itens)
  SCHEDULES: 'Horarios',
  AGENDA: 'Agenda',
  HISTORIC: 'Histórico',
  FINANCES: 'Finanzas',
  PAYMENTS: 'Pagos',
  REPORTS: 'Informes',
  ADM: 'Adm',
  CLIENTS: 'Clientes',
  PROFILE: 'Perfil',
  //BARBERS SCREEN
  BARBERS_REPORT: 'Informe de los barberos',
  FINISHED_APPOINTMENTS_COUNT: 'Citas finalizadas',
  FINISHED_APPOINTMENTS_COUNT_DESC: 'Número de calendarios ya finalizados.',
  //PERFIL (profile menu)
  MAIN_DATA: 'Datos principales',
  LOGIN_DATA: 'Datos de acceso',
  BARBESHOP_LINK: 'Enlace de la barbería',
  BLOCKED_TIME: 'Horas bloqueadas',
  PASSWORD_PROFILE: 'contraseña',
  EXIT: 'cierre de sesión',

  //PROFILE (Dados principais)
  CHANGE_LOGO_BUTTON: 'cambiar el logotipo',
  BARBERSHOP_DESC_PLACEHOLDER: 'Describa aquí sus servicios',
  BARBERSHOP_LINK: 'Link de peluquería',
  MAIN_PHONE: 'Teléfono principal',
  SAVE_CHANGES: 'Guardar los cambios',
  CPF: 'Doc ID',
  CNPJ: 'Doc ID',

  //PROFILE (Share link)
  UPDATED_LINK: 'Enlace actualizado con éxito',
  COPIED_LINK: 'Enlace copiado',
  LINK_DESC:
    'El enlace es una forma fácil de compartir el perfil de su barbería con sus clientes y de que éstos puedan reservar con su barbería de forma práctica y sencilla.',
  LINK_NOTE:
    'Nota: El enlace no puede contener acentos, caracteres especiales o espacios en blanco. Sólo estará disponible si su peluquería tiene el estado "Activo"..',
  COPY_LINK: 'copiar enlace',

  //PROFILE (Location)
  ADDRESS_ERROR: 'fallo en la carga de la dirección.',

  //PROFILE (photos/action modal)
  EXCLUDING: 'excluyendo',
  SENDING: 'Enviando',
  EXCLUDE_PHOTO: 'eliminar foto',
  EXCLUDE_PHOTO_CONFIRMATION: '¿Estás seguro de que quieres borrar esta foto?',

  //PROFILE (Blocked times)
  BLOCKED_TIME_TOAST: 'Horario bloqueado con éxito.',
  UNBLOCKED_TIME_TOAST: 'Horario desbloqueado con éxito.',
  OPTIONS: 'opciones',
  REPEAT_TIME_EVERYDAY: 'Repetir todos los días',
  ADD_TIME: 'Adicionar horário.',
  BLOCK_TIME_CONFIRMATION: '¿Desea bloquear este horario?',
  YES: 'Si',
  NO: 'No',

  //PROFILE (Plan/plan invoice card)
  MY_PLAN: 'Mi plan',
  CURRENT_PLAN: 'plan actual:',
  SING_UP_DATE: 'fecha de ingreso:',
  LAST_PAYMENTS: 'últimos pagos:',
  PAYMENT_NOT_FOUND: 'no se ha encontrado ningún pago',
  VIEW: 'ver',
  EXPIRED: 'Expirado',

  //PROFILE (Change password)
  ACTUAL_PASSWORD: 'Contraseña actual',
  NEW_PROFILE_PASSWORD: 'Nueva contraseña',
  ACTUAL_PASSWORD_REQUIRED: 'Se requiere la contraseña actual',
  NEW_PASSWORD_REQUIRED: 'Se requiere la nueva actual',
  NEW_PASSWORD_LENGTH: 'La nueva contraseña debe tener al menos 6 caracteres',

  //PROFILE (Logout modal)
  EXIT_CONFIRMATION: '¿Realmente quieres irte?',
  BARBERSHOP_PROFILE: 'Perfil de la barbería',
  IMAGE: 'Imagen',

  //Profile -> Blocked times
  EVERYBODY: 'Todos',
  EVERYDAY: 'Todos los días',
  EVERY: 'Toda',

  PAID: 'Pagado',
  CANCELED: 'Cancelado',
  PARTIALLY_PAID: 'parcialmente pagado',
  REFUNDED: 'Devuelto',
  AUTHORIZED: 'Autorizado',
  IN_PROTEST: 'En protesta',
  NO_STATUS: 'sin estatus',

  // ClientDetails
  INFORMATIONS: 'Información',
  RECENT_OBSERVATIONS: 'Últimos comentarios',
  SEE_ALL: 'Ver todo',
  NO_OBSERVATIONS: 'No se han hecho comentarios',
  NEXT_APPOINTMENT: 'Próxima cita',
  NO_APPOINTMENT: 'No hay citas programadas para este cliente.',
  OBSERVATIONS: 'Comentarios',
  OBSERVATION_SAVE_ERROR: 'Error al guardar observacion',
  DESCRIPTION_PLACEHOLDER: 'Descripcíon',
  CUSTOMER_STATISTICS: 'Estadísticas de clientes',
  FINISHED: 'Finalizado',
  AVERAGE_TICKET: 'Ticket medio',
  TOTAL_PROFIT: 'Total ricibido',
  DELETE_OBSERVATION: 'Borrar nota',
  DELETE_OBSERVATION_DESC: 'Quieres eliminar esta nota?',
  DELETE_OBSERVATION_SUCCESS: 'Nota eliminada con éxito!',
  DELETE_OBSERVATION_ERROR: 'Hubo un error al eliminar nota.',

  // PREMIUM FEATURES
  BLOCKED_FEATURE:
    'Esta funcionalidad es exclusiva para nuestros suscriptores. Haga clic en el botón a continuación para comprar su suscripción y obtener acceso a esta y muchas otras funciones.',
  SIGN_NOW: 'Quiero ser premium!',
  HOME_BLOCKED: 'El informe mensual es exclusivo para suscriptores.',
  LINK_BLOCKED:
    'El enlace de programación personalizada es solo para suscriptores.',
  BARBERS_REPORT_BLOCKED:
    'El informe de los peluqueros es exclusivo para suscriptores.',
  COMMISSIONS_REPORT_BLOCKED:
    'El informe de comisiones es exclusivo para los suscriptores.',
  BLOCKED_FEATURE_TITLE: '{{section}} es exclusivo para suscriptores.',

  //SOURCE
  CLIENT_MOBILE_APP: 'Programado por el Cliente en la App',
  BARBER_MOBILE_APP: 'Programado por el Peluquero - en la App',
  BARBERSHOP_MOBILE_APP: 'Programado por Barber Shop en la App',
  BARBERSHOP_MOBILE_APP_WPP: 'Programado por Barbershop - WhatsApp',
  BARBERSHOP_MOBILE_APP_PRESENTIAL: 'Programado por Barbershop - Face to Face',

  //ADDITIONAL PRODUCTS
  ERROR_LOADING_PRODUCTS: 'Error al cargar productos adicionales',
  SUCCESS_PRODUCTS_CREATED: 'Producto creado con éxito',
  ERROR_CREATE_PRODUCTS: 'Error al crear un producto adicional',
  SUCCESS_PRODUCTS_DELETE: 'Producto eliminado con éxito',
  ERROR_DELETE_PRODUCTS: 'Error al borrar un producto',
  SUCCESS_PRODUCTS_UPDATED: 'Producto cambiado con éxito',
  ERROR_UPDATE_PRODUCTS: 'Error al cambiar de producto',
  ADD_PRODUCT_BUTTON: 'Añadir producto',
  CONFIRM_DELETE_PRODUCT: 'Desea eliminar el producto',
  ERROR_REMOVE_PRODUCT_APPOINTMENT: 'Error al eliminar el producto',
  ADDITIONAL_PRODUCTS_PREMIUM_MESSAGE:
    'Los productos son exclusivos para suscriptores Premium',

  TOTAL_REVENUE_TITLE: 'Ingresos brutos',
  TOTAL_REVENUE_SUBTITLE: 'Importe facturado hasta el momento',
  TOTAL_PREDICTION_SUBTITLE: 'Previsión de ventas',
  // EXPENSES_TITLE: 'Gastos',
  EXPENSES_SUBTITLE: 'Publicado',
  BALANCE: 'Balance',
  MES: 'Mes',
  TOTAL: 'Total',
  BARBERS_COMMISSION: 'Comisión de Barberos',
  TOTAL_CLIENTES: 'Total de clientes',
  NEW_CLIENTS: 'Nuevos clientes',
  ATENDIDO: 'Atendido',
  AWAITING: 'Esperando',
  DESTACADO: 'Pendiente',
  FINALIZADO: 'Finalizado',
  SALES_PRODUCT: 'Venta de productos',
  SALES_SERVICE: 'Venta de servicios',
  SALES_PRODUCT_TABLE: 'Ventas - productos',
  SALES_SERVICE_TABLE: 'Ventas - Servicios',

  BALANCE_MONTH: 'Balance del mes',
  RELEASE: 'Postings',
  REVENUES: 'Ingresos contabilizados',
  EXPENSES_TITLE: 'Gastos contabilizados',
  PREDITICTION: 'Previsión de saldo del mes',
  PREDITICTION_SUBTITLE: 'Valor neto aproximado',
  PREVISION: 'Previsión',
  GROSS_REVENUE: 'Facturación bruta',
  APPOINTMENT: 'Programación',
  RECEIVED_UNTIL_NOW: 'Recibido hasta ahora',
  DISCOUNTS_UNTIL_NOW: 'Descontado hasta la fecha',
  NO_DATA_RESUME: 'No se mostrarán datos para el mes seleccionado',
  EXTRACT: 'Extracto',
  TOTAL_SALES: 'Ventas totales',
  TOTAL_COMMISSION: 'Valor total del periodo',
  APPOINTMENTS_BY_DAY: 'Citas por día',
  _REVENUES: 'Ingresos',
  _EXPENSES: 'Gastos',
  SELL_SERVICE: 'Venta de servicios',
  FILTER: 'Filtros',
  APPLY: 'Aplicar',
  NOT_INFORMED: 'No informado',
  START: 'Inicio',
  END: 'Fin',
  PAYMENT: 'Pago',
  IN_TOTAL: 'clientes en total',
  CLIENTE_CREATE_APPOINTMENT:
    'Seleccione o cliente a partir da lista de clientes registados',

  BF_BANNER_1: 'La mejor inversión para su barbería',
  BF_BANNER_2: 'con un descuento imperdible',
  FOR_MONTH: '/mes',
  FOR_ONLY: 'Sólo para',
  DURING_MONTH: 'durante 3 meses o',
  ANUAL_PLAN: 'en el plan anual (598,80 dólares)',
  BUT_HURRY: '¡Pero corre, que es por tiempo limitado!',
  OFFER_UNTIL: 'Oferta disponible hasta el 30/11/2022',
  SUBSCRIBE_NOW: 'Suscribir ahora',
  LEARN_MORE: 'Más información',

  MODAL_1: '¡Gane una semana de prueba con nosotros!',
  GO_BACK: 'Atrás',
  STATUS_APPOINTMENT: 'Estado de la cita: ',
  AWAITING_: 'Esperando',
  PENDING_: 'Pendiente',
  FINISHED_: 'Terminado',
  NO_DISCOUNT: 'No se ha añadido ningún descuento',
  NO_PRODUCT: 'No hay productos añadidos',
  PAYMENT_FORM: 'Forma de pago',
  PAYMENT_DATE_DESC: 'Recibirá este pago el día ',
  PAYMENT_DONE: 'Pago recibido el día ',
  PAYMENT_PENDING: 'Pago pendiente',
  SUBTOTAL: 'Subtotal',
  SOURCE_FORM: 'Forma de nombramiento no informada',
  PROFESSIONAL: 'Profesional',
  ADD_SOURCE: 'Añadir fuente de programación',
  PERCENTAGE_PROD: 'Venta de producto - barbero: ',
  PERCENTAGE_SERV: 'Servicio de peluquería: ',
  PERCENTAGE_PAY: 'Forma de pago: ',
  PRODUCTS_COMISSION: 'Comisión del producto',
  SERVICE_COMISSION: 'Comisión de servicio',
  BARBER_PROFILE: 'Barbero añadido',

  TOTAL_ENTRIES: 'Total de entradas (ingresos)',
  UNTIL_MOMENT: 'Hasta ahora',
  TOTAL_EXITS: 'Total salidas (gastos)',
  PER_DAY: 'Por día',
  CLIENT_AVERAGE: 'Número medio de clientes',
  DISCOUNTS: 'Descuentos',
  OTHER_DISCOUNTS: 'Otros descuentos ofrecidos',
  LIQUID_VALUE: 'Valor neto (Ingresos - Gastos)',
  LIQUID_VALUE_MOBILE: 'Valor neto (Ingresos - Gastos)',

  APPOINTMENTS_MONTH: 'reservas en ese mes',
  NEXT_APPOINTMENTS: 'Próximas citas hoy:',
  NO_APPOINTMENT_TODAY: 'No hay próximas citas',
  SEE_APPOINTMENTS: 'Ver todas las citas del día',

  SELECT_PRODUCTS: 'Seleccionar productos',
  BLOCKED_TIMES: 'Horario bloqueado',

  NOTIFICATIONS: 'Notificaciones',
  NOTIFICATIONS_DESC:
    'Establece tus preferencias sobre el envío de notificaciones por correo electrónico y push',
  SEND_EMAIL:
    'Sus clientes y barberos recibirán notificaciones por correo electrónico',
  SEND_PUSH:
    'Tus clientes y peluqueros recibirán notificaciones push en el móvil',
  COMPLEMENT: 'Complementar',
  SELECT_PRODUCTS: 'Seleccionar productos',
  PERCENTAGE_SERVICES: '(%) Servicios',
  PAYMENT_METHOD_REPORT: 'Detalles del cliente',
  CLIENTS_REPORT: 'Desglose de clientes',
  LAST_APPOINTMENT: 'ÚLTIMA ASISTENCIA',
  TOTAL_PRODUCTS: 'Cantidad total de productos',
  TOTAL_SERVICES: 'Importe total servicios',
  MORE_SERVICE: 'Servicio más utilizado',
  SALES_PRODUCT_CLIENT: 'Venta de productos (finalizada)',
  SALES_SERVICE_CLIENT: 'Ventas de servicios (finalizadas)',
  CLIENT: 'Cliente',
  TOTAL_DESCUENTOS_TABLA: 'TOTAL EN DESCUENTOS',
  COMISSIONS_REPORT: 'Desglose de comisiones',
  APPOINTMENTS_1: 'CITAS-',
  HAVE: 'Há ',
  TOTAL_DISCOUNTS: 'Descuentos ofrecidos',
  CLIENT_UNTIL: 'Cliente desde ',
  product: 'Producto',
  BILLING_PERCENTAGE: '(%) FACTURACIÓN',
  SELLS_REPORT: 'Detalle de ventas',
  SEE_MORE: 'Ver más',
  PRODUCTOS: 'Productos',
  RESPONSABLED_NAME: 'Nombre de la persona responsable',
  RECURRENT: '¿Recurrente?',
  SELLS: 'Ventas',

  TERMS_AND_POLICYS_1: 'Al registrarse en la plataforma, usted acepta con',
  TERMS_AND_POLICYS_2: 'los ',
  AND: 'y ',
  CELLPHONE: 'Teléfono',
  BARBERSHOP_NAME_PLACEHOLDER: 'Ex: Barbearia Best Barbers',
  OWNER_NAME_PLACEHOLDER: 'Ex: Antônio José',
  FREE_TRIAL_MODAL_TITLE_FIRST_PART_NEW: 'Ganaste',
  BASIC: 'Básico',

  PAYMENT_MODAL_TITLE: 'Elige el método de pago que más te convenga',
  PIX: 'Pix',
  CREDIT: 'Crédito',
  TICKET: 'Boleto',
  PAYMENT_MODAL_SUBTITLE_1: 'Registra tu tarjeta',
  NOW: 'ahora',
  AND_WIN: 'y obtén',
  DISCOUNT_10_PERCENT: '15 días gratis',
  CPF_OR_CNPJ: 'CPF o CNPJ del titular',
  NUMBER_CARD: 'Numero de tarjeta',
  COMPLETE_NAME_CARD: 'Nombre completo (escrito en la tarjeta)',
  CVV: 'CVV',
  EXPIRE_AT: 'Fecha de vencimiento',
  CREDIT_CARD_ADVISE:
    'Se puede cobrar una cantidad de hasta BRL 1,00 por validar su tarjeta de crédito',
  ADD_CREDIT_CARD: 'Tarjeta añadida con éxito!',
  ADD_CREDIT_CARD_ERROR: 'Error al añadir la tarjeta!',
  ADD_CREDIT_CARD_ERROR_DESC: 'Revise los datos y vuelva a intentarlo.',

  PREMIUM_EXCLUSIVE: 'Solo disponible para suscriptores Premium',
  BEGIN_PREMIUM: 'Comenzar Premium',

  // SIGN PREMIUM MODAL
  SIGN_PREMIUM_MODAL_TITLE: 'Tu barbería puede hacer ',
  SIGN_PREMIUM_MODAL_TITLE_SPAN: 'mucho más!',
  MEMBERSHIP_BENEFIT_1_NEW: 'Gestión de citas',
  MEMBERSHIP_BENEFIT_2_NEW: 'Acceso exclusivo para cada barbero',
  MEMBERSHIP_BENEFIT_3_NEW: 'Link de reserva personalizado',
  MEMBERSHIP_BENEFIT_4_NEW: 'Número ilimitado de barberos',
  MEMBERSHIP_BENEFIT_5_NEW: 'Número ilimitado de servicios',
  MEMBERSHIP_BENEFIT_6_NEW: 'Control financiero automatizado',
  MEMBERSHIP_BENEFIT_7_NEW: 'Informes comerciales y métricas',
  MEMBERSHIP_BENEFIT_8_NEW: 'Notificación de citas',
  MEMBERSHIP_BENEFIT_9_NEW: 'Productos y descuentos en citas',
  SIGN_PREMIUM_MODAL_BUTTON: 'Suscríbete ahora por {{currency}} 69,90/mes',

  MEMBERSHIP_BENEFIT_1_NEW: 'Gestão de agendamentos',
  MEMBERSHIP_BENEFIT_2_NEW: 'Acesso exclusivo para cada barbeiro',
  MEMBERSHIP_BENEFIT_3_NEW: 'Link de agendamento personalizado',
  MEMBERSHIP_BENEFIT_4_NEW: 'Número ilimitado de barbeiros',
  MEMBERSHIP_BENEFIT_5_NEW: 'Número ilimitado de serviços',
  MEMBERSHIP_BENEFIT_6_NEW: 'Controle financeiro automatizado',
  MEMBERSHIP_BENEFIT_7_NEW: 'Relatórios e métricas do seu negócio',
  MEMBERSHIP_BENEFIT_8_NEW: 'Notificação de agendamentos',
  MEMBERSHIP_BENEFIT_9_NEW: 'Produtos e descontos nos agendamentos',
  UNITYS: 'Unidades',
  SEND_NOTIFICATION: 'Enviar notificación',
  SEND_NOTIFICATION_TITLE:
    'Envía un mensaje masivo a todos tus clientes de forma sencilla y cómoda.',
  SEND_NOTIFICATION_PLACEHOLDER: 'Ej: Nueva promoción en la peluquería',
  SEND_NOTIFICATION_SUCCESS: 'Mensaje enviado con éxito',
  SEND_NOTIFICATION_ERROR: 'Error al enviar el mensaje',
  SEND: 'Enviar',

  // SINGLE PRODUCT SELL
  SINGLE_PRODUCT_SELL: 'Venta única',
  SINGLE_PRODUCT: 'Producto único',
  BARBER_OPTIONAL: 'Barbero (opcional)',
  SINGLE_SALE_SUCCESS: 'Venta creada con éxito',
  SINGLE_SALE_ERROR: 'Hubo un error al crear una sola venta',
  SINGLE_SALE_DELETE_SUCCESS: 'Venta única eliminada con éxito',
  SINGLE_SALE_DELETE_ERROR: 'Hubo un error al eliminar sola venta',
  SELECT_DISCOUNT: 'Seleccionar descuento',
  GET_SINGLE_SALE_ERROR: 'Hubo un error al cargar sola venta',
  CONFIRM_DELETE_SINGLE_SALE: 'Quieres eliminar la venta única?',
  DELETE_SINGLE_SALE_SUCCESS: 'Venta única eliminada con éxito',
  DELETE_SINGLE_SALE_ERROR: 'Hubo un error al eliminar una sola venta',
  PRODUCT_SALE: 'Venta de productos',

  ERROR_GET_UNITYS: 'Hubo un error al cargar unidades.',
  CHANGE_UNITY: 'Cambiar unidade',

  MEET_US: 'Cómo nos conociste?',
  MEET_US_DESC:
    'Ayúdanos haciéndonos saber dónde encontraste BestBarbers para que podamos llegar a más barberías.',
  SAVE_DATA_SUCCESS: 'Datos guardados correctamente',

  EDIT_PERMISSION: 'Editar Permiso',
  EDIT_PERMISSION_TITLE: 'Editar permiso de colaborador',
  FINANCE: 'Financiero',
  SINGLE_PRODUCT_PERMISSION: 'Venta de productos separados',
  APPOINTMENT_PERMISSION: 'Horarios',
  CLIENTS_PERMISSION: 'Clientes',
  HISTORIC_PERMISSION: 'Histórico',
  SERVICES_PERMISSION: 'Servicios',
  BLOCKED_HOURS_PERMISSION: 'Tiempos bloqueados',
  TEAM_PERMISSION: 'Equipo',
  ADDITIONAL_PRODUCTS_PERMISSION: 'Productos adicionales',
  UNITYS_PERMISSION: 'Unidades',
  PAYMENT_METHODS_PERMISSION: 'Métodos de pago',
  NOTIFICATIONS_PERMISSION: 'Notificaciones',
  BLOCKED_PERMISSION: 'Sin acceso',
  ONLY_READ: 'Solo lectura',
  EDIT: 'Crear y editar',
  PERMISSION_SUCCESS: 'Permisos actualizados con éxito',
  PERMISSION_ERROR: 'Hubo un error al actualizar permisos',
  PERMISSION_DENIED: 'No tienes permiso para realizar esta acción.',
  NOTIFICATIONS_TITLE: 'Notificaciones de peluquería',
  NOTIFICATIONS_DESC_BARBERSHOP:
    'Establezca sus preferencias de notificación de barbería para correo electrónico y notificaciones automáticas',
  FLAG: 'Bandeira',

  //Subscription --> Benefits -> SubscriptionModal
  REGULARIZE_SIGNATURE: 'Regularizar firma',
  SUBSCRIPTION_BENEFITS: 'Ver beneficios de suscripción',
  SUBSCRIPTION_HAS_EXPIRED: '¡Su suscripción ha expirado!',
  SUBSCRIPTION_PLAN_BENEFITS:
    '¡Tu plan de suscripción a BestBarbers ha caducado! ¡No dejes colgados a tus clientes! ¡Regulariza tu plan ahora mismo y vuelve a disfrutar de todos los beneficios de la versión premium!',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_1: 'Notificación de cita',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_2: 'Enlace de reserva personalizado',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_3: 'Control financiero 100% automatizado',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_4: 'Gestión de horarios',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_5: 'Número ilimitado de barberos',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_6: 'Acceso exclusivo para cada barbero',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_7: 'Gestión de clientes',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_8: 'Creando servicios personalizados',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_9: 'Informes y métricas para tu negocio',
  TO_GO_BACK: 'Para volver',
  PAYMENTS_MANAGER: 'Gestión de pagos',
  SIGNATURE_CLUB: 'Club de Suscripción',
  PAYMENT_ON_APP: 'Pago por Aplicación',
  SUBSCRIBERS: 'Suscriptores',
  ANTECIPATIONS: 'Saldo y prepagos',
  SIGNATURE_CLUB_DESC:
    'Con el club de la firma, tus clientes pagarán con tarjeta de crédito una serie de servicios que ofrece la peluquería y tendrán derecho a disfrutar de estos servicios con una cantidad fija al mes',
  SIGNATURE_BLOCKED: 'El club de suscripción es exclusivo para suscriptores.',
  PAYMENT_ON_APP_BLOCKED:
    'El pago de la aplicación es exclusivo para suscriptores.',
  ANTECIPATIONS_BLOCKED: 'Saldo y prepagos son exclusivos para suscriptores.',
  ACTIVATE_SIGNATURE_CLUB: 'Activar servicio de suscripción',
  SIGNATURE_WARN_1:
    'El monto de la suscripción se cargará automáticamente a su cliente mensualmente',
  SIGNATURE_WARN_2:
    'No se preocupe! Toda la gestión de suscripciones será realizada por BestBarbers',
  SIGNATURE_WARN_3:
    'Recuerde hacer clic en el botón Guardar para aplicar los cambios.',
  PAYMENT_APP_ON: 'Habilitar el pago en la aplicación',
  PAYMENT_ON_APP_DESC:
    'Al habilitar el pago a través de la aplicación, el cliente podrá pagar con tarjeta de crédito al reservar',
  CHARGE_FORM: 'Método de facturación',
  ON_SCHEDULE: 'Cobrar al cliente en el momento de la programación',
  ON_FINISH: 'Cobrar al cliente al finalizar la cita',
  PAYMENT_ON_APP_DESC_2:
    'El pago en la barbería seguirá activo, quedando a elección del cliente.', //ok
  CLICK_TO_SAVE: 'Debe hacer clic en guardar para cambiar las preferencias',
  ON_SCHEDULE_DESC:
    'Descripción: El cargo en la tarjeta se realizará en el momento en que el cliente solicite la cita', //ok
  ON_FINISH_DESC:
    'Descripción: La tarjeta se cargará cuando la barbería finalice la cita', //ok
  CANCEL_TAXES_WARN:
    'Si el cliente cancela la cita que ya ha sido pagada, se cobrará una tarifa del 5% del valor de la transacción realizada.',
  HABILITY: 'Habilitar',
  DAYS_TO_CHARGE:
    'El retiro del monto estará disponible 32 días después del pago',
  ANTECIPATION_SUCCESS: 'Anticipación realizada con éxito',
  ANTECIPATION_ERROR: 'Hubo un error al realizar la anticipación',
  BALANCE_EXCHANGE: 'Saldo disponible para retiro',
  PENDING_VALUES: 'Importes pendientes de cobro',
  SELECT_VALUES_ANTECIPATION: 'Seleccione las cantidades que desea anticipar',
  LIQUID_VALUE_ANTECIPATION: 'Valor neto',
  PREVISION_EXCHANGE: 'Pronóstico de retiro',
  ANTECIPATE: 'Anticiparse',
  EXCHANGE: 'Retirar por PIX',
  PIX_SUCCESS: 'Pix hecho con éxito',
  PIX_ERROR: 'Hubo un error al hacer pix',
  PIX_TYPES: 'Tipos de llaves',
  PIX_KEY: 'Llave PIX',
  CONFIRM_PIX: 'Quieres retirar el valor de ',
  NO_SUBSCRIBERS: 'No se encontraron suscriptores',
  SEARCH_SUBSCRIBERS: 'Buscar por suscriptor',
  PLAN_DETAIL: 'Detalles del plan',
  CANCEL_SIGNATURE: 'Darse de baja',
  EXPIRED_AT: 'Fecha de vencimiento',
  CONFIRMED: '',
  STATUS: 'Status',
  CANCEL_SIGNATURE_DESC: 'Deseja cancelar una suscripción do cliente?',
  CANCEL_SIGNATURE_SUCCESS: 'Suscripción cancelada con éxito',
  CANCEL_SIGNATURE_ERROR: 'Hubo un error al cancelar suscripción',
  TYPE: 'Tipo',
  signature: 'Suscripción',
  normal: 'Normal',
  FLAG: 'Bandera',
  MOBILE_PHONE: 'Teléfono celular',
  CONFIRMATE_DATA: 'Confirmar datos',
  MODAL_CONFIRMATE_DATA_SUBTITLE:
    'Para que no haya problemas con los pagos de tu barbería, revisa tus datos',
}
