import React from 'react'
import { format, parseISO } from 'date-fns'
import { Box, Flex, Avatar, Text, Image, Icon } from '../../atoms'
import { theme } from 'styles/theme'
import { useBreakpoint } from 'services/hooks'

export const ObservationCard = ({ item, inModal, onDelete, details = false, ...props }) => {
  const { isDesktop } = useBreakpoint()
  const image_url =
    item?.user?.employer?.length > 0
      ? item?.user?.employer?.[0]?.profile_image_url
      : !item?.barber_id
      ? item?.barbershop?.profile_image_url
      : item?.barber?.profile_image_url || item?.internal_image_url
  const name =
    item?.user?.employer?.length > 0
      ? item?.user?.employer?.[0]?.name
      : !item?.barber_id
      ? item?.barbershop?.name
      : item?.barber?.name || item?.internal_name

  return (
    <Flex mb={theme.pxToRem(12)}>
      {props?.type !== 'details' && (
        <Box>
          <Avatar size="menu" type="barber" src={image_url} />
        </Box>
      )}

      <Flex
        flexDir="column"
        justifyContent="center"
        padding={theme.pxToRem(props?.type !== 'details' ? 16 : 12)}
        ml={theme.pxToRem(props?.type !== 'details' && 8)}
        width={!isDesktop ? theme.pxToRem(240) : theme.pxToRem(360)}
        minH={theme.pxToRem(props?.type !== 'details' ? 48 : 68)}
        bg="cardBackground"
        borderWidth={1}
        borderColor="modalBorder"
        borderRadius={theme.pxToRem(8)}
      >
        {item?.observation_text && (
          <Text
            fontSize={14}
            kind="medium"
            maxW={theme.pxToRem(250)}
            overflow={details ? 'hidden' : 'visible'}
            textOverflow={details ? 'ellipsis' : 'visible'}
            whiteSpace={details ? 'nowrap' : 'visible'}
          >
            {item?.observation_text}
          </Text>
        )}

        {!item?.observation_text && <Image src={item.observation_image_url} />}

        <Flex
          alignItems="center"
          justifyContent="flex-end"
          mt={props?.type !== 'details' && theme.pxToRem(12)}
          w="100%"
        >
          {name && (
            <Text kind="medium" fontSize={12} color="textLight" mr={theme.pxToRem(4)}>
              {name} -
            </Text>
          )}

          {item?.date && (
            <Text kind="medium" fontSize={12} color="textGrey">
              {format(parseISO(item.date), 'dd/MM/yyyy HH:mm')}
            </Text>
          )}

          {inModal && (
            <Icon
              name={props?.type === 'details' ? 'ChevronRight' : 'Trash'}
              color={props?.type === 'details' ? 'primary' : 'baseRed'}
              size={16}
              ml={theme.pxToRem(8)}
              cursor="pointer"
              onClick={onDelete}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
