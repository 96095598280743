import * as React from 'react'

function SvgCalendarOne(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 5.333H5a3.333 3.333 0 00-3.333 3.334v20A3.333 3.333 0 005 32h20a3.333 3.333 0 003.333-3.333v-20A3.333 3.333 0 0025 5.334zM21.667 2v6.667M8.333 2v6.667M1.667 15.334h26.666M13.333 22H15M15 22v5"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgCalendarOne
