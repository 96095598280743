import React, { useEffect, useState } from 'react'
import { Table, Box } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useQuery } from 'react-query'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useBarbershopGoals } from 'services/hooks/use-barber-goals'
import { GOALS_COLUMNS } from './settings'
import { EditGoalModal } from './EditGoals'

export const ManagerGoals = () => {
  const { onLogout } = useLogoutPaymentIssues()
  const { getAllGoals } = useBarbershopGoals()
  const { userData } = useBarbershopStore()
  const [editModal, setEditModalOpen] = useState(false)

  useEffect(() => {
    onLogout()
  }, [])

  const { isLoading, data } = useQuery(['get-all-goals'], () => getAllGoals({ barbershop_id: userData?.id }))

  return (
    <Box flex={1} mt="12px">
      <Table
        data={data?.goals ?? []}
        loading={isLoading}
        columns={GOALS_COLUMNS}
        isSelectableRow
        onSelect={(a) => setEditModalOpen(a)}
      />
      <EditGoalModal isOpen={editModal !== false} onClose={() => setEditModalOpen(false)} goalData={editModal} />
    </Box>
  )
}
