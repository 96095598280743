import React, { useEffect, useMemo, useState } from 'react'
import { useTheme, Select, useToast } from '@chakra-ui/react'
import {
  Flex,
  Text,
  TextInput,
  Button,
} from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint, useBarbershop } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { ModalMenu } from 'components/molecules'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import { types } from './settings'
import { useBarberReleases } from 'services/hooks/use-barber-releases'

export const WorthBarberModal = ({ isModalOpen, onClose, refetch, edit, ...props }) => {
  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const [barber, setBarber] = useState()
  const [value, setValue] = useState()
  const [description, setDescription] = useState()
  const [type, setType] = useState()
  const [status, setStatus] = useState('PENDING')
  const theme = useTheme()
  const {getBarbers, createWorthBarber} = useBarbershop()
  const queryClient = useQueryClient()
  const {deleteBarberRelases} = useBarberReleases()
  const toast = useToast()
  const [paymentDate, setPaymentDate] = useState()

  const mutate = useMutation(
    'create-worth-barber',
    (params) => createWorthBarber(params),
  )

  const mutateDelete = useMutation(
    'delete-release-barber',
    (params) => deleteBarberRelases(params),
  )

  const onSuccess = () => {
    onClose()
    setValue()
    setBarber()
    setDescription()
    setType()
  }

  useEffect(() => {
if(edit) {
  setType(edit?.type)
  setDescription(edit?.description)
  setBarber(edit?.barber?.id)
  setValue(edit?.value.toString())
  setStatus(edit?.status)
  setPaymentDate(edit?.simple_date)
}

  }, [edit])

  const { data } = useQuery('get-barbers', () => getBarbers({barbershop_id: userData.id}))

  const isDisabled = useMemo(() => {
    return !value || value?.length === 0 || Number(value) === 0 ||
    !barber || barber?.length === 0 || !type || type?.length === 0 || !description
  }, [value, barber, type, description])

  const handleCreateWorthBarber = () => {
    const params = { 
      barbershop_id: userData?.barbershop?.id || userData?.id,
      type: type,
      barber_id: barber,
      value: Number(value?.replace?.(',', '.')),
      description: type === 'Vale' ? null : description,
      status,
      payment_date: paymentDate,
    }
    mutate.mutate({params }, {
      onSuccess: () => {
        onSuccess()
        queryClient.invalidateQueries(['get-barber-extract'])
        queryClient.invalidateQueries(['get-cashier-transactions'])
      },
      onSettled: () => {
        queryClient.invalidateQueries(['get-barber-extract'])
        queryClient.invalidateQueries(['get-cashier-transactions'])
      }, 
    })
  }

  const deleteRelease = () => {
    mutateDelete.mutate({id: edit?.id }, {
      onSuccess: () => {
        toast({title: 'Lançamento excluido com sucesso', isClosable: true, status: 'success', duration: 4000})
      },
      onSettled: () => {
        onSuccess()
        queryClient.invalidateQueries(['get-barber-extract'])
        queryClient.invalidateQueries(['get-cashier-transactions'])
      },
      onError: (error) => {
        toast({title: 'Erro ao excluir lançamento', description: error.response?.data?.error, isClosable: true, status: 'error', duration: 4000})

      },
    })
  }

  return (
    <>
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onClose}
        title='Adicionar lançamento para o barbeiro'
        headerStyle={{ mb: pxToRem(-12) }}
      > 
          <Flex
            position="relative"
            direction="column"
            alignItems={!isDesktop && 'center'}
          > 
              <Flex mt='16px' mb={theme.pxToRem(12)} flexDir="column">
                <Text
                  kind="medium"
                  fontSize={theme.pxToRem(14)}
                  color="textGrey" 
                  mb={theme.pxToRem(8)}
                >
                  {t('SELECT_BARBER')}
                </Text>
                <Flex
                  bg="backgroundLayout"
                  flexDir="column"
                  h={theme.pxToRem(56)}
                  align="center"
                  justify="center"
                  px={theme.pxToRem(16)}
                  borderRadius={theme.pxToRem(8)}
                  borderWidth={1}
                  borderColor="border"
                >
                  <Select
                    name="barber"
                    value={barber}
                    w="full"
                    fontFamily="Montserrat Bold"
                    placeholder={t('SELECT')}
                    fontSize={14}
                    textColor="textLight"
                    iconColor="primary"
                    borderColor="transparent"
                    errorBorderColor="baseRed"
                    focusBorderColor="transparent"
                    variant="unstyled"
                    marginBottom={theme.pxToRem(2)}
                    onChange={(e) => setBarber(e.target.value)}
                    disabled={edit}
                  > 
                    {data?.map((item, index) => (
                      <option
                        style={{
                          backgroundColor: theme.colors.menuBackground,
                          fontFamily: 'Montserrat Medium',
                        }}
                        key={`service_${item?.id}`}
                        value={item?.id}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </Select> 
                </Flex>
              </Flex>
              <Flex mb={theme.pxToRem(12)} flexDir="column">
                <Text
                  kind="medium"
                  fontSize={theme.pxToRem(14)}
                  color="textGrey" 
                  mb={theme.pxToRem(8)}
                >
                  {t('RELEASE_TYPE')} 
                </Text>
                <Flex
                  bg="backgroundLayout"
                  flexDir="column"
                  h={theme.pxToRem(56)}
                  align="center"
                  justify="center"
                  px={theme.pxToRem(16)}
                  borderRadius={theme.pxToRem(8)}
                  borderWidth={1}
                  borderColor="border"
                >
                  <Select
                    name="barber"
                    value={type}
                    w="full"
                    fontFamily="Montserrat Bold"
                    placeholder={t('SELECT')}
                    fontSize={14}
                    textColor="textLight"
                    iconColor="primary"
                    borderColor="transparent"
                    errorBorderColor="baseRed"
                    focusBorderColor="transparent"
                    variant="unstyled"
                    marginBottom={theme.pxToRem(2)}
                    onChange={(e) => setType(e.target.value)}
                    disabled={edit}
                  > 
                    {types?.map((item, index) => (
                      <option
                        style={{
                          backgroundColor: theme.colors.menuBackground,
                          fontFamily: 'Montserrat Medium',
                        }}
                        key={`service_${item?.value}`}
                        value={item?.value}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </Select> 
                </Flex>
              </Flex> <TextInput
            noBorder
            type='string'
            value={description}
            placeholder='Descrição (Vale/Gorjeta)'
            name="description"
            label='Descrição'
            onChange={(e) => setDescription(e.target.value)}
            isDisabled={edit}
            />
              <TextInput
            noBorder
            name="price"
            label='Valor'
            onChange={(e) => setValue(e.target.value)}
            placeholder="0,00"
            mask="CURRENCY_MASK"
            value={value}
            mt={ '36px'}
            isDisabled={edit}
            leftElement={
              <Text
                mt={theme.pxToRem(2)}
                fontWeight="bold"
                ml="0"
                width={theme.pxToRem(40)}
              >
                {t('CURRENCY')}
              </Text>
            }
          />
          <TextInput
            mt='36px'
                  h="46px"
                  noBorder
                  type="date"
                  value={paymentDate}
                  onChange={(e) => {
                      setPaymentDate(e.target.value)
                  }}
                  label='Data de pagamento'
                  padding={theme.pxToRem(10)}
            isDisabled={edit}
            />
          {type === 'revenue' && <Flex mt={'46px'} flexDirection="row" justifyContent="space-between">
                    <Button
                      kind={status === 'PENDING' ? 'primary' : 'outline'}
                      size="small"
                      w="100%"
                      marginRight="32px"
                      onClick={() => setStatus('PENDING')}
                      isDisabled={edit}
                    >
                      {t('PENDING_PAYMENT')}
                    </Button>
                    <Button
                      kind={status === 'PAID' ? 'primary' : 'outline'}
                      size="small"
                      w="100%"
                      onClick={() => setStatus('PAID')}
                      isDisabled={edit}
                    >
                      {t('PAID_PAYMENT')}
                    </Button>
                  </Flex>}
              <Flex
                mt='36px'
                width="full"
                maxWidth={!isDesktop && 325}
              >
                <Button
                  kind={edit ? 'danger' : "primary"}
                  size="block"
                  isDisabled={edit ? false : isDisabled}
                  type="submit"
                  isLoading={mutate.isLoading}
                  onClick={() => edit ? deleteRelease() : handleCreateWorthBarber()}
                  mt='12px'
                >
                  {edit ? 'Excluir' : 'Salvar'}
                </Button>
              </Flex>
          </Flex>
      </ModalMenu>
    </>
  )
}
