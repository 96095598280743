import React, { useState } from 'react'
import { Center, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import { Box, Flex, Text, Button, Loader } from 'components/atoms'
import { FinanceResumeCard, AntecipationCard, ExtractCard, Table } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { parseISO, format } from 'date-fns'

import { api } from 'services/api/config'
import { REQUEST_ANTECIPATION, GET_BALANCE, GET_ASAAS_EXTRACT } from 'services/api/endpoints'
import { useQuery, useMutation } from 'react-query'
import { theme } from 'styles'
import { PixModal } from 'components/organisms/PixModal'
import { columns } from './settings'
import { Layout } from 'components'
import { ExportAsaasExtractModal } from 'components/organisms/ExportAsaasExtractModal'
import * as XLSX from 'xlsx'

export const Exchange = (props) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  let [skip, setSkip] = useState(0)
  const [loadMore, setLoadMore] = useState(false)
  const [extract, setExtract] = useState([])
  const [pixModalOpen, setPixModalOpen] = useState(false)
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)

  async function getAsaasExtract() {
    const res = await api
      .get(GET_ASAAS_EXTRACT(userData?.barbershop?.id || userData?.id), {
        params: { skip },
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function getBalance() {
    const res = await api
      .get(GET_BALANCE(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: balance, isLoading: isLoadingBalance } = useQuery('get-balance', getBalance, {
    onError: (error) => {
      toast({
        message: t('ERROR_LOADING_DATA'),
        description: error.toString().substring(7),
        type: 'error',
        duration: 4000,
      })
    },
    refetchOnWindowFocus: false,
  })

  const { data, isLoading } = useQuery(['get-asaas-extract', skip], getAsaasExtract, {
    onSuccess: (values) => {
      setExtract([...extract, ...values?.extract])
      setLoadMore(false)
    },
    onError: (error) => {
      toast({
        title: t('ERROR_LOADING_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
      })
      setLoadMore(false)
    },
    refetchOnWindowFocus: false,
  })

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(extract)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabela')

    XLSX.writeFile(workbook, 'extrato.xlsx')
  }

  async function getRefundLink(params) {
    const res = await api
      .post(`v3/barbershop/refund-link/`, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('get-link', getRefundLink, {
    onSuccess: ({ link }) => {
      window.open(link, '_blank')
    },
    onError: (error) => {
      toast({
        title: 'Erro ao buscar link',
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
      })
    },
  })

  // console.log(extract)

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Saldo e saque"
      widthTitle="100%"
      secondActionClick={handleExport}
      secondActionText="Exportar em excel"
      iconSecondActionText={'Excel'}
      // secondLoading={mutate.isLoading}
      thirtyClick={{
        icon: 'Money',
        iconColor: 'black',
        onClick: () => setPixModalOpen(true),
        text: t('EXCHANGE'),
        disabled: !data?.balance === 0 || data?.balance === 0,
      }}
    >
      {(isLoading || isLoadingBalance) && !loadMore ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Flex flexDirection="column" h="100%">
          <FinanceResumeCard
            label="BALANCE_EXCHANGE"
            background="success"
            balance="successDark"
            width="100%"
            mb={16}
            value={balance?.balance || 0}
          />
          <Text kind="extraBold" fontSize={18} w="100%">
            {`Total de registros: ${data?.total}`}
          </Text>
          <Table
            data={extract}
            isSelectableRowVariabled={extract?.map((i) => i?.type === 'Estorno' || i?.type === 'Saque')}
            onSelect={(item) =>
              mutate.mutate({
                payment_id: item?.type === 'Estorno' ? item?.payment_id : item?.transfer_id,
                barbershop_id: userData?.id,
              })
            }
            columns={columns}
            loading={isLoading && !loadMore}
          />

          {data?.hasMore && (
            <Flex width="full" justifyContent="center" paddingY={theme.pxToRem(32)}>
              <Button
                disabled={loadMore}
                isLoading={loadMore}
                onClick={() => {
                  setLoadMore(true)
                  setSkip((skip += 100))
                }}
              >
                {t('LOAD_MORE')}
              </Button>
            </Flex>
          )}
          <PixModal isOpen={pixModalOpen} onClose={() => setPixModalOpen(false)} total={data?.balance} />
          {/* <ExportAsaasExtractModal
            isOpen={downloadModalOpen}
            onClose={() => setDownloadModalOpen(false)}
            totalRegister={extract?.total}
            onClick={}
          /> */}
        </Flex>
      )}
    </Layout>
  )
}

Exchange.path = '/saldo'
Exchange.title = 'Saldo e saque'
Exchange.secured = true
