import * as React from 'react'

export const SvgOne = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="14" fill="#363D49" />
    <path
      d="M15.5848 9.2V19H13.3168V11.02H11.3568V9.2H15.5848Z"
      fill="#E6B817"
    />
  </svg>
)

export const SvgTwo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="14" fill="#363D49" />
    <path
      d="M17.7391 17.152V19H10.3331V17.53L14.1131 13.96C14.5144 13.5773 14.7851 13.2507 14.9251 12.98C15.0651 12.7 15.1351 12.4247 15.1351 12.154C15.1351 11.762 14.9998 11.4633 14.7291 11.258C14.4678 11.0433 14.0804 10.936 13.5671 10.936C13.1378 10.936 12.7504 11.02 12.4051 11.188C12.0598 11.3467 11.7704 11.5893 11.5371 11.916L9.88509 10.852C10.2678 10.2827 10.7951 9.83933 11.4671 9.522C12.1391 9.19533 12.9091 9.032 13.7771 9.032C14.5051 9.032 15.1398 9.15333 15.6811 9.396C16.2318 9.62933 16.6564 9.96533 16.9551 10.404C17.2631 10.8333 17.4171 11.342 17.4171 11.93C17.4171 12.462 17.3051 12.9613 17.0811 13.428C16.8571 13.8947 16.4231 14.4267 15.7791 15.024L13.5251 17.152H17.7391Z"
      fill="#E6B817"
    />
  </svg>
)

export const SvgThree = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="28" height="28" rx="14" fill="#363D49" />
    <path
      d="M14.9814 13.19C15.8401 13.33 16.4981 13.652 16.9554 14.156C17.4128 14.6507 17.6414 15.2667 17.6414 16.004C17.6414 16.5733 17.4921 17.1007 17.1934 17.586C16.8948 18.062 16.4374 18.4447 15.8214 18.734C15.2148 19.0233 14.4681 19.168 13.5814 19.168C12.8908 19.168 12.2094 19.0793 11.5374 18.902C10.8748 18.7153 10.3101 18.454 9.84342 18.118L10.7254 16.382C11.0988 16.662 11.5281 16.8813 12.0134 17.04C12.5081 17.1893 13.0121 17.264 13.5254 17.264C14.0948 17.264 14.5428 17.1567 14.8694 16.942C15.1961 16.718 15.3594 16.4053 15.3594 16.004C15.3594 15.2013 14.7481 14.8 13.5254 14.8H12.4894V13.302L14.5054 11.02H10.3334V9.2H17.1934V10.67L14.9814 13.19Z"
      fill="#E6B817"
    />
  </svg>
)
