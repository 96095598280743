export const  options = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      noBorder: true,
      label: 'CLIENT_NAME',
    },
    {
        id: 'package_service',
        name: 'package_service',
        type: 'text',
        noBorder: true,
        label: 'PACKAGE_PAID',
      },
      {
        id: 'value',
        name: 'value',
        label: 'VALUE',
        noBorder: true,
        mask: 'CURRENCY_MASK',
        type: 'money',
        placeholder: 'R$ 10,00',
      },
      {
        id: 'quantity_hired',
        name: 'quantity_hired',
        label: 'QUANTITY_HIRED',
        noBorder: true,
        type: 'string',
      },
      {
        id: 'quantity_left',
        name: 'quantity_left',
        label: 'QUANTITY_LEFT',
        noBorder: true,
        type: 'string',
      },
  ]

export const initialBarber = {
  name: '',
  package_service: '',
  value: '',
  quantity_hired: 0,
  quantity_left: 0,
}
