import React from 'react'
import { Center, useTheme, Image } from '@chakra-ui/react'
import { RegisterWrapper } from 'components'
import paymentImage from 'assets/img/backgrounds/payment_method.png'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useHistory } from 'react-router-dom'
import { useBreakpoint } from 'services/hooks'
import { useMutation } from 'react-query'
import { useBarbershop } from 'services/hooks/use-barbershop'

export const PaymentStepScreen = () => {
  const theme = useTheme()
  const history = useHistory()
  const { isMobile } = useBreakpoint()
  const { updateStatus } = useBarbershop()

  const { userData, setUserData } = useBarbershopStore()

  const mutate = useMutation(
    'update-status',
    () =>
      updateStatus({
        status: 'publish',
        id: userData?.barbershop?.id || userData?.id,
      }),
    {
      onSuccess: (data) => {
        setUserData({ ...userData, status: data.status })
        history.push('/agenda')
      },
      onError: () => {
        history.push('/agenda')
      },
    }
  )

  const onClickContinue = () => {
    if (userData.status === 'not_published' || !userData.status) {
      mutate.mutate()
    }
  }

  return (
    <RegisterWrapper barbershopSetup onContinue={onClickContinue}>
      <Center flexDir="column">
        <Image
          h={theme.pxToRem(isMobile ? 272 : 320)}
          borderRadius={theme.pxToRem(12)}
          src={paymentImage}
        />
      </Center>
    </RegisterWrapper>
  )
}

PaymentStepScreen.path = '/cadastro/pagamento'
PaymentStepScreen.title = 'Pagamento'
PaymentStepScreen.secured = true
