import React from 'react'
import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { Icon, Text, Box, Flex } from '../../atoms'
import { useTranslation } from 'react-i18next'
import { theme } from 'styles/theme'

export const AppointmentCard = ({ item, type, onClick, ...props }) => {
  const { t } = useTranslation()

  const startHour = item?.start_hour.substring(0, 5)
  const finishHour = item?.finish_hour.substring(0, 5)

  const formatDate = (item) => {
    const date =
      !!item?.simple_date &&
      format(parseISO(item?.simple_date), 'EEEE, dd/MM', {
        locale: ptBR,
      })

    const formated = !!date && date.charAt(0).toUpperCase() + date.slice(1)

    return formated
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={theme.pxToRem(16)}
      height={theme.pxToRem(118)}
      background="cardBackground"
      borderWidth={1}
      borderColor="modalBorder"
      borderRadius={theme.pxToRem(8)}
      onClick={onClick}
      {...props}
    >
      <Flex alignItems="center">
        {type === 'historic' && <Icon name="Historic" color="primary" size={18} mr={theme.pxToRem(16)} />}

        <Box>
          <Text fontSize={16} kind="bold" width={theme.pxToRem(240)}>
            {formatDate(item)}
          </Text>

          <Text kind="medium" fontSize={12} color="textGrey">{`${startHour} - ${finishHour}`}</Text>

          <Flex alignItems="center" justifyContent="space-between" mt={theme.pxToRem(8)}>
            <Box w="50%">
              <Text kind="medium" fontSize={12} color="textGrey">
                {t('BARBER')}
              </Text>
              <Text kind="medium" fontSize={14}>
                {item?.barber?.name}
              </Text>
            </Box>

            <Box>
              <Text kind="medium" fontSize={12} color="textGrey">
                {t('_SERVICE')}
              </Text>
              <Text
                kind="medium"
                fontSize={14}
                maxW="120px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {item?.product?.map((i) => i?.name).join(', ') ||
                  item?.appointmentServices?.map((i) => i?.product?.name).join(', ')}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Icon name="ChevronRight" color="primary" size={18} />
    </Flex>
  )
}
