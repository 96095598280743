import * as React from 'react'

function SvgDoc(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.49 11.607h-7.255a1.176 1.176 0 100 2.353h7.255a1.176 1.176 0 100-2.353zM25.98 17.099H13.235a1.176 1.176 0 100 2.353H25.98a1.176 1.176 0 100-2.353z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M32.307 1.24a1.178 1.178 0 00-1.263.192l-3.295 2.915-3.298-2.95c-.447-.4-1.122-.4-1.569 0l-3.294 2.946-3.294-2.946c-.447-.4-1.122-.4-1.569 0l-3.298 2.95-3.294-2.915a1.177 1.177 0 00-1.956.881v20.942h-4c-.65 0-1.177.526-1.177 1.176v2.863a5.614 5.614 0 005.608 5.608v-2.353a3.259 3.259 0 01-3.255-3.255v-1.686h18.431v1.686a5.614 5.614 0 005.608 5.608A5.614 5.614 0 0033 27.294V2.314c0-.463-.271-.883-.693-1.073zm-1.66 26.054a3.259 3.259 0 01-3.255 3.255 3.259 3.259 0 01-3.255-3.255V24.43c0-.65-.526-1.176-1.176-1.176H8.529V4.925l2.123 1.878c.447.395 1.12.393 1.564-.004l3.294-2.946 3.294 2.945c.447.4 1.122.4 1.569 0l3.294-2.946 3.294 2.946c.445.398 1.117.4 1.564.005l2.122-1.878v22.369z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path d="M27.064 30.788H6.285v1.853h20.78v-1.853z" fill="currentColor" />
    </svg>
  )
}

export default SvgDoc
