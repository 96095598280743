import React, { useState, useEffect } from 'react'
import { Box, Button, Flex, Text, TextInput } from 'components/atoms'
import { ModalMenu, SelectCard } from 'components/molecules'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { t } from 'services/translation'
import {
  useToast,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import { unformatCurrency } from 'utils/formatCurrency'
import { useBarbershopGoals } from 'services/hooks/use-barber-goals'
import { useBarbershopStore } from 'services/stores'
import { TableInputs } from '../../molecules'
import { tableInputColumns, tableInputAll } from './settings'
import { ModalButtonBackground } from 'components-v2/atoms'
import { format, parseISO } from 'date-fns'

export const EditGoalModal = ({ isOpen, onClose, goalData }) => {
  const [collaborator, setCollaborator] = useState()
  const [value, setValue] = useState()
  const [finish_date, setFinishDate] = useState()
  const [bonus_active, setBonusActive] = useState(false)
  const queryClient = useQueryClient()
  const { userData } = useBarbershopStore()
  const { updateGoal, getProductsByBarber, deleteGoal } = useBarbershopGoals()

  const toast = useToast()
  const [products, setProducts] = useState({
    change_all: false,
    change_all_value: 0,
    data: [],
  })
  const [services, setServices] = useState({
    change_all: false,
    change_all_value: 0,
    data: [],
  })

  const onCleanState = () => {
    setProducts({
      change_all: false,
      change_all_value: 0,
      data: [],
    })
    setServices({
      change_all: false,
      change_all_value: 0,
      data: [],
    })
    setCollaborator()
    setFinishDate()
    setBonusActive(false)
    setValue()
  }

  const { data: getData } = useQuery(
    ['get-products-by-barber-goals'],
    () =>
      getProductsByBarber({
        barbershop_id: userData?.id,
        barber_id: collaborator?.id,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (d) => {
        setProducts({
          data: d?.products,
          change_all: products.change_all,
          change_all_value: products.change_all_value,
        })
        setServices({
          data: d?.services,
          change_all: services.change_all,
          change_all_value: services.change_all_value,
        })
      },
    }
  )
  useEffect(() => {
    if (goalData?.id) {
      setBonusActive(goalData?.bonus_active)
      setFinishDate(goalData?.end_date ?? '')
      setValue(goalData?.value)
      if (!!getData) {
        if (goalData?.products_and_services_comission) {
          const prod = goalData?.products_and_services_comission?.[0]?.products
          const serv = goalData?.products_and_services_comission?.[0]?.services
          let prod_data = [
            ...getData?.products
              ?.filter(
                (i) => !prod?.data?.filter((i2) => i2?.id === i?.id)?.length
              )
              ?.map((m) => ({ ...m, inactive: true })),
          ]
          if(prod?.data) {
            prod_data = [
              ...prod_data,
             ...prod?.data,
            ]
          }
          let serv_data = [
            ...getData?.services
              ?.filter(
                (i) => !serv?.data?.filter((i2) => i2?.id === i?.id)?.length
              )
              ?.map((m) => ({ ...m, inactive: true })),
          ]
          if(serv?.data) {
            serv_data = [
              ...serv_data,
             ...serv?.data,
            ]
          }
          setProducts({
            ...prod,
            data: prod_data,
          })
          setServices({
            ...serv,
            data: serv_data,
          })
        } else {
          setProducts({
            data: getData?.products,
            change_all: products.change_all,
            change_all_value: products.change_all_value,
          })
          setServices({
            data: getData?.services,
            change_all: services.change_all,
            change_all_value: services.change_all_value,
          })
        }
      }
    }
  }, [goalData, getData])

  const mutateCreate = useMutation(
    'create-goal',
    () =>
      updateGoal({
        body: {
          value: unformatCurrency(value),
          bonus_active,
          end_date: format(parseISO(finish_date), 'yyyy-MM-dd'),
          products_and_services_comission: [{ products, services }],
        },
        goal_id: goalData?.id,
      }),
    {
      onSuccess: () => {
        toast({
          title: 'Meta alterada com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        queryClient.invalidateQueries(['get-all-goals'])
        onClose()
        onCleanState()
      },
      onError: () => {
        toast({
          title: 'Erro ao alterar meta',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const mutationDelete = useMutation(
    'delete-goal',
    () => deleteGoal({ id: goalData?.id }),
    {
      onSuccess: () => {
        toast({
          title: 'Meta excluída com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        queryClient.invalidateQueries(['get-all-goals'])
        onClose()
        onCleanState()
      },
      onError: () => {
        toast({
          title: 'Erro ao excluir meta',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={() => {
        onCleanState()
        onClose()
      }}
      title="Detalhes da meta do colaborador"
      size="lg"
    >
      <Box w="100%">
        <Text fontSize={16} kind="semiBold" mb="12px">
          Meta de faturamento Mensal
        </Text>

        <TextInput
          mask="CURRENCY_MASK"
          value={value}
          label="Valor da meta de faturamento mensal (R$)"
          placeholder="Informe o valor da meta de faturamento mensal"
          onChange={(e) => setValue(e.target.value)}
        />
        <TextInput
          mt={8}
          type="DATE"
          value={finish_date}
          label="Essa meta é válida até quando?"
          placeholder="Informe até qual data esta meta será válida"
          onChange={(e) => setFinishDate(e.target.value)}
        />
        <Text mt={10} fontSize={14} kind="medium" color="textGrey" mb="12px">
          Comissão escalável
        </Text>

        <SelectCard
          px={4}
          item={{
            title: 'Ativar bônus de comissão ao alcançar esta meta',
          }}
          setSelected={() => setBonusActive(!bonus_active)}
          selected={bonus_active}
          h={14}
          w="100%"
        />
        {bonus_active && (
          <>
            <Text mb={2} mt={2} fontSize={12} color="textGrey">
              Observação: ao bater a meta, os serviços e produtos terão suas
              comissões alteradas de acordo com as informações abaixo. As
              comissões serão alteradas no mês seguinte ao mês em que a meta foi
              alcançada.
            </Text>

            <Tabs>
              <TabList
                color="textGrey"
                borderColor="modalBorder"
                borderBottomWidth={1}
              >
                {['Serviços', 'Produtos'].map((label) => (
                  <Tab
                    w="50%"
                    fontSize={14}
                    fontWeight={500}
                    _selected={{
                      color: '#E5B817',
                      borderColor: '#E5B817',
                      borderBottomWidth: 4,
                    }}
                  >
                    {label}
                  </Tab>
                ))}
              </TabList>

              <TabPanels p={0} marginTop="12px">
                <TabPanel p={0}>
                  <Flex w="100%" justify="space-between">
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar individualmente',
                      }}
                      setSelected={() =>
                        setServices({
                          change_all: !services.change_all,
                          data: services.data,
                        })
                      }
                      selected={!services.change_all}
                      h={14}
                      w="54%"
                    />
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar para todos',
                      }}
                      setSelected={() =>
                        setServices({
                          change_all: !services.change_all,
                          data: services.data,
                        })
                      }
                      selected={services.change_all}
                      h={14}
                      w="45%"
                    />
                  </Flex>
                  <TableInputs
                    columns={
                      services.change_all ? tableInputAll : tableInputColumns
                    }
                    data={
                      services.change_all
                        ? [
                            {
                              id: 1,
                              name: 'Todos os serviços',
                              change_all: true,
                              percentage: services.change_all_value,
                            },
                          ]
                        : services?.data
                    }
                    setData={(d) => {
                      const change_all = d?.[0]?.change_all
                      setServices({
                        change_all: services.change_all,
                        ...(change_all
                          ? {
                              change_all_value: Number(
                                d?.[0]?.percentage?.replace('%', '')
                              ),
                            }
                          : {}),
                        data: change_all ? services.data : d,
                      })
                    }}
                  />
                </TabPanel>
                <TabPanel p={0}>
                  <Flex w="100%" justify="space-between">
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar individualmente',
                      }}
                      setSelected={() =>
                        setProducts({
                          data: products.data,
                          change_all: !products.change_all,
                        })
                      }
                      selected={!products.change_all}
                      h={14}
                      w="54%"
                    />
                    <SelectCard
                      px={2}
                      item={{
                        title: 'Alterar para todos',
                      }}
                      setSelected={() =>
                        setProducts({
                          data: products.data,
                          change_all: !products.change_all,
                        })
                      }
                      selected={products.change_all}
                      h={14}
                      w="45%"
                    />
                  </Flex>
                  <TableInputs
                    columns={
                      products.change_all ? tableInputAll : tableInputColumns
                    }
                    data={
                      products.change_all
                        ? [
                            {
                              id: 1,
                              name: 'Todos os produtos',
                              change_all: true,
                              percentage: products.change_all_value,
                            },
                          ]
                        : products?.data
                    }
                    setData={(d) => {
                      const change_all = d?.[0]?.change_all
                      setProducts({
                        change_all: products.change_all,
                        ...(change_all
                          ? {
                              change_all_value: Number(
                                d?.[0]?.percentage?.replace('%', '')
                              ),
                            }
                          : {}),
                        data: change_all ? products.data : d,
                      })
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>
      <ModalButtonBackground>
        <Button
          kind="danger"
          isDisabled={mutationDelete.isLoading}
          isLoading={mutationDelete.isLoading}
          size="block"
          w="40%"
          mr="2%"
          onClick={() => {
            mutationDelete.mutate()
          }}
        >
          {t('DELETE')}
        </Button>
        <Button
          kind="primary"
          isDisabled={mutateCreate.isLoading}
          isLoading={mutateCreate.isLoading}
          size="block"
          onClick={() => {
            mutateCreate.mutate()
          }}
        >
          {t('SAVE')}
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
