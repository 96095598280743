import React, { useState } from 'react'

import { LoginForm } from './LoginForm'
import { LoginNotification } from './LoginNotification'
import { LoginChangePassword } from './LoginChangePassword'
import { LoginForgotPasswordForm } from './LoginForgotPassword'

import { useTheme } from '@chakra-ui/react'
import { Flex, Box, Link, Text } from 'components/atoms'
import { Modal } from 'components/molecules'
import { LOGIN_SCREEN_STEPS as STEPS } from 'constants/login'
import { useTranslation } from 'react-i18next'

import { useBreakpoint } from 'services/hooks'

export const LoginModal = (props) => {
  const { isMobile } = useBreakpoint()
  const { t } = useTranslation()
  const [selectedStep, setSelectedStep] = useState(STEPS.LOGIN)
  const [values, setValues] = useState({})
  const theme = useTheme()
  const changeSelectedStep = (step) => {
    setSelectedStep(step)
  }

  const STEPS_CONFIG = {
    [STEPS.LOGIN]: { height: theme.pxToRem(552) },
    [STEPS.AUTH_FACTOR]: { height: theme.pxToRem(544) },
    [STEPS.FORGOT_PASSWORD]: { height: 'auto' },
    [STEPS.MAIL_SENT]: { height: theme.pxToRem(432) },
    [STEPS.MAIL_NOT_SENT]: { height: theme.pxToRem(416) },
    [STEPS.RESET_PASSWORD]: { height: 'auto' },
    [STEPS.PASSWORD_CHANGED]: { height: 'auto' },
  }

  return (
    <Flex flexDir="column" justifyContent="center" alignItems="center">
      <Modal
        pb={isMobile && theme.pxToRem(24)}
        mb={isMobile && theme.pxToRem(24)}
        w={isMobile ? '100%' : theme.pxToRem(544)}
        height={isMobile ? '100%' : STEPS_CONFIG[selectedStep].height}
        backgroundColor="cardBackground"
        isLoginModal
      >
        {selectedStep === STEPS.LOGIN && (
          <LoginForm {...props} changeSelectedStep={changeSelectedStep} />
        )}
        {selectedStep === STEPS.FORGOT_PASSWORD && (
          <LoginForgotPasswordForm
            changeSelectedStep={changeSelectedStep}
            setValues={setValues}
          />
        )}
        {selectedStep === STEPS.RESET_PASSWORD && (
          <LoginChangePassword
            values={values}
            changeSelectedStep={changeSelectedStep}
          />
        )}
        {(selectedStep === STEPS.MAIL_SENT ||
          selectedStep === STEPS.MAIL_NOT_SENT ||
          selectedStep === STEPS.PASSWORD_CHANGED) && (
          <LoginNotification
            values={values}
            selectedStep={selectedStep}
            changeSelectedStep={changeSelectedStep}
          />
        )}
      </Modal>

      {selectedStep === STEPS.LOGIN && (
        <Box
          w="100%"
          mt={isMobile ? theme.pxToRem(24) : theme.pxToRem(48)}
          textAlign="center"
        >
          <Link href="/cadastro">
            <Text
              kind="bold"
              fontSize={theme.pxToRem(14)}
              as="u"
              textDecoration="none"
            >
              {t('SIGNUP1')}
            </Text>
            <Text
              kind="bold"
              fontSize={theme.pxToRem(14)}
              fontWeight="bold"
              as="u"
              textDecoration="none"
              color="primary"
            >
              &nbsp; {t('SIGNUP2')}
            </Text>
          </Link>
        </Box>
      )}
    </Flex>
  )
}
