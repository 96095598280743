import React, { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Flex, Box, Text, Button } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { Form } from 'components/molecules'
import { dataMainData, validationMainData, initialMainData } from './settings'
import { api } from 'services/api/config'
import { useQuery, useMutation } from 'react-query'
import { cpfFormatter, cnpjFormatter } from 'utils'

export const RefreshDataModal = ({ isOpen, onClose }) => {
  const toast = useToast()
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { userData, setUserData } = useBarbershopStore()
  const [values, getValues] = useState({
    params: initialMainData,
    isValid: false,
  })

  async function infoDocs() {
    if (!userData?.barberhsop?.id && !userData?.id) {
      throw new Error('Barbearia não encontrada')
    }
    const { data } = await api.get(
      `v3/barbershop/main-data?barbershop_id=${
        userData?.barberhsop?.id || userData?.id
      }`
    )
    return data
  }

  async function infoDocsUpdate(formData) {
    const res = await api
      .put(
        `v3/barbershop/main-data?barbershop_id=${
          userData?.barberhsop?.id || userData?.id
        }`,
        formData
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data } = useQuery('barbershop-info-docs', infoDocs, {
    onError: ({ error }) => {
      toast({
        title: t('BARBERSHOP_INFO_DOCS_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        isClosable: true,
        duration: 4000,
      })
    },
  })

  const handleSuccess = (data) => {
    setUserData({
      ...userData,
      ...data,
    })
  }

  const mutate = useMutation(
    'barbershop-info-docs-update',
    (formData) => infoDocsUpdate(formData),
    {
      onSuccess: (data) => {
        toast({
          title: t('UPDATED_DATA'),
          status: 'success',
          isClosable: true,
          duration: 4000,
        })
        handleSuccess(data)

        onClose()
      },
      onError: ({ error }) => {
        toast({
          title: t('ERROR_SAVE_DATA'),
          description: error.toString().substring(7),
          status: 'error',
          isClosable: true,
          duration: 4000,
        })
      },
    }
  )

  const handleFormSubmit = () => {
    const params = {
      ...values?.params,
    }
    mutate.mutate(params)
    setUserData({
      ...userData,
      ...values,
    })
  }

  useEffect(() => {
    getValues({
      params: {
        name: data?.name,
        secondary_identifier_doc: cpfFormatter(data?.secondary_identifier_doc),
        identifier_doc: cnpjFormatter(data?.identifier_doc),
        owner_name: data?.owner_name,
      },
      isValid: false,
    })
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
        pos={!isDesktop && 'absolute'}
        top={!isDesktop && 0}
        maxW={theme.pxToRem(744)}
        maxH={theme.pxToRem(!isDesktop ? 690 : 639)}
      >
        <ModalHeader
          textAlign="flex-start"
          mt={theme.pxToRem(20)}
          w={!isDesktop ? '100%' : theme.pxToRem(744)}
        >
          <Flex
            flexDir="column"
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text kind="extraBold" fontSize={24} mr={theme.pxToRem(6)}>
              {t('MODAL_REFRESH_DATA_TITLE')}
            </Text>
          </Flex>
          <Text w="100%" color="textGrey" fontSize={14}>
            {t('MODAL_REFRESH_DATA_SUBTITLE')}
          </Text>
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody w="100%">
          <Box w="100%">
            <Form
              mb={40}
              getValues={getValues}
              initialValues={values?.params}
              validationSchema={validationMainData}
              data={dataMainData}
            />
          </Box>
        </ModalBody>

        <ModalFooter flexDir="column">
          <Button
            isLoading={mutate.isLoading}
            kind="primary"
            w="100%"
            onClick={handleFormSubmit}
            mb={theme.pxToRem(24)}
            disabled={!values?.params?.owner_name}
          >
            {t('SAVE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
