import React from 'react'
import { Text, Flex, Button } from 'components'
import { theme } from 'styles'

import { Image } from '@chakra-ui/react'
import andreM from './../images/section-garantia-mobile.png'
import heroDevices from './../images/hero-device.png'
import { useBreakpoint } from 'services/hooks'

export const FreeTestPartners = ({ clickButton }) => {
  const { isDesktop } = useBreakpoint()
  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        px="5vh"
        h="70vh"
        flexDir="column"
        bgColor="backgroundLayout"
        overflow="hidden"
        backgroundSize="cover"
        backgroundPosition="center center"
      >
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(14)}
          kind="medium"
          mb={theme.pxToRem(12)}
          mt={theme.pxToRem(32)}
        >
          GARANTIA TOTAL DE 7 DIAS
          <Flex bg="primary" h="2px" w="15%" mt={theme.pxToRem(8)} />
        </Text>
        <Text fontSize={theme.pxToRem(30)} kind="extraBold" mb={theme.pxToRem(12)}>
          Uma semana inteira para testar com tranquilidade
        </Text>
        <Text mb={theme.pxToRem(8)} color="textLight" fontSize={theme.pxToRem(12)} kind="medium">
          Temos certeza de que o BestBarbers será um grande avanço para a sua barbearia e que você terá ótimos
          resultados.
        </Text>
        <Text color="textLight" fontSize={theme.pxToRem(12)} kind="medium">
          Se até o sétimo dia de uso você não estiver satisfeito, por qualquer motivo que seja, basta nos enviar um
          simples e-mail que faremos a devolução de 100% do valor investido.
        </Text>
        <Button mt={theme.pxToRem(24)} w="100%" onClick={clickButton}>
          Começar agora
        </Button>
      </Flex>
    )
  }

  return (
    <Flex
      w={'100vw'}
      h={'85vh'}
      align="center"
      justify="flex-start"
      pl="15vw"
      flexDir="row"
      bgColor="backgroundLayout"
      overflow="hidden"
    >
      <Flex w="40%" h="100%" mr="4%" flexDir="column" justify="center">
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(14)}
          kind="medium"
          mb={theme.pxToRem(8)}
          mt={theme.pxToRem(24)}
        >
          GARANTIA TOTAL DE 7 DIAS
        </Text>
        <Flex bg="primary" h="2px" w="5%" mb={theme.pxToRem(24)} />
        <Text w={theme.pxToRem(600)} fontSize={theme.pxToRem(36)} kind="extraBold" mb={theme.pxToRem(24)}>
          Uma semana inteira para testar com tranquilidade
        </Text>
        <Text mb={theme.pxToRem(8)} color="textLight" fontSize={theme.pxToRem(16)} kind="medium">
          Temos certeza de que o BestBarbers será um grande avanço para a sua barbearia e que você terá ótimos
          resultados.
        </Text>
        <Text mb={theme.pxToRem(10)} color="textLight" fontSize={theme.pxToRem(16)} kind="medium">
          Se até o sétimo dia de uso você não estiver satisfeito, por qualquer motivo que seja, basta nos enviar um
          simples e-mail que faremos a devolução de 100% do valor investido.
        </Text>
        <Button mt={theme.pxToRem(40)} onClick={clickButton}>
          Começar agora
        </Button>
      </Flex>
      <Flex h="100%" align="flex-end" justify="center" w="50%">
        <Image src={heroDevices} h="80%" w="auto" />
      </Flex>
    </Flex>
  )
}
