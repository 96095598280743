import React from 'react'
import { Image } from '@chakra-ui/react'
import { Text, Flex, Button } from 'components'
import { theme } from 'styles'
import israelBackground from '../images/israel-background.png'
import israel from '../images/israel.png'
import { useBreakpoint } from 'services/hooks'
import ReactPlayer from 'react-player'

export const Israel = ({ clickButton }) => {
  const { isDesktop } = useBreakpoint()
  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        h={'85vh'}
        backgroundImage={`url(${israelBackground})`}
        backgroundSize="cover"
        backgroundPosition="center center"
        align="center"
        justify="center"
        px="5vw"
        flexDir="column"
      >
        <Text
          color="textMedium"
          fontSize={theme.pxToRem(12)}
          kind="medium"
          mb={theme.pxToRem(4)}
        >
          CONHEÇA ISRAEL ÁVILA
        </Text>
        <Flex bg="primary" h="2px" w="5%" mb={theme.pxToRem(24)} />
        <Text
          fontSize={theme.pxToRem(24)}
          kind="extraBold"
          mb={theme.pxToRem(24)}
          textAlign="center"
        >
          Utilizado por quem conhece do negócio na prática
        </Text>
        <ReactPlayer
          url="https://vimeo.com/794212317"
          width="90vw"
          height="35vh"
        />
        <Button mt={theme.pxToRem(24)} size="small" onClick={clickButton}>
          Começar agora
        </Button>
      </Flex>
    )
  }
  return (
    <Flex
      w={'100vw'}
      h={'106vh'}
      backgroundImage={`url(${israelBackground})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      align="center"
      justify="center"
      pl="10vw"
      pr="10vw"
      flexDir="column"
    >
      <Text
        color="textMedium"
        fontSize={theme.pxToRem(14)}
        kind="medium"
        mb={theme.pxToRem(8)}
        mt={'5vh'}
      >
        CONHEÇA ISRAEL ÁVILA
      </Text>
      <Flex bg="primary" h="2px" w="5%" mb={theme.pxToRem(24)} />
      <Text
        w={theme.pxToRem(600)}
        fontSize={theme.pxToRem(36)}
        kind="extraBold"
        mb={theme.pxToRem(32)}
        textAlign="center"
      >
        Utilizado por quem conhece do negócio na prática
      </Text>
      <ReactPlayer
        url="https://vimeo.com/794212317"
        width="55vw"
        height="55vh"
      />
      <Button mt={theme.pxToRem(40)} size="small" onClick={clickButton}>
        Começar agora
      </Button>
    </Flex>
  )
}
