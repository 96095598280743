export const resumeReport = {
  all_appointments: [
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:42:45.994Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:10:00',
      id: 182101,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:27:24.762Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:30:00',
      id: 182090,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '14:40:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 43460,
      createdAt: '2023-02-03T18:43:08.400Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:50:00',
      id: 188812,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:43:08.400Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:48:03.226Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:10:00',
      id: 182068,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '17:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:49:58.664Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '19:00:00',
      id: 182070,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-03T18:53:15.293Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:40:00',
      id: 188825,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:50:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:53:15.293Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-02-03T18:56:07.000Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:00:00',
      id: 188828,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12942,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 15,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:56:07.000Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-05T22:39:36.711Z',
      created_by_user_id: 15,
      date: '2023-01-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176181,
      net_value: null,
      past: true,
      payment_date: '2023-01-06T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-06',
      source: null,
      start_date: null,
      start_hour: '08:15:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 85,
      total_value: 85,
      total_with_barber_percentage: 85,
      total_with_payment_percentage: 85,
      type: 'normal',
      updatedAt: '2023-02-03T13:42:51.417Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-17T11:12:37.681Z',
      created_by_user_id: 15,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:25:00',
      id: 180315,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-17',
      source: null,
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T13:32:54.951Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3258,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T19:05:45.304Z',
      created_by_user_id: 15,
      date: '2023-01-21T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180584,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-21',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '10:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T19:05:45.304Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-06T17:02:34.265Z',
      created_by_user_id: 15,
      date: '2023-02-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:10:00',
      id: 189904,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-06',
      source: null,
      start_date: null,
      start_hour: '14:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-06T17:55:20.071Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-02-06T17:55:50.943Z',
      created_by_user_id: 15,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:20:00',
      id: 189942,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 13027,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 12,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T19:25:08.427Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T21:35:57.567Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '14:30:00',
      id: 180667,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5719,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '11:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 280,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T16:07:47.259Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 35911,
      createdAt: '2023-01-18T11:42:36.560Z',
      created_by_user_id: 15,
      date: '2023-01-19T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180790,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-19',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-19T13:41:15.840Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3241,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-09T13:43:13.588Z',
      created_by_user_id: null,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '11:50:00',
      id: 191752,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '11:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T20:53:41.854Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-17T19:47:27.784Z',
      created_by_user_id: null,
      date: '2023-01-18T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 180606,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-18',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-18T11:43:02.631Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T21:06:36.953Z',
      created_by_user_id: null,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 179314,
      net_value: 85,
      past: true,
      payment_date: '2023-01-17T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.6915,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-17',
      source: 'barbershop_mobile_app_phone_call',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.64978,
      total_value: 85,
      total_with_barber_percentage: 58.34128,
      total_with_payment_percentage: 83.3085,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T20:12:10.961Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:40:00',
      id: 179283,
      net_value: 85,
      past: true,
      payment_date: '2023-02-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 1.72822,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.07178,
      total_value: 85,
      total_with_barber_percentage: 57.8,
      total_with_payment_percentage: 83.27178,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 1.5,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-01-31T17:53:36.207Z',
      created_by_user_id: 15,
      date: '2023-01-31T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:30:00',
      id: 186622,
      net_value: 25,
      past: true,
      payment_date: '2023-01-31T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'pix',
      payment_method_choosed: [Object],
      payment_method_id: 2643,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 12942,
      product_price: 15,
      promotional: false,
      simple_date: '2023-01-31',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '16:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 21.8,
      total_value: 25,
      total_with_barber_percentage: 21.8,
      total_with_payment_percentage: 25,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 3.5,
      barber_percentage_value: 0.3466884615384616,
      barber_products_percentage_value: 2.5,
      barber_products_value: 2.9716153846153848,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:40:41.695Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 1.23,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178308,
      net_value: 128.77,
      past: true,
      payment_date: '2023-02-11T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 3.7510057150000007,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 121.70069043884615,
      total_value: 128.77,
      total_with_barber_percentage: 125.45169615384616,
      total_with_payment_percentage: 125.018994285,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 0.12297723523589575,
      barber_products_percentage_value: 17,
      barber_products_value: 20.23173870009898,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-02T20:04:48.044Z',
      created_by_user_id: null,
      date: '2023-02-03T03:00:00.000Z',
      discount: 1,
      discount_observation: 'teste',
      end_date: null,
      finish_hour: '18:20:00',
      id: 188036,
      net_value: 120.24,
      past: true,
      payment_date: '2023-03-05T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9865699935334873,
      product: [Object],
      product_id: 12945,
      product_price: 1.24,
      promotional: false,
      simple_date: '2023-02-03',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 96.89871407113162,
      total_value: 120.24,
      total_with_barber_percentage: 99.88528406466511,
      total_with_payment_percentage: 117.2534300064665,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:51.203Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 1.56816,
      barber_products_percentage_value: 17,
      barber_products_value: 0.8330850000000001,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:43:52.567Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 10,
      discount_observation: 'TESTE',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178310,
      net_value: 10,
      past: true,
      payment_date: '2023-01-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 0.199,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 7.399754999999999,
      total_value: 10,
      total_with_barber_percentage: 7.598754999999999,
      total_with_payment_percentage: 9.801,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:47:00.323Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:20:00',
      id: 178311,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:50:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-12T14:54:20.556Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:42:55.112Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:25:00',
      id: 176385,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.9850000000000003,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 147.015,
      total_value: 150,
      total_with_barber_percentage: 150,
      total_with_payment_percentage: 147.015,
      type: 'normal',
      updatedAt: '2023-02-03T17:28:51.039Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:44:09.464Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176388,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:30:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 50.010000000000005,
      total_value: 50.010000000000005,
      total_with_barber_percentage: 50.010000000000005,
      total_with_payment_percentage: 50.010000000000005,
      type: 'normal',
      updatedAt: '2023-02-03T13:48:27.469Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 9.6,
      barber_products_percentage_value: 17,
      barber_products_value: 20.400000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41588,
      createdAt: '2023-01-13T21:22:54.416Z',
      created_by_user_id: null,
      date: '2023-01-14T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 179329,
      net_value: 150,
      past: true,
      payment_date: '2023-01-14T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-14',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 120,
      total_value: 150,
      total_with_barber_percentage: 120,
      total_with_payment_percentage: 150,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 15.868361270355203,
      barber_products_percentage_value: 10,
      barber_products_value: 1.8677994648223981,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-04T18:03:16.912Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 5,
      discount_observation: 'Desconto especial',
      end_date: null,
      finish_hour: '16:20:00',
      id: 175763,
      net_value: 100.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.9901990000000003,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:40:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 80.2836402648224,
      total_value: 100.01,
      total_with_barber_percentage: 82.2738392648224,
      total_with_payment_percentage: 98.019801,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41428,
      createdAt: '2023-01-11T20:50:08.307Z',
      created_by_user_id: null,
      date: '2023-01-28T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 178273,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-28',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-28T16:51:07.653Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 3.3340061700000003,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-03T23:00:27.844Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 175626,
      net_value: 105.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.0896990000000004,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 72.92757483000001,
      total_value: 105.01,
      total_with_barber_percentage: 75.01727383000001,
      total_with_payment_percentage: 102.92030100000001,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 7,
      barber_products_percentage_value: 10,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41585,
      createdAt: '2023-01-13T20:38:01.984Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:30:00',
      id: 179301,
      net_value: 35,
      past: true,
      payment_date: '2023-01-13T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 4771,
      product_price: 35,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 28,
      total_value: 35,
      total_with_barber_percentage: 28,
      total_with_payment_percentage: 35,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41687,
      createdAt: '2023-01-16T15:09:40.110Z',
      created_by_user_id: null,
      date: '2023-01-16T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '13:10:00',
      id: 179964,
      net_value: 95,
      past: true,
      payment_date: '2023-01-16T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-16',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '12:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 66.1,
      total_value: 95,
      total_with_barber_percentage: 66.1,
      total_with_payment_percentage: 95,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-24T12:45:25.997Z',
      created_by_user_id: null,
      date: '2023-01-25T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 183312,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-25',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-25T13:08:06.963Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 10,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-01-26T19:39:43.497Z',
      created_by_user_id: null,
      date: '2023-01-26T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 184544,
      net_value: 110,
      past: true,
      payment_date: '2023-02-25T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9391700000000003,
      product: [Object],
      product_id: 12887,
      product_price: 100,
      promotional: false,
      simple_date: '2023-01-26',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 95.36082999999999,
      total_value: 110,
      total_with_barber_percentage: 98.3,
      total_with_payment_percentage: 107.06083,
      type: 'fit',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
  ],
  appointment_others_discounts: 21.330000000000002,
  appointment_payments_methods_discounts: 20.050785833188005,
  appointments_paid_total_after_discounts: 1241.973494622111,
  appointments_paid_total_comissions: 266.68571954470116,
  entries_month_total_prevision: 3110.94,
  expenses_total: 0,
  month_balance: 1467.2034946221108,
  month_total_prevision: 2754.819494622111,
  new_clients: 5,
  payment_method_info: {
    data: [
      { payment_method: { name: 'Cartão de crédito' }, quantity: 2 },
      { payment_method: { name: 'Cartão de débito' }, quantity: 5 },
      { payment_method: { name: 'Pix' }, quantity: 10 },
      { payment_method: { name: 'Dinheiro' }, quantity: 15 },
      { payment_method: { name: 'Picpay' }, quantity: 6 },
    ],
    total: 38,
  },
  revenues_total: 225.23,
  services_info: {
    data: [
      { service: 'Corte de cabelo', quantity: 10 },
      { service: 'Corte fade', quantity: 7 },
      { service: 'Corte feminino', quantity: 3 },
      { service: 'Barba', quantity: 10 },
      { service: 'Hidratação', quantity: 12 },
    ],
  },
  total_appointments: 33,
  total_awaiting: 4,
  total_clients: 11,
  total_entries: 1775.27,
  total_exits: 308.06650537788914,
  total_finished: 15,
  total_pending: 14,
}

export const extractReports = {
  all_transactions: [
    {
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 10,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'João Silva' },
      client_id: 1305,
      createdAt: '2023-01-26T19:39:43.497Z',
      created_by_user_id: null,
      date: '2023-01-26T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 184544,
      net_value: 110,
      past: true,
      payment_date: '2023-02-25T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9391700000000003,
      product: {
        name: 'Corte de cabelo',
      },
      product_id: 12887,
      product_price: 100,
      promotional: false,
      simple_date: '2023-01-26',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 95.36082999999999,
      total_value: 110,
      total_with_barber_percentage: 98.3,
      total_with_payment_percentage: 107.06083,
      type: 'fit',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Alberto Braga' },
      client_id: 29121,
      createdAt: '2023-01-13T20:12:10.961Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:40:00',
      id: 179283,
      net_value: 85,
      past: true,
      payment_date: '2023-02-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 1.72822,
      product: {
        name: 'Corte de cabelo',
      },
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.07178,
      total_value: 85,
      total_with_barber_percentage: 57.8,
      total_with_payment_percentage: 83.27178,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 3.5,
      barber_percentage_value: 0.3466884615384616,
      barber_products_percentage_value: 2.5,
      barber_products_value: 2.9716153846153848,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Lucas Azevedo' },
      client_id: 29121,
      createdAt: '2023-01-11T21:40:41.695Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 1.23,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178308,
      net_value: 128.77,
      past: true,
      payment_date: '2023-02-11T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 3.7510057150000007,
      product: {
        name: 'Corte de cabelo',
      },
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 121.70069043884615,
      total_value: 128.77,
      total_with_barber_percentage: 125.45169615384616,
      total_with_payment_percentage: 125.018994285,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3241,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'André Rangel' },
      client_id: 4578,
      createdAt: '2023-02-09T13:43:13.588Z',
      created_by_user_id: null,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '11:50:00',
      id: 191752,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        name: 'Corte de cabelo',
      },
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '11:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T20:53:41.854Z',
    },
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Matheus Stehling' },
      client_id: 30885,
      createdAt: '2023-02-06T17:55:50.943Z',
      created_by_user_id: 15,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:20:00',
      id: 189942,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        name: 'Barba',
      },
      product_id: 13027,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 12,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T19:25:08.427Z',
    },
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Gabriel Rangel' },
      client_id: 1305,
      createdAt: '2023-02-06T17:02:34.265Z',
      created_by_user_id: 15,
      date: '2023-02-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:10:00',
      id: 189904,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        name: 'Barba',
      },
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-06',
      source: null,
      start_date: null,
      start_hour: '14:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-06T17:55:20.071Z',
    },
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Rafael Pereira' },
      client_id: 43460,
      createdAt: '2023-02-03T18:43:08.400Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:50:00',
      id: 188812,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        name: 'Barba',
      },
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:43:08.400Z',
    },
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Willian Sugiyama' },
      client_id: 4578,
      createdAt: '2023-02-03T18:53:15.293Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:40:00',
      id: 188825,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        name: 'Barba',
      },
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:50:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:53:15.293Z',
    },
    {
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'Ayrton Lacerda' },
      client_id: 31220,
      createdAt: '2023-02-03T18:56:07.000Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:00:00',
      id: 188828,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: {
        name: 'Barba',
      },
      product_id: 12942,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 15,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:56:07.000Z',
    },
    {
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 1.5,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: { name: 'João Victor' },
      client_id: 31220,
      createdAt: '2023-01-31T17:53:36.207Z',
      created_by_user_id: 15,
      date: '2023-01-31T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:30:00',
      id: 186622,
      net_value: 25,
      past: true,
      payment_date: '2023-01-31T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'pix',
      payment_method_choosed: [Object],
      payment_method_id: 2643,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: {
        name: 'Barba',
      },
      product_id: 12942,
      product_price: 15,
      promotional: false,
      simple_date: '2023-01-31',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '16:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 21.8,
      total_value: 25,
      total_with_barber_percentage: 21.8,
      total_with_payment_percentage: 25,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      barbershop_id: 1,
      createdAt: '2023-01-20T20:30:21.598Z',
      description: null,
      due_at_date: '2023-01-20T03:00:00.000Z',
      finance_categories_id: 1,
      finance_category: {
        name: 'Infraestrutura',
      },
      id: 3715,
      recurrent: true,
      status: 'pending',
      title: 'Teste',
      type: 'revenue',
      updatedAt: '2023-01-20T20:30:21.598Z',
      value: '1.23',
    },
    {
      barbershop_id: 1,
      createdAt: '2023-01-20T20:15:24.775Z',
      description: null,
      due_at_date: '2023-01-20T03:00:00.000Z',
      finance_categories_id: 1,
      finance_category: {
        name: 'Pagamentos Diversos',
      },
      id: 3714,
      recurrent: false,
      status: 'pending',
      title: 'Conta de Luz',
      type: 'revenue',
      updatedAt: '2023-01-20T20:15:24.775Z',
      value: '12.00',
    },
    {
      barbershop_id: 1,
      createdAt: '2023-01-20T20:14:04.856Z',
      description: null,
      due_at_date: '2023-01-20T03:00:00.000Z',
      finance_categories_id: 1,
      finance_category: {
        name: 'Pagamentos Diversos',
      },
      id: 3713,
      recurrent: true,
      status: 'paid',
      title: 'Conta de água',
      type: 'revenue',
      updatedAt: '2023-01-20T20:14:04.856Z',
      value: '124.00',
    },
  ],
  month_balance: 1467.2034946221108,
  total_entries: 1775.27,
  total_exits: 308.06650537788914,
}

export const comissionReport = {
  all_appointments: [
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:42:45.994Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:10:00',
      id: 182101,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:27:24.762Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:30:00',
      id: 182090,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '14:40:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:49:58.664Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '19:00:00',
      id: 182070,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-03T18:53:15.293Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:40:00',
      id: 188825,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:50:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:53:15.293Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:48:03.226Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:10:00',
      id: 182068,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '17:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 43460,
      createdAt: '2023-02-03T18:43:08.400Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:50:00',
      id: 188812,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:43:08.400Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-02-03T18:56:07.000Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:00:00',
      id: 188828,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12942,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 15,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:56:07.000Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-06T17:02:34.265Z',
      created_by_user_id: 15,
      date: '2023-02-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:10:00',
      id: 189904,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-06',
      source: null,
      start_date: null,
      start_hour: '14:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-06T17:55:20.071Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3241,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-09T13:43:13.588Z',
      created_by_user_id: null,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '11:50:00',
      id: 191752,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '11:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T20:53:41.854Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-02-06T17:55:50.943Z',
      created_by_user_id: 15,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:20:00',
      id: 189942,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 13027,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 12,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T19:25:08.427Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-17T11:12:37.681Z',
      created_by_user_id: 15,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:25:00',
      id: 180315,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-17',
      source: null,
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T13:32:54.951Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3258,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T19:05:45.304Z',
      created_by_user_id: 15,
      date: '2023-01-21T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180584,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-21',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '10:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T19:05:45.304Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T21:35:57.567Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '14:30:00',
      id: 180667,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5719,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '11:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 280,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T16:07:47.259Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 35911,
      createdAt: '2023-01-18T11:42:36.560Z',
      created_by_user_id: 15,
      date: '2023-01-19T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180790,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-19',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-19T13:41:15.840Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-05T22:39:36.711Z',
      created_by_user_id: 15,
      date: '2023-01-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176181,
      net_value: null,
      past: true,
      payment_date: '2023-01-06T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-06',
      source: null,
      start_date: null,
      start_hour: '08:15:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 85,
      total_value: 85,
      total_with_barber_percentage: 85,
      total_with_payment_percentage: 85,
      type: 'normal',
      updatedAt: '2023-02-03T13:42:51.417Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T21:06:36.953Z',
      created_by_user_id: null,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 179314,
      net_value: 85,
      past: true,
      payment_date: '2023-01-17T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.6915,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-17',
      source: 'barbershop_mobile_app_phone_call',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.64978,
      total_value: 85,
      total_with_barber_percentage: 58.34128,
      total_with_payment_percentage: 83.3085,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-17T19:47:27.784Z',
      created_by_user_id: null,
      date: '2023-01-18T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 180606,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-18',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-18T11:43:02.631Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T20:12:10.961Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:40:00',
      id: 179283,
      net_value: 85,
      past: true,
      payment_date: '2023-02-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 1.72822,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.07178,
      total_value: 85,
      total_with_barber_percentage: 57.8,
      total_with_payment_percentage: 83.27178,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 1.5,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-01-31T17:53:36.207Z',
      created_by_user_id: 15,
      date: '2023-01-31T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:30:00',
      id: 186622,
      net_value: 25,
      past: true,
      payment_date: '2023-01-31T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'pix',
      payment_method_choosed: [Object],
      payment_method_id: 2643,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 12942,
      product_price: 15,
      promotional: false,
      simple_date: '2023-01-31',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '16:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 21.8,
      total_value: 25,
      total_with_barber_percentage: 21.8,
      total_with_payment_percentage: 25,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 0.12297723523589575,
      barber_products_percentage_value: 17,
      barber_products_value: 20.23173870009898,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-02T20:04:48.044Z',
      created_by_user_id: null,
      date: '2023-02-03T03:00:00.000Z',
      discount: 1,
      discount_observation: 'teste',
      end_date: null,
      finish_hour: '18:20:00',
      id: 188036,
      net_value: 120.24,
      past: true,
      payment_date: '2023-03-05T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9865699935334873,
      product: [Object],
      product_id: 12945,
      product_price: 1.24,
      promotional: false,
      simple_date: '2023-02-03',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 96.89871407113162,
      total_value: 120.24,
      total_with_barber_percentage: 99.88528406466511,
      total_with_payment_percentage: 117.2534300064665,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:51.203Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 3.5,
      barber_percentage_value: 0.3466884615384616,
      barber_products_percentage_value: 2.5,
      barber_products_value: 2.9716153846153848,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:40:41.695Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 1.23,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178308,
      net_value: 128.77,
      past: true,
      payment_date: '2023-02-11T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 3.7510057150000007,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 121.70069043884615,
      total_value: 128.77,
      total_with_barber_percentage: 125.45169615384616,
      total_with_payment_percentage: 125.018994285,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:47:00.323Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:20:00',
      id: 178311,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:50:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-12T14:54:20.556Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 1.56816,
      barber_products_percentage_value: 17,
      barber_products_value: 0.8330850000000001,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:43:52.567Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 10,
      discount_observation: 'TESTE',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178310,
      net_value: 10,
      past: true,
      payment_date: '2023-01-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 0.199,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 7.399754999999999,
      total_value: 10,
      total_with_barber_percentage: 7.598754999999999,
      total_with_payment_percentage: 9.801,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:44:09.464Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176388,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:30:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 50.010000000000005,
      total_value: 50.010000000000005,
      total_with_barber_percentage: 50.010000000000005,
      total_with_payment_percentage: 50.010000000000005,
      type: 'normal',
      updatedAt: '2023-02-03T13:48:27.469Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 15.868361270355203,
      barber_products_percentage_value: 10,
      barber_products_value: 1.8677994648223981,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-04T18:03:16.912Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 5,
      discount_observation: 'Desconto especial',
      end_date: null,
      finish_hour: '16:20:00',
      id: 175763,
      net_value: 100.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.9901990000000003,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:40:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 80.2836402648224,
      total_value: 100.01,
      total_with_barber_percentage: 82.2738392648224,
      total_with_payment_percentage: 98.019801,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:42:55.112Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:25:00',
      id: 176385,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.9850000000000003,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 147.015,
      total_value: 150,
      total_with_barber_percentage: 150,
      total_with_payment_percentage: 147.015,
      type: 'normal',
      updatedAt: '2023-02-03T17:28:51.039Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 3.3340061700000003,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-03T23:00:27.844Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 175626,
      net_value: 105.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.0896990000000004,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 72.92757483000001,
      total_value: 105.01,
      total_with_barber_percentage: 75.01727383000001,
      total_with_payment_percentage: 102.92030100000001,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41428,
      createdAt: '2023-01-11T20:50:08.307Z',
      created_by_user_id: null,
      date: '2023-01-28T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 178273,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-28',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-28T16:51:07.653Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 7,
      barber_products_percentage_value: 10,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41585,
      createdAt: '2023-01-13T20:38:01.984Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:30:00',
      id: 179301,
      net_value: 35,
      past: true,
      payment_date: '2023-01-13T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 4771,
      product_price: 35,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 28,
      total_value: 35,
      total_with_barber_percentage: 28,
      total_with_payment_percentage: 35,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-24T12:45:25.997Z',
      created_by_user_id: null,
      date: '2023-01-25T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 183312,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-25',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-25T13:08:06.963Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 9.6,
      barber_products_percentage_value: 17,
      barber_products_value: 20.400000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41588,
      createdAt: '2023-01-13T21:22:54.416Z',
      created_by_user_id: null,
      date: '2023-01-14T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 179329,
      net_value: 150,
      past: true,
      payment_date: '2023-01-14T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-14',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 120,
      total_value: 150,
      total_with_barber_percentage: 120,
      total_with_payment_percentage: 150,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41687,
      createdAt: '2023-01-16T15:09:40.110Z',
      created_by_user_id: null,
      date: '2023-01-16T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '13:10:00',
      id: 179964,
      net_value: 95,
      past: true,
      payment_date: '2023-01-16T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-16',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '12:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 66.1,
      total_value: 95,
      total_with_barber_percentage: 66.1,
      total_with_payment_percentage: 95,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 10,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-01-26T19:39:43.497Z',
      created_by_user_id: null,
      date: '2023-01-26T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 184544,
      net_value: 110,
      past: true,
      payment_date: '2023-02-25T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9391700000000003,
      product: [Object],
      product_id: 12887,
      product_price: 100,
      promotional: false,
      simple_date: '2023-01-26',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 95.36082999999999,
      total_value: 110,
      total_with_barber_percentage: 98.3,
      total_with_payment_percentage: 107.06083,
      type: 'fit',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
  ],
  appointments_total_comissions: 208.4618716866663,
  appointments_total_discount: 17.23,
  appointments_total_products: 580.03,
  appointments_total_services: 2128.24,
  appointments_total_value: 2691.04,
  comissions_info: {
    data: [
      {
        appointments: Array(3),
        barber: {
          name: 'Lucas Azevedo',
          percentage: 5,
          products_percentage: 0,
        },
        products_comission: 3,
        services_comission: 5,
        total_comission: 10,
        total_sales: 125,
      },
      {
        appointments: Array(6),
        barber: {
          name: 'Rafael Pereira',
          percentage: 10,
          products_percentage: 0,
        },
        products_comission: 2,
        services_comission: 5,
        total_comission: 11,
        total_sales: 120,
      },
      {
        appointments: Array(5),
        barber: {
          name: 'Gabriel Rangel',
          percentage: 5,
          products_percentage: 0,
        },
        products_comission: 5,
        services_comission: 5,
        total_comission: 14,
        total_sales: 230,
      },
      {
        appointments: Array(2),
        barber: {
          name: 'Matheus Stehling',
          percentage: 5,
          products_percentage: 0,
        },
        products_comission: 5,
        services_comission: 5,
        total_comission: 15,
        total_sales: 300,
      },
      {
        appointments: Array(9),
        barber: { name: 'André Rangel', percentage: 5, products_percentage: 5 },
        products_comission: 5,
        services_comission: 5,
        total_comission: 10,
        total_sales: 500,
      },
    ],
    total_value: 1275.46,
  },
  total_appointments: 33,
  total_awaiting: 4,
  total_finished: 15,
  total_pending: 14,
}

export const servicesReport = {
  all_appointments: [
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:42:45.994Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:10:00',
      id: 182101,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:27:24.762Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:30:00',
      id: 182090,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '14:40:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:49:58.664Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '19:00:00',
      id: 182070,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 43460,
      createdAt: '2023-02-03T18:43:08.400Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:50:00',
      id: 188812,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:43:08.400Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:48:03.226Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:10:00',
      id: 182068,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '17:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41428,
      createdAt: '2023-01-11T20:50:08.307Z',
      created_by_user_id: null,
      date: '2023-01-28T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 178273,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-28',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-28T16:51:07.653Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-03T18:53:15.293Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:40:00',
      id: 188825,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:50:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:53:15.293Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-02-03T18:56:07.000Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:00:00',
      id: 188828,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12942,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 15,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:56:07.000Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-06T17:02:34.265Z',
      created_by_user_id: 15,
      date: '2023-02-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:10:00',
      id: 189904,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-06',
      source: null,
      start_date: null,
      start_hour: '14:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-06T17:55:20.071Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-02-06T17:55:50.943Z',
      created_by_user_id: 15,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:20:00',
      id: 189942,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 13027,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 12,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T19:25:08.427Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3258,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T19:05:45.304Z',
      created_by_user_id: 15,
      date: '2023-01-21T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180584,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-21',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '10:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T19:05:45.304Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-17T19:47:27.784Z',
      created_by_user_id: null,
      date: '2023-01-18T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 180606,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-18',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-18T11:43:02.631Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T20:12:10.961Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:40:00',
      id: 179283,
      net_value: 85,
      past: true,
      payment_date: '2023-02-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 1.72822,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.07178,
      total_value: 85,
      total_with_barber_percentage: 57.8,
      total_with_payment_percentage: 83.27178,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 0.12297723523589575,
      barber_products_percentage_value: 17,
      barber_products_value: 20.23173870009898,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-02T20:04:48.044Z',
      created_by_user_id: null,
      date: '2023-02-03T03:00:00.000Z',
      discount: 1,
      discount_observation: 'teste',
      end_date: null,
      finish_hour: '18:20:00',
      id: 188036,
      net_value: 120.24,
      past: true,
      payment_date: '2023-03-05T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9865699935334873,
      product: [Object],
      product_id: 12945,
      product_price: 1.24,
      promotional: false,
      simple_date: '2023-02-03',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 96.89871407113162,
      total_value: 120.24,
      total_with_barber_percentage: 99.88528406466511,
      total_with_payment_percentage: 117.2534300064665,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:51.203Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:47:00.323Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:20:00',
      id: 178311,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:50:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-12T14:54:20.556Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:44:09.464Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176388,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:30:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 50.010000000000005,
      total_value: 50.010000000000005,
      total_with_barber_percentage: 50.010000000000005,
      total_with_payment_percentage: 50.010000000000005,
      type: 'normal',
      updatedAt: '2023-02-03T13:48:27.469Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:42:55.112Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:25:00',
      id: 176385,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.9850000000000003,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 147.015,
      total_value: 150,
      total_with_barber_percentage: 150,
      total_with_payment_percentage: 147.015,
      type: 'normal',
      updatedAt: '2023-02-03T17:28:51.039Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 15.868361270355203,
      barber_products_percentage_value: 10,
      barber_products_value: 1.8677994648223981,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-04T18:03:16.912Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 5,
      discount_observation: 'Desconto especial',
      end_date: null,
      finish_hour: '16:20:00',
      id: 175763,
      net_value: 100.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.9901990000000003,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:40:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 80.2836402648224,
      total_value: 100.01,
      total_with_barber_percentage: 82.2738392648224,
      total_with_payment_percentage: 98.019801,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 3.3340061700000003,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-03T23:00:27.844Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 175626,
      net_value: 105.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.0896990000000004,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 72.92757483000001,
      total_value: 105.01,
      total_with_barber_percentage: 75.01727383000001,
      total_with_payment_percentage: 102.92030100000001,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 7,
      barber_products_percentage_value: 10,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41585,
      createdAt: '2023-01-13T20:38:01.984Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:30:00',
      id: 179301,
      net_value: 35,
      past: true,
      payment_date: '2023-01-13T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 4771,
      product_price: 35,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 28,
      total_value: 35,
      total_with_barber_percentage: 28,
      total_with_payment_percentage: 35,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 9.6,
      barber_products_percentage_value: 17,
      barber_products_value: 20.400000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41588,
      createdAt: '2023-01-13T21:22:54.416Z',
      created_by_user_id: null,
      date: '2023-01-14T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 179329,
      net_value: 150,
      past: true,
      payment_date: '2023-01-14T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-14',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 120,
      total_value: 150,
      total_with_barber_percentage: 120,
      total_with_payment_percentage: 150,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-17T11:12:37.681Z',
      created_by_user_id: 15,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:25:00',
      id: 180315,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-17',
      source: null,
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T13:32:54.951Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T21:35:57.567Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '14:30:00',
      id: 180667,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5719,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '11:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 280,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T16:07:47.259Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 35911,
      createdAt: '2023-01-18T11:42:36.560Z',
      created_by_user_id: 15,
      date: '2023-01-19T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180790,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-19',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-19T13:41:15.840Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3241,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-09T13:43:13.588Z',
      created_by_user_id: null,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '11:50:00',
      id: 191752,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '11:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T20:53:41.854Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-05T22:39:36.711Z',
      created_by_user_id: 15,
      date: '2023-01-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176181,
      net_value: null,
      past: true,
      payment_date: '2023-01-06T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-06',
      source: null,
      start_date: null,
      start_hour: '08:15:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 85,
      total_value: 85,
      total_with_barber_percentage: 85,
      total_with_payment_percentage: 85,
      type: 'normal',
      updatedAt: '2023-02-03T13:42:51.417Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T21:06:36.953Z',
      created_by_user_id: null,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 179314,
      net_value: 85,
      past: true,
      payment_date: '2023-01-17T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.6915,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-17',
      source: 'barbershop_mobile_app_phone_call',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.64978,
      total_value: 85,
      total_with_barber_percentage: 58.34128,
      total_with_payment_percentage: 83.3085,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 1.5,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-01-31T17:53:36.207Z',
      created_by_user_id: 15,
      date: '2023-01-31T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:30:00',
      id: 186622,
      net_value: 25,
      past: true,
      payment_date: '2023-01-31T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'pix',
      payment_method_choosed: [Object],
      payment_method_id: 2643,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 12942,
      product_price: 15,
      promotional: false,
      simple_date: '2023-01-31',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '16:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 21.8,
      total_value: 25,
      total_with_barber_percentage: 21.8,
      total_with_payment_percentage: 25,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 3.5,
      barber_percentage_value: 0.3466884615384616,
      barber_products_percentage_value: 2.5,
      barber_products_value: 2.9716153846153848,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:40:41.695Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 1.23,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178308,
      net_value: 128.77,
      past: true,
      payment_date: '2023-02-11T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 3.7510057150000007,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 121.70069043884615,
      total_value: 128.77,
      total_with_barber_percentage: 125.45169615384616,
      total_with_payment_percentage: 125.018994285,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 1.56816,
      barber_products_percentage_value: 17,
      barber_products_value: 0.8330850000000001,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:43:52.567Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 10,
      discount_observation: 'TESTE',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178310,
      net_value: 10,
      past: true,
      payment_date: '2023-01-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 0.199,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 7.399754999999999,
      total_value: 10,
      total_with_barber_percentage: 7.598754999999999,
      total_with_payment_percentage: 9.801,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-24T12:45:25.997Z',
      created_by_user_id: null,
      date: '2023-01-25T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 183312,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-25',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-25T13:08:06.963Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41687,
      createdAt: '2023-01-16T15:09:40.110Z',
      created_by_user_id: null,
      date: '2023-01-16T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '13:10:00',
      id: 179964,
      net_value: 95,
      past: true,
      payment_date: '2023-01-16T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-16',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '12:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 66.1,
      total_value: 95,
      total_with_barber_percentage: 66.1,
      total_with_payment_percentage: 95,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 10,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-01-26T19:39:43.497Z',
      created_by_user_id: null,
      date: '2023-01-26T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 184544,
      net_value: 110,
      past: true,
      payment_date: '2023-02-25T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9391700000000003,
      product: [Object],
      product_id: 12887,
      product_price: 100,
      promotional: false,
      simple_date: '2023-01-26',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 95.36082999999999,
      total_value: 110,
      total_with_barber_percentage: 98.3,
      total_with_payment_percentage: 107.06083,
      type: 'fit',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
  ],
  all_sells: [
    {
      discount: 0,
      promotion_price: 0,
      quantity: 10,
      service: 'Corte de cabelo',
      total_value: 1000,
      value: 100,
    },
    {
      discount: 0,
      promotion_price: 0,
      quantity: 5,
      service: 'Escova ',
      total_value: 150,
      value: 30,
    },
    {
      discount: 0,
      promotion_price: 0,
      quantity: 2,
      service: 'Teste ',
      total_value: 30,
      value: 15,
    },
    {
      discount: 0,
      promotion_price: 10,
      quantity: 1,
      service: 'Teste',
      total_value: 12,
      value: 12,
    },
    {
      discount: 0,
      promotion_price: 10,
      quantity: 7,
      service: 'Corte com Mariana',
      total_value: 145,
      value: 35,
    },
    {
      discount: 27.2,
      promotion_price: 85,
      quantity: 6,
      service: 'Hidratação',
      total_value: 510,
      value: 85,
    },
    {
      discount: 0.12297723523589575,
      promotion_price: 0,
      quantity: 1,
      service: 'Testee',
      total_value: 1.24,
      value: 1.24,
    },
    {
      discount: 0,
      promotion_price: 0,
      quantity: 1,
      service: 'Platinado',
      total_value: 280,
      value: 280,
    },
    {
      category: 'product',
      name: 'Shampoo',
      quantity: 4,
      total_value: 480,
      value: 120,
    },
    {
      category: 'product',
      name: 'Pomada',
      quantity: 8,
      total_value: 720,
      value: 90,
    },
  ],
  appointments_total_products: 580.03,
  appointments_total_services: 2128.24,
  appointments_total_value: 2691.04,
  not_finished_appointments_total_value: 1357,
  total_appointments: 33,
  total_awaiting: 4,
  total_finished: 15,
  total_pending: 14,
}

export const paymentReport = {
  all_appointments: [
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:42:45.994Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:10:00',
      id: 182101,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:27:24.762Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:30:00',
      id: 182090,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '14:40:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:48:03.226Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:10:00',
      id: 182068,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '17:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T21:35:57.567Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '14:30:00',
      id: 180667,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5719,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '11:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 280,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T16:07:47.259Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:49:58.664Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '19:00:00',
      id: 182070,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 43460,
      createdAt: '2023-02-03T18:43:08.400Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:50:00',
      id: 188812,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:43:08.400Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-03T18:53:15.293Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:40:00',
      id: 188825,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:50:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:53:15.293Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-02-03T18:56:07.000Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:00:00',
      id: 188828,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12942,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 15,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:56:07.000Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-02-06T17:55:50.943Z',
      created_by_user_id: 15,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:20:00',
      id: 189942,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 13027,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 12,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T19:25:08.427Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-17T11:12:37.681Z',
      created_by_user_id: 15,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:25:00',
      id: 180315,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-17',
      source: null,
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T13:32:54.951Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3258,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T19:05:45.304Z',
      created_by_user_id: 15,
      date: '2023-01-21T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180584,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-21',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '10:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T19:05:45.304Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-06T17:02:34.265Z',
      created_by_user_id: 15,
      date: '2023-02-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:10:00',
      id: 189904,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-06',
      source: null,
      start_date: null,
      start_hour: '14:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-06T17:55:20.071Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T21:06:36.953Z',
      created_by_user_id: null,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 179314,
      net_value: 85,
      past: true,
      payment_date: '2023-01-17T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.6915,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-17',
      source: 'barbershop_mobile_app_phone_call',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.64978,
      total_value: 85,
      total_with_barber_percentage: 58.34128,
      total_with_payment_percentage: 83.3085,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 35911,
      createdAt: '2023-01-18T11:42:36.560Z',
      created_by_user_id: 15,
      date: '2023-01-19T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180790,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-19',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-19T13:41:15.840Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3241,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-09T13:43:13.588Z',
      created_by_user_id: null,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '11:50:00',
      id: 191752,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '11:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T20:53:41.854Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-05T22:39:36.711Z',
      created_by_user_id: 15,
      date: '2023-01-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176181,
      net_value: null,
      past: true,
      payment_date: '2023-01-06T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-06',
      source: null,
      start_date: null,
      start_hour: '08:15:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 85,
      total_value: 85,
      total_with_barber_percentage: 85,
      total_with_payment_percentage: 85,
      type: 'normal',
      updatedAt: '2023-02-03T13:42:51.417Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-17T19:47:27.784Z',
      created_by_user_id: null,
      date: '2023-01-18T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 180606,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-18',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-18T11:43:02.631Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T20:12:10.961Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:40:00',
      id: 179283,
      net_value: 85,
      past: true,
      payment_date: '2023-02-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 1.72822,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.07178,
      total_value: 85,
      total_with_barber_percentage: 57.8,
      total_with_payment_percentage: 83.27178,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 1.5,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-01-31T17:53:36.207Z',
      created_by_user_id: 15,
      date: '2023-01-31T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:30:00',
      id: 186622,
      net_value: 25,
      past: true,
      payment_date: '2023-01-31T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'pix',
      payment_method_choosed: [Object],
      payment_method_id: 2643,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 12942,
      product_price: 15,
      promotional: false,
      simple_date: '2023-01-31',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '16:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 21.8,
      total_value: 25,
      total_with_barber_percentage: 21.8,
      total_with_payment_percentage: 25,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 0.12297723523589575,
      barber_products_percentage_value: 17,
      barber_products_value: 20.23173870009898,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-02T20:04:48.044Z',
      created_by_user_id: null,
      date: '2023-02-03T03:00:00.000Z',
      discount: 1,
      discount_observation: 'teste',
      end_date: null,
      finish_hour: '18:20:00',
      id: 188036,
      net_value: 120.24,
      past: true,
      payment_date: '2023-03-05T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9865699935334873,
      product: [Object],
      product_id: 12945,
      product_price: 1.24,
      promotional: false,
      simple_date: '2023-02-03',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 96.89871407113162,
      total_value: 120.24,
      total_with_barber_percentage: 99.88528406466511,
      total_with_payment_percentage: 117.2534300064665,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:51.203Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 3.5,
      barber_percentage_value: 0.3466884615384616,
      barber_products_percentage_value: 2.5,
      barber_products_value: 2.9716153846153848,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:40:41.695Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 1.23,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178308,
      net_value: 128.77,
      past: true,
      payment_date: '2023-02-11T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 3.7510057150000007,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 121.70069043884615,
      total_value: 128.77,
      total_with_barber_percentage: 125.45169615384616,
      total_with_payment_percentage: 125.018994285,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:47:00.323Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:20:00',
      id: 178311,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:50:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-12T14:54:20.556Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 1.56816,
      barber_products_percentage_value: 17,
      barber_products_value: 0.8330850000000001,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:43:52.567Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 10,
      discount_observation: 'TESTE',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178310,
      net_value: 10,
      past: true,
      payment_date: '2023-01-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 0.199,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 7.399754999999999,
      total_value: 10,
      total_with_barber_percentage: 7.598754999999999,
      total_with_payment_percentage: 9.801,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:44:09.464Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176388,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:30:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 50.010000000000005,
      total_value: 50.010000000000005,
      total_with_barber_percentage: 50.010000000000005,
      total_with_payment_percentage: 50.010000000000005,
      type: 'normal',
      updatedAt: '2023-02-03T13:48:27.469Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 15.868361270355203,
      barber_products_percentage_value: 10,
      barber_products_value: 1.8677994648223981,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-04T18:03:16.912Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 5,
      discount_observation: 'Desconto especial',
      end_date: null,
      finish_hour: '16:20:00',
      id: 175763,
      net_value: 100.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.9901990000000003,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:40:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 80.2836402648224,
      total_value: 100.01,
      total_with_barber_percentage: 82.2738392648224,
      total_with_payment_percentage: 98.019801,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:42:55.112Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:25:00',
      id: 176385,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.9850000000000003,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 147.015,
      total_value: 150,
      total_with_barber_percentage: 150,
      total_with_payment_percentage: 147.015,
      type: 'normal',
      updatedAt: '2023-02-03T17:28:51.039Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41428,
      createdAt: '2023-01-11T20:50:08.307Z',
      created_by_user_id: null,
      date: '2023-01-28T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 178273,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-28',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-28T16:51:07.653Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 3.3340061700000003,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-03T23:00:27.844Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 175626,
      net_value: 105.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.0896990000000004,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 72.92757483000001,
      total_value: 105.01,
      total_with_barber_percentage: 75.01727383000001,
      total_with_payment_percentage: 102.92030100000001,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 7,
      barber_products_percentage_value: 10,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41585,
      createdAt: '2023-01-13T20:38:01.984Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:30:00',
      id: 179301,
      net_value: 35,
      past: true,
      payment_date: '2023-01-13T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 4771,
      product_price: 35,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 28,
      total_value: 35,
      total_with_barber_percentage: 28,
      total_with_payment_percentage: 35,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 10,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-01-26T19:39:43.497Z',
      created_by_user_id: null,
      date: '2023-01-26T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 184544,
      net_value: 110,
      past: true,
      payment_date: '2023-02-25T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9391700000000003,
      product: [Object],
      product_id: 12887,
      product_price: 100,
      promotional: false,
      simple_date: '2023-01-26',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 95.36082999999999,
      total_value: 110,
      total_with_barber_percentage: 98.3,
      total_with_payment_percentage: 107.06083,
      type: 'fit',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 9.6,
      barber_products_percentage_value: 17,
      barber_products_value: 20.400000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41588,
      createdAt: '2023-01-13T21:22:54.416Z',
      created_by_user_id: null,
      date: '2023-01-14T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 179329,
      net_value: 150,
      past: true,
      payment_date: '2023-01-14T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-14',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 120,
      total_value: 150,
      total_with_barber_percentage: 120,
      total_with_payment_percentage: 150,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41687,
      createdAt: '2023-01-16T15:09:40.110Z',
      created_by_user_id: null,
      date: '2023-01-16T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '13:10:00',
      id: 179964,
      net_value: 95,
      past: true,
      payment_date: '2023-01-16T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-16',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '12:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 66.1,
      total_value: 95,
      total_with_barber_percentage: 66.1,
      total_with_payment_percentage: 95,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-24T12:45:25.997Z',
      created_by_user_id: null,
      date: '2023-01-25T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 183312,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-25',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-25T13:08:06.963Z',
    },
  ],
  appointment_payments_methods_discounts: 20.36036370853349,
  appointments_total_products: 580.03,
  appointments_total_value: 1334.04,
  payment_method_info: {
    data: [
      {
        payment_method: {
          barbershop_id: 1,
          createdAt: '2021-11-11T13:29:49.732Z',
          discount_from_barber: true,
          due_in_days: 0,
          id: 179,
          inactive: true,
          name: 'Dinheiro',
          percentage: 0,
          updatedAt: '2023-01-16T16:43:20.777Z',
        },
        quantity: 5,
        value: 415.01,
        value_percentage: 0,
      },
      {
        payment_method: {
          barbershop_id: 1,
          createdAt: '2021-08-11T01:46:27.260Z',
          discount_from_barber: false,
          due_in_days: 30,
          id: 2,
          inactive: false,
          name: 'Visa - Crédito',
          percentage: 2.99,
          updatedAt: '2022-11-20T17:12:30.227Z',
        },
        quantity: 4,
        value: 444.01,
        value_percentage: 11.404965708533489,
      },
      {
        payment_method: {
          barbershop_id: 1,
          createdAt: '2021-10-21T15:11:08.478Z',
          discount_from_barber: true,
          due_in_days: 0,
          id: 145,
          inactive: false,
          name: 'MasterCard',
          percentage: 1.99,
          updatedAt: '2021-10-21T15:11:08.478Z',
        },
        quantity: 5,
        value: 450.02,
        value_percentage: 8.955398000000002,
      },
      {
        payment_method: {
          barbershop_id: 1,
          createdAt: '2023-01-16T16:43:27.246Z',
          discount_from_barber: false,
          due_in_days: 0,
          id: 2643,
          inactive: false,
          name: 'PIX',
          percentage: 0,
          updatedAt: '2023-01-16T16:43:27.246Z',
        },
        quantity: 1,
        value: 25,
        value_percentage: 0,
      },
    ],
    total: 15,
  },
  total_appointments: 33,
  total_awaiting: 4,
  total_finished: 15,
  total_pending: 14,
}

export const clientReport = {
  all_appointments: [
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:42:45.994Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:10:00',
      id: 182101,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-20T17:27:24.762Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:30:00',
      id: 182090,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '14:40:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T18:29:16.551Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:49:58.664Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '19:00:00',
      id: 182070,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-02-03T18:56:07.000Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:00:00',
      id: 188828,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12942,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 15,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:56:07.000Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-20T16:48:03.226Z',
      created_by_user_id: null,
      date: '2023-01-24T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:10:00',
      id: 182068,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-24',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '17:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-24T21:50:41.913Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 43460,
      createdAt: '2023-02-03T18:43:08.400Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:50:00',
      id: 188812,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:43:08.400Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-03T18:53:15.293Z',
      created_by_user_id: null,
      date: '2023-02-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:40:00',
      id: 188825,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:50:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-03T18:53:15.293Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-06T17:02:34.265Z',
      created_by_user_id: 15,
      date: '2023-02-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '15:10:00',
      id: 189904,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-06',
      source: null,
      start_date: null,
      start_hour: '14:20:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-06T17:55:20.071Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-02-06T17:55:50.943Z',
      created_by_user_id: 15,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:20:00',
      id: 189942,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 13027,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 12,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T19:25:08.427Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-17T11:12:37.681Z',
      created_by_user_id: 15,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:25:00',
      id: 180315,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-17',
      source: null,
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T13:32:54.951Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3258,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T19:05:45.304Z',
      created_by_user_id: 15,
      date: '2023-01-21T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180584,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-21',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '10:00:00',
      status: 'awaiting',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-17T19:05:45.304Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 30885,
      createdAt: '2023-01-17T21:35:57.567Z',
      created_by_user_id: null,
      date: '2023-01-20T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '14:30:00',
      id: 180667,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5719,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-20',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '11:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 280,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-20T16:07:47.259Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 35911,
      createdAt: '2023-01-18T11:42:36.560Z',
      created_by_user_id: 15,
      date: '2023-01-19T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '10:30:00',
      id: 180790,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-19',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '10:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-19T13:41:15.840Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 3241,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-02-09T13:43:13.588Z',
      created_by_user_id: null,
      date: '2023-02-09T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '11:50:00',
      id: 191752,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-02-09',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '11:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-02-09T20:53:41.854Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-05T22:39:36.711Z',
      created_by_user_id: 15,
      date: '2023-01-06T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176181,
      net_value: null,
      past: true,
      payment_date: '2023-01-06T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-06',
      source: null,
      start_date: null,
      start_hour: '08:15:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 85,
      total_value: 85,
      total_with_barber_percentage: 85,
      total_with_payment_percentage: 85,
      type: 'normal',
      updatedAt: '2023-02-03T13:42:51.417Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-17T19:47:27.784Z',
      created_by_user_id: null,
      date: '2023-01-18T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 180606,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-18',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:30:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 35,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-18T11:43:02.631Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T21:06:36.953Z',
      created_by_user_id: null,
      date: '2023-01-17T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 179314,
      net_value: 85,
      past: true,
      payment_date: '2023-01-17T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.6915,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-17',
      source: 'barbershop_mobile_app_phone_call',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.64978,
      total_value: 85,
      total_with_barber_percentage: 58.34128,
      total_with_payment_percentage: 83.3085,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 1.5,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 31220,
      createdAt: '2023-01-31T17:53:36.207Z',
      created_by_user_id: 15,
      date: '2023-01-31T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '16:30:00',
      id: 186622,
      net_value: 25,
      past: true,
      payment_date: '2023-01-31T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'pix',
      payment_method_choosed: [Object],
      payment_method_id: 2643,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 12942,
      product_price: 15,
      promotional: false,
      simple_date: '2023-01-31',
      source: 'barbershop_mobile_app_presential',
      start_date: null,
      start_hour: '16:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 21.8,
      total_value: 25,
      total_with_barber_percentage: 21.8,
      total_with_payment_percentage: 25,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 0.12297723523589575,
      barber_products_percentage_value: 17,
      barber_products_value: 20.23173870009898,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-02-02T20:04:48.044Z',
      created_by_user_id: null,
      date: '2023-02-03T03:00:00.000Z',
      discount: 1,
      discount_observation: 'teste',
      end_date: null,
      finish_hour: '18:20:00',
      id: 188036,
      net_value: 120.24,
      past: true,
      payment_date: '2023-03-05T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9865699935334873,
      product: [Object],
      product_id: 12945,
      product_price: 1.24,
      promotional: false,
      simple_date: '2023-02-03',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 96.89871407113162,
      total_value: 120.24,
      total_with_barber_percentage: 99.88528406466511,
      total_with_payment_percentage: 117.2534300064665,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:51.203Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 1.56816,
      barber_products_percentage_value: 17,
      barber_products_value: 0.8330850000000001,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:43:52.567Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 10,
      discount_observation: 'TESTE',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178310,
      net_value: 10,
      past: true,
      payment_date: '2023-01-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 0.199,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 7.399754999999999,
      total_value: 10,
      total_with_barber_percentage: 7.598754999999999,
      total_with_payment_percentage: 9.801,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 15.868361270355203,
      barber_products_percentage_value: 10,
      barber_products_value: 1.8677994648223981,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-04T18:03:16.912Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 5,
      discount_observation: 'Desconto especial',
      end_date: null,
      finish_hour: '16:20:00',
      id: 175763,
      net_value: 100.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 1.9901990000000003,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '15:40:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 80.2836402648224,
      total_value: 100.01,
      total_with_barber_percentage: 82.2738392648224,
      total_with_payment_percentage: 98.019801,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 26.65872,
      barber_products_percentage_value: 17,
      barber_products_value: 3.3340061700000003,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-03T23:00:27.844Z',
      created_by_user_id: 15,
      date: '2023-01-04T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:40:00',
      id: 175626,
      net_value: 105.01,
      past: true,
      payment_date: '2023-01-04T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.0896990000000004,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-04',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 72.92757483000001,
      total_value: 105.01,
      total_with_barber_percentage: 75.01727383000001,
      total_with_payment_percentage: 102.92030100000001,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 20,
      barber_percentage_value: 7,
      barber_products_percentage_value: 10,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41585,
      createdAt: '2023-01-13T20:38:01.984Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:30:00',
      id: 179301,
      net_value: 35,
      past: true,
      payment_date: '2023-01-13T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 4771,
      product_price: 35,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 28,
      total_value: 35,
      total_with_barber_percentage: 28,
      total_with_payment_percentage: 35,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41687,
      createdAt: '2023-01-16T15:09:40.110Z',
      created_by_user_id: null,
      date: '2023-01-16T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '13:10:00',
      id: 179964,
      net_value: 95,
      past: true,
      payment_date: '2023-01-16T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: true,
      simple_date: '2023-01-16',
      source: 'barbershop_mobile_app',
      start_date: null,
      start_hour: '12:30:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 66.1,
      total_value: 95,
      total_with_barber_percentage: 66.1,
      total_with_payment_percentage: 95,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 10,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 10,
      barber_percentage_value: 10,
      barber_products_percentage_value: 17,
      barber_products_value: 1.7000000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 1305,
      createdAt: '2023-01-26T19:39:43.497Z',
      created_by_user_id: null,
      date: '2023-01-26T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 184544,
      net_value: 110,
      past: true,
      payment_date: '2023-02-25T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 2.9391700000000003,
      product: [Object],
      product_id: 12887,
      product_price: 100,
      promotional: false,
      simple_date: '2023-01-26',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 95.36082999999999,
      total_value: 110,
      total_with_barber_percentage: 98.3,
      total_with_payment_percentage: 107.06083,
      type: 'fit',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 0,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 27.2,
      barber_products_percentage_value: 17,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-13T20:12:10.961Z',
      created_by_user_id: null,
      date: '2023-01-13T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '18:40:00',
      id: 179283,
      net_value: 85,
      past: true,
      payment_date: '2023-02-12T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 1.72822,
      product: [Object],
      product_id: 2637,
      product_price: 85,
      promotional: false,
      simple_date: '2023-01-13',
      source: null,
      start_date: null,
      start_hour: '18:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 56.07178,
      total_value: 85,
      total_with_barber_percentage: 57.8,
      total_with_payment_percentage: 83.27178,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.815Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 2,
      barber_percentage: 3.5,
      barber_percentage_value: 0.3466884615384616,
      barber_products_percentage_value: 2.5,
      barber_products_value: 2.9716153846153848,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:40:41.695Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 1.23,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:30:00',
      id: 178308,
      net_value: 128.77,
      past: true,
      payment_date: '2023-02-11T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'credit',
      payment_method_choosed: [Object],
      payment_method_id: 2,
      payment_percentage: 2.99,
      payment_percentage_value: 3.7510057150000007,
      product: [Object],
      product_id: 4771,
      product_price: 10,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 121.70069043884615,
      total_value: 128.77,
      total_with_barber_percentage: 125.45169615384616,
      total_with_payment_percentage: 125.018994285,
      type: 'normal',
      updatedAt: '2023-02-09T14:49:11.102Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 29121,
      createdAt: '2023-01-11T21:47:00.323Z',
      created_by_user_id: null,
      date: '2023-01-12T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:20:00',
      id: 178311,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 4771,
      product_price: null,
      promotional: true,
      simple_date: '2023-01-12',
      source: null,
      start_date: null,
      start_hour: '08:50:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 10,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-12T14:54:20.556Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 20.01,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:44:09.464Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:55:00',
      id: 176388,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:30:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 50.010000000000005,
      total_value: 50.010000000000005,
      total_with_barber_percentage: 50.010000000000005,
      total_with_payment_percentage: 50.010000000000005,
      type: 'normal',
      updatedAt: '2023-02-03T13:48:27.469Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 3199,
      barber_percentage: 0,
      barber_percentage_value: 0,
      barber_products_percentage_value: 0,
      barber_products_value: 0,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-06T14:42:55.112Z',
      created_by_user_id: 15,
      date: '2023-01-07T03:00:00.000Z',
      discount: 0,
      discount_observation: 'Desc',
      end_date: null,
      finish_hour: '08:25:00',
      id: 176385,
      net_value: null,
      past: true,
      payment_date: '2023-01-07T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'others',
      payment_method_choosed: [Object],
      payment_method_id: 145,
      payment_percentage: 1.99,
      payment_percentage_value: 2.9850000000000003,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-07',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: null,
      total_after_discounts: 147.015,
      total_value: 150,
      total_with_barber_percentage: 150,
      total_with_payment_percentage: 147.015,
      type: 'normal',
      updatedAt: '2023-02-03T17:28:51.039Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 2,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41428,
      createdAt: '2023-01-11T20:50:08.307Z',
      created_by_user_id: null,
      date: '2023-01-28T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 178273,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 5720,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-28',
      source: null,
      start_date: null,
      start_hour: '08:00:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 30,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-28T16:51:07.653Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: 120,
      barber: [Object],
      barber_id: 684,
      barber_percentage: 32,
      barber_percentage_value: 9.6,
      barber_products_percentage_value: 17,
      barber_products_value: 20.400000000000002,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 41588,
      createdAt: '2023-01-13T21:22:54.416Z',
      created_by_user_id: null,
      date: '2023-01-14T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '08:25:00',
      id: 179329,
      net_value: 150,
      past: true,
      payment_date: '2023-01-14T03:00:00.000Z',
      payment_discounted_from_barber: null,
      payment_method: 'cash',
      payment_method_choosed: [Object],
      payment_method_id: 179,
      payment_percentage: 0,
      payment_percentage_value: 0,
      product: [Object],
      product_id: 5720,
      product_price: 30,
      promotional: false,
      simple_date: '2023-01-14',
      source: 'barbershop_mobile_app_wpp',
      start_date: null,
      start_hour: '08:00:00',
      status: 'finished',
      storage: null,
      taxes: [Array],
      total_after_discounts: 120,
      total_value: 150,
      total_with_barber_percentage: 120,
      total_with_payment_percentage: 150,
      type: 'normal',
      updatedAt: '2023-02-09T20:37:58.816Z',
    },
    {
      additional_product: [Array],
      additional_products: null,
      additional_products_total_price: null,
      barber: [Object],
      barber_id: 684,
      barber_percentage: null,
      barber_percentage_value: null,
      barber_products_percentage_value: null,
      barber_products_value: null,
      barbershop_id: 1,
      cancelable: false,
      canceled_at: null,
      client: [Object],
      client_id: 4578,
      createdAt: '2023-01-24T12:45:25.997Z',
      created_by_user_id: null,
      date: '2023-01-25T03:00:00.000Z',
      discount: 0,
      discount_observation: null,
      end_date: null,
      finish_hour: '09:00:00',
      id: 183312,
      net_value: null,
      past: true,
      payment_date: null,
      payment_discounted_from_barber: null,
      payment_method: null,
      payment_method_choosed: null,
      payment_method_id: null,
      payment_percentage: null,
      payment_percentage_value: null,
      product: [Object],
      product_id: 12887,
      product_price: null,
      promotional: false,
      simple_date: '2023-01-25',
      source: 'client_mobile_app',
      start_date: null,
      start_hour: '08:10:00',
      status: 'pending',
      storage: null,
      taxes: null,
      total_after_discounts: null,
      total_value: 100,
      total_with_barber_percentage: null,
      total_with_payment_percentage: null,
      type: 'normal',
      updatedAt: '2023-01-25T13:08:06.963Z',
    },
  ],
  appointments_total_products: 580.03,
  appointments_total_services: 2128.24,
  appointments_total_value: 2691.0400000000004,
  clients_info: [
    {
      client: {
        name: 'João Ferreira',
      },
      discount: 0,
      last_appointment: '2023-02-09T03:00:00.000Z',
      new: false,
      product_value: 140.01,
      quantity: 9,
      service_quantity: [Array],
      service_value: 680,
    },
    {
      client: {
        name: 'Lucas Fernandes',
      },
      discount: 0,
      last_appointment: '2023-02-09T03:00:00.000Z',
      new: false,
      product_value: 0,
      quantity: 5,
      service_quantity: [Array],
      service_value: 527,
    },
    {
      client: {
        name: 'Alberto Moreira',
      },
      discount: 0,
      last_appointment: '2023-02-04T03:00:00.000Z',
      new: false,
      product_value: 10,
      quantity: 2,
      service_quantity: [Array],
      service_value: 30,
    },
    {
      client: {
        name: 'Gabriel Silva',
      },
      discount: 0,
      last_appointment: '2023-02-04T03:00:00.000Z',
      new: true,
      product_value: 0,
      quantity: 1,
      service_quantity: [Array],
      service_value: 100,
    },
    {
      client: {
        name: 'André Rangel',
      },
      discount: 1,
      last_appointment: '2023-02-06T03:00:00.000Z',
      new: false,
      product_value: 130,
      quantity: 3,
      service_quantity: [Array],
      service_value: 201.24,
    },
    {
      client: {
        name: 'João Quintiliano',
      },
      discount: 16.23,
      last_appointment: '2023-01-17T03:00:00.000Z',
      new: false,
      product_value: 170.02,
      quantity: 8,
      service_quantity: [Array],
      service_value: 400,
    },
    {
      client: {
        name: 'Matheus Franco',
      },
      discount: 0,
      last_appointment: '2023-01-19T03:00:00.000Z',
      new: false,
      product_value: 0,
      quantity: 1,
      service_quantity: [Array],
      service_value: 10,
    },
    {
      client: {
        name: 'Silvio Santos',
      },
      discount: 0,
      last_appointment: '2023-01-13T03:00:00.000Z',
      new: true,
      product_value: 0,
      quantity: 1,
      service_quantity: [Array],
      service_value: 35,
    },
    {
      client: {
        name: 'Joeliton Carlos',
      },
      discount: 0,
      last_appointment: '2023-01-16T03:00:00.000Z',
      new: true,
      product_value: 10,
      quantity: 1,
      service_quantity: [Array],
      service_value: 85,
    },
    {
      client: {
        name: 'Givanildo Souza',
      },
      discount: 0,
      last_appointment: '2023-01-28T03:00:00.000Z',
      new: true,
      product_value: 0,
      quantity: 1,
      service_quantity: [Array],
      service_value: 30,
    },
    {
      client: {
        name: 'Victor Silva',
      },
      discount: 0,
      last_appointment: '2023-01-14T03:00:00.000Z',
      new: true,
      product_value: 120,
      quantity: 1,
      service_quantity: [Array],
      service_value: 30,
    },
  ],
  new_clients: 5,
  total_appointments: 33,
  total_awaiting: 4,
  total_clients: 11,
  total_finished: 15,
  total_pending: 14,
}
