import React from 'react'
import { Center, Flex, Spinner, useTheme } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Text } from 'components/atoms'

const defaultValues = {
  emptyListMessage: 'EMPTY_LIST_MESSAGE',
  emptyValueMessage: '',
  data: [],
}

export const MobileList = ({
  data,
  loading,
  emptyListMessage = defaultValues.emptyListMessage,
  renderItem,
  onSelect = () => {},
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      width="100%"
      h="fit-content"
      mb={theme.pxToRem(24)}
    >
      {!!data?.length &&
        data.map((item, index) => renderItem({ item, index, onSelect }))}

      {(!data || !data.length) && !loading && emptyListMessage !== 'none' && (
        <Center minH={theme.pxToRem(120)} width="100%">
          <Text color="textLight">{t(emptyListMessage)}</Text>
        </Center>
      )}
      {loading && (
        <Center minH={theme.pxToRem(120)} w="100%">
          <Spinner color="white" />
        </Center>
      )}
    </Flex>
  )
}
