import * as React from 'react'

function SvgGears(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#gears_svg__clip0)" fill="currentColor">
        <path d="M23.625 10.497c-.193-.121-1.706-1.028-2.308-1.303l-.745-1.8c.221-.595.644-2.264.71-2.554a.8.8 0 00-.214-.743L19.902 2.93a.8.8 0 00-.744-.214c-.219.05-1.932.48-2.553.71l-1.8-.745c-.263-.576-1.143-2.053-1.303-2.308a.8.8 0 00-.678-.375h-1.648a.8.8 0 00-.678.375C10.377.567 9.47 2.08 9.195 2.682l-1.8.746c-.595-.222-2.264-.645-2.554-.711a.8.8 0 00-.743.214L2.932 4.097a.8.8 0 00-.214.744c.05.219.48 1.932.71 2.553l-.745 1.8C2.107 9.457.63 10.337.375 10.497a.8.8 0 00-.375.678v1.648a.8.8 0 00.375.678c.193.121 1.706 1.028 2.308 1.303l.745 1.8c-.221.595-.644 2.264-.71 2.553a.8.8 0 00.214.744l1.166 1.166a.8.8 0 00.743.214c.22-.05 1.933-.48 2.554-.71l1.8.745c.263.576 1.143 2.053 1.303 2.308a.8.8 0 00.678.375h1.648a.8.8 0 00.678-.375c.121-.193 1.028-1.706 1.303-2.308l1.8-.745c.595.221 2.264.644 2.553.71a.8.8 0 00.744-.214l1.166-1.166a.8.8 0 00.214-.744c-.05-.219-.48-1.932-.71-2.553l.745-1.8c.576-.263 2.053-1.143 2.308-1.303a.8.8 0 00.375-.678v-1.648a.8.8 0 00-.375-.678zM22.4 12.372c-.57.339-1.518.885-1.816 1.008a.8.8 0 00-.61.483l-1.017 2.457a.8.8 0 00.09.772c.123.298.407 1.355.57 1.997l-.527.528c-.642-.164-1.699-.448-1.997-.572a.8.8 0 00-.772-.089l-2.457 1.018a.8.8 0 00-.483.61c-.123.297-.669 1.245-1.008 1.815h-.746c-.34-.57-.885-1.518-1.008-1.816a.8.8 0 00-.483-.61l-2.457-1.017a.8.8 0 00-.772.09c-.298.123-1.355.407-1.997.571l-.528-.528c.163-.642.448-1.699.571-1.997a.8.8 0 00.09-.772l-1.018-2.457a.8.8 0 00-.61-.483c-.297-.123-1.245-.669-1.815-1.008v-.746c.57-.34 1.518-.885 1.816-1.008a.8.8 0 00.61-.483l1.017-2.457a.8.8 0 00-.09-.772c-.123-.298-.407-1.355-.571-1.997l.528-.528c.642.163 1.699.448 1.997.571a.8.8 0 00.772.09l2.457-1.018a.8.8 0 00.483-.61c.123-.297.669-1.245 1.008-1.815h.746c.339.57.885 1.518 1.008 1.816a.8.8 0 00.483.61l2.457 1.017a.8.8 0 00.772-.09c.298-.123 1.355-.407 1.997-.57l.528.527c-.163.642-.448 1.699-.572 1.997a.8.8 0 00-.089.772l1.018 2.457a.8.8 0 00.61.483c.297.123 1.245.669 1.815 1.008v.746z" />
        <path d="M12.001 6.768A5.237 5.237 0 006.77 12 5.237 5.237 0 0012 17.232a5.238 5.238 0 005.232-5.232A5.237 5.237 0 0012 6.77zm0 8.863a3.636 3.636 0 01-3.631-3.632A3.636 3.636 0 0112 8.37a3.635 3.635 0 013.631 3.63 3.636 3.636 0 01-3.631 3.632z" />
      </g>
      <defs>
        <clipPath id="gears_svg__clip0">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgGears
