import React from 'react'
import Chart from 'react-apexcharts'
import { useTheme } from '@chakra-ui/react'
import { Text, Flex } from 'components/atoms'
import { t } from 'services/translation'
import { colors } from 'styles'

export const PieChart = (props) => {
  const theme = useTheme()
  const pieChartOptions = {
    labels: !props?.data?.label ? [] : props?.data?.label,
    chart: {
      width: '50px',
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    legend: {
      show: props?.legend || false,
      position: props?.legend?.position || 'bottom',
    },
    dataLabels: {
      enabled: props?.dataLabels || false,
    },
    colors: colors.pieChart,
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
    },
  }

  const pieChartData = !props?.data?.data ? [] : props?.data?.data
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      p={theme.pxToRem(16)}
      w="100%"
      bg="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Text kind="extraBold">{t(props?.title)}</Text>
      <Chart
        options={pieChartOptions}
        series={pieChartData}
        type="pie"
        width={props?.w}
        height={props?.h}
      />
      {props?.total && (
        <Text mt={theme.pxToRem(-12)} kind="extraBold">
          Total: {props?.total}
        </Text>
      )}
    </Flex>
  )
}
