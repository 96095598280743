import React from 'react'
import { Text, Button, Box } from 'components/atoms'
import { ModalMenu, Table } from 'components/molecules'
import { useTheme } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { t } from 'services/translation'
import { usePlans } from 'services/hooks/use-plans'
import { useBarbershopStore } from 'services/stores'
import { formatPrice } from 'utils'

const columns = [
  { key: 'name', label: 'NAME_TABLE' },
  {
    key: 'value',
    label: 'VALUE',
    render: (item) => <Text>{formatPrice(item?.value)}</Text>,
  },
]

export const SelectPlan = ({ isOpen, onClose, plan, setPlan, ...props }) => {
  const {getPlans} = usePlans()
  const theme = useTheme()
  const { userData } = useBarbershopStore()

  const queryPlans = useQuery(
    ['get-plans'],
   () => getPlans({id: userData?.id}),
  )

  const handleSelectService = (pm) => {
      setPlan(pm)
      onClose()
  }

  return (
    <ModalMenu 
    isOpen={isOpen}
      onClose={
        onClose
      }
      title={t('SELECT_SERVICE')}>
      <Box>
        <Table
          withoutHeader
          isSelectableRow
          data={queryPlans.data?.plans}
          loading={queryPlans.isLoading}
          columns={columns}
          onSelect={handleSelectService}
        />
        <Button size="block" mt={theme.pxToRem(24)} onClick={onClose}>
          {t('CONFIRM')}
        </Button>
      </Box>
    </ModalMenu>
  )
}
