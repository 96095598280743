import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api/config'

const mutationFn = async ({ appointmentId, body }) => {
  const { data } = await api.put(`v3/appointment/discount/${appointmentId}`, {
    ...body,
  })

  return data
}

export const useAddAppointmentDiscount = ({ appointmentId, onSuccess }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: body => mutationFn({ appointmentId, body }),
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(['get-appointment-details', appointmentId])
    },
  })
}
