import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/react'
import { Box, Button, Flex } from 'components/atoms'
import { ModalMenu, Form } from 'components/molecules'
import { Text } from 'components/atoms/Text'
import { theme } from 'styles'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { useHistory } from 'react-router-dom'
import { dataBarber, initialBarber, validationBarber, select, booelanOptions } from './settings'
import { Select } from '@chakra-ui/react'
import { colors } from 'styles'

import { api } from 'services/api/config'
import { useMutation, useQueryClient } from 'react-query'
import { EMPLOYEE_PERMISSION, USER_DATA } from 'services/api/endpoints'
import { useLazyFetch } from 'services/hooks'
import { formatPhone } from 'utils/phone-formater'
import { render, options } from './settings'
import { useQuery } from 'react-query'
import { defaultBarberPermissions } from 'utils'

export const PermissionModal = ({
  isModalOpen,
  onClose,
  isRegister,
  onCompleted,
  barbersList,
  isBlocked,
  isEdit = false,
  collaboratorData = {},
}) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const [value, setValue] = useState()
  const history = useHistory()
  const queryClient = useQueryClient()

  const render = [
    {
      id: 0,
      label: 'FINANCE',
      value: 'finance',
      setValue: (val) => setValue({ ...value, finance: val }),
    },
    {
      id: 1,
      label: 'SINGLE_PRODUCT_PERMISSION',
      value: 'single_product',
      setValue: (val) => setValue({ ...value, single_product: val }),
    },
    {
      id: 2,
      label: 'Criar agendamento',
      value: 'create_appointment',
      setValue: (val) => setValue({ ...value, create_appointment: val }),
    },
    {
      id: 2.2,
      label: 'Cancelar agendamento',
      value: 'cancel_appointment',
      setValue: (val) => setValue({ ...value, cancel_appointment: val }),
    },
    {
      id: 2.3,
      label: 'Finalizar agendamento',
      value: 'finish_appointment',
      setValue: (val) => setValue({ ...value, finish_appointment: val }),
    },
    {
      id: 2.1,
      label: 'Adicionar serviços na comanda',
      value: 'appointment_services',
      setValue: (val) => setValue({ ...value, appointment_services: val }),
    },
    {
      id: 2.4,
      label: 'Adicionar produtos na comanda',
      value: 'appointment_products',
      setValue: (val) => setValue({ ...value, appointment_products: val }),
    },
    {
      id: 2.5,
      label: 'Adicionar desconto na comanda',
      value: 'appointment_discount',
      setValue: (val) => setValue({ ...value, appointment_discount: val }),
    },
    {
      id: 2.5,
      label: 'Adicionar fonte do agendamento',
      value: 'appointment_source',
      setValue: (val) => setValue({ ...value, appointment_source: val }),
    },
    {
      id: 3,
      label: 'CLIENTS_PERMISSION',
      value: 'clients',
      setValue: (val) => setValue({ ...value, clients: val }),
    },
    {
      id: 4,
      label: 'HISTORIC_PERMISSION',
      value: 'historic',
      setValue: (val) => setValue({ ...value, historic: val }),
    },
    {
      id: 5,
      label: 'SERVICES_PERMISSION',
      value: 'services',
      setValue: (val) => setValue({ ...value, services: val }),
    },
    {
      id: 6,
      label: 'BLOCKED_HOURS_PERMISSION',
      value: 'blocked_hours',
      setValue: (val) => setValue({ ...value, blocked_hours: val }),
    },
    {
      id: 7,
      label: 'TEAM_PERMISSION',
      value: 'team',
      setValue: (val) => setValue({ ...value, team: val }),
    },
    {
      id: 8,
      label: 'ADDITIONAL_PRODUCTS_PERMISSION',
      value: 'additional_products',
      setValue: (val) => setValue({ ...value, additional_products: val }),
    },
    {
      id: 9,
      label: 'Gestão das assinaturas',
      value: 'signature',
      setValue: (val) => setValue({ ...value, signature: val }),
    },
    {
      id: 10,
      label: 'PAYMENT_METHODS_PERMISSION',
      value: 'payment_methods',
      setValue: (val) => setValue({ ...value, payment_methods: val }),
    },
    {
      id: 11,
      label: 'NOTIFICATIONS_PERMISSION',
      value: 'notifications',
      setValue: (val) => setValue({ ...value, notifications: val }),
    },
    {
      id: 12,
      label: 'CASHIER_PERMISSION',
      value: 'cashier',
      setValue: (val) => setValue({ ...value, cashier: val }),
    },
    {
      id: 13,
      label: 'REOPEN_APPOINTMENT_PERMISSION',
      value: 'reopen',
      setValue: (val) => setValue({ ...value, reopen: val }),
    },
    {
      id: 14,
      label: 'Configurações',
      value: 'configurations',
      setValue: (val) => setValue({ ...value, configurations: val }),
    },
    {
      id: 15,
      label: 'Unidades',
      value: 'unitys',
      setValue: (val) => setValue({ ...value, unitys: val }),
    },
    {
      id: 16,
      label: 'Notas fiscais',
      value: 'nota_fiscal',
      setValue: (val) => setValue({ ...value, nota_fiscal: val }),
    },
    {
      id: 16,
      label: 'Visualizar todos os barbeiros na agenda',
      value: 'visibile_all_barbers',
      type: 'boolean',
      setValue: (val) => setValue({ ...value, visibile_all_barbers: val }),
    },
    {
      id: 17,
      label: 'Saldo e saque',
      value: 'balance_withdraw',
      type: 'boolean',
      setValue: (val) => setValue({ ...value, balance_withdraw: val }),
    },
  ]

  async function getEmployeePermission() {
    const res = await api
      .get(
        EMPLOYEE_PERMISSION(collaboratorData?.id, collaboratorData?.user?.type === 'barber' ? 'barber' : 'collaborator')
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading } = useQuery('get-employee-permission', getEmployeePermission, {
    onSuccess: (data) => {
      setValue({ ...defaultBarberPermissions, ...data?.permissions })
    },
  })

  async function changeEmployeePermission() {
    const params = {
      ...value,
    }
    const res = await api
      .put(
        EMPLOYEE_PERMISSION(
          collaboratorData?.id,
          collaboratorData?.user?.type === 'barber' ? 'barber' : 'collaborator'
        ),
        params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('change-employe-permission', (params) => changeEmployeePermission(params), {
    onSuccess: async (value) => {
      toast({
        title: t('PERMISSION_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClose()
    },
    onError: (error) => {
      toast({
        title: t('PERMISSION_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <ModalMenu isOpen={isModalOpen} onClose={onClose} title={t('EDIT_PERMISSION_TITLE')}>
      <Box marginBottom={pxToRem(200)}>
        {render.map((item) => (
          <Flex mb={theme.pxToRem(12)} flexDir="column">
            <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)}>
              {t(item?.label)}
            </Text>
            <Flex
              bg="backgroundLayout"
              flexDir="column"
              h={theme.pxToRem(56)}
              align="center"
              justify="center"
              px={theme.pxToRem(16)}
              borderRadius={theme.pxToRem(8)}
              borderWidth={1}
              borderColor="border"
            >
              <Select
                name="service"
                value={value?.[item?.value]}
                w="full"
                fontFamily="Montserrat Bold"
                fontSize={14}
                textColor="textLight"
                iconColor="primary"
                borderColor="transparent"
                errorBorderColor="baseRed"
                focusBorderColor="transparent"
                variant="unstyled"
                marginBottom={theme.pxToRem(2)}
                onChange={(e) => item?.setValue(e.target.value)}
              >
                {(item?.type === 'boolean' ? booelanOptions : options).map((item, index) => (
                  <option
                    style={{
                      backgroundColor: colors.menuBackground,
                      fontFamily: 'Montserrat Medium',
                    }}
                    key={`service_${item?.id}`}
                    value={item?.value}
                  >
                    {item?.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
        ))}
      </Box>
      <Button size="block" mt={theme.pxToRem(-200)} onClick={() => mutate.mutate()} isLoading={mutate.isLoading}>
        {t('SAVE')}
      </Button>
    </ModalMenu>
  )
}

PermissionModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}
