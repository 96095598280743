import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Flex, useTheme, useToast } from '@chakra-ui/react'
import { TextInput, Logo, Text, Button } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { LOGIN_SCREEN_STEPS as SCREEN_STEPS } from 'constants/login'

import { api } from 'services/api/config'
import { RESET_PASSWORD } from 'services/api/endpoints'
import { useMutation } from 'react-query'

export const LoginChangePassword = ({ values: stateValues, ...props }) => {
  const { user, email } = stateValues
  const theme = useTheme()
  const toast = useToast()
  const { isMobile } = useBreakpoint()
  const { t } = useTranslation()

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      // token: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      // token: Yup.string()
      //   .length(8, t('INVALID_TOKEN'))
      //   .required(t('TOKEN_REQUIRED')),
      password: Yup.string()
        .min(6, t('PASSWORD'))
        .required(t('PASSWORD_REQUIRED')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], t('CONFIRM_PASSWORD'))
        .min(6, t('PASSWORD'))
        .required(t('PASSWORD_REQUIRED')),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      const params = {
        userId: user?.id,
        // token: values.token,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      }
      mutate.mutate(params)
    },
  })

  async function resetPassword(params) {
    const res = await api
      .put(RESET_PASSWORD, params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'reset-password',
    (params) => resetPassword(params),
    {
      onSuccess: (data) => {
        props?.changeSelectedStep(SCREEN_STEPS.LOGIN)
        toast({
          title: t('PASSWORD_CHANGED'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: t('PASSWORD_NOT_CHANGED'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <Flex
      align={isMobile ? 'center' : 'flex-start'}
      justifyContent="center"
      flexDir="column"
      w={isMobile ? '100%' : theme.pxToRem(520)}
      py={theme.pxToRem(48)}
      px={theme.pxToRem(isMobile ? 24 : 48)}
      borderRadius={theme.pxToRem(isMobile ? 0 : 24)}
    >
      <Logo />
      <Text
        kind="extraBold"
        textAlign={isMobile ? 'center' : null}
        fontSize={theme.pxToRem(24)}
        mt={theme.pxToRem(24)}
        mb={theme.pxToRem(24)}
      >
        {t('NEW_PASSWORD')}
      </Text>
      <Text kind="semiBold">
        {/* {t('ENTER_TOKEN_FIRST')} */}
        {/* {email} */}
        {t('ENTER_TOKEN')}
      </Text>
      {/* <TextInput
        noBorder
        type="string"
        name="token"
        label="Token"
        disabled={mutate.isLoading}
        error={errors.token}
        value={values.token}
        onChange={handleChange}
        placeholder="Token"
        mt={theme.pxToRem(12)}
        mb={theme.pxToRem(24)}
      /> */}

      <TextInput
        noBorder
        type="password"
        name="password"
        label={t('PASSWORD_PLACEHOLDER')}
        placeholder={t('PASSWORD_PLACEHOLDER')}
        error={errors.password}
        value={values.password}
        onChange={handleChange}
        disabled={mutate.isLoading}
        mt={theme.pxToRem(12)}
        mb={theme.pxToRem(12)}
      />

      <TextInput
        noBorder
        type="password"
        name="confirmPassword"
        label={t('CONFIRM_PASSWORD_PLACEHOLDER')}
        placeholder={t('CONFIRM_PASSWORD_PLACEHOLDER')}
        error={errors.confirmPassword}
        value={values.confirmPassword}
        onChange={handleChange}
        disabled={mutate.isLoading}
        mt={theme.pxToRem(24)}
      />

      <Button
        size="block"
        mt={theme.pxToRem(48)}
        isLoading={mutate.isLoading}
        onClick={handleSubmit}
      >
        {t('CHANGE_PASSWORD_BUTTON')}
      </Button>
    </Flex>
  )
}
