import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores/barbershop'
import { Box, Button, Icon, Image, Link, Text, TextInput } from 'components/atoms'
import { useTheme, useToast, Flex } from '@chakra-ui/react'
import { colors } from 'styles/colors'
import { DEVICE_ID, LOGIN_SCREEN_STEPS as SCREEN_STEPS } from 'constants/login'
import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { api } from 'services/api/config'
import { LOGIN } from 'services/api/endpoints'
import { useMutation } from 'react-query'
import { SelectUnityModal } from '..'
import { defaultPermissions } from 'utils'
import { SubscriptionModal } from '..'
import { saveLoginData, useBreakpoint, useBarbershopSubscription, login } from 'services/hooks'

export const LoginForm = (props) => {
  const history = useHistory()
  const theme = useTheme()
  const toast = useToast()
  const { setData, setUser } = useBarbershopStore()
  const [open, setOpen] = useState(false)
  const [unitys, setUnitys] = useState()
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)
  const { getIuguNewInvoice } = useBarbershopSubscription()
  const [barbershopUserData, setBarbershopUserData] = useState()

  const { t } = useTranslation()

  const { isMobile } = useBreakpoint()

  const { values, handleSubmit, handleChange, errors, setFieldValue } = useFormik({
    initialValues: {
      type: 'e-mail',
      user: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      user: Yup.string().min(8, t('INVALID_USERNAME')).required(t('USERNAME_REQUIRED')),
      password: Yup.string().min(6, t('PASSWORD')).required(t('PASSWORD_REQUIRED')),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      const params = {
        password: values.password,
        user: values.user,
        device_id: DEVICE_ID,
      }
      mutate.mutate(params)
    },
  })

  const onChangeUser = (text) => {
    const unmasked = text.replace(/[^a-zA-Z0-9@._]/g, '')
    if (!isNaN(parseFloat(unmasked)) && isFinite(unmasked)) {
      setFieldValue('type', 'phone')
      setFieldValue('user', text)
      return
    }
    setFieldValue('type', 'e-mail')
    let unmaskedEmail = text.replace(/[^a-zA-Z0-9@._-]/g, '')
    if (values.type === 'phone') {
      unmaskedEmail = text.replace(/[^a-zA-Z0-9@._]/g, '')
    }
    setFieldValue('user', unmaskedEmail)
  }

  // async function login(params) {
  //   const res = await api
  //     .post(LOGIN, params)
  //     .then((response) => {
  //       return response.data
  //     })
  //     .catch((error) => {
  //       throw Error(error.response.data.error)
  //     })
  //   return res
  // }

  const mutate = useMutation('login', (params) => login(params), {
    onSuccess: (data) => {
      if (data?.user?.type === 'client' || data?.user?.type === 'barber') {
        toast({
          title: t('USER_NOT_AUTH'),
          description: t('USER_NOT_AUTH_DESC'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return
      }
      const toSaveUserData =
        data?.userData?.barbershops?.[0] || data?.userData?.barbershop || data?.userData?.[0] || data?.userData
      const positive_access = ['LEAD', 'TRIAL', 'ONBOARDING', 'SUBSCRIBED', 'SUBSCRIBED_PARTNER']
      if (!positive_access.includes(toSaveUserData?.account_status)) {
        setOpenSubscriptionModal(true)
        setBarbershopUserData(toSaveUserData)
        return
      }

      if (data?.user?.type === 'owner' || data?.user?.type === 'ownerAndBarber') {
        setUser(data?.user)
        setUnitys(data)
        setOpen(true)
        return
      }

      const isBarbershop =
        data?.user?.type === 'owner' ||
        data?.user?.type === 'ownerAndBarber' ||
        data?.user?.type === 'barbershop' ||
        data?.user?.type === 'barbershopAndBarber'

      const permissions = isBarbershop ? defaultPermissions : data?.userData?.permissions

      toSaveUserData.permissions = permissions
      if (!isBarbershop) {
        toSaveUserData.employee_name = data?.userData?.name
        toSaveUserData.employee_image = data?.userData?.profile_image_url
      }

      saveLoginData({
        user: data?.user,
        userData: toSaveUserData,
        token: data?.token,
        new_token: data?.new_token,
      })
      setData(data?.user, toSaveUserData, data?.subscription, data?.customer)
      if (props?.redirectTo) {
        history.push(props?.redirectTo)
        return
      }
      if (!toSaveUserData.status || toSaveUserData.status === 'not_published') {
        history.push('/cadastro/localizacao')
        return
      }

      history.push('/')
    },
    onError: (error) => {
      toast({
        title: t('LOGIN_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const openLink = (url) => {
    var win = window.open(url, '_blank')
    win.focus()
  }

  const mutation_invoice = useMutation('get-iugu-new-invoice-login', () =>
    getIuguNewInvoice({
      iugu_subscription_id: barbershopUserData?.iugu_subscription_id,
      onSuccess: (data) => {
        openLink(data?.invoice)
      },
    })
  )

  return (
    <Flex alignItems="center" flexDir="column" w="100%" py={theme.pxToRem(48)} px={theme.pxToRem(isMobile ? 24 : 48)}>
      <Image src={bestBarbersLogo} alt="BestBarbers" width={theme.pxToRem(160)} objectFit="contain" />

      <Text kind="extraBold" fontSize={theme.pxToRem(24)} mt={theme.pxToRem(40)}>
        {t('ACCESS_ACCOUNT')}
      </Text>

      <Text color="textGrey" textAlign={isMobile ? 'center' : 'left'} fontSize={theme.pxToRem(14)}>
        {t('MANAGE_YOUR_BARBERSHOP')}
      </Text>

      <TextInput
        type="inputMask"
        mask={values.type === 'phone' ? '** *****-****' : null}
        alwaysShowMask={false}
        maskChar={null}
        noBorder
        name="email"
        mt={theme.pxToRem(40)}
        mb={theme.pxToRem(errors.user ? 16 : 0)}
        backgroundColor="backgroundLayout"
        placeholder={t('EMAIL_PLACEHOLDER')}
        label={t('EMAIL_PLACEHOLDER')}
        error={errors.user}
        value={values.user}
        onChange={(e) => onChangeUser(e.target.value.toLowerCase())}
        paddingLeft={theme.pxToRem(50)}
        leftElement={
          <Box mr={theme.pxToRem(16)} color={errors.user ? colors.danger : colors.primary}>
            <Icon
              name="PersonBarber"
              color={errors.user ? colors.danger : colors.primary}
              ml={theme.pxToRem(20)}
              mr={theme.pxToRem(20)}
            />
          </Box>
        }
      />

      <TextInput
        noBorder
        type="password"
        name="password"
        backgroundColor="backgroundLayout"
        placeholder={t('PASSWORD_PLACEHOLDER')}
        label={t('PASSWORD_PLACEHOLDER')}
        error={errors.password}
        value={values.password}
        mt={theme.pxToRem(32)}
        onChange={handleChange}
        paddingLeft={theme.pxToRem(50)}
        leftElement={
          <Box mr={theme.pxToRem(16)} color={errors.password ? colors.danger : colors.primary}>
            <Icon
              name="Lock"
              color={errors.password ? colors.danger : colors.primary}
              ml={theme.pxToRem(20)}
              mr={theme.pxToRem(20)}
            />
          </Box>
        }
      />
      <Flex w="100%" flexDir="column" alignItems="center" justifyContent="center" mt={theme.pxToRem(52)}>
        <Button w="100%" onClick={handleSubmit} isLoading={mutate.isLoading}>
          <Text fontSize={theme.pxToRem(16)} color={colors.textDark} kind="bold">
            {t('LOGIN_BUTTON')}
          </Text>
        </Button>

        <Link mt={theme.pxToRem(24)} onClick={() => history.replace('/reset-password/check-phone')}>
          <Text kind="bold" fontSize={theme.pxToRem(14)} as="u" textDecoration="none" color={colors.primary}>
            {t('FORGOT_PASSWORD')}
          </Text>
        </Link>
      </Flex>

      <SelectUnityModal isOpen={open} onClose={() => setOpen(false)} data={unitys} />
      <SubscriptionModal
        isOpen={openSubscriptionModal}
        onClose={() => setOpenSubscriptionModal(false)}
        onClick={mutation_invoice.mutate}
        isLoading={mutation_invoice.isLoading}
      />
    </Flex>
  )
}
