import React, { useEffect, useState } from 'react'
import {
  Layout, TextInput,
} from 'components'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { t } from 'services/translation'
import { Flex } from '@chakra-ui/react'
import { AvarageTicket } from 'components-v2/organisms/AvarageTicket/AvarageTicket'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const AverageTicketScreen = (props) => {
const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
    const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))

    const {onLogout} = useLogoutPaymentIssues()

    useEffect(() => {
      onLogout()
    }, [])

  return (
    <Layout headerTitle='Ticket médio' headerCenterElement={() => <Flex mr='40' ml='-20'>
    <TextInput
                noBorder
                type="date"
                name="start_date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label={t('START')}
                padding='10px'
                mr='5'
              />
              <TextInput
                noBorder
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label={t('END')}
                padding='10px'
              />
              </Flex>}>
<AvarageTicket startDate={startDate} endDate={endDate} />
    </Layout>
  )
}

AverageTicketScreen.path = '/ticket-medio'
AverageTicketScreen.title = 'Ticket médio'
AverageTicketScreen.secured = true
