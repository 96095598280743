export const unmaskPhone = (phone) => validString(phone).replace(/[\(\)\.\s-]+/g, '')

export const formatPhone = (phone, userData) => {
  if (!phone?.length) {
    return ''
  }

  phone = unmaskPhone(validString(phone))

  let formatted = phone
  if (formatted?.length >= 12 && userData?.id === 10848) {
    formatted = formatted.substring(2)
  }
  if (phone?.length > 11 && userData?.id !== 10848) {
    phone = phone.substr(phone?.length - 11)
  }
  return userData?.id === 10848
    ? `(${formatted.substr(0, 3)}) ${formatted.substr(3, 3)} ${formatted.substr(6)}`
    : `(${phone.substr(0, 2)}) ${phone.substr(2, phone.length - 1 - 5)}-${phone.substring(7)}`
}

const validString = (str) => (!!str ? `${str}` : '')

const splitedString = (phone) => (validString(phone) > 11 ? validString(phone).substring(2) : validString(phone))
