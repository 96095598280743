import React, { useEffect, useState } from 'react'
import { useTheme, Flex } from '@chakra-ui/react'
import { Layout, Table, TextInput, Box, Text, Icon, SubscriptionDetailModal, Link, SelectFilter } from 'components'
import { Search } from 'assets/icons'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { GET_BARBERSHOP_SUBSCRIPTION } from 'services/api/endpoints'
import { api } from 'services/api/config'
import { formatPhone } from 'utils/phone-formater'
import { t } from 'services/translation'
import { serviceColumns, statuses } from './settings'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { usePlans } from 'services/hooks/use-plans'
import { CreatePlanModal } from 'components-v2/organisms/CreatePlanModal'
import { AddNewSubscriber } from 'components-v2/organisms/AddNewSubscriber'
import { differenceInDays, format, parseISO, subHours } from 'date-fns'
import * as XLSX from 'xlsx'

const mobileColumns = [
  { key: 'image', label: '', type: 'image', placeholder: 'client' },
  { key: 'name', label: 'NAME_TABLE' },
  { key: 'phone', label: 'PHONE_TABLE' },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => (
      <Text color={item?.status === 'ACTIVE' && !item?.inactive ? 'success' : 'danger'}>
        {item?.inactive ? 'Cancelado' : t(item?.status)}
      </Text>
    ),
  },
]

export const NewSubscribersScreen = ({ ...props }) => {
  const [modalDetailOpen, setModalDetailOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [subscribers, setSubscribers] = useState([])
  const [addSubscriber, setAddSubscriber] = useState(false)
  const [plan, setPlan] = useState(null)
  const [query, setQuery] = useState('')
  const [client, setClient] = useState()
  const [status, setStatus] = useState()
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const { onLogout } = useLogoutPaymentIssues()
  const { userData } = useBarbershopStore()

  useEffect(() => {
    onLogout()
  }, [])

  const desktopColumns = [
    {
      key: 'image',
      label: '',
      type: 'image',
      placeholder: 'client',
      render: (item) => item?.client?.profile_image_url,
    },
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => <Text>{item?.client?.name}</Text>,
    },
    {
      key: 'email',
      label: 'EMAIL_TABLE',
      render: (item) => <Text>{item?.client?.user?.email}</Text>,
    },
    {
      key: 'phone',
      label: 'PHONE_TABLE',
      render: (item) => (
        <Link href={`https://wa.me/${item?.client?.user?.phone}`}>
          {formatPhone(item?.client?.user?.phone, userData)}
        </Link>
      ),
    },
    {
      key: 'createdAt',
      label: 'DATA DA 1ª MENSALIDADE',
      render: (item) => <Text>{format(subHours(parseISO(item?.createdAt), 3), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'due_simple_date',
      label: 'Data venc.',
      render: (item) => <Text>{format(parseISO(item?.due_simple_date ?? item?.due_date), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'status',
      label: 'STATUS',
      render: (item) => {
        const due_date = parseISO(item?.due_simple_date)
        const diff = differenceInDays(due_date, new Date())
        return (
          <Text color={diff < 0 || item?.inactive || item?.status === 'INACTIVE' ? 'danger' : 'success'}>
            {item?.inactive ? 'Cancelado' : diff < 0 ? 'Inativo' : t(item?.status)}
          </Text>
        )
      },
    },
  ]
  async function getManagerSubscription({ queryKey }) {
    const res = await api
      .get(GET_BARBERSHOP_SUBSCRIPTION(userData?.id, queryKey[1]?.name))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading, refetch } = useQuery(['get-subscribers', status], getManagerSubscription, {
    onSuccess: (value) => {
      setSubscribers({ data: value, filtered: value })
    },
  })

  const onCloseModal = () => {
    refetch()
    setClient()
    setModalDetailOpen(false)
  }

  const emptyList = () => (
    <Flex align="center" flexDir="column" mt={theme.pxToRem(24)}>
      <Icon name="Scissors" size={24} mb={theme.pxToRem(12)} />
      <Text>{t('NO_SUBSCRIBERS')}</Text>
    </Flex>
  )

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setSubscribers({ ...subscribers, filtered: subscribers?.data })
    } else {
      const search_result = subscribers?.data.filter(
        (item) =>
          String(item?.client?.name.toLowerCase()).includes(query.toLowerCase()) ||
          String(item?.client?.user?.email?.toLowerCase()).includes(query.toLowerCase())
      )
      setSubscribers({ ...subscribers, filtered: search_result })
    }
  }

  const handleSelectCustomer = (client) => {
    setClient(client)
    setModalDetailOpen(true)
  }

  const handleExport = () => {
    const tableExport = subscribers?.filtered?.map((item) => {
      const due_date = parseISO(item?.due_simple_date)
      const diff = differenceInDays(due_date, new Date())

      return {
        Nome: item?.client?.name,
        Email: item?.client?.user?.email,
        Telefone: item?.client?.user?.phone,
        'Data de vencimento': format(parseISO(item?.due_simple_date ?? item?.due_date), 'dd/MM/yyyy'),
        'Data da primeira mensalidade': format(subHours(parseISO(item?.createdAt), 3), 'dd/MM/yyyy'),
        Status: item?.inactive ? 'Cancelado' : diff < 0 ? 'Inativo' : t(item?.status),
      }
    })

    const worksheet = XLSX.utils.json_to_sheet(tableExport)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tabela')

    XLSX.writeFile(workbook, 'assinantes.xlsx')
  }

  return (
    <Layout
      {...props}
      headerTitle="Clube de assinatura"
      modalFilter
      modalFilterWidth="12%"
      secondActionClick={() => setAddSubscriber(true)}
      secondActionText="Cadastrar novo assinante"
      thirtyClick={{
        icon: 'Excel',
        iconColor: 'black',
        onClick: handleExport,
        text: 'Exportar em excel',
      }}
      // secondActionClick={handleExport}
      // secondActionText="Exportar em excel"
      // iconSecondActionText={'Excel'}
    >
      <Box flex={1} mt="12px">
        <TextInput
          noBorder
          type="text"
          width="full"
          color="white"
          placeholder={t('SEARCH_SUBSCRIBERS')}
          value={query}
          mb={!isDesktop && theme.pxToRem(16)}
          onChange={(event) => onSearch(event.target.value)}
          leftElement={<Search color={colors.primary} />}
        />
        <Flex w="10%" mt="16px">
          <SelectFilter
            onChange={(e) => {
              setStatus(statuses?.find((i) => i?.value === e))
            }}
            value={status?.value}
            options={statuses}
            label={'Status'}
            placeholder="Ativos/Inativos"
            selW="150px"
            fullWidth
          />
        </Flex>

        {subscribers?.filtered?.length === 0 && emptyList()}
        {subscribers?.filtered?.length !== 0 && (
          <Table
            isSelectableRow={isDesktop}
            data={subscribers?.filtered || []}
            loading={isLoading}
            columns={isDesktop ? desktopColumns : mobileColumns}
            onSelect={handleSelectCustomer}
          />
        )}
      </Box>

      <CreatePlanModal
        isOpen={planModalOpen}
        onClose={() => {
          setPlan(null)
          setPlanModalOpen(false)
        }}
        plan={plan}
      />
      <AddNewSubscriber isOpen={addSubscriber} onClose={() => setAddSubscriber(false)} />

      <SubscriptionDetailModal
        isOpen={modalDetailOpen}
        onClose={onCloseModal}
        barbershop_id={client?.barbershop_id}
        signature_id={client?.id}
        client_id={client?.client_id}
      />
    </Layout>
  )
}

NewSubscribersScreen.path = '/assinantes-novo'
NewSubscribersScreen.title = 'Assinantes'
NewSubscribersScreen.secured = true
