import React from "react"


function SvgComputer(props) { 
return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_2524_17320)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 3.6373C6.72017 3.6373 6.69155 3.64916 6.67045 3.67026C6.64936 3.69135 6.6375 3.71997 6.6375 3.7498C6.6375 4.3167 6.4123 4.86039 6.01144 5.26125C5.61058 5.6621 5.0669 5.8873 4.5 5.8873H3.75C3.52125 5.8873 3.30187 5.97817 3.14012 6.13993C2.97837 6.30168 2.8875 6.52106 2.8875 6.7498V13.4998C2.8875 13.7286 2.97837 13.9479 3.14012 14.1097C3.30187 14.2714 3.52125 14.3623 3.75 14.3623H14.25C14.4788 14.3623 14.6981 14.2714 14.8599 14.1097C15.0216 13.9479 15.1125 13.7286 15.1125 13.4998V6.7498C15.1125 6.52106 15.0216 6.30168 14.8599 6.13993C14.6981 5.97817 14.4788 5.8873 14.25 5.8873H13.5C12.9331 5.8873 12.3894 5.6621 11.9886 5.26125C11.5877 4.86039 11.3625 4.31671 11.3625 3.7498C11.3625 3.71997 11.3507 3.69135 11.3296 3.67026C11.3085 3.64916 11.2798 3.6373 11.25 3.6373H6.75ZM3.75 4.6123C3.1831 4.6123 2.63942 4.8375 2.23856 5.23836C1.8377 5.63922 1.6125 6.1829 1.6125 6.7498V13.4998C1.6125 14.0667 1.8377 14.6104 2.23856 15.0112C2.63942 15.4121 3.1831 15.6373 3.75 15.6373H14.25C14.8169 15.6373 15.3606 15.4121 15.7614 15.0112C16.1623 14.6104 16.3875 14.0667 16.3875 13.4998V6.7498C16.3875 6.1829 16.1623 5.63922 15.7614 5.23836C15.3606 4.8375 14.8169 4.6123 14.25 4.6123H13.5C13.2713 4.6123 13.0519 4.52143 12.8901 4.35968C12.7284 4.19793 12.6375 3.97855 12.6375 3.7498C12.6375 3.38182 12.4913 3.0289 12.2311 2.76869C11.9709 2.50849 11.618 2.3623 11.25 2.3623H6.75C6.38201 2.3623 6.0291 2.50849 5.76889 2.76869C5.50869 3.0289 5.3625 3.38182 5.3625 3.7498C5.3625 3.97855 5.27163 4.19793 5.10988 4.35968C4.94813 4.52143 4.72875 4.6123 4.5 4.6123H3.75Z" fill="#111111"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 8.1373C8.10944 8.1373 7.3875 8.85925 7.3875 9.7498C7.3875 10.6404 8.10944 11.3623 9 11.3623C9.89056 11.3623 10.6125 10.6404 10.6125 9.7498C10.6125 8.85925 9.89056 8.1373 9 8.1373ZM6.1125 9.7498C6.1125 8.15508 7.40528 6.8623 9 6.8623C10.5947 6.8623 11.8875 8.15508 11.8875 9.7498C11.8875 11.3445 10.5947 12.6373 9 12.6373C7.40528 12.6373 6.1125 11.3445 6.1125 9.7498Z" fill="#111111"/>
    </g>
    <defs>
    <clipPath id="clip0_2524_17320">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
    </svg>
)

}

export default SvgComputer
