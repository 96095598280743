import React from 'react'
import { useTheme } from '@chakra-ui/react'
import { Flex, Button, Text } from 'components/atoms'
import { colors } from 'styles/colors'
import { useTranslation } from 'react-i18next'

export const WeekdaySelector = (props) => {
  const weekDays = props.weekDays
  const theme = useTheme()
  const { t } = useTranslation()

  const selectDay = (selectedDay) => {
    const updatedWeekdays = weekDays.map((day) => {
      if (selectedDay.id === day.id) {
        day.selected = !day.selected
      }
      return day
    })
    props.onSetWeekdays(updatedWeekdays)
  }

  return (
    <Flex w='100%' justify='space-between'>
      {weekDays.map((day) => (
        <Button
          key={`dayID-${day.id}`}
          w={theme.pxToRem(50)}
          h={theme.pxToRem(40)}
          mr={theme.pxToRem(12)}
          onClick={() => selectDay(day)}
          borderColor={
            day?.selected ? colors.primary : colors.whiteTransparent[300]
          }
          kind="outline"
        >
          <Text
            fontSize="lg"
            fontWeight="bold"
            color={day.selected ? colors.primary : colors.white}
          >
            {t(day?.letter)}
          </Text>
        </Button>
      ))}
    </Flex>
  )
}
