import { t } from 'services/translation'

export const benefits = [
  {
    id: 0,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_1_NEW',
  },
  {
    id: 1,
    basic: true,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_2_NEW',
  },
  {
    id: 2,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_3_NEW',
  },
  {
    id: 3,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_4_NEW',
  },
  {
    id: 4,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_5_NEW',
  },
  {
    id: 5,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_6_NEW',
  },
  {
    id: 6,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_7_NEW',
  },
  {
    id: 7,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_8_NEW',
  },
  {
    id: 8,
    basic: false,
    premium: true,
    text: 'MEMBERSHIP_BENEFIT_9_NEW',
  },
]

export const paymentMethod = [
  {
    id: 0,
    name: t('PIX'),
    icon: 'Pix',
  },
  {
    id: 1,
    name: t('CREDIT'),
    icon: 'CreditCard',
  },
  {
    id: 2,
    name: t('TICKET'),
    icon: 'Ticket',
  },
]

export const freePeriodText = (id) => {
  if (id === 1) {
    return {
      text1: 'Cadastre seu cartão',
      text2: 'agora',
      text3: 'e ganhe',
      text4: '15 dias gratuitos',
    }
  }
  return {
    text1: '7 dias',
    text2: 'gratuitos',
    text3: 'por',
    text4: 'nossa conta!',
  }
}
