import React, { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import {
  Table,
  Text,
  Link,
  Avatar,
  SelectDateButton,
  SelectDateModal,
} from 'components'
import { Flex, Spinner, Center, useToast } from '@chakra-ui/react'
import { INTERNAL_ADM_BARBERSHOPS_BY_APPOINTMENTS } from 'services/api/endpoints'
import { useLazyFetch } from 'services/hooks'
import { Layout } from '../Components'

// const columns = [
//   {
//     key: 'image',
//     label: '',
//     render: (item) => <Avatar src={item.profile_image_url} size="medium" />,
//   },
//   {
//     key: 'name',
//     label: 'NOME',
//     render: (item) => <Text>{item.name}</Text>,
//   },
//   {
//     key: 'phone',
//     label: 'TELEFONE',

//     render: (item) => (
//       <Link
//         href={`https://wa.me/${item.phone.replace(/[^\w]/gi, '')}`}
//         isExternal
//       >
//         {item.phone}
//       </Link>
//     ),
//   },
//   {
//     key: 'email',
//     label: 'EMAIL',
//     render: (item) => <Text>{item.email}</Text>,
//   },
//   {
//     key: 'status',
//     label: 'STATUS',
//     render: (item) => <Text>{item.status}</Text>,
//   },
//   // {
//   //   key: 'stripe_client_id',
//   //   label: 'STRIPE CLIENT ID',
//   //   render: (item) => <Text>{item.stripe_client_id}</Text>,
//   // },
//   // {
//   //   key: 'appointments',
//   //   label: 'AGENDAMENTOS',
//   //   render: (item) => <Text>{item.appointments}</Text>,
//   // },
//   {
//     key: 'created_at',
//     label: 'DATA CRIAÇÃO',
//     render: (item) => (
//       <Text>{format(new Date(item.created_at), 'dd/MM/yyyy')}</Text>
//     ),
//   },
// ]

const columns = [
  {
    key: 'image',
    label: '',
    render: (item) => <Avatar src={item.profile_image_url} size="medium" />,
  },
  {
    width: 120,
    key: 'name',
    label: 'NOME',
    render: (item) => <Text>{item.name}</Text>,
  },
  {
    key: 'phone',
    label: 'TELEFONE',
    render: (item) => (
      <Link
        href={`https://wa.me/${item.phone.replace(/[^\w]/gi, '')}`}
        isExternal
      >
        {item.phone}
      </Link>
    ),
  },
  {
    key: 'status',
    label: 'STATUS',
    render: (item) => <Text>{item.status}</Text>,
  },
  {
    key: 'email',
    label: 'EMAIL',
    render: (item) => <Text>{item.email}</Text>,
  },
  {
    key: 'appointments',
    label: 'AGENDAMENTOS',
    render: (item) => <Text>{item.appointments}</Text>,
  },
  {
    key: 'createdAt',
    label: 'CADASTRO',
    render: (item) => (
      <Text>{format(parseISO(item.created_at), 'dd/MM/yyyy')}</Text>
    ),
  },
]

export const BarbershopsByAppointments = () => {
  const toast = useToast()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dateModalOpen, setDateModalOpen] = useState(false)

  const [getData, { loading, data }] = useLazyFetch(
    INTERNAL_ADM_BARBERSHOPS_BY_APPOINTMENTS,
    {
      onError: (error) => {
        console.log({ error })
        toast({
          title: 'Houve um erro ao carregar as barbearias!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    getData()
  }, [])

  return (
    <Layout title="Novas barbearias por Data">
      <Flex
        flexDir="column"
        w="100%"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Text mt="-2vh">
          Listagem de barbearias que não estão usando mais o sistema
        </Text>
        <Text mb="4vh">(is not published)</Text>
        {loading ? (
          <Center>
            <Spinner color="textLight" />
          </Center>
        ) : (
          <Table
            loading={loading}
            data={data?.barbershops || []}
            columns={columns}
          />
        )}
      </Flex>
    </Layout>
  )
}

BarbershopsByAppointments.path = '/internal-adm/barbershops-by-appointments'
BarbershopsByAppointments.title = 'Novas barbearias'
BarbershopsByAppointments.secured = true
