import React from 'react'
import { Layout } from 'components'

import { BestCoins } from 'components-v2/organisms/BestCoins/BestCoins'

export const BestCoinsScreen = () => {
  return (
    <Layout>
      <BestCoins />
    </Layout>
  )
}

BestCoinsScreen.path = '/best-coins'
BestCoinsScreen.title = 'BestCoins'
BestCoinsScreen.secured = true
