import { useQuery as query } from 'react-query'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { useBarbershopGoalsV2 } from 'services/hooks/use-barber-goals-v2'
import { useToast } from '@chakra-ui/react'

export const useBarberGoalsQuery = () => {
  const toast = useToast()
  const { getAllGoalsResume } = useBarbershopGoalsV2()

  return ({ selectedDay, signatureEnabled, barbershopId }) =>
    query(
      ['get-resume-goals', selectedDay, signatureEnabled],
      () =>
        getAllGoalsResume({
          body: {
            start_date: format(startOfMonth(selectedDay), 'yyyy-MM-dd'),
            end_date: format(endOfMonth(selectedDay), 'yyyy-MM-dd'),
            signature_enabled: signatureEnabled,
            barbershop_id: barbershopId,
          },
        }),
      {
        select: (values) => [...values?.barbersResume, ...values?.employeesResume],
        onError: (err) => {
          toast({
            title: 'Erro ao buscar dados',
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        },
        refetchOnWindowFocus: false,
      }
    )
}
