import * as Yup from 'yup'
import { t } from 'services/translation'

export const dataProfile = [
  {
    id: 0,
    name: 'owner_name',
    type: 'string',
    noBorder: true,
    label: 'RESPONSABLED_NAME',
    placeholder: t('RESPONSABLED_NAME'),
  },
  {
    id: 1,
    name: 'name',
    type: 'string',
    noBorder: true,
    label: 'BARBERSHOP_NAME',
    placeholder: t('BARBERSHOP_NAME'),
  },
  {
    id: 2,
    name: 'phone',
    type: 'cel-phone',
    noBorder: true,
    label: 'BARBERSHOP_PHONE',
    mask: 'PHONE_MASK',
    placeholder: t('BARBERSHOP_PHONE'),
  },
  {
    id: 3,
    name: 'secondary_identifier_doc',
    noBorder: true,
    label: 'CPF',
    placeholder: 'CPF',
    mask: 'CPF_MASK',
  },
  {
    id: 4,
    name: 'identifier_doc',
    noBorder: true,
    label: 'CNPJ',
    placeholder: 'CNPJ',
    mask: 'CNPJ_MASK',
  },
  {
    id: 5,
    name: 'description',
    label: 'BARBERSHOP_DESCRIPTION',
    placeholder: t('BARBERSHOP_DESCRIPTION_PLACEHOLDER'),
    typeInput: 'textArea',
  },
]

export const validationProfile = Yup.object().shape({
  name: Yup.string().min(4, t('NAME')).required(t('NAME_REQUIRED')),
  owner_name: Yup.string().min(4, 'NAME').required('Nome do responsável é obrigatório'),
  phone: Yup.string(),
  identifier_doc: Yup.string(),
  secondary_identifier_doc: Yup.string(),
  description: Yup.string().min(10, t('DESCRIPTION')).required(t('DESCRIPTION_REQUIRED')),
})

export const initialProfile = {
  name: '',
  owner_name: '',
  phone: '',
  identifier_doc: '',
  description: '',
  secondary_identifier_doc: '',
}

//ACCESS DATA ------------------------------------------------------------------------
export const dataAccessData = [
  {
    name: 'email',
    type: 'string',
    noBorder: true,
    label: 'Email',
  },
  {
    name: 'phone',
    type: 'string',
    noBorder: true,
    label: 'PHONE_NUMBER',
    mask: 'PHONE_MASK',
  },
]

export const validationAccessData = Yup.object().shape({
  phone: Yup.string().required('Telefone é obrigatório'),
  email: Yup.string().email('Precisa ser um email válido').required('Email é obrigatório'),
})

export const initialAccessData = {
  email: '',
  phone: '',
}

//LINK ------------------------------------------------------------------------
export const dataLink = [
  {
    name: 'slug',
    type: 'string',
    noBorder: true,
    label: 'Link',
  },
]

export const validationLink = Yup.object().shape({
  slug: Yup.string()
    .min(3, 'Link deve ter 3 caracteres')
    .matches(/^[A-Za-z0-9_-]+$/, 'Link não pode conter espaços em branco e carácteres especiais')
    .required('Link é obrigatório'),
})

export const initialLink = {
  slug: '',
}

//LOCATION ------------------------------------------------------------------------
export const dataLocation = [
  {
    name: 'zip_code',
    type: 'string',
    noBorder: true,
    label: 'ZIP_CODE_PLACEHOLDER',
    mask: 'CEP_MASK',
  },
  {
    name: 'state',
    type: 'string',
    noBorder: true,
    label: 'STATE',
  },
  {
    name: 'city',
    type: 'string',
    noBorder: true,
    label: 'CITY',
  },
  {
    name: 'street',
    type: 'string',
    noBorder: true,
    label: 'ADDRESS',
  },
  {
    name: 'street_number',
    type: 'string',
    noBorder: true,
    label: 'NUMBER',
  },
  {
    name: 'complement',
    type: 'string',
    noBorder: true,
    label: 'COMPLEMENT',
  },
  {
    name: 'neighborhood',
    type: 'string',
    noBorder: true,
    label: 'NEIGHBORHOOD',
  },
]

export const validationLocation = Yup.object().shape({
  zip_code: Yup.string().min(8, t('INCOMPLETE_CEP')).required(t('CEP_REQUIRED')),
  state: Yup.string().required(t('STATE_REQUIRED')),
  city: Yup.string().required(t('CITY_REQUIRED')),
  street: Yup.string().required(t('STREET_REQUIRED')),
  street_number: Yup.string().required(t('STREET_NUMBER_REQUIRED')),
  neighborhood: Yup.string(),
})

export const initialLocation = {
  zip_code: '',
  state: '',
  city: '',
  street: '',
  street_number: '',
  neighborhood: '',
  country: '',
  latitude: '',
  longitude: '',
}

//CHANGE PASSWORD ------------------------------------------------------------------------
export const dataChangePassword = [
  {
    id: 'oldPassword',
    name: 'oldPassword',
    type: 'password',
    noBorder: true,
    label: 'ACTUAL_PASSWORD',
  },
  {
    id: 'password',
    name: 'password',
    type: 'password',
    noBorder: true,
    label: 'NEW_PROFILE_PASSWORD',
  },
  {
    id: 'confirmPassword',
    name: 'confirmPassword',
    type: 'password',
    noBorder: true,
    label: 'REPEAT_NEW_PASSWORD',
  },
]

export const validationChangePassword = Yup.object().shape({
  oldPassword: Yup.string().min(6, t('PASSWORD')).required(t('ACTUAL_PASSWORD_REQUIRED')),
  password: Yup.string().min(6, t('NEW_PASSWORD_LENGTH')).required(t('NEW_PASSWORD_REQUIRED')),
  confirmPassword: Yup.string().min(6, t('NEW_PASSWORD_LENGTH')).required(t('NEW_PASSWORD_REQUIRED')),
})

export const initialChangePassword = {
  oldPassword: '',
  password: '',
  confirmPassword: '',
}
