import React from 'react'
import { Screen, Flex } from 'components'
import loginBg from 'assets/img/backgrounds/login.png'
import { CreateAccountForm } from 'components'
import { theme } from 'styles'

export const AccountStepScreen = () => {
  return (
    <Screen type="login" imageUrl={loginBg}>
      <Flex
        justifyContent="center"
        alignItems="center"
        paddingTop={theme.pxToRem(40)}
        h="auto"
        w="100%"
      >
        <CreateAccountForm />
      </Flex>
    </Screen>
  )
}

AccountStepScreen.path = '/cadastro/:cupom?'
AccountStepScreen.title = 'Criar Conta'
AccountStepScreen.secured = false
