import {
  format,
  differenceInMinutes,
  add,
  getISODay,
  parseISO,
  subMinutes,
} from 'date-fns'
import { createDateComplete } from 'utils/date'
import { colors, theme } from 'styles'

export const generateDataForCalendarView = (
  selectedDate,
  opening_hours, // data to get whats the open and close hours for the day
  appointmentsData, // appointments for the day
  barbersInfo
) => {
  try {
    const date = new Date(selectedDate) // selected date
    const barbers = barbersInfo.filter((item) => !item?.type)
    const dayOfTheWeek = getISODay(date) // selected date day of the week
    const openingHoursForDay = opening_hours[dayOfTheWeek - 1] // gets the open and close hours
    const start_hour = openingHoursForDay.start_hour
    const close_hour = openingHoursForDay.close_hour
    const initialTime =
      openingHoursForDay.start_hour < '06:00'
        ? openingHoursForDay.start_hour
        : `06:00 às ${openingHoursForDay.start_hour}`
    const finalTime = `${openingHoursForDay.close_hour} às 23:59`

    // get if the barbershop is closed
    if (openingHoursForDay.is_closed) {
      return { error: 'Barbearia Fechada' }
    }
    const totalOfMinutes = differenceInMinutes(
      // get the total minutes of the day
      createDateComplete(format(date, 'yyyy-MM-dd'), close_hour),
      createDateComplete(format(date, 'yyyy-MM-dd'), start_hour)
    )
    let times = [] // array to get all times available from the days
    // here we will get each 10m interval, from the star hour to the close hour
    for (let i = 0; i < totalOfMinutes; i++) {
      if (i === 0 || i % 10 === 0) {
        const dateFormatted = add(
          createDateComplete(format(date, 'yyyy-MM-dd'), start_hour),
          {
            minutes: i,
          }
        )
        const scheduleHour = format(dateFormatted, 'HH:mm') // formats the hour
        times.push(scheduleHour)
      }
    }

    const barbersTimes = []
    barbers.forEach((barber) => {
      const barberAppointments = appointmentsData?.filter(
        // get barber appointments for the day
        (item) => item?.barber?.id === barber?.id
      )
      const barberBlockedTimes = appointmentsData?.filter(
        // get barber appointments for the day
        (item) =>
          (item?.barber_id === barber?.id && item?.type === 'blocked_times') ||
          item?.all_barbers
      )
      const bookedTimes = times.map((time, index) => {
        const appointments = []
        const start_hour = '06:00'
        const start_hour_closed = openingHoursForDay?.close_hour
        const open_hour = openingHoursForDay?.start_hour
        const finish_hour = '23:00'
        const hour = `${time}:00`
        const limitHour = format(
          subMinutes(parseISO(`2021-10-10 ${open_hour}`), 10),
          'HH:mm:ss'
        )
        const currentDifOfMinutes = differenceInMinutes(
          parseISO(`2021-10-10 ${hour}`),
          parseISO(`2021-10-10 ${start_hour}`)
        )
        const totalOfMinutes = differenceInMinutes(
          parseISO(`2021-10-10 ${open_hour}`),
          parseISO(`2021-10-10 ${start_hour}`)
        )
        const isStart =
          hour === start_hour ||
          (currentDifOfMinutes >= -5 && currentDifOfMinutes < 5)
        const isFinish = hour === limitHour
        if (
          (currentDifOfMinutes >= -5 && open_hour > hour) ||
          isStart ||
          isFinish
        ) {
          appointments.push({
            isStart,
            isFinish:
              hour === limitHour || hour === `${open_hour.substring(0, 4)}0:00`,
            line: Math.round(currentDifOfMinutes / 10),
            totalOfMinutes,
            type: 'closed',
          })
        }
        const limitHourClosed = format(
          subMinutes(parseISO(`2021-10-10 ${finish_hour}`), 10),
          'HH:mm:ss'
        )
        const currentDifOfMinutesClosed = differenceInMinutes(
          parseISO(`2021-10-10 ${hour}`),
          parseISO(`2021-10-10 ${start_hour_closed}`)
        )
        const totalOfMinutesClosed = differenceInMinutes(
          parseISO(`2021-10-10 ${finish_hour}`),
          parseISO(`2021-10-10 ${start_hour_closed}`)
        )
        const isStartFinished =
          hour === start_hour ||
          (currentDifOfMinutesClosed >= -5 && currentDifOfMinutesClosed < 5)
        const isFinishFinished = hour === limitHourClosed
        if (
          (currentDifOfMinutesClosed >= -5 && finish_hour > hour) ||
          isStartFinished ||
          isFinishFinished
        ) {
          appointments.push({
            isStart: isStartFinished,
            isFinish:
              hour === limitHour || hour === `${open_hour.substring(0, 4)}0:00`,
            line: Math.round(currentDifOfMinutes / 10),
            totalOfMinutes: totalOfMinutesClosed,
            type: 'closed',
          })
        }
        barberAppointments.forEach((appointment, index) => {
          const { start_hour, finish_hour } = appointment
          const hour = `${time}:00`
          const limitHour = format(
            subMinutes(parseISO(`2021-10-10 ${finish_hour}`), 10),
            'HH:mm:ss'
          )
          const currentDifOfMinutes = differenceInMinutes(
            parseISO(`2021-10-10 ${hour}`),
            parseISO(`2021-10-10 ${start_hour}`)
          )
          const totalOfMinutes = differenceInMinutes(
            parseISO(`2021-10-10 ${finish_hour}`),
            parseISO(`2021-10-10 ${start_hour}`)
          )
          const isStart =
            hour === start_hour ||
            (currentDifOfMinutes >= -5 && currentDifOfMinutes < 5)
          const isFinish = hour === limitHour
          if (
            (currentDifOfMinutes >= -5 && finish_hour > hour) ||
            isStart ||
            isFinish
          ) {
            appointments.push({
              ...appointment,
              isStart,
              isFinish:
                hour === limitHour ||
                hour === `${finish_hour.substring(0, 4)}0:00`,
              line: Math.round(currentDifOfMinutes / 10),
              totalOfMinutes,
            })
          }
        })
        barberBlockedTimes.forEach((blocked, index) => {
          const { start_hour, finish_hour } = blocked
          const hour = `${time}:00`
          const limitHour = format(
            subMinutes(parseISO(`2021-10-10 ${finish_hour}`), 10),
            'HH:mm:ss'
          )
          const currentDifOfMinutes = differenceInMinutes(
            parseISO(`2021-10-10 ${hour}`),
            parseISO(`2021-10-10 ${start_hour}`)
          )
          const totalOfMinutes = differenceInMinutes(
            parseISO(`2021-10-10 ${finish_hour}`),
            parseISO(`2021-10-10 ${start_hour}`)
          )
          const isStart =
            hour === start_hour ||
            (currentDifOfMinutes >= -5 && currentDifOfMinutes < 5)
          const isFinish = hour === limitHour
          const app = appointments.find(
            (item) => item.start_hour === start_hour
          )
          if (
            ((currentDifOfMinutes >= -5 && finish_hour > hour) ||
              isStart ||
              isFinish) &&
            !app
          ) {
            appointments.push({
              ...blocked,
              isStart,
              isFinish:
                hour === limitHour ||
                hour === `${finish_hour.substring(0, 4)}0:00`,
              line: Math.round(currentDifOfMinutes / 10),
              totalOfMinutes,
            })
          }
        })
        return {
          time,
          appointments,
        }
      })
      barbersTimes.push({
        barber,
        bookedTimes,
      })
    })
    return { times, barbersTimes, initialTime, finalTime }
  } catch (error) {
    console.log({ error })
  }
}

export const handleStatus = (status) => {
  if (status === 'finished') {
    return 'Finalizado'
  }
  if (status === 'pending') {
    return 'Pendente'
  }
  if (status === 'awaiting') {
    return 'Aguardando'
  }
  if (status === 'confirmed') {
    return 'Confirmado'
  }
  return 'Pendente'
}

export const handleBgColor = (appointment) => {
  if (appointment && appointment.type === 'closed') {
    return 'transparent'
  }
  if (appointment?.status === 'confirmed') {
    return 'blue'
  }
  if (appointment?.status === 'client_missed') {
    return 'textMedium'
  }
  if (appointment?.status === 'client_arrived') {
    return 'orange'
  }
  if (appointment && appointment.status === 'finished') {
    return 'success'
  }
  if (appointment && appointment.type === 'blocked_times') {
    return 'brand.primaryLight'
  }
  if (appointment && appointment.past) {
    return 'baseRed'
  }
  if (appointment) {
    return 'primary'
  }
  return 'transparent'
}

export const handleFlexProps = (appointment) => ({
  cursor: appointment?.type === 'closed' ? 'auto' : 'pointer',
  borderRightColor: 'modalBorder',
  borderLeftColor: 'modalBorder',
  borderTopColor: appointment?.isStart ? 'modalBorder' : 'transparent',
  borderBottomColor: appointment?.isFinish ? 'modalBorder' : 'transparent',
  borderWidth: '1px',
  borderTopLeftRadius: theme.pxToRem(appointment?.isStart ? 6 : 0),
  borderTopRightRadius: theme.pxToRem(appointment?.isStart ? 6 : 0),
  borderBottomLeftRadius: theme.pxToRem(appointment?.isFinish ? 6 : 0),
  borderBottomRightRadius: appointment?.isFinish ? 6 : 0,
  h: theme.pxToRem(30),
  backgroundColor:
    appointment?.type === 'closed'
      ? colors.greyTransparent[1]
      : 'cardBackground',
  p: theme.pxToRem(3),
  pl: theme.pxToRem(16),
})

