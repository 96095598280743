import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalBody, ModalHeader, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { Icon, Text } from 'components/atoms'
import { useHistory } from 'react-router-dom'

import ReactPlayer from 'react-player/youtube'

export const AdsModal = ({ isOpen, link, onClose, text, primary_title, subtitle, description, type }) => {
  const theme = useTheme()
  const history = useHistory()
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    if (counter < 6) {
      setTimeout(() => {
        setCounter((state) => ++state)
      }, 1000)
    } else {
      return
    }
  }, [counter])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={theme.pxToRem(870)}
        borderColor="primary"
        borderWidth={1}
        bg="backgroundLayout"
        flexDir="column"
        align="center"
        justify="center"
        py="16px"
        borderRadius="12px"
      >
        <ModalHeader textAlign="center" w={'100%'}>
          <Flex flexDir="column" w="100%" alignItems="center" justifyContent="center">
            <Text mb="8px" color="primary" kind="extraBold" fontSize={28} textAlign="center">
              {primary_title}
            </Text>
          </Flex>
          <Text color="white" kind="bold" fontSize={20} textAlign="center">
            {subtitle}
          </Text>
        </ModalHeader>
        {counter > 0 && <ModalCloseButton mt="8px" color="white" onClick={onClose} />}
        <Flex ml="5%" flexDir="column" w="90%" h="100%" align="center" justify="center">
          <Text mb="10px" color="white" kind="regular" fontSize={14} textAlign="center">
            {description}
          </Text>
          <Text mb="16px" color="white" kind="bold" fontSize={16} textAlign="center">
            {text}
          </Text>
        </Flex>
        <Flex w="100%" h="100%" mb="24px" align="center" justify="center">
          {type === 'video' ? (
            <ReactPlayer url={link} playing={true} w="100%" h="100%" controls={true} />
          ) : (
            <img src={link} style={{ width: '100%', height: '100%', border: 'none' }} title="Web View" />
          )}
        </Flex>
      </ModalContent>
    </Modal>
  )
}
