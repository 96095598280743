import React, { useState, useEffect } from 'react'
import { Text, ModalMenu, TextInput, FileInput, Button } from 'components'
import { ModalButtonBackground } from 'components-v2/atoms'
import { useToast } from '@chakra-ui/react'
import { t } from 'services/translation'
import { useBarbershopAds } from 'services/hooks/use-barbershop-ads'
import { useBarbershopStore } from 'services/stores'
import { useMutation, useQueryClient } from 'react-query'

export const BarbershopAdsModal = ({
  goBack,
  isModalOpen,
  onClose,
  ads_data = null,
}) => {
  const toast = useToast()
  const { createBarbershopAds, updateBarbershopAds } = useBarbershopAds()
  const { userData } = useBarbershopStore()
  const queryClient = useQueryClient()

  const [photo, setPhoto] = useState()
  const [redirect_link, setLink] = useState()

  const handleSelectPhoto = async (photo) => {
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onload = () => {
      setPhoto(reader?.result)
    }
  }

  const mutation = useMutation(
    'create-barbershop-ads',
    () => {
      const splitted = photo?.split(',')
      const mime = splitted?.[0]?.toString()
      const mime1 = mime?.split(':')
      const mime2 = mime1?.[1]?.split(';')
      const body = {
        barbershop_id: userData?.barbershop?.id || userData?.id,
        base64: splitted?.[1],
        mime: mime2?.[0],
        redirect_link,
      }
      if (!ads_data) {
        createBarbershopAds(body)
      } else {
        updateBarbershopAds({
          ads_id: ads_data?.id,
          body: {
            redirect_link,
            ...(ads_data?.url !== photo
              ? { base64: splitted?.[1], mime: mime2?.[0] }
              : {}),
          },
        })
      }
    },
    {
      onSuccess: () => {
        toast({
          title: !ads_data
            ? 'Anúncio criado com sucesso'
            : 'Anúncio atualizado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        onClose()
        setTimeout(() => {
          queryClient.invalidateQueries('get-barbershop-ads')
        }, 3000)
      },
    }
  )

  useEffect(() => {
    if (ads_data) {
      setPhoto(ads_data?.url)
      setLink(ads_data?.redirect_link)
    } else {
      setPhoto()
      setLink()
    }
  }, [isModalOpen])

  return (
    <ModalMenu
      isOpen={isModalOpen}
      onClose={onClose}
      title={t(!ads_data ? 'Adicionar novo anúncio' : 'Detalhes do anúncio')}
    >
      <Text mt={0} mb={4} fontSize={14} color="textGrey" kind="medium">
        Imagem do Anúncio
      </Text>
      <FileInput
        defaultImage={photo}
        label="Profile"
        onChange={handleSelectPhoto}
        canDelete={false}
        w={'100%'}
        h={130}
        br={8}
      />

      <Text mt={2} mb={4} fontSize={12} color="textGrey" kind="medium">
        Proporções sugeridas: 335px x 130px / 670px x 260px / 1005px x 650px
      </Text>

      <TextInput
        noBorder
        type="text"
        label="Link de redirecionamento do Anúncio"
        placeholder="Ao clicar no anúncio o cliente será redirecionado"
        backgroundColor="backgroundLayout"
        borderWidth={1}
        borderColor="modalBorder"
        borderRadius={8}
        paddingLeft="16px"
        mt={4}
        onChange={(event) => {
          setLink(event?.target?.value)
        }}
        value={redirect_link}
        w="100%"
      />

      <ModalButtonBackground>
        <Button
          onClick={mutation.mutate}
          disabled={!photo || mutation.isLoading}
          isLoading={mutation.isLoading}
          w="100%"
        >
          Salvar
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}
