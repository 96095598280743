import { Box, SimpleGrid } from '@chakra-ui/react'
import { addMinutes, format } from 'date-fns'

import { ClockPointer } from './ClockPointer'

export const GridBackground = ({
  rowHeight,
  rows5Min,
  rows30Min,
  displayMinutes,
  cols,
  headers,
  minTime,
  maxTime,
  onGridClick,
  agenda_interval,
  ...props
}) => {
  const handleGridClick = (index) => {
    if (!onGridClick) return

    const rowIndex = (index - (index % cols)) / cols
    const startTimeStr = format(
      addMinutes(new Date(`2000/01/01 ${minTime}`), rowIndex * 5),
      'HH:mm'
    )
    const endTimeStr = format(
      addMinutes(new Date(`2000/01/01 ${minTime}`), rowIndex * 5 + 5),
      'HH:mm'
    )
    const header = headers[index % cols]

    onGridClick({ header, startTimeStr, endTimeStr })
  }

  return (
    <Box position="relative" bg="red" {...props}>
      <SimpleGrid columns={cols} w="full" h="full" position="absolute">
        {Array.from({ length: rows30Min * cols - cols }, (_e, i) => (
          <Box
            key={i}
            h={`${(rowHeight + 1) * (agenda_interval / 5)}px`}
            borderWidth={0.5}
            borderColor="modalBorder"
          />
        ))}
      </SimpleGrid>
      <SimpleGrid
        columns={cols}
        w="full"
        h="full"
        position="absolute"
        zIndex={1}
      >
        {Array.from({ length: rows5Min * cols }, (_e, i) => {
          return (
            <Box
              key={i}
              h={`${rowHeight + 1}px`}
              borderWidth={0.5}
              borderColor="modalBorder"
              opacity={0.5}
              onMouseUp={(ev) => handleGridClick(i)}
              color="red"
            />
          )
        })}
      </SimpleGrid>

      <ClockPointer
        zIndex={1}
        displayMinutes={displayMinutes}
        rows5Min={rows5Min}
        rowHeight={rowHeight}
        minTime={minTime}
        maxTime={maxTime}
      />
    </Box>
  )
}
