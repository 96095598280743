import React, { useState, useEffect } from 'react'
import { Grid, GridItem, useToast } from '@chakra-ui/react'
import { Box, Text, FileInput } from 'components/atoms'
import { ActionModal } from 'components/organisms'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useTranslation } from 'react-i18next'

import {
  BARBERSHOP_IMAGES,
  BARBERSHOP_UPLOAD_IMAGES,
  BARBERSHOP_UPLOAD_IMAGES_EDIT,
} from 'services/api/endpoints'
import { useQuery, useMutation } from 'react-query'
import { api } from 'services/api/config'

export const Photos = () => {
  const toast = useToast()
  const [selectedPhoto, selectedPhotoSet] = useState({})
  const [currentPhotos, currentPhotosSet] = useState([])
  const [load, setLoad] = useState()
  const { photos, setPhotos, userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()

  const handleDeleteCancel = () => {
    selectedPhotoSet({})
  }

  async function changePhoto(params) {
    const res = await api[params.method](
      params.method === 'put'
        ? BARBERSHOP_UPLOAD_IMAGES_EDIT(
            params?.params?.id,
            userData?.barbershop?.id || userData?.id
          )
        : BARBERSHOP_UPLOAD_IMAGES(userData?.barbershop?.id || userData?.id),
      params?.params
    )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function deletePhoto(params) {
    const res = await api
      .delete(
        BARBERSHOP_UPLOAD_IMAGES_EDIT(
          selectedPhoto?.id || params,
          userData?.barbershop?.id || userData?.id
        )
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function getPhotos() {
    const res = await api
      .get(BARBERSHOP_IMAGES(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { refetch } = useQuery('get-photos', getPhotos, {
    onSuccess: (data) => {
      setPhotos(data)
    },
  })

  const mutate = useMutation('change-photos', (params) => changePhoto(params), {
    onSuccess: (data) => {
      refetch()
      toast({
        title: t('PHOTOS_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      setLoad(false)
    },
    onError: (error) => {
      toast({
        title: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const mutateDelete = useMutation(
    'change-photos',
    (params) => deletePhoto(params),
    {
      onSuccess: (data) => {
        selectedPhotoSet({})
        refetch()
        toast({
          title: t('PHOTOS_TOAST'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const handleDeletePhoto = (photo, index) => {
    if (photo.id) {
      selectedPhotoSet(photo)
    } else {
      currentPhotosSet((photos) => {
        photos[index] = {
          url: null,
          raw: null,
        }
        return [...photos]
      })
    }
  }

  const handleSelectPhoto = async (photo, index) => {
    if (currentPhotos[index]?.url) {
      mutateDelete.mutate(currentPhotos[index]?.id)
    }
    setLoad(index)
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onload = () => {
      const splitted = reader.result.split(',')
      const mime = splitted[0].toString()
      const mime1 = mime.split(':')
      const mime2 = mime1[1].split(';')
      const params = {
        method: 'post',
        params: {
          base64: splitted[1],
          mime: mime2[0],
        },
      }
      mutate.mutate(params)
    }
    currentPhotosSet((photos) => {
      const url = URL.createObjectURL(photo)
      photos[index] = {
        url,
        raw: photo,
      }
      return [...photos]
    })
  }

  useEffect(() => {
    const photoList = Array.from({ length: 8 }, (_, key) => {
      return { ...photos?.[key] } || {}
    })

    currentPhotosSet(photoList)
  }, [photos])

  return (
    <Box>
      <form onSubmit={() => {}}>
        <Text
          fontSize={pxToRem(18)}
          fontWeight="bold"
          marginBottom={pxToRem(26)}
        >
          {t('PHOTOS')}
        </Text>
        <Grid
          justifyItems="center"
          templateRows={isDesktop ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'}
          templateColumns={isDesktop ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'}
          gap={4}
        >
          {currentPhotos.map((item, index) => {
            return (
              <GridItem>
                <FileInput
                  defaultImage={item?.url}
                  key={`photo-${index}`}
                  label={t('IMAGE')}
                  onChange={(file) => handleSelectPhoto(file, index)}
                  onClear={(cb) => {
                    handleDeletePhoto(item, cb)
                  }}
                  loading={load === index}
                />
              </GridItem>
            )
          })}
        </Grid>
      </form>

      <ActionModal
        kind="warning"
        title={t('EXCLUDE_PHOTO')}
        description={t('EXCLUDE_PHOTO_CONFIRMATION')}
        isOpen={selectedPhoto?.id}
        onConfirm={() => mutateDelete.mutate()}
        onCancel={handleDeleteCancel}
        loading={mutateDelete.isLoading}
      />
    </Box>
  )
}
