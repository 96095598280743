import React, { useState } from 'react'
import { useTheme } from '@chakra-ui/react'
import { Flex, Table, TextInput, Button, Text } from 'components'
import { useAppointmentStore, useBarbershopStore } from 'services/stores'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSignature } from 'services/hooks/use-signature'
import { formatPhone } from 'utils/phone-formater'

export const AppointmentSignatureSelectClient = ({ goBack }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { setClient, setClientsList, clientsList } = useAppointmentStore()
  const [query, setQuery] = useState('')
  const { getAllSignatures } = useSignature()
  const [skip, setSkip] = useState(0)
  const [refreshing, setRefreshing] = useState(false)

  const { userData } = useBarbershopStore()

  const fetchMoreItems = () => {
    setRefreshing(true)
    setSkip(skip + 20)
  }

  const columns = [
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <Text w="100%" kind="bold" color="textLight">
          {item.client.name}
        </Text>
      ),
    },
    {
      key: 'phone',
      label: 'PHONE_NUMBER',
      width: 130,
      render: (item) => (
        <Text w="100%" kind="medium" color="textGrey">
          {formatPhone(item.client.user.phone, userData)}
        </Text>
      ),
    },
  ]
  const { refetch, isLoading, isRefetching } = useQuery(
    ['get-clients-signature', skip],
    () => getAllSignatures({ barbershop_id: userData?.id, skip, name: query }),
    {
      onSuccess: (data) => {
        let newList = data?.clientsSignature
        if (skip !== 0 && query?.length % 2 === 0) {
          newList = [...clientsList, ...data?.clientsSignature]
        }
        setClientsList(newList)
        setRefreshing(false)
      },
      refetchOnWindowFocus: false,
    }
  )

  const handleSelectCustomer = (item) => {
    setClient({
      ...item,
      created: false,
    })
    goBack()
  }

  return (
    <Flex flexDirection="column" flex="1">
      <Flex w="100%" justify="space-between" mb={4}>
        <TextInput
          noBorder
          type="text"
          color={colors.white}
          placeholder={t('SEARCH_CLIENT')}
          backgroundColor="backgroundLayout"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={12}
          paddingLeft={theme.pxToRem(16)}
          mb={theme.pxToRem(12)}
          onChange={(event) => {
            setQuery(event?.target?.value)
          }}
          w="95%"
          mr="3%"
        />
        <Button
          onClick={refetch}
          icon="Search"
          iconColor="black"
          iconSize={20}
          w={theme.pxToRem(52)}
          h={theme.pxToRem(52)}
          isLoading={isLoading || isRefetching}
        />
      </Flex>

      <Table
        withoutHeader
        loading={isLoading}
        columns={columns}
        data={clientsList}
        isSelectableRow
        onSelect={handleSelectCustomer}
      />
      <Flex width="full" justifyContent="center" paddingY={theme.pxToRem(32)}>
        {
          <Button isLoading={refreshing} onClick={fetchMoreItems}>
            Carregar mais
          </Button>
        }
      </Flex>
    </Flex>
  )
}
