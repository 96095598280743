import { Box, Flex } from '@chakra-ui/react'

export const GridHeader = ({
  colsDividers,
  colWidth,
  headers,
  headerRender,
  ...props
}) => (
  <Flex {...props}>
    {Array.from({ length: colsDividers - 1 }, (_e, index) => (
      <Box
        key={index}
        borderRightWidth={1}
        borderColor="modalBorder"
        w={`${colWidth + 1}px`}
        h="full"
      >
        {headerRender(headers[index])}
      </Box>
    ))}
  </Flex>
)
