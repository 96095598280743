import { Text } from 'components'
import { format, parseISO } from 'date-fns'
import { formatPrice } from 'utils'

export const columns = [
  {
    key: 'date',
    label: 'DATA DA TRANSAÇÃO',
    render: (item) => (
      <Text kind="medium" fontSize={14}>
        {format(parseISO(item.date), 'dd/MM/yyyy')}
      </Text>
    ),
  },
  {
    key: 'value',
    label: 'VALUE_TABLE',
    render: (item) => (
      <Text kind="medium" fontSize={14}>
        {formatPrice(item?.value)}
      </Text>
    ),
  },
  {
    key: 'balance',
    label: 'SALDO DO DIA',
    render: (item) => (
      <Text kind="medium" fontSize={14}>
        {formatPrice(item?.balance)}
      </Text>
    ),
  },
  {
    key: 'descirption',
    label: 'DESCRIPTION_TABLE',
    render: (item) => (
      <Text kind="medium" fontSize={14}>
        {item?.description}
      </Text>
    ),
  },
]
