
import * as React from 'react'

function ExtractArrows(props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.21053 21.1311C6.21053 21.611 6.56399 22 7 22C7.43601 22 7.78947 21.611 7.78947 21.1311L7.78947 4.6067L10.7368 7.03963C11.0856 7.32756 11.5805 7.24976 11.8421 6.86585C12.1037 6.48195 12.033 5.93732 11.6842 5.64939L7.47368 2.17378C7.19298 1.94207 6.80702 1.94207 6.52632 2.17378L2.31583 5.64939C1.96702 5.93732 1.89633 6.48195 2.15794 6.86585C2.41955 7.24976 2.91438 7.32756 3.26319 7.03963L6.21053 4.6067L6.21053 21.1311Z" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7895 2.8689C17.7895 2.38902 17.436 2 17 2C16.564 2 16.2105 2.38902 16.2105 2.8689V19.3933L13.2632 16.9604C12.9144 16.6724 12.4195 16.7502 12.1579 17.1341C11.8963 17.5181 11.967 18.0627 12.3158 18.3506L16.5263 21.8262C16.807 22.0579 17.193 22.0579 17.4737 21.8262L21.6842 18.3506C22.033 18.0627 22.1037 17.5181 21.8421 17.1341C21.5805 16.7502 21.0856 16.6724 20.7368 16.9604L17.7895 19.3933V2.8689Z" fill="currentColor" />
        </svg>

    )
}

export default ExtractArrows
