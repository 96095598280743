import React from 'react'

export const FormAtom = (props) => {
  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }

  return (
    <form style={formStyles} {...props}>
      {props.children}
    </form>
  )
}

FormAtom.defaultProps = {}

FormAtom.propTypes = {
  ...FormAtom.propTypes,
}
