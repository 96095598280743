import React, { useMemo } from 'react'
import {
  MonthAppointmentsChart,
  Text,
  ServiceDetailsCard,
  AppointmentsResumeCard,
  AppointmentHomeCard,
  FinanceResumeCard,
  PieChart,
  Icon,
  Button,
  Link,
} from 'components'
import { Flex, Box, useTheme } from '@chakra-ui/react'
import { formatHomeChart } from 'utils/chartData'
import { useTranslation } from 'react-i18next'
import { resume } from './settings'
import { useHistory } from 'react-router-dom'

export const RenderDesktop = ({
  data,
  startDate,
  endDate,
  appointments,
  setSelectedAppointment,
  setDetailsModalVisible,
  isBlocked,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const dataPie = () => {
    const newArr = data?.services_info?.data?.sort((a, b) => b.quantity - a.quantity)
    let label = newArr?.map((item) => item.service)
    let d = newArr?.map((item) => {
      return item.quantity
    })

    const values = {
      label,
      data: d,
    }
    return values
  }

  const appointmentsByDate = useMemo(() => {
    if (data?.all_appointments) {
      return formatHomeChart(startDate, endDate, data)
    }
    return []
  }, [data, startDate, endDate])

  const appointmentsArr = () => {
    if (appointments) {
      const newArr = appointments.filter((item) => !item?.past)
      if (newArr.length > 2) {
        const slice = newArr.slice(0, 2)
        return slice
      }
      return newArr
    }
    return []
  }
  const cellColors = [...theme.colors.pieChart]

  return (
    <Flex
      flexDir="column"
      w="100%"
      padding={isBlocked && theme.pxToRem(24)}
      pointerEvents={isBlocked && 'none'}
    >
      <Box w="100%">
        <Flex w="100%" flexDir="row" mb={theme.pxToRem(24)}>
          {resume(data)?.map((item) => (
            <FinanceResumeCard {...item} width="100%" />
          ))}
        </Flex>

        <Flex w="100%" justifyContent="space-between">
          <Flex
            flexDir={'column'}
            justifyContent="space-between"
            w="49.4%"
            h={650}
            mb={theme.pxToRem(24)}
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              w={'100%'}
              h="100%"
              mr={theme.pxToRem(24)}
            >
              <MonthAppointmentsChart appointmentsByDate={appointmentsByDate} />
            </Flex>

            <Flex justifyContent="center" w={'100%'} h="100%">
              {!!data?.services_info?.data?.length &&
                data?.services_info?.data?.length !== 0 ? (
                <PieChart
                  h="90%"
                  w="100%"
                  data={dataPie()}
                  title={t('SERVICES_DETAILS')}
                  mt="5%"
                />
              ) : (
                <Flex mt="5%" flexDir="column" align="center" justify="start">
                  <Icon name="Chart" size={20} />
                  <Text align="center">{t('NO_DATA_RESUME')}</Text>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex w="49.4%" flexDir="column">
            <AppointmentsResumeCard
              item={data}
              horizontal
              info={'lancamentos'}
            />

            <Flex flexDir="column" h="32%">
              <Text kind="extraBold" fontSize={18} mt={theme.pxToRem(16)}>
                {t('NEXT_APPOINTMENTS')}
              </Text>

              {appointmentsArr()?.length > 0 ? (
                <Flex w="100%" justify="space-between" mt={theme.pxToRem(12)}>
                  {appointmentsArr()?.map((item) => (
                    <AppointmentHomeCard
                      data={item}
                      onClick={() => {
                        setSelectedAppointment(item)
                        setDetailsModalVisible(true)
                      }}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex flexDir="column" align="center" mt={theme.pxToRem(24)}>
                  <Icon name="Calendar" size={30} color="textGrey" />
                  <Text mt={4}>{t('NO_APPOINTMENT_TODAY')}</Text>
                </Flex>
              )}
              <Flex
                w="100%"
                flexDir="row"
                justify="center"
                align="center"
                mt={theme.pxToRem(10)}
                onClick={() => history.push('agenda')}
                cursor="pointer"
              >
                <Text>{t('SEE_APPOINTMENTS')}</Text>
                <Icon name="ArrowRight" ml={2} size={14} color="white" />
              </Flex>
            </Flex>
            {/* <Button
              onClick={() => history.push('agenda')}
              mt={theme.pxToRem(24)}
              mb={theme.pxToRem(16)}
              kind="outline"
              size="block"
            >
              {t('SEE_APPOINTMENTS')}
            </Button> */}
            {!!data?.services_info &&
              data?.services_info?.data
                ?.sort((a, b) => b.quantity - a.quantity)
                ?.slice(0, 3)
                ?.map((item, index) => (
                  <ServiceDetailsCard
                    item={item}
                    index={index}
                    color={cellColors[index]}
                    totalAppointmentsThisMoth={data?.all_appointments?.length}
                    isBlocked={isBlocked}
                  />
                ))}
            {data?.services_info?.data?.length > 3 && (
              <Link
                onClick={() => history.push('/relatorio-servicos')}
                align="center"
                mt={theme.pxToRem(12)}
              >
                {t('SEE_MORE')}
              </Link>
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}
