import React, { useState } from 'react'
import { Center, Spinner, useTheme, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { SingleSaleOptions } from '../AppointmentsModal/SingleSaleOptions'
import {
  Box,
  Text,
  Button,
  ClientCard,
  ServiceCard,
  BarberCard,
  PaymentMethodCard,
  AdditionalProductCard,
  ModalMenu,
  ModalAlert,
} from 'components'
import {
  useFetch,
  useLazyFetch,
  usePermissionCollaborator,
} from 'services/hooks'
import {
  SINGLE_PRODUCT_DELETE,
  SINGLE_PRODUCT_GET,
} from 'services/api/endpoints'

export const NewSingleSaleModal = ({ selectedSale, isOpen, onClose }) => {
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  return (
    <ModalMenu isOpen={isOpen} onClose={onClose} title={t('SINGLE_PRODUCT')}>
      <SingleSaleOptions onClose={() => onClose(false)} />
    </ModalMenu>
  )
}
