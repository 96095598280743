import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { pt } from './pt'
import { en } from './en'
import { es } from './es'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      'pt-BR': {
        translation: pt,
      },
      pt: {
        translation: pt,
      },
      es: {
        translation: es,
      },
    },
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n

export const t = (key) => {
  if (!key) {
    return ''
  }
  return i18n.t(key)
}
