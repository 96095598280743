import React from 'react'
import { Image } from '@chakra-ui/react'
import { Text, Flex, Button, Icon } from 'components'
import { theme } from 'styles'
import { useBreakpoint } from 'services/hooks'

import bestBarbersLogo from 'assets/img/brand/logo_white.png'
const options = [
  {
    label: 'Home',
  },
  {
    label: 'Vantagens',
  },
  {
    label: 'Planos',
  },
  {
    label: 'Sobre',
  },
  {
    label: 'Teste grátis',
  },
]
export const Header = ({ clickButton }) => {
  const { isDesktop } = useBreakpoint()

  return (
    <Flex
      w="100vw"
      bgColor="menuBackground"
      h={theme.pxToRem(68)}
      flexDir="row"
      align="center"
      justify="space-between"
      px={isDesktop ? '10vw' : '5vw'}
    >
      <Image src={bestBarbersLogo} w={theme.pxToRem(140)} h="auto" />
      <Flex flexDir="row" align="center">
        {/* {isDesktop && (
          <>
            {options.map((item) => (
              <Text mr={10} fontSize={theme.pxToRem(14)} kind="bold">
                {item.label}
              </Text>
            ))}
            <Button size="x-small" onClick={clickButton}>
              Assinar agora
            </Button>
          </>
        )} */}
        {isDesktop && (
          <Button size="x-small-auto" onClick={clickButton}>
            Quero um App Personalizado
          </Button>
        )}
        {!isDesktop && <Icon onClick={clickButton} cursor="pointer" name="Crown" fill="primary" />}
      </Flex>
    </Flex>
  )
}
