import { Text } from "components/atoms"
import { format, parseISO } from "date-fns"
import { formatPrice } from "utils"
export const renderInput = [
    {
        label: 'START_DATE',
        name: 'start_date',
        mr: '16px',
    },
    {
        label: 'END_DATE',
        name: 'end_date',
        width: '100%',
    },
]

export const columns = (signature) => {
  if(signature) {
    return [
      {
        key: 'client',
        label: 'CLIENT_TABLE',
        render: (item) => <Text>{item?.type === 'worth' || item?.type === 'revenue' || item?.type === 'singleProductBarber' ? ' - ' :item?.client?.name}</Text>,
      },
      {
        key: 'services',
        label: 'DESCRIPTION_TABLE',
        render: (item) => <Text>{item?.type === 'worth' ? 'Vale' : item?.type === 'revenue' ? item?.description :  item?.type === 'singleProductBarber' ? `Compra: ${item?.additional_product?.map(i => i?.name)?.join(', ')}` : (item?.formatted_services || item?.additional_product)?.map(i => i?.name)?.join(', ')}</Text>,
      },
      {
        key: 'date',
        label: 'DATE_HOUR',
        render: (item) => <Text>{format(parseISO(item?.simple_date ? item?.simple_date : item?.date ?? item?.createdAt), 'dd/MM/yyyy')}</Text>,
      },
      {
        key: 'points',
        label: 'PONTOS',
        render: (item) => <Text>{item?.appointmentServices?.filter(i => i?.points)?.reduce((acc, val) => acc + Number(val?.points), 0)}</Text>,
      },
    ]
  }
  return [
    {
      key: 'client',
      label: 'CLIENT_TABLE',
      render: (item) => <Text>{item?.type === 'worth' || item?.type === 'revenue' || item?.type === 'singleProductBarber' ? ' - ' :item?.client?.name}</Text>,
    },
    {
      key: 'services',
      label: 'DESCRIPTION_TABLE',
      render: (item) => <Text>{item?.type === 'worth' ? 'Vale' : item?.type === 'revenue' ? item?.description :  item?.type === 'singleProductBarber' ? `Compra: ${item?.additional_product?.map(i => i?.name)?.join(', ')}` : (item?.formatted_services || item?.additional_product)?.map(i => i?.name)?.join(', ')}</Text>,
    },
    {
      key: 'date',
      label: 'DATE_HOUR',
      render: (item) => <Text>{format(parseISO(item?.simple_date ? item?.simple_date : item?.date ?? item?.createdAt), 'dd/MM/yyyy')}</Text>,
    },
    {
      key: 'sales_products',
      label: 'BARBER_COMMISSION_SERVICE',
      render: (item) => <Text>{item?.type === 'worth' || item?.type === 'revenue' || item?.type === 'singleProductBarber' ? ' - ' : formatPrice(item?.barber_percentage_value)}</Text>,
    },
    {
        key: 'sales_service',
        label: 'BARBER_COMMISSION_PRODUCT',
        render: (item) => <Text>{item?.type === 'worth' || item?.type === 'revenue' || item?.type === 'singleProductBarber' ? ' - ' : formatPrice(item?.barber_products_value)}</Text>,
      },
      {
        key: 'total',
        label: 'TOTAL',
        render: (item) => <Text>{formatPrice((item?.type === 'worth' || item?.type === 'revenue'  ? item?.value : item?.type === 'singleProductBarber' ? item?.total_value - item?.appointment_payments?.reduce?.((acc, val) => acc + val.payment_value, 0) : item?.barber_products_value + item?.barber_percentage_value))}</Text>,
      },
  ]
} 

  export const cards = (data, signature) => {
    const arr = [
  //     {
  //       label: 'Total',
  //       description: signature ? 'Serviços extras' : 'Valores extras',
  //       value: signature ? data?.detachedServices : data?.extra_value,
  //       color: 'success'
  //   },
  //   {
  //     label: 'Total',
  //     description: signature ? 'Produtos extras' : 'Comissões em serviços',
  //     value: signature ? data?.detachedProducts : data?.servicesTotalComission,
  //     notCurrency: false,
  //     color: 'success'
  // },
    // {
    //     label: 'Total',
    //     description: signature ? 'Comissão de assinatura' : 'Comissões em produtos',
    //     value: signature ? data?.servicesTotalComission : data?.productsTotalComission,
    //     notCurrency: false,
    //   color: 'success'
    // },
    ]

    if(!signature) {
      arr.push({
        label: 'Total',
        description: 'Saldo do barbeiro',
        value: data?.barber_balance,
        color: 'danger'
    },
     
      {
        label: 'Total',
        description: 'Compras em produtos',
        value: data?.shoppingTotalValue,
        color: 'danger'
    },
    {
      label: 'Total',
      description: 'Vales',
      value: data?.worths_value,
      color: 'danger'
    },)
    }

    return arr
  }