import React from 'react'
import { useToast, useTheme, Center } from '@chakra-ui/react'
import { Flex, Text, Loader } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { PlanInvoiceCard } from 'components/molecules'
import { format, parseISO } from 'date-fns'
import { formatCurrency } from 'utils/formatCurrency'
import { MobileList } from '../MobileList'
import { useTranslation } from 'react-i18next'

import { api } from 'services/api/config'
import { useQuery } from 'react-query'
import { GET_SUBSCRIPTION_DETAIL } from 'services/api/endpoints'

export const Plan = () => {
  const toast = useToast()
  const theme = useTheme()
  const { t } = useTranslation()

  async function getPlan() {
    const res = await api
      .get(GET_SUBSCRIPTION_DETAIL)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading } = useQuery('get-plan', getPlan, {
    onError: (error) => {
      toast({
        title: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <Flex flexDirection="column" h="100%">
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Flex flexDirection="column" h="100%">
          <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
            {t('MY_PLAN')}
          </Text>
          <Flex flexDirection="row">
            <Text mr={theme.pxToRem(6)}>{t('CURRENT_PLAN')}</Text>
            <Text kind="bold" color="primary">
              {data?.plan_name}
            </Text>
          </Flex>
          <Flex mt={theme.pxToRem(24)} flexDirection="row">
            <Text mr={theme.pxToRem(6)}>{t('SING_UP_DATE')}</Text>
            <Text kind="bold">
              {data?.created_at
                ? format(parseISO(data?.created_at), 'dd/MM/yyyy')
                : ''}
            </Text>
          </Flex>

          <Flex mt={theme.pxToRem(24)} flexDirection="row">
            <Text mr={theme.pxToRem(6)}>{t('VALUE')}</Text>
            <Text kind="bold">{formatCurrency(data?.price_cents / 100)}</Text>
          </Flex>

          <Flex mt={theme.pxToRem(48)} flexDirection="column" w="100%">
            <Text kind="bold">{t('LAST_PAYMENTS')}</Text>
            <MobileList
              emptyListMessage={t('PAYMENT_NOT_FOUND')}
              data={data?.recent_invoices || []}
              loading={isLoading}
              renderItem={({ item }) => (
                <PlanInvoiceCard key={item.id} item={item} />
              )}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
