import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Box } from 'components/atoms'
import { RatingCard, BarbershopRating } from 'components/molecules'
import { MobileList } from 'components/organisms'
import { Layout } from 'components/templates'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useQuery } from 'react-query'
import { useBarbershop } from 'services/hooks/use-barbershop'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const Ratings = ({ props }) => {
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const { getRatings } = useBarbershop()
  const {onLogout} = useLogoutPaymentIssues()

  const { data, isLoading } = useQuery('get-ratings', () =>
    getRatings({ id: userData?.barbershop?.id || userData?.id })
  )

  useEffect(() => {
    onLogout()
  }, [userData])

  return (
    <Layout {...props} headerTitle={t('RATINGS')} fullHeight>
      <Flex
        width="full"
        justifyContent="space-between"
        flexDirection={isDesktop ? 'row' : 'column-reverse'}
      >
        <Box width={isDesktop ? '65%' : 'full'}>
          <MobileList
            data={data || []}
            loading={isLoading}
            renderItem={({ item, index }) => (
              <RatingCard item={item} index={index} />
            )}
          />
        </Box>

        {!isLoading && (
          <Box width={isDesktop ? '33%' : 'full'}>
            <BarbershopRating data={data || []} />
          </Box>
        )}
      </Flex>
    </Layout>
  )
}

Ratings.path = '/avaliacoes'
Ratings.title = 'Avaliações'
Ratings.secured = true
