import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { Icon, Text, Flex } from 'components/atoms'

export const AgendaIntervalCard = ({ time, onClick }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      px={theme.pxToRem(18)}
      mr={theme.pxToRem(16)}
      mb={theme.pxToRem(42)}
      height={theme.pxToRem(56)}
      width="100%"
      bgColor="agendaIntervalBackground"
      borderRadius={8}
      cursor="pointer"
      onClick={onClick}
    >
      <Flex alignItems="center" w='60%'>
        <Icon name="Clock" size={16} mr={theme.pxToRem(16)} />
        <Text width={260}>
          {time} {t('MINUTES')}
        </Text>
      </Flex>
      <Icon size={14} name="ChevronRight" />
    </Flex>
  )
}
