import { formatPrice } from './price'
import { format, parseISO } from 'date-fns'

export const PAYMENT_METHODS = {
  credit_card: 'Crédito',
  debit_card: 'Débito',
  cash: 'Dinheiro',
}

export const formatAppointmentTime = (item) => {
  return `${item?.start_hour?.substring(
    0,
    5
  )} - ${item?.finish_hour?.substring?.(0, 5)}`
}

export const formatAppointmentDateTime = (item) => {
  return `${format(
    parseISO(item?.simple_date),
    'dd/MM/yy'
  )} às ${item?.start_hour?.substring(0, 5)}`
}

export const formatAppointmentStatus = (item) => {
  return item?.canceled_at ? 'Comanda cancelada' : item.status === 'finished'
    ? 'Finalizado'
    : item.status === 'awaiting'
    ? 'Aguardando'
    : item.status === 'client_missed' 
    ? 'Cliente Faltou'
    : item?.status === 'confirmed'
    ? 'Confirmado'
    : item?.status === 'client_arrived'
    ? 'Cliente chegou'
    : 'Pendente'
}

export const formatAppointmentPaymentMethod = (item) =>
  PAYMENT_METHODS[item] || ''

export const formatAppointments = (data) => {
  return data.map((item) => ({
    hour: formatAppointmentTime(item),
    client: item.client.name,
    barber: item.barber.name,
    product: item.product.name,
    payment: item.payment_method,
    total_value: formatPrice(
      item?.promotional ? item?.product.promotion_price : item?.product.price
    ),
    status: formatAppointmentStatus(item.status),
  }))
}
