import { api } from 'services/api/config'
import { useBarbershopStore } from 'services/stores'

export const useServices = () => {
  const { userData } = useBarbershopStore()
  const getServices = async (props) => {
    const response = await api({
      url: `v3/barbershop/services/${props?.id || userData?.id}`,
    })
    return response.data
  }

  const updateServiceOrder = async (props) => {
    const response = await api.put(`v3/barbershop/order-services`, props?.body)
    return response.data
  }

  const addServicePlan = async (props) => {
    const response = await api.post(`v3/scripts/update-plan-quantity`, props?.body)
    return response.data
  }

  const deleteServicePlan = async (props) => {
    const response = await api.post(`v3/scripts/remove-service-plan`, props?.body)
    return response.data
  }

  return {
    deleteServicePlan,
    addServicePlan,
    updateServiceOrder,
    getServices,
  }
}
