import React, { useState, useMemo } from 'react'
import { useTheme, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { ModalMenu } from 'components/molecules'
import { Text, Flex, Button, Box, Icon } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import { generateHoursList } from 'utils'

import { api } from 'services/api/config'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { BARBERSHOP_AGENDA_INTERVAL } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'

export const AgendaIntervalModal = ({ isOpen, onClose, title }) => {
  const [agenda_time_interval, setAgendaTimeInterval] = useState(10)
  const { isMobile } = useBreakpoint()
  const { t } = useTranslation()
  const theme = useTheme()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { userData } = useBarbershopStore()

  async function getAgendaInterval() {
    const res = await api
      .get(BARBERSHOP_AGENDA_INTERVAL(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  useQuery('get-agenda-interval', getAgendaInterval, {
    onSuccess: (data) => {
      if (data) {
        setAgendaTimeInterval(data ? data : 10)
      }
    },
  })

  async function changeAgendaInterval(params) {
    const res = await api
      .put(
        BARBERSHOP_AGENDA_INTERVAL(userData?.barbershop?.id || userData?.id),
        params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation(
    'change-agenda-interval',
    (params) => changeAgendaInterval(params),
    {
      onSuccess: (data) => {
        toast({
          title: t('AGENDA_INTERVAL_SAVED'),
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        queryClient.invalidateQueries('get-agenda-interval')
        onClose()
      },
      onError: (error) => {
        toast({
          title: t('AGENDA_INTERVAL_SAVE_FAIL'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  const onClickSave = () => {
    const params = {
      agenda_time_interval,
    }
    mutate.mutate(params)
  }

  const hoursList = useMemo(() => {
    const list = generateHoursList(agenda_time_interval)

    return list
  }, [agenda_time_interval])

  const handleAddMinutes = () => {
    setAgendaTimeInterval(agenda_time_interval + 5)
  }

  const handleDecreaseMinutes = () => {
    setAgendaTimeInterval(
      agenda_time_interval - 5 > 5 ? agenda_time_interval - 5 : 5
    )
  }

  return (
    <ModalMenu title={title} isOpen={isOpen} onClose={onClose}>
      <Flex flexDir="column" w="100%" h="100%">
        <Flex flexDir="row" w="100%">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            px={theme.pxToRem(18)}
            mr={theme.pxToRem(16)}
            mb={theme.pxToRem(42)}
            height={theme.pxToRem(56)}
            width={theme.pxToRem(180)}
            bgColor="agendaIntervalBackground"
            borderRadius={8}
          >
            <Icon name="Clock" size={16} mr={theme.pxToRem(16)} />
            <Text fontSize={14}>{`${agenda_time_interval} ${t(
              'MINUTES'
            )}`}</Text>
          </Flex>

          <Button
            disabled={agenda_time_interval === 5}
            size="square"
            kind="outline"
            color="primary"
            borderColor="agendaIntervalBackground"
            borderRadius={8}
            mr={theme.pxToRem(16)}
            onClick={handleDecreaseMinutes}
          >
            <Icon name="Minus" size={20} />
          </Button>
          <Button
            size="square"
            kind="outline"
            color="primary"
            borderColor="agendaIntervalBackground"
            borderRadius={8}
            onClick={handleAddMinutes}
          >
            <Icon name="Plus" size={20} />
          </Button>
        </Flex>

        <Box w="100%" textAlign={isMobile && 'center'}>
          <Text fontSize={18} fontWeight="extrabold" mb={theme.pxToRem(2)}>
            {t('YOUR_AGENDA_INTERVAL')}
          </Text>
          <Text fontSize={14} mb={theme.pxToRem(24)}>
            {t('YOUR_AGENDA_INTERVAL_DESC')}
          </Text>

          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            alignItems="center"
            mb={theme.pxToRem(44)}
            w="100%"
          >
            {hoursList.slice(0, isMobile ? 3 : 4).map((item, index) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb={isMobile && theme.pxToRem(8)}
                w={!isMobile && '100%'}
              >
                <Flex
                  key={`time_${index}`}
                  alignItems="center"
                  justifyContent="center"
                  p={theme.pxToRem(16)}
                  width={isMobile ? theme.pxToRem(170) : theme.pxToRem(80)}
                  height={theme.pxToRem(56)}
                  bgColor="backgroundLayout"
                  borderColor="agendaIntervalBackground"
                  borderRadius={8}
                >
                  <Text fontWeight="extrabold">{item}</Text>
                </Flex>

                {!isMobile && index !== 3 && <Icon name="ChevronRight" />}
              </Flex>
            ))}
          </Flex>

          <Button
            disabled={mutate.isLoading}
            isLoading={mutate.isLoading}
            onClick={onClickSave}
            p={`${theme.pxToRem(16)} ${theme.pxToRem(24)}`}
            w="100%"
          >
            {t('SAVE_CHANGES')}
          </Button>
        </Box>
      </Flex>
    </ModalMenu>
  )
}
