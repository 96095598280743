import React, { useEffect } from 'react'
import { format } from 'date-fns'
import { Flex, Box, Calendar } from 'components'
import { useTheme } from '@chakra-ui/react'
import { useAppointmentStore } from 'services/stores'

const AppointmentSelectDate = ({ goBack }) => {
  const { date, setDate, setSimpleDate, type } = useAppointmentStore()
  const theme = useTheme()

  useEffect(() => {
    if (!date) {
      setDate(new Date(2023, 0, 1, 0, 0, 0))
    }
  }, [])

  const handleSelect = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd')

    setDate(date)
    setSimpleDate(formattedDate)
    goBack()
  }

  return (
    <Flex flexDirection="column">
      <Box marginBottom={theme.pxToRem(32)}>
        <Calendar 
          selectedDate={date}
          onSelectDate={handleSelect}
          showNavigation={false}
          minDate={new Date(2023, 0, 1, 0, 0, 0)}
          maxDate={new Date(2023, 0, 31, 0, 0, 0)}
          // defaultActiveStartDate={new Date(2023, 0, 1, 0, 0, 0)}
          />
      </Box>
    </Flex>
  )
}

export default AppointmentSelectDate
