import * as React from 'react'

function SvgPersonBarber(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        stroke="transparent"
        d="M12.986.657h-1.972A4.937 4.937 0 006.082 5.59v3.287A5.925 5.925 0 0012 14.794a5.925 5.925 0 005.918-5.918V5.59A4.937 4.937 0 0012.986.657zm2.959 8.22A3.95 3.95 0 0112 12.821a3.95 3.95 0 01-3.945-3.946V5.59a2.962 2.962 0 012.959-2.96h1.972a2.962 2.962 0 012.959 2.96v3.287z"
      />
      <path
        fill="currentColor"
        stroke="transparent"
        d="M16.11 11.572a.986.986 0 100 1.972c3.263 0 5.917 2.714 5.917 6.05v1.775H1.973v-1.776c0-3.335 2.654-6.049 5.917-6.049a.986.986 0 100-1.972C3.54 11.572 0 15.17 0 19.594v2.761c0 .545.442.987.986.987h22.028a.986.986 0 00.986-.987v-2.762c0-4.423-3.54-8.021-7.89-8.021z"
      />
      <path
        fill="#20242A"
        stroke="#20242A"
        d="M7.5 9l4.5-.5 4 .5 2 2.5H6.5l1-2.5z"
      />
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M19.466 9.538h-.002a.349.349 0 00-.353.108c-.46.499-.847.672-1.188.641-.341-.03-.713-.268-1.07-.57l-.019-.014-.34-.296c-.273-.239-.563-.493-.87-.72-.993-.737-1.973-.82-2.708-.537a1.982 1.982 0 00-.917.686 1.981 1.981 0 00-.918-.686c-.735-.278-1.713-.196-2.707.537a13.84 13.84 0 00-.87.722c-.138.12-.27.237-.358.31-.36.304-.718.537-1.07.57-.352.032-.73-.147-1.2-.656a.353.353 0 00-.453-.048.264.264 0 00-.11.305c.065.2.148.397.248.587.432.844 1.26 1.481 2.27 1.746a5.744 5.744 0 002.86-.008c.264-.066.522-.15.771-.253.752-.307 1.25-.709 1.538-1.139.29.43.786.833 1.538 1.14.25.101.51.186.775.252a5.742 5.742 0 002.856.01c1.01-.265 1.838-.903 2.27-1.747.1-.19.183-.386.248-.587.05-.151-.05-.309-.221-.353zm-7.788.325c-.023.552-.453 1.162-1.485 1.584-.22.089-.446.163-.679.221a5.006 5.006 0 01-2.485.014 3.039 3.039 0 01-1.494-.892c.193.059.399.08.602.064.538-.041 1.01-.344 1.465-.727.153-.123.255-.219.367-.317.261-.23.54-.474.831-.689.775-.575 1.505-.653 2.035-.452.259.098.477.263.625.474.153.215.229.466.218.72zm5.298 1.815c-.813.204-1.675.2-2.485-.014a5.07 5.07 0 01-.68-.221c-1.031-.422-1.462-1.032-1.485-1.584a1.155 1.155 0 01.217-.72 1.34 1.34 0 01.625-.474c.53-.2 1.26-.123 2.035.452.29.215.57.461.832.69.107.093.212.185.35.302l.015.014c.459.383.927.686 1.466.727.204.016.41-.006.602-.066a3.038 3.038 0 01-1.492.894z"
      />
    </svg>
  )
}

export default SvgPersonBarber
