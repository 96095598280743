import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useToast } from '@chakra-ui/react'

import { Flex, Box, Text, Button, Image } from 'components/atoms'
import { useBreakpoint } from 'services/hooks'
import bestBarbersLogo from 'assets/img/brand/logo_white.png'
import { Form } from 'components/molecules'
import { data, validation, initialValues } from './settings'
import { useMutation } from 'react-query'
import { api } from 'services/api/config'
import { LP_FIRST_REGISTER } from 'services/api/endpoints'
import { useBarbershopStore } from 'services/stores'

export const LandingPageModal = ({
  isOpen,
  onClose,
  handleFormSubmit,
  redirect,
  params,
}) => {
  const theme = useTheme()
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const { setUser } = useBarbershopStore()
  const [values, getValues] = useState({
    params: initialValues,
    isValid: false,
  })

  async function register() {
    const res = await api
      .post(LP_FIRST_REGISTER(redirect), { ...values.params, ...params })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutation = useMutation('lp-first-register', () => register(), {
    onSuccess: (data) => {
      setUser({ ...data.user, name: values.params.name })
      toast({
        title: t('Dados cadastrados com sucesso!'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClose()
      handleFormSubmit()
    },
    onError: (error) => {
      toast({
        title: t('Falha ao cadastrar dados. Por favor, tente novamente'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
        w={['94vw', '45vw']}
        py={['5vh', theme.pxToRem(40)]}
        px={['1vw', theme.pxToRem(20)]}
        mt={0}
      >
        <ModalHeader textAlign="center" w={'100%'}>
          <Flex flexDir="column" w="100%" alignItems="center">
            <Image
              src={bestBarbersLogo}
              w={theme.pxToRem(140)}
              h="auto"
              mb={theme.pxToRem(12)}
            />
            <Text
              kind="extraBold"
              fontSize={20}
              mb={theme.pxToRem(12)}
              mr={theme.pxToRem(6)}
              w={['90%', '70%']}
            >
              Sabia que você não ia ficar de fora dessa!
            </Text>
          </Flex>
          <Text w="100%" color="textGrey" fontSize={12}>
            Insira seus dados abaixo e experimente um gerenciamento de
            barbearias que realmente funciona!
          </Text>
        </ModalHeader>

        <ModalCloseButton color="white" onClick={onClose} />

        <ModalBody w="100%" mt={0}>
          <Box w="100%">
            <Form
              mb={isDesktop ? 40 : 30}
              getValues={getValues}
              initialValues={values?.params}
              validationSchema={validation}
              data={data}
            />
          </Box>
        </ModalBody>

        <ModalFooter flexDir="column">
          <Button
            onClick={mutation.mutate}
            isLoading={mutation.isLoading}
            kind="primary"
            w="100%"
          >
            {t('SAVE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
