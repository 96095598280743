import { api } from 'services/api/config'

export const usePackageService = () => {
  const deletePackageService = async (props) => {
    const response = await api.delete(
      `v3/barbershop/package-services/${props.id}`
    )
    return response.data
  }

  const renewPackageService = async (props) => {
    const response = await api.put(
      `v3/appointments/renew-package-service/${props.id}`,
      props?.body
    )
    return response.data
  }

  return {
    renewPackageService,
    deletePackageService,
  }
}
