import * as React from 'react'

function SvgUsers(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 15.333A6.667 6.667 0 1012 2a6.667 6.667 0 000 13.333zM2 32v-3.333A6.667 6.667 0 018.667 22h6.666A6.667 6.667 0 0122 28.667V32M23.667 2.216a6.667 6.667 0 010 12.917M32 32v-3.333a6.667 6.667 0 00-5-6.417"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgUsers
