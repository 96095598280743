import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme, useDisclosure, Box } from '@chakra-ui/react'

import { Flex, Text, Button, Icon } from 'components/atoms'
import { Header } from 'components/molecules'
import {
  Menu,
  ConfirmFreeTrialModal,
  RefreshDataModal,
  RefreshAddressModal,
  PaymentMethodChoosedModal,
  CreditCardModal,
  SignPremiumModal,
  FreeTrialModal,
  MissingProductModal,
} from 'components/organisms'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import {
  useModalStore,
  useMenuCollapse,
  useBarbershopStore,
} from 'services/stores'
import { BarbershopIntroModal } from 'screens'
import { BarbershopStatusBanner } from 'components/molecules/BarbershopStatusBanner'

export const Layout = (props) => {
  const {
    children,
    headerCenterElement,
    isInternalAdm,
    isDetailScreen,
    handleBackNavigation = () => {},
    isPremiumScreen,
    dir,
  } = props
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { currentModal, setCurrentModal } = useModalStore()
  const { isOpen, onToggle } = useDisclosure()
  const isBlocked = usePremiumBlock()
  const { collapsed } = useMenuCollapse()
  const { userData } = useBarbershopStore()
  const [bannerOpen, setBannerOpen] = useState(
    userData?.account_status === 'TRIAL' || isBlocked
  )

  return (
    <Box
      w="100%"
      h={props?.h || '100vh'}
      pt={isBlocked && '3vh'}
      overflow="auto"
    >
      {bannerOpen && (
        <BarbershopStatusBanner
          onClose={() => setBannerOpen(false)}
          position="absolute"
          top={0}
          zIndex={999}
        />
      )}
      <Flex w="100%" h="100%">
        {isDesktop ? (
          <>
            <Menu path={props.path} isInternalAdm={isInternalAdm} />
            <Flex
              direction="column"
              width="100%"
              height="100%"
              overflowY="auto"
              py={
                bannerOpen
                  ? '80px'
                  : !props.contentPosition && theme.pxToRem(24)
              }
              paddingLeft={theme.pxToRem(
                (props.contentPosition ? 20 : 24) + (collapsed ? 88 : 230)
              )}
              paddingRight={
                props?.overflowX
                  ? 0
                  : !props.contentPosition && theme.pxToRem(24)
              }
              position={props.contentPosition}
              overflowX="hidden"
            >
              {!props?.withoutHeader && <Header {...props} />}
              <Box
                w="100%"
                h={props.fullHeight ? '100%' : 'auto'}
                pointerEvents={props.isBlocked && 'none'}
                overflow={isBlocked && isPremiumScreen && 'hidden'}
                overflowX={props?.overflowX}
              >
                {children}
              </Box>
            </Flex>
          </>
        ) : (
          <Box w="100%" h="100vh" pt={bannerOpen && '125px'}>
            <Menu
              isOpen={isOpen}
              onToggle={onToggle}
              isInternalAdm={isInternalAdm}
            />

            {!isOpen && (
              <Box
                w="100%"
                padding={!props.contentPosition && theme.pxToRem(16)}
                position={props.contentPosition}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop={theme.pxToRem(16)}
                  marginBottom={theme.pxToRem(24)}
                  w="100%"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                    flexDir={dir || 'row'}
                  >
                    {isDetailScreen && (
                      <Button
                        size="square"
                        kind="ghost"
                        onClick={handleBackNavigation}
                        marginRight={theme.pxToRem(12)}
                        mt={!isDesktop && theme.pxToRem(-10)}
                        mb={!isDesktop && theme.pxToRem(10)}
                      >
                        <Icon name="ArrowLeft" color="primary" />
                      </Button>
                    )}
                    <Flex
                      flexDir={props?.modalFilter ? 'row' : 'column'}
                      alignItems={'center'}
                      justifyContent="space-between"
                      w={
                        props?.widthTitle ||
                        ((props?.modalFilter || isDesktop) && '100%')
                      }
                    >
                      <Text
                        kind="extraBold"
                        fontSize={theme.pxToRem(24)}
                        mt={
                          !isDesktop &&
                          !props?.withouPading &&
                          theme.pxToRem(-10)
                        }
                        mb={
                          !isDesktop &&
                          !props?.withouPading &&
                          theme.pxToRem(10)
                        }
                      >
                        {props.headerTitle}
                      </Text>

                      <Flex w={props?.modalFilterWidth || 'full'}>
                        {headerCenterElement && headerCenterElement()}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  direction="column"
                  position={isDesktop ? null : 'relative'}
                  width="100%"
                  height="100%"
                >
                  {props.headerActionText && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      position={isDesktop ? null : 'fixed'}
                      right={isDesktop ? null : theme.pxToRem(40)}
                      bottom={isDesktop ? null : theme.pxToRem(40)}
                      height={isDesktop ? null : theme.pxToRem(60)}
                      width={isDesktop ? null : theme.pxToRem(60)}
                      padding={isDesktop ? theme.pxToRem(8) : theme.pxToRem(32)}
                      backgroundColor={
                        props.premiumBlockMessage || props.isBlocked
                          ? 'grey'
                          : 'primary'
                      }
                      borderRadius={
                        isDesktop ? theme.pxToRem(8) : theme.pxToRem(40)
                      }
                      _hover={{ cursor: 'pointer' }}
                      pointerEvents={
                        (props.premiumBlockMessage || props.isBlocked) && 'none'
                      }
                    >
                      <Icon
                        name="Plus"
                        size={isDesktop ? theme.pxToRem(8) : 18}
                        color="black"
                        onClick={props.headerActionClick}
                      />
                    </Flex>
                  )}
                  <Box
                    width="100%"
                    height={props.fullHeight ? '100%' : 'auto'}
                    pointerEvents={props.isBlocked && 'none'}
                  >
                    {children}
                  </Box>
                </Flex>
              </Box>
            )}
          </Box>
        )}

        {currentModal === 'signPremiumModal' && (
          <SignPremiumModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}

        {currentModal === 'freeTrialModal' && (
          <FreeTrialModal isOpen={true} onClose={() => setCurrentModal(null)} />
        )}

        {currentModal === 'paymentMethodChoosedModal' && (
          <PaymentMethodChoosedModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}

        {currentModal === 'creditCardModal' && (
          <CreditCardModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}

        {currentModal === 'barbershopIntroModal' && (
          <BarbershopIntroModal isOpen={true} />
        )}

        {currentModal === 'confirmFreeTrial' && (
          <ConfirmFreeTrialModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}

        {currentModal === 'refreshDataModal' && (
          <RefreshDataModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}

        {currentModal === 'refreshAddressModal' && (
          <RefreshAddressModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}

        {currentModal === 'missingProductModal' && (
          <MissingProductModal
            isOpen={true}
            onClose={() => setCurrentModal(null)}
          />
        )}
      </Flex>
    </Box>
  )
}

Layout.defaultProps = {
  fullHeight: true,
}

Layout.propTypes = {
  ...Header.propTypes,
  fullHeight: PropTypes.bool,
}
